const RadilogySchedule = {
  schema: {
    type: 'object',
    properties: {
      recordNumberUninformed: {
        type: 'boolean',
      },
      recordNumber: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
        recordNumber: '',
      },
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
      birthDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      sex: {
        oneOf: [
          {
            const: 'MASCULINO',
            title: 'MASCULINO',
          },
          {
            const: 'FEMININO',
            title: 'FEMININO',
          },
          {
            const: null,
            title: '',
          },
        ],
        customErrors: '',
      },
      phoneNumber: {
        type: 'string',
        mask: '(99) 9999-9999',
        maskSelector: (value) => {
          const len = value.replace(/[\W_]/g, '').length;
          if (len > 10) return '(99) 99999-9999';
          else return '(99) 9999-9999';
        },
        customErrors: '',
        phone: '',
      },
      fit: { 
        type: 'boolean',
        customErrors: '',
      },
      fitJustification: { 
        type: 'string',
        customErrors: '',
      },
      treatmentId: {
        type: 'number',
        format: 'selectTreatmentDialog',
        customErrors: '',
      },
      requestingUnit: {
        format: 'selectClinicDialog',
        customErrors: '',
      },
      scheduling: {
        format: 'selectAppointmentDialog',
        customErrors: '',
      },
      update: {
        type: 'boolean',
        customErrors: '',
      },
      requestingUnitBoolean: {
        type: 'boolean',
        customErrors: '',
      },
      userUnit: {
        type: 'number',
        customErrors: '',
      },
      reschedulingJustification: {
        type: 'string',
        minLength: 5,
        customErrors: '',
      },
    },
    required: [
      'name',
      'birthDate',
      'sex',
      'phoneNumber',
      'treatmentId',
      'scheduling',
    ],
  },
  uischema: {
    type: 'Group',
    label: 'Cadastro de Agendamento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade Solicitante*',
            scope: '#/properties/requestingUnit',
          },
          {
            type: 'Control',
            label: 'Prontuário não informado',
            scope: '#/properties/recordNumberUninformed',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário*',
            scope: '#/properties/recordNumber',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/recordNumberUninformed',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/requestingUnitBoolean',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/requestingUnitBoolean',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data de Nascimento',
            scope: '#/properties/birthDate',
          },
          {
            type: 'Control',
            label: 'Sexo',
            scope: '#/properties/sex',
          },
          {
            type: 'Control',
            label: 'Telefone',
            scope: '#/properties/phoneNumber',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/requestingUnitBoolean',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Encaixe',
            scope: '#/properties/fit',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/isRadiology',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Justificativa de Encaixe',
            scope: '#/properties/fitJustification',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/fit',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tratamento',
            scope: '#/properties/treatmentId',
          },
          {
            type: 'Control',
            label: 'Data e Hora',
            scope: '#/properties/scheduling',
            rule: {
              effect: 'DISABLE',
              condition: {
                scope: '#/properties/treatmentId',
                schema: { enum: [null, undefined] },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/isRadiology',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tratamento',
            scope: '#/properties/treatmentId',
          },
          {
            type: 'Control',
            label: 'Data e Hora',
            scope: '#/properties/scheduling',
            rule: {
              effect: 'DISABLE',
              condition: {
                scope: '#/properties/treatmentId',
                schema: { enum: [null, undefined] },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/isRadiology',
            schema: {
              const: true,
            },
          },
        },
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Agendamento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário não informado',
            scope: '#/properties/recordNumberUninformed',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/recordNumberUninformed',
                schema: {
                  const: false,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'DISABLE',
          condition: {
            scope: '#',
            schema: {},
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário',
            scope: '#/properties/recordNumber',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/recordNumberUninformed',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'DISABLE',
          condition: {
            scope: '#',
            schema: {},
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
        rule: {
          effect: 'DISABLE',
          condition: {
            scope: '#',
            schema: {},
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data de Nascimento',
            scope: '#/properties/birthDate',
          },
          {
            type: 'Control',
            label: 'Sexo',
            scope: '#/properties/sex',
          },
          {
            type: 'Control',
            label: 'Telefone',
            scope: '#/properties/phoneNumber',
          },
        ],
        rule: {
          effect: 'DISABLE',
          condition: {
            scope: '#',
            schema: {},
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Encaixe',
            scope: '#/properties/fit',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/isRadiology',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Justificativa de Encaixe',
            scope: '#/properties/fitJustification',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/fit',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade Solicitante*',
            scope: '#/properties/requestingUnit',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Tratamento',
            scope: '#/properties/treatmentId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Data e Hora',
            scope: '#/properties/scheduling',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/isRadiology',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tratamento',
            scope: '#/properties/treatmentId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Data e Hora',
            scope: '#/properties/scheduling',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/isRadiology',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Justificativa de Reagendamento*',
            scope: '#/properties/reschedulingJustification',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/update',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/reschedule',
            schema: {
              const: false,
            },
          },
        },
      },
    ],
  },
  uischemaAlternative: {
    type: 'Group',
    label: 'Cadastro de Agendamento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tratamento',
            scope: '#/properties/treatmentId',
            options: {
              readonly: true
            }
          },
          {
            type: 'Control',
            label: 'Data e Hora',
            scope: '#/properties/scheduling',
            options: {
              readonly: true
            }
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário não informado',
            scope: '#/properties/recordNumberUninformed',
          },

        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário*',
            scope: '#/properties/recordNumber',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/recordNumberUninformed',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data de Nascimento',
            scope: '#/properties/birthDate',
          },
          {
            type: 'Control',
            label: 'Sexo',
            scope: '#/properties/sex',
          },
          {
            type: 'Control',
            label: 'Telefone',
            scope: '#/properties/phoneNumber',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    {
      id: 'patient.recordNumber',
      label: 'Prontuário',
      minWidth: 100,
      align: 'center',
    },
    { id: 'patient.name', label: 'Paciente', minWidth: 100, align: 'center' },
    {
      id: 'treatment.name',
      label: 'Tratamento',
      minWidth: 100,
      align: 'center',
    },
    { id: 'scheduling', label: 'Agendamento', minWidth: 100, align: 'center' },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
    {
      id: 'requestingUnit.name',
      label: 'Unidade Solicitante',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'RadiologyScheduleController@getAll',
    getAllByClinic: 'RadiologyScheduleController@getAllByClinic',
    getAllExecutedRadiology:
      'RadiologyScheduleController@getAllExecutedRadiology',
    getAllExecutedRadiologyByUnit:
      'RadiologyScheduleController@getAllExecutedRadiologyByUnit',
    findById: 'RadiologyScheduleController@findById',
    setOnAttendance: 'RadiologyScheduleController@setOnAttendance',
    setFinished: 'RadiologyScheduleController@setFinished',
    setMissed: 'RadiologyScheduleController@setMissed',
    create: 'RadiologyScheduleController@create',
    update: 'RadiologyScheduleController@patchUpdate',
    rescheduleRadiology: 'RadiologyScheduleController@rescheduleRadiology',
    delete: 'RadiologyScheduleController@delete',
  },
};

export default RadilogySchedule;
