/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Container,
  CancelButton,
  SubmitButton,
  DialogContent,
  Card,
  Dialog,
  DialogActions,
  ActionsContainer,
  HeaderContainer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CardHeader,
} from './styles';
import SelectSubstituteDialog from '../selectSubstituteDialogControl';
import first from 'lodash/first';

function AuditReplacementDialog({
                                  onSuccess,
                                  onError,
                                  onCancel,
                                  type,
                                  substituteDentist,
                                  replacedDentistId,
                                  ...props
                                }) {
  const [selectedType, setSelectedType] = useState(null);
  const [replacement, setReplacement] = useState(null);
  const [dentistScheduleTypes, setDentistScheduleTypes] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (substituteDentist) {
      const scheduleTypes = substituteDentist?.dentistScheduleTypes.filter(
        (x) =>
          x.type === 'Fixo' ||
          x.type === 'Procedimentos' ||
          x.type === 'Pacientes'
      ).map((x) => x.type);

      if (scheduleTypes.length > 1) {
        setSelectedType(type);
      } else {
        setSelectedType(first(scheduleTypes));
        setDisabled(true);
      }
      setDentistScheduleTypes(scheduleTypes);
    }
  }, [substituteDentist, type]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSuccess?.(selectedType, replacement);
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title="Agenda" />
              </HeaderContainer>
              <FormControl component="fieldset">
                <FormLabel component="legend" required>
                  Por qual tipo de agenda o procedimento será pago?
                </FormLabel>
                <RadioGroup
                  row={true}
                  aria-label="type"
                  name="type"
                  value={selectedType ?? null}
                  onChange={(e) => {
                    setReplacement(null);
                    setSelectedType(e.target.value);
                  }}
                >
                  {dentistScheduleTypes.includes('Procedimentos') ? (
                    <FormControlLabel
                      value="Procedimentos"
                      control={<Radio required />}
                      label="Procedimentos"
                      disabled={disabled}
                    />
                  ) : null}
                  {dentistScheduleTypes.includes('Pacientes') ? (
                    <FormControlLabel
                      value="Pacientes"
                      control={<Radio required />}
                      label="Pacientes"
                      disabled={disabled}
                    />
                  ) : null}
                  {dentistScheduleTypes.includes('Fixo') ? (
                    <FormControlLabel
                      value="Fixo"
                      control={<Radio required />}
                      label="Fixo"
                      disabled={disabled}
                    />
                  ) : null}
                </RadioGroup>
              </FormControl>
              {selectedType === 'Fixo' ? (
                <SelectSubstituteDialog
                  label="Selecione uma substituição"
                  substituteDentistId={substituteDentist?.userId}
                  replacedDentistId={replacedDentistId}
                  visible
                  enabled
                  required
                  onSuccess={(id) => setReplacement(id)}
                />
              ) : null}
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setReplacement(null);
                    setSelectedType(null);
                    onCancel?.();
                  }}
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Finalizar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default AuditReplacementDialog;
