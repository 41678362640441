import React, { useState, useEffect, useCallback, useRef } from 'react';
import RadiologySchedule from '../../../../../../forms/radiologySchedule';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import VoucherRadiology from '../../../../../../components/voucherRadiology';
import FilterTree from '../../../../../../helpers/filterTree';
import moment from 'moment';
import {
  Container,
  ViewShow,
  SelectContainer,
  Printer,
  PrintOS,
  CalendarSync
} from './styles';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useReactToPrint } from 'react-to-print';
import DateRangePickersComponent from '../../../../../../components/dateRangePickersComponent';

function CentralRadiologyScheduleList({ history }) {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ scheduling: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
  const [radiologySchedule, setRadiologySchedule] = useState(null);
  const componentRef = useRef();
  const [openMessage, setOpenMessage] = useState(false);

  const columns = RadiologySchedule.tableSchema;

  const loadRadiologySchedules = useCallback(() => {
    restApiClient.radiologySchedule
      .getAllExecutedRadiologyByUnit(
        user?.unitId,
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('scheduling', searchBy)
              .or()
              .like('patient.name', searchBy)
              .or()
              .like('patient.record_number', searchBy)
              .or()
              .like('treatment.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [
    rowsPerPage,
    page,
    orderBy,
    searchBy,
    selectedDateFrom,
    selectedDateTo,
  ]);

  const handleAlertClose = () => {
    setOpenMessage(false);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Encaminhamento`,
    content: () => {
      return componentRef.current;
    },
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }
      @media print { 
        grid {
          page-break-inside: avoid;
        }
      }`,
  });

  useEffect(() => {
    loadRadiologySchedules();
  }, [loadRadiologySchedules]);

  return (
    <Container>
      <CrudTable
        title={'Agendamentos da Central'}
        customHeaderComponent={
          <SelectContainer>
            <DateRangePickersComponent
              dateTo={selectedDateTo}
              dateFrom={selectedDateFrom}
              handleChange={(to, from) => {
                setSelectedDateTo(to);
                setSelectedDateFrom(from);
              }}
            />
          </SelectContainer>
        }
        modelActions={RadiologySchedule.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => history.push('/radiology/schedule/create'),
          },
          update: {
            onClick: (radiologyScheduleId) =>
              history.push(
                `/radiology/schedule/update/${radiologyScheduleId}/true`
              ),
            selector: (row) => row?.status === 'Agendado',
          },
          delete: {
            onClick: (radiologyScheduleId, index) => {
              restApiClient.radiologySchedule
                .delete(radiologyScheduleId)
                .then(() => {
                  const result = { ...rows };
                  if (index > -1) {
                    result.values.splice(index, 1);
                    result.count--;
                  }
                  setRows(result);
                });
            },
            selector: (row) => row?.status === 'Agendado',
          },
          view: {
            icon: <ViewShow />,
            onClick: (radiologyScheduleId, row) => {
              history.push({
                pathname: `/radiology/schedule/update/${radiologyScheduleId}/false`,
                state: {
                  isRadiology: true,
                  reschedule: !!row?.reschedulingJustification,
                  viewForm: true,
                },
              });
            },
            title: 'Visualizar agendamento',
            allowedActions: [RadiologySchedule.actions.update],
            selector: (row) => row?.status !== 'Agendado',
          },
          rescheduling: {
            icon: <CalendarSync />,
            onClick: (radiologyScheduleId) => {
              history.push({
                pathname: `/radiology/schedule/update/${radiologyScheduleId}/true`,
                state: {
                  reschedule: true,
                },
              });
            },
            title: 'Reagendamento',
            allowedActions: [RadiologySchedule.actions.rescheduleRadiology],
            selector: (row) => row?.status === 'Agendado',
          },
          printFowarding: {
            icon: <Printer />,
            onClick: async (radiologyScheduleId, row) => {
              await setRadiologySchedule(row);
              handlePrint();
            },
            title: 'Imprimir Encaminhamento',
          },
        }}
        customCellRenderers={{
          scheduling: {
            renderer: (field, value) => {
              const date = new Date(value);
              let options = {
                minute: 'numeric' || '2-digit',
                hour: 'numeric' || '2-digit',
                day: '2-digit' || 'numeric',
                month: '2-digit' || 'short',
                year: 'numeric' || '2-digit',
                hourCycle: 'h23',
              };
              return value
                ? `${date.toLocaleDateString('pt-br', options)}`
                : '-';
            },
          },
          'patient.recordNumber': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />

      <Snackbar
        open={openMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {'Agendamento atualizado com Sucesso'}
        </Alert>
      </Snackbar>

      <PrintOS ref={componentRef}>
        <VoucherRadiology crrRadiologySchedule={radiologySchedule} />
      </PrintOS>
    </Container>
  );
}

export default CentralRadiologyScheduleList;
