import styled from 'styled-components';
import { AddChart as MaterialAddChart } from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const AddChart = styled(MaterialAddChart)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;
