const Sale = {
  schema: {
    type: 'object',
    properties: {
      buyerId: {
        format: 'selectBuyerDialog',
        customErrors: '',
      },
    },
    required: ['buyerId'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Transferência',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Destinatário',
            scope: '#/properties/buyerId',
          },
        ],
      },
    ],
  },

  tableSchema: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'status', label: 'Situação', minWidth: 25, align: 'center' },
    { id: 'buyer', label: 'Destinatário', minWidth: 100, align: 'center' },
  ],
  SaleItemsTableSchema: [
    { id: 'item.name', label: 'Nome do Item', minWidth: 10, align: 'center' },
    { id: 'item.brand', label: 'Marca', minWidth: 10, align: 'center' },
    {
      id: 'item.itemLocators',
      label: 'Loc',
      minWidth: 10,
      align: 'center',
    },
    {
      id: 'item.stock',
      label: 'Estoque',
      minWidth: 10,
      align: 'center',
    },
  ],
  InstallmentTableSchema: [
    { id: 'number', label: 'Parcela', minWidth: 10, align: 'center' },
    { id: 'price', label: 'Valor', minWidth: 10, align: 'center' },
  ],
  actions: {
    findById: 'SaleController@findById',
    create: 'SaleController@create',
    getAll: 'SaleController@getAll',
    update: 'SaleController@patchUpdate',
    completeTransfer: 'SaleController@completeTransfer',
    delete: 'SaleController@delete',
    getAllBySaleId: 'SaleItemController@getAllBySaleId',
    createSaleItem: 'SaleItemController@create',
    deleteSaleItem: 'SaleItemController@delete',
  },
};

export default Sale;
