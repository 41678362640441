import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectDentistSpecialtyControl from '../../components/selectDentistSpecialtyDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectDentistSpecialtyControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) =>
      !isEmpty(schema) && schema.format === 'selectDentistSpecialtyDialog'
  )
);

const customSelectDentistSpecialtyControlTester = rankWith(
  4,
  isSelectDentistSpecialtyControl
);

export default {
  tester: customSelectDentistSpecialtyControlTester,
  renderer: withJsonFormsEnumProps(SelectDentistSpecialtyControl),
};
