const Origin = {
  actions: {
    create: 'OriginController@create',
    getAll: 'OriginController@getAll',
    getAllTree: 'OriginController@getAllTree',
    findById: 'OriginController@findById',
    update: 'OriginController@patchUpdate',
    delete: 'OriginController@delete',
  },
  listSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo de Despesa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'incomeExpenseType',
      label: 'Tipo de Receita',
      minWidth: 100,
      align: 'center',
    },
  ],
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
  ],
};

export default Origin;
