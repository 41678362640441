import axios from 'axios';
import { store } from '../store';
import { Creators } from '../store/ducks/auth';
import { objectReduce, objectDiff, objectToArray } from '../extensions/object';
import FileDownload from 'js-file-download';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_ADDR,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const buildSorterArray = (sortersObj) =>
  sortersObj
    ? objectReduce(
        sortersObj,
        (res, key, value) => {
          res += `&sorters[${key}]=${value}`;
          return res;
        },
        ''
      )
    : '';

const buildPatchFromDiff = (oldData, newData) => {
  const diff = objectDiff(oldData, newData);
  return objectToArray(diff, (key, value) => ({
    op: 'replace',
    path: `/${key}`,
    value: value,
  }));
};

function buildParams(take, skip, filters) {
  const params = {};
  if (take) {
    params['take'] = take;
  }
  if (skip) {
    params['skip'] = take;
  }
  if (filters) {
    params['filters'] = filters;
  }
  return params;
}

const restApiClient = {
  token: {
    get: async (login, password) => {
      const result = await client.post(
        '/api/auth/token',
        JSON.stringify({ login, password })
      );
      const authDto = result.data;
      return {
        token: authDto.token,
        refreshToken: authDto.refreshToken,
        tokenExpiration: new Date(Date.now() + authDto.expiresIn * 1000),
      };
    },
    refresh: async (refreshToken) => {
      const result = await client.post(
        '/api/auth/refresh',
        JSON.stringify({ refreshToken })
      );
      const authDto = result.data;
      return {
        token: authDto.token,
        refreshToken: authDto.refreshToken,
        tokenExpiration: new Date(Date.now() + authDto.expiresIn * 1000),
      };
    },
  },

  user: {
    profile: async () => {
      const result = await client.get('/api/auth/profile');
      return result.data;
    },
    changePassword: async (password, passwordConfirmation) => {
      const result = await client.post(
        '/api/user/password/change',
        JSON.stringify({ password, passwordConfirmation })
      );
      return result;
    },
  },

  employee: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/employee?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllLabEmployee: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/employee/get-all-lab-employee?' + buildSorterArray(sortersObj),
        {
          params: buildParams(take, skip, filters),
        }
      );
      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/employee/unit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllLabEmployeeWithBusyLevel: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/employee/get-all-lab-employee-with-busylvl?' +
          buildSorterArray(sortersObj),
        {
          params: buildParams(take, skip, filters),
        }
      );
      return result;
    },
    getCsvProduction: async (from, to) => {
      const path = `/api/employee/csv/production/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/employee/${id}`);
      return result;
    },
    create: async (employee) => {
      const result = await client.post(
        '/api/employee',
        JSON.stringify(employee)
      );
      return result;
    },
    update: async (id, oldEmployee, employee) => {
      const patchObj = buildPatchFromDiff(oldEmployee, employee);
      const result = await client.patch(
        `/api/employee/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    resetPassword: async (id) => {
      const result = await client.put(`/api/employee/reset-password/${id}`);
      return result;
    },
    updateRoles: async (id, added, removed) => {
      const result = await client.patch(
        `/api/employee/${id}/roles`,
        JSON.stringify([
          {
            op: 'add',
            path: '/roles',
            value: added,
          },
          {
            op: 'remove',
            path: '/roles',
            value: removed,
          },
        ])
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/employee/${id}`);
      return result;
    },
    restore: async (id) => {
      const result = await client.put(`/api/employee/restore/${id}`);
      return result;
    },
  },

  unit: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/unit?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithConsumptions: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/unit/consumptions?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllCritical: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/unit/critical?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByType: async (
      type,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/unit/type/${type}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/unit/${id}`);
      return result;
    },
    create: async (unit) => {
      const result = await client.post('/api/unit', JSON.stringify(unit));
      return result;
    },
    update: async (id, oldUnit, unit) => {
      const patchObj = buildPatchFromDiff(oldUnit, unit);
      const result = await client.patch(
        `/api/unit/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/unit/${id}`);
      return result;
    },
  },

  role: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/role?' + buildSorterArray(sortersObj),
        {
          params: buildParams(take, skip, filters),
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/role/${id}`);
      return result;
    },
    create: async (role) => {
      const result = await client.post('/api/role', JSON.stringify(role));
      return result;
    },
    update: async (id, oldRole, role) => {
      const patchObj = buildPatchFromDiff(oldRole, role);
      const result = await client.patch(
        `/api/role/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/role/${id}`);
      return result;
    },
    updateActions: async (id, added, removed) => {
      const result = await client.patch(
        `/api/role/${id}`,
        JSON.stringify([
          {
            op: 'add',
            path: '/actions',
            value: added.map((x) => ({
              actionName: x,
            })),
          },
          {
            op: 'remove',
            path: '/actions',
            value: removed,
          },
        ])
      );
      return result;
    },
  },

  group: {
    findById: async (id) => {
      const result = await client.get(`/api/group/${id}`);
      return result;
    },
    create: async (group) => {
      const result = await client.post('/api/group', JSON.stringify(group));
      return result;
    },
    update: async (id, oldGroup, group) => {
      const patchObj = buildPatchFromDiff(oldGroup, group);
      const result = await client.patch(
        `/api/group/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/group/get-all/with-filter?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllAsTree: async () => {
      const result = await client.get('/api/group/tree');
      return result;
    },
    getAllAsGroup: async () => {
      const result = await client.get('/api/group');
      return result;
    },
    getAllChildrenByParentId: async (id) => {
      const result = await client.get(`/api/group/parent/${id}`);
      return result;
    },
    getTypeById: async (id) => {
      const result = await client.get(`/api/group/type/${id}`);
      return result;
    },
  },

  action: {
    getAll: async () => {
      const result = await client.get('/api/action');
      return result;
    },
  },

  address: {
    getAllWithSupplier: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/address/supplier?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findWithSupplierById: async (id) => {
      const result = await client.get(`/api/address/supplier/${id}`);
      return result;
    },
  },

  supplier: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/supplier?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/supplier/${id}`);
      return result;
    },
    findByCnpj: async (cnpj) => {
      const result = await client.get(`/api/supplier/cnpj/${cnpj}`);
      return result;
    },
    create: async (supplier) => {
      const result = await client.post(
        '/api/supplier',
        JSON.stringify(supplier)
      );
      return result;
    },
    update: async (id, oldSupplier, supplier, added, removed) => {
      const patchObj = buildPatchFromDiff(oldSupplier, supplier);
      const arrayPatchObject = [
        {
          op: 'add',
          path: '/bankAccounts',
          value: added,
        },
        {
          op: 'remove',
          path: '/bankAccounts',
          value: removed,
        },
      ];
      const result = await client.patch(
        `/api/supplier/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObject))
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/supplier/${id}`);
      return result;
    },
    restore: async (id) => {
      const result = await client.put(`/api/supplier/restore/${id}`);
      return result;
    },
  },

  item: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/item?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithSaleAvg: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/item/with-sale-avg?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByUnit: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/item/byunit?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByUnitId: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/unit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByClinic: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        `/api/item/clinic?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWhereHasStockInDistributionCenter: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/order/inStock?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllInStock: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/item/inStock?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithItemsByGroup: async (
      unitId,
      groupId,
      budgetId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/get-all-with-items-by-group/${unitId}/${groupId}/${budgetId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getCsvItemInStockByUnit: async (unitId) => {
      const result = await client.get(`/api/item/csv/unit/${unitId}`);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getCsvItemInStockByUnitAndGroup: async (unitId, groupId) => {
      const result = await client.get(
        `/api/item/csv/unit/${unitId}/group/${groupId}`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getCsvItemInClinicStockByGroup: async (groupId) => {
      const result = await client.get(
        `/api/item/csv/clinic/stock/group/${groupId}`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getAllItemInStockByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/stock/unit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllItemInStockByClinic: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/stock/clinic?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllItemInStockByUnitAndGroup: async (
      unitId,
      groupId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/stock/unit/${unitId}/group/${groupId}` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllItemInClinicStockByGroup: async (
      groupId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/clinic/stock/group/${groupId}` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllPricePending: async (
      purchaseId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/pending/price/${purchaseId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithStock: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/byunit/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithStockClinic: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/item/byclinic?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithoutBoxByUnit: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/item/without-box?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getCsvCustomerPriceList: async (groupId) => {
      const path = groupId
        ? `/api/item/csv/customerPriceList/group/${groupId}?`
        : `/api/item/csv/customerPriceList?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    updatePrice: async (id, price) => {
      const result = await client.put(
        `/api/item/${id}/price`,
        JSON.stringify({ price })
      );
      return result;
    },
    updatePrices: async (itens) => {
      const result = await client.put(
        `/api/item/updatePrices`,
        JSON.stringify(itens)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/item/${id}`);
      return result;
    },
    findByIdAndUnit: async (id, unit) => {
      const result = await client.get(
        `/api/item/locator/id=${id}&unitId=${unit}`
      );
      return result;
    },
    create: async (item) => {
      const result = await client.post('/api/item', JSON.stringify(item));
      return result;
    },
    createByPendentEntry: async (id, item) => {
      const result = await client.post(
        `/api/item/pending/entry/${id}`,
        JSON.stringify(item)
      );
      return result;
    },
    update: async (id, oldItem, item, added, removed) => {
      const arrayPatchObj = [
        {
          op: 'add',
          path: '/barcode',
          value: added.map((x) => ({
            barcode: x,
            autoBarcode: false,
          })),
        },
        {
          op: 'remove',
          path: '/barcode',
          value: removed,
        },
      ];
      const patchObj = buildPatchFromDiff(oldItem, item);
      const result = await client.patch(
        `/api/item/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObj))
      );
      return result;
    },
    updateAutoBarcode: async (id) => {
      const barcodeValue = ('0000000000000' + id).slice(-13);
      const patchObj = [
        {
          op: 'add',
          path: '/barcode',
          value: {
            barcode: barcodeValue,
            autoBarcode: true,
          },
        },
      ];
      const result = await client.patch(
        `/api/item/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    restore: async (id) => {
      const result = await client.put(`/api/item/restore/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/item/${id}`);
      return result;
    },
  },
  budgetItem: {
    getAllByBudgetId: async (
      budgetId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/budgetItem/budget/${budgetId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  product: {
    findById: async (id) => {
      const result = await client.get(`/api/product/${id}`);
      return result;
    },
    create: async (product) => {
      const result = await client.post('/api/product', JSON.stringify(product));
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/product?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByUnitId: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/product/byunit/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllCriticalByUnit: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/product/critical/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithItems: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/product/get-all-with-items/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithItemsClinic: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/product/get-all-with-items-clinic?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWhereHasStockInDistributionCenter: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/product/stockInDistributionCenter?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllInStockClinic: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/product/clinic/get-all-in-stock?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllInClinic: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        `/api/product/get-all-in-clinic?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithItemsByGroup: async (
      unitId,
      groupId,
      budgetId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/product/get-all-with-items-by-group/${unitId}/${groupId}/${budgetId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    update: async (id, oldProduct, product) => {
      const patchObj = buildPatchFromDiff(oldProduct, product);
      const result = await client.patch(
        `/api/product/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    updatePrice: async (products) => {
      const result = await client.put(
        `/api/product/update-prices`,
        JSON.stringify(products)
      );
      return result;
    },
    findByIdAndUnit: async (id, unit) => {
      const result = await client.get(
        `/api/product/locator/id=${id}&unitId=${unit}`
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/product/${id}`);
      return result;
    },
  },

  productLocator: {
    findById: async (id) => {
      const result = await client.get(`/api/productLocator/${id}`);
      return result;
    },
    create: async (productLocator) => {
      const result = await client.post(
        '/api/productLocator',
        JSON.stringify(productLocator)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/productLocator?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    update: async (id, oldProductLocator, productLocator) => {
      const patchObj = buildPatchFromDiff(oldProductLocator, productLocator);
      const result = await client.patch(
        `/api/productLocator/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    updateLocatorClinic: async (stock) => {
      const result = await client.put(
        '/api/productLocator/clinic/locator',
        JSON.stringify(stock)
      );
      return result;
    },

    delete: async (id) => {
      const result = await client.delete(`/api/productLocator/${id}`);
      return result;
    },
  },

  itemLocator: {
    findById: async (id) => {
      const result = await client.get(`/api/itemLocator/${id}`);
      return result;
    },
    create: async (productLocator) => {
      const result = await client.post(
        '/api/itemLocator',
        JSON.stringify(productLocator)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/itemLocator?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    update: async (id, oldItemLocator, itemLocator) => {
      const patchObj = buildPatchFromDiff(oldItemLocator, itemLocator);
      const result = await client.patch(
        `/api/itemLocator/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/itemLocator/${id}`);
      return result;
    },
  },

  stockConfig: {
    get: async () => {
      const result = await client.get('/api/stock-config');
      return result;
    },
    update: async (id, oldStock, stock) => {
      const patchObj = buildPatchFromDiff(oldStock, stock);
      const result = await client.patch(
        `/api/stock-config`,
        JSON.stringify(patchObj)
      );
      return result;
    },
  },

  entry: {
    create: async (entry) => {
      const result = await client.post('/api/entry', JSON.stringify(entry));
      return result;
    },
    getAllByPurchase: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/entry/bypurchase/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByItem: async (
      itemId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/entry/item/${itemId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findUnregisteredItemPendingById: async (id) => {
      const result = await client.get(
        `/api/entry/${id}/pending/unregisteredItem`
      );
      return result;
    },
    getAllUnregisteredItem: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/entry/pending/unregisteredItem?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllUnregisteredItemByPurchaseId: async (
      purchaseId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/entry/pending/unregisteredItem/${purchaseId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },

  itemWithdraw: {
    withdrawItems: async (itemsWithdraw) => {
      const result = await client.post(
        '/api/itemWithdraw',
        JSON.stringify(itemsWithdraw)
      );
      return result;
    },
    withdrawItem: async (itemWithdraw) => {
      const result = await client.post(
        '/api/itemWithdraw/clinic',
        JSON.stringify(itemWithdraw)
      );
      return result;
    },
    getCsvWithdrawReportByUnit: async (from, to, unitId) => {
      const path = unitId
        ? `/api/itemWithdraw/csv/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/itemWithdraw/csv/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getItemWithdrawReportByUnit: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const path = unitId
        ? `/api/itemWithdraw/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/itemWithdraw/period/${from}/to/${to}?`;
      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
  },

  purchase: {
    create: async (purchase) => {
      const result = await client.post(
        '/api/purchase',
        JSON.stringify(purchase)
      );
      return result;
    },
    merge: async (purchaseIds) => {
      const result = await client.post(
        '/api/purchase/merge',
        JSON.stringify({ purchaseIds })
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/purchase/${id}`);
      return result;
    },
    update: async (id, oldBudget, budget, added, removed) => {
      const patchObj = buildPatchFromDiff(oldBudget, budget);
      const result = await client.patch(
        `/api/purchase/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAllPending: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/purchase/pending?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    submitInvoice: async (purchaseId, file, onUploadProgress) => {
      let formData = new FormData();
      formData.append('invoice', file);
      const result = await client.post(
        `/api/purchase/${purchaseId}/invoice`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        }
      );
      return result;
    },
    getAllAvailableToMerge: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/purchase/${id}/mergeable?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithUnit: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/purchase/byunit?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithPendenciesUnregisteredItem: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/purchase/pendencies/unregistered?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithPendenciesItemPrice: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/purchase/pendencies/price?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    checkout: async (id) => {
      const result = await client.post(`/api/purchase/checkout/${id}`);
      return result;
    },
  },

  budget: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/budget?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllBudgetsInEditing: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/budget/get-all/editing?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/budget/${id}`);
      return result;
    },
    costShare: async (id, value) => {
      const result = await client.put(
        `/api/budget/${id}/costShare`,
        JSON.stringify({ value })
      );
      return result;
    },
    makePurchase: async (id) => {
      const result = await client.put(`/api/budget/${id}/purchase`);
      return result;
    },
    create: async (budget) => {
      const result = await client.post('/api/budget', JSON.stringify(budget));
      return result;
    },
    update: async (id, oldBudget, budget, added, removed, setNull) => {
      let arrayPatchObj = [
        {
          op: 'add',
          path: '/budgetItems',
          value: added,
        },
        {
          op: 'remove',
          path: '/budgetItems',
          value: removed,
        },
      ];
      if (setNull) {
        const searchGroupIdPatch = [
          {
            op: 'replace',
            path: '/searchGroupId',
          },
        ];
        arrayPatchObj = arrayPatchObj.concat(searchGroupIdPatch);
      }
      const patchObj = buildPatchFromDiff(oldBudget, budget);
      const result = await client.patch(
        `/api/budget/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObj))
      );
      return result;
    },
    getCsvCosts: async (id) => {
      const result = await client.get(`/api/budget/csv/costs/${id}`, {
        responseType: 'blob',
      });
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    importItems: async (id, model) => {
      const result = await client.put(
        `/api/budget/${id}/importItems`,
        JSON.stringify(model)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/budget/${id}`);
      return result;
    },
  },
  order: {
    getAllByUnit: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/order/byunit?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllTransferOrders: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/order/transfer-orders?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    create: async (order) => {
      const result = await client.post('/api/order', JSON.stringify(order));
      return result;
    },
    suggestedTransfer: async (unitId, transferTypeId) => {
      const result = await client.post(
        `/api/order/suggested/${unitId}/transferType/${transferTypeId}`
      );
      return result;
    },
    submitOrder: async (id) => {
      const result = await client.put(`/api/order/submit-order/${id}`);
      return result;
    },
    separateOrder: async (id) => {
      const result = await client.put(`/api/order/separate-order/${id}`);
      return result;
    },
    approveTransfer: async (id) => {
      const result = await client.put(`/api/order/approve-transfer/${id}`);
      return result;
    },
    approveTransferItem: async (id) => {
      const result = await client.put(`/api/order/approveTransfer/${id}`);
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/order/${id}`);
      return result;
    },
    findByIdByEmployee: async (id) => {
      const result = await client.get(`/api/order/employee/${id}`);
      return result;
    },
    updateByClinic: async (id, oldOrderItem, orderItem, added, removed) => {
      const arrayPatchObj = [
        {
          op: 'add',
          path: '/orderItem',
          value: added,
        },
        {
          op: 'remove',
          path: '/orderItem',
          value: removed,
        },
      ];
      const patchObj = buildPatchFromDiff(oldOrderItem, orderItem);
      const result = await client.patch(
        `/api/order/clinic/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObj))
      );
      return result;
    },
    updateByStockItem: async (
      id,
      oldOrderItem,
      orderItem,
      added,
      removed,
      edited
    ) => {
      const editedPatchObject = edited.map((orderItem) => ({
        op: 'patch',
        path: `/orderItem/${orderItem.id}`,
        value: [
          {
            op: 'replace',
            path: 'approvedQuantity',
            value: orderItem.approvedQuantity,
          },
        ],
      }));

      const arrayPatchObject = [
        {
          op: 'add',
          path: '/orderItem',
          value: added,
        },
        {
          op: 'remove',
          path: '/orderItem',
          value: removed,
        },
      ];

      const patchObj = buildPatchFromDiff(oldOrderItem, orderItem);

      const result = await client.patch(
        `/api/order/stock/${id}`,
        JSON.stringify([...patchObj, ...arrayPatchObject, ...editedPatchObject])
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/order/${id}`);
      return result;
    },
  },

  orderItem: {
    getAllByOrder: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderItem/byorder/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByOrderWithItemStock: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderItem/stock/byorder/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/orderItem?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/orderItem/${id}`);
      return result;
    },
    create: async (orderItem) => {
      const result = await client.post(
        '/api/orderItem',
        JSON.stringify(orderItem)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/orderItem/${id}`);
      return result;
    },
  },
  stock: {
    updateLocatorClinic: async (stock) => {
      const result = await client.put(
        '/api/stock/clinic/locator',
        JSON.stringify(stock)
      );
      return result;
    },
    updateLocatorStock: async (stock) => {
      const result = await client.put(
        '/api/stock/stock/locator',
        JSON.stringify(stock)
      );
      return result;
    },
    getStockValueByUnitId: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const path = unitId
        ? `/api/stock/value/unit/${unitId}?`
        : `/api/stock/value?`;
      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
  },
  stockHistory: {
    create: async (order) => {
      const result = await client.post(
        '/api/stock-history',
        JSON.stringify(order)
      );
      return result;
    },
    getAllStockHistoriesValuesByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const path = unitId
        ? `/api/stock-history/unit/${unitId}/values/${from}/to/${to}?`
        : `/api/stock-history/values/${from}/to/${to}?`;
      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    getCsvStockAdjustmentHistoryReportByUnit: async (from, to, unitId) => {
      const path = unitId
        ? `/api/stock-history/csv/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/stock-history/csv/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
  },
  purchaseItem: {
    getAllByPurchaseId: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/purchaseItem/purchase/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  budgetFile: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/budgetFile?' + buildSorterArray(sortersObj),
        {
          params: buildParams(take, skip, filters),
        }
      );
      return result;
    },
    getAllByBudgetId: async (
      budgetId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/budgetFile/budget/${budgetId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    submitFile: async (file, budgetId, onUploadProgress) => {
      let formData = new FormData();
      formData.append('budgetFile', file);
      formData.append('budgetId', budgetId);
      const result = await client.post('/api/budgetFile/file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });
      return result;
    },
    downloadFile: async (id) => {
      const result = await client.get(`/api/budgetFile/file/download/${id}`, {
        responseType: 'blob',
      });
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(result.data, filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''));
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/budgetFile/${id}`);
      return result;
    },
  },
  purchaseEntry: {
    create: async (purchaseEntry) => {
      const result = await client.post(
        '/api/purchaseEntry',
        JSON.stringify(purchaseEntry)
      );
      return result;
    },
    getAllByPurchaseId: async (
      purchaseId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/purchaseEntry/purchase/${purchaseId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    downloadInvoice: async (id) => {
      const result = await client.get(
        `/api/purchaseEntry/${id}/invoice/download`,
        {
          responseType: 'blob',
        }
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(result.data, filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''));
      return result;
    },
  },
  purchaseEntryItem: {
    manualEntry: async (purchaseEntryId, entryItem) => {
      const result = await client.post(
        `/api/entry/${purchaseEntryId}`,
        JSON.stringify(entryItem)
      );
      return result;
    },
  },
  purchaseFile: {
    getAllByPurchaseId: async (
      purchaseId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/purchaseFile/purchase/${purchaseId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    submitFile: async (file, purchaseId, onUploadProgress) => {
      let formData = new FormData();
      formData.append('purchaseFile', file);
      formData.append('purchaseId', purchaseId);
      const result = await client.post('/api/purchaseFile/file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });
      return result;
    },
    downloadFile: async (id) => {
      const result = await client.get(`/api/purchaseFile/file/download/${id}`, {
        responseType: 'blob',
      });
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(result.data, filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''));
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/purchaseFile/${id}`);
      return result;
    },
  },
  dentist: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/dentist?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentist/${id}`);
      return result;
    },
    findByCpf: async (cpf) => {
      const result = await client.get(`/api/dentist/cpf/${cpf}`);
      return result;
    },
    create: async (dentist) => {
      const result = await client.post('/api/dentist', JSON.stringify(dentist));
      return result;
    },
    updateDentistProcedure: async (id, oldDentist, dentist, added, removed) => {
      const patchObj = buildPatchFromDiff(oldDentist, dentist);
      const arrayPatchObject = [
        {
          op: 'add',
          path: '/dentistProcedurePrices',
          value: added,
        },
        {
          op: 'remove',
          path: '/dentistProcedurePrices',
          value: removed,
        },
      ];
      const result = await client.patch(
        `/api/dentist/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObject))
      );
      return result;
    },
    update: async (
      id,
      oldDentist,
      dentist,
      added,
      removed,
      isSpecialties = false
    ) => {
      let dentistScheduleTypesPatchObject = [];
      if (!isEmpty(dentist.dentistScheduleTypes)) {
        dentistScheduleTypesPatchObject = [
          {
            op: 'add',
            path: '/dentistScheduleTypes',
            value: get(dentist, 'dentistScheduleTypes').added,
          },
          {
            op: 'remove',
            path: '/dentistScheduleTypes',
            value: get(dentist, 'dentistScheduleTypes').removed,
          },
        ];
      }
      const patchObj = buildPatchFromDiff(oldDentist, dentist);
      const arrayPatchObject = isSpecialties
        ? [
            {
              op: 'add',
              path: '/dentistSpecialties',
              value: added,
            },
            {
              op: 'remove',
              path: '/dentistSpecialties',
              value: removed,
            },
          ]
        : [
            {
              op: 'add',
              path: '/dentistBankAccounts',
              value: added,
            },
            {
              op: 'remove',
              path: '/dentistBankAccounts',
              value: removed,
            },
          ];
      const result = await client.patch(
        `/api/dentist/${id}`,
        JSON.stringify(
          patchObj
            .concat(arrayPatchObject)
            .concat(dentistScheduleTypesPatchObject)
        )
      );
      return result;
    },
    updateFavoriteUnit: async (id, favoriteUnitId) => {
      const result = await client.put(
        `/api/dentist/update-favorite-unit/${id}`,
        JSON.stringify({ favoriteUnitId })
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/dentist/${id}`);
      return result;
    },
    resetPassword: async (id) => {
      const result = await client.put(`/api/dentist/reset-password/${id}`);
      return result;
    },
    restore: async (id) => {
      const result = await client.put(`/api/dentist/restore/${id}`);
      return result;
    },
  },
  buyer: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/buyer?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/buyer/${id}`);
      return result;
    },
    create: async (dentist) => {
      const result = await client.post('/api/buyer', JSON.stringify(dentist));
      return result;
    },
    update: async (id, oldBuyer, buyer, added, removed) => {
      const patchObj = buildPatchFromDiff(oldBuyer, buyer);
      const arrayPatchObject = [
        {
          op: 'add',
          path: '/buyerAddresses',
          value: added.map((x) => ({
            cep: x.cep,
            address: x.address,
            number: x.number,
            complement: x.complement ? x.complement : undefined,
            neighborhood: x.neighborhood,
            city: x.city,
            state: x.state,
          })),
        },
        {
          op: 'remove',
          path: '/buyerAddresses',
          value: removed,
        },
      ];
      const result = await client.patch(
        `/api/buyer/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObject))
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/buyer/${id}`);
      return result;
    },
  },
  buyerAddress: {
    getAllByBuyerId: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/buyerAddress/bybuyer/${id}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/buyerAddress/${id}`);
      return result;
    },
  },
  profitMarginCategory: {
    update: async (group) => {
      if (isNaN(group?.profitMargin)) group.profitMargin = null;
      const result = await client.put(
        `/api/profitMarginCategory/update/profit-margin`,
        JSON.stringify(group)
      );
      return result;
    },
  },
  profitMarginIndividual: {
    update: async (items) => {
      items = items.map((x) => ({
        itemId: x.itemId,
        profitMargin: !isNaN(x.profitMargin) ? x.profitMargin : null,
      }));
      const result = await client.put(
        `/api/profitMarginIndividual/update/profit-margin`,
        JSON.stringify(items)
      );
      return result;
    },
  },
  transfer: {
    getAllGroupValuesByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const path = unitId
        ? `/api/transfer/unit/${unitId}/group/period/${from}/to/${to}?`
        : `/api/transfer/group/period/${from}/to/${to}?`;
      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    getCsvTransferredItems: async (from, to, unitId) => {
      const path = `/api/transfer/transferred-items/csv/unit/${unitId}/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getCsvTransferredItemsInClinic: async (from, to) => {
      const result = await client.get(
        `/api/transfer/transferred-items/csv/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getAllGroupValuesInClinicByPeriod: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/transfer/csv/consumption-clinic/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  sale: {
    findById: async (id) => {
      const result = await client.get(`/api/sale/${id}`);
      return result;
    },
    create: async (sale) => {
      const result = await client.post('/api/sale', JSON.stringify(sale));
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/sale?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    update: async (id, oldSale, sale, added, removed) => {
      const arrayPatchObj = [
        {
          op: 'add',
          path: '/saleItems',
          value: added,
        },
        {
          op: 'remove',
          path: '/saleItems',
          value: removed,
        },
      ];
      const patchObj = buildPatchFromDiff(oldSale, sale);
      const result = await client.patch(
        `/api/sale/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObj))
      );
      return result;
    },
    completeTransfer: async (id, sale) => {
      const result = await client.put(
        `/api/sale/complete-transfer/${id}`,
        JSON.stringify(sale)
      );
      return result;
    },
    getCsvCompletedSalesReport: async (from, to) => {
      const result = await client.get(
        `/api/sale/csv/completed-sales/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getCompletedSalesReport: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/sale/completed-sales/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/sale/${id}`);
      return result;
    },
  },
  saleItem: {
    create: async (saleItem) => {
      const result = await client.post(
        '/api/saleItem',
        JSON.stringify(saleItem)
      );
      return result;
    },
    getAllBySaleId: async (
      saleId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/saleItem/sale/${saleId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/saleItem/${id}`);
      return result;
    },
  },
  paymentSale: {
    create: async (payment) => {
      const result = await client.post(
        '/api/paymentSale',
        JSON.stringify(payment)
      );
      return result;
    },
  },
  endProduct: {
    create: async (balance) => {
      const result = await client.post(
        '/api/endProduct',
        JSON.stringify(balance)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/endProduct/${id}`);
      return result;
    },
    getAll: async (
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/endProduct?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    update: async (id, oldEndProduct, endProduct, added, removed) => {
      const arrayPatchObj = [
        {
          op: 'add',
          path: '/workflows',
          value: added.map((x) => ({
            stepId: x.stepId,
            position: x.position,
          })),
        },
        {
          op: 'remove',
          path: '/workflows',
          value: removed,
        },
      ];
      const patchObj = buildPatchFromDiff(oldEndProduct, endProduct);
      const result = await client.patch(
        `/api/endProduct/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObj))
      );
      return result;
    },
    getFinishedProducts: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/endProduct/report/finished-products/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  toothType: {
    create: async (balance) => {
      const result = await client.post(
        '/api/toothType',
        JSON.stringify(balance)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/toothType/${id}`);
      return result;
    },
    getAll: async (
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/toothType?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    update: async (id, oldToothType, toothType, added, removed) => {
      const arrayPatchObj = [
        {
          op: 'add',
          path: '/toothShades',
          value: added.map((x) => ({
            name: x,
          })),
        },
        {
          op: 'remove',
          path: '/toothShades',
          value: removed,
        },
      ];
      const patchObj = buildPatchFromDiff(oldToothType, toothType);
      const result = await client.patch(
        `/api/toothType/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObj))
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/toothType/${id}`);
      return result;
    },
  },
  toothShade: {
    findById: async (id) => {
      const result = await client.get(`/api/toothShade/${id}`);
      return result;
    },
    getAllByToothTypeId: async (
      toothTypeId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/toothShade/toothType/${toothTypeId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  step: {
    findById: async (id) => {
      const result = await client.get(`/api/step/${id}`);
      return result;
    },
    create: async (step) => {
      const result = await client.post('/api/step', JSON.stringify(step));
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/step?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    update: async (id, oldStep, step, added, removed) => {
      const patchObj = buildPatchFromDiff(oldStep, step);
      const arrayPatchObject = [
        {
          op: 'add',
          path: '/suggestedExecutors',
          value: added,
        },
        {
          op: 'remove',
          path: '/suggestedExecutors',
          value: removed,
        },
      ];
      const result = await client.patch(
        `/api/step/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObject))
      );
      return result;
    },
  },
  section: {
    findById: async (id) => {
      const result = await client.get(`/api/section/${id}`);
      return result;
    },
    create: async (section) => {
      const result = await client.post('/api/section', JSON.stringify(section));
      return result;
    },
    update: async (id, oldSection, section) => {
      const patchObj = buildPatchFromDiff(oldSection, section);
      const result = await client.patch(
        `/api/section/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/section?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllBranches: async () => {
      const result = await client.get('/api/section/branch');
      return result;
    },
    getAllChildrenByParentId: async (id) => {
      const result = await client.get(`/api/section/parent/${id}`);
      return result;
    },
    getTypeById: async (id) => {
      const result = await client.get(`/api/section/type/${id}`);
      return result;
    },
  },
  calendar: {
    create: async (calendar) => {
      const result = await client.post(
        '/api/calendar',
        JSON.stringify(calendar)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/calendar/${id}`);
      return result;
    },
    getAll: async (
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/calendar?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  holiday: {
    create: async (holiday) => {
      const result = await client.post('/api/holiday', JSON.stringify(holiday));
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/holiday/${id}`);
      return result;
    },
    getPastDayAvaliable: async (unitId) => {
      const result = await client.get(`/api/holiday/pastDay/${unitId}`);
      return result;
    },
    getAll: async (
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/holiday?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllRadiologyByPeriod: async (
      from,
      to,
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/holiday/radiology/period/${from}/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByCityAndYear: async (
      unitId,
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/holiday/city/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/holiday/${id}`);
      return result;
    },
    getAllByCalendarId: async (
      calendarId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/holiday/calendar/${calendarId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  recess: {
    create: async (recess) => {
      const result = await client.post('/api/recess', JSON.stringify(recess));
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/recess/${id}`);
      return result;
    },
    getAll: async (
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/recess?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByPeriod: async (
      from,
      to,
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/recess/period/${from}/${to}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/recess/${id}`);
      return result;
    },
  },
  labConfig: {
    getAll: async () => {
      const result = await client.get('/api/lab-config');
      return result;
    },
    update: async (id, oldLabConfig, labConfig) => {
      const patchObj = buildPatchFromDiff(oldLabConfig, labConfig);
      const result = await client.patch(
        `/api/lab-config`,
        JSON.stringify(patchObj)
      );
      return result;
    },
  },
  orderService: {
    findById: async (id) => {
      const result = await client.get(`/api/orderService/${id}`);
      return result;
    },
    create: async (step) => {
      const result = await client.post(
        '/api/orderService',
        JSON.stringify(step)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/orderService?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByClinic: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderService/byunit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getCurrentPatientReturnDateByPatientId: async (patientId) => {
      const result = await client.get(`/api/orderService/return/${patientId}`);
      return result;
    },
    getAllOrderServiceRefused: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderServiceStep/get-all/refused?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getReworkHistory: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderService/report/rework-history/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/orderService/${id}`);
      return result;
    },
  },
  orderServiceStep: {
    findById: async (id) => {
      const result = await client.get(`/api/orderServiceStep/${id}`);
      return result;
    },
    create: async (step) => {
      const result = await client.post(
        '/api/orderServiceStep',
        JSON.stringify(step)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/orderServiceStep?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByOrderServiceId: async (
      orderServiceId,
      sortersObj = null,
      take,
      skip,
      filters = null
    ) => {
      const result = await client.get(
        `/api/orderServiceStep/orderService/${orderServiceId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllOrderServiceStepByDentistId: async (
      dentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderServiceStep/get-all/dentist/${dentistId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllOrderServiceStepPendingByUnitId: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderServiceStep/get-all/pending/${unitId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllOrderServiceStepSended: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderServiceStep/get-all/sended?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllOrderServiceStepQueued: async (
      executorId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      let path;

      if (executorId === 'all') {
        path = `/api/orderServiceStep/get-all/queued/all?`;
      } else if (executorId === 'outsourceds') {
        path = `/api/orderServiceStep/get-all/queued/outsourceds/${true}?`;
      } else {
        path = `/api/orderServiceStep/get-all/queued/${executorId}?`;
      }

      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    getAllOrderServiceStepCompletedLaboratory: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderServiceStep/get-all/completed-laboratory?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getCsvPendingSteps: async () => {
      const path = `/api/orderServiceStep/csv/clinic/pendingSteps?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getFinishedLabStepsInClinicCSVReport: async (from, to) => {
      const path = `/api/orderServiceStep/csv/clinic/finishedSteps/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getAllOSFinishedLabByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const path = unitId
        ? `/api/orderServiceStep/report/section/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/orderServiceStep/report/section/period/${from}/to/${to}?`;
      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    getLabCostInClinicByPeriod: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/orderServiceStep/report/labCostClinic/section/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getFinishedLabStepsCsvReport: async (from, to, unitId) => {
      const path = unitId
        ? `/api/orderServiceStep/csv/finishedSteps/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/orderServiceStep/csv/finishedSteps/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getCsvStepFlow: async (from, to) => {
      const path = `/api/orderServiceStep/csv/stepFlow/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    update: async (id, oldOrderServiceStep, orderServiceStep) => {
      let patchObj = buildPatchFromDiff(oldOrderServiceStep, orderServiceStep);
      patchObj = patchObj.map((item) => {
        if (item.value === 'null') {
          item.value = null;
        }
        return item;
      });
      const result = await client.patch(
        `/api/orderServiceStep/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/orderServiceStep/${id}`);
      return result;
    },
    setSubmitted: async (id) => {
      const result = await client.put(`/api/orderServiceStep/submitted/${id}`);
      return result;
    },
    setQueued: async (id) => {
      const result = await client.put(`/api/orderServiceStep/queued/${id}`);
      return result;
    },
    setInTransit: async (id) => {
      const result = await client.put(`/api/orderServiceStep/transit/${id}`);
      return result;
    },
    setSended: async (id) => {
      const result = await client.put(`/api/orderServiceStep/sended/${id}`);
      return result;
    },
    setSkippedLab: async (id) => {
      const result = await client.put(`/api/orderServiceStep/skipped/${id}`);
      return result;
    },
    setRejected: async (id, obj) => {
      const result = await client.put(
        `/api/orderServiceStep/rejected/${id}`,
        JSON.stringify(obj)
      );
      return result;
    },
    setDentistRefused: async (id, obj) => {
      const result = await client.put(
        `/api/orderServiceStep/dentistRefused/${id}`,
        JSON.stringify(obj)
      );
      return result;
    },
    setStepCompletedRefused: async (id, obj) => {
      const result = await client.put(
        `/api/orderServiceStep/stepCompletedRefused/${id}`,
        JSON.stringify(obj)
      );
      return result;
    },
    setStepCompleted: async (id) => {
      const result = await client.put(
        `/api/orderServiceStep/stepCompleted/${id}`
      );
      return result;
    },
    setGrouped: async (id) => {
      const result = await client.put(`/api/orderServiceStep/grouped/${id}`);
      return result;
    },
    setSubmittedInGroup: async (id) => {
      const result = await client.put(
        `/api/orderServiceStep/submittedInGroup/${id}`
      );
      return result;
    },
    setFinalized: async (id) => {
      const result = await client.put(`/api/orderServiceStep/finalized/${id}`);
      return result;
    },
    setReceived: async (id) => {
      const result = await client.put(`/api/orderServiceStep/received/${id}`);
      return result;
    },
    suspendOrderService: async (id) => {
      const result = await client.put(`/api/orderServiceStep/suspended/${id}`);
      return result;
    },
    resumeOrderService: async (id) => {
      const result = await client.put(`/api/orderServiceStep/resumed/${id}`);
      return result;
    },
    defineExecutor: async (id, obj) => {
      const result = await client.put(
        `/api/orderServiceStep/executor/${id}`,
        JSON.stringify(obj)
      );
      return result;
    },
    fixOSByDentist: async (id) => {
      const result = await client.put(
        `/api/orderServiceStep/fixOrderService/dentist/${id}`
      );
      return result;
    },
    fixOSByClinic: async (id) => {
      const result = await client.put(
        `/api/orderServiceStep/fixOrderService/clinic/${id}`
      );
      return result;
    },
    setCompletedLaboratory: async (id) => {
      const result = await client.put(
        `/api/orderServiceStep/completedLaboratory/${id}`
      );
      return result;
    },
    setTakeCost: async (id, obj) => {
      const result = await client.put(
        `/api/orderServiceStep/${id}/takeCost`,
        JSON.stringify(obj)
      );
      return result;
    },
    setSkippedStep: async (id) => {
      const result = await client.put(
        `/api/orderServiceStep/skippedStep/${id}`
      );
      return result;
    },
  },
  patient: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/patient?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    create: async (patient) => {
      const result = await client.post('/api/patient', JSON.stringify(patient));
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/patient/${id}`);
      return result;
    },
    findByRecordNumber: async (recordNumber, unitId) => {
      const path = unitId
        ? `/api/patient/recordNumber/${recordNumber}/unit/${unitId}`
        : `/api/patient/recordNumber/${recordNumber}`;
      const result = await client.get(path);
      return result;
    },
    update: async (id, oldPatient, patient) => {
      const patchObj = buildPatchFromDiff(oldPatient, patient);
      const result = await client.patch(
        `/api/patient/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
  },
  monthlyStock: {
    getStockHistoryValue: async (
      date,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/monthlyStock/stockHistoryValue/date/${date}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getStockHistoryValueCSVReport: async (date) => {
      const path = `/api/monthlyStock/csv/stockHistoryValue/date/${date}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
  },
  schedule: {
    create: async (schedule) => {
      const result = await client.post(
        '/api/schedule',
        JSON.stringify(schedule)
      );
      return result;
    },
  },
  outsourced: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/outsourced?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllOSOutsourcedByPeriod: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/outsourced/report/steps/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/outsourced/${id}`);
      return result;
    },
    findByCnpj: async (cnpj) => {
      const result = await client.get(`/api/outsourced/cnpj/${cnpj}`);
      return result;
    },
    create: async (outsourced) => {
      const result = await client.post(
        '/api/outsourced',
        JSON.stringify(outsourced)
      );
      return result;
    },
    update: async (
      id,
      oldOutsourced,
      outsourced,
      added,
      removed,
      isOutsourcedPriceTables = false
    ) => {
      const patchObj = buildPatchFromDiff(oldOutsourced, outsourced);
      const arrayPatchObject = isOutsourcedPriceTables
        ? [
            {
              op: 'add',
              path: '/outsourcedPriceTables',
              value: added,
            },
            {
              op: 'remove',
              path: '/outsourcedPriceTables',
              value: removed,
            },
          ]
        : [
            {
              op: 'add',
              path: '/outsourcedBankAccounts',
              value: added,
            },
            {
              op: 'remove',
              path: '/outsourcedBankAccounts',
              value: removed,
            },
          ];
      const result = await client.patch(
        `/api/outsourced/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObject))
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/outsourced/${id}`);
      return result;
    },
    restore: async (id) => {
      const result = await client.put(`/api/outsourced/restore/${id}`);
      return result;
    },
  },
  outsourcedStepPrice: {
    create: async (outsourcedStepPrice) => {
      const result = await client.post(
        '/api/outsourcedStepPrice',
        JSON.stringify(outsourcedStepPrice)
      );
      return result;
    },
    update: async (id, oldOutsourcedStepPrice, outsourcedStepPrice) => {
      const patchObj = buildPatchFromDiff(
        oldOutsourcedStepPrice,
        outsourcedStepPrice
      );
      const result = await client.patch(
        `/api/outsourcedStepPrice/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    findByStepAndOutsourced: async (step, outsourced) => {
      const result = await client.get(
        `/api/outsourcedStepPrice/step/${step}/outsourced/${outsourced}`
      );
      return result;
    },
  },
  origin: {
    findById: async (id) => {
      const result = await client.get(`/api/origin/${id}`);
      return result;
    },
    create: async (origin) => {
      const result = await client.post('/api/origin', JSON.stringify(origin));
      return result;
    },
    update: async (id, oldOrigin, origin) => {
      const patchObj = buildPatchFromDiff(oldOrigin, origin);
      const result = await client.patch(
        `/api/origin/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/origin?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllTree: async () => {
      const result = await client.get('/api/origin/tree');
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/origin/${id}`);
      return result;
    },
  },
  treatment: {
    findById: async (id) => {
      const result = await client.get(`/api/treatment/${id}`);
      return result;
    },
    create: async (treatment) => {
      const result = await client.post(
        '/api/treatment',
        JSON.stringify(treatment)
      );
      return result;
    },
    update: async (id, oldTreatment, treatment) => {
      const patchObj = buildPatchFromDiff(oldTreatment, treatment);
      const result = await client.patch(
        `/api/treatment/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/treatment?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByConsiderTime: async () => {
      const result = await client.get('/api/treatment/consider-time?');
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/treatment/${id}`);
      return result;
    },
  },
  serviceSupplier: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/serviceSupplier?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/serviceSupplier/${id}`);
      return result;
    },
    findByCnpj: async (cnpj) => {
      const result = await client.get(`/api/serviceSupplier/cnpj/${cnpj}`);
      return result;
    },
    create: async (serviceSupplier) => {
      const result = await client.post(
        '/api/serviceSupplier',
        JSON.stringify(serviceSupplier)
      );
      return result;
    },
    update: async (id, oldServiceSupplier, serviceSupplier, added, removed) => {
      const patchObj = buildPatchFromDiff(oldServiceSupplier, serviceSupplier);
      const arrayPatchObject = [
        {
          op: 'add',
          path: '/serviceSupplierBankAccounts',
          value: added,
        },
        {
          op: 'remove',
          path: '/serviceSupplierBankAccounts',
          value: removed,
        },
      ];
      const result = await client.patch(
        `/api/serviceSupplier/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObject))
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/serviceSupplier/${id}`);
      return result;
    },
    restore: async (id) => {
      const result = await client.put(`/api/serviceSupplier/restore/${id}`);
      return result;
    },
  },
  specialty: {
    findById: async (id) => {
      const result = await client.get(`/api/specialty/${id}`);
      return result;
    },
    create: async (specialty) => {
      const result = await client.post(
        '/api/specialty',
        JSON.stringify(specialty)
      );
      return result;
    },
    update: async (id, oldSpecialty, specialty) => {
      const patchObj = buildPatchFromDiff(oldSpecialty, specialty);
      const result = await client.patch(
        `/api/specialty/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/specialty?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllBySpecialtyDentist: async (
      dentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/specialty/dentist/${dentistId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllTree: async () => {
      const result = await client.get('/api/specialty/tree');
      return result;
    },
  },
  transferType: {
    findById: async (id) => {
      const result = await client.get(`/api/transferType/${id}`);
      return result;
    },
    create: async (transferType) => {
      const result = await client.post(
        '/api/transferType',
        JSON.stringify(transferType)
      );
      return result;
    },
    update: async (id, oldTransferType, transferType) => {
      const patchObj = buildPatchFromDiff(oldTransferType, transferType);
      const result = await client.patch(
        `/api/transferType/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/transferType?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllTree: async () => {
      const result = await client.get('/api/transferType/tree');
      return result;
    },
  },
  procedure: {
    findById: async (id) => {
      const result = await client.get(`/api/procedure/${id}`);
      return result;
    },
    create: async (procedure) => {
      const result = await client.post(
        '/api/procedure',
        JSON.stringify(procedure)
      );
      return result;
    },
    update: async (id, oldProcedure, procedure) => {
      const patchObj = buildPatchFromDiff(oldProcedure, procedure);
      const result = await client.patch(
        `/api/procedure/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/procedure?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllBySpecialtyDentist: async (
      dentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/procedure/dentist/${dentistId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/procedure/${id}`);
      return result;
    },
  },
  radiologySchedule: {
    findById: async (id) => {
      const result = await client.get(`/api/radiologySchedule/${id}`);
      return result;
    },
    create: async (radilogySchedule) => {
      const result = await client.post(
        '/api/radiologySchedule',
        JSON.stringify(radilogySchedule)
      );
      return result;
    },
    update: async (id, oldRadilogySchedule, radilogySchedule) => {
      const patchObj = buildPatchFromDiff(
        oldRadilogySchedule,
        radilogySchedule
      );
      const result = await client.patch(
        `/api/radiologySchedule/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    rescheduleRadiology: async (id, oldRadilogySchedule, radilogySchedule) => {
      const patchObj = buildPatchFromDiff(
        oldRadilogySchedule,
        radilogySchedule
      );
      const result = await client.patch(
        `/api/radiologySchedule/reschedule/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/radiologySchedule?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByClinic: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologySchedule/unit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByClinicDate: async (
      unitId,
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologySchedule/unit/${unitId}/period/${from}/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllExecutedRadiology: async (
      date = null,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      let request = '';
      if (date) {
        request = `/api/radiologySchedule/radiology/date/${date}?`;
      } else {
        request = `/api/radiologySchedule/radiology?`;
      }
      const result = await client.get(request + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    getAllExecutedRadiologyByPeriod: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologySchedule/radiology/period/${from}/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllExecutedRadiologyByUnit: async (
      unitId,
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologySchedule/radiology/unit/${unitId}/period/${from}/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllRadiologySchedulesByUnitByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const path = unitId
        ? `/api/radiologySchedule/report/costUnit/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/radiologySchedule/report/costUnit/period/${from}/to/${to}?`;
      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    setOnAttendance: async (id) => {
      const result = await client.put(
        `/api/radiologySchedule/onAttendance/${id}`
      );
      return result;
    },
    getClinicSchedulesCSVReportByUnit: async (unitId, from, to) => {
      const path = unitId
        ? `/api/radiologySchedule/report/csv/clinicSchedules/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/radiologySchedule/report/csv/clinicSchedules/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getRadiologySchedulesCSVReport: async (from, to) => {
      const result = await client.get(
        `/api/radiologySchedule/report/csv/radiologySchedules/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getAllClinicRadiologySchedulesByUnitByPeriod: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologySchedule/report/clinic/costUnit/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getClinicSchedulesCSVReport: async (from, to) => {
      const result = await client.get(
        `/api/radiologySchedule/report/clinic/csv/clinicSchedules/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getRadiologySchedulesCSVReportClinic: async (from, to) => {
      const result = await client.get(
        `/api/radiologySchedule/report/clinic/csv/radiologySchedules/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    setOnWaiting: async (id) => {
      const result = await client.put(`/api/radiologySchedule/onWaiting/${id}`);
      return result;
    },
    setFinished: async (id) => {
      const result = await client.put(`/api/radiologySchedule/finished/${id}`);
      return result;
    },
    setMissed: async (id) => {
      const result = await client.put(`/api/radiologySchedule/missed/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/radiologySchedule/${id}`);
      return result;
    },
  },
  extraCost: {
    create: async (extraCost) => {
      const result = await client.post(
        '/api/extraCost',
        JSON.stringify(extraCost)
      );
      return result;
    },
  },
  unitRadiologyService: {
    create: async (saleItem) => {
      const result = await client.post(
        '/api/unitRadiologyService',
        JSON.stringify(saleItem)
      );
      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/unitRadiologyService/unit/${unitId ?? -1}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/unitRadiologyService/${id}`);
      return result;
    },
  },
  openingHour: {
    getAll: async () => {
      const result = await client.get(`/api/openingHour`);
      return result;
    },
    getAllByDay: async (
      day,
      treatment,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/openingHour/day/${day}/treatment/${treatment}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByTreatment: async (treatment) => {
      const result = await client.get(
        `/api/openingHour/treatment/${treatment}?`
      );
      return result;
    },
    update: async (dayOfWeek, treatmentId, added, removed) => {
      const result = await client.put(
        `/api/openingHour`,
        JSON.stringify({ dayOfWeek, treatmentId, added, removed })
      );
      return result;
    },
  },
  finishedLabService: {
    update: async (id, oldFinishedLabService, finishedLabService) => {
      const patchObj = buildPatchFromDiff(
        oldFinishedLabService,
        finishedLabService
      );
      const result = await client.patch(
        `/api/finishedLabService/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
  },

  chair: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/chair?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/chair/unit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/chair/${id}`);
      return result;
    },
    create: async (chair) => {
      const result = await client.post('/api/chair', JSON.stringify(chair));
      return result;
    },
    update: async (id, chair) => {
      const result = await client.put(
        `/api/chair/${id}`,
        JSON.stringify(chair)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/chair/${id}`);
      return result;
    },
  },
  chairDentist: {
    create: async (chairDentist) => {
      const result = await client.post(
        '/api/chairDentist',
        JSON.stringify(chairDentist)
      );
      return result;
    },
    getAllByChair: async (
      chairId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/chairDentist/chair/${chairId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/chairDentist/${id}`);
      return result;
    },
  },
  officeHour: {
    create: async (officeHour) => {
      const result = await client.post(
        '/api/dentistOfficeHour',
        JSON.stringify(officeHour)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentistOfficeHour/${id}`);
      return result;
    },
    getAll: async () => {
      const result = await client.get(`/api/dentistOfficeHour`);
      return result;
    },
    getAllByChairDentist: async (
      chairDentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistOfficeHour/chairDentist/${chairDentistId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByUnit: async (unitId) => {
      const result = await client.get(`/api/dentistOfficeHour/unit/${unitId}?`);
      return result;
    },
    getAllByChair: async (chairId) => {
      const result = await client.get(
        `/api/dentistOfficeHour/chair/${chairId}?`
      );
      return result;
    },
    update: async (id, oldOfficeHour, officeHour) => {
      const patchObj = buildPatchFromDiff(oldOfficeHour, officeHour);
      const result = await client.patch(
        `/api/dentistOfficeHour/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/dentistOfficeHour/${id}`);
      return result;
    },
  },
  dentistProcedurePrice: {
    getAllByDentistAndUnit: async (dentistId, unitId) => {
      const result = await client.get(
        `/api/dentistProcedurePrice/dentist/${dentistId}/unit/${unitId}?`
      );
      return result;
    },
  },
  substitute: {
    create: async (substitute) => {
      const result = await client.post(
        '/api/substitute',
        JSON.stringify(substitute)
      );
      return result;
    },
    update: async (id, oldSubstitute, substitute) => {
      const patchObj = buildPatchFromDiff(oldSubstitute, substitute);
      const result = await client.patch(
        `/api/substitute/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/substitute?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllInClinic: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/substitute/byclinic?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByReplacement: async (
      substituteDentistId,
      replacedDentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/substitute/substituteDentist/${substituteDentistId}/replacedDentist/${replacedDentistId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getFixedPaymentsCSVReport: async (from, to, unitId) => {
      const result = await client.get(
        `/api/substitute/csv/fixedPayments/unit/${unitId}/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/substitute/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/substitute/${id}`);
      return result;
    },
  },
  attendance: {
    findById: async (id) => {
      const result = await client.get(`/api/attendance/${id}`);
      return result;
    },
    create: async (attendance) => {
      const result = await client.post(
        '/api/attendance',
        JSON.stringify(attendance)
      );
      return result;
    },
    update: async (id, oldAttendance, attendance) => {
      const patchObj = buildPatchFromDiff(oldAttendance, attendance);
      const result = await client.patch(
        `/api/attendance/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    setAuditing: async (id) => {
      const result = await client.put(`/api/attendance/auditing/${id}`);
      return result;
    },
    setApproved: async (id, model = null) => {
      const result = await client.put(
        `/api/attendance/auditing/approved/${id}`,
        JSON.stringify(model)
      );
      return result;
    },
    setDisapproved: async (id, disapprovedJustification) => {
      const result = await client.put(
        `/api/attendance/auditing/disapproved/${id}`,
        JSON.stringify({ disapprovedJustification })
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/attendance?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByDate: async (
      date,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        date
          ? `/api/attendance/all-procedures/date/${date}?`
          : `/api/attendance/all-procedures?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllAttendancesFixedAndProductivity: async (
      dentistId,
      date,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      let path;

      if (date) {
        path = `/api/attendance/fixedAndProductivity/dentist/${dentistId}/date/${date}?`;
      } else {
        path = `/api/attendance/fixedAndProductivity/dentist/${dentistId}?`;
      }

      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    getAllAttendancesClinic: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/attendance/byclinic?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllInClinic: async (
      dentistId,
      date,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      let path;

      if (date) {
        path = `/api/attendance/clinic/fixedAndProductivity/dentist/${dentistId}/date/${date}?`;
      } else {
        path = `/api/attendance/clinic/fixedAndProductivity/dentist/${dentistId}?`;
      }

      const result = await client.get(path + buildSorterArray(sortersObj), {
        params: {
          take,
          skip,
          filters,
        },
      });
      return result;
    },
    getAllByDateClinic: async (
      date,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        date
          ? `/api/attendance/clinic/all-procedures/date/${date}?`
          : `/api/attendance/clinic/all-procedures?` +
              buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByDentist: async (
      dentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/attendance/dentist/${dentistId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getFinishedProceduresCsvReport: async (from, to, unitId, dentistId) => {
      const result = await client.get(
        `/api/attendance/csv/finishedProcedures/unit/${unitId}/dentist/${dentistId}/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getFinishedLabStepsCsvReport: async (from, to, unitId, dentistId) => {
      let path;

      if (unitId === 'all' && dentistId === 'all') {
        path = `/api/attendance/csv/finishedProcedures/period/${from}/to/${to}?`;
      } else if (unitId === 'all' && dentistId !== 'all') {
        path = `/api/attendance/csv/finishedProcedures/dentist/${dentistId}/period/${from}/to/${to}?`;
      } else if (unitId !== 'all' && dentistId === 'all') {
        path = `/api/attendance/csv/finishedProcedures/unit/${unitId}/period/${from}/to/${to}?`;
      } else {
        path = `/api/attendance/csv/finishedProcedures/unit/${unitId}/dentist/${dentistId}/period/${from}/to/${to}?`;
      }

      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/attendance/${id}`);
      return result;
    },
  },
  dentistWorkedHour: {
    create: async (dentistWorkedHour) => {
      const result = await client.post(
        '/api/dentistWorkedHour',
        JSON.stringify(dentistWorkedHour)
      );
      return result;
    },
    update: async (id, oldDentistWorkedHour, dentistWorkedHour) => {
      delete dentistWorkedHour.isSubstitute;
      delete oldDentistWorkedHour.isSubstitute;
      const patchObj = buildPatchFromDiff(
        oldDentistWorkedHour,
        dentistWorkedHour
      );
      const result = await client.patch(
        `/api/dentistWorkedHour/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    updateSpecialty: async (id, specialtyId) => {
      const result = await client.put(
        `/api/dentistWorkedHour/${id}/specialty`,
        JSON.stringify({ specialtyId })
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/dentistWorkedHour?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllInClinic: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/dentistWorkedHour/byclinic?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByDate: async (
      date,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        date
          ? `/api/dentistWorkedHour/all-schedules/date/${date}?`
          : `/api/dentistWorkedHour/all-schedules?` +
              buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByDentist: async (
      dentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistWorkedHour/dentist/${dentistId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByDateInClinic: async (
      date,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        date
          ? `/api/dentistWorkedHour/clinic/all-schedules/date/${date}?`
          : `/api/dentistWorkedHour/clinic/all-schedules?` +
              buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getFinishedHoursCSVReport: async (from, to, unitId, dentistId) => {
      const result = await client.get(
        `/api/dentistWorkedHour/csv/finishedHours/unit/${unitId}/dentist/${dentistId}/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentistWorkedHour/${id}`);
      return result;
    },
    setSubmitted: async (id) => {
      const result = await client.put(`/api/dentistWorkedHour/submitted/${id}`);
      return result;
    },
    setApproved: async (id) => {
      const result = await client.put(`/api/dentistWorkedHour/approved/${id}`);
      return result;
    },
    setDisapproved: async (id, justification) => {
      const result = await client.put(
        `/api/dentistWorkedHour/disapproved/${id}/justification/${justification}`
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/dentistWorkedHour/${id}`);
      return result;
    },
  },
  dentistHourValue: {
    create: async (dentistHourValue) => {
      const result = await client.post(
        '/api/dentistHourValue',
        JSON.stringify(dentistHourValue)
      );
      return result;
    },
    update: async (id, oldDentistHourValue, dentistHourValue) => {
      const patchObj = buildPatchFromDiff(
        oldDentistHourValue,
        dentistHourValue
      );
      const result = await client.patch(
        `/api/dentistHourValue/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAllByDentistAndUnit: async (
      dentistId,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistHourValue/dentist/${dentistId}/unit/${unitId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentistHourValue/${id}`);
      return result;
    },
    updateDentistHourValues: async (added, removed) => {
      const result = await client.put(
        `/api/dentistHourValue/update-values`,
        JSON.stringify({ added, removed })
      );
      return result;
    },
  },
  dentistFixedValue: {
    create: async (dentistFixedValue) => {
      const result = await client.post(
        '/api/dentistFixedValue',
        JSON.stringify(dentistFixedValue)
      );
      return result;
    },
    update: async (id, oldDentistFixedValue, dentistFixedValue) => {
      const patchObj = buildPatchFromDiff(
        oldDentistFixedValue,
        dentistFixedValue
      );
      const result = await client.patch(
        `/api/dentistFixedValue/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAllByDentist: async (
      dentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistFixedValue/dentist/${dentistId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentistFixedValue/${id}`);
      return result;
    },
  },
  dentistPatientValue: {
    create: async (dentistPatientValue) => {
      const result = await client.post(
        '/api/dentistPatientValue',
        JSON.stringify(dentistPatientValue)
      );
      return result;
    },
    update: async (id, oldDentistPatientValue, dentistPatientValue) => {
      const patchObj = buildPatchFromDiff(
        oldDentistPatientValue,
        dentistPatientValue
      );
      const result = await client.patch(
        `/api/dentistPatientValue/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAllByDentistAndUnit: async (
      dentistId,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistPatientValue/dentist/${dentistId}/unit/${unitId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentistPatientValue/${id}`);
      return result;
    },
  },
  dentistWorkedHourAttendance: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/dentistWorkedHourAttendance?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByWorkedHour: async (
      workedHourId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistWorkedHourAttendance/workedHour/${workedHourId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentistWorkedHourAttendance/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(
        `/api/dentistWorkedHourAttendance/${id}`
      );
      return result;
    },
  },
  unitFinance: {
    create: async (unitFinance) => {
      const result = await client.post(
        '/api/unitFinance',
        JSON.stringify(unitFinance)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/unitFinance/${id}`);
      return result;
    },
    findByIdWithHistory: async (id) => {
      const result = await client.get(`/api/unitFinance/${id}/history`);
      return result;
    },
    patchUpdate: async (id, oldUnitFinance, unitFinance) => {
      const patchObj = buildPatchFromDiff(oldUnitFinance, unitFinance);
      const result = await client.patch(
        `/api/unitFinance/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    update: async (id, unitFinance) => {
      const result = await client.put(
        `/api/unitFinance/${id}`,
        JSON.stringify(unitFinance)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/unitFinance?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllWithConsolidation: async (
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        '/api/unitFinance/consolidation?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getDailyBankingHistoryByPeriod: async (from, to) => {
      const result = await client.get(
        `/api/unitFinance/report/banking-history/daily/period/${from}/to/${to}?`
      );
      return result;
    },
    getMonthlyBankingHistory: async (month, year) => {
      const result = await client.get(
        `/api/unitFinance/report/banking-history/monthly/date/${month}/${year}`
      );
      return result;
    },
    getBankAccountMovementsByPeriod: async (from, to, unitFinanceId) => {
      const result = await client.get(
        `/api/unitFinance/report/movements/unitFinance/${unitFinanceId}/period/${from}/to/${to}?`
      );
      return result;
    },
    adjustBalance: async (id, unitFinance) => {
      const result = await client.put(
        `/api/unitFinance/adjustBalance/${id}`,
        JSON.stringify(unitFinance)
      );
      return result;
    },
    restore: async (id) => {
      const result = await client.put(`/api/unitFinance/restore/${id}`);
      return result;
    },
    consolidate: async (id) => {
      const result = await client.put(`/api/unitFinance/${id}/consolidate`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/unitFinance/${id}`);
      return result;
    },
  },
  dailyUnitFinance: {
    getConsolidatedAt: async () => {
      const result = await client.get('/api/dailyUnitFinance/consolidatedAt');
      return result;
    },
  },
  expenseType: {
    findById: async (id) => {
      const result = await client.get(`/api/expenseType/${id}`);
      return result;
    },
    create: async (expenseType) => {
      const result = await client.post(
        '/api/expenseType',
        JSON.stringify(expenseType)
      );
      return result;
    },
    update: async (id, oldExpenseType, expenseType) => {
      const patchObj = buildPatchFromDiff(oldExpenseType, expenseType);
      const result = await client.patch(
        `/api/expenseType/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAllAsTree: async (type, month, year, unitId) => {
      const result = await client.get(
        `/api/expenseType/tree/type/${type}/unit/${unitId}/date/${month}/${year}`
      );
      return result;
    },
    getAllAsTreeClinic: async (month, year) => {
      const result = await client.get(
        `/api/expenseType/tree/clinic/date/${month}/${year}`
      );
      return result;
    },
    getAllLeaf: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/expenseType/leaf?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/expenseType/all?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllAsExpenseType: async () => {
      const result = await client.get('/api/expenseType');
      return result;
    },
    getAllChildrenByParentId: async (id) => {
      const result = await client.get(`/api/expenseType/parent/${id}`);
      return result;
    },
    getTypeById: async (id) => {
      const result = await client.get(`/api/expenseType/type/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/expenseType/${id}`);
      return result;
    },
    getDreCSV: async (from) => {
      const result = await client.get(
        `/api/expenseType/dre/csv/period/${from}`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getDreByUnitCSV: async (unitId, from, to) => {
      const result = await client.get(
        `/api/expenseType/dre/csv/unit/${unitId}/period/${from}/to/${to}`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getClinicDreByUnitCSV: async (from, to) => {
      const result = await client.get(
        `/api/expenseType/dre/csv/clinic/period/${from}/to/${to}`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
  },
  paymentType: {
    create: async (paymentType) => {
      const result = await client.post(
        '/api/paymentType',
        JSON.stringify(paymentType)
      );
      return result;
    },
    update: async (id, oldPaymentType, paymentType) => {
      const patchObj = buildPatchFromDiff(oldPaymentType, paymentType);
      const result = await client.patch(
        `/api/paymentType/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/paymentType?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/paymentType/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/paymentType/${id}`);
      return result;
    },
  },
  expense: {
    create: async (expense) => {
      const result = await client.post('/api/expense', JSON.stringify(expense));
      return result;
    },
    checkDuplicateExpense: async (expense) => {
      const result = await client.post(
        '/api/expense/check',
        JSON.stringify(expense)
      );
      return result;
    },
    update: async (id, oldExpense, expense, added, removed) => {
      const patchObj = buildPatchFromDiff(oldExpense, expense);
      const arrayPatchObject = [
        {
          op: 'add',
          path: '/employee_expense',
          value: added,
        },
        {
          op: 'remove',
          path: '/employee_expense',
          value: removed,
        },
      ];
      const result = await client.patch(
        `/api/expense/${id}`,
        JSON.stringify(patchObj.concat(arrayPatchObject))
      );
      return result;
    },
    updateExpenseWithApportion: async (id, model) => {
      const result = await client.put(
        `/api/expense/apportion/${id}`,
        JSON.stringify(model)
      );
      return result;
    },
    getAllGrouped: async (
      groupedId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/expense/grouped/${groupedId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByMonth: async (
      month,
      year,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/expense/month/${month}/${year}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        `/api/expense?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByPeriod: async (
      from,
      to,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/expense/period/from/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllPayableExpensesByPeriod: async (from, to, unitId) => {
      const path = unitId
        ? `/api/expense/report/payable-expenses/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/expense/report/payable-expenses/period/${from}/to/${to}?`;

      const result = await client.get(path);
      return result;
    },
    getAllPayableExpensesByPeriodCsv: async (from, to, unitId) => {
      const path = unitId
        ? `/api/expense/csv/payable-expenses/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/expense/csv/payable-expenses/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getAllPaidExpensesByPeriod: async (from, to, unitId) => {
      const path = unitId
        ? `/api/expense/report/paid-expenses/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/expense/report/paid-expenses/period/${from}/to/${to}?`;

      const result = await client.get(path);
      return result;
    },
    getAllPaidExpensesByPeriodCsv: async (from, to, unitId) => {
      const path = unitId
        ? `/api/expense/csv/paid-expenses/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/expense/csv/paid-expenses/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getAllExpensesByPeriod: async (from, to, unitId) => {
      const path = unitId
        ? `/api/expense/report/expenses/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/expense/report/expenses/period/${from}/to/${to}?`;

      const result = await client.get(path);
      return result;
    },
    getAllExpensesByPeriodCsv: async (from, to, unitId) => {
      const path = unitId
        ? `/api/expense/csv/expenses/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/expense/csv/expenses/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/expense/${id}`);
      return result;
    },
    setPaid: async (id, model) => {
      const result = await client.put(
        `/api/expense/paid/${id}`,
        JSON.stringify(model)
      );
      return result;
    },
    bulkPayment: async (groupedExpenseId, model) => {
      const result = await client.put(
        `/api/expense/bulkPayment/${groupedExpenseId}`,
        JSON.stringify(model)
      );
      return result;
    },
    setUndonePayment: async (id) => {
      const result = await client.put(`/api/expense/undonePayment/${id}`);
      return result;
    },
    agroupExpense: async (id, model) => {
      const result = await client.put(
        `/api/expense/agroup-expense/${id}`,
        JSON.stringify(model)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/expense/${id}`);
      return result;
    },
    submitFoodVoucherModel: async (
      file,
      serviceSupplierId,
      paymentTypeId,
      dueDate,
      onUploadProgress
    ) => {
      let formData = new FormData();
      formData.append('foodVoucherModel', file);
      formData.append('serviceSupplierId', serviceSupplierId);
      formData.append('paymentTypeId', paymentTypeId);
      formData.append('dueDate', dueDate);
      const result = await client.post('/api/expense/voucher/food', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });
      return result;
    },
    submitTransportVoucherModel: async (
      file,
      serviceSupplierId,
      paymentTypeId,
      dueDate,
      ticketValue,
      onUploadProgress
    ) => {
      let formData = new FormData();
      formData.append('transportVoucherModel', file);
      formData.append('serviceSupplierId', serviceSupplierId);
      formData.append('paymentTypeId', paymentTypeId);
      formData.append('dueDate', dueDate);
      formData.append('ticketValue', ticketValue);
      const result = await client.post(
        '/api/expense/voucher/transport',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        }
      );
      return result;
    },
  },
  employeeExpense: {
    getAllByExpense: async (
      expenseId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/employeeExpense/expense/${expenseId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  bankingOperation: {
    findById: async (id) => {
      const result = await client.get(`/api/bankingOperation/${id}`);
      return result;
    },
    create: async (bankingOperation) => {
      const result = await client.post(
        '/api/bankingOperation',
        JSON.stringify(bankingOperation)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/bankingOperation?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  expenseTypeExpenseType: {
    create: async (expenseTypeExpenseType) => {
      const result = await client.post(
        '/api/expenseTypeExpenseType',
        JSON.stringify(expenseTypeExpenseType)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/expenseTypeExpenseType/${id}`);
      return result;
    },
    getAllByExpenseTypeId: async (
      dreFieldId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/expenseTypeExpenseType/expenseType/${dreFieldId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  dreField: {
    findById: async (id) => {
      const result = await client.get(`/api/dreField/${id}`);
      return result;
    },
    create: async (dreField) => {
      const result = await client.post(
        '/api/dreField',
        JSON.stringify(dreField)
      );
      return result;
    },
    update: async (id, dreField) => {
      const result = await client.put(
        `/api/dreField/${id}`,
        JSON.stringify(dreField)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/dreField?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    up: async (dreField) => {
      const result = await client.put(
        '/api/dreField/up',
        JSON.stringify(dreField)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/dreField/${id}`);
      return result;
    },
  },
  sector: {
    findById: async (id) => {
      const result = await client.get(`/api/sector/${id}`);
      return result;
    },
    create: async (sector) => {
      const result = await client.post('/api/sector', JSON.stringify(sector));
      return result;
    },
    update: async (id, oldSector, sector) => {
      const patchObj = buildPatchFromDiff(oldSector, sector);
      const result = await client.patch(
        `/api/sector/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/sector?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllTree: async () => {
      const result = await client.get('/api/sector/tree');
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/sector/${id}`);
      return result;
    },
  },
  sectorExpenseType: {
    create: async (sectorExpenseType) => {
      const result = await client.post(
        '/api/sectorExpenseType',
        JSON.stringify(sectorExpenseType)
      );
      return result;
    },
    update: async (id, oldSectorExpenseType, sectorExpenseType) => {
      const patchObj = buildPatchFromDiff(
        oldSectorExpenseType,
        sectorExpenseType
      );
      const result = await client.patch(
        `/api/sectorExpenseType/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAllBySectorId: async (
      sectorId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/sectorExpenseType/sector/${sectorId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  recurrentExpense: {
    update: async (id, oldRecurrentExpense, recurrentExpense) => {
      const patchObj = buildPatchFromDiff(
        oldRecurrentExpense,
        recurrentExpense
      );
      const result = await client.patch(
        `/api/recurrentExpense/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/recurrentExpense?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/recurrentExpense/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/recurrentExpense/${id}`);
      return result;
    },
  },
  finishedPatientService: {
    getFinishedPatientsCSVReport: async (from, to, unitId, dentistId) => {
      const result = await client.get(
        `/api/finishedPatientService/csv/finishedPatients/unit/${unitId}/dentist/${dentistId}/period/${from}/to/${to}?`
      );
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
  },
  itemizedCard: {
    findById: async (id) => {
      const result = await client.get(`/api/itemizedCard/${id}`);
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/itemizedCard?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    create: async (itemizedCard) => {
      const result = await client.post(
        '/api/itemizedCard',
        JSON.stringify(itemizedCard)
      );
      return result;
    },
    update: async (id, oldItemizedCard, itemizedCard) => {
      const patchObj = buildPatchFromDiff(oldItemizedCard, itemizedCard);
      const result = await client.patch(
        `/api/itemizedCard/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    updateInstallment: async (id, installments) => {
      const result = await client.put(
        `/api/itemizedCard/${id}/installments`,
        JSON.stringify({ installments })
      );
      return result;
    },
    getAllByCashRegisterValueId: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/itemizedCard/byCashRegisterValueId/${id}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/itemizedCard/${id}`);
      return result;
    },
    submitPaymentModel: async (
      file,
      expenseTypeId,
      serviceSupplierId,
      onUploadProgress
    ) => {
      let formData = new FormData();
      formData.append('paymentModel', file);
      formData.append('expenseTypeId', expenseTypeId);
      formData.append('serviceSupplierId', serviceSupplierId);
      const result = await client.post(
        '/api/itemizedCard/paymentModel',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        }
      );
      return result;
    },
    submitSalesModel: async (file, onUploadProgress) => {
      let formData = new FormData();
      formData.append('salesModel', file);
      const result = await client.post(
        '/api/itemizedCard/salesModel',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        }
      );
      return result;
    },
  },
  itemizedCardInstallment: {
    getAllByItemizedCardId: async (
      itemizedCardId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/itemizedCardInstallment/itemizedCard/${itemizedCardId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  itemizedCheck: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/itemizedCheck?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/itemizedCheck/${id}`);
      return result;
    },
    create: async (itemizedCheck) => {
      const result = await client.post(
        '/api/itemizedCheck',
        JSON.stringify(itemizedCheck)
      );
      return result;
    },
    getAllByCashRegisterValueId: async (
      id,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/itemizedCheck/byCashRegisterValueId/${id}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    setCompensation: async (id) => {
      const result = await client.put(`/api/itemizedCheck/compensation/${id}`);
      return result;
    },
    setReturned: async (id, itemizedCheck) => {
      const result = await client.post(
        `/api/itemizedCheck/returned/${id}`,
        JSON.stringify(itemizedCheck)
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/itemizedCheck/${id}`);
      return result;
    },
  },
  cashRegisterValue: {
    getAllToDre: async (
      type,
      month,
      year,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/cashRegisterValue/dre/type/${type}/month/${month}/${year}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllCards: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/cashRegisterValue/card?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllChecks: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/cashRegisterValue/check?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllCardsByCashRegisterId: async (
      cashRegisterId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/cashRegisterValue/clinic/cashRegisterId/${cashRegisterId}/cards?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllChecksByCashRegisterId: async (
      cashRegisterId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/cashRegisterValue/clinic/cashRegisterId/${cashRegisterId}/checks?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  cashRegisterClose: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/cashRegisterClose?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/cashRegisterClose/byunit/${unitId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getUnitLastCashRegister: async (unitId) => {
      const result = await client.get(
        `/api/cashRegisterClose/lastCashRegister/unit/${unitId}?`
      );
      return result;
    },
    getAllByClinic: async (
      take = null,
      skip = null,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/cashRegisterClose/get-all-by-clinic?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/cashRegisterClose/${id}`);
      return result;
    },
    create: async (cashRegisterClose) => {
      const result = await client.post(
        '/api/cashRegisterClose',
        JSON.stringify(cashRegisterClose)
      );
      return result;
    },
    update: async (
      id,
      oldCashRegisterClose,
      cashRegisterClose,
      added,
      edited,
      removed
    ) => {
      const patchObj = buildPatchFromDiff(
        oldCashRegisterClose,
        cashRegisterClose
      );

      const editedPatchObject = edited.map((item) => ({
        op: 'patch',
        path: `/cashRegisterValues/${item.id}`,
        value: [
          {
            op: 'replace',
            path: 'value',
            value: item.value,
          },
        ],
      }));

      const arrayPatchObject = [
        {
          op: 'add',
          path: '/cashRegisterValues',
          value: added,
        },
        {
          op: 'remove',
          path: '/cashRegisterValues',
          value: removed,
        },
      ];

      const result = await client.patch(
        `/api/cashRegisterClose/${id}`,
        JSON.stringify([...patchObj, ...arrayPatchObject, ...editedPatchObject])
      );
      return result;
    },
    setBlocked: async (id) => {
      const result = await client.put(`/api/cashRegisterClose/blocked/${id}`);
      return result;
    },
    setEvaluation: async (id) => {
      const result = await client.put(
        `/api/cashRegisterClose/evaluation/${id}`
      );
      return result;
    },
    setDisapproved: async (id, disapprovalJustification) => {
      const result = await client.put(
        `/api/cashRegisterClose/disapproved/${id}`,
        JSON.stringify({ disapprovalJustification })
      );
      return result;
    },
    unlockCashRegister: async (id) => {
      const result = await client.put(`/api/cashRegisterClose/unlock/${id}`);
      return result;
    },
    getCashRegisterClose: async (from, to, unitId) => {
      const path = unitId
        ? `/api/cashRegisterClose/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/cashRegisterClose/period/${from}/to/${to}?`;
      return await client.get(path);
    },
    getCashRegisterCloseCSV: async (from, to, unitId) => {
      const path = unitId
        ? `/api/cashRegisterClose/csv/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/cashRegisterClose/csv/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getCashEntry: async (from, to, unitId) => {
      const path = unitId
        ? `/api/cashRegisterClose/entry/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/cashRegisterClose/entry/period/${from}/to/${to}?`;
      return await client.get(path);
    },
    getCashEntryCSV: async (from, to, unitId) => {
      const path = unitId
        ? `/api/cashRegisterClose/csv/entry/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/cashRegisterClose/csv/entry/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
    getRemainingCash: async (from, to, unitId) => {
      const path = unitId
        ? `/api/cashRegisterClose/remaining/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/cashRegisterClose/remaining/period/${from}/to/${to}?`;
      return await client.get(path);
    },
    getRemainingCashCSV: async (from, to, unitId) => {
      const path = unitId
        ? `/api/cashRegisterClose/csv/remaining/unit/${unitId}/period/${from}/to/${to}?`
        : `/api/cashRegisterClose/csv/remaining/period/${from}/to/${to}?`;
      const result = await client.get(path);
      let headerLine = result.headers['content-disposition'];
      const regex = /filename=(?<filename>.*)?/gm;
      const filename = regex.exec(headerLine).groups.filename;
      FileDownload(
        result.data,
        decodeURIComponent(
          escape(filename.replaceAll(/[^a-zA-Z0-9-_ \.]*/g, ''))
        ),
        'text/csv;charset=utf-8'
      );
      return result;
    },
  },
  groupedExpense: {
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/groupedExpense?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/groupedExpense/${id}`);
      return result;
    },
  },
  adverseExpense: {
    create: async (expense) => {
      const result = await client.post(
        '/api/adverseExpense',
        JSON.stringify(expense)
      );
      return result;
    },
    update: async (id, oldAdverseExpense, adverseExpense) => {
      const patchObj = buildPatchFromDiff(oldAdverseExpense, adverseExpense);
      const result = await client.patch(
        `/api/adverseExpense/${id}`,
        JSON.stringify(patchObj)
      );
      return result;
    },
    getAll: async (take, skip, filters = null, sortersObj = null) => {
      const result = await client.get(
        '/api/adverseExpense?' + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/adverseExpense/${id}`);
      return result;
    },
    cancelAdverseExpense: async (id) => {
      const result = await client.put(`/api/adverseExpense/canceled/${id}`);
      return result;
    },
    consolidateAdverseExpense: async (id, model) => {
      const result = await client.put(
        `/api/adverseExpense/consolidate/${id}`,
        JSON.stringify(model)
      );
      return result;
    },
  },
  stockConsumption: {
    create: async (stockConsumption) => {
      const result = await client.post(
        '/api/stockConsumption',
        JSON.stringify(stockConsumption)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/stockConsumption/${id}`);
      return result;
    },
    update: async (
      id,
      oldStockConsumption,
      stockConsumption,
      added,
      edited
    ) => {
      let editedPatchObject = [];
      const patchObj = buildPatchFromDiff(
        oldStockConsumption,
        stockConsumption
      );

      const addedPatchObject = [
        {
          op: 'add',
          path: '/stockConsumptionValues',
          value: added,
        },
      ];

      if (edited) {
        editedPatchObject = edited?.map((item) => ({
          op: 'patch',
          path: `/stockConsumptionValues/${item.id}`,
          value: [
            {
              op: 'replace',
              path: 'correction',
              value: item.correction,
            },
            {
              op: 'replace',
              path: 'subtotal',
              value: item.subtotal,
            },
            {
              op: 'replace',
              path: 'profitMargin',
              value: item.profitMargin,
            },
            {
              op: 'replace',
              path: 'totalProfit',
              value: item.totalProfit,
            },
          ],
        }));
      }

      const result = await client.patch(
        `/api/stockConsumption/${id}`,
        JSON.stringify([...patchObj, ...addedPatchObject, ...editedPatchObject])
      );

      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/stockConsumption/unit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    setEditing: async (id) => {
      const result = await client.put(`/api/stockConsumption/editing/${id}`);
      return result;
    },
    sendConsumption: async (id) => {
      const result = await client.put(`/api/stockConsumption/send/${id}`);
      return result;
    },
    getAllStockConsolidatedConsumptions: async (month, year, unitId) => {
      const path = unitId
        ? `/api/stockConsumption/report/consolidated-consumptions/unit/${unitId}/date/${month}/${year}?`
        : `/api/stockConsumption/report/consolidated-consumptions/date/${month}/${year}?`;
      const result = await client.get(path);
      return result;
    },
    getAllGroupsConsumptionByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/stockConsumption/groups/unit/${unitId}/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/stockConsumption/${id}`);
      return result;
    },
  },
  stockConsumptionValue: {
    getAllToDre: async (
      month,
      year,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/stockConsumptionValue/dre/month/${month}/${year}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByStockConsumptionId: async (
      stockConsumptionId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/stockConsumptionValue/stockConsumption/${stockConsumptionId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  labConsumption: {
    create: async (labConsumption) => {
      const result = await client.post(
        '/api/labConsumption',
        JSON.stringify(labConsumption)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/labConsumption/${id}`);
      return result;
    },
    update: async (id, oldLabConsumption, labConsumption, added, edited) => {
      let editedPatchObject = [];
      const patchObj = buildPatchFromDiff(oldLabConsumption, labConsumption);

      const addedPatchObject = [
        {
          op: 'add',
          path: '/labConsumptionValues',
          value: added,
        },
      ];

      if (edited) {
        editedPatchObject = edited?.map((item) => ({
          op: 'patch',
          path: `/labConsumptionValues/${item.id}`,
          value: [
            {
              op: 'replace',
              path: 'correction',
              value: item.correction,
            },
            {
              op: 'replace',
              path: 'subtotal',
              value: item.subtotal,
            },
          ],
        }));
      }

      const result = await client.patch(
        `/api/labConsumption/${id}`,
        JSON.stringify([...patchObj, ...addedPatchObject, ...editedPatchObject])
      );

      return result;
    },
    getAllSectionsConsumptionByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/labConsumption/sections/unit/${unitId}/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllLabConsolidatedConsumptions: async (month, year, unitId) => {
      const path = unitId
        ? `/api/labConsumption/report/consolidated-consumptions/unit/${unitId}/date/${month}/${year}?`
        : `/api/labConsumption/report/consolidated-consumptions/date/${month}/${year}?`;
      const result = await client.get(path);
      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/labConsumption/unit/${unitId}?` + buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    setEditing: async (id) => {
      const result = await client.put(`/api/labConsumption/editing/${id}`);
      return result;
    },
    sendConsumption: async (id) => {
      const result = await client.put(`/api/labConsumption/send/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/labConsumption/${id}`);
      return result;
    },
  },
  labConsumptionValue: {
    getAllToDre: async (
      month,
      year,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/labConsumptionValue/dre/month/${month}/${year}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByLabConsumptionId: async (
      labConsumptionId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/labConsumptionValue/labConsumption/${labConsumptionId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  radiologyConsumption: {
    create: async (radiologyConsumption) => {
      const result = await client.post(
        '/api/radiologyConsumption',
        JSON.stringify(radiologyConsumption)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/radiologyConsumption/${id}`);
      return result;
    },
    update: async (
      id,
      oldRadiologyConsumption,
      radiologyConsumption,
      added,
      edited
    ) => {
      let editedPatchObject = [];
      const patchObj = buildPatchFromDiff(
        oldRadiologyConsumption,
        radiologyConsumption
      );

      const addedPatchObject = [
        {
          op: 'add',
          path: '/radiologyConsumptionValues',
          value: added,
        },
      ];

      if (edited) {
        editedPatchObject = edited?.map((item) => ({
          op: 'patch',
          path: `/radiologyConsumptionValues/${item.id}`,
          value: [
            {
              op: 'replace',
              path: 'correction',
              value: item.correction,
            },
            {
              op: 'replace',
              path: 'subtotal',
              value: item.subtotal,
            },
          ],
        }));
      }

      const result = await client.patch(
        `/api/radiologyConsumption/${id}`,
        JSON.stringify([...patchObj, ...addedPatchObject, ...editedPatchObject])
      );

      return result;
    },
    getAllOriginsConsumptionByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologyConsumption/origins/unit/${unitId}/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllRadiologyConsolidatedConsumptions: async (month, year, unitId) => {
      const path = unitId
        ? `/api/radiologyConsumption/report/consolidated-consumptions/unit/${unitId}/date/${month}/${year}?`
        : `/api/radiologyConsumption/report/consolidated-consumptions/date/${month}/${year}?`;
      const result = await client.get(path);
      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologyConsumption/unit/${unitId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    setEditing: async (id) => {
      const result = await client.put(
        `/api/radiologyConsumption/editing/${id}`
      );
      return result;
    },
    sendConsumption: async (id) => {
      const result = await client.put(`/api/radiologyConsumption/send/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/radiologyConsumption/${id}`);
      return result;
    },
  },
  radiologyConsumptionValue: {
    getAllToDre: async (
      month,
      year,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologyConsumptionValue/dre/month/${month}/${year}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByRadiologyConsumptionId: async (
      radiologyConsumptionId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/radiologyConsumptionValue/radiologyConsumption/${radiologyConsumptionId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  unitFinanceBalanceHistory: {
    getAllByUnitFinance: async (
      unitFinanceId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/unitFinanceBalanceHistory/unitFinance/${unitFinanceId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  dentistSpecialty: {
    getAllByDentist: async (
      dentistId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistSpecialty/dentist/${dentistId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  dentistConsumption: {
    create: async (dentistConsumption) => {
      const result = await client.post(
        '/api/dentistConsumption',
        JSON.stringify(dentistConsumption)
      );
      return result;
    },
    findById: async (id) => {
      const result = await client.get(`/api/dentistConsumption/${id}`);
      return result;
    },
    update: async (
      id,
      oldDentistConsumption,
      dentistConsumption,
      added,
      edited
    ) => {
      let editedPatchObject = [];
      const patchObj = buildPatchFromDiff(
        oldDentistConsumption,
        dentistConsumption
      );

      const addedPatchObject = [
        {
          op: 'add',
          path: '/dentistConsumptionValues',
          value: added,
        },
      ];

      if (edited) {
        editedPatchObject = edited?.map((item) => ({
          op: 'patch',
          path: `/dentistConsumptionValues/${item.id}`,
          value: [
            {
              op: 'replace',
              path: 'correction',
              value: item.correction,
            },
            {
              op: 'replace',
              path: 'subtotal',
              value: item.subtotal,
            },
          ],
        }));
      }

      const result = await client.patch(
        `/api/dentistConsumption/${id}`,
        JSON.stringify([...patchObj, ...addedPatchObject, ...editedPatchObject])
      );

      return result;
    },
    getAllSpecialtiesConsumptionByPeriod: async (
      from,
      to,
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistConsumption/specialties/unit/${unitId}/period/${from}/to/${to}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByUnit: async (
      unitId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistConsumption/unit/${unitId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    setEditing: async (id) => {
      const result = await client.put(`/api/dentistConsumption/editing/${id}`);
      return result;
    },
    sendConsumption: async (id) => {
      const result = await client.put(`/api/dentistConsumption/send/${id}`);
      return result;
    },
    delete: async (id) => {
      const result = await client.delete(`/api/dentistConsumption/${id}`);
      return result;
    },
  },
  dentistConsumptionValue: {
    getAllToDre: async (
      month,
      year,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistConsumptionValue/dre/month/${month}/${year}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
    getAllByDentistConsumptionId: async (
      dentistConsumptionId,
      take,
      skip,
      filters = null,
      sortersObj = null
    ) => {
      const result = await client.get(
        `/api/dentistConsumptionValue/dentistConsumption/${dentistConsumptionId}?` +
          buildSorterArray(sortersObj),
        {
          params: {
            take,
            skip,
            filters,
          },
        }
      );
      return result;
    },
  },
  clinicAdjustmentHistory: {
    adjustQuantityStock: async (adjust) => {
      const result = await client.post(
        '/api/clinicAdjustmentHistory',
        JSON.stringify(adjust)
      );
      return result;
    },
  },
};

client.interceptors.request.use(
  async (config) => {
    const authReducer = store.getState().authReducer;
    if (authReducer.token) {
      if (new Date(authReducer.tokenExpiration) <= new Date(Date.now())) {
        if (!authReducer.refreshing) {
          try {
            store.dispatch(Creators.tokenFetching());
            store.dispatch(Creators.tokenRefreshing());
            const authDto = await restApiClient.token.refresh(
              authReducer.refreshToken
            );
            store.dispatch(Creators.storeToken(authDto));
            const userDto = await restApiClient.user.profile();
            store.dispatch(Creators.storeUser(userDto));
          } catch (e) {
            store.dispatch(Creators.logout());
            store.dispatch(Creators.tokenError(e));
          }
        }
      }
      config.headers.Authorization = `Bearer ${
        store.getState().authReducer.token
      }`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default restApiClient;
