const StockConsumption = {
  schema: {
    type: 'object',
    properties: {
      beginDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
        beginDate: '',
      },
      endDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
        endDate: '',
        beforeToday: '',
      },
    },
    required: ['beginDate', 'endDate'],
  },
  uischema: {
    type: 'Group',
    label: 'Intervalo de Consumo - Estoque',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Inicial',
            scope: '#/properties/beginDate',
          },
          {
            type: 'Control',
            label: 'Data Final',
            scope: '#/properties/endDate',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'beginDate', label: 'Início', minWidth: 100, align: 'center' },
    { id: 'endDate', label: 'Término', minWidth: 100, align: 'center' },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
    { id: 'total', label: 'Total', minWidth: 100, align: 'center' },
  ],
  groupsConsumptionTableSchema: [
    {
      id: 'name',
      label: 'Grupo',
      minWidth: 100,
      align: 'center',
    },
    { id: 'transfer', label: 'Transferência', minWidth: 100, align: 'center' },
    { id: 'adjustment', label: 'Ajustes', minWidth: 100, align: 'center' },
    { id: 'loss', label: 'Perda', minWidth: 100, align: 'center' },
    { id: 'correction', label: 'Correção', minWidth: 100, align: 'center' },
    { id: 'subtotal', label: 'Subtotal', minWidth: 100, align: 'center' },
    {
      id: 'profitMargin',
      label: 'Margem de Lucro',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'totalProfit',
      label: 'Subtotal + Lucro',
      minWidth: 100,
      align: 'center',
    },
  ],
  dreConsumptionTableSchema: [
    {
      id: 'stockConsumption.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'range', label: 'Período', minWidth: 100, align: 'center' },
    { id: 'transfer', label: 'Transferência', minWidth: 100, align: 'center' },
    { id: 'adjustment', label: 'Ajustes', minWidth: 100, align: 'center' },
    { id: 'loss', label: 'Perda', minWidth: 100, align: 'center' },
    { id: 'correction', label: 'Correção', minWidth: 100, align: 'center' },
    { id: 'subtotal', label: 'Subtotal', minWidth: 100, align: 'center' },
  ],
  actions: {
    create: 'StockConsumptionController@create',
    findById: 'StockConsumptionController@findById',
    update: 'StockConsumptionController@patchUpdate',
    getAllByUnit: 'StockConsumptionController@getAllByUnit',
    getAllGroupsConsumptionByPeriod:
      'StockConsumptionController@getAllGroupsConsumptionByPeriod',
    setEditing: 'StockConsumptionController@setEditing',
    sendConsumption: 'StockConsumptionController@sendConsumption',
    delete: 'StockConsumptionController@delete',
  },
  valuesActions: {
    getAllByStockConsumptionId:
      'StockConsumptionValueController@getAllByStockConsumptionId',
    getAllToDre: 'StockConsumptionValueController@getAllToDre',
  },
};

export default StockConsumption;
