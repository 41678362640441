import styled from 'styled-components';
import {
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
} from '@material-ui/core';
import { Button as MaterialButton, TextField } from '@material-ui/core';

export const Container = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  width: 300px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 0px !important;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Input = styled(TextField)`
  width: 100%;
  color: white;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
