import styled from 'styled-components';
import { Edit as MaterialEdit } from '@styled-icons/boxicons-solid/Edit';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Edit = styled(MaterialEdit)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
