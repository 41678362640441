const CashRegisterClose = {
  schema: {
    type: 'object',
    properties: {
      unitId: {
        type: 'number',
        format: 'selectUnitDialog',
        customErrors: '',
      },
      numberCashRegister: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
      date: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      employeeId: {
        format: 'selectEmployeeDialog',
        customErrors: '',
      },
      cashRegisterValues: {
        type: 'array',
        customErrors: '',
        items: {
          type: 'object',
          properties: {
            paymentTypeName: {
              type: 'string',
              customErrors: '',
            },
            paymentTypeId: {
              format: 'selectPaymentTypeDialog',
              customErrors: '',
            },
            unitFinanceId: {
              format: 'selectUnitFinanceDialog',
              customErrors: '',
            },
            value: {
              type: 'number',
              format: 'money',
              customErrors: '',
            },
          },
          required: ['paymentTypeId', 'unitFinanceId', 'value'],
        },
      },
    },
    required: [
      'unitId',
      'numberCashRegister',
      'employeeId',
      'date',
      'cashRegisterValues',
    ],
  },
  uischema: {
    type: 'Group',
    label: 'Fechamento de Caixa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: 'Funcionário',
            scope: '#/properties/employeeId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/disableEmployee',
                schema: { enum: [null, undefined] },
              },
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do Caixa',
            scope: '#/properties/numberCashRegister',
          },
          {
            type: 'Control',
            label: 'Data',
            scope: '#/properties/date',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Valores',
            },
            scope: '#/properties/cashRegisterValues',
            options: {
              elementLabelProp: 'paymentTypeName',
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Tipo',
                        scope: '#/properties/paymentTypeId',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Conta',
                        scope: '#/properties/unitFinanceId',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Valor',
                        scope: '#/properties/value',
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Fechamento de Caixa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: 'Funcionário',
            scope: '#/properties/employeeId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/disableEmployee',
                schema: { enum: [null, undefined] },
              },
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do Caixa',
            scope: '#/properties/numberCashRegister',
          },
          {
            type: 'Control',
            label: 'Data',
            scope: '#/properties/date',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/cashRegisterValues',
            label: {
              text: 'Valores',
            },
            options: {
              readonly: false,
              elementLabelProp: 'paymentTypeName',
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Tipo',
                        scope: '#/properties/paymentTypeId',
                        rule: {
                          effect: 'ENABLE',
                          condition: {
                            scope: '#/properties/id',
                            schema: { enum: [null, undefined] },
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Conta',
                        scope: '#/properties/unitFinanceId',
                        rule: {
                          effect: 'ENABLE',
                          condition: {
                            scope: '#/properties/id',
                            schema: { enum: [null, undefined] },
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Valor',
                        scope: '#/properties/value',
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    ],
  },
  adjustUischema: {
    type: 'Group',
    label: 'Fechamento de Caixa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: 'Funcionário',
            scope: '#/properties/employeeId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do Caixa',
            scope: '#/properties/numberCashRegister',
          },
          {
            type: 'Control',
            label: 'Data',
            scope: '#/properties/date',
            options: {
              readonly: true,
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/cashRegisterValues',
            label: {
              text: 'Valores',
            },
            options: {
              readonly: false,
              elementLabelProp: 'paymentTypeName',
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Tipo',
                        scope: '#/properties/paymentTypeId',
                        rule: {
                          effect: 'ENABLE',
                          condition: {
                            scope: '#/properties/id',
                            schema: { enum: [null, undefined] },
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Conta',
                        scope: '#/properties/unitFinanceId',
                        rule: {
                          effect: 'ENABLE',
                          condition: {
                            scope: '#/properties/id',
                            schema: { enum: [null, undefined] },
                          },
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Valor',
                        scope: '#/properties/value',
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    ],
  },
  readonlyUischema: {
    type: 'Group',
    label: 'Fechamento de Caixa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: 'Funcionário',
            scope: '#/properties/employeeId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do Caixa',
            scope: '#/properties/numberCashRegister',
          },
          {
            type: 'Control',
            label: 'Data',
            scope: '#/properties/date',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'ControlCustomArray',
            label: {
              text: 'Valores',
            },
            scope: '#/properties/cashRegisterValues',
            options: {
              readonly: true,
              elementLabelProp: 'paymentTypeName',
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Tipo',
                        scope: '#/properties/paymentTypeId',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Conta',
                        scope: '#/properties/unitFinanceId',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Valor',
                        scope: '#/properties/value',
                      },
                    ],
                  },
                ],
                rule: {
                  effect: 'ENABLE',
                  condition: {
                    scope: '#/properties/readonly',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            },
          },
        ],
      },
    ],
    rule: {
      effect: 'ENABLE',
      condition: {
        scope: '#/properties/readonly',
        schema: {
          const: false,
        },
      },
    },
  },
  tableSchema: [
    {
      id: 'numberCashRegister',
      label: 'Núm. Caixa',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    { id: 'date', label: 'Data', minWidth: 100, align: 'center' },
    {
      id: 'employee.user.name',
      label: 'Funcionário',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
    {
      id: 'cashRegisterValues',
      label: 'Total',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'CashRegisterCloseController@getAll',
    getAllByUnit: 'CashRegisterCloseController@getAllByUnit',
    getAllByClinic: 'CashRegisterCloseController@getAllByClinic',
    getUnitLastCashRegister:
      'CashRegisterCloseController@getUnitLastCashRegister',
    findById: 'CashRegisterCloseController@findById',
    create: 'CashRegisterCloseController@create',
    update: 'CashRegisterCloseController@patchUpdate',
    setBlocked: 'CashRegisterCloseController@setBlocked',
    setEvaluation: 'CashRegisterCloseController@setEvaluation',
    unlockCashRegister: 'CashRegisterCloseController@unlockCashRegister',
    setDisapproved: 'CashRegisterCloseController@setDisapproved',
  },
};

export default CashRegisterClose;
