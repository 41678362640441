import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import OrderService from '../../../../../forms/orderService';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import OrderServiceDialog from '../../../../../components/orderServiceDialog';
import FilterTree from '../../../../../helpers/filterTree';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  Container,
  AddToQueue,
  Preview,
  ReceiptRefund,
  ExclamationTriangleFill,
  AddBox,
  Check,
  Printer,
  PrintOS,
  Cancel,
} from './styles';
import ViewUrgencyDialog from '../../../../../components/viewUrgencyDialog';
import DentistRefusedDialog from '../../../../../components/dentistRefusedDialog';
import SubmitDialog from '../../../../../components/submitDialog';
import VoucherOS from '../../../../../components/voucherOS';
import { useReactToPrint } from 'react-to-print';

function OrderServiceList({ history, ...props }) {
  const userData = useSelector((state) => state.authReducer?.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [selectedRefuseStep, setSelectedRefuseStep] = useState(false);
  const [openRejection, setOpenRejection] = useState(false);
  const [justification, setJustification] = useState(null);
  const [orderServiceStepId, setOrderServiceStepId] = useState(null);
  const [workflowPosition, setWorkflowPosition] = useState(-1);
  const [createPath, setCreatePath] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [createDialogText, setCreateDialogText] = useState('');
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [orderService, setOrderService] = useState(null);
  const [newPrint, setNewPrint] = useState(false);
  const [printJustification, setPrintJustification] = useState(false);
  const componentRef = useRef();

  const columns = OrderService.tableSchema;

  const loadServiceOrders = useCallback(() => {
    restApiClient.orderServiceStep
      .getAllOrderServiceStepByDentistId(
        userData.id,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('orderService.id', searchBy)
              .or()
              .like('orderService.patient.name', searchBy)
              .or()
              .like('orderService.patient.record_number', searchBy)
              .or()
              .like('step.name', searchBy)
              .or()
              .like('orderService.endProduct.name', searchBy)
              .or()
              .like('status', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, userData.id]);

  useEffect(() => {
    loadServiceOrders();
  }, [loadServiceOrders]);

  useEffect(() => {
    if (newPrint) {
      handlePrint();
      setNewPrint(false);
      setPrintJustification(false);
    }
  }, [newPrint]);

  const handleAlertClose = (event, reason) => {
    setOpenMessage(false);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Ordem de Serviço`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }`,
  });

  return (
    <Container>
      <CrudTable
        title={'Minhas Ordens de Serviço'}
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: (
              <span>
                Você tem certeza que deseja finalizar a ordem de serviço?
              </span>
            ),
            onSuccess: () => {
              restApiClient.orderServiceStep
                .setFinalized(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                  setSubmitConfirmationId(-1);
                });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          add: {
            onClick: (id) => setOpen(true),
            title: 'Criar Ordem de Serviço',
            allowDentist: true,
          },
          delete: {
            onClick: (orderServiceStepId) => {
              restApiClient.orderServiceStep
                .delete(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                });
            },
            allowDentist: true,
            selector: (row) => {
              return (
                (row?.status === 'Em Edição (Dentista)' ||
                  row?.status === 'Submetida (Clínica)' ||
                  row?.status === 'Submetida em Grupo') &&
                row?.current
              );
            },
          },
          addOrderServiceStep: {
            icon: <AddToQueue />,
            onClick: (orderServiceId, row) => {
              if (row) {
                history.push({
                  pathname: `/dentist/lab/orderService/update/${row.orderService.id}/${row.workflowPosition}`,
                  state: {
                    date: row.createdAt,
                  },
                });
              } else {
                history.push(
                  `/dentist/lab/orderService/create/${row.orderService.id}/${
                    row.orderService.endProductId
                  }/${0}`
                );
              }
            },
            title: 'Atualizar uma etapa',
            allowDentist: true,
            selector: (row) => row?.status === 'Em Edição (Dentista)',
          },
          viewStep: {
            icon: <Preview />,
            onClick: (orderServiceId, row) => {
              history.push({
                pathname: `/dentist/lab/orderService/update/${row.orderService.id}/${row.workflowPosition}`,
                state: {
                  date: row.createdAt,
                  typeRequest: 'view',
                },
              });
            },
            title: 'Visualizar Etapa',
            allowDentist: true,
            selector: (row) => row?.status !== 'Em Edição (Dentista)',
          },
          addNextStep: {
            icon: <AddBox />,
            onClick: (orderServiceId, row) => {
              setOpenCreateDialog(true);
              setCreateDialogText(
                'Ao iniciar uma nova etapa não será mais possível modificar a etapa anterior, você deseja continuar?'
              );
              setCreatePath(
                `/dentist/lab/orderService/update/${row.orderService.id}/${row.workflowPosition}`
              );
            },
            title: 'Adicionar Próxima Etapa',
            allowDentist: true,
            selector: (row) =>
              (row?.status === 'Etapa Concluída (Dentista)' ||
                row?.status === 'Etapa Concluída e Recusada (Laboratório)') &&
              row.nextStep,
          },
          refuseOrder: {
            icon: <ReceiptRefund />,
            onClick: (orderServiceId, row) => {
              setSelectedRefuseStep(row.id);
              setOrderService({
                ...row.orderService,
                orderServiceStep: { ...row },
              });
              setOpenRefuse(true);
            },
            title: 'Recusar Ordem de Serviço',
            allowDentist: true,
            selector: (row) =>
              row?.status === 'Recebido (Clínica)' ||
              row?.status === 'Finalizada' ||
              row?.status === 'Etapa Concluída (Dentista)',
          },
          viewRejection: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceId, row) => {
              setOrderServiceStepId(row.id);
              setOpenRejection(true);
              setJustification(row?.rejectedJustification);
              setWorkflowPosition(row.workflowPosition);
            },
            allowDentist: true,
            title: 'Visualizar Justificativa da Recusa',
            selector: (row) => row?.status === 'Ajuste (Dentista)',
          },
          acceptOrderService: {
            icon: <Check />,
            onClick: (id, row) => {
              const lastWorkflow = row.orderService.endProduct.workflows.reduce(
                (workFlowPrev, workFlowCurrent) =>
                  workFlowPrev.position > workFlowCurrent.position
                    ? workFlowPrev
                    : workFlowCurrent
              );

              if (row?.stepId === lastWorkflow?.stepId) {
                setOrderServiceStepId(row.id);
                setSubmitConfirmationId(id);
              } else {
                restApiClient.orderServiceStep
                  .setStepCompleted(row.id)
                  .then(() => {
                    loadServiceOrders();
                  });
              }
            },
            allowDentist: true,
            title: 'Aceitar Ordem de Serviço',
            selector: (row) => row?.status === 'Recebido (Clínica)',
          },
          printOrder: {
            icon: <Printer />,
            selector: (row) => row?.status !== 'Em Edição (Dentista)',
            onClick: (id, row) => {
              setOrderService({
                ...row.orderService,
                orderServiceStep: { ...row },
              });
              setNewPrint(true);
            },
            title: 'Imprimir OS',
            allowDentist: true,
          },
          suspendedOS: {
            icon: <Cancel />,
            selector: (row) =>
              row?.status === 'Recebido (Clínica)' ||
              row?.status === 'Etapa Concluída (Dentista)',
            onClick: (id) => {
              restApiClient.orderServiceStep
                .suspendOrderService(id)
                .then(() => {
                  loadServiceOrders();
                });
            },
            title: 'Suspender OS',
            allowDentist: true,
          },
        }}
      />

      <OrderServiceDialog
        open={open}
        orderService={OrderService}
        onCancel={() => setOpen(false)}
        onSuccess={(result) => {
          setOpen(false);
          //Caso seja um novo EndProduct - cria um novo step
          history.push({
            pathname: `/dentist/lab/orderService/create/${result.data.id}/${
              result.data.endProductId
            }/${0}`,
            state: {
              patientName: result.data?.patient?.name,
              recordNumber: result.data?.patient?.recordNumber,
              unitId: result.data?.patient?.unitId,
            },
          });
        }}
        {...props}
      />

      <Snackbar
        open={openMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {'OS Salva com Sucesso'}
        </Alert>
      </Snackbar>

      <SubmitDialog
        open={openCreateDialog}
        onCancel={() => setOpenCreateDialog(false)}
        onSuccess={() => {
          setOpenCreateDialog(false);
          if (createPath) {
            history.push({
              pathname: createPath,
              state: {
                typeRequest: 'create',
              },
            });
          }
        }}
        dialogText={createDialogText}
      />

      <DentistRefusedDialog
        open={openRefuse}
        orderServiceStepId={selectedRefuseStep}
        onCancel={() => {
          setOpenRefuse(false);
        }}
        onSuccess={(justification, stepFinished) => {
          if (!stepFinished) {
            setPrintJustification(justification);
            setNewPrint(true);
          }
          setOpenRefuse(false);
          loadServiceOrders();
        }}
      />

      <ViewUrgencyDialog
        open={openRejection}
        title={'Recusa - Justificativa'}
        justification={justification}
        orderServiceStepId={orderServiceStepId}
        onCancel={() => {
          setOpenRejection(false);
        }}
        onSuccess={(orderServiceStep) => {
          const modelOrderServiceStep = {
            orderServiceId: orderServiceStep.orderServiceId,
            dentistId: orderServiceStep.dentistId,
            stepId: orderServiceStep.stepId,
            unitId: orderServiceStep.unitId,
            opposite: orderServiceStep.opposite
              ? orderServiceStep.opposite
              : false,
            waxBite: orderServiceStep.waxBite
              ? orderServiceStep.waxBite
              : false,
            toothNumber: orderServiceStep.toothNumber,
            dentalArch: orderServiceStep.dentalArch,
            toothShadeId: orderServiceStep.toothShadeId,
            observation: orderServiceStep.observation,
            current: orderServiceStep.current,
          };
          restApiClient.orderServiceStep
            .create(modelOrderServiceStep)
            .then((e) => {
              history.push(
                `/dentist/lab/orderService/update/${e.data.orderServiceId}/${workflowPosition}`
              );
            });
        }}
      />

      <PrintOS ref={componentRef}>
        <VoucherOS
          orderService={orderService}
          printJustification={printJustification}
        />
      </PrintOS>
    </Container>
  );
}

export default OrderServiceList;
