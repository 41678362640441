import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsCellProps, withJsonFormsEnumProps } from '@jsonforms/react';
import SelectServiceSupplierDialogControl from '../../components/selectServiceSupplierDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectServiceSupplierDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectServiceSupplierDialog'
  )
);

const customSelectServiceSupplierDialogControlTester = rankWith(
  4,
  isSelectServiceSupplierDialogControl
);

export default {
  tester: customSelectServiceSupplierDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectServiceSupplierDialogControl),
};
