import React, { useState, useEffect, useCallback } from 'react';
import CashRegisterClose from '../../../../../../forms/cashRegisterClose';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import {
  Container,
  Preview,
  UnlockAlt,
  Assignment,
  CancelButton,
} from './styles';

function PendingCashRegisterCloseList({ history, location }) {
  const consolidationDate = location?.state?.consolidationDate;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = CashRegisterClose.tableSchema;

  const loadcashRegisterCloses = useCallback(() => {
    if (consolidationDate) {
      restApiClient.cashRegisterClose
        .getAll(
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp
                    .df('status', 'Bloqueado')
                    .lte('date', consolidationDate);
                  rightOp
                    .like('numberCashRegister', searchBy)
                    .or()
                    .like('status', searchBy)
                    .or()
                    .like('unit.name', searchBy)
                    .or()
                    .like('employee.user.name', searchBy);
                })
                .toString()
            : new FilterTree()
                .df('status', 'Bloqueado')
                .lte('date', consolidationDate)
                .toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadcashRegisterCloses();
  }, [loadcashRegisterCloses]);

  return (
    <Container>
      <CrudTable
        title={'Fechamento de Caixa'}
        modelActions={CashRegisterClose.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          update: {
            onClick: (expenseId, row) => {
              history.push({
                pathname: `/financial/cashRegisterClose/update/${expenseId}`,
                state: {
                  adjust: row.status === 'Em Ajuste',
                },
              });
            },
            allowedActions: [
              CashRegisterClose.actions.findById,
              CashRegisterClose.actions.update,
              CashRegisterClose.actions.setBlocked,
              CashRegisterClose.actions.getAllByUnit,
            ],
            selector: (row) =>
              row.status === 'Em Edição' || row.status === 'Em Ajuste',
          },
          unlockCashRegister: {
            icon: <UnlockAlt />,
            onClick: (id) => {
              restApiClient.cashRegisterClose
                .unlockCashRegister(id)
                .then(() => loadcashRegisterCloses());
            },
            allowedActions: [CashRegisterClose.actions.unlockCashRegister],
            title: 'Desbloquear Fechamento de Caixa',
            selector: (row) => row.status === 'Bloqueado',
          },
          evaluateCashRegister: {
            icon: <Assignment />,
            onClick: (id) => {
              history.push({
                pathname: `/financial/cashRegisterClose/${id}`,
                state: { evaluate: true },
              });
            },
            allowedActions: [
              CashRegisterClose.actions.setBlocked,
              CashRegisterClose.actions.setDisapproved,
            ],
            title: 'Avaliar Fechamento de Caixa',
            selector: (row) => row.status === 'Em Avaliação',
          },
          view: {
            icon: <Preview />,
            onClick: (cashRegisterCloseId) => {
              history.push({
                pathname: `/financial/cashRegisterClose/${cashRegisterCloseId}`,
                state: { readonly: true },
              });
            },
            title: 'Visualizar Fechamento de Caixa',
            selector: (row) => row.status !== 'Em Avaliação',
          },
        }}
        customCellRenderers={{
          date: {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          cashRegisterValues: {
            renderer: (field, value) => {
              return value
                .reduce((accumulator, currentValue) => {
                  return accumulator + currentValue.value;
                }, 0)
                .toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.status === 'Em Avaliação') {
            return '#ffe0e0';
          }
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />
    </Container>
  );
}

export default PendingCashRegisterCloseList;
