import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportReworkHistory = {
  buildPdf: (result, date) => {
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;

    doc.setFont('Arial');
    doc.setFontSize(11);
    doc.text(`Período: ${date?.format('MM/YYYY')}`, 155, (y += 8));
    doc.text(
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds(),
      155,
      (y += 5)
    );
    doc.setFontSize(18);
    doc.text(
      `Histórico Bancário (Mensal)`,
      doc.internal.pageSize.getWidth() / 2,
      (y += 10),
      { align: 'center' }
    );
    y += 5;
    //CORPO DO PDF
    let bodyValues = [];
    result.unitFinances.forEach(function (unitFinance) {
      // PARA CADA CONTA FINANCEIRA
      let accountName =
        unitFinance.type === 'Banco'
          ? unitFinance?.bankAccount?.holderName ?? '-'
          : unitFinance?.type === 'Caixa'
          ? `${unitFinance?.type} Financeiro`
          : unitFinance?.type === 'Cheque'
          ? `${unitFinance?.type}`
          : '-';
      bodyValues.push([
        accountName,
        unitFinance?.bankAccount?.bank ?? '-',
        unitFinance?.bankAccount?.agency
          ? `${unitFinance?.bankAccount?.agency}${
              unitFinance?.bankAccount?.agencyDigit
                ? `-${unitFinance?.bankAccount?.agencyDigit}`
                : ''
            }`
          : '-',
        unitFinance?.bankAccount
          ? `${unitFinance?.bankAccount?.account}${
              unitFinance?.bankAccount?.accountDigit
                ? `-${unitFinance?.bankAccount?.accountDigit}`
                : ''
            }`
          : '-',
        parseFloat(unitFinance?.firstValue).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        parseFloat(unitFinance?.lastValue).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        parseFloat(unitFinance?.monthlyBalance).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      ]);
    });

    doc.autoTable({
      startY: (y += 2),
      showHead: 'firstPage',
      styles: {
        halign: 'center',
        valign: 'middle',
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: [55, 112, 222],
        textColor: [255, 255, 255],
        fontSize: 9,
      },
      bodyStyles: {
        fontSize: 9,
        textColor: [0, 0, 0],
      },
      head: [
        [
          'Titular',
          'Banco',
          'Agência-Dígito',
          'Conta-Dígito',
          'Saldo Inicial',
          'Saldo Final',
          'Resultado',
        ],
      ],
      body: [
        ...bodyValues,
        [
          {
            content: 'TOTAL',
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 10,
              fontStyle: 'bold',
              textColor: [25, 25, 25],
              fillColor: [225, 225, 225],
            },
            colSpan: 6,
          },
          {
            content: parseFloat(result?.total ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 10,
              fontStyle: 'bold',
              textColor: [25, 25, 25],
              fillColor: [225, 225, 225],
            },
            colSpan: 1,
          },
        ],
      ],
      columnStyles: {
        4: { cellWidth: 'wrap' },
        5: { cellWidth: 'wrap' },
        6: { cellWidth: 'wrap' },
      },
      theme: 'grid',
      margin: 5,
      rowPageBreak: 'avoid',
    });
    y = doc.lastAutoTable.finalY;

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + '/' + String(pageCount), 198, 295);
    }
    return doc;
  },
};

export default ReportReworkHistory;
