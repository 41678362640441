import React from 'react';
import Role from '../../../forms/role';
import CreateUpdateForm from '../../../components/createUpdateForm';
import restApiClient from '../../../services/restApiClient';
import { Container } from './style';

function RoleCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Role}
        restGetDataById={restApiClient.role.findById}
        restCreateData={restApiClient.role.create}
        restUpdateData={restApiClient.role.update}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default RoleCreateUpdate;
