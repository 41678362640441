const CashRegisterValue = {
  tableSchema: [
    {
      id: 'cashRegisterClose.numberCashRegister',
      label: 'Núm. Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.employee.user.name',
      label: 'Funcionário',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'paymentType.name',
      label: 'Tipo',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'unitFinance.bankAccount.holderName',
      label: 'Conta',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor Bruto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'confirmed',
      label: 'Valor Confirmado',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'liquid',
      label: 'Valor Líquido',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  clinicCardtableSchema: [
    {
      id: 'cashRegisterClose.numberCashRegister',
      label: 'Núm. Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.employee.user.name',
      label: 'Funcionário',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'paymentType.name',
      label: 'Tipo',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'unitFinance.bankAccount.holderName',
      label: 'Conta',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'confirmed',
      label: 'Valor Lançado',
      minWidth: 100,
      align: 'center',
    },
  ],
  checkTableSchema: [
    {
      id: 'cashRegisterClose.numberCashRegister',
      label: 'Núm. Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.employee.user.name',
      label: 'Usuário',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  clinicCardtableSchema: [
    {
      id: 'cashRegisterClose.numberCashRegister',
      label: 'Núm. Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.employee.user.name',
      label: 'Funcionário',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'paymentType.name',
      label: 'Tipo',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'unitFinance.bankAccount.holderName',
      label: 'Conta',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'confirmed',
      label: 'Valor Lançado',
      minWidth: 100,
      align: 'center',
    },
  ],
  drePaymentTableSchema: [
    {
      id: 'cashRegisterClose.numberCashRegister',
      label: 'Núm. Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.employee.user.name',
      label: 'Usuário',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  clinicCheckTableSchema: [
    {
      id: 'cashRegisterClose.numberCashRegister',
      label: 'Núm. Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterClose.employee.user.name',
      label: 'Usuário',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'confirmed',
      label: 'Valor Lançado',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'CashRegisterValueController@getAllCards',
    getAllToDre: 'CashRegisterValueController@getAllToDre',
    getAllChecks: 'CashRegisterValueController@getAllChecks',
    getAllCardsByCashRegisterId:
      'CashRegisterValueController@getAllCardsByCashRegisterId',
    getAllChecksByCashRegisterId:
      'CashRegisterValueController@getAllChecksByCashRegisterId',
  },
};

export default CashRegisterValue;
