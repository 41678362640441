import styled from 'styled-components';
import {
  NotesMedical as MaterialNotesMedical
} from '../../../../../styles/icons';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const NotesMedical = styled(MaterialNotesMedical)`
  width: 23px;
  height: 23px;
  color: var(--secondary);
`;

export const SelectContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin: auto !important;
  }
`;

export const MuiPickersUtilsProvider = styled(MaterialMuiPickersUtilsProvider)`
  padding: 0 !important;
`;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  width: 40%;
  padding: 0 !important;
`;


