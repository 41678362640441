import styled from 'styled-components';
import { Box as MaterialBox } from '../../../../styles/icons';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
