import styled from 'styled-components';
import {
  DialogContent as MaterialDialogContent,
  TextField as MaterialTextField,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  Button as MaterialButton,
  Card as MaterialCard,
  CardHeader as MaterialCardHeader,
  FormControl as MaterialFormControl,
  FormLabel as MaterialFormLabel,
  FormControlLabel as MaterialFormControlLabel,
  Radio as MaterialRadio,
  RadioGroup as MaterialRadioGroup,
  FormHelperText as MaterialFormHelperText,
} from '@material-ui/core';


export const Dialog = styled(MaterialDialog)``;

export const Container = styled.div``;

export const DialogContent = styled(MaterialDialogContent)`
  width: 500px !important;
`;

export const InputTextarea = styled(MaterialTextField)`
  width: 100%;
  color: white;
`;

export const DialogActions = styled(MaterialDialogActions)``;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  & > * {
    margin: auto !important;
  }
`;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px 10px 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CardHeader = styled(MaterialCardHeader)`
`;

export const FormDiv = styled.div`
  margin: 0px 15px 15px 15px;
`;

export const FormControl = styled(MaterialFormControl)``;

export const FormLabel = styled(MaterialFormLabel)``;

export const FormControlLabel = styled(MaterialFormControlLabel)``;

export const Radio = styled(MaterialRadio)``;

export const RadioGroup = styled(MaterialRadioGroup)``;

export const FormHelperText = styled(MaterialFormHelperText)``;
