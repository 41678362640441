import React, { useState, useEffect, useCallback, useRef } from 'react';
import OrderService from '../../../../../forms/orderService';
import OrderServiceStep from '../../../../../forms/orderServiceStep';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  Printer,
  PrintOS,
  Preview,
  Check,
  Cancel,
  Restore,
} from './styles';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import VoucherOS from '../../../../../components/voucherOS';

function ClinicOrderServiceList({ history, props }) {
  const selectedUnit = useSelector((x) => x.authReducer.user?.unitId ?? -1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [orderService, setOrderService] = useState(null);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [orderServiceStepId, setOrderServiceStepId] = useState(null);
  const componentRef = useRef();
  const columns = OrderService.clinicTableSchema;

  const loadServiceOrders = useCallback(() => {
    restApiClient.orderService
      .getAllByClinic(
        selectedUnit,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('orderServiceStep.id', searchBy)
              .or()
              .like('patient.name', searchBy)
              .or()
              .like('patient.record_number', searchBy)
              .or()
              .like('orderServiceStep.dentistUser.name', searchBy)
              .or()
              .like('endProduct.name', searchBy)
              .or()
              .like('orderServiceStep.status', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedUnit]);

  useEffect(() => {
    loadServiceOrders();
  }, [loadServiceOrders]);

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Ordem de Serviço`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }
      @media print { 
        grid {
          page-break-inside: avoid;
        }
      }`,
  });

  return (
    <Container>
      <CrudTable
        title={'Acompanhamento de Ordens de Serviço'}
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: (
              <span>
                Você tem certeza que deseja finalizar a ordem de serviço?
              </span>
            ),
            onSuccess: () => {
              restApiClient.orderServiceStep
                .setFinalized(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                  setSubmitConfirmationId(-1);
                });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          view: {
            icon: <Preview />,
            onClick: (orderServiceId, row) => {
              if (row.orderServiceStep) {
                history.push({
                  pathname: `/lab/orderService/update/${orderServiceId}/${row.workflowPosition}`,
                  state: {
                    dentistId: row.orderServiceStep.dentistId,
                    date: row.orderServiceStep.createdAt,
                    allowView: true,
                    patientName: row.orderService?.patient?.name,
                    recordNumber: row.orderService?.patient?.recordNumber,
                  },
                });
              }
            },
            title: 'Visualizar OS',
          },
          acceptOrderService: {
            icon: <Check />,
            onClick: (id, row) => {
              const lastWorkflow = row.endProduct.workflows.reduce(
                (workFlowPrev, workFlowCurrent) =>
                  workFlowPrev.position > workFlowCurrent.position
                    ? workFlowPrev
                    : workFlowCurrent
              );

              if (row.orderServiceStep?.stepId === lastWorkflow.stepId) {
                setOrderServiceStepId(row.orderServiceStep.id);
                setSubmitConfirmationId(id);
              } else {
                restApiClient.orderServiceStep
                  .setStepCompleted(row.orderServiceStep.id)
                  .then(() => {
                    loadServiceOrders();
                  });
              }
            },
            title: 'Aceitar Ordem de Serviço',
            allowedActions: [
              OrderServiceStep.actions.setStepCompleted,
              OrderServiceStep.actions.setFinalized,
            ],
            selector: (row) =>
              row.orderServiceStep?.status === 'Recebido (Clínica)' &&
              new Date(moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00') >
                new Date(
                  row.orderServiceStep?.schedule?.labReturnDate + ' 00:00:00'
                ),
          },
          printOS: {
            icon: <Printer />,
            onClick: async (orderServiceId, row) => {
              await setOrderService(row);
              handlePrint();
            },
            title: 'Imprimir Etapa',
            selector: (row) =>
              row.orderServiceStep?.status === 'Enviada (Laboratório)',
          },
          suspendedOS: {
            icon: <Cancel />,
            selector: (row) =>
              row?.orderServiceStep?.status === 'Recebido (Clínica)' ||
              row?.orderServiceStep?.status === 'Etapa Concluída (Dentista)',
            onClick: (id, row) => {
              restApiClient.orderServiceStep
                .suspendOrderService(row?.orderServiceStep?.id)
                .then(() => {
                  loadServiceOrders();
                });
            },
            title: 'Suspender OS',
            allowedActions: [OrderServiceStep.actions.suspendOrderService],
          },
          resumeOS: {
            icon: <Restore />,
            selector: (row) => row?.orderServiceStep?.status === 'OS Suspensa',
            onClick: (id, row) => {
              restApiClient.orderServiceStep
                .resumeOrderService(row?.orderServiceStep?.id)
                .then(() => {
                  loadServiceOrders();
                });
            },
            title: 'Retomar OS',
            allowedActions: [OrderServiceStep.actions.resumeOrderService],
          },
        }}
        customCellRenderers={{
          'orderServiceStep.schedule.patientReturnDate': {
            renderer: (field, value) => {
              const date = new Date(value + ' 00:00:00');
              return value ? `${date.toLocaleDateString('pt-br')}` : '-';
            },
          },
          'orderServiceStep.schedule.labReturnDate': {
            renderer: (field, value) => {
              const date = new Date(value + ' 00:00:00');
              return value ? `${date.toLocaleDateString('pt-br')}` : '-';
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          const currentDate = new Date(
            moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00'
          );
          let labDate = new Date(
            row?.orderServiceStep?.schedule?.labReturnDate + ' 00:00:00'
          );

          if (
            row?.orderServiceStep?.status === 'Em Fila (Laboratório)' &&
            labDate <= currentDate
          ) {
            return '#ffe0e0';
          }
        }}
      />

      <PrintOS ref={componentRef}>
        <VoucherOS isClinic orderService={orderService} />
      </PrintOS>
    </Container>
  );
}

export default ClinicOrderServiceList;
