const Report = {
  actions: {
    getItemWithdrawReportByUnit:
      'ItemWithdrawController@getItemWithdrawReportByUnit',
    getCsvItemWithdrawReportByUnit:
      'ItemWithdrawController@getCsvItemWithdrawReportByUnit',
    getCsvItemInStockByUnit: 'ItemController@getCsvItemInStockByUnit',
    getCsvItemInStockByUnitAndGroup:
      'ItemController@getCsvItemInStockByUnitAndGroup',
    getAllGroupValuesByPeriod: 'TransferController@getAllGroupValuesByPeriod',
    getAllStockHistoriesValuesByPeriod:
      'StockHistoryController@getAllStockHistoriesValuesByPeriod',
    getCompletedSalesReport: 'SaleController@getCompletedSalesReport',
    getCsvCompletedSalesReport: 'SaleController@getCsvCompletedSalesReport',
    getStockValueByUnitId: 'StockController@getStockValueByUnitId',
    getCsvStockAdjustmentHistoryReportByUnit:
      'StockHistoryController@getCsvStockAdjustmentHistoryReportByUnit',
    getCsvTransferredItems: 'TransferController@getCsvTransferredItems',
    getCsvCustomerPriceList: 'ItemController@getCsvCustomerPriceList',
    getAllOSFinishedLabByPeriod:
      'OrderServiceStepController@getAllOSFinishedLabByPeriod',
    getAllOSOutsourcedByPeriod:
      'OutsourcedController@getAllOSOutsourcedByPeriod',
    getFinishedLabStepsCsvReport:
      'OrderServiceStepController@getFinishedLabStepsCsvReport',
    getReworkHistory: 'OrderServiceController@getReworkHistory',
    getFinishedProducts: 'EndProductController@getFinishedProducts',
    getCsvProduction: 'EmployeeController@getCsvProduction',
    getStepFlowCSVReport: 'OrderServiceStepController@getStepFlowCSVReport',
    getAllRadiologySchedulesByUnitByPeriod:
      'RadiologyScheduleController@getAllRadiologySchedulesByUnitByPeriod',
    getClinicSchedulesCSVReportByUnit:
      'RadiologyScheduleController@getClinicSchedulesCSVReportByUnit',
    getRadiologySchedulesCSVReport:
      'RadiologyScheduleController@getRadiologySchedulesCSVReport',
    getStockHistoryValue: 'MonthlyStockController@getStockHistoryValue',
    getStockHistoryValueCSVReport:
      'MonthlyStockController@getStockHistoryValueCSVReport',
    getFinishedProceduresCSVReport:
      'AttendanceController@getFinishedProceduresCSVReport',
    getFinishedHoursCSVReport:
      'DentistWorkedHourController@getFinishedHoursCSVReport',
    getFinishedPatientsCSVReport:
      'FinishedPatientServiceController@getFinishedPatientsCSVReport',
    getAllPayableExpensesByPeriod:
      'ExpenseController@getAllPayableExpensesByPeriod',
    getAllPayableExpensesByPeriodCsv:
      'ExpenseController@getAllPayableExpensesByPeriodCSV',
    getAllPaidExpensesByPeriod: 'ExpenseController@getAllPaidExpensesByPeriod',
    getAllPaidExpensesByPeriodCsv:
      'ExpenseController@getAllPaidExpensesByPeriodCSV',
    getAllExpensesByPeriod: 'ExpenseController@getAllExpensesByPeriod',
    getAllExpensesByPeriodCsv: 'ExpenseController@getAllExpensesByPeriodCSV',
    getCashRegisterClose: 'CashRegisterCloseController@getCashRegisterClose',
    getCashRegisterCloseCSV:
      'CashRegisterCloseController@getCashRegisterCloseCSV',
    getCashEntry: 'CashRegisterCloseController@getCashEntry',
    getCashEntryCSV: 'CashRegisterCloseController@getCashEntryCSV',
    getRemainingCash: 'CashRegisterCloseController@getRemainingCash',
    getRemainingCashCSV: 'CashRegisterCloseController@getRemainingCashCSV',
    getDailyBankingHistoryByPeriod:
      'UnitFinanceController@getDailyBankingHistoryByPeriod',
    getMonthlyBankingHistory: 'UnitFinanceController@getMonthlyBankingHistory',
    getDreCSV: 'ExpenseTypeController@getDreCSV',
    getDreByUnitCSV: 'ExpenseTypeController@getDreByUnitCSV',
    getClinicDreByUnitCSV: 'ExpenseTypeController@getClinicDreByUnitCSV',
    getFixedPaymentsCSVReport: 'SubstituteController@getFixedPaymentsCSVReport',
    getBankAccountMovements:
      'UnitFinanceController@getBankAccountMovementsByPeriod',
    getAllStockConsolidatedConsumptions:
      'StockConsumptionController@getAllStockConsolidatedConsumptions',
    getAllLabConsolidatedConsumptions:
      'LabConsumptionController@getAllLabConsolidatedConsumptions',
    getAllRadiologyConsolidatedConsumptions:
      'RadiologyConsumptionController@getAllRadiologyConsolidatedConsumptions',
  },
  clinicActions: {
    getCsvItemInClinicStockByGroup:
      'ItemController@getCsvItemInClinicStockByGroup',
    getAllGroupValuesInClinicByPeriod:
      'TransferController@getAllGroupValuesInClinicByPeriod',
    getAllClinicRadiologySchedulesByPeriod:
      'RadiologyScheduleController@getAllClinicRadiologySchedulesByPeriod',
    getClinicSchedulesCSVReport:
      'RadiologyScheduleController@getClinicSchedulesCSVReport',
    getRadiologySchedulesCSVReportClinic:
      'RadiologyScheduleController@getRadiologySchedulesCSVReportClinic',
    getCsvPendingSteps: 'OrderServiceStepController@getCsvPendingSteps',
    getFinishedLabStepsInClinicCSVReport:
      'OrderServiceStepController@getFinishedLabStepsInClinicCSVReport',
    getLabCostInClinicByPeriod:
      'OrderServiceStepController@getLabCostInClinicByPeriod',
    getCsvTransferredItemsInClinic:
      'TransferController@getCsvTransferredItemsInClinic',
  },
};

export default Report;
