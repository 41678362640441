import styled from 'styled-components';
import {
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  Button as MaterialButton,
  FormHelperText as MaterialFormHelperText,
  Hidden as MaterialHidden,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';
import {
  FileCsv as MaterialFileCsv,
  FilePdf as MaterialFilePdf,
} from '../../../styles/icons';
import SelectUnitFinanceDialog from '../../../components/selectUnitFinanceDialogControl';

export const MuiPickersUtilsProvider = styled(
  MaterialMuiPickersUtilsProvider
)``;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  margin-left: 10px !important;
`;

export const Container = styled.div``;

export const AutoDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const Hidden = styled(MaterialHidden)``;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const FormHelperText = styled(MaterialFormHelperText)``;

export const SelectContainer = styled.div`
  width: 300px;
  margin: 5px;
`;

export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const FooterContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;

  span {
    font-size: 1.5rem;
    font-weight: 400;
  }
`;

export const ExportCsvButton = styled(MaterialButton)`
  color: #ffffff !important;
  background: #2dbd17 !important;
  width: 205px;
`;

export const ExportPdfButton = styled(MaterialButton)`
  color: #ffffff !important;
  background: #de261d !important;
  width: 205px;
`;

export const FileCsv = styled(MaterialFileCsv)`
  width: 22px;
  height: 22px;
`;

export const FilePdf = styled(MaterialFilePdf)`
  width: 25px;
  height: 25px;
`;

export const ButtonContainer = styled.div`
  margin: 5px;
`;

export const CircularProgress = styled(MaterialCircularProgress)``;

export const SelectUnitFinance = styled(SelectUnitFinanceDialog)`
  height: 44px;
`;
