import React, { useState } from 'react';
import Employee from '../../../forms/employee';
import CreateUpdateForm from '../../../components/createUpdateForm';
import restApiClient from '../../../services/restApiClient';

import { Container } from './styles';
import ShowPassDialog from '../../../components/showPassDialog';

function EmployeeCreateUpdate(props) {
  const [password, setPassword] = useState('');
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Employee}
        restGetDataById={restApiClient.employee.findById}
        restCreateData={restApiClient.employee.create}
        restUpdateData={restApiClient.employee.update}
        onCreateSuccess={(request) => {
          setPassword(request.data.password);
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
      <ShowPassDialog
        onClose={() => {
          props.history.goBack();
        }}
        password={password}
        open={password ? true : false}
      />
    </Container>
  );
}

export default EmployeeCreateUpdate;
