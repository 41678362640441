import React, { useState, useEffect } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  HeaderContainer,
  FooterContainer,
  Container,
  Hidden,
  AutoDiv,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  SelectContainer,
  InputLabel,
  ExportCsvButton,
  ExportPdfButton,
  FileCsv,
  FilePdf,
  ButtonContainer,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import { useSecurity } from '../../../../../extensions/hooks';
import Report from '../../../../../forms/report';
import Item from '../../../../../forms/item';
import Group from '../../../../../forms/group';
import SecuredItem from '../../../../../components/securedItem';
import ReportItemsStockByGroupClinic from '../../../../../pdf/reportItemsStockByGroupClinic';
import moment from 'moment';
import ReportStockComsumptionUnit from '../../../../../pdf/reportStockComsumptionUnit';

function ClinicStockReport() {
  const employee = useSelector((x) => x.authReducer.user);
  const [selectGroupVisible, setSelectGroupVisible] = useState(false);
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [groups, setGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateTo, setMaxDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [groupError, setGroupError] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const [reportError, setReportError] = useState('');
  const [finalDateError, setFinalDateError] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);

  const pdfSecurity = new Map();
  pdfSecurity.set('clinicStockItemsByGroup', [
    Item.actions.getAllItemInClinicStockByGroup,
  ]);
  pdfSecurity.set('consumptionUnit', [
    Report.clinicActions.getAllGroupValuesInClinicByPeriod,
  ]);

  const csvSecurity = new Map();
  csvSecurity.set('clinicStockItemsByGroup', [
    Report.clinicActions.getCsvItemInClinicStockByGroup,
  ]);
  csvSecurity.set('transferredItems', [
    Report.clinicActions.getCsvTransferredItemsInClinic,
  ]);

  const listReports = [
    useSecurity(
      { value: 'clinicStockItemsByGroup', label: 'Itens do Estoque (Grupo)' },
      [
        [
          Report.clinicActions.getCsvItemInClinicStockByGroup,
          Group.actions.getAllAsGroup,
        ],
        [
          Item.actions.getAllItemInClinicStockByGroup,
          Group.actions.getAllAsGroup,
        ],
        [Report.actions.getCsvTransferredItemsInClinic],
      ]
    ),
    useSecurity({ value: 'consumptionUnit', label: 'Custo Parcial (Grupo)' }, [
      [
        Report.clinicActions.getAllGroupValuesInClinicByPeriod,
        Report.actions.getAllStockHistoriesValuesByPeriod,
        Group.actions.getAllAsGroup,
      ],
    ]),
    useSecurity({ value: 'transferredItems', label: 'Itens Transferidos' }, [
      [Report.clinicActions.getCsvTransferredItemsInClinic],
    ]),
  ].filter((x) => !!x);

  useEffect(() => {
    if (
      ['clinicStockItemsByGroup', 'consumptionUnit'].includes(selectedReport)
    ) {
      restApiClient.group.getAllAsGroup().then((e) => {
        setGroups(e.data);
      });
    }
  }, [selectedReport]);

  async function loadAdjusts(transferData, unitId = null) {
    restApiClient.stockHistory
      .getAllStockHistoriesValuesByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        let stockHistoryData = e.data;
        let newStockComsumption = transferData;
        Object.keys(stockHistoryData).forEach(function (unit) {
          //para cada unidade do ajuste verificar se a unidade existe nas transferências
          // caso exista adicione apenas o result dentro do grupo
          // caso não exista adicione o grupo a unidade
          if (unit !== 'totalGroup') {
            Object.keys(stockHistoryData[unit].groups).forEach(function (
              value
            ) {
              if (newStockComsumption[unit]) {
                if (newStockComsumption[unit].groups[value]) {
                  const groupValue = newStockComsumption[unit].groups[value];
                  groupValue.result =
                    stockHistoryData[unit].groups[value].result;
                  groupValue.loss = stockHistoryData[unit].groups[value].loss;
                  newStockComsumption[unit].groups[value] = groupValue;
                } else {
                  newStockComsumption[unit].groups[value] =
                    stockHistoryData[unit].groups[value];
                }
              } else {
                newStockComsumption[unit] = stockHistoryData[unit];
              }
              newStockComsumption[unit].totalAdjustUnit =
                stockHistoryData[unit]?.totalAdjustUnit;
            });
          } else {
            Object.keys(stockHistoryData[unit].groups).forEach(function (
              value
            ) {
              if (stockHistoryData[unit].groups[value])
                if (newStockComsumption[unit]) {
                  if (newStockComsumption[unit].groups[value]) {
                    const groupValue = newStockComsumption[unit].groups[value];
                    groupValue.result =
                      stockHistoryData[unit].groups[value].result;
                    groupValue.loss = stockHistoryData[unit].groups[value].loss;
                    newStockComsumption[unit].groups[value] = groupValue;
                  } else {
                    newStockComsumption[unit].groups[value] =
                      stockHistoryData[unit].groups[value];
                  }
                } else {
                  newStockComsumption[unit] = stockHistoryData[unit];
                }
              newStockComsumption[unit].totalAdjustGlobal =
                stockHistoryData[unit]?.totalAdjustGlobal;
            });
          }
        });
        const doc = ReportStockComsumptionUnit.buildStockComsumptionPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          { count: 1, values: [employee?.unit] },
          groups,
          newStockComsumption
        );
        doc.save(
          `RELATORIO_CUSTO_PARCIAL_${employee?.unit.name.replaceAll(
            ' ',
            '_'
          )}.pdf`
        );
        setFetching(false);
      });
  }

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios do Estoque</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setReportError('');
                switch (e.target.value) {
                  case 'clinicStockItemsByGroup':
                    setExportCsvVisible(true);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(false);
                    setSelectGroupVisible(true);
                    break;
                  case 'consumptionUnit':
                    setExportCsvVisible(false);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setSelectGroupVisible(false);
                    break;
                  case 'transferredItems':
                    setExportCsvVisible(true);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setSelectGroupVisible(false);
                    break;
                  default:
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setSelectGroupVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
        <Hidden xsUp={!selectGroupVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(groupError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma categoria
              </InputLabel>
              <Select
                value={selectedGroup || ''}
                label="Selecione uma categoria"
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                  setGroupError('');
                }}
              >
                {[
                  <MenuItem value="" key={'empty'}>
                    <em>Nenhuma</em>
                  </MenuItem>,
                ].concat(
                  groups?.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>
                {!isEmpty(groupError) ? groupError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
      </AutoDiv>

      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setInitialDateError(value)}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateTo}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateTo
              ).format('DD/MM/YYYY')}.`}
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setFinalDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem
                    allowedActions={[csvSecurity.get(selectedReport)]}
                  >
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'clinicStockItemsByGroup':
                            if (selectedGroup) {
                              setFetching(true);
                              restApiClient.item
                                .getCsvItemInClinicStockByGroup(selectedGroup)
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setGroupError('Selecione uma opção válida.');
                            }
                            break;
                          case 'transferredItems':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              setFetching(true);
                              restApiClient.transfer
                                .getCsvTransferredItemsInClinic(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem
                    allowedActions={[pdfSecurity.get(selectedReport)]}
                  >
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'clinicStockItemsByGroup':
                            if (selectedGroup) {
                              setFetching(true);
                              restApiClient.item
                                .getAllItemInClinicStockByGroup(selectedGroup)
                                .then((e) => {
                                  const doc =
                                    ReportItemsStockByGroupClinic.buildItemListPdf(
                                      employee?.unit.name,
                                      e.data
                                    );
                                  doc.save(
                                    `RELATORIO_ITENS_${employee?.unit.name.replaceAll(
                                      ' ',
                                      '_'
                                    )}_POR_GRUPO.pdf`
                                  );
                                  setFetching(false);
                                });
                            } else {
                              setGroupError('Selecione uma opção válida.');
                            }
                            break;
                          case 'consumptionUnit':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              setFetching(true);
                              restApiClient.transfer
                                .getAllGroupValuesInClinicByPeriod(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  loadAdjusts(e.data, employee?.unitId);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>
    </Container>
  );
}

export default ClinicStockReport;
