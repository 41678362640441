import React, { useEffect, useState } from 'react';
import {
  Container,
  Select, 
  SelectContainer,
  FormControl, 
  InputLabel, 
  MenuItem, 
  StyledCreateUpdateForm,
  AutoDiv,
} from './styles';
import Alert from '@material-ui/lab/Alert';
import { first, isEmpty, isEqualWith, uniqueId } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import OpeningHour from '../../../../forms/openingHour';
import { objectMap } from '../../../../extensions/object';
import restApiClient from '../../../../services/restApiClient';
import CircularProgress from '@material-ui/core/CircularProgress';

function OpeningHoursCreateUpdate(props) {
  const [dayOfWeek, setDayOfWeek] = useState('monday');
  const [treatments, setTreatments] = useState(null);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [key, setKey] = useState(-1);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  
  const daysOfTheWeek = [
    { id: 'sunday', name: 'Domingo' },
    { id: 'monday', name: 'Segunda-Feira' },
    { id: 'tuesday', name: 'Terça-Feira'},
    { id: 'wednesday', name: 'Quarta-Feira' },
    { id: 'thursday', name: 'Quinta-Feira'},
    { id: 'friday', name: 'Sexta-Feira' },
    { id: 'saturday', name: 'Sábado' },
  ];

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  useEffect(() => {
    restApiClient.unitRadiologyService
      .getAllByUnit()
      .then(async (e) => {
        setTreatments(e.data.values);
        setSelectedTreatment(first(e.data.values).treatment.id);
      });
  }, []);
  
  return selectedTreatment ? (
    <Container>
      <SelectContainer>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="outlined-selectedUnit-native-simple">
            Selecione um Tratamento
          </InputLabel>
          <Select
            id="treatment"
            value={selectedTreatment || ''}
            label="Selecione um Tratamento"
            onChange={(e) => {
              setSelectedTreatment(e.target.value);
              setKey(uniqueId());
            }}
          >
            {treatments?.map((optionValue) => (
              <MenuItem value={optionValue.treatment.id} key={optionValue.treatment.id}>
                {`${optionValue?.treatment.name} (${optionValue?.treatment?.origin.name})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="outlined-selectedUnitId-native-simple">
            Selecione um dia da semana
          </InputLabel>
          <Select
            value={ dayOfWeek || '' }
            label="Selecione um dia da semana"
            onChange={(e) => {
              setDayOfWeek(e.target.value);
              setKey(uniqueId());
            }}
          >
            {
              daysOfTheWeek?.map((optionValue) => (
                <MenuItem disabled={optionValue.id === 'sunday'} value={optionValue.id} key={optionValue.id}>
                  {optionValue.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </SelectContainer>
      <StyledCreateUpdateForm
        forceId={-1}
        key={key}
        modelSchema={OpeningHour}
        restGetDataById={ async () => {
          const result = await restApiClient.openingHour.getAllByDay(dayOfWeek, selectedTreatment);
          result.data = objectMap(result.data, (key, value) => {
            if(isEmpty(value)) return [{}];
            return value;
          });
          return result;
        }}
        restUpdateData={ async (modelId, originalData, modelData) => {
          const added = modelData?.intervals.filter(
            item => !originalData.intervals.some(obj =>
              isEqualWith(obj, item, (obj1, obj2) =>
                obj1.dayOfWeek === obj2.dayOfWeek &&
                obj1.beginInterval === obj2.beginInterval &&
                obj1.endInterval === obj2.endInterval
              )
            )
          );

          const removed = originalData?.intervals.filter(
            item => !modelData.intervals.some(obj =>
              isEqualWith(obj, item, (obj1, obj2) =>
                obj1.id === obj2.id &&
                obj1.dayOfWeek === obj2.dayOfWeek &&
                obj1.beginInterval === obj2.beginInterval &&
                obj1.endInterval === obj2.endInterval
              )
            )
          ).map(item => item.id).filter((x) => x != null);
          
          const result = await restApiClient.openingHour.update(dayOfWeek, selectedTreatment, added, removed);
          
          if (result.status === 200 && !isEmpty(result.data)) {
            setAlert({
              message: 'Horário de funcionamento foi salvo com sucesso!',
              severity: 'success',
            });
          }
          return result;
        }}
        {...props}
      />
      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  ) : (
    <AutoDiv>
      <CircularProgress size={60} />
    </AutoDiv>
  );
}

export default OpeningHoursCreateUpdate;
