import React, { useState, useEffect, useCallback } from 'react';
import Group from '../../../forms/group';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, MoneySettings, Receipt } from './styles';
import ExpenseTypeDialog from '../../../components/expenseTypeDialog';
import CreateUpdateGroupDialog from '../../../components/createUpdateGroupDialog';

function GroupTreeView({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [openExpenseTypeDialog, setOpenExpenseTypeDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('expense');
  const [action, setAction] = useState({
    key: '',
    actionLabel: '',
    depthLabel: '',
  });

  const columns = Group.listSchema;

  const loadGroups = useCallback(() => {
    restApiClient.group
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('name', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Categorização'}
        modelActions={Group.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpen(true);
              setAction({
                key: 'add',
                actionLabel: 'Cadastro',
                depthLabel: 'Categoria',
              });
            },
          },
          update: {
            onClick: (groupId) => {
              setSelectedId(groupId);
              setOpen(true);
              setAction({
                key: 'edit',
                actionLabel: 'Edição',
                depthLabel: 'Categoria',
              });
            },
            allowedActions: [[Group.actions.findById, Group.actions.update]],
          },
          link: {
            icon: <Receipt />,
            onClick: (id) => {
              setSelectedId(id);
              setType('expense');
              setOpenExpenseTypeDialog(true);
            },
            maxDepth: 2,
            title: 'Vincular a um tipo de despesa',
            allowedActions: [[Group.actions.findById, Group.actions.update]],
          },
          linkIncome: {
            icon: <MoneySettings />,
            onClick: (id) => {
              setSelectedId(id);
              setType('income');
              setOpenExpenseTypeDialog(true);
            },
            maxDepth: 2,
            title: 'Vincular a um tipo de receita',
            allowedActions: [[Group.actions.findById, Group.actions.update]],
          },
          delete: {
            onClick: (groupId, index) => {
              restApiClient.group.delete(groupId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
        }}
        customCellRenderers={{
          expenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value?.name ?? '-';
              }
            },
          },
          incomeExpenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value?.name ?? '-';
              }
            },
          },
        }}
      />

      <ExpenseTypeDialog
        open={openExpenseTypeDialog}
        selectedId={selectedId}
        restModel={restApiClient.group}
        type={type}
        onClose={() => {
          setOpenExpenseTypeDialog(false);
        }}
        onSuccess={() => {
          loadGroups();
          setOpenExpenseTypeDialog(false);
        }}
      />

      <CreateUpdateGroupDialog
        open={open}
        restModel={restApiClient.group}
        action={action}
        parentId={selectedId}
        onCancel={() => {
          setOpen(false);
          setSelectedId(undefined);
          setType('expense');
        }}
        onSuccess={() => {
          loadGroups();
          setOpen(false);
        }}
      />
    </Container>
  );
}

export default GroupTreeView;
