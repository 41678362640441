const OrderServiceStep = {
  schema: {
    type: 'object',
    properties: {
      dentistId: {
        type: 'number',
        format: 'selectDentist',
        customErrors: '',
      },
      patientName: {
        type: 'string',
        customErrors: '',
      },
      recordNumber: {
        type: 'number',
        customErrors: '',
      },
      date: {
        type: 'string',
        customErrors: '',
      },
      unitId: {
        type: 'number',
        format: 'selectClinicDialog',
        customErrors: '',
      },
      endProductId: {
        type: 'number',
        format: 'selectEndProductDialog',
        customErrors: '',
        handleAsyncChanges: (data, validator) => {
          if (data?.hideSkipStep) {
            validator.asyncUpdate({
              showSkipStep: false,
              showGroupStep: false,
            });
          }
        },
      },
      stepId: {
        type: 'number',
        format: 'selectStepDialog',
        customErrors: '',
      },
      opposite: {
        type: 'boolean',
        customErrors: '',
      },
      waxBite: {
        type: 'boolean',
        customErrors: '',
      },
      dentalArch: {
        enum: ['Superior', 'Inferior', 'Ambas'],
        customErrors: '',
      },
      toothShadeId: {
        format: 'selectToothShadeDialog',
        customErrors: '',
      },
      toothNumber: {
        format: 'selectToothNumber',
        customErrors: '',
      },
      enableFields: {
        type: 'boolean',
        customErrors: '',
      },
      showDentalArch: {
        type: 'boolean',
        customErrors: '',
      },
      showSkipStep: {
        type: 'boolean',
        customErrors: '',
      },
      showToothNumber: {
        type: 'boolean',
        customErrors: '',
      },
      showToothShade: {
        type: 'boolean',
        customErrors: '',
      },
      skipStep: {
        type: 'boolean',
        customErrors: '',
      },
      skipJustify: {
        type: 'string',
        customErrors: '',
      },
      groupStep: {
        type: 'boolean',
        customErrors: '',
      },
      showGroupStep: {
        type: 'boolean',
        customErrors: '',
      },
      status: {
        format: 'selectStatus',
        customErrors: '',
      },
      executorId: {
        type: 'number',
        format: 'selectExecutorDialog',
        customErrors: '',
      },
      observation: {
        type: 'string',
        customErrors: '',
      },
    },
    required: ['unitId'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Etapa da Ordem de Serviço',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Situação',
            scope: '#/properties/status',
          },
          {
            type: 'Control',
            label: 'Executor',
            scope: '#/properties/executorId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/enableFields',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dentista',
            scope: '#/properties/dentistId',
            options: {
              readonly: true,
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário',
            scope: '#/properties/recordNumber',
          },
          {
            type: 'Control',
            label: 'Paciente',
            scope: '#/properties/patientName',
          },
        ],
        rule: {
          effect: 'ENABLE',
          condition: {
            scope: '#/properties/enableFields',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data',
            scope: '#/properties/date',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/enableFields',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Produto Final',
            scope: '#/properties/endProductId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/enableFields',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Etapa',
                scope: '#/properties/stepId',
                rule: {
                  effect: 'ENABLE',
                  condition: {
                    scope: '#/properties/enableFields',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Observação',
            scope: '#/properties/observation',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/skipStep',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Pular Etapa',
            scope: '#/properties/skipStep',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Antagonista',
            scope: '#/properties/opposite',
          },
          {
            type: 'Control',
            label: 'Mordida em Cera',
            scope: '#/properties/waxBite',
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Arcadas',
                scope: '#/properties/dentalArch',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/showDentalArch',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Arcadas',
                scope: '#/properties/toothShadeId',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/showToothShade',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Numero do dente',
                scope: '#/properties/toothNumber',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/showToothNumber',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/skipStep',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Justificativa',
            scope: '#/properties/skipJustify',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/skipStep',
                schema: {
                  const: false,
                },
              },
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Agrupar com a próxima etapa',
            scope: '#/properties/groupStep',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/showGroupStep',
                schema: {
                  const: false,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/skipStep',
            schema: {
              const: true,
            },
          },
        },
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Etapa da Ordem de Serviço',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Situação',
            scope: '#/properties/status',
          },
          {
            type: 'Control',
            label: 'Executor',
            scope: '#/properties/executorId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/enableFields',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dentista',
            scope: '#/properties/dentistId',
            options: {
              readonly: true,
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário',
            scope: '#/properties/recordNumber',
          },
          {
            type: 'Control',
            label: 'Paciente',
            scope: '#/properties/patientName',
          },
        ],
        rule: {
          effect: 'ENABLE',
          condition: {
            scope: '#/properties/enableFields',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data',
            scope: '#/properties/date',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/enableFields',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Produto Final',
            scope: '#/properties/endProductId',
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/enableFields',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Etapa',
                scope: '#/properties/stepId',
                rule: {
                  effect: 'ENABLE',
                  condition: {
                    scope: '#/properties/enableFields',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Observação',
            scope: '#/properties/observation',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/skipStep',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Pular Etapa',
            scope: '#/properties/skipStep',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Antagonista',
            scope: '#/properties/opposite',
          },
          {
            type: 'Control',
            label: 'Mordida em Cera',
            scope: '#/properties/waxBite',
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Arcadas',
                scope: '#/properties/dentalArch',
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/showDentalArch',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Arcadas',
                scope: '#/properties/toothShadeId',
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/showToothShade',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Numero do dente',
                scope: '#/properties/toothNumber',
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: '#/properties/showToothNumber',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Agrupar com a próxima etapa',
                scope: '#/properties/groupStep',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/showGroupStep',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/viewForm',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/skipStep',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Justificativa',
            scope: '#/properties/skipJustify',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/skipStep',
                schema: {
                  const: false,
                },
              },
            },
          },
        ],
      },
    ],
    rule: {
      effect: 'ENABLE',
      condition: {
        scope: '#/properties/viewForm',
        schema: {
          const: false,
        },
      },
    },
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
  ],
  actions: {
    getAll: 'OrderServiceStepController@getAll',
    getAllByOrderServiceId: 'OrderServiceStepController@getAllByOrderServiceId',
    getAllOrderServiceStepPendingByUnitId:
      'OrderServiceStepController@getAllOrderServiceStepPendingByUnitId',
    getAllOrderServiceStepSended:
      'OrderServiceStepController@getAllOrderServiceStepSended',
    getAllOrderServiceStepQueued:
      'OrderServiceStepController@getAllOrderServiceStepQueued',
    getAllOrderServiceStepCompletedLaboratory:
      'OrderServiceStepController@getAllOrderServiceStepCompletedLaboratory',
    findById: 'OrderServiceStepController@findById',
    create: 'OrderServiceStepController@create',
    update: 'OrderServiceStepController@patchUpdate',
    delete: 'OrderServiceStepController@delete',
    setQueued: 'OrderServiceStepController@setQueued',
    setRejected: 'OrderServiceStepController@setRejected',
    setStepCompleted: 'OrderServiceStepController@setStepCompleted',
    setFinalized: 'OrderServiceStepController@setFinalized',
    setSended: 'OrderServiceStepController@setSended',
    setInTransit: 'OrderServiceStepController@setInTransit',
    setSkippedLab: 'OrderServiceStepController@setSkippedLab',
    setGrouped: 'OrderServiceStepController@setGrouped',
    setSubmittedInGroup: 'OrderServiceStepController@setSubmittedInGroup',
    defineExecutor: 'OrderServiceStepController@defineExecutor',
    fixOSByClinic: 'OrderServiceStepController@fixOSByClinic',
    setCompletedLaboratory: 'OrderServiceStepController@setCompletedLaboratory',
    setReceived: 'OrderServiceStepController@setReceived',
    setTakeCost: 'OrderServiceStepController@setTakeCost',
    resumeOrderService: 'OrderServiceStepController@resumeOrderService',
    suspendOrderService: 'OrderServiceStepController@suspendOrderService',
  },
};

export default OrderServiceStep;
