/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import {
  Container,
  AddBox,
  Paper,
  TableBody,
  TableCell,
  HeaderTableCell,
  ActionCell,
  DeleteConfirmationContainer,
  TableSortLabel,
  IconButton,
  ActionButton,
  HeaderContainer,
  HeaderActionsContainer,
  SearchField,
  Edit,
  Delete,
  ActionsContainer,
  Save,
  Close,
  Search,
  FooterContainer,
} from './styles';
import SecuredItem from '../securedItem';

function CrudTable({
  title,
  customHeaderComponent,
  customFooterComponent,
  customInfosComponent,
  modelActions,
  columns,
  customColumns,
  idField = 'id',
  rows,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
  availableRowsPerPage,
  orderBy,
  setOrderBy,
  searchBy,
  setSearchBy,
  actions,
  customCellRenderers,
  customColoring,
  className,
  emptyHeader,
  customConfirmations,
  noPagination,
  customHeaderTable,
}) {
  const [onDeleteId, setOnDeleteId] = useState(-1);
  const [searchContent, setSearchContent] = useState(searchBy);

  useEffect(() => {
    setSearchContent(searchBy);
  }, [searchBy]);

  const containsActions =
    Object.keys(actions ?? {}).filter((value) => value !== 'add').length > 0;
  const customActions = Object.keys(actions ?? {}).filter(
    (value) => value !== 'add' && value !== 'update' && value !== 'delete'
  );

  const toogleOrderBy = (id) => {
    const newOrder = { ...orderBy };
    switch (orderBy[id]) {
      case 'asc':
        newOrder[id] = 'desc';
        break;
      case 'desc':
        delete newOrder[id];
        break;
      default:
        newOrder[id] = 'asc';
        break;
    }
    setOrderBy(newOrder);
  };

  return (
    <Container className={className}>
      <Paper>
        {!emptyHeader ? (
          <HeaderContainer>
            <h1>{title}</h1>
            {customHeaderComponent}
            <HeaderActionsContainer>
              <SearchField
                autoFocus
                value={searchContent}
                adornment={<Search />}
                // clickableAdornment={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setPage(0);
                    setSearchBy(searchContent);
                  }
                }}
                placeholder={'Pressione ENTER para buscar'}
                // onAdornmentClick={() => {
                //   setPage(0);
                //   setSearchBy(searchContent);
                // }}
                onChange={(e) => {
                  setSearchContent(e.target.value);
                }}
                withClear
                onClear={() => setSearchBy('')}
              />
              {actions?.['add']?.onClick &&
              (!actions['add'].selector || actions['add'].selector()) ? (
                <SecuredItem
                  allowedActions={[
                    actions?.['add']?.allowedActions ?? modelActions.create,
                  ]}
                  allowDentist={actions?.['add']?.allowDentist ?? false}
                >
                  <IconButton
                    onClick={actions['add'].onClick}
                    title={'Adicionar'}
                  >
                    <AddBox />
                  </IconButton>
                </SecuredItem>
              ) : null}
            </HeaderActionsContainer>
          </HeaderContainer>
        ) : null}
        {customInfosComponent}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <HeaderTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {customHeaderTable && column.id === customHeaderTable.id ? (
                      customHeaderTable.customCell
                    ) : (
                      <TableSortLabel
                        active={orderBy[column.id] !== undefined}
                        direction={orderBy[column.id]}
                        onClick={() => toogleOrderBy(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                  </HeaderTableCell>
                ))}
                {customColumns?.map((column) => (
                  <SecuredItem
                    key={column.id}
                    allowedActions={column.allowedActions ?? []}
                  >
                    <HeaderTableCell
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy[column.id] !== undefined}
                        direction={orderBy[column.id]}
                        onClick={() => toogleOrderBy(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </HeaderTableCell>
                  </SecuredItem>
                ))}
                {containsActions ? (
                  <SecuredItem
                    allowedActions={[
                      actions?.['update']
                        ? actions?.['update']?.allowedActions ??
                          modelActions.update
                        : null,
                      actions?.['delete']
                        ? actions?.['delete']?.allowedActions ??
                          modelActions.delete
                        : null,
                      ...customActions
                        .map((x) => actions[x].allowedActions)
                        .flat(),
                    ].filter((x) => x)}
                    allowDentist={
                      (actions?.['update']?.allowDentist ?? false) ||
                      (actions?.['delete']?.allowDentist ?? false) ||
                      customActions.reduce(
                        (acc, x) => acc || actions[x].allowDentist,
                        false
                      )
                    }
                  >
                    <HeaderTableCell key={'action'} align={'center'}>
                      <span>Ações</span>
                    </HeaderTableCell>
                  </SecuredItem>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.values.map((row, index) => {
                let customConfirmation = null;
                customConfirmation = customConfirmations?.find((item) =>
                  item.selector(row[idField], row)
                );

                return customConfirmation ? (
                  <TableRow key={index}>
                    <TableCell
                      colSpan={
                        columns.length + (customColumns?.length ?? 0) + 1
                      }
                    >
                      <DeleteConfirmationContainer>
                        {customConfirmation.content}
                        <IconButton
                          onClick={() => {
                            customConfirmation.onSuccess?.(row[idField], row);
                          }}
                        >
                          <Save />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            customConfirmation.onCancel?.();
                          }}
                        >
                          <Close />
                        </IconButton>
                      </DeleteConfirmationContainer>
                    </TableCell>
                  </TableRow>
                ) : row[idField] === onDeleteId ? (
                  <TableRow key={index}>
                    <TableCell
                      colSpan={
                        columns.length + (customColumns?.length ?? 0) + 1
                      }
                    >
                      <DeleteConfirmationContainer>
                        <span>
                          Você tem certeza que deseja prosseguir com essa
                          operação?
                        </span>
                        <IconButton
                          onClick={() =>
                            actions?.['delete']?.onClick?.(
                              row[idField],
                              index,
                              row
                            )
                          }
                        >
                          <Save />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOnDeleteId(-1);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </DeleteConfirmationContainer>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${row.name}_${index}`}
                  >
                    {columns.map((column) => {
                      const splitedId = column.id.split('.');
                      let crrValue = row;
                      splitedId.forEach(
                        (item) => (crrValue = crrValue?.[item])
                      );
                      const value = crrValue;

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          customColor={
                            customColoring?.(column.id, value, column, row) ??
                            'transparent'
                          }
                        >
                          {customCellRenderers &&
                          customCellRenderers[column.id] &&
                          customCellRenderers[column.id].renderer
                            ? customCellRenderers[column.id].renderer(
                                column.id,
                                value,
                                column,
                                row
                              )
                            : column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    {customColumns?.map((column) => {
                      const splitedId = column.id.split('.');
                      let crrValue = row;
                      splitedId.forEach(
                        (item) => (crrValue = crrValue?.[item])
                      );
                      const value = crrValue;
                      return (
                        <SecuredItem
                          key={column.id}
                          allowedActions={column.allowedActions ?? []}
                        >
                          <TableCell
                            align={column.align}
                            customColor={
                              customColoring?.(column.id, value, column, row) ??
                              'transparent'
                            }
                          >
                            {customCellRenderers &&
                            customCellRenderers[column.id] &&
                            customCellRenderers[column.id].renderer
                              ? customCellRenderers[column.id].renderer(
                                  column.id,
                                  value,
                                  column,
                                  row
                                )
                              : null}
                          </TableCell>
                        </SecuredItem>
                      );
                    })}
                    {containsActions ? (
                      <SecuredItem
                        allowedActions={[
                          actions?.['update']
                            ? actions?.['update']?.allowedActions ??
                              modelActions.update
                            : null,
                          actions?.['delete']
                            ? actions?.['delete']?.allowedActions ??
                              modelActions.delete
                            : null,
                          ...customActions
                            .map((x) => actions[x].allowedActions)
                            .flat(),
                        ].filter((x) => x)}
                        allowDentist={
                          (actions?.['update']?.allowDentist ?? false) ||
                          (actions?.['delete']?.allowDentist ?? false) ||
                          customActions.reduce(
                            (acc, x) => acc || actions[x].allowDentist,
                            false
                          )
                        }
                      >
                        <ActionCell
                          customColor={
                            customColoring?.('actions', null, null, row) ??
                            'transparent'
                          }
                        >
                          <ActionsContainer>
                            {actions['update'] &&
                            actions['update'].onClick &&
                            (!actions['update'].selector ||
                              actions['update'].selector(row)) ? (
                              <SecuredItem
                                allowedActions={
                                  actions?.['update']?.allowedActions ?? [
                                    [
                                      modelActions.findById,
                                      modelActions.update,
                                    ],
                                  ]
                                }
                                allowDentist={
                                  actions?.['update']?.allowDentist ?? false
                                }
                              >
                                <ActionButton
                                  title={'Atualizar'}
                                  onClick={() =>
                                    actions['update'].onClick(row[idField], row)
                                  }
                                >
                                  <Edit />
                                </ActionButton>
                              </SecuredItem>
                            ) : null}
                            {customActions
                              .filter(
                                (key) =>
                                  actions[key].icon &&
                                  actions[key].onClick &&
                                  (!actions[key].selector ||
                                    actions[key].selector(row))
                              )
                              .map((key) =>
                                !actions[key].selector ||
                                actions[key].selector(row) ? (
                                  <SecuredItem
                                    key={key}
                                    allowedActions={
                                      actions[key]?.allowedActions
                                    }
                                    allowDentist={
                                      actions[key]?.allowDentist ?? false
                                    }
                                  >
                                    <ActionButton
                                      title={actions[key].title}
                                      onClick={() => {
                                        actions[key].onClick(row[idField], row);
                                      }}
                                    >
                                      {actions[key].icon}
                                    </ActionButton>
                                  </SecuredItem>
                                ) : null
                              )}
                            {actions['delete'] &&
                            actions['delete'].onClick &&
                            (!actions['delete'].selector ||
                              actions['delete'].selector(row)) ? (
                              <SecuredItem
                                allowedActions={
                                  actions?.['delete']?.allowedActions ?? [
                                    modelActions.delete,
                                  ]
                                }
                                allowDentist={
                                  actions?.['delete']?.allowDentist ?? false
                                }
                              >
                                <ActionButton
                                  title={'Excluir'}
                                  onClick={() => {
                                    setOnDeleteId(row[idField]);
                                  }}
                                >
                                  <Delete />
                                </ActionButton>
                              </SecuredItem>
                            ) : null}
                          </ActionsContainer>
                        </ActionCell>
                      </SecuredItem>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!noPagination ? (
          <TablePagination
            rowsPerPageOptions={availableRowsPerPage ?? [10, 25, 50, 100]}
            component="div"
            count={rows.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(e, newPage) => setPage(newPage)}
            labelRowsPerPage={'Linhas por página:'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            onChangeRowsPerPage={(e) => {
              setRowsPerPage(+e.target.value);
              setPage(0);
            }}
          />
        ) : null}
      </Paper>
      {customFooterComponent ? (
        <FooterContainer> {customFooterComponent} </FooterContainer>
      ) : null}
    </Container>
  );
}

export default CrudTable;
