/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  ActionsContainer,
  CancelButton,
  ContentContainer,
  DialogActions,
  DialogContent,
  DialogTitle,
  DropzoneContainer,
  SubmitButton,
} from './styles';
import { DropzoneArea } from 'material-ui-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function ImportFileDialog({
  onSuccess,
  onError,
  onCancel,
  item,
  modelId,
  restSubmitData,
  ...props
}) {
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState('');
  const [fetching, setFetching] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFetching(true);
    restSubmitData(file, modelId)
      .then((x) => {
        setErrors([]);
        setFile('');
        setFetching(false);
        onSuccess(x);
      })
      .catch((x) => {
        if (x.response?.status === 400) {
          setFetching(false);
          onError?.(x.response);
          setErrors(x.response.data.errors);

          setAlert({
            message: `${Object.keys(x.response.data.errors).map(function (
              value,
              index
            ) {
              return x.response.data.errors[value][index];
            })}`,
            severity: 'error',
          });
        }
      });
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <DialogTitle id="alert-dialog-title">Submissão de Anexo</DialogTitle>
        <DialogContent>
          <ContentContainer>
            <DropzoneContainer>
              <DropzoneArea
                useChipsForPreview
                filesLimit={1}
                maxFileSize={2097152}
                showAlerts={false}
                acceptedFiles={[
                  '.csv, text/csv, application/vnd.ms-excel, application/csv, ' +
                    'text/x-csv, application/x-csv, text/comma-separated-values, ' +
                    'text/x-comma-separated-values, text/plain, application/pdf, ' +
                    'image/jpeg, image/jpg, image/png',
                ]}
                dropzoneText="Clique ou arraste o arquivo aqui"
                onChange={(files) => {
                  if (files.length > 0) {
                    const reader = new FileReader();
                    reader.readAsText(files[0], 'UTF-8');
                    reader.onload = function (evt) {
                      setFile(files[0]);
                      setAlert({
                        message: 'Arquivo anexado com sucesso.',
                        severity: 'success',
                      });
                      setErrors([]);
                    };
                  }
                }}
                onDropRejected={(file, event) => {
                  if (!event.target.accept.split(', ').includes(file[0].type)) {
                    setAlert({
                      message:
                        'O anexo foi rejeitado. Formato do arquivo não compatível.',
                      severity: 'error',
                    });
                  } else if (file[0].size > 2097152) {
                    setAlert({
                      message: `O anexo foi rejeitado. O arquivo ${file[0].name} excede o limite máximo de  2 MBs.`,
                      severity: 'error',
                    });
                  }
                  setFile('');
                }}
                onDelete={() => {
                  setFile('');
                  setErrors([]);
                }}
                onAlert={() => {}}
              />
            </DropzoneContainer>
          </ContentContainer>
        </DialogContent>
        <DialogActions>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span>Processando submissão</span>
            </>
          ) : (
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  setErrors([]);
                  onCancel?.();
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              {!isEmpty(errors) ? (
                <SubmitButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled
                >
                  Enviar
                </SubmitButton>
              ) : (
                <SubmitButton type="submit" variant="contained" color="primary">
                  Enviar
                </SubmitButton>
              )}
            </ActionsContainer>
          )}
        </DialogActions>
      </form>
      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert?.severity ? `${alert?.severity}` : 'success'}
        >
          {alert?.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default ImportFileDialog;
