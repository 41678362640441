import { groupBy, isArray } from 'lodash';
import { useSelector } from 'react-redux';

function SecuredItem({
  children,
  allowedRoles = [],
  allowedActions = [],
  allowDentist = undefined,
  allowCustom = undefined,
}) {
  const user = useSelector((x) => x.authReducer.user);
  const roleCheck = (x) => user?.roles.includes(x);
  const actionCheck = (x) => user?.actions.includes(x);
  const groupedAllowedActions = groupBy(allowedActions, (x) =>
    isArray(x) ? 'requiredAll' : 'anyOf'
  );
  const groupedAllowedRoles = groupBy(allowedRoles, (x) =>
    isArray(x) ? 'requiredAll' : 'anyOf'
  );
  const allowedAnyOf =
    groupedAllowedRoles['anyOf']?.some(roleCheck) ||
    groupedAllowedActions['anyOf']?.some(actionCheck);
  const allowedRequiredAll =
    (!groupedAllowedRoles['requiredAll']?.length ||
      groupedAllowedRoles['requiredAll'].some((x) => x.every(roleCheck))) &&
    (!groupedAllowedActions['requiredAll']?.length ||
      groupedAllowedActions['requiredAll'].some((x) => x.every(actionCheck))) &&
    (!allowCustom || allowCustom(user)) &&
    !(
      !groupedAllowedRoles['requiredAll']?.length &&
      !groupedAllowedActions['requiredAll']?.length
    );
  const allowedDentist =
    allowDentist &&
    user.isDentist &&
    (!allowDentist.dentistScheduleTypes ||
      user.dentistScheduleTypes.some((x) =>
        allowDentist.dentistScheduleTypes.includes(x.type)
      ));
  return allowedAnyOf ||
    allowedRequiredAll ||
    allowedDentist ||
    (!allowedRoles.length && !allowedActions.length && !allowDentist)
    ? children
    : null;
}

export default SecuredItem;
