import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OrderService from '../../../../forms/orderService';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import ViewUrgencyDialog from '../../../../components/viewUrgencyDialog';
import {
  Container,
  Preview,
  ExclamationTriangleFill,
  ClockHistory,
} from './styles';
import OrderServiceHistory from '../../../../pdf/orderServiceHistory';

function LabAllOrderServiceList({ history, ...props }) {
  const userData = useSelector((state) => state.authReducer?.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [justification, setJustification] = useState('');
  const columns = OrderService.labAllOSTableSchema;

  useEffect(() => {
    restApiClient.orderService
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('orderServiceStep.id', searchBy)
              .or()
              .like('@p.name', searchBy)
              .or()
              .like('patient.record_number', searchBy)
              .or()
              .like('orderServiceStep.dentistUser.name', searchBy)
              .or()
              .like('endProduct.name', searchBy)
              .or()
              .like('orderServiceStep.step.name', searchBy)
              .or()
              .like('orderServiceStep.status', searchBy)
              .or()
              .like('orderServiceStep.unit.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, userData]);

  return (
    <Container>
      <CrudTable
        title={'Todas Ordens de Serviço'}
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <Preview />,
            onClick: (orderServiceId, row) => {
              if (row.orderServiceStep) {
                history.push({
                  pathname: `/lab/orderService/update/${orderServiceId}/${row.workflowPosition}`,
                  state: {
                    dentistId: row.orderServiceStep.dentistId,
                    date: row.orderServiceStep.createdAt,
                    allowView: true,
                  },
                });
              }
            },
            title: 'Visualizar OS',
          },
          viewDentistRejectedJustification: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceId, row) => {
              setOpen(true);
              setJustification(
                row.orderServiceStep?.dentistRejectedJustification
              );
            },
            title: 'Visualizar Justificativa',
            selector: (row) =>
              row.orderServiceStep?.status ===
              'Recusada Dentista (Laboratório)',
          },
          showHistory: {
            icon: <ClockHistory />,
            onClick: (orderServiceId, row) => {
              const doc = OrderServiceHistory.buildOrderServiceHistoryPdf(row);
              doc.save(`HISTORICO_OS_${orderServiceId}.pdf`);
            },
            title: 'Visualizar Histórico da OS',
          },
        }}
      />

      <ViewUrgencyDialog
        open={open}
        title={'Recusa - Justificativa'}
        justification={justification}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </Container>
  );
}

export default LabAllOrderServiceList;
