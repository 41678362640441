import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
`;

export const SaveButton = styled(Button)`
  width: 30%;
  color: #ffffff !important;
`;

export const CancelButton = styled(Button)`
  width: 30%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const BackButton = styled(Button)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const FooterContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin: auto !important;
  }
`;

export const AutoDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 620px;
    width: 100%;
`;

export const SubmitButton = styled(Button)`
  width: 30%;
  color: #ffffff !important;
  background: #2dbd17 !important;
`;

export const PrintOS = styled.div`
  @media screen {
    display: none
  }
`;
