import styled from 'styled-components';
import { DialogContent as MaterialDialogContent } from '@material-ui/core';
import CreateUpdateForm from '../createUpdateForm';

export const Container = styled.div``;

export const DialogContent = styled(MaterialDialogContent)`
`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)``;

export const ContentContainer = styled.div`
  width: 550px;
`;
