import { createGlobalStyle } from 'styled-components';

export const SystemColors = {
  primary:
    process.env.REACT_APP_API_BASE_ADDR === 'https://homolog.odontocg.com.br'
      ? '#e32636'
      : '#0046ab',
  secondary:
    process.env.REACT_APP_API_BASE_ADDR === 'https://homolog.odontocg.com.br'
      ? '#ef5854'
      : '#3770de',
};

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *, button, input {
    border: 0;
    outline: 0;
    font-family: 'Roboto', -apple-system, system-ui, sans-serif;
  }

  html{
    background: var(--background);
  }

  :root {
    --background: #ffffff;
    --liteBackground: #f0f5ff;
    --darkBackground: #000000;
    --primary: ${SystemColors.primary};
    --secondary: ${SystemColors.secondary};
    --tertiary: #393d42;
    --outline: #828386;
  }
`;
