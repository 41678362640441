import styled from 'styled-components';
import { 
  FileEarmarkMedicalFill as MaterialFileEarmarkMedicalFill 
} from '../../../../../styles/icons';
import {
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel, MenuItem as MaterialMenuItem,
  Select as MaterialSelect,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';


export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const FileEarmarkMedicalFill = styled(MaterialFileEarmarkMedicalFill)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const SelectContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin: auto !important;
  }
`;

export const Select = styled(MaterialSelect)``;

export const FormControl = styled(MaterialFormControl)`
  width: 50%;
`;

export const InputLabel = styled(MaterialInputLabel)``;

export const MenuItem = styled(MaterialMenuItem)``;

export const MuiPickersUtilsProvider = styled(MaterialMuiPickersUtilsProvider)`
  padding: 0 !important;
`;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  width: 35%;
  padding: 0 !important;
`;
