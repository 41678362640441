import styled from 'styled-components';
import MaterialPaper from '@material-ui/core/Paper';
import {
  Resources as MaterialResources,
  AppointmentTooltip as MaterialAppointmentTooltip,
  GroupingPanel as MaterialGroupingPanel,
  ViewSwitcher as MaterialViewSwitcher,
  Scheduler as MaterialScheduler,
  WeekView as MaterialWeekView,
  Appointments as MaterialAppointments,
  Toolbar as MaterialToolbar,
  DateNavigator as MaterialDateNavigator,
  TodayButton as MaterialTodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  ViewState as MaterialViewState,
  GroupingState as MaterialGroupingState,
  IntegratedGrouping as MaterialIntegratedGrouping,
} from '@devexpress/dx-react-scheduler';
import { Button as MaterialButton } from '@material-ui/core';

export const Scheduler = styled(MaterialScheduler)`
`;

export const WeekView = styled(MaterialWeekView)``;

export const Appointments = styled(MaterialAppointments)``;

export const Toolbar = styled(MaterialToolbar)``;

export const DateNavigator = styled(MaterialDateNavigator)``;

export const TodayButton = styled(MaterialTodayButton)``;

export const Resources = styled(MaterialResources)``;

export const AppointmentTooltip = styled(MaterialAppointmentTooltip)``;

export const GroupingPanel = styled(MaterialGroupingPanel)``;

export const ViewSwitcher = styled(MaterialViewSwitcher)``;

export const ViewState = styled(MaterialViewState)``;

export const GroupingState = styled(MaterialGroupingState)``;

export const IntegratedGrouping = styled(MaterialIntegratedGrouping)``;

export const Container = styled.div`
  height: 88vh;
  width: 85vw;
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const ContentContainer = styled.div`
  width: 100%;
  min-height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Paper = styled(MaterialPaper)`
  width: 100%;
  min-height: auto;
  max-height: 100%;
  padding-bottom: 2px;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin: auto !important;
  }
`;
