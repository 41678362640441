/* eslint-disable no-unused-expressions */
import React from 'react';
import { Container, ColorPicker } from './styles';
import { FormHelperText, Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';
import { isEmpty } from 'lodash';

const SelectColorPickerControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  ...props
}) => {
  const palette = {
    red: '#ff0000',
    blue: '#0000ff',
    green: '#00ff00',
    yellow: 'yellow',
    cyan: 'cyan',
    lime: 'lime',
    gray: 'gray',
    orange: 'orange',
    purple: 'purple',
    black: 'black',
    white: 'white',
    pink: 'pink',
    darkblue: 'darkblue',
  };
  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          disabled={disabled}
          error={!isEmpty(errors)}
          adornment={
            <ColorPicker
              value={data ?? '#0046ab'}
              onChange={(color) => handleChange(path, `#${color.hex}`)}
              palette={palette}
              hideTextfield
            />}
          onAdornmentClick={() => { }}
          clickableAdornment
          label="Cor"
          InputLabelProps={{
            shrink: true,
            readOnly: true,
          }}
          value={data}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
      </Container>
    </Hidden>
  );
};

export default SelectColorPickerControl;
