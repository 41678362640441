import React, { useCallback, useEffect, useState } from 'react';
import {
  AutoDiv,
  BackButton,
  Button,
  Container,
  HeaderContainer,
  Preview,
  SaveIcon,
  StyledTypography,
} from './styles';
import UnitFinance from '../../../../../forms/unitFinance';
import restApiClient from '../../../../../services/restApiClient';
import FilterTree from '../../../../../helpers/filterTree';
import CrudTable from '../../../../../components/crudTable';
import moment from 'moment';
import first from 'lodash/first';
import SubmitDialog from '../../../../../components/submitDialog';
import ErrorDialog from '../../../../../components/errorDialog';

function BankAccountStatement({ history, match }) {
  const unitFinanceId = match?.params?.unitFinanceId;
  const [open, setOpen] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [date, setDate] = useState(undefined);
  const [accountName, setAccountName] = useState(undefined);
  const [consolidated, setConsolidated] = useState(undefined);
  const [total, setTotal] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = UnitFinance.statementTableSchema;

  const loadBankAccounts = useCallback(() => {
    if (unitFinanceId) {
      restApiClient.unitFinance
        .findByIdWithHistory(
          unitFinanceId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy ? new FilterTree().toString() : null,
          orderBy
        )
        .then((e) => {
          setConsolidated(first(e.data.dailyUnitFinances).balanceValue);
          setAccountName(e.data?.bankAccount?.holderName ?? e.data.type);
          const date = first(Object.keys(e.data.accountHistories));
          setDate(moment(e.data.consolidingDate).format('DD/MM/YYYY'));
          const data = Object.values(date ? e.data.accountHistories[date] : {});
          setRows({ count: data.length, values: data });
          setTotal(data.reduce((acc, x) => acc + parseFloat(x.value), 0));
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadBankAccounts();
  }, [loadBankAccounts]);

  return (
    <Container>
      <CrudTable
        title={`Consolidação da conta ${accountName} no dia ${date}`}
        customHeaderComponent={
          <HeaderContainer>
            <AutoDiv>
              <StyledTypography variant="h6">
                Saldo Inicial:{' '}
                {consolidated?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </StyledTypography>
              <StyledTypography variant="h6">
                Saldo Final:{' '}
                {(consolidated + total)?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </StyledTypography>
              <StyledTypography variant="h6">
                Diferença:{' '}
                {total?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </StyledTypography>
            </AutoDiv>
          </HeaderContainer>
        }
        customFooterComponent={
          <>
            <BackButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
            {unitFinanceId && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={async () => {
                  setOpen(true);
                }}
              >
                Consolidar
              </Button>
            )}
          </>
        }
        modelActions={UnitFinance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <Preview />,
            onClick: (expenseId, row) => {
              let expensableType;
              if (row?.operable?.expensableType) {
                let indexes = row?.operable?.expensableType.split('\\');
                expensableType =
                  indexes[2][0].toLowerCase() + indexes[2].substring(1);
              }
              history.push({
                pathname: `/financial/expense/${row?.operable?.id}`,
                state: { readonly: true, expensableType },
              });
            },
            title: 'Visualizar Despesa',
            selector: (row) => row?.type === 'Despesa',
          },
        }}
        customCellRenderers={{
          date: {
            renderer: (field, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
          value: {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          operable: {
            renderer: (field, value) => {
              if (value?.employeeId) {
                return value?.employee?.user?.name;
              } else if (value?.patientId) {
                return value?.patient?.name;
              } else if (value?.dentistId) {
                return value?.dentist?.user?.name;
              } else if (value?.expensable) {
                return value?.expensable?.isPhysicalPerson
                  ? value?.expensable?.socialName
                  : value?.expensable?.fantasyName;
              } else {
                return '-';
              }
            },
          },
          'operable.observation': {
            renderer: (field, value) => (value ? value : '-'),
          },
          'operable.unit.name': {
            renderer: (field, value) => (value ? value : '-'),
          },
        }}
      />
      <SubmitDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          restApiClient.unitFinance
            .consolidate(unitFinanceId)
            .then((e) => {
              const yesterday = moment()
                .subtract(1, 'day')
                .format('DD/MM/YYYY');
              if (date === yesterday) {
                history.goBack();
              } else {
                loadBankAccounts();
              }
            })
            .catch((e) => {
              setErrorMessage(e.response.data.errors);
              setOpenErrorDialog(true);
            });
        }}
        dialogText="Tem certeza que deseja Consolidar essa conta? Essa operação é crítica e não poderá ser desfeita!"
      />

      <ErrorDialog
        open={openErrorDialog}
        title="Erro"
        error={errorMessage}
        onClose={() => setOpenErrorDialog(false)}
      />
    </Container>
  );
}

export default BankAccountStatement;
