import styled from 'styled-components';
import {
  Close as MaterialClose,
} from '../../../../../styles/icons';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const BackButton = styled(Button)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
