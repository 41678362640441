import React, { useState, useEffect } from 'react';
import Item from '../../../../forms/item';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container } from './styles';
import isEmpty from 'lodash/isEmpty';

function ItemListClinic({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = Item.searchClinicTableSchema;

  async function loadItems() {
    restApiClient.item
      .getAllWithStockClinic(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('brand', searchBy)
              .or()
              .like('barcode.barcode', searchBy)
              .or()
              .like('itemLocators.locator', searchBy)
              .or()
              .like('group.name', searchBy)
              .or()
              .like('group.parent.name', searchBy)
              .or()
              .like('group.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }

  useEffect(() => {
    loadItems();
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Busca de Itens'}
        modelActions={Item.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          barcode: {
            renderer: (field, value) => {
              return !isEmpty(value)
                ? value.map((item) => item.barcode).join(', ')
                : '-';
            },
          },
          'stock.availableQuantity': {
            renderer: (field, value) => {
              return value ?? 0;
            },
          },
          group: {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value.name;
              }
            },
          },
          itemLocators: {
            renderer: (field, value) => {
              return value[0]?.locator ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default ItemListClinic;
