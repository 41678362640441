import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  PriceChange as MaterialPriceChange,
  Restore as MaterialRestore,
  Close as MaterialClose,
  ClockHistory as MaterialClockHistory,
  Receipt as MaterialReceipt,
  Save as MaterialSaveIcon,
} from '../../../../../styles/icons';
import {
  Button as MaterialButton,
  Typography as MaterialTypography,
} from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const AutoDiv = styled.div`
  width: auto;
  margin-right: auto;
  margin-left: 15px;
`;

export const StyledTypography = styled(MaterialTypography)`
  line-height: 1.25 !important;
`;

export const BackButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Button = styled(MaterialButton)`
  color: #ffffff !important;
`;

export const SaveIcon = styled(MaterialSaveIcon)`
  width: 25px;
  height: 25px;
`;

export const PriceChange = styled(MaterialPriceChange)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ClockHistory = styled(MaterialClockHistory)`
  width: 20px;
  height: 20px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Receipt = styled(MaterialReceipt)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
