import React, { useEffect } from 'react';
import restApiClient from '../../services/restApiClient';
import {
  FormControl,
  FormHelperText,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputLabel,
  NativeSelect,
  Container,
  LayerGroup,
  StyledAdornedTextField,
} from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';

const SelectSectionDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  config,
  errors,
  visible,
  noValidation,
  onSuccess,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState({});
  const [selectedSecondarySection, setSelectedSecondarySection] =
    React.useState({});
  const [selectedTertiarySection, setSelectedTertiarySection] = React.useState(
    {}
  );
  const [allSection, setAllSection] = React.useState([]);
  const [secondarySectionsById, setSecondarySectionsById] = React.useState([]);
  const [tertiarySectionsById, setTertiarySectionsById] = React.useState([]);
  const [sectionError, setSectionError] = React.useState('');
  const [resultString, setResultString] = React.useState('');

  useEffect(() => {
    if (data) {
      restApiClient.section.getTypeById(data).then((e) => {
        const response = e.data;
        switch (response.type) {
          case 'section':
            setSelectedSection(response.section);
            setResultString(`${response.section.name}`);
            break;
          case 'secondarySection':
            setSelectedSection(response.section);
            setSelectedSecondarySection(response.secondary);
            setResultString(
              `${response.section.name} > ${response.secondary.name}`
            );
            break;
          case 'tertiarySection':
            setSelectedSection(response.section);
            setSelectedSecondarySection(response.secondary);
            setSelectedTertiarySection(response.tertiary);
            setResultString(
              `${response.section.name} > ${response.secondary.name} > ${response.tertiary.name}`
            );
            break;
          default:
            break;
        }
      });
    }
  }, [data]);

  useEffect(() => {
    restApiClient.section.getAllBranches().then((e) => {
      setAllSection(e.data);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedSection)) {
      restApiClient.section
        .getAllChildrenByParentId(selectedSection.id)
        .then((e) => {
          setSecondarySectionsById(e.data);
        });
    }
  }, [selectedSection]);

  useEffect(() => {
    if (!isEmpty(selectedSecondarySection)) {
      restApiClient.section
        .getAllChildrenByParentId(selectedSecondarySection.id)
        .then((e) => {
          setTertiarySectionsById(e.data);
        });
    }
  }, [selectedSecondarySection]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <StyledAdornedTextField
          className={className}
          disabled={!enabled}
          size="small"
          error={!isEmpty(errors)}
          adornment={<LayerGroup />}
          clickableAdornment
          onAdornmentClick={() => {
            if (enabled) {
              setOpen(true);
            }
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={() => {
            if (enabled) {
              setOpen(true);
            }
          }}
          label="Seção > Seção secundária > Seção terciária"
          InputLabelProps={{
            readOnly: true,
          }}
          value={resultString ?? ''}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
      </Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Seção</DialogTitle>
        <DialogContent>
          <FormControl error={!isEmpty(sectionError)}>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
              Seção
            </InputLabel>
            <NativeSelect
              value={selectedSection?.id}
              onChange={(e) => {
                setSelectedSection(
                  allSection.find(
                    (item) => item.id === parseInt(e.target.value)
                  ) ?? {}
                );
                if (!isEmpty(sectionError)) {
                  setSectionError('');
                }
                if (!isEmpty(selectedSecondarySection)) {
                  setSelectedSecondarySection({});
                }
                if (!isEmpty(selectedTertiarySection)) {
                  setSelectedTertiarySection({});
                }
              }}
            >
              <option aria-label="None" value="">
                Selecione
              </option>
              {allSection.map((item) => (
                <option key={`section_${item.id}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </NativeSelect>
            <FormHelperText>
              {!isEmpty(sectionError) ? sectionError : ' '}
            </FormHelperText>
          </FormControl>
          {!isEmpty(selectedSection) && !isEmpty(secondarySectionsById) ? (
            <FormControl>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Seção Secundária
              </InputLabel>
              <NativeSelect
                value={selectedSecondarySection?.id}
                onChange={(e) => {
                  setSelectedSecondarySection(
                    secondarySectionsById.find(
                      (item) => item.id === parseInt(e.target.value) ?? {}
                    )
                  );
                  if (!isEmpty(selectedTertiarySection)) {
                    setSelectedTertiarySection({});
                  }
                }}
              >
                <option aria-label="None" value="">
                  Selecione
                </option>
                {secondarySectionsById?.map((item) => (
                  <option key={`secondary_section_${item.id}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          ) : null}
          {!isEmpty(selectedSecondarySection) &&
          !isEmpty(tertiarySectionsById) ? (
            <FormControl>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Seção Terciária
              </InputLabel>
              <NativeSelect
                value={selectedTertiarySection?.id}
                onChange={(e) => {
                  setSelectedTertiarySection(
                    tertiarySectionsById.find(
                      (item) => item.id === parseInt(e.target.value) ?? {}
                    )
                  );
                }}
              >
                <option aria-label="None" value="">
                  Selecione
                </option>
                {tertiarySectionsById?.map((item) => (
                  <option key={`tertiary_section_${item.id}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectedSection({});
              setSelectedSecondarySection({});
              setSelectedTertiarySection({});
              setOpen(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (noValidation) {
                if (!isEmpty(selectedTertiarySection)) {
                  setResultString(
                    `${selectedSection.name} > ${selectedSecondarySection.name} > ${selectedTertiarySection.name}`
                  );
                  onSuccess({
                    type: 'tertiarySection',
                    id: selectedTertiarySection.id,
                  });
                } else if (!isEmpty(selectedSecondarySection)) {
                  setResultString(
                    `${selectedSection.name} > ${selectedSecondarySection.name}`
                  );
                  onSuccess({
                    type: 'secondarySection',
                    id: selectedSecondarySection.id,
                  });
                } else if (!isEmpty(selectedSection)) {
                  setResultString(`${selectedSection.name}`);
                  onSuccess({ type: 'section', id: selectedSection.id });
                } else {
                  setResultString(``);
                  onSuccess({ type: 'none' });
                }
                setOpen(false);
              } else {
                if (isEmpty(selectedSection)) {
                  setSectionError('Campo Obrigatório');
                } else {
                  if (!isEmpty(selectedTertiarySection)) {
                    setResultString(
                      `${selectedSection.name} > ${selectedSecondarySection.name} > ${selectedTertiarySection.name}`
                    );
                    handleChange(path, selectedTertiarySection.id);
                  } else if (!isEmpty(selectedSecondarySection)) {
                    setResultString(
                      `${selectedSection.name} > ${selectedSecondarySection.name}`
                    );
                    handleChange(path, selectedSecondarySection.id);
                  } else {
                    setResultString(`${selectedSection.name}`);
                    handleChange(path, selectedSection.id);
                  }
                  setOpen(false);
                }
              }
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Hidden>
  );
};

export default SelectSectionDialogControl;
