import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import {
  withJsonFormsCellProps,
  withJsonFormsEnumProps,
} from '@jsonforms/react';
import SelectUnitFinanceDialogControl from '../../components/selectUnitFinanceDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectUnitFinanceDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectUnitFinanceDialog'
  )
);

const customSelectUnitFinanceDialogControlTester = rankWith(
  4,
  isSelectUnitFinanceDialogControl
);

export default {
  tester: customSelectUnitFinanceDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectUnitFinanceDialogControl),
};
