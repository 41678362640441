/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Specialty from '../../forms/specialty';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  HandHoldingMedical,
} from './styles';
import isEmpty from 'lodash/isEmpty';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';
import { useSelector } from 'react-redux';

const SelectDentistSpecialtyDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  required,
  forceDentistId,
  ...props
}) => {
  const dentistId = useSelector((state) => state.authReducer?.user.id);
  const [open, setOpen] = React.useState(false);
  const [selectedSpecialty, setSelectedSpecialty] = React.useState({});
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const handleClickOpen = () => {
    if (enabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = Specialty.tableSchema;

  useEffect(() => {
    restApiClient.specialty
      .getAllBySpecialtyDentist(
        forceDentistId ? forceDentistId : dentistId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    if (data) {
      restApiClient.specialty.findById(data).then((e) => {
        setSelectedSpecialty(e.data);
      });
    } else {
      setSelectedSpecialty({});
    }
  }, [data]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          className={className}
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<HandHoldingMedical />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label="Especialidade"
          InputLabelProps={{
            readOnly: true,
          }}
          value={selectedSpecialty?.name ?? ''}
          required={required}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
      </Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <StyledCrudTable
            title={'Seleção de Especialidade'}
            modelActions={Specialty.actions}
            columns={columns}
            rows={rows}
            page={page}
            setPage={(page) => setPage(page)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[3]}
            setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            onOrderByChange={(orderBy) => setOrderby(orderBy)}
            orderBy={orderBy}
            setOrderBy={(value) => setOrderby(value)}
            searchBy={searchBy}
            setSearchBy={(value) => setSearchBy(value)}
            actions={{
              insert: {
                icon: <AddBox />,
                onClick: (id, data) => {
                  setSelectedSpecialty(data);
                  handleChange?.(path, id);
                  onSuccess?.(id);
                  handleClose();
                },
                title: 'Vincular',
                allowDentist: true,
                allowedActions: [[Specialty.actions.getAllBySpecialtyDentist]],
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Hidden>
  );
};

export default SelectDentistSpecialtyDialogControl;
