import styled from 'styled-components';
import { FileEarmarkMedicalFill as MaterialFileEarmarkMedicalFill } from '../../../../../styles/icons';


export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const FileEarmarkMedicalFill = styled(MaterialFileEarmarkMedicalFill)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
