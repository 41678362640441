const Employee = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com um nome',
        customErrors: '',
      },
      login: {
        type: 'string',
        format: 'login',
        upperCased: false,
        minLength: 2,
        maxLength: 32,
        customErrors: '',
      },
      email: {
        type: 'string',
        format: 'email',
        customErrors: '',
      },
      cpf: {
        type: 'string',
        mask: '999.999.999-99',
        minLength: 11,
        maxLength: 14,
        customErrors: '',
        cpf: '',
      },
      rg: {
        type: 'string',
        customErrors: '',
        minLength: 5,
        maxLength: 14,
      },
      sectorId: {
        format: 'selectSectorDialog',
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      civilState: {
        enum: [
          'SOLTEIRO (A)',
          'CASADO (A)',
          'DIVORCIADO (A)',
          'SEPARADO (A)',
          'VIÚVO (A)',
        ],
        customErrors: '',
      },
      vtRegistration: {
        type: 'string',
        minLength: 6,
        maxLength: 6,
        customErrors: '',
      },
      admissionDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      workday: {
        type: 'integer',
        minimum: 0,
        maximum: 44,
        customErrors: '',
      },
    },
    required: [
      'name',
      'login',
      'cpf',
      'rg',
      'unitId',
      'civilState',
      'admissionDate',
      'workday',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Funcionário',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Login',
            scope: '#/properties/login',
          },
          {
            type: 'Control',
            label: 'Email',
            scope: '#/properties/email',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'CPF',
            scope: '#/properties/cpf',
          },
          {
            type: 'Control',
            label: 'RG',
            scope: '#/properties/rg',
          },
          {
            type: 'Control',
            label: 'Estado Civil',
            scope: '#/properties/civilState',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Setor',
            scope: '#/properties/sectorId',
          },
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Matrícula VT',
            scope: '#/properties/vtRegistration',
          },
          {
            type: 'Control',
            label: 'Data de Admissão',
            scope: '#/properties/admissionDate',
          },
          {
            type: 'Control',
            label: 'Carga horária',
            scope: '#/properties/workday',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'login', label: 'Login', minWidth: 100, align: 'center' },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
  ],
  labEmployeeTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
  ],
  labEmployeeBusyLevelTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'busyLevelName',
      label: 'Índice de Ocupação',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'productivity',
      label: 'Fila/Produtividade',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'EmployeeController@getAll',
    getAllLabEmployee: 'EmployeeController@getAllLabEmployee',
    getAllLabEmployeeWithBusyLevel:
      'EmployeeController@getAllLabEmployeeWithBusyLevel',
    getAllByUnit: 'EmployeeController@getAllByUnit',
    findById: 'EmployeeController@findById',
    create: 'EmployeeController@create',
    update: 'EmployeeController@patchUpdate',
    updateRoles: 'EmployeeController@patchUpdateRoles',
    resetPassword: 'EmployeeController@resetPassword',
    getCsvProduction: 'EmployeeController@getCsvProduction',
    delete: 'EmployeeController@delete',
    restore: 'EmployeeController@restore',
  },
};

export default Employee;
