/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import DayJsUtils from '@date-io/dayjs';
import { useSecurity } from '../../../extensions/hooks';
import SecuredItem from '../../../components/securedItem';
import FilterTree from '../../../helpers/filterTree';
import {
  Hidden,
  Select,
  FileCsv,
  FilePdf,
  AutoDiv,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectContainer,
  HeaderContainer,
  FooterContainer,
  ExportCsvButton,
  ExportPdfButton,
  ButtonContainer,
  CircularProgress,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  SelectUnitFinance,
} from './styles';
import Report from '../../../forms/report';
import Unit from '../../../forms/unit';
import UnitFinance from '../../../forms/unitFinance';
import restApiClient from '../../../services/restApiClient';
import ReportPayableExpenses from '../../../pdf/reportPayableExpenses';
import ReportBankAccountMovements from '../../../pdf/reportBankAccountMovements';
import ReportCashRegisterClose from '../../../pdf/reportCashRegisterClose';
import ReportCashEntry from '../../../pdf/reportCashEntry';
import ReportRemainingCashEntry from '../../../pdf/reportRemainingCashEntry';

function FinancialReportList() {
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [selectUnitVisible, setSelectUnitVisible] = useState(false);
  const [selectUnitFinanceVisible, setSelectUnitFinanceVisible] =
    useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [selectedReport, setSelectedReport] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedUnitFinance, setSelectedUnitFinance] = useState('');
  const [reportError, setReportError] = useState('');
  const [finalDateError, setFinalDateError] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [units, setUnits] = useState(null);
  const [unitFinances, setUnitFinances] = useState(null);
  const [unitError, setUnitError] = useState('');
  const [unitFinanceError, setUnitFinanceError] = useState('');

  const pdfSecurity = new Map();
  pdfSecurity.set('payableExpenses', [
    [Report.actions.getAllPayableExpensesByPeriod, Unit.actions.getAll],
  ]);
  pdfSecurity.set('paidExpenses', [
    [Report.actions.getAllPaidExpensesByPeriod, Unit.actions.getAll],
  ]);
  pdfSecurity.set('expenses', [
    [Report.actions.getAllExpensesByPeriod, Unit.actions.getAll],
  ]);
  pdfSecurity.set('movements', [
    [Report.actions.getBankAccountMovements, UnitFinance.actions.getAll],
  ]);
  pdfSecurity.set('remaining', [
    [Report.actions.getRemainingCash, Unit.actions.getAll],
  ]);
  pdfSecurity.set('cashRegisterClose', [
    [Report.actions.getCashRegisterClose, Unit.actions.getAll],
  ]);
  pdfSecurity.set('cashEntry', [
    [Report.actions.getCashEntry, Unit.actions.getAll],
  ]);

  const csvSecurity = new Map();
  csvSecurity.set('payableExpenses', [
    [Report.actions.getAllPayableExpensesByPeriodCsv, Unit.actions.getAll],
  ]);
  csvSecurity.set('paidExpenses', [
    [Report.actions.getAllPaidExpensesByPeriodCsv, Unit.actions.getAll],
  ]);
  csvSecurity.set('expenses', [
    [Report.actions.getAllExpensesByPeriodCsv, Unit.actions.getAll],
  ]);
  csvSecurity.set('remaining', [
    [Report.actions.getRemainingCashCSV, Unit.actions.getAll],
  ]);
  csvSecurity.set('cashRegisterClose', [
    [Report.actions.getCashRegisterCloseCSV, Unit.actions.getAll],
  ]);
  csvSecurity.set('cashEntry', [
    [Report.actions.getCashEntryCSV, Unit.actions.getAll],
  ]);

  const listReports = [
    useSecurity({ value: 'payableExpenses', label: 'Contas a pagar' }, [
      [Report.actions.getAllPayableExpensesByPeriod],
      [Report.actions.getAllPayableExpensesByPeriodCsv],
    ]),
    useSecurity({ value: 'paidExpenses', label: 'Contas pagas' }, [
      [Report.actions.getAllPaidExpensesByPeriod],
      [Report.actions.getAllPaidExpensesByPeriodCsv],
    ]),
    useSecurity({ value: 'expenses', label: 'Contas' }, [
      [Report.actions.getAllExpensesByPeriod],
      [Report.actions.getAllExpensesByPeriodCsv],
    ]),
    useSecurity({ value: 'movements', label: 'Movimentações' }, [
      [Report.actions.getBankAccountMovements],
    ]),
    useSecurity({ value: 'remaining', label: 'Valores a receber' }, [
      [Report.actions.getRemainingCash],
      [Report.actions.getRemainingCashCSV],
    ]),
    useSecurity({ value: 'cashRegisterClose', label: 'Caixas' }, [
      [Report.actions.getCashRegisterClose],
      [Report.actions.getCashRegisterCloseCSV],
    ]),
    useSecurity({ value: 'cashEntry', label: 'Valores recebidos' }, [
      [Report.actions.getCashEntry],
      [Report.actions.getCashEntryCSV],
    ]),
  ].filter((x) => !!x);

  useEffect(() => {
    if (
      [
        'payableExpenses',
        'paidExpenses',
        'expenses',
        'remaining',
        'cashRegisterClose',
        'cashEntry',
      ].includes(selectedReport)
    ) {
      restApiClient.unit.getAll().then((e) => {
        setUnits(e.data);
      });
    }
  }, [selectedReport]);

  async function loadPayableExpenses(unitId = null) {
    setFetching(true);
    restApiClient.expense
      .getAllPayableExpensesByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportPayableExpenses.buildPayableExpensesPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data,
          'Contas a Pagar'
        );
        doc.save(`RELATORIO_CONTAS_A_PAGAR.pdf`);
        setFetching(false);
      });
  }

  async function loadPaidExpenses(unitId = null) {
    setFetching(true);
    restApiClient.expense
      .getAllPaidExpensesByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportPayableExpenses.buildPayableExpensesPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data,
          'Contas Pagas'
        );
        doc.save(`RELATORIO_CONTAS_PAGAS.pdf`);
        setFetching(false);
      });
  }

  async function loadExpenses(unitId = null) {
    setFetching(true);
    restApiClient.expense
      .getAllExpensesByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportPayableExpenses.buildPayableExpensesPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data,
          'Contas'
        );
        doc.save(`RELATORIO_CONTAS.pdf`);
        setFetching(false);
      });
  }

  async function loadCashRegisterClose(unitId = null) {
    setFetching(true);
    restApiClient.cashRegisterClose
      .getCashRegisterClose(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportCashRegisterClose.buildPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data
        );
        doc.save(`RELATORIO_FECHAMENTO_CAIXA.pdf`);
        setFetching(false);
      });
  }

  async function loadCashRegisterEntry(unitId = null) {
    setFetching(true);
    restApiClient.cashRegisterClose
      .getCashEntry(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportCashEntry.buildPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data
        );
        doc.save(`RELATORIO_ENTRADA_FINANCEIRA.pdf`);
        setFetching(false);
      });
  }

  async function loadRemainingCashEntry(unitId = null) {
    setFetching(true);
    restApiClient.cashRegisterClose
      .getRemainingCash(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportRemainingCashEntry.buildPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data
        );
        doc.save(`RELATORIO_A_RECEBER.pdf`);
        setFetching(false);
      });
  }

  async function loadMovements(unitFinanceId) {
    setFetching(true);
    restApiClient.unitFinance
      .getBankAccountMovementsByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitFinanceId
      )
      .then((e) => {
        const doc = ReportBankAccountMovements.buildPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data
        );
        doc.save(`RELATORIO_MOVIMENTACOES.pdf`);
        setFetching(false);
      });
  }

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios do Financeiro</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setDateFromError(false);
                setReportError('');
                switch (e.target.value) {
                  case 'payableExpenses':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectUnitVisible(true);
                    setSelectUnitFinanceVisible(false);
                    break;
                  case 'paidExpenses':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectUnitVisible(true);
                    setSelectUnitFinanceVisible(false);
                    break;
                  case 'expenses':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectUnitVisible(true);
                    setSelectUnitFinanceVisible(false);
                    break;
                  case 'movements':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    setSelectUnitVisible(false);
                    setSelectUnitFinanceVisible(true);
                    break;
                  case 'remaining':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectUnitVisible(true);
                    setSelectUnitFinanceVisible(false);
                    break;
                  case 'cashRegisterClose':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectUnitVisible(true);
                    setSelectUnitFinanceVisible(false);
                    break;
                  case 'cashEntry':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectUnitVisible(true);
                    setSelectUnitFinanceVisible(false);
                    break;
                  default:
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setSelectUnitVisible(false);
                    setSelectUnitFinanceVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
        <Hidden xsUp={!selectUnitVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(unitError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnit || ''}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                  setUnitError('');
                }}
              >
                {[
                  <MenuItem value={'all'} key={'all'}>
                    {'TODAS UNIDADES'}
                  </MenuItem>,
                ]
                  .concat(
                    units?.values.map((optionValue) => (
                      <MenuItem value={optionValue.id} key={optionValue.id}>
                        {optionValue.name}
                      </MenuItem>
                    ))
                  )
                  .concat([
                    <MenuItem value="" key={'empty'}>
                      <em>Nenhuma</em>
                    </MenuItem>,
                  ])}
              </Select>
              <FormHelperText>
                {!isEmpty(unitError) ? unitError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
        <Hidden xsUp={!selectUnitFinanceVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={isEmpty(unitFinanceError)}
            >
              <SelectUnitFinance
                label="Selecione uma conta bancária"
                size="small"
                variant="outlined"
                onSuccess={(id) => {
                  setUnitFinanceError('');
                  setSelectedUnitFinance(id);
                }}
                visible
                enabled
                required
                error={!isEmpty(unitFinanceError)}
                helperText={!isEmpty(unitFinanceError) ? unitFinanceError : ''}
              />
            </FormControl>
          </SelectContainer>
        </Hidden>
      </AutoDiv>
      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setInitialDateError(value)}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setFinalDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={csvSecurity.get(selectedReport)}>
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async () => {
                        switch (selectedReport) {
                          case 'payableExpenses':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                setFetching(true);
                                restApiClient.expense
                                  .getAllPayableExpensesByPeriodCsv(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    null
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else if (selectedUnit) {
                                setFetching(true);
                                restApiClient.expense
                                  .getAllPayableExpensesByPeriodCsv(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    selectedUnit
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'paidExpenses':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                setFetching(true);
                                restApiClient.expense
                                  .getAllPaidExpensesByPeriodCsv(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    null
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else if (selectedUnit) {
                                setFetching(true);
                                restApiClient.expense
                                  .getAllPaidExpensesByPeriodCsv(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    selectedUnit
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'expenses':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                setFetching(true);
                                restApiClient.expense
                                  .getAllExpensesByPeriodCsv(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    null
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else if (selectedUnit) {
                                setFetching(true);
                                restApiClient.expense
                                  .getAllExpensesByPeriodCsv(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    selectedUnit
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'cashRegisterClose':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                setFetching(true);
                                restApiClient.cashRegisterClose
                                  .getCashRegisterCloseCSV(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    null
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else if (selectedUnit) {
                                setFetching(true);
                                restApiClient.cashRegisterClose
                                  .getCashRegisterCloseCSV(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    selectedUnit
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'cashEntry':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                setFetching(true);
                                restApiClient.cashRegisterClose
                                  .getCashEntryCSV(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    null
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else if (selectedUnit) {
                                setFetching(true);
                                restApiClient.cashRegisterClose
                                  .getCashEntryCSV(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    selectedUnit
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'remaining':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                setFetching(true);
                                restApiClient.cashRegisterClose
                                  .getRemainingCashCSV(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    null
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else if (selectedUnit) {
                                setFetching(true);
                                restApiClient.cashRegisterClose
                                  .getRemainingCashCSV(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    selectedUnit
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={pdfSecurity.get(selectedReport)}>
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async () => {
                        switch (selectedReport) {
                          case 'payableExpenses':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                await loadPayableExpenses();
                              } else if (selectedUnit) {
                                await loadPayableExpenses(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;

                          case 'paidExpenses':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                await loadPaidExpenses();
                              } else if (selectedUnit) {
                                await loadPaidExpenses(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;

                          case 'expenses':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                await loadExpenses();
                              } else if (selectedUnit) {
                                await loadExpenses(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'movements':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnitFinance) {
                                await loadMovements(selectedUnitFinance);
                              } else {
                                setUnitFinanceError(
                                  'Selecione uma opção válida.'
                                );
                              }
                            } else setDateFromError(true);
                            break;
                          case 'cashRegisterClose':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                await loadCashRegisterClose();
                              } else if (selectedUnit) {
                                await loadCashRegisterClose(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'cashEntry':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                await loadCashRegisterEntry();
                              } else if (selectedUnit) {
                                await loadCashRegisterEntry(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          case 'remaining':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit === 'all') {
                                await loadRemainingCashEntry();
                              } else if (selectedUnit) {
                                await loadRemainingCashEntry(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else setDateFromError(true);
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>
    </Container>
  );
}

export default FinancialReportList;
