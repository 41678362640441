import React, { useEffect, useState } from 'react';
import RadiologyConsumption from '../../../../forms/radiologyConsumption';
import CrudTable from '../../../../components/crudTable';
import { BackButton, Container } from './styles';
import restApiClient from '../../../../services/restApiClient';
import FilterTree from '../../../../helpers/filterTree';
import moment from 'moment';

function DreRadiologyConsumptionList({ history, match, location }) {
  const expenseType = location?.state?.expenseType;
  const expenseTypeId = match?.params?.expenseTypeId ?? -1;
  const type = location?.state?.type;
  const month = location?.state?.month;
  const year = location?.state?.year;
  const unitId = location?.state?.unitId;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = RadiologyConsumption.dreConsumptionTableSchema;

  useEffect(() => {
    if (type && month && year && expenseTypeId && unitId && expenseType) {
      restApiClient.radiologyConsumptionValue
        .getAllToDre(
          month,
          year,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  rightOp
                    .eq('origin.expenseTypeId', expenseType.id)
                    .conditionals((tree) => {
                      if (unitId != -1) {
                        tree.and().eq('radiologyConsumption.unitId', unitId);
                      } else {
                        switch (type) {
                          default:
                          case 1:
                            tree
                              .and()
                              .eq('radiologyConsumption.unit.type', 'clinic')
                              .and()
                              .eq('radiologyConsumption.unit.showInDre', true);
                            break;
                          case 2:
                            tree
                              .and()
                              .df('radiologyConsumption.unit.type', 'clinic');
                            break;
                          case 4:
                            tree.and().df('unit.type', 'distribution_center');
                            break;
                        }
                      }
                    });
                  rightOp.like('origin.name', searchBy);
                })
                .toString()
            : new FilterTree()
                .eq('origin.expenseTypeId', expenseType.id)
                .conditionals((tree) => {
                  if (unitId != -1) {
                    tree.and().eq('radiologyConsumption.unitId', unitId);
                  } else {
                    switch (type) {
                      default:
                      case 1:
                        tree
                          .and()
                          .eq('radiologyConsumption.unit.type', 'clinic')
                          .and()
                          .eq('radiologyConsumption.unit.showInDre', true);
                        break;
                      case 2:
                        tree
                          .and()
                          .df('radiologyConsumption.unit.type', 'clinic');
                        break;
                      case 4:
                        tree.and().df('unit.type', 'distribution_center');
                        break;
                    }
                  }
                })
                .toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={`Consumo - ${expenseType.name}`}
        customFooterComponent={
          <>
            <BackButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
          </>
        }
        modelActions={RadiologyConsumption.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        availableRowsPerPage={[15]}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          range: {
            renderer: (field, value, column, row) => {
              return `${moment(row.radiologyConsumption.beginDate).format(
                'DD/MM/YYYY'
              )} - ${moment(row.radiologyConsumption.endDate).format(
                'DD/MM/YYYY'
              )}`;
            },
          },
          value: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          correction: {
            renderer: (field, value, column, row) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          subtotal: {
            renderer: (field, value, column, row) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
    </Container>
  );
}

export default DreRadiologyConsumptionList;
