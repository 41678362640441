import styled from 'styled-components';
import {
  Box as MaterialBox,
  Save as MaterialSaveIcon,
} from '../../../../../styles/icons';
import {
  Button as MaterialButon,
  Typography as MaterialTypography,
} from '@material-ui/core';
import MoneyInput from '../../../../../components/moneyInput';
import TextFieldInput from '../../../../../components/textFieldInput';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 110px;
`;

export const StyledTextFieldInput = styled(TextFieldInput)`
  width: 70px;
`;

export const Button = styled(MaterialButon)``;

export const SaveIcon = styled(MaterialSaveIcon)`
  width: 25px;
  height: 25px;
`;

export const StyledTypography = styled(MaterialTypography)`
  margin-left: 20px !important;
`;
