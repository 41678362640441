const LabConfig = {
  schema: {
    type: 'object',
    properties: {
      labDate: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
      minimumLabTime: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
      daysForProductivityQueue: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
      monthCountForTheLabAverage: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
    },
    required: [
      'labDate',
      'minimumLabTime',
      'daysForProductivityQueue',
      'monthCountForTheLabAverage',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Configurações do Laboratório',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Laboratório',
            scope: '#/properties/labDate',
          },
          {
            type: 'Control',
            label: 'Prazo Mínimo Laboratório',
            scope: '#/properties/minimumLabTime',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Meses para média de produtividade',
            scope: '#/properties/monthCountForTheLabAverage',
          },
          {
            type: 'Control',
            label: 'Dias para fila de produtividade',
            scope: '#/properties/daysForProductivityQueue',
          },
        ],
      },
    ],
  },
  actions: {
    getAll: 'LabConfigController@getAll',
    update: 'LabConfigController@patchUpdate',
  },
};

export default LabConfig;
