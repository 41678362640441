const ItemizedCheck = {
  schema: {
    type: 'object',
    properties: {
      cashRegisterValueId: {
        type: 'integer',
        customErrors: '',
      },
      number: {
        type: 'integer',
        customErrors: '',
      },
      name: {
        type: 'string',
        minLength: 3,
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
    },
    required: ['cashRegisterValueId', 'number', 'value', 'name'],
  },

  uischema: {
    type: 'Group',
    label: 'Lançar entrada de cheque',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome/CPF',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número',
            scope: '#/properties/number',
          },
          {
            type: 'Control',
            label: 'Valor',
            scope: '#/properties/value',
          },
        ],
      },
    ],
  },
  tableSchema: [
    {
      id: 'number',
      label: 'Número',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor Bruto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'name',
      label: 'Nome/CPF',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  dialogTableSchema: [
    {
      id: 'number',
      label: 'Número',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor Bruto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'name',
      label: 'Nome/CPF',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'ItemizedCheckController@getAll',
    getAllByCashRegisterValueId:
      'ItemizedCheckController@getAllByCashRegisterValueId',
    create: 'ItemizedCheckController@create',
    findById: 'ItemizedCheckController@findById',
    setCompensation: 'ItemizedCheckController@setCompensation',
    setReturned: 'ItemizedCheckController@setReturned',
    delete: 'ItemizedCheckController@delete',
  },
};

export default ItemizedCheck;
