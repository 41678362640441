import {
  rankWith,
  and,
  uiTypeIs,
  schemaMatches,
  schemaTypeIs,
} from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectControl from '../../components/selectControl';
import isEmpty from 'lodash/isEmpty';


const isSelectControl = and(uiTypeIs('Control'), schemaTypeIs('integer'), 
  schemaMatches(schema => !isEmpty(schema) &&
    schema.format === 'select'));

const materialCustomSelectControlTester = rankWith(4, isSelectControl);

export default {
  tester: materialCustomSelectControlTester,
  renderer: withJsonFormsEnumProps(SelectControl),
};
