/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import {
  ActionsContainer,
  DropzoneContainer,
  SubmitButton,
  ContentContainer,
  DialogTitle,
  CancelButton,
  DialogContent,
  DialogActions,
} from './styles';
import restApiClient from '../../services/restApiClient';
import { DropzoneArea } from 'material-ui-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';

function InvoiceDialog({
  onSuccess,
  onError,
  onCancel,
  item,
  purchase,
  ...props
}) {
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState('');
  const [fetching, setFetching] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFetching(true);
    restApiClient.purchase
      .submitInvoice(purchase.id, file)
      .then((x) => {
        setErrors({});
        setFetching(false);
        onSuccess(x);
      })
      .catch((x) => {
        if (x.response?.status === 400) {
          setFetching(false);
          onError?.(x.response);
          setErrors(x.response.data.errors);
        }
      });
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Importação de nota fiscal
        </DialogTitle>
        <DialogContent>
          <ContentContainer>
            <DropzoneContainer>
              <DropzoneArea
                useChipsForPreview
                filesLimit={1}
                acceptedFiles={['text/xml', 'application/xml']}
                dropzoneText="Clique ou arraste o arquivo aqui"
                getFileAddedMessage={(fileName) =>
                  `Arquivo ${fileName} foi adicionado com sucesso.`
                }
                getFileRemovedMessage={(fileName) =>
                  `Arquivo ${fileName} foi removido com sucesso.`
                }
                onChange={(files) => {
                  if (files.length > 0) {
                    const reader = new FileReader();
                    reader.readAsText(files[0], 'UTF-8');
                    reader.onload = function (evt) {
                      const parser = new DOMParser();
                      const xmlDoc = parser.parseFromString(
                        evt.target.result,
                        'text/xml'
                      );

                      if (
                        xmlDoc.getElementsByTagName('parsererror').length > 0
                      ) {
                        setErrors({
                          invoice: ['O arquivo inserido contém erros de XML'],
                        });
                        return;
                      }

                      const cnpj = xmlDoc
                        .getElementsByTagName('emit')[0]
                        .getElementsByTagName('CNPJ')[0].innerHTML;

                      restApiClient.supplier
                        .findByCnpj(cnpj)
                        .then((result) => {
                          if (result.data.addressId !== purchase.supplierId) {
                            setErrors({
                              invoice: [
                                'Fornecedor da nota diferente do fornecedor da compra',
                              ],
                            });
                          }
                        })
                        .catch((e) => {
                          setErrors({
                            invoice: ['Fornecedor inexistente'],
                          });
                        });
                      setFile(files[0]);
                    };
                  } else {
                    setErrors({});
                  }
                }}
              />
            </DropzoneContainer>
            {errors?.invoice && (
              <Alert severity="error">{errors?.invoice}</Alert>
            )}
          </ContentContainer>
        </DialogContent>
        <DialogActions>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span>Importando Informações</span>
            </>
          ) : (
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  setErrors({});
                  onCancel?.();
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              {!errors?.invoice && (
                <SubmitButton type="submit" variant="contained" color="primary">
                  Entrar
                </SubmitButton>
              )}
            </ActionsContainer>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default InvoiceDialog;
