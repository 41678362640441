/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Item from '../../forms/item';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  PackageIcon,
} from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';

const SelectItemDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  config,
  errors,
  visible,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const allowBox = schema.allowBox;

  const handleClickOpen = () => {
    if (enabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = Item.unaryTableSchema;

  useEffect(() => {
    if (data) {
      restApiClient.item.findById(data).then((response) => {
        setSelectedItem(response.data);
      });
    }
  }, [data]);

  useEffect(() => {
    const filter = new FilterTree().conditionals((tree) => {
      if (allowBox || searchBy) {
        tree.and((leftOp, rightOp) => {
          leftOp.isNull('deletedAt');
          if (allowBox) rightOp.isNull('sonId');
          if (searchBy) {
            rightOp
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('barcode.barcode', searchBy);
          }
        });
      } else {
        tree.isNull('deletedAt');
      }
    });

    restApiClient.item
      .getAll(rowsPerPage, page * rowsPerPage, filter.toString(), orderBy)
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          error={!isEmpty(errors)}
          adornment={<PackageIcon />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label="Item"
          InputLabelProps={{
            readOnly: true,
          }}
          value={selectedItem?.name ?? ''}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
      </Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        maxWidth="xl"
      >
        <DialogContent>
          <StyledCrudTable
            title={'Seleção de Item Unitário'}
            modelActions={Item.actions}
            columns={columns}
            rows={rows}
            page={page}
            setPage={(page) => setPage(page)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[3]}
            setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            onOrderByChange={(orderBy) => setOrderby(orderBy)}
            orderBy={orderBy}
            setOrderBy={(value) => setOrderby(value)}
            searchBy={searchBy}
            setSearchBy={(value) => setSearchBy(value)}
            actions={{
              insert: {
                icon: <AddBox />,
                onClick: (id, data) => {
                  handleChange(path, id);
                  setSelectedItem(data);
                  uischema.handleAdditionalChanges?.(data, handleChange);
                  handleClose();
                },
                title: 'Vincular',
              },
            }}
            customCellRenderers={{
              hasConversion: {
                renderer: (field, value) => {
                  return value ? <PackageIcon /> : '-';
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Hidden>
  );
};

export default SelectItemDialogControl;
