/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  Procedures,
} from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';
import Procedure from '../../forms/procedure';

const SelectProcedureDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  required,
  ...props
}) => {
  const dentistId = useSelector((state) => state.authReducer?.user.id);
  const isDentist = useSelector((state) => state.authReducer?.user?.croNumber);
  const [open, setOpen] = React.useState(false);
  const [selectedProcedure, setSelectedProcedure] = React.useState({});

  const handleClickOpen = () => {
    if (enabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const columns = Procedure.procedureListSchema;

  useEffect(() => {
    if (isDentist) {
      restApiClient.procedure
        .getAllBySpecialtyDentist(
          dentistId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    if (data) {
      restApiClient.procedure.findById(data).then((e) => {
        setSelectedProcedure(e.data);
      });
    } else {
      setSelectedProcedure({});
    }
  }, [data]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<Procedures />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label={label}
          InputLabelProps={{
            readOnly: true,
          }}
          value={selectedProcedure?.name ?? ''}
          required={required}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
      </Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <StyledCrudTable
            title={'Seleção de Procedimento'}
            modelActions={Procedure.actions}
            columns={columns}
            rows={rows}
            page={page}
            setPage={(page) => setPage(page)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[3]}
            setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            onOrderByChange={(orderBy) => setOrderby(orderBy)}
            orderBy={orderBy}
            setOrderBy={(value) => setOrderby(value)}
            searchBy={searchBy}
            setSearchBy={(value) => setSearchBy(value)}
            actions={{
              insert: {
                icon: <AddBox />,
                onClick: (id, data) => {
                  const pathArray = path.split('.');
                  pathArray.pop();
                  if (pathArray.length > 0) {
                    handleChange?.(
                      `${pathArray.join('.')}.showToothNumber`,
                      data.toothNumber
                    );
                    handleChange?.(
                      `${pathArray.join('.')}.showDentalArch`,
                      data.dentalArch
                    );
                    handleChange?.(
                      `${pathArray.join('.')}.procedureName`,
                      data.name
                    );
                  } else {
                    handleChange?.('showToothNumber', data.toothNumber);
                    handleChange?.('showDentalArch', data.dentalArch);
                  }

                  setSelectedProcedure(data);
                  handleChange?.(path, id);
                  onSuccess?.(id);
                  handleClose();
                },
                title: 'Vincular',
                allowDentist: true,
              },
            }}
            customCellRenderers={{
              price: {
                renderer: (field, value) => {
                  value = parseFloat(value);
                  return value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  });
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Hidden>
  );
};

export default SelectProcedureDialogControl;
