import styled from 'styled-components';
import {
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel, 
  MenuItem as MaterialMenuItem,
  Select as MaterialSelect,
} from '@material-ui/core';
import CreateUpdateForm from '../../../../components/createUpdateForm';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;
export const Select = styled(MaterialSelect)`
  width: 100%;
`;
export const FormControl = styled(MaterialFormControl)`
  width: 40%;
`;
export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;
export const MenuItem = styled(MaterialMenuItem)``;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)`
  form {
    margin-top: 30px;
  }
`;

export const AutoDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 620px;
    width: 100%;
`;
