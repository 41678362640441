const File = {
  tableSchema: [
    {
      id: 'file.name',
      label: 'Nome do arquivo',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'createdAt',
      label: 'Data de criação',
      minWidth: 100,
      align: 'center',
    },
  ],
};
export default File;
