import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import forEach from 'lodash/forEach';

const ReportRadiologyConsolidatedComsumption = {
  buildPdf: (date, stockComsumption) => {
    const doc = new jsPDF();
    let y = 0;
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(
      15,
      (y += 15),
      `Relatório de Consumo Consolidado da Radiologia por Unidade`
    );

    doc.setFontSize(12);
    doc.text(15, (y += 8), `Período: ${date?.format('MM/YYYY')}`);
    let now = new Date();
    doc.text(
      15,
      (y += 5),
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds()
    );
    //CORPO DO PDF
    forEach(stockComsumption, function (unit, key) {
      if (Math.ceil(y) >= 260) {
        doc.addPage();
        y = 10;
      }
      // PARA CADA UNIDADE
      let unitTitle =
        key !== 'global' ? `Unidade: ${unit?.name}` : `Total por Grupo:`;
      y += 8;
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, y, 200, y);
      doc.setFontSize(12);
      doc.text(15, (y += 4), unitTitle);
      y += 2;
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, y, 200, y);
      doc.setFontSize(10);
      let bodyValue = [];
      forEach(unit.origins, function (value) {
        bodyValue.push([
          value?.originName,
          {
            content: parseFloat(value.value ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: { halign: 'left' },
          },
          {
            content: parseFloat(value.correction ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: { halign: 'left' },
          },
        ]);
      });
      doc.autoTable({
        startY: (y += 1),
        head: [
          [
            'Origem',
            { content: 'Valor', styles: { halign: 'left' } },
            { content: 'Correção', styles: { halign: 'left' } },
          ],
        ],
        body: bodyValue,
        theme: 'plain',
        tableWidth: 'wrap',
        styles: { fontSize: 9 },
      });
      y = doc.lastAutoTable.finalY;

      y += 2;
      doc.setLineWidth(0.8);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, y, 200, y);
      doc.setFontSize(12);
      doc.text(
        15,
        (y += 5),
        `Total: ${parseFloat(unit?.total ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`
      );
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 290, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default ReportRadiologyConsolidatedComsumption;
