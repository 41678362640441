import styled from 'styled-components';
import {
  DialogContent as MaterialDialogContent,
  Button as MaterialButton,
  Table as MaterialTable,
  TableBody as MaterialTableBody,
  TableCell as MaterialTableCell,
  TableContainer as MaterialTableContainer,
  TableHead as MaterialTableHead,
  TableRow as MaterialTableRow,
  Paper as MaterialPaper,
  Card as MaterialCard,
} from '@material-ui/core';

export const Container = styled.div``;

export const TextStyledH6 = styled.h6`
  font-family: Arial Black, sans-serif !important;
`;

export const TextStyledH5 = styled.h5`
  font-family: Arial Black, sans-serif !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  min-height: 300px;
  min-width: 400px;
  padding: 5px !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const AutoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Table = styled(MaterialTable)``;

export const TableBody = styled(MaterialTableBody)``;

export const BodyTableCell = styled(MaterialTableCell)`
  font-family: Arial, sans-serif !important;
  font-weight: 900;
  font-size: 10px !important;
  margin: 0px !important;
  padding: 0px !important;
  border-bottom: none !important;
`;

export const HeaderTableCell = styled(MaterialTableCell)`
  font-family: Arial Black, sans-serif !important;
  font-size: 10px !important;
  margin: 0px !important;
  padding: 0px !important;
  border-bottom: none !important;
`;

export const TableContainer = styled(MaterialTableContainer)`
  width: 100%;
  margin: 0px !important;
  box-shadow: none !important;
`;

export const TableHead = styled(MaterialTableHead)``;

export const TableRow = styled(MaterialTableRow)`
  margin-top: 5px !important;
`;

export const Paper = styled(MaterialPaper)``;

export const DottedLine = styled.div`
  border-bottom: 1px dotted #000000;
  width: 100%;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
`;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  padding: 10px;
`;
