import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportStockComsumptionUnit = {
  buildStockComsumptionPdf: (from, to, units, groups, stockComsumption) => {
    const doc = new jsPDF();
    let y = 0;
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(15, (y += 15), `Relatório de Resumo de Consumo por Unidade`);

    doc.setFontSize(12);
    doc.text(15, (y += 8), `Período: ${from} a ${to}`);
    let now = new Date();
    doc.text(
      15,
      (y += 5),
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds()
    );
    //CORPO DO PDF
    Object.keys(stockComsumption).forEach(function (unit) {
      if (Math.ceil(y) >= 260) {
        doc.addPage();
        y = 10;
      }
      if (unit !== 'totalGroup') {
        // PARA CADA UNIDADE
        const unitSelected = units.values.find(
          (x) => x.addressId === parseInt(unit)
        );
        y += 8;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y, 200, y);
        doc.setFontSize(12);
        doc.text(15, (y += 4), `Unidade: ${unitSelected?.name}`);
        y += 2;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y, 200, y);
        doc.setFontSize(10);
        let bodyValue = [];
        Object.keys(stockComsumption[unit].groups).forEach(function (value) {
          const groupValue = stockComsumption[unit].groups[value];
          const groupSelected = groups.find((x) => x.id === groupValue.groupId);
          bodyValue.push([
            groupSelected.name,
            {
              content: parseFloat(groupValue.value ?? 0).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              styles: { halign: 'right' },
            },
            {
              content: parseFloat(groupValue.result ?? 0).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              styles: { halign: 'right' },
            },
            {
              content: parseFloat(groupValue.loss ?? 0).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              styles: { halign: 'right' },
            },
          ]);
        });
        doc.autoTable({
          startY: (y += 1),
          head: [
            [
              'Grupo',
              'Transferência',
              { content: 'Ajustes', styles: { halign: 'right' } },
              { content: 'Perda', styles: { halign: 'right' } },
            ],
          ],
          body: bodyValue,
          theme: 'plain',
          tableWidth: 'wrap',
          styles: { fontSize: 9 },
        });
        y = doc.lastAutoTable.finalY;

        y += 2;
        doc.setLineWidth(0.8);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y, 200, y);
        doc.setFontSize(12);
        let totalUnit =
          parseFloat(stockComsumption[unit].totalUnit ?? 0) +
          parseFloat(stockComsumption[unit].totalAdjustUnit ?? 0);
        doc.text(
          15,
          (y += 5),
          `Total: ${parseFloat(totalUnit).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}`
        );
      } else {
        //Total geral
        y += 10;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y, 200, y);
        doc.setFontSize(12);
        doc.text(15, (y += 4), `Total por Grupo:`);
        y += 2;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y, 200, y);
        doc.setFontSize(10);
        let bodyTotalValue = [];
        Object.keys(stockComsumption[unit].groups).forEach(function (value) {
          const groupTotalValue = stockComsumption[unit].groups[value];
          const groupTotalSelected = groups.find(
            (x) => x.id === groupTotalValue.groupId
          );
          bodyTotalValue.push([
            groupTotalSelected.name,
            {
              content: parseFloat(groupTotalValue.value ?? 0).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              styles: { halign: 'right' },
            },
            {
              content: parseFloat(groupTotalValue.result ?? 0).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              styles: { halign: 'right' },
            },
            {
              content: parseFloat(groupTotalValue.loss ?? 0).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              styles: { halign: 'right' },
            },
          ]);
        });
        doc.autoTable({
          startY: (y += 1),
          head: [
            [
              'Grupo',
              'Transferência',
              { content: 'Ajustes', styles: { halign: 'right' } },
              { content: 'Perda', styles: { halign: 'right' } },
            ],
          ],
          body: bodyTotalValue,
          theme: 'plain',
          tableWidth: 'wrap',
          styles: { fontSize: 9 },
        });
        y = doc.lastAutoTable.finalY;

        y += 2;
        doc.setLineWidth(0.8);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y, 200, y);
        doc.setFontSize(12);
        let totalGlobal =
          parseFloat(stockComsumption[unit].totalGlobal ?? 0) +
          parseFloat(stockComsumption[unit].totalAdjustGlobal ?? 0);
        doc.text(
          15,
          (y += 4),
          `Total: ${parseFloat(totalGlobal).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}`
        );
      }
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 290, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default ReportStockComsumptionUnit;
