import Stock from '../../../../forms/stock';
import { Container, BoxRemove } from './styles';
import React, { useState, useEffect } from 'react';
import FilterTree from '../../../../helpers/filterTree';
import CrudTable from '../../../../components/crudTable';
import restApiClient from '../../../../services/restApiClient';
import WithdrawClinicStockDialog from '../../../../components/withdrawClinicStockDialog';
import Item from '../../../../forms/item';

function ClinicListItemWithdraw() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const columns = Item.withdrawItemsTableSchema;

  useEffect(() => {
    restApiClient.item
      .getAllItemInStockByClinic(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('brand', searchBy)
              .or()
              .like('barcode.barcode', searchBy)
              .or()
              .like('itemLocators.locator', searchBy)
              .or()
              .like('group.name', searchBy)
              .or()
              .like('group.parent.name', searchBy)
              .or()
              .like('group.parent.parent.name', searchBy)
              .or()
              .like('stock.availableQuantity', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedRow]);

  return (
    <Container>
      <CrudTable
        title={'Itens'}
        modelActions={Item.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          itemWithdraw: {
            icon: <BoxRemove />,
            onClick: (id, row) => {
              setOpen(true);
              setSelectedRow(row);
            },
            title: 'Dar baixa do item',
            allowedActions: [Stock.actions.withdrawItem],
            selector: (row) => row?.stock[0]?.availableQuantity > 0,
          },
        }}
        customCellRenderers={{
          stock: {
            renderer: (field, value) => {
              return value[0]?.availableQuantity
                ? value[0]?.availableQuantity
                : '-';
            },
          },
          itemLocators: {
            renderer: (field, value) => {
              return value[0]?.locator ? value[0]?.locator : '-';
            },
          },
          group: {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value.name;
              }
            },
          },
          barcode: {
            renderer: (field, value, column, row) => {
              return value.map((item) => item.barcode).join(', ');
            },
          },
        }}
      />

      <WithdrawClinicStockDialog
        open={open}
        item={selectedRow}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          setSelectedRow({});
        }}
      />
    </Container>
  );
}

export default ClinicListItemWithdraw;
