const Attendance = {
  schema: {
    type: 'object',
    properties: {
      recordNumberUninformed: {
        type: 'boolean',
      },
      recordNumber: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
        recordNumber: '',
      },
      dentistId: {
        type: 'number',
        format: 'selectDentist',
        customErrors: '',
      },
      name: {
        type: 'string',
        customErrors: '',
        handleAsyncChanges: (data) => {
          data.recordNumberFound = true;
        },
      },
      attendanceDate: {
        type: 'string',
        format: 'date',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      type: {
        type: 'string',
        customErrors: '',
      },
      patientId: {
        type: 'number',
        customErrors: '',
      },
      requestingUnitBoolean: {
        type: 'boolean',
        customErrors: '',
      },
      procedures: {
        type: 'array',
        customErrors: '',
        items: {
          type: 'object',
          properties: {
            procedureId: {
              format: 'selectProcedureDialog',
              customErrors: '',
            },
            procedureName: {
              type: 'string',
              customErrors: '',
            },
            dentalArch: {
              format: 'customEnum',
              enum: ['', 'Superior', 'Inferior', 'Ambas'],
              customErrors: '',
              customRules: true,
            },
            toothNumber: {
              format: 'selectToothNumber',
              customErrors: '',
              customRules: true,
            },
            observation: {
              type: ['string', 'null'],
              customErrors: '',
            },
          },
        },
      },
      recordNumberFound: {
        type: 'boolean',
        customErrors: '',
      },
      showDentalArch: {
        type: 'boolean',
        customErrors: '',
      },
      showToothNumber: {
        type: 'boolean',
        customErrors: '',
      },
    },
    required: ['name', 'unitId'],
  },
  updateSchema: {
    type: 'object',
    properties: {
      recordNumberUninformed: {
        type: 'boolean',
      },
      recordNumber: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
        recordNumber: '',
      },
      name: {
        type: 'string',
        customErrors: '',
      },
      dentistId: {
        type: 'number',
        format: 'selectDentist',
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      patientId: {
        type: 'number',
        customErrors: '',
      },
      attendanceDate: {
        type: 'string',
        format: 'date',
      },
      requestingUnitBoolean: {
        type: 'boolean',
        customErrors: '',
      },
      procedureId: {
        type: 'integer',
        format: 'selectProcedureDialog',
        customErrors: '',
      },
      dentalArch: {
        format: 'customEnum',
        enum: ['Superior', 'Inferior', 'Ambas'],
        customErrors: '',
        customRules: true,
      },
      toothNumber: {
        format: 'selectToothNumber',
        customErrors: '',
        customRules: true,
      },
      observation: {
        type: ['string', 'null'],
        customErrors: '',
      },
      recordNumberFound: {
        type: 'boolean',
        customErrors: '',
      },
      showDentalArch: {
        type: 'boolean',
        customErrors: '',
      },
      showToothNumber: {
        type: 'boolean',
        customErrors: '',
      },
      history: {
        type: 'array',
        customErrors: '',
        items: {
          type: 'object',
          properties: {
            dentistId: {
              type: 'number',
              format: 'selectDentist',
              customErrors: '',
            },
            procedureId: {
              format: 'selectProcedureDialog',
              customErrors: '',
            },
            attendanceDate: {
              type: 'string',
              format: 'date',
            },
            dentalArch: {
              format: 'customEnum',
              enum: ['', 'Superior', 'Inferior', 'Ambas'],
              customErrors: '',
              customRules: true,
            },
            toothNumber: {
              format: 'selectToothNumber',
              customErrors: '',
              customRules: true,
            },
            observation: {
              type: ['string', 'null'],
              customErrors: '',
            },
          },
        },
      },
    },
    required: ['name', 'unitId'],
  },
  uischema: {
    type: 'Group',
    label: 'Criação de Atendimento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário não informado',
            scope: '#/properties/recordNumberUninformed',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade do Paciente',
            scope: '#/properties/unitId',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: 'Dentista',
            scope: '#/properties/dentistId',
            options: {
              readonly: true,
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do Prontuário',
            scope: '#/properties/recordNumber',
          },
          {
            type: 'Control',
            label: 'Data de Atendimento',
            scope: '#/properties/attendanceDate',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/recordNumberUninformed',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data de Atendimento',
            scope: '#/properties/attendanceDate',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/recordNumberUninformed',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome do Paciente',
            scope: '#/properties/name',
          },
        ],
        rule: {
          effect: 'DISABLE',
          condition: {
            scope: '#/properties/requestingUnitBoolean',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Procedimentos',
            },
            scope: '#/properties/procedures',
            options: {
              elementLabelProp: 'procedureName',
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Nome do Procedimento',
                        scope: '#/properties/procedureId',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Arcadas*',
                        scope: '#/properties/dentalArch',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Número do dente',
                        scope: '#/properties/toothNumber',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Observação',
                        scope: '#/properties/observation',
                      },
                    ],
                  },
                ],
              },
            },
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/recordNumberFound',
                schema: {
                  const: false,
                },
              },
            },
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Atendimento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário não informado',
            scope: '#/properties/recordNumberUninformed',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade do Paciente',
            scope: '#/properties/unitId',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: 'Dentista',
            scope: '#/properties/dentistId',
            options: {
              readonly: true,
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do Prontuário',
            scope: '#/properties/recordNumber',
          },
          {
            type: 'Control',
            label: 'Data de Atendimento',
            scope: '#/properties/attendanceDate',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/recordNumberUninformed',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data de Atendimento',
            scope: '#/properties/attendanceDate',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/recordNumberUninformed',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome do Paciente',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'Group',
        label: 'Procedimento',
        elements: [
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome do Procedimento',
                scope: '#/properties/procedureId',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Arcadas*',
                scope: '#/properties/dentalArch',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Número do dente',
                scope: '#/properties/toothNumber',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Observação',
                scope: '#/properties/observation',
              },
            ],
          },
        ],
      },
      {
        type: 'Group',
        label: 'Histórico de Retrabalho',
        elements: [
          {
            type: 'ControlCustomArray',
            label: {
              text: '',
            },
            noData: '',
            scope: '#/properties/history',
            options: {
              readonly: true,
              elementLabelProp: 'attendanceDate',
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Data de Atendimento',
                        scope: '#/properties/attendanceDate',
                        options: {
                          readonly: true,
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Nome do Dentista',
                        scope: '#/properties/dentistId',
                        options: {
                          readonly: true,
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Nome do Procedimento',
                        scope: '#/properties/procedureId',
                        options: {
                          readonly: true,
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Arcadas*',
                        scope: '#/properties/dentalArch',
                        options: {
                          readonly: true,
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Número do dente',
                        scope: '#/properties/toothNumber',
                        options: {
                          readonly: true,
                        },
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Observação',
                        scope: '#/properties/observation',
                        options: {
                          readonly: true,
                        },
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/history',
            schema: {
              const: [],
            },
          },
        },
      },
    ],
    rule: {
      effect: 'ENABLE',
      condition: {
        scope: '#/properties/readonly',
        schema: {
          const: false,
        },
      },
    },
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 100,
      align: 'center',
    },
    { id: 'type', label: 'Tipo Agenda', minWidth: 100, align: 'center' },
    { id: 'patient.name', label: 'Paciente', minWidth: 100, align: 'center' },
    {
      id: 'procedure.name',
      label: 'Procedimento',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'attendanceDate',
      label: 'Data Realização',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
  ],
  replacementsTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    {
      id: 'dentist.user.name',
      label: 'Dentista Substituto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'replacedDentist.user.name',
      label: 'Dentista Substituído',
      minWidth: 100,
      align: 'center',
    },
    { id: 'type', label: 'Tipo Agenda', minWidth: 100, align: 'center' },
    { id: 'patient.name', label: 'Paciente', minWidth: 100, align: 'center' },
    {
      id: 'procedure.name',
      label: 'Procedimento',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'attendanceDate',
      label: 'Data Realização',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'AttendanceController@getAll',
    getAllByDate: 'AttendanceController@getAllByDate',
    getAllAttendancesFixedAndProductivity: 'AttendanceController@getAll',
    getAllInClinic: 'AttendanceController@getAllInClinic',
    findById: 'AttendanceController@findById',
    create: 'AttendanceController@create',
    update: 'AttendanceController@patchUpdate',
    delete: 'AttendanceController@delete',
    setAuditing: 'AttendanceController@setAuditing',
    setApproved: 'AttendanceController@setApproved',
    setDisapproved: 'AttendanceController@setDisapproved',
  },
};

export default Attendance;
