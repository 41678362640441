import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectEndProductDialogControl from '../../components/selectEndProductDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectEndProductDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectEndProductDialog'
  )
);

const customSelectEndProductDialogControlTester = rankWith(
  4,
  isSelectEndProductDialogControl
);

export default {
  tester: customSelectEndProductDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectEndProductDialogControl),
};
