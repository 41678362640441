const Outsourced = {
  schema: {
    type: 'object',
    properties: {
      isPhysicalPerson: {
        type: 'boolean',
      },
      socialName: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com um Nome Social',
        customErrors: '',
      },
      cpf: {
        type: 'string',
        mask: '999.999.999-99',
        minLength: 11,
        maxLength: 14,
        customErrors: '',
        cpf: '',
      },
      fantasyName: {
        type: 'string',
        customErrors: '',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com um Nome Fantasia',
      },
      cep: {
        type: 'string',
        description: 'Digite somente números',
        mask: '99999-999',
        cep: '',
        minLength: 8,
        maxLength: 8,
        customErrors: '',
      },
      address: {
        type: 'string',
        customErrors: '',
      },
      number: {
        type: 'string',
        minLength: 1,
        maxLength: 8,
        customErrors: '',
      },
      complement: {
        type: 'string',
        maxLength: 128,
        customErrors: '',
      },
      neighborhood: {
        type: 'string',
        customErrors: '',
      },
      city: {
        type: 'string',
        customErrors: '',
      },
      state: {
        type: 'string',
        customErrors: '',
      },
      cnpj: {
        type: 'string',
        customErrors: '',
        mask: '99.999.999/9999-99',
        cnpj: '',
        minLength: 14,
        maxLength: 18,
      },
      stateRegistry: {
        type: 'string',
        customErrors: '',
        stateRegistry: '',
      },
      email: {
        type: 'string',
        format: 'email',
        customErrors: '',
      },
      phoneNumber: {
        type: 'string',
        mask: '(99) 9999-9999',
        maskSelector: (value) => {
          const len = value.replace(/[\W_]/g, '').length;
          if (len > 10) return '(99) 99999-9999';
          else return '(99) 9999-9999';
        },
        customErrors: '',
        phone: '',
      },
      alternativePhoneNumber: {
        type: 'string',
        mask: '(99) 9999-9999',
        maskSelector: (value) => {
          const len = value.replace(/[\W_]/g, '').length;
          if (len > 10) return '(99) 99999-9999';
          else return '(99) 9999-9999';
        },
        customErrors: '',
        phone: '',
      },
      outsourcedBankAccounts: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            bank: {
              type: 'string',
              customErrors: '',
            },
            agency: {
              type: 'string',
              customErrors: '',
            },
            agencyDigit: {
              type: 'string',
              customErrors: '',
              description: 'Dígito da agência, caso não tenha deixe vazio!',
            },
            account: {
              type: 'string',
              customErrors: '',
            },
            accountDigit: {
              type: 'string',
              customErrors: '',
              description: 'Dígito da conta, caso não tenha deixe vazio!',
            },
          },
        },
      },
    },
    required: [
      'socialName',
      'email',
      'phoneNumber',
      'cep',
      'address',
      'neighborhood',
      'number',
      'city',
      'state',
    ],
  },

  uischema: {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Group',
        label: 'Cadastro de Terceirizado',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'É Pessoa Física?',
                scope: '#/properties/isPhysicalPerson',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Social',
                scope: '#/properties/socialName',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CPF*',
                scope: '#/properties/cpf',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Fantasia*',
                scope: '#/properties/fantasyName',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Cep',
                scope: '#/properties/cep',
              },
              {
                type: 'Control',
                label: 'Endereço',
                scope: '#/properties/address',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Número',
                scope: '#/properties/number',
              },
              {
                type: 'Control',
                label: 'Complemento',
                scope: '#/properties/complement',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Bairro',
                scope: '#/properties/neighborhood',
              },
              {
                type: 'Control',
                label: 'Município',
                scope: '#/properties/city',
              },
              {
                type: 'Control',
                label: 'Estado',
                scope: '#/properties/state',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CNPJ*',
                scope: '#/properties/cnpj',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
              {
                type: 'Control',
                label: 'Inscrição Estadual',
                scope: '#/properties/stateRegistry',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Email',
                scope: '#/properties/email',
              },
              {
                type: 'Control',
                label: 'Telefone',
                scope: '#/properties/phoneNumber',
              },
              {
                type: 'Control',
                label: 'Telefone Alternativo',
                scope: '#/properties/alternativePhoneNumber',
              },
            ],
          },
        ],
      },
      {
        type: 'Group',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Dados Bancários do Terceirizado',
            },
            scope: '#/properties/outsourcedBankAccounts',
            options: {
              detail: {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: 'Banco',
                    scope: '#/properties/bank',
                  },
                  {
                    type: 'Control',
                    label: 'Conta',
                    scope: '#/properties/account',
                  },
                  {
                    type: 'Control',
                    label: 'Dígito da Conta',
                    scope: '#/properties/accountDigit',
                  },
                  {
                    type: 'Control',
                    label: 'Agência',
                    scope: '#/properties/agency',
                  },
                  {
                    type: 'Control',
                    label: 'Dígito da Agência',
                    scope: '#/properties/agencyDigit',
                  },
                ],
              },
            },
          },
        ],
      },
    ],
  },

  updateUischema: {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Group',
        label: 'Edição de Terceirizado',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Social',
                scope: '#/properties/socialName',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CPF*',
                scope: '#/properties/cpf',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Fantasia*',
                scope: '#/properties/fantasyName',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Cep',
                scope: '#/properties/cep',
              },
              {
                type: 'Control',
                label: 'Endereço',
                scope: '#/properties/address',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Número',
                scope: '#/properties/number',
              },
              {
                type: 'Control',
                label: 'Complemento',
                scope: '#/properties/complement',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Bairro',
                scope: '#/properties/neighborhood',
              },
              {
                type: 'Control',
                label: 'Município',
                scope: '#/properties/city',
              },
              {
                type: 'Control',
                label: 'Estado',
                scope: '#/properties/state',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CNPJ*',
                scope: '#/properties/cnpj',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
              {
                type: 'Control',
                label: 'Inscrição Estadual',
                scope: '#/properties/stateRegistry',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isPhysicalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Email',
                scope: '#/properties/email',
              },
              {
                type: 'Control',
                label: 'Telefone',
                scope: '#/properties/phoneNumber',
              },
              {
                type: 'Control',
                label: 'Telefone Alternativo',
                scope: '#/properties/alternativePhoneNumber',
              },
            ],
          },
        ],
      },
      {
        type: 'Group',
        elements: [
          {
            label: {
              text: 'Dados Bancários do Terceirizado',
            },
            type: 'Control',
            scope: '#/properties/outsourcedBankAccounts',
            options: {
              detail: {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: 'Banco',
                    scope: '#/properties/bank',
                  },
                  {
                    type: 'Control',
                    label: 'Conta',
                    scope: '#/properties/account',
                  },
                  {
                    type: 'Control',
                    label: 'Dígito da Conta',
                    scope: '#/properties/accountDigit',
                  },
                  {
                    type: 'Control',
                    label: 'Agência',
                    scope: '#/properties/agency',
                  },
                  {
                    type: 'Control',
                    label: 'Dígito da Agência',
                    scope: '#/properties/agencyDigit',
                  },
                ],
              },
            },
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'socialName', label: 'Nome Social', minWidth: 100, align: 'center' },
    {
      id: 'fantasyName',
      label: 'Nome Fantasia',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'OutsourcedController@getAll',
    findById: 'OutsourcedController@findById',
    findByCnpj: 'OutsourcedController@findByCnpj',
    create: 'OutsourcedController@create',
    update: 'OutsourcedController@patchUpdate',
    delete: 'OutsourcedController@delete',
    restore: 'OutsourcedController@restore',
    createOutsourcedStepPrice: 'OutsourcedStepPriceController@create',
    updateOutsourcedStepPrice: 'OutsourcedStepPriceController@patchUpdate',
    findByStepAndOutsourced:
      'OutsourcedStepPriceController@findByStepAndOutsourced',
  },
  data: {
    isPhysicalPerson: false,
    cnpj: undefined,
    stateRegistry: undefined,
    cpf: undefined,
    fantasyName: undefined,
  },
};

export default Outsourced;
