const Step = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com nome da etapa',
        customErrors: '',
      },
      sectionId: {
        format: 'selectSectionDialog',
        customErrors: '',
      },
      price: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      toothNumber: {
        type: 'boolean',
        customErrors: '',
      },
      dentalArch: {
        type: 'boolean',
        customErrors: '',
      },
      toothColor: {
        type: 'boolean',
        customErrors: '',
      },
      workTime: {
        type: 'number',
        customErrors: '',
        format: 'float',
      },
      suggestedExecutors: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            employeeId: {
              title: '',
              type: 'number',
              format: 'selectLabEmployeeDialog',
              customErrors: '',
            },
          },
          required: ['employeeId'],
        },
      },
    },
    required: [
      'name',
      'sectionId',
      'price',
      'toothNumber',
      'dentalArch',
      'toothColor',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Etapa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: 'Seção, seção secundária ou seção terciária',
            scope: '#/properties/sectionId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do dente',
            scope: '#/properties/toothNumber',
          },
          {
            type: 'Control',
            label: 'Arcada dentária',
            scope: '#/properties/dentalArch',
          },
          {
            type: 'Control',
            label: 'Cor do dente',
            scope: '#/properties/toothColor',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Preço',
            scope: '#/properties/price',
          },
          {
            type: 'Control',
            label: 'Tempo de trabalho',
            scope: '#/properties/workTime',
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Executor(es) sugerido(s)',
            scope: '#/properties/suggestedExecutors',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'section', label: 'Seção', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 25, align: 'center' },
  ],
  outsourcedTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'section', label: 'Seção', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'StepController@getAll',
    findById: 'StepController@findById',
    create: 'StepController@create',
    update: 'StepController@patchUpdate',
  },
  data: {
    toothNumber: false,
    dentalArch: false,
    toothColor: false,
  },
};

export default Step;
