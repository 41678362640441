const BudgetItem = {
  tableSchema: [
    {
      id: 'item.id',
      label: 'Código Interno',
      minWidth: 25,
      align: 'center',
    },
    { id: 'item.name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'item.group',
      label: 'Categorização',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'itemQuantity',
      label: 'Quantidade Pedido',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'price',
      label: 'Preço Pedido',
      minWidth: 100,
      align: 'center',
    },
  ],
  importItemsTableSchema: [
    { id: 'enabled', label: '', minWidth: 10, align: 'center' },
    {
      id: 'item.id',
      label: 'Código Interno',
      minWidth: 25,
      align: 'center',
    },
    { id: 'item.name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'item.group',
      label: 'Categorização',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'itemQuantity',
      label: 'Quantidade Pedido',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'price',
      label: 'Preço Pedido',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAllByBudgetId: 'BudgetItemController@getAllByBudgetId',
  },
};

export default BudgetItem;
