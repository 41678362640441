const Report = {
  schema: {
    type: 'object',
    properties: {
      year: {
        type: 'integer',
        minimum: 2021,
        customErrors: '',
      },
      city: {
        format: 'selectCityDialog',
        customErrors: '',
      },
    },
    required: ['year', 'city'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Calendário',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Ano',
            scope: '#/properties/year',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Município',
            scope: '#/properties/city',
          },
        ],
      },
    ],
  },
  tableSchema: [
      { id: 'city', label: 'Município', minWidth: 100, align: 'center' },
      { id: 'year', label: 'Ano', minWidth: 100, align: 'center' },
    ],
  actions: {
      getAll: 'CalendarController@getAll',
      findById: 'CalendarController@findById',
      create: 'CalendarController@create',
  },
  data: {
    year:  new Date().getFullYear(),
  },
};
  
  export default Report;
  