/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import DentalChart from '../dentalChart';
import { Container, FormControl, FormHelperText } from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';

const SelectToothNumberControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  ...props
}) => {
  const [tooths, setTooths] = useState([]);

  if (schema.customRules) {
    const pathArray = path.split('.');
    pathArray.pop();
    let crrPtr = config.completeData;

    pathArray.forEach((x) => {
      crrPtr = crrPtr[x];
    });

    visible = crrPtr.showToothNumber || crrPtr.toothNumber;
  }

  useEffect(() => {
    setTooths(
      data
        ?.split(',')
        .filter((x) => x !== '')
        .map((x) => parseInt(x)) ?? []
    );
  }, [data]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <FormControl error={!isEmpty(errors)}>
          <DentalChart
            disabled={!enabled}
            selectedTooth={tooths}
            label={label}
            toothSelected={(id) => {
              let newTooths = [...tooths];
              if (tooths.includes(id)) {
                var index = newTooths.indexOf(id);
                if (index > -1) {
                  newTooths.splice(index, 1);
                }
              } else {
                newTooths.push(id);
              }
              handleChange(path, newTooths.join());
            }}
          />
          <FormHelperText>{!isEmpty(errors) ? errors : ' '}</FormHelperText>
        </FormControl>
      </Container>
    </Hidden>
  );
};

export default SelectToothNumberControl;
