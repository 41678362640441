const PurchaseItem = {
  tableSchema: [
    {
      id: 'item.id',
      label: 'ID',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'item.name',
      label: 'Nome do Item',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'purchaseQuantity',
      label: 'Quantidade Compra',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'receivedQuantity',
      label: 'Quantidade Recebida',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'price',
      label: 'Preço',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAllByPurchaseId: 'PurchaseItemController@getAllByPurchaseId',
  },
};

export default PurchaseItem;
