import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  DialogContent as MaterialDialogContent,
} from '@material-ui/core';
import CreateUpdateForm from '../createUpdateForm';

export const Dialog = styled(MaterialDialog)`
  width: 100%;
`;

export const Container = styled.div``;

export const DialogContent = styled(MaterialDialogContent)`
  width: 550px !important;
  padding: 0px !important;
`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)`
  margin-bottom: 25px !important;
`;
