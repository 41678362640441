import React, { useState, useCallback, useEffect } from 'react';
import DentistWorkedHour from '../../../../../../forms/dentistWorkedHour';
import Attendance from '../../../../../../forms/attendance';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import {
  Container,
  FileEarmarkMedicalFill,
  CancelButton,
  ViewShow,
} from './styles';
import moment from 'moment';

function ScheduleAttendancesAuditList({ history, ...props }) {
  const workedhourId = props.match.params?.id ?? -1;
  const replacement = props.location?.state?.replacement ?? false;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = DentistWorkedHour.attendancesTableSchema;

  const loadAttendances = useCallback(() => {
    restApiClient.attendance
      .getAllAttendancesFixedAndProductivity(
        -1,
        null,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild.eq(
                  'workedHourAttendance.worked_hour_id',
                  workedhourId
                );
                rightChild
                  .like('id', searchBy)
                  .or()
                  .like('type', searchBy)
                  .or()
                  .like('patient.name', searchBy)
                  .or()
                  .like('procedure.name', searchBy)
                  .or()
                  .like('unit.name', searchBy)
                  .or()
                  .like('status', searchBy);
              })
              .toString()
          : new FilterTree()
              .eq('workedHourAttendance.worked_hour_id', workedhourId)
              .toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, workedhourId]);

  useEffect(() => {
    loadAttendances();
  }, [loadAttendances]);

  return (
    <Container>
      <CrudTable
        title={'Auditar Procedimentos'}
        modelActions={DentistWorkedHour.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          audit: {
            icon: <FileEarmarkMedicalFill />,
            onClick: (id, row) => {
              let path = replacement
                ? `/dentist/audit/ScheduleReplacements/procedure/${row.id}`
                : `/dentist/audit/procedure/${row.id}`;
              history.push({
                pathname: path,
                state: {
                  readonly: true,
                },
              });
            },
            title: 'Auditar Procedimento',
            allowedActions: [
              [
                Attendance.actions.findById,
                Attendance.actions.setApproved,
                Attendance.actions.setDisapproved,
              ],
            ],
            selector: (row) => row?.status === 'Sob Auditoria',
          },
          view: {
            icon: <ViewShow />,
            onClick: (id, row) => {
              history.push({
                pathname: `/dentist/dentistWorkedHour/attendance/${row.id}`,
                state: {
                  readonly: true,
                },
              });
            },
            title: 'Visualizar Procedimento',
            selector: (row) => row?.status !== 'Sob Auditoria',
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.history) {
            return '#ffe0e0';
          }
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />
    </Container>
  );
}

export default ScheduleAttendancesAuditList;
