const ItemBarcode = {
    actions: {
        getAll: 'ItemBarcodeController@getAll',
        findById: 'ItemBarcodeController@findById',
        create: 'ItemBarcodeController@create',
        update: 'ItemBarcodeController@patchUpdate',
        delete: 'ItemBarcodeController@delete',
    },
  };
  
  export default ItemBarcode;
  