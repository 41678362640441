/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Address from '../../forms/address';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  Truck,
} from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';

const SelectAnySupplierDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  required,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedSupplier, setSelectedSupplier] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const columns = Address.tableSchema;

  useEffect(() => {
    restApiClient.address
      .getAllWithSupplier(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('supplier.socialName', searchBy)
              .or()
              .like('supplier.fantasyName', searchBy)
              .or()
              .like('outsourced.socialName', searchBy)
              .or()
              .like('outsourced.fantasyName', searchBy)
              .or()
              .like('serviceSupplier.socialName', searchBy)
              .or()
              .like('serviceSupplier.fantasyName', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows({
          count: e.data.count,
          values: e.data.values.map((item) =>
            item.supplier
              ? { ...item.supplier, type: 'supplier', typeLabel: 'Estoque' }
              : item.outsourced
              ? {
                  ...item.outsourced,
                  type: 'outsourced',
                  typeLabel: 'Laboratório',
                }
              : {
                  ...item.serviceSupplier,
                  type: 'serviceSupplier',
                  typeLabel: 'Financeiro',
                }
          ),
        });
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    if (data) {
      restApiClient.address.findWithSupplierById(data).then((e) => {
        setSelectedSupplier(e.data);
      });
    } else {
      setSelectedSupplier({});
    }
  }, [data]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<Truck />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label={label ?? 'Fornecedor'}
          InputLabelProps={{
            readOnly: true,
          }}
          required={required}
          value={
            selectedSupplier?.supplier?.socialName ??
            selectedSupplier?.serviceSupplier?.socialName ??
            selectedSupplier?.outsourced?.socialName ??
            ''
          }
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <StyledCrudTable
              title={'Seleção de Fornecedor'}
              modelActions={Address.actions}
              columns={columns}
              rows={rows}
              page={page}
              setPage={(page) => setPage(page)}
              rowsPerPage={rowsPerPage}
              availableRowsPerPage={[3]}
              setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              onOrderByChange={(orderBy) => setOrderby(orderBy)}
              orderBy={orderBy}
              setOrderBy={(value) => setOrderby(value)}
              searchBy={searchBy}
              setSearchBy={(value) => setSearchBy(value)}
              idField={'addressId'}
              actions={{
                insert: {
                  icon: <AddBox />,
                  onClick: (id, data) => {
                    setSelectedSupplier(data);
                    handleChange?.(path, id);
                    handleChange?.('expensableType', data.type);
                    onSuccess?.(id, data);
                    handleClose();
                  },
                  title: 'Vincular',
                  allowedActions: [Address.actions.getAll],
                  selector: (row) => row.addressId !== selectedSupplier.id,
                },
              }}
              customCellRenderers={{
                fantasyName: {
                  renderer: (field, value) => {
                    return value ?? '-';
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Hidden>
  );
};

export default SelectAnySupplierDialogControl;
