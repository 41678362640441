import { combineReducers } from 'redux';
import { reducer as authReducer, saga as authSaga } from './auth';
import { reducer as filterReducer } from './filter';
import { withReduxStateSync } from 'redux-state-sync';

export const reducers = withReduxStateSync(
  combineReducers({
    authReducer,
    filterReducer
  })
);

export const sagas = [authSaga].flat();
