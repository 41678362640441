import styled from 'styled-components';
import {
  PriceChange as MaterialPriceChange,
  Restore as MaterialRestore,
  Close as MaterialClose,
  ClockHistory as MaterialClockHistory,
  Receipt as MaterialReceipt,
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const PriceChange = styled(MaterialPriceChange)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ClockHistory = styled(MaterialClockHistory)`
  width: 20px;
  height: 20px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Receipt = styled(MaterialReceipt)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
