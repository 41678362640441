import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectProcedureDialogControl from '../../components/selectProcedureDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectProcedureDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectProcedureDialog'
  )
);

const customSelectProcedureDialogControlTester = rankWith(
  4,
  isSelectProcedureDialogControl
);

export default {
  tester: customSelectProcedureDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectProcedureDialogControl),
};
