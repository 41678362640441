import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  reset: [],
  storeFilter: ['filterDto'],
});

const INITIAL_STATE = {
  filters: {}
};

const storeFilter = (state = INITIAL_STATE, { filterDto }) => ({
  ...state,
  filters: {
    ...state.filters,
    ...filterDto
  }
});

const reset = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET]: reset,
  [Types.STORE_FILTER]: storeFilter,
});
