import React, { useState, useCallback, useEffect } from 'react';
import DentistWorkedHour from '../../../../../../forms/dentistWorkedHour';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import {
  Container,
  CancelButton,
  Preview,
  TextBulletListSquareWarning,
} from './styles';
import moment from 'moment';
import Attendance from '../../../../../../forms/attendance';
import DisapprovedJusticationDialog from '../../../../../../components/disapprovedJustificationDialog';

function AllProceduresInScheduleList({ history, ...props }) {
  const workedhourId = props.match.params?.id ?? -1;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDisapproved, setOpenDisapproved] = useState(false);
  const [disapprovedJustification, setDisapprovedJustification] =
    useState(null);
  const columns = DentistWorkedHour.internalAttendancesTableSchema;

  const loadAttendances = useCallback(() => {
    restApiClient.dentistWorkedHourAttendance
      .getAllByWorkedHour(
        workedhourId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('attendance.id', searchBy)
              .or()
              .like('attendance.patient.name', searchBy)
              .or()
              .like('attendance.procedure.name', searchBy)
              .or()
              .like('attendance.unit.name', searchBy)
              .or()
              .like('attendance.status', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadAttendances();
  }, [loadAttendances]);

  return (
    <Container>
      <CrudTable
        title={'Procedimentos'}
        modelActions={DentistWorkedHour.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewAttendance: {
            icon: <Preview />,
            onClick: (id, row) => {
              history.push({
                pathname: `/dentist/audit/all-schedules/schedule/${workedhourId}/attendance/${row?.attendanceId}`,
                state: { readonly: true },
              });
            },
            title: 'Visualizar Procedimento',
            allowedActions: [Attendance.actions.findById],
          },
          viewRejection: {
            icon: <TextBulletListSquareWarning />,
            onClick: (orderServiceId, row) => {
              setOpenDisapproved(true);
              setDisapprovedJustification(
                row?.attendance.disapprovedJustification
              );
            },
            allowedActions: [
              DentistWorkedHour.dentistWorkedHourAttendanceActions
                .getAllByWorkedHour,
            ],
            title: 'Visualizar Justificativa da Reprovação',
            selector: (row) => row?.attendance.status === 'Reprovado',
          },
        }}
        customCellRenderers={{
          'attendance.attendanceDate': {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <DisapprovedJusticationDialog
        open={openDisapproved}
        disapprovedJustification={disapprovedJustification}
        onCancel={() => setOpenDisapproved(false)}
      />
    </Container>
  );
}

export default AllProceduresInScheduleList;
