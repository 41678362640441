import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Attendance from '../../../../../forms/attendance';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import {
  Container,
  BackButton,
  CancelButton,
  FooterContainer,
  SaveButton,
  SubmitButton,
} from './styles';
import SubmitDialog from '../../../../../components/submitDialog';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

function AttendanceCreateUpdate(props) {
  const userData = useSelector((state) => state.authReducer?.user);
  const dentistId = userData.isDentist ? userData.id : -1;
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const readonly = props.location?.state?.readonly ?? false;
  const type = props.location?.state?.type;
  const replacedDentistId = props.location?.state?.replacedDentistId ?? null;
  const unitId = props.location?.state?.dateUnitId ?? null;
  const requestingUnitBoolean = !unitId;
  const attendanceDate = props.location?.state?.attendanceDate ?? null;
  const [errorMessage, setErrorMessage] = useState('');
  const [previousDate, setPreviousDate] = useState(null);

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Attendance}
        schemaSelector={(modelSchema, modelId) =>
          modelId ? modelSchema.updateSchema : modelSchema.schema
        }
        restGetDataById={async (id) => {
          const result = await restApiClient.attendance.findById(id);
          result.data.recordNumber = result.data.patient.recordNumber;
          result.data.recordNumberUninformed =
            !result.data.patient.recordNumber;
          result.data.name = result.data.patient.name;
          result.data.showToothNumber = !!result.data.toothNumber;
          result.data.showDentalArch = !!result.data.dentalArch;
          if (attendanceDate) {
            setPreviousDate(result.data.attendanceDate);
            result.data.attendanceDate = attendanceDate;
          }
          return result;
        }}
        restCreateData={async (attendance) => {
          if (attendance.recordNumberUninformed) {
            delete attendance.patientId;
            delete attendance.recordNumber;
          }
          const result = await restApiClient.attendance.create(attendance);
          return result;
        }}
        restUpdateData={async (id, oldAttendance, attendance) => {
          attendance.recordNumber = !attendance.recordNumberUninformed
            ? attendance.recordNumber
            : null;

          if (
            oldAttendance?.patient.recordNumber !== attendance?.recordNumber
          ) {
            await restApiClient.patient
              .findByRecordNumber(attendance?.recordNumber, attendance.unitId)
              .then(async (patient) => {
                await restApiClient.patient.update(
                  patient.data.id,
                  { name: oldAttendance.patient.name },
                  { name: attendance.name }
                );
                attendance.patientId = patient.data.id;
              })
              .catch(async () => {
                //Se o numero do prontuário é diferente e nao encontrado,
                // necessário criar um novo paciente
                if (
                  attendance.recordNumberUninformed !==
                  oldAttendance.recordNumberUninformed
                ) {
                  const resultPatient = await restApiClient.patient.create({
                    unitId: attendance.unitId,
                    recordNumber: attendance.recordNumber,
                    name: attendance.name,
                  });
                  //Necessário atualizar id do novo paciente ao orderService
                  attendance.patientId = resultPatient.data.id;
                } else {
                  await restApiClient.patient.update(
                    attendance.patient.id,
                    { name: oldAttendance.patient.name },
                    { name: attendance.name }
                  );
                }
              });
          } else if (oldAttendance.name !== attendance.name) {
            //Se apenas o nome está diferente, é ncessário atualizar o nome do paciente
            await restApiClient.patient.update(
              attendance.patient.id,
              { name: oldAttendance.patient.name },
              { name: attendance.name }
            );
          }

          attendance.dentalArch = attendance.showDentalArch
            ? attendance.dentalArch
            : null;
          attendance.toothNumber = attendance.showToothNumber
            ? attendance.toothNumber
            : null;
          attendance.type = type;
          attendance.replacedDentistId = replacedDentistId;
          oldAttendance.attendanceDate = previousDate;
          attendance.attendanceDate = attendanceDate;

          delete attendance.recordNumber;
          delete oldAttendance.recordNumber;
          delete oldAttendance.patient;
          delete attendance.patient;
          delete oldAttendance.name;
          delete attendance.name;
          delete oldAttendance.recordNumberFound;
          delete attendance.recordNumberFound;
          delete oldAttendance.requestingUnitBoolean;
          delete attendance.requestingUnitBoolean;
          delete oldAttendance.phoneNumber;
          delete attendance.phoneNumber;
          delete oldAttendance.sex;
          delete attendance.sex;
          delete oldAttendance.birthDate;
          delete attendance.birthDate;
          delete oldAttendance.showDentalArch;
          delete attendance.showDentalArch;
          delete oldAttendance.showToothNumber;
          delete attendance.showToothNumber;
          delete oldAttendance.procedures;
          delete attendance.procedures;
          delete oldAttendance.readonly;
          delete attendance.readonly;
          delete oldAttendance.recordNumberUninformed;
          delete attendance.recordNumberUninformed;
          delete oldAttendance.patientLoaded;
          delete attendance.patientLoaded;

          const result = await restApiClient.attendance.update(
            id,
            oldAttendance,
            attendance
          );

          return result;
        }}
        onCreateSuccess={async (request, data) => {
          if (data === 'submit') {
            for await (const attendance of request.data) {
              await restApiClient.attendance.setAuditing(attendance.id);
            }
          }
          props.history.goBack();
        }}
        onUpdateSuccess={async (request, data) => {
          if (data === 'submit') {
            await restApiClient.attendance.setAuditing(request.data.id);
          }
          props.history.goBack();
        }}
        modelData={{
          requestingUnitBoolean: requestingUnitBoolean,
          recordNumberFound: false,
          readonly,
          procedures: [{}],
          dentistId: dentistId,
          unitId: unitId,
          type: type,
          replacedDentistId: replacedDentistId,
          attendanceDate: attendanceDate,
          recordNumberUninformed: false,
        }}
        onError={(errors) => {
          if (errors?.attendanceDate) {
            setErrorMessage(errors.attendanceDate[0]);
          }
        }}
        customButtonsBuilder={(submit) =>
          readonly ? (
            <>
              <FooterContainer>
                <BackButton
                  onClick={() => props.history.goBack()}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Voltar
                </BackButton>
              </FooterContainer>
            </>
          ) : (
            <>
              <CancelButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SaveButton
                type="button"
                variant="contained"
                color="primary"
                id="save"
                onClick={() => submit()}
              >
                {'Salvar'}
              </SaveButton>
              <SubmitButton
                type="button"
                variant="contained"
                color="primary"
                id="submit"
                onClick={() => {
                  setOpen(true);
                  setDialogText(
                    'Ao submeter o atendimento, o mesmo não poderá mais ser editado. Deseja continuar?'
                  );
                  setConfirmSubmit(() => () => submit('submit'));
                }}
              >
                {'Submeter'}
              </SubmitButton>
            </>
          )
        }
        {...props}
      />

      <SubmitDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          confirmSubmit();
        }}
        dialogText={dialogText}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AttendanceCreateUpdate;
