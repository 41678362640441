/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  ActionsContainer,
  SubmitButton,
  CancelButton,
  StyledMoneyInput,
  Card,
  FormDiv,
  Grid,
  CardHeader,
  HeaderContainer,
  TextField,
} from './styles';
import restApiClient from '../../services/restApiClient';

function AdjustUnitFinanceBalanceDialog({
  onSuccess,
  onError,
  onCancel,
  unitFinance,
  ...props
}) {
  const [newBalance, setNewBalance] = useState(0);
  const [justification, setJustification] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    restApiClient.unitFinance
      .adjustBalance(unitFinance?.id, {
        newBalance,
        justification,
        previousBalance: unitFinance?.balanceValue,
      })
      .then(() => {
        setNewBalance(0);
        setJustification('');
        onSuccess?.();
      })
      .catch((result) => {
        setErrors(result.response.data.errors);
      });
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        {...props}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Ajuste de Saldo" />
              </HeaderContainer>
              <FormDiv>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="bank-account"
                      type="text"
                      label="Conta Bancária"
                      InputLabelProps={{ shrink: true }}
                      value={
                        unitFinance?.holderName
                          ? unitFinance?.holderName
                          : unitFinance?.type === 'Caixa'
                          ? `${unitFinance?.type} Financeiro`
                          : unitFinance?.type === 'Cheque'
                          ? `${unitFinance?.type}`
                          : 'Não Encontrado'
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledMoneyInput
                      id="previous-balance"
                      label="Saldo Atual"
                      value={unitFinance?.balanceValue}
                      enabled={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledMoneyInput
                      id="new-balance"
                      label="Novo Saldo"
                      value={newBalance}
                      error={!isEmpty(errors.newBalance)}
                      helperText={
                        !isEmpty(errors.newBalance)
                          ? errors.newBalance.join(' ')
                          : ''
                      }
                      onChange={(e, value) => {
                        setErrors({});
                        setNewBalance(value);
                      }}
                      allowNegative
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="justification"
                      size="normal"
                      label="Justificativa"
                      value={justification}
                      multiline
                      inputProps={{ maxLength: 250 }}
                      onChange={(e) => {
                        setErrors({});
                        setJustification(e.target.value);
                      }}
                      error={!isEmpty(errors?.justification)}
                      helperText={
                        !isEmpty(errors?.justification)
                          ? errors?.justification.join(' ')
                          : ''
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </FormDiv>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setErrors({});
                    setNewBalance(0);
                    setJustification('');
                    onCancel?.();
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Confirmar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </Container>
  );
}

export default AdjustUnitFinanceBalanceDialog;
