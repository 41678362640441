import React, { useState, useEffect } from 'react';
import OrderServiceStep from '../../../../../../forms/orderServiceStep';
import CreateUpdateForm from '../../../../../../components/createUpdateForm';
import restApiClient from '../../../../../../services/restApiClient';
import { objectMap } from '../../../../../../extensions/object';
import { Container, AutoDiv } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';

function ClinicOrderServiceStepCreateUpdate({
  crrViewForm = null,
  crrEndProductId = null,
  crrOrderServiceId = null,
  crrWorkflowPosition = null,
  crrId = false,
  crrOrderServiceStep = null,
  crrPatientName = null,
  crrRecordNumber = null,
  ...props
}) {
  const [showGroupStep, setShowGroupStep] = useState(false);
  const [showDentalArch, setShowDentalArch] = useState(false);
  const [showToothNumber, setShowToothNumber] = useState(false);
  const [showToothShade, setShowToothShade] = useState(false);
  const [stepId, setStepId] = useState(-1);
  const endProductId = parseInt(
    crrEndProductId ?? props.match?.params?.endProductId ?? -1
  );
  const orderServiceId = parseInt(
    crrOrderServiceId ?? props.match?.params?.orderServiceId ?? -1
  );
  const workflowPosition = parseInt(
    crrWorkflowPosition ?? props.match?.params?.workflowPosition ?? -1
  );
  const dentistId = props.location.state?.dentistId ?? -1;
  let viewForm = crrViewForm ?? props.match?.params?.viewForm ?? -1;

  const date = props.location?.state?.date
    ? new Date(props.location?.state.date).toLocaleDateString('pt-br')
    : new Date().toLocaleDateString();

  const patientName = crrPatientName ?? props.match?.params?.patientName ?? '';
  const recordNumber = parseInt(
    crrRecordNumber ?? props.match?.params?.recordNumber ?? -1
  );

  if (viewForm === 'true' || viewForm === true) {
    viewForm = true;
  } else {
    viewForm = false;
  }

  useEffect(() => {
    if (endProductId) {
      restApiClient.endProduct.findById(endProductId).then((e) => {
        let firstStepFind = e.data.workflows.find(
          (x) => x.position === workflowPosition
        );
        let nextStepFind = e.data.workflows.find(
          (x) => x.position === workflowPosition + 1
        );

        if (firstStepFind) {
          if (nextStepFind) {
            setShowGroupStep(true);
          } else {
            setShowGroupStep(false);
          }
          let step = firstStepFind.step;
          setShowDentalArch(step.dentalArch);
          setShowToothNumber(step.toothNumber);
          setShowToothShade(step.toothColor);
          setStepId(step.id);
        }
      });
    }
  }, [endProductId, workflowPosition]);

  return stepId !== -1 ? (
    <Container>
      <CreateUpdateForm
        modelSchema={OrderServiceStep}
        forceId={crrId}
        restGetDataById={async (id) => {
          let result = await restApiClient.orderServiceStep.findById(id);
          result.data = objectMap(result.data, (key, value) =>
            value !== null ? value : undefined
          );
          return result;
        }}
        restUpdateData={async (id, oldOrderServiceStep, orderServiceStep) => {
          delete oldOrderServiceStep.dentistId;
          delete oldOrderServiceStep.date;
          delete oldOrderServiceStep.endProductId;
          delete oldOrderServiceStep.stepId;
          delete oldOrderServiceStep.enableFields;
          delete oldOrderServiceStep.showDentalArch;
          delete oldOrderServiceStep.showToothNumber;
          delete oldOrderServiceStep.showToothShade;
          delete oldOrderServiceStep.showSkipStep;
          delete oldOrderServiceStep.viewForm;
          delete oldOrderServiceStep.workflowPosition;
          delete oldOrderServiceStep.hideSkipStep;
          delete oldOrderServiceStep.showGroupStep;
          delete oldOrderServiceStep.groupStep;
          delete oldOrderServiceStep.status;
          delete oldOrderServiceStep.executorId;
          delete oldOrderServiceStep.patientName;
          delete oldOrderServiceStep.recordNumber;
          delete oldOrderServiceStep.requestingUnitBoolean;
          
          delete orderServiceStep.dentistId;
          delete orderServiceStep.date;
          delete orderServiceStep.endProductId;
          delete orderServiceStep.stepId;
          delete orderServiceStep.enableFields;
          delete orderServiceStep.showDentalArch;
          delete orderServiceStep.showToothNumber;
          delete orderServiceStep.showToothShade;
          delete orderServiceStep.showSkipStep;
          delete orderServiceStep.viewForm;
          delete orderServiceStep.workflowPosition;
          delete orderServiceStep.hideSkipStep;
          delete orderServiceStep.showGroupStep;
          delete orderServiceStep.groupStep;
          delete orderServiceStep.status;
          delete orderServiceStep.executorId;
          delete orderServiceStep.patientName;
          delete orderServiceStep.recordNumber;
          delete orderServiceStep.requestingUnitBoolean;
          
          if (!orderServiceStep.skipStep) {
            // se apagou o skip, deve ser atualizado a justificativa
            orderServiceStep.skipJustify = 'null';
          } else {
            orderServiceStep.dentalArch = 'null';
            orderServiceStep.toothShadeId = 'null';
            orderServiceStep.toothNumber = 'null';
          }

          const result = await restApiClient.orderServiceStep.update(
            id,
            oldOrderServiceStep,
            orderServiceStep
          );
          return result;
        }}
        modelData={{
          date: date,
          enableFields: false,
          showDentalArch: showDentalArch,
          showToothNumber: showToothNumber,
          showToothShade: showToothShade,
          stepId: stepId,
          showGroupStep: showGroupStep,
          hideSkipStep: true,
          skipStep: false,
          endProductId: parseInt(endProductId),
          dentistId: dentistId,
          orderServiceId: orderServiceId,
          viewForm: viewForm,
          workflowPosition: parseInt(workflowPosition),
          patientName: patientName,
          recordNumber: recordNumber,
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
          props.history.replace({
            pathname: `/clinic/lab/orderService/pending`,
            state: {
              updateSuccess: true,
            },
          });
        }}
        {...props}
      />
    </Container>
  ) : (
    <AutoDiv>
      <CircularProgress size={60} />
    </AutoDiv>
  );
}

export default ClinicOrderServiceStepCreateUpdate;
