import styled from 'styled-components';
import { AddBox as MaterialAddBox } from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
