/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Container,
  CancelButton,
  SubmitButton,
  DialogContent,
  Card,
  Dialog,
  DialogActions,
  ActionsContainer,
  HeaderContainer,
  CardHeader,
  SelectUnit,
} from './styles';
import TextField from '@material-ui/core/TextField';
import restApiClient from '../../services/restApiClient';
import isNull from 'lodash/isNull';

function SelectUnitCashRegisterDialog({
  onSuccess,
  onError,
  onCancel,
  employeeUnit = null,
  enabled = true,
  ...props
}) {
  const [unitId, setUnitId] = useState(null);
  const [numberCashRegister, setNumberCashRegister] = useState(null);

  useEffect(() => {
    if (employeeUnit) {
      setUnitId(employeeUnit);
    }

    if (unitId) {
      restApiClient.cashRegisterClose
        .getUnitLastCashRegister(unitId)
        .then((e) => {
          setNumberCashRegister((e.data?.numberCashRegister ?? 0) + 1);
        })
        .catch(() => setNumberCashRegister(1));
    }
  }, [unitId, employeeUnit]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isNull(numberCashRegister) && numberCashRegister > 0) {
                onSuccess?.({ unitId, numberCashRegister });
              }
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title={'Criar Fechamento de Caixa'} />
              </HeaderContainer>
              <SelectUnit
                size="small"
                visible
                enabled={enabled}
                required
                data={unitId}
                onSuccess={(id) => {
                  setUnitId(id);
                }}
              />
              {unitId ? (
                <TextField
                  id="outlined-number"
                  label="Número do Caixa"
                  type="number"
                  value={numberCashRegister}
                  InputProps={{
                    inputProps: { min: 1 },
                    pattern: '[0-9]*',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    let crrNumber = parseInt(e.target.value);
                    setNumberCashRegister(crrNumber);
                  }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+')
                      event.preventDefault();
                  }}
                  error={!isNull(numberCashRegister) && numberCashRegister < 1}
                  helperText={
                    !isNull(numberCashRegister) && numberCashRegister < 1
                      ? 'Número do Caixa deve ser maior que zero!'
                      : ''
                  }
                  required
                />
              ) : null}
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    onCancel?.();
                    setUnitId(null);
                    setNumberCashRegister(null);
                  }}
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Selecionar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default SelectUnitCashRegisterDialog;
