import { groupBy, isArray } from 'lodash';
import { useSelector } from 'react-redux';

export const useSecurity = (
  content,
  allowedActions = [],
  allowedRoles = [],
  allowDentist = false
) => {
  const user = useSelector((x) => x.authReducer.user);
  const roleCheck = (x) => user?.roles.includes(x);
  const actionCheck = (x) => user?.actions.includes(x);
  const groupedAllowedActions = groupBy(allowedActions, (x) =>
    isArray(x) ? 'requiredAll' : 'anyOf'
  );
  const groupedAllowedRoles = groupBy(allowedRoles, (x) =>
    isArray(x) ? 'requiredAll' : 'anyOf'
  );
  let allowedAnyOf =
    groupedAllowedRoles['anyOf']?.some(roleCheck) ||
    groupedAllowedActions['anyOf']?.some(actionCheck);
  let allowedRequiredAll =
    (!groupedAllowedRoles['requiredAll']?.length ||
      groupedAllowedRoles['requiredAll'].some((x) => x.every(roleCheck))) &&
    (!groupedAllowedActions['requiredAll']?.length ||
      groupedAllowedActions['requiredAll'].some((x) => x.every(actionCheck))) &&
    !(
      !groupedAllowedRoles['requiredAll']?.length &&
      !groupedAllowedActions['requiredAll']?.length
    );
  return allowedAnyOf ||
    allowedRequiredAll ||
    (allowDentist && user.isDentist) ||
    (!allowedRoles.length && !allowedActions.length && !allowDentist)
    ? content
    : null;
};

export const useSecurityCheck = (allowedActions = [], allowedRoles = []) =>
  useSecurity(true, allowedActions, allowedRoles) ?? false;
