import React, { useState, useEffect } from 'react';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, AssignmentTurnedIn } from './styles';
import Purchase from '../../../../forms/purchase';
import Entry from '../../../../forms/entry';
import moment from 'moment';

function UnregisteredItemPendingList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const columns = Purchase.tableSchema;

  useEffect(() => {
    restApiClient.purchase
      .getAllWithPendenciesUnregisteredItem(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('unit.name', searchBy)
              .or()
              .like('budget.name', searchBy)
              .or()
              .like('invoiceId', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Compras com pendências de cadastro de item'}
        modelActions={Purchase.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          manage: {
            icon: <AssignmentTurnedIn />,
            onClick: (purchaseId) => {
              history.push(`/unregistered-item/list/${purchaseId}`);
            },
            title: 'Gerenciar Itens',
            allowedActions: [
              Entry.actions.getAllUnregisteredItemPendingByPurchaseId,
            ],
          },
        }}
        customCellRenderers={{
          createdAt: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
          invoiceId: {
            renderer: (field, value) => {
              if (value) {
                return value.substring(0, 15) + '...';
              }
            },
          },
        }}
      />
    </Container>
  );
}

export default UnregisteredItemPendingList;
