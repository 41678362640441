import styled from 'styled-components';
import {
  Grid as MaterialGrid,
  Table as MaterialTable,
  TableBody as MaterialTableBody,
  TableCell as MaterialTableCell,
  TableRow as MaterialTableRow,
} from '@material-ui/core';

export const TextTitle = styled.h3`
  font-family: Courier New, sans-serif !important;
`;

export const TextSubtitle = styled.h4`
  font-family: Courier New, sans-serif !important;
  font-weight: normal;
  padding-bottom: 10px;
`;

export const TextStyledH5 = styled.h5`
  font-family: Courier New, sans-serif !important;
  font-weight: normal;
`;

export const BoldTextStyledH5 = styled.h5`
  font-family: Courier New, sans-serif !important;
`;

export const Grid = styled(MaterialGrid)``;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DottedLine = styled.div`
  border-style: none none dotted;
  border-bottom: 1px dotted #696969;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: lighter;
`;

export const Table = styled(MaterialTable)``;

export const TableBody = styled(MaterialTableBody)``;

export const TableRow = styled(MaterialTableRow)``;

export const TableCell = styled(MaterialTableCell)`
  font-size: 16px !important;
  padding: 1px !important;
  color: black !important;
`;
