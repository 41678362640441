import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  background: linear-gradient(
    140.23deg,
    var(--primary) 19.68%,
    var(--secondary) 94.9%
  );
`;

export const LoginCard = styled.div`
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 70%;
  }
`;

export const Field = styled.div`
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
`;

export const Input = styled(TextField)`
  width: 100%;
  color: white;
  box-shadow: 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 100px 80px rgba(0, 0, 0, 0.07);
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  color: #ffffff !important;
`;

export const Logo = styled.img`
  height: 60px;
  margin-bottom: 20px;
`;

export const Form = styled.form``;
