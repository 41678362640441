import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportLabCostUnits = {
  buildLabCostUnitsPdf: (from, to, units, sections, costUnits) => {
    const doc = new jsPDF();
    let y = 0;
    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(15, (y += 15),  `Relatório de Custo por Unidades`);

    doc.setFontSize(12);
    doc.text(15, (y += 8), `Período: ${from} a ${to}`);
    let now = new Date
    doc.text(15, (y += 5), 'Emissão: ' + now.toLocaleDateString() + ' às ' + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds());
    //CORPO DO PDF
    Object.keys(costUnits).forEach(function(unit) {
      if (Math.ceil(y) >= 260){
        doc.addPage();
        y = 10;
      }
      if(unit !== 'totalSection'){
        // PARA CADA UNIDADE
        const unitSelected = units.values.find(
          (x) => x.addressId === parseInt(unit)
        );
        y += 8;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        doc.text(15, (y += 4), `Unidade: ${unitSelected?.name}`);
        y += 2;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(10);
        let bodyValue = [];
        Object.keys(costUnits[unit].sections).forEach(function(value) {
          const sectionValue = costUnits[unit].sections[value];
          const sectionSelected = sections.find(
            (x) => x.id === sectionValue.sectionId
          );
          bodyValue.push([
            sectionSelected.name,
            { content: parseFloat(sectionValue.value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }), styles: { halign: 'left'} },
            { content: parseFloat(sectionValue.extraCosts ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }), styles: { halign: 'left'} },
          ]);
        });
        doc.autoTable({
          startY: (y += 1),
          head: [[
            'Seção',
            {content: 'Serviços',  styles: { halign: 'left'}},
            {content: 'Custos Extras',  styles: { halign: 'left'}},
          ]],
          body: bodyValue,
          theme: 'plain',
          tableWidth: 'wrap',
          styles: { fontSize: 9 },
        });
        y = doc.lastAutoTable.finalY;

        y += 2;
        doc.setLineWidth(0.8);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        let totalUnit = parseFloat(costUnits[unit].totalUnit ?? 0) + parseFloat(costUnits[unit].totalAdjustUnit ?? 0);
        doc.text(15, (y += 5), `Total: ${parseFloat(totalUnit).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`);
      } else {
        //Total geral
        y += 10;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        doc.text(15, (y += 4), `Total por Grupo:`);
        y += 2;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(10);
        let bodyTotalValue = [];
        let showRework = false;
        Object.keys(costUnits[unit].sections).forEach(function(value) {
          const sectionTotalValue = costUnits[unit].sections[value];
          const sectionTotalSelected = sections.find(
            (x) => x.id === sectionTotalValue.sectionId
          );
          if(sectionTotalValue.rework > 0){
            showRework = true;
          }
          bodyTotalValue.push([
            sectionTotalSelected.name,
            { content:  parseFloat(sectionTotalValue.value ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }), styles: { halign: 'left'} },
            { content:  parseFloat(sectionTotalValue.extraCosts ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }), styles: { halign: 'left'} },
            { content:  parseFloat(sectionTotalValue.rework ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }), styles: { halign: 'left'} },
          ]);
        });
        let editHead = [];
        if(showRework){
          editHead = [
            'Seção',
            {content: 'Serviços',  styles: { halign: 'left'}},
            {content: 'Custos Extras',  styles: { halign: 'left'}},
            {content: 'Retrabalho',  styles: { halign: 'left'}},
          ];
        } else {
          editHead = [
            'Seção',
            {content: 'Serviços',  styles: { halign: 'left'}},
            {content: 'Custos Extras',  styles: { halign: 'left'}},
          ];
        }
        doc.autoTable({
          startY: (y += 1),
          head: [editHead],
          body: bodyTotalValue,
          theme: 'plain',
          tableWidth: 'wrap',
          styles: { fontSize: 9 },
        });
        y = doc.lastAutoTable.finalY;

        y += 2;
        doc.setLineWidth(0.8);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        let totalGlobal = parseFloat(costUnits[unit].totalGlobal ?? 0) + parseFloat(costUnits[unit].totalAdjustGlobal ?? 0);
        doc.text(15, (y += 4), `Total: ${parseFloat(totalGlobal).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`);
      }
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for(var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 290, String(i) + '/' + String(pageCount));
    }


    return doc;
  },
};

export default ReportLabCostUnits;
