/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  Dialog,
  Container,
  DialogTitle,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  Typography,
} from './styles';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

function ProfitMarginConfirmationDialog({ onSuccess, onCancel, ...props }) {
  const [checked, setChecked] = React.useState(false);
  
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h6">Atenção</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={(e) => {
                setChecked(e.target.checked);
              }} name="checked" />}
              label="Substituir todas as margens de lucro individual"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                onCancel?.();
                setChecked(false);
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <SubmitButton
              onClick={() => {
                onSuccess?.(checked);
                setChecked(false);
              }}
              variant="contained"
              color="primary"
            >
              Salvar
            </SubmitButton>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ProfitMarginConfirmationDialog;
