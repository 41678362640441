import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const OrderServiceHistory = {
  buildOrderServiceHistoryPdf: (orderService) => {
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;
    
    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(10);
    doc.text('Emissão: ' + now.toLocaleDateString() + ' às ' + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds(), 160, (y += 5));
    doc.setFontSize(16);
    doc.text(`Histórico da OS`, (doc.internal.pageSize.getWidth() / 2), (y += 10), { align: 'center'});

    //CORPO DO PDF
    y += 5;
    doc.setLineWidth(0.3);
    doc.setDrawColor(0, 0, 0);
    doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
    y += 1;
    doc.setFontSize(11);
    doc.text(`Ordem de Serviço: ${orderService?.id}`, 5, (y += 4));
    y += 0.5;
    doc.text(`Produto Final: ${orderService?.endProduct.name}`, 5, (y += 4));
    y += 0.5;
    doc.text(`Paciente: ${orderService?.patient.recordNumber} (${orderService?.patient.name})`, 5, (y += 4));
    y += 2;
    doc.setFontSize(10);
    let bodyValue = [];
    orderService.orderServiceSteps.forEach(function(orderServiceStep) {
      // PARA CADA ORDER SERVICE STEP
      let date = new Date(orderServiceStep.updatedAt);
      let takeCost = '';

      switch(orderServiceStep?.takeCost) {
        case 'laboratory':
          takeCost = 'Laboratório';
          break;
        case 'dentist':
          takeCost = 'Dentista';
          break;
        default:
          break;
      }
      bodyValue.push([
        orderServiceStep?.id ?? '-',
        orderServiceStep?.dentist?.user.name ?? '-',
        orderServiceStep?.unit?.name ?? '-',
        orderServiceStep?.step?.name ?? '-',
        orderServiceStep?.toothNumber ?
          orderServiceStep?.toothNumber.split(',').join(', ') : '-',
        orderServiceStep?.status ?? '-',
        date.toLocaleDateString(),
        orderServiceStep?.dentistRejectedJustification ?? '-',
      ]);
    });
    doc.autoTable({
      startY: (y += 1),
      headStyles: { fillColor: [0, 0, 0] },
      head: [['Id', 'Dentista', 'Unidade', 'Etapa', 'Dentes', 'Situação', 'Data', 'Justificativa Dentista']],
      body: bodyValue,
      theme: 'grid',
      styles: {
        halign: 'center',
        valign: 'middle',
        fontSize: 8,
      },
      columnStyles: {
        4: { cellWidth: 18 },
      },
      margin: 5,
    });

    y = doc.lastAutoTable.finalY;
    
    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for(let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 295, String(i) + '/' + String(pageCount));
    }
    return doc;
  },
};

export default OrderServiceHistory;
