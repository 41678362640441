import styled from 'styled-components';
import {
  DialogContent as MaterialDialogContent,
  FormGroup as MaterialFormGroup,
  TextField as MaterialTextField,
  Checkbox as MaterialCheckbox,
  FormControlLabel as MaterialFormControlLabel,
  DialogTitle as MaterialDialogTitle,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  Button as MaterialButton,
  CardHeader as MaterialCardHeader, Card as MaterialCard,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';

export const Dialog = styled(MaterialDialog)``;

export const Container = styled.div``;

export const DialogContent = styled(MaterialDialogContent)`
  width: 400px !important;
`;

export const FormGroup = styled(MaterialFormGroup)``;

export const Checkbox = styled(MaterialCheckbox)``;

export const FormControlLabel = styled(MaterialFormControlLabel)`
  margin-top: 10px;
  padding: 0 !important;
`;

export const MuiPickersUtilsProvider = styled(MaterialMuiPickersUtilsProvider)``;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  width: 100% !important;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export const InputTextarea = styled(MaterialTextField)`
  width: 100%;
  color: white;
`;

export const FormDiv = styled.div`
  margin: 0px 15px 15px 15px;
`;

export const DialogActions = styled(MaterialDialogActions)``;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CardHeader = styled(MaterialCardHeader)`
`;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
