import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import forEach from 'lodash/forEach';

const ReportStockConsolidatedComsumption = {
  buildPdf: (date, stockComsumption) => {
    const doc = new jsPDF();
    let y = 0;
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(
      15,
      (y += 15),
      `Relatório de Consumo Consolidado do Estoque por Unidade`
    );

    doc.setFontSize(12);
    doc.text(15, (y += 8), `Período: ${date?.format('MM/YYYY')}`);
    let now = new Date();
    doc.text(
      15,
      (y += 5),
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds()
    );
    //CORPO DO PDF
    forEach(stockComsumption, function (unit, key) {
      if (Math.ceil(y) >= 260) {
        doc.addPage();
        y = 10;
      }
      // PARA CADA UNIDADE
      let unitTitle =
        key !== 'global' ? `Unidade: ${unit?.name}` : `Total por Grupo:`;
      y += 8;
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, y, 200, y);
      doc.setFontSize(12);
      doc.text(15, (y += 4), unitTitle);
      y += 2;
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, y, 200, y);
      doc.setFontSize(10);
      let bodyValue = [];
      forEach(unit.groups, function (value) {
        bodyValue.push([
          value?.groupName,
          {
            content: parseFloat(value.transfer ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: { halign: 'right' },
          },
          {
            content: parseFloat(value.adjustment ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: { halign: 'right' },
          },
          {
            content: parseFloat(value.loss ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: { halign: 'right' },
          },
          {
            content: parseFloat(value.correction ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: { halign: 'right' },
          },
          {
            content: parseFloat(value.subtotal ?? 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }),
            styles: { halign: 'right' },
          },
          {
            content: `${value.profitMargin ?? 0}%`,
            styles: { halign: 'center' },
          },
          {
            content: parseFloat(value.totalProfit ?? 0).toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              }
            ),
            styles: { halign: 'right' },
          },
        ]);
      });

      let columns;
      if (key !== 'global') {
        columns = [
          'Grupo',
          'Transferência',
          { content: 'Ajustes', styles: { halign: 'right' } },
          { content: 'Perda', styles: { halign: 'right' } },
          { content: 'Correção', styles: { halign: 'right' } },
          { content: 'Subtotal', styles: { halign: 'right' } },
          { content: 'Margem de Lucro', styles: { halign: 'right' } },
          { content: 'Subtotal + Lucro', styles: { halign: 'right' } },
        ];
      } else {
        columns = [
          'Grupo',
          'Transferência',
          { content: 'Ajustes', styles: { halign: 'right' } },
          { content: 'Perda', styles: { halign: 'right' } },
          { content: 'Correção', styles: { halign: 'right' } },
        ];
      }
      doc.autoTable({
        startY: (y += 1),
        head: [columns],
        body: bodyValue,
        theme: 'plain',
        tableWidth: 'wrap',
        styles: { fontSize: 9 },
      });
      y = doc.lastAutoTable.finalY;

      y += 2;
      doc.setLineWidth(0.8);
      doc.setDrawColor(0, 0, 0);
      doc.line(10, y, 200, y);
      doc.setFontSize(12);
      doc.text(
        15,
        (y += 5),
        `Total: ${parseFloat(unit?.total ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`
      );
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 290, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default ReportStockConsolidatedComsumption;
