import styled from 'styled-components';
import {
  AddBox as AddBoxMaterial,
  Edit as EditBoxMaterial,
  Delete as MaterialDelete,
  MoneyCalculator as MaterialMoneyCalculator,
  ReceiptAdd as MaterialReceiptAdd,
  Statement as MaterialStatement,
  PackageIcon as MaterialPackageIcon,
  LabFlask as MaterialLabFlask,
  XRay as MaterialXRay,
} from '../../../../../styles/icons';
import TreeView from '../../../../../components/treeView';
import {
  Paper as PaperMaterial,
  IconButton as MaterialIconButton,
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectContainer = styled.div`
  width: 650px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  h1 {
    width: 100%;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 22px;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 98%;
  margin-bottom: 10px;
`;

export const CustomTreeView = styled(TreeView)`
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Paper = styled(PaperMaterial)`
  margin-top: 15px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconButton = styled(MaterialIconButton)``;

export const AddBox = styled(AddBoxMaterial)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ReceiptAdd = styled(MaterialReceiptAdd)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Statement = styled(MaterialStatement)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const MoneyCalculator = styled(MaterialMoneyCalculator)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PackageIcon = styled(MaterialPackageIcon)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const LabFlask = styled(MaterialLabFlask)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const XRay = styled(MaterialXRay)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const EditBox = styled(EditBoxMaterial)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Delete = styled(MaterialDelete)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;
export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;
export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;
export const MenuItem = styled(MaterialMenuItem)``;

export const MuiPickersUtilsProvider = styled(MaterialMuiPickersUtilsProvider)`
  width: 300px;
`;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  margin: 0px !important;
  padding-bottom: 5px;
`;
