/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Hidden } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import merge from 'lodash/merge';
import dayjsUtils from '@date-io/dayjs';
import moment from 'moment';
import restApiClient from '../../services/restApiClient';

function CustomAttendanceDateControl({
  id,
  handleChange,
  onSuccess,
  description,
  errors,
  label,
  schema,
  uischema,
  enabled,
  visible = true,
  required,
  config,
  input,
  inputProps,
  onChange,
  path,
  data,
  cancelLabel,
  clearLabel,
  okLabel,
  forceUnitId,
  ...props
}) {
  let crrDate = new Date();
  const [minDate, setMinDate] = useState(new Date(crrDate.setDate(crrDate.getDate() - 1)));
  const isValid = !errors || errors?.length === 0;
  const formHelperText = !isValid ? errors : null;
  const appliedUiSchemaOptions = merge({}, config, uischema?.options);
  const format = appliedUiSchemaOptions?.dateFormat ?? 'DD/MM/YYYY';
  const [selectedDate, setSelectedDate] = useState(
    new Date().getDay() !== 0
      ? moment(new Date()).format('YYYY-MM-DD')
      : moment(minDate).format('YYYY-MM-DD')
  );
  const [openCalendar, setOpenCalendar] = useState(false);
  const [holidays, setHolidays] = useState(false);

  const handleClickOpen = () => {
    if (enabled) {
      setOpenCalendar(true);
    }
  };
  
  useEffect(() => {
    if(config?.completeData?.unitId || forceUnitId) {
      let crrUnitId = forceUnitId ?? config?.completeData?.unitId;
      restApiClient.holiday
      .getPastDayAvaliable(crrUnitId)
      .then((e) => {
        crrDate = new Date();
        setMinDate(new Date(crrDate.setDate(crrDate.getDate() - e.data.daysToSubtract)));
      });
    }
  }, [config?.completeData?.unitId, forceUnitId]);

  useEffect(() => {
    if(config?.completeData?.unitId || forceUnitId) {
      let crrUnitId = forceUnitId ?? config?.completeData?.unitId;
      restApiClient.holiday
      .getAllByCityAndYear(crrUnitId)
      .then((e) => {
        setHolidays(e.data.values);
      });
    }
  }, [config?.completeData?.unitId, forceUnitId]);

  useEffect(() => {
    if (!data) {
      handleChange?.(path, selectedDate);
      onSuccess?.(selectedDate);
    } else {
      onSuccess?.(data);
    }
  }, []);

  function disableSundaysAndHolidays(dayJsDate) {
    const date = dayJsDate.toDate();
    let crrHolidays = holidays.map((holiday) => { 
      const crrDateHoliday = holiday.date.split('-');
      return new Date(crrDateHoliday[0], (crrDateHoliday[1] - 1), crrDateHoliday[2], 0, 0, 0, 0).getTime();
    }).includes(date.getTime());
    return  crrHolidays || date.getDay() === 0;
  }

  return (
    <Hidden xsUp={!visible}>
      <MuiPickersUtilsProvider utils={dayjsUtils}>
        <KeyboardDatePicker
          id={id + '-input'}
          required={required}
          label={label}
          error={!isValid}
          fullWidth={!appliedUiSchemaOptions.trim}
          InputLabelProps={selectedDate ? { shrink: true } : undefined}
          value={data ?? selectedDate}
          open={openCalendar}
          onClick={handleClickOpen}
          onAdornmentClick={handleClickOpen}
          onClose={() => setOpenCalendar(false)}
          onChange={(value) => {
            const date = moment(value.toDate()).format('YYYY-MM-DD');
            setSelectedDate(date);
            handleChange?.(path, date);
            onChange?.(value, date);
            onSuccess?.(date);
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          format={format}
          views={appliedUiSchemaOptions.views}
          disabled={!enabled}
          cancelLabel={cancelLabel ?? appliedUiSchemaOptions.cancelLabel}
          clearLabel={clearLabel ?? appliedUiSchemaOptions.clearLabel}
          okLabel={okLabel ?? appliedUiSchemaOptions.okLabel}
          shouldDisableDate={disableSundaysAndHolidays}
          minDate={minDate}
          disableFuture
          helperText={!isValid ? formHelperText : null}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </Hidden>
  );
}

export default CustomAttendanceDateControl;
