import React, { useRef, useState } from 'react';
import RadiologySchedule from '../../../../forms/radiologySchedule';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { BackButton, Container, FooterContainer, PrintOS } from './styles';
import { first } from 'lodash';
import { useReactToPrint } from 'react-to-print';
import VoucherRadiology from '../../../../components/voucherRadiology';
import { useSelector } from 'react-redux';

function RadiologyScheduleCreateUpdate(props) {
  const userData = useSelector((state) => state.authReducer?.user);
  const update = props.match?.params?.update
    ? props.match?.params?.update === 'true'
    : false;
  const isRadiology = props.location?.state?.isRadiology ?? false;
  const viewForm = props.location?.state?.viewForm ?? false;
  const scheduling = props.location?.state?.scheduling ?? null;
  const treatmentId = props.location?.state?.treatmentId ?? null;
  const uischemaAlternative =
    props.location?.state?.uischemaAlternative ?? false;
  const reschedule = props.location?.state?.reschedule ?? false;
  const [radiologySchedule, setRadiologySchedule] = useState(null);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Encaminhamento`,
    content: () => {
      return componentRef.current;
    },
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }
      @media print { 
        grid {
          page-break-inside: avoid;
        }
      }`,
  });

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={RadiologySchedule}
        uischemaSelector={(modelSchema, modelId) => {
          return uischemaAlternative
            ? modelSchema.uischemaAlternative
            : modelId
            ? modelSchema.updateUischema
            : modelSchema.uischema;
        }}
        restGetDataById={async (id) => {
          const result = await restApiClient.radiologySchedule.findById(id);
          result.data.recordNumber = result.data.patient.recordNumber;
          result.data.name = result.data.patient.name;
          result.data.birthDate = result.data.patient.birthDate;
          result.data.sex = result.data.patient.sex;
          result.data.phoneNumber = result.data.patient.phoneNumber;
          result.data.scheduling = new Date(
            result.data.scheduling
          ).toISOString();
          result.data.requestingUnit = result.data.requestingUnit.addressId;
          result.data.recordNumberUninformed =
            !result.data.patient.recordNumber;
          result.data.fit = isRadiology ? result.data.fit : false;
          return result;
        }}
        restCreateData={async (radiologySchedule) => {
          let executorUnit = null;
          await restApiClient.unit.getAllByType('radiology').then((e) => {
            executorUnit = first(e.data.values).id;
          });

          if (radiologySchedule.recordNumberUninformed) {
            delete radiologySchedule.patientId;
            delete radiologySchedule.recordNumber;
          }

          return await restApiClient.radiologySchedule.create({
            executorUnit,
            ...radiologySchedule,
          });
        }}
        restUpdateData={async (id, oldRadiologySchedule, radiologySchedule) => {
          delete oldRadiologySchedule.update;
          delete radiologySchedule.update;
          delete oldRadiologySchedule.isRadiology;
          delete radiologySchedule.isRadiology;
          delete oldRadiologySchedule.reschedule;
          delete radiologySchedule.reschedule;
          delete oldRadiologySchedule.requestingUnitBoolean;
          delete radiologySchedule.requestingUnitBoolean;
          delete oldRadiologySchedule.userUnit;
          delete radiologySchedule.userUnit;
          delete oldRadiologySchedule.unitId;
          delete radiologySchedule.unitId;
          delete radiologySchedule.patientLoaded;

          if (isRadiology)
            radiologySchedule.fitJustification = radiologySchedule.fit
              ? radiologySchedule.fitJustification
              : null;

          if (reschedule) {
            return await restApiClient.radiologySchedule.rescheduleRadiology(
              id,
              oldRadiologySchedule,
              radiologySchedule
            );
          } else {
            return await restApiClient.radiologySchedule.update(
              id,
              oldRadiologySchedule,
              radiologySchedule
            );
          }
        }}
        modelData={{
          update: update,
          isRadiology: isRadiology,
          fit: false,
          recordNumberUninformed: false,
          requestingUnitBoolean: true,
          userUnit: userData.unit.addressId,
          scheduling: scheduling,
          treatmentId: treatmentId,
          reschedule: reschedule,
        }}
        config={{
          unitType: 'radiology',
        }}
        onCreateSuccess={async (request, data) => {
          props.history.goBack();
          await setRadiologySchedule(request.data);
          handlePrint();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        customButtonsBuilder={
          viewForm
            ? () => (
                <>
                  <FooterContainer>
                    <BackButton
                      onClick={() => props.history.goBack()}
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      Voltar
                    </BackButton>
                  </FooterContainer>
                </>
              )
            : null
        }
        {...props}
      />

      <PrintOS ref={componentRef}>
        <VoucherRadiology crrRadiologySchedule={radiologySchedule} />
      </PrintOS>
    </Container>
  );
}

export default RadiologyScheduleCreateUpdate;
