import React, { useState, useEffect, useCallback } from 'react';
import ItemizedCheck from '../../../../../forms/itemizedCheck';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  CancelButton,
  Container,
  SendClock,
  HeaderContainer,
  AutoDiv,
  StyledTypography,
  AssignmentReturn,
  ViewShow,
} from './styles';
import CreateItemizedCheckDialog from '../../../../../components/createItemizedCheckDialog';
import SubmitObservationDialog from '../../../../../components/submitObservationDialog';

function ItemizedCheckList({ history, match, location }) {
  const cashRegisterValueId = match?.params?.cashRegisterValueId ?? -1;
  const cashRegisterValue = location?.state?.cashRegisterValue;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [], valueSum: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [itemizedCheckId, setItemizedCheckId] = useState(null);
  const [viewObservation, setViewObservation] = useState(false);

  const columns = ItemizedCheck.tableSchema;

  const loadCashRegisterValues = useCallback(() => {
    if (cashRegisterValueId) {
      restApiClient.itemizedCheck
        .getAllByCashRegisterValueId(
          cashRegisterValueId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('number', searchBy)
                .or()
                .like('status', searchBy)
                .or()
                .like('name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadCashRegisterValues();
  }, [loadCashRegisterValues]);

  return (
    <Container>
      <CrudTable
        title={`Lançamento de Cheques - Caixa ${cashRegisterValue?.cashRegisterClose.numberCashRegister}`}
        customHeaderComponent={
          <HeaderContainer>
            <AutoDiv>
              <StyledTypography variant="h6">
                {`Lançado: ${rows?.valueSum?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })} de ${cashRegisterValue?.value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}
              </StyledTypography>
            </AutoDiv>
          </HeaderContainer>
        }
        modelActions={ItemizedCheck.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpenDialog(true);
            },
            title: 'Lançar entrada de cheque',
          },
          setCompensation: {
            icon: <SendClock />,
            onClick: (id) => {
              restApiClient.itemizedCheck
                .setCompensation(id)
                .then(() => loadCashRegisterValues());
            },
            allowedActions: [ItemizedCheck.actions.setCompensation],
            title: 'Enviar para Depósito',
            selector: (row) => row.status === 'Pendente',
          },
          setReturned: {
            icon: <AssignmentReturn />,
            onClick: (id) => {
              setItemizedCheckId(id);
              setOpenSubmit(true);
            },
            allowedActions: [ItemizedCheck.actions.setReturned],
            title: 'Devolução',
            selector: (row) => row.status === 'Em Compensação',
          },
          view: {
            icon: <ViewShow />,
            onClick: (id) => {
              setItemizedCheckId(id);
              setOpenSubmit(true);
              setViewObservation(true);
            },
            allowedActions: [ItemizedCheck.actions.setReturned],
            title: 'Visualizar Observação',
            selector: (row) => row.status === 'Devolvido',
          },
          delete: {
            onClick: (id) => {
              restApiClient.itemizedCheck.delete(id).then(() => {
                loadCashRegisterValues();
              });
            },
            allowedActions: [ItemizedCheck.actions.delete],
            selector: (row) => row?.status === 'Pendente',
          },
        }}
        customCellRenderers={{
          'cashRegisterClose.date': {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <CreateItemizedCheckDialog
        open={openDialog}
        onCancel={() => {
          setOpenDialog(false);
        }}
        onSuccess={(data) => {
          setRows({
            ...rows,
            values: [...rows.values, { ...data, status: 'Pendente' }],
            valueSum: rows.valueSum + data.value,
          });

          if (rows.valueSum + data.value === cashRegisterValue.value) {
            setOpenDialog(false);
          }
        }}
        cashRegisterValueId={parseInt(cashRegisterValueId)}
      />

      <SubmitObservationDialog
        open={openSubmit}
        onCancel={() => {
          setOpenSubmit(false);
          setItemizedCheckId(null);
          setViewObservation(false);
        }}
        onSuccess={(observation) => {
          restApiClient.itemizedCheck
            .setReturned(itemizedCheckId, {
              observation: observation,
              updateStatus: !viewObservation,
            })
            .then(() => loadCashRegisterValues());

          setOpenSubmit(false);
          setItemizedCheckId(null);
          setViewObservation(false);
        }}
        dialogTitle={'Observação'}
        itemizedCheckId={itemizedCheckId}
        viewObservation={viewObservation}
      />
    </Container>
  );
}

export default ItemizedCheckList;
