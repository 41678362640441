import React, { useState, useEffect, useCallback } from 'react';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, StyledMoneyInput, Button, SaveIcon } from './styles';
import Item from '../../../forms/item';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function UpdatePricesList() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [itemList, setItemList] = useState([]);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const columns = Item.updatePricesTableSchema;

  const loadItems = useCallback(() => {
    restApiClient.item
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftOp, rightOp) => {
                leftOp.isNull('deletedAt');
                rightOp
                  .like('id', searchBy)
                  .or()
                  .like('name', searchBy)
                  .or()
                  .like('brand', searchBy)
                  .or()
                  .like('price', searchBy)
                  .or()
                  .like('group.name', searchBy)
                  .or()
                  .like('group.parent.name', searchBy)
                  .or()
                  .like('group.parent.parent.name', searchBy);
              })
              .toString()
          : new FilterTree().isNull('deletedAt').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const salvar = async () => {
    const itemPriceList = itemList.filter((item) => item.price > 0);
    const result = await restApiClient.item.updatePrices(itemPriceList);

    if (result.status === 200) {
      setAlert({
        message: 'Os valores dos produtos foram alterados com sucesso!',
        severity: 'success',
      });
      setItemList([]);
      loadItems();
    }
  };

  return (
    <Container>
      <CrudTable
        title={'Atualizar preços'}
        modelActions={Item.actions}
        columns={columns}
        customColumns={[
          {
            id: 'newPrice',
            label: 'Novo Preço',
            minWidth: 50,
            align: 'center',
            allowedActions: [Item.actions.updatePrice],
          },
        ]}
        customFooterComponent={
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={salvar}
            >
              Salvar
            </Button>
          </>
        }
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{}}
        customCellRenderers={{
          price: {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          newPrice: {
            renderer: (field, value, column, row) => {
              row.newPrice = parseFloat(row.newPrice);
              let itemFind = itemList.find((x) => x.itemId === row.id);
              let newPrice = 0;
              if (itemFind) newPrice = itemFind?.price;
              else newPrice = value;
              return (
                <StyledMoneyInput
                  value={newPrice}
                  onChange={(e, value) => {
                    let item = null;
                    const newList = [...itemList];
                    if ((item = newList.find((x) => x.itemId === row.id))) {
                      item.price = value;
                    } else {
                      const item = {
                        itemId: row.id,
                        price: value,
                      };
                      newList.push(item);
                    }
                    row.newPrice = value;
                    setItemList(newList);
                  }}
                />
              );
            },
          },
          group: {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value.name;
              }
            },
          },
        }}
      />

      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default UpdatePricesList;
