const Patient = {
  schema: {
    type: 'object',
    properties: {
      recordNumber: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
      birthDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      sex: {
        oneOf: [
          {
            const: 'MASCULINO',
            title: 'MASCULINO',
          },
          {
            const: 'FEMININO',
            title: 'FEMININO',
          },
          {
            const: null,
            title: '',
          },
        ],
        customErrors: '',
      },
      phoneNumber: {
        type: 'string',
        mask: '(99) 9999-9999',
        maskSelector: (value) => {
          const len = value.replace(/[\W_]/g, '').length;
          if (len > 10) return '(99) 99999-9999';
          else return '(99) 9999-9999';
        },
        customErrors: '',
        phone: '',
      },
    },
    required: ['name', 'unitId'],
  },

  uischema: {
    type: 'Group',
    label: 'Atualização de Paciente',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Prontuário',
            scope: '#/properties/recordNumber',
          },
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data de Nascimento',
            scope: '#/properties/birthDate',
          },
          {
            type: 'Control',
            label: 'Sexo',
            scope: '#/properties/sex',
          },
          {
            type: 'Control',
            label: 'Telefone',
            scope: '#/properties/phoneNumber',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'recordNumber', label: 'Prontuário', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
  ],
  actions: {
    findByRecordNumber: 'PatientController@findByRecordNumber',
    create: 'PatientController@create',
    update: 'PatientController@patchUpdate',
    findById: 'PatientController@findById',
    getAll: 'PatientController@getAll',
  },
};

export default Patient;
