import Menu from '../menu';
import { Logo } from './styles';
import React, { useState } from 'react';
import { resetGlobal } from 'reactn';
import LogoutDialog from '../logoutDialog';
import AppBar from '@material-ui/core/AppBar';
import AppRoutes from '../../routes/app.routes';
import AuthRoutes from '../../routes/auth.routes';
import { Creators } from '../../store/ducks/auth';
import { ThemeProvider } from '@material-ui/styles';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PasswordChange from '../../pages/passwordChange';
import LogoImg from '../../assets/img/logo.png';
import {
  Container,
  Theme,
  IconButton,
  LogOut,
  Toolbar,
  PageContainer,
  ContentContainer,
  DivRow,
  DivColumn,
} from './styles';

const Layout = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.authReducer.user);
  const passwordChangeRequired = useSelector(
    (state) => state.authReducer?.user?.passwordChangeRequired
  );
  const userData = useSelector((state) => state.authReducer?.user);
  const userName = userData?.name.split(' ');

  return (
    <ThemeProvider theme={Theme}>
      <Container>
        {loggedIn ? (
          passwordChangeRequired ? (
            <PasswordChange />
          ) : (
            <>
              <AppBar position="static">
                <Toolbar>
                  <Link to="/">
                    <Logo src={LogoImg} />
                  </Link>
                  <DivRow>
                    <DivColumn>
                      {userData.isDentist ? (
                        <>
                          <h5>BEM VINDO, {userName[0]} (DENTISTA) </h5>
                        </>
                      ) : (
                        <>
                          <h5>
                            BEM VINDO, {userName[0]} (
                            {userData?.roles.toString()}){' '}
                          </h5>
                          <h5>UNIDADE: ({userData?.unit?.name})</h5>
                        </>
                      )}
                    </DivColumn>
                    <IconButton onClick={() => setOpen(true)} title={'Sair'}>
                      <LogOut />
                    </IconButton>
                  </DivRow>
                </Toolbar>
              </AppBar>
              <PageContainer>
                <Menu />
                <ContentContainer>
                  <AppRoutes />
                </ContentContainer>
              </PageContainer>
            </>
          )
        ) : (
          <AuthRoutes />
        )}
        <LogoutDialog
          open={open}
          onCancel={() => setOpen(false)}
          onSuccess={() => {
            setOpen(false);
            resetGlobal();
            dispatch(Creators.logout());
            history.push('/');
          }}
        />
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
