import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  TitleWrapper,
  AccountIcon,
  MiniAccountIcon,
  CustomTreeView,
} from './styles';

const Profile = () => {
  return (
    <Container>
      <TitleWrapper>
        <AccountIcon />
        <h1>Perfil</h1>
      </TitleWrapper>

      <Link to="/">Ir para Dashboard</Link>
      <CustomTreeView
        actions={{
          add: {
            icon: <MiniAccountIcon />,
            onClick: (id) => console.log(id),
            maxDepth: 1,
            title: 'Adicionar',
          },
        }}
        data={{
          'Primeiro Grupo': {
            id: 1,
            children: {
              'Primeira Categoria': {
                id: 2,
                children: {
                  'Primeira Subcategoria': {
                    id: 4,
                  },
                },
              },
            },
          },
          'Segundo Grupo': {
            id: 5,
            children: {
              'Segunda Subcategoria': {
                id: 6,
              },
            },
          },
        }}
      />
    </Container>
  );
};

export default Profile;
