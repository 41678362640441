import React, { useState, useCallback, useEffect } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, NotesMedical, Send, ViewShow, Delete } from './styles';
import { useSelector } from 'react-redux';
import DentistWorkedHour from '../../../../../forms/dentistWorkedHour';
import DentistWorkedHourDialog from '../../../../../components/dentistWorkedHourDialog';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SubmitDialog from '../../../../../components/submitDialog';

function DentistWorkedHourList({ history, ...props }) {
  const dentistId = useSelector((state) => state.authReducer?.user?.id);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ attendanceDate: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [hoursWorkedDentistId, setHoursWorkedDentistId] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [actionSubmit, setActionSubmit] = useState(false);
  const [dentistWorkedHourId, setDentistWorkedHourId] = useState(null);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const columns = DentistWorkedHour.tableSchema;

  const loadDentistWorkedHour = useCallback(() => {
    restApiClient.dentistWorkedHour
      .getAllByDentist(
        dentistId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild.df('status', 'Reprovada').df('status', 'Aprovada');
                rightChild
                  .like('unit.name', searchBy)
                  .or()
                  .like('specialty.name', searchBy);
              })
              .toString()
          : new FilterTree()
              .df('status', 'Reprovada')
              .df('status', 'Aprovada')
              .toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, dentistId]);

  useEffect(() => {
    loadDentistWorkedHour();
  }, [loadDentistWorkedHour]);

  return (
    <Container>
      <CrudTable
        title={'Minhas Agendas de Atendimentos'}
        modelActions={DentistWorkedHour.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setActionSubmit(false);
              setOpen(true);
            },
            title: 'Criar Horário de Atendimento',
            allowDentist: true,
          },
          update: {
            onClick: (id) => {
              setActionSubmit(false);
              setHoursWorkedDentistId(id);
              setOpen(true);
            },
            allowDentist: true,
            selector: (row) => isEmpty(row?.workedHourAttendances),
          },
          throwAttendance: {
            onClick: (id, row) =>
              history.push({
                pathname: '/dentist/dentistWorkedHour/attendances',
                state: { workedHour: row },
              }),
            icon: <NotesMedical />,
            title: 'Lançar Atendimento(s)',
            allowDentist: true,
            selector: (row) => row.status === 'Em Edição',
          },
          submitted: {
            icon: <Send />,
            onClick: (id, row) => {
              if (
                !row.workedHourAttendances.some(
                  (e) => e.attendance.status === 'Em Edição'
                )
              ) {
                setHoursWorkedDentistId(id);
                setActionSubmit(true);
                setOpen(true);
              } else {
                setErrorMessage(
                  'Não é possível submeter uma agenda com procedimento(s) em edição!'
                );
              }
            },
            title: 'Submeter agenda',
            allowDentist: true,
            selector: (row) =>
              !isEmpty(row?.workedHourAttendances) &&
              row.status === 'Em Edição',
          },
          viewAttendances: {
            onClick: (id, row) =>
              history.push({
                pathname: '/dentist/dentistWorkedHour/attendances',
                state: {
                  workedHour: row,
                  actionAddDisabled: true,
                },
              }),
            icon: <ViewShow />,
            title: 'Visualizar Atendimento(s)',
            allowDentist: true,
            selector: (row) => row.status !== 'Em Edição',
          },
          deleteSchedule: {
            onClick: (id, row) => {
              if (
                row?.workedHourAttendances.some(
                  (e) => e.attendance.status === 'Sob Auditoria'
                )
              ) {
                setDentistWorkedHourId(id);
                setOpenSubmitDialog(true);
              } else {
                restApiClient.dentistWorkedHour.delete(id).then(() => {
                  loadDentistWorkedHour();
                });
              }
            },
            icon: <Delete />,
            title: 'Excluir Agenda',
            allowDentist: true,
            selector: (row) => row.status === 'Em Edição',
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
          endTime: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
          'specialty.name': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />

      <DentistWorkedHourDialog
        open={open}
        dentistWorkedHour={DentistWorkedHour}
        dentistWorkedHourId={hoursWorkedDentistId}
        actionSubmit={actionSubmit}
        onCancel={async () => {
          setOpen(false);
          setHoursWorkedDentistId(null);
        }}
        onError={(error) => {
          setErrorMessage(error);
        }}
        onSuccess={() => {
          setOpen(false);
          loadDentistWorkedHour();
          setHoursWorkedDentistId(null);
          setActionSubmit(false);
        }}
        {...props}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <SubmitDialog
        open={openSubmitDialog}
        onCancel={() => setOpenSubmitDialog(false)}
        onSuccess={() => {
          restApiClient.dentistWorkedHour
            .delete(dentistWorkedHourId)
            .then(() => {
              loadDentistWorkedHour();
              setOpenSubmitDialog(false);
            });
        }}
        dialogText={
          'Ao apagar essa agenda todos os procedimentos já lançados também serão apagados, deseja continuar?'
        }
      />
    </Container>
  );
}

export default DentistWorkedHourList;
