const Item = {
  schema: {
    type: 'object',
    properties: {
      hasConversion: {
        type: 'boolean',
        customErrors: '',
      },
      name: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
      multiplyFactor: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
      barcode: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            barcode: {
              title: '',
              type: 'string',
              upperCased: false,
              maxLength: 64,
              customErrors: '',
            },
          },
        },
      },
      brand: {
        type: 'string',
        minLength: 1,
        maxLength: 100,
        customErrors: '',
      },
      sonName: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
      hasRegistry: {
        type: 'boolean',
        customErrors: '',
      },
      sonId: {
        format: 'selectItemDialog',
        customErrors: '',
      },
      sonBarcode: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            barcode: {
              title: '',
              type: 'string',
              upperCased: false,
              maxLength: 64,
              customErrors: '',
            },
          },
        },
      },
      itemId: {
        format: 'selectItemDialog',
        allowBox: true,
        customErrors: '',
      },
      itemExists: {
        type: 'boolean',
        customErrors: '',
      },
      groupId: {
        format: 'selectGroupDialog',
        customErrors: '',
      },
      price: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
    },
    required: ['name', 'brand', 'price', 'groupId'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Item',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'É uma caixa?',
            scope: '#/properties/hasConversion',
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome',
                scope: '#/properties/name',
              },
              {
                type: 'Control',
                label: 'Marca',
                scope: '#/properties/brand',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Preço',
                scope: '#/properties/price',
              },
              {
                type: 'Control',
                label: 'Categorização',
                scope: '#/properties/groupId',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/barcode',
                label: {
                  text: 'Código de Barras',
                },
              },
            ],
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/hasConversion',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome da caixa',
                scope: '#/properties/name',
              },
              {
                type: 'Control',
                label: 'Fator Multiplicador*',
                scope: '#/properties/multiplyFactor',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/barcode',
                label: {
                  text: 'Código de Barras',
                },
              },
            ],
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/hasConversion',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Item já cadastrado?',
                scope: '#/properties/hasRegistry',
              },
            ],
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: 'Nome do Item Unitário*',
                    scope: '#/properties/sonName',
                  },
                  {
                    type: 'Control',
                    label: 'Marca',
                    scope: '#/properties/brand',
                  },
                ],
              },
              {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: 'Preço',
                    scope: '#/properties/price',
                  },
                  {
                    type: 'Control',
                    label: 'Categorização',
                    scope: '#/properties/groupId',
                  },
                ],
              },
              {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: {
                      text: 'Código de Barras do Item Unitário',
                    },
                    scope: '#/properties/sonBarcode',
                  },
                ],
              },
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/hasRegistry',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/sonId',
                    handleAdditionalChanges: (data, handleChange) => {
                      handleChange('brand', data.brand);
                      handleChange('groupId', data.groupId);
                      handleChange('price', data.price);
                    },
                  },
                  {
                    type: 'Control',
                    label: 'Marca',
                    scope: '#/properties/brand',
                    disable: true,
                    rule: {
                      effect: 'DISABLE',
                      condition: {
                        scope: '#/properties/hasRegistry',
                        schema: {
                          const: true,
                        },
                      },
                    },
                  },
                ],
              },
              {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: 'Preço',
                    scope: '#/properties/price',
                  },
                  {
                    type: 'Control',
                    label: 'Categorização',
                    scope: '#/properties/groupId',
                  },
                ],
                rule: {
                  effect: 'DISABLE',
                  condition: {
                    scope: '#/properties/hasRegistry',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/hasRegistry',
                schema: {
                  const: false,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/hasConversion',
            schema: {
              const: false,
            },
          },
        },
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Edição de Item',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: 'Fator Multiplicador*',
            scope: '#/properties/multiplyFactor',
          },
          {
            type: 'Control',
            label: 'Marca',
            scope: '#/properties/brand',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/hasConversion',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: 'Marca',
            scope: '#/properties/brand',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/hasConversion',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Preço',
            scope: '#/properties/price',
          },
          {
            type: 'Control',
            label: 'Categorização',
            scope: '#/properties/groupId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/barcode',
            label: {
              text: 'Código de Barras',
            },
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    { id: 'hasConversion', label: 'Caixa', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 100, align: 'center' },
    { id: 'group', label: 'Categorização', minWidth: 100, align: 'center' },
    {
      id: 'itemLocators',
      label: 'Localizador',
      minWidth: 100,
      align: 'center',
    },
  ],
  disableTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de Barras',
      minWidth: 25,
      align: 'center',
    },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    { id: 'hasConversion', label: 'Caixa', minWidth: 100, align: 'center' },
    {
      id: 'deletedAt',
      label: 'Data Desativação',
      minWidth: 100,
      align: 'center',
    },
  ],
  searchTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de Barras',
      minWidth: 25,
      align: 'center',
    },
    { id: 'name', label: 'Nome do Item', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    {
      id: 'itemLocators',
      label: 'Localizador',
      minWidth: 100,
      align: 'center',
    },
    { id: 'group', label: 'Categorização', minWidth: 100, align: 'center' },
    {
      id: 'stock.availableQuantity',
      label: 'Quantidade',
      minWidth: 100,
      align: 'center',
    },
  ],
  updatePricesTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome do Item', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    { id: 'group', label: 'Categorização', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 100, align: 'center' },
  ],
  searchClinicTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de Barras',
      minWidth: 25,
      align: 'center',
    },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    { id: 'group', label: 'Categorização', minWidth: 100, align: 'center' },
    {
      id: 'stock.availableQuantity',
      label: 'Quantidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'itemLocators',
      label: 'Localizador',
      minWidth: 100,
      align: 'center',
    },
  ],
  adjustClinicTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de Barras',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'stock.availableQuantity',
      label: 'Quantidade',
      minWidth: 100,
      align: 'center',
    },
  ],
  shoppingGroupSearchTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de Barras',
      minWidth: 25,
      align: 'center',
    },
    { id: 'name', label: 'Nome do Item', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    {
      id: 'stock.availableQuantity',
      label: 'Quantidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'salesAvg',
      label: 'Média de Vendas',
      minWidth: 100,
      align: 'center',
    },
  ],
  unaryTableSchema: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    { id: 'hasConversion', label: 'Caixa', minWidth: 100, align: 'center' },
  ],
  pricePendentTableSchema: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    {
      id: 'item.price',
      label: 'Preço Atual',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'lastEntry',
      label: 'Preço NF-e',
      minWidth: 100,
      align: 'center',
    },
  ],
  separateOrderTableSchema: [
    { id: 'brand', label: 'Marca', minWidth: 50, align: 'center' },
    {
      id: 'stock',
      label: 'Quantidade Estoque',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'orderProductItem',
      label: 'Quantidade Aprovada',
      minWidth: 100,
      align: 'center',
    },
  ],
  separateSaleTableSchema: [
    { id: 'name', label: 'Nome', minWidth: 50, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 50, align: 'center' },
    {
      id: 'stock',
      label: 'Quantidade',
      minWidth: 100,
      align: 'center',
    },
  ],
  manualEntryTableSchema: [
    { id: 'name', label: 'Nome', minWidth: 50, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 50, align: 'center' },
  ],
  withdrawItemTableSchema: [
    { id: 'name', label: 'Nome', minWidth: 50, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 25, align: 'center' },
    {
      id: 'stock',
      label: 'Quantidade Estoque',
      minWidth: 10,
      align: 'center',
    },
  ],
  historyTableSchema: [
    {
      id: 'purchaseEntry.purchase.budget.name',
      label: 'Nome da Compra',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'updatedAt',
      label: 'Data de entrada',
      minWidth: 100,
      align: 'center',
    },
    { id: 'quantity', label: 'Quantidade', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'ItemController@getAll',
    getAllByUnit: 'ItemController@getAllByUnit',
    getAllByUnitId: 'ItemController@getAllByUnitId',
    getAllByClinic: 'ItemController@getAllByClinic',
    getAllInStock: 'ItemController@getAllInStock',
    getAllPricePending: 'ItemController@getAllPricePending',
    getAllItemInStockByUnit: 'ItemController@getAllItemInStockByUnit',
    getAllItemInStockByUnitAndGroup:
      'ItemController@getAllItemInStockByUnitAndGroup',
    getAllItemInClinicStockByGroup:
      'ItemController@getAllItemInClinicStockByGroup',
    updatePrice: 'ItemController@priceUpdate',
    getAllWithStock: 'ItemController@getAllWithStock',
    getAllWithStockClinic: 'ItemController@getAllWithStockClinic',
    getAllWithoutBoxByUnit: 'ItemController@getAllWithoutBoxByUnit',
    manualEntry: 'PurchaseEntryItemController@manualEntry',
    getAllItemsByProductIdAndOrderProductId:
      'ItemController@getAllItemsByProductIdAndOrderProductId',
    getAllItemsByProductIdClinic: 'ItemController@getAllItemsByProductIdClinic',
    getAllWithItems: 'ItemController@getAllWithItemsByGroup',
    findById: 'ItemController@findById',
    findByIdAndUnit: 'ItemController@findByIdAndUnit',
    create: 'ItemController@create',
    update: 'ItemController@patchUpdate',
    restore: 'ItemController@restore',
    delete: 'ItemController@delete',
    createByPendentEntry: 'ItemController@createByPendentEntry',
    getAllWithSaleAvg: 'ItemController@getAllWithSaleAvg',
    updatePrices: 'ItemController@updatePrices',
    getAllWhereHasStockInDistributionCenter:
      'ItemController@getAllWhereHasStockInDistributionCenter',
    getAllItemInStockByClinic: 'ItemController@getAllItemInStockByClinic',
    setProfitMargin: 'ProfitMarginIndividualController@update',
  },
  itemLocatorActions: {
    findById: 'ItemLocatorController@findById',
    create: 'ItemLocatorController@create',
    getAll: 'ItemLocatorController@getAll',
    update: 'ItemLocatorController@patchUpdate',
    delete: 'ItemLocatorController@delete',
  },
  itemLocatorTableSchema: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 50, align: 'center' },
    {
      id: 'itemLocators',
      label: 'Localizador',
      minWidth: 100,
      align: 'center',
    },
  ],
  itemLocatorClinicTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de Barras',
      minWidth: 25,
      align: 'center',
    },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 50, align: 'center' },
    {
      id: 'itemLocators',
      label: 'Localizador',
      minWidth: 100,
      align: 'center',
    },
  ],
  withdrawItemsTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome do Item', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 50, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de Barras',
      minWidth: 25,
      align: 'center',
    },
    { id: 'group', label: 'Categorização', minWidth: 100, align: 'center' },
    {
      id: 'itemLocators',
      label: 'Localizador',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'stock',
      label: 'Quantidade',
      minWidth: 100,
      align: 'center',
    },
  ],
  dialogTableSchema: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'group', label: 'Categorização', minWidth: 100, align: 'center' },
    { id: 'stock', label: 'Quantidade', minWidth: 100, align: 'center' },
  ],
  tableSchemaUpdatePrices: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'group', label: 'Categorização', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 100, align: 'center' },
  ],
  data: {
    hasConversion: false,
    hasRegistry: false,
  },
};

Item.unregisteredUischema = {
  type: 'Group',
  label: 'Cadastrar Item pendente',
  elements: [
    {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          label: 'Item já existe?',
          scope: '#/properties/itemExists',
        },
        {
          type: 'HorizontalLayout',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/itemId',
              rule: {
                effect: 'HIDE',
                condition: {
                  scope: '#/properties/itemExists',
                  schema: {
                    const: false,
                  },
                },
              },
            },
            { type: 'HorizontalLayout', elements: [] },
          ],
        },
      ],
    },
    {
      ...Item.uischema,
      type: 'VerticalLayout',
      rule: {
        effect: 'SHOW',
        condition: {
          scope: '#/properties/itemExists',
          schema: {
            const: false,
          },
        },
      },
    },
  ],
};

export default Item;
