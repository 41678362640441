import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportStockHistoryValue = {
  buildStockHistoryValuePdf: (units, groups, stockValue, data) => {
    const doc = new jsPDF();
    let y = 0;
    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(14);
    const dateFormated = new Date(data).toLocaleDateString('pt-br', {month: 'numeric', year: 'numeric'});
    doc.text(15, (y += 15),  `Relatório do Valor do Estoque em ${dateFormated}`);

    doc.setFontSize(12);
    let now = new Date
    doc.text(15, (y += 5), 'Emissão: ' + now.toLocaleDateString() + ' às ' + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds());
    //CORPO DO PDF
    Object.keys(stockValue).forEach(function(unit) {
        if (Math.ceil(y) >= 260){
            doc.addPage();
            y = 10;
        }
        if(unit !== 'totalGroup'){
            // PARA CADA UNIDADE
            const unitSelected = units.values.find(
                (x) => x.addressId === parseInt(unit)
            );
            y += 8;
            doc.setLineWidth(0.5); 
            doc.setDrawColor(0, 0, 0);
            doc.line(10, y , 200, y);
            doc.setFontSize(12);
            doc.text(15, (y += 4), `Unidade: ${unitSelected?.name}`);
            y += 2;
            doc.setLineWidth(0.5); 
            doc.setDrawColor(0, 0, 0);
            doc.line(10, y , 200, y);
            doc.setFontSize(10);
            let bodyValue = [];
            Object.keys(stockValue[unit].groups).forEach(function(value) {
                const groupValue = stockValue[unit].groups[value];
                const groupSelected = groups.find(
                    (x) => x.id === groupValue.groupId
                );
                bodyValue.push([
                    groupSelected.name, 
                    { content: parseFloat(groupValue.value ?? 0).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }), styles: { halign: 'right'} },
                ]);
            });
            doc.autoTable({
                startY: (y += 1),
                head: [['Grupo', 'Valor']],
                body: bodyValue,
                theme: 'plain',
                tableWidth: 'wrap',
                styles: { fontSize: 9 },
            });
            y = doc.lastAutoTable.finalY;
            
            y += 2;
            doc.setLineWidth(0.8); 
            doc.setDrawColor(0, 0, 0);
            doc.line(10, y , 200, y);
            doc.setFontSize(12);
            let totalUnit = parseFloat(stockValue[unit].totalUnit ?? 0);
            doc.text(15, (y += 5), `Total: ${parseFloat(totalUnit).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })}`);
        } else {
            //Total geral
            y += 10;
            doc.setLineWidth(0.5); 
            doc.setDrawColor(0, 0, 0);
            doc.line(10, y , 200, y);
            doc.setFontSize(12);
            doc.text(15, (y += 4), `Total por Grupo:`);
            y += 2;
            doc.setLineWidth(0.5); 
            doc.setDrawColor(0, 0, 0);
            doc.line(10, y , 200, y);
            doc.setFontSize(10);
            let bodyTotalValue = [];
            Object.keys(stockValue[unit].groups).forEach(function(value) {
                const groupTotalValue = stockValue[unit].groups[value];
                const groupTotalSelected = groups.find(
                    (x) => x.id === groupTotalValue.groupId
                );
                bodyTotalValue.push([
                    groupTotalSelected.name, 
                    { content:  parseFloat(groupTotalValue.result ?? 0).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                    }), styles: { halign: 'right'} },
                ]);
            });
            doc.autoTable({
                startY: (y += 1),
                head: [['Grupo', 'Valor']],
                body: bodyTotalValue,
                theme: 'plain',
                tableWidth: 'wrap',
                styles: { fontSize: 9 },
            });
            y = doc.lastAutoTable.finalY;
    
            y += 2;
            doc.setLineWidth(0.8); 
            doc.setDrawColor(0, 0, 0);
            doc.line(10, y , 200, y);
            doc.setFontSize(12);
            let totalGlobal = parseFloat(stockValue[unit].totalGlobal ?? 0);
            doc.text(15, (y += 4), `Total: ${parseFloat(totalGlobal).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })}`);
        }
    });
    
    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    
    doc.setFontSize(10);

    for(var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 290, String(i) + '/' + String(pageCount));
    }
    

    return doc;
  },
};

export default ReportStockHistoryValue;
