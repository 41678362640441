/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Container,
  DialogContent,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  InputTextarea,
  FormControlLabel,
  Checkbox,
  Dialog,
  FormDiv,
  ActionsContainer,
  CancelButton,
  DialogActions,
  SubmitButton,
  Card,
  CardHeader,
  HeaderContainer,
} from './styles';
import DayJsUtils from '@date-io/dayjs';
import Grid from '@material-ui/core/Grid';
import restApiClient from '../../services/restApiClient';
import dayjs from 'dayjs';
import moment from 'moment';

function PatientReturnDialog({
  onSuccess,
  onCancel,
  beginDate,
  unitId,
  patientId,
  orderServiceStepId,
  ...props
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [urgency, setUrgency] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [justification, setJustification] = useState(null);
  const [justificationError, setJustificationError] = useState(false);
  const [minDate, setMinDate] = useState(new Date().getTime());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    if (props.open) {
      restApiClient.holiday.getAllByCityAndYear(unitId).then((r) => {
        const listHolidays = r.data.values.map((holiday) => {
          let date = new Date(holiday.date + ' 00:00:00');
          return date.getTime();
        });
        setHolidays(listHolidays);
      });
    }
  }, [props.open]);

  useEffect(() => {
    if (props.open) {
      restApiClient.orderService
        .getCurrentPatientReturnDateByPatientId(patientId)
        .then((e) => {
          if (e.data.patientReturnDate) {
            setSelectedDate(new Date(e.data.patientReturnDate + ' 00:00:00'));
          }
        });
    }
  }, [props.open]);

  function disableSundays(dayJsDate) {
    const date = dayJsDate.toDate();
    return (
      date.getDay() === 0 ||
      (!urgency ? date < beginDate : false) ||
      holidays.includes(date.getTime())
    );
  }

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <Card>
            <HeaderContainer>
              <CardHeader title="Agendamento de Retorno" />
            </HeaderContainer>
            <FormDiv>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={urgency}
                    onChange={(e) => {
                      setJustificationError(false);
                      setSelectedDate(null);
                      setJustification(null);
                      setUrgency(e.target.checked);
                    }}
                    name="urgency"
                  />
                }
                label="É Urgência?"
              />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <MuiPickersUtilsProvider utils={DayJsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="return-date"
                      label="Data de retorno"
                      size="small"
                      inputVariant="outlined"
                      format="DD/MM/YYYY"
                      value={selectedDate}
                      onChange={(date) => {
                        setDateError(false);
                        setSelectedDate(date.toDate());
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Editar data',
                      }}
                      disablePast
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={() => setOpenCalendar(true)}
                      shouldDisableDate={disableSundays}
                      open={openCalendar}
                      onClose={() => setOpenCalendar(false)}
                      cancelLabel="Cancelar"
                      okLabel="Selecionar"
                      invalidDateMessage="Data em formato inválido."
                      onError={(value) => setInitialDateError(value)}
                      helperText={dateError ? 'Este campo é obrigatório.' : ''}
                      error={dateError}
                      minDate={minDate}
                      minDateMessage={`A data deve ser maior que ${dayjs(
                        minDate
                      ).format('DD/MM/YYYY')}.`}
                      required
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {urgency ? (
                  <Grid item xs={12} sm={12}>
                    <InputTextarea
                      id="outlined-multiline-static"
                      size="small"
                      label="Justificativa"
                      value={justification}
                      onChange={(e) => {
                        setJustificationError(false);
                        setJustification(e.target.value);
                      }}
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{ maxLength: 250 }}
                      required
                      helperText={
                        justificationError ? 'Este campo é obrigatório.' : ''
                      }
                      error={justificationError}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </FormDiv>
          </Card>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                setUrgency(false);
                setSelectedDate(null);
                setDateError(false);
                setJustification(null);
                setJustificationError(null);
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <SubmitButton
              onClick={() => {
                if (!selectedDate) {
                  setDateError(true);
                } else {
                  if (urgency && !justification) {
                    setJustificationError(true);
                  } else {
                    const model = {
                      orderServiceStepId: orderServiceStepId,
                      urgency: urgency,
                      patientReturnDate:
                        moment(selectedDate).format('YYYY-MM-DD'),
                      justification: justification || null,
                    };
                    restApiClient.schedule
                      .create(model)
                      .then(async (result) => {
                        setUrgency(false);
                        setSelectedDate(null);
                        setJustification(null);
                        onSuccess?.(result?.data);
                      })
                      .catch((x) => {
                        if (x.response?.status === 400) {
                          if (x.response.data?.errors?.patientReturnDate) {
                            setDateError(
                              x.response.data.errors.patientReturnDate.join(' ')
                            );
                          } else if (x.response.data?.errors?.justification) {
                            setJustificationError(
                              x.response.data.errors.justification.join(' ')
                            );
                          }
                        }
                      });
                  }
                }
              }}
              variant="contained"
              color="primary"
            >
              Submeter
            </SubmitButton>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default PatientReturnDialog;
