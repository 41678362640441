import React, { useState, useEffect } from 'react';
import Unit from '../../../../../forms/unit';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, AddChart } from './styles';
import RadiologyConsumption from '../../../../../forms/radiologyConsumption';
import moment from 'moment';

function RadiologyConsumptionList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Unit.tableSchema;

  useEffect(() => {
    restApiClient.unit
      .getAllWithConsumptions(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild
                  .df('type', 'distribution_center')
                  .df('type', 'radiology');
                rightChild.like('name', searchBy);
              })
              .toString()
          : new FilterTree()
              .df('type', 'distribution_center')
              .df('type', 'radiology')
              .toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Consumo da Radiologia'}
        modelActions={Unit.actions}
        columns={columns}
        customColumns={[
          {
            id: 'lastRadiologyConsumption',
            label: 'Último Envio',
            minWidth: 50,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          throwComsumption: {
            title: 'Lançar Consumo',
            icon: <AddChart />,
            onClick: (id, row) => {
              history.push({
                pathname: `/administrative/radiology-consumption/unit/${id}`,
                state: { labelUnit: row?.name },
              });
            },
            allowedActions: [RadiologyConsumption.actions.getAllByUnit],
          },
        }}
        customCellRenderers={{
          lastRadiologyConsumption: {
            renderer: (field, value, column, row) => {
              if (row?.lastRadiologyConsumption) {
                return `${moment(row.lastRadiologyConsumption.endDate).format(
                  'DD/MM/YYYY'
                )}`;
              } else {
                return '-';
              }
            },
          },
        }}
      />
    </Container>
  );
}

export default RadiologyConsumptionList;
