import styled from 'styled-components';
import MaterialPaper from '@material-ui/core/Paper';
import TransferList from '../../../../components/transferList';
import { Button as MaterialButton } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  span {
    font-size: 1.5rem;
    font-weight: 400;
  }
`;

export const Paper = styled(MaterialPaper)`
  width: 80%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledTransferList = styled(TransferList)`
  max-height: 300px;
  margin-bottom: 10px;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 30%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 30%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
