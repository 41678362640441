import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectClinicDialogControl from '../../components/selectClinicDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectClinicDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectClinicDialog'
  )
);

const customSelectClinicDialogControlTester = rankWith(
  4,
  isSelectClinicDialogControl
);

export default {
  tester: customSelectClinicDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectClinicDialogControl),
};
