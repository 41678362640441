import styled from 'styled-components';
import CrudTable from '../crudTable';
import { AddShoppingCart as MaterialAddShoppingCart } from '../../styles/icons';

export const Container = styled.div`
  margin-top: 10px;
  padding-left: 70px;
  padding-right: 70px;
  width: 100%;
  height: 600px;
`;

export const StyledCrudTable = styled(CrudTable)`
  min-width: 800px !important;
  min-height: 600px !important;
  max-width: 800px !important;
  max-height: 600px !important;
`;

export const AutoDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 800px !important;
  min-height: 600px !important;
  height: 600px;
`;

export const AddShoppingCart = styled(MaterialAddShoppingCart)`
  width: 20px;
  height: 20px;
`;
