import styled from 'styled-components';
import SecuredListItem from '../securedListItem';
import SecuredCollapseList from '../securedCollapseList';
import MaterialList from '@material-ui/core/List';
import MaterialListItemText from '@material-ui/core/ListItemText';
import MaterialListItemIcon from '@material-ui/core/ListItemIcon';
import {
  UserBadge as MaterialUserBadge,
  UserCircle as MaterialUserCircle,
  OfficeBuilding as MaterialOfficeBuilding,
  LayerGroup as MaterialLayerGroup,
  Truck as MaterialTruck,
  Box as MaterialBox,
  Settings as MaterialSettings,
  Boxes as MaterialBoxes,
  AddBox as MaterialAddBox,
  Money as MaterialMoney,
  Exclamation as MaterialExclamation,
  SearchIcon as MaterialSearch,
  ViewModule as MaterialViewModule,
  Cart as MaterialCart,
  BoxAdd as MaterialBoxAdd,
  ClipboardList as MaterialClipboardList,
  ClockHistory as MaterialClockHistory,
  TruckLoading as MaterialTruckLoading,
  LocationCity as MaterialLocationCity,
  BoxProduct as MaterialBoxProduct,
  Transfer as MaterialTransfer,
  PackageIcon as MaterialPackageIcon,
  Warehouse as MaterialWarehouse,
  Grid3x2GapFill as MaterialGrid,
  ShoppingCart as MaterialShoppingCart,
  ClipboardCheck as MaterialClipboardCheck,
  CashRegister as MaterialCashRegister,
  AccountBalance as MaterialAccountBalance,
  Report as MaterialReport,
  BagPlusFill as MaterialBagPlusFill,
  Tooth as MaterialTooth,
  UserGroup as MaterialUserGroup,
  ShoppingBag as MaterialShoppingBag,
  Admin as MaterialAdmin,
  GraphTrend as MaterialGraphTrend,
  Percent as MaterialPercent,
  BalanceScale as MaterialBalanceScale,
  LabFlask as MaterialLabFlask,
  BarChartSteps as MaterialBarChartSteps,
  CalendarSettings as MaterialCalendarSettings,
  ListAlt as MaterialListAlt,
  ListWarning as MaterialListWarning,
  ListOS as MaterialListOS,
  Patient as MaterialPatient,
  XRay as MaterialXray,
  TripOrigin as MaterialTripOrigin,
  LocalHospital as MaterialLocalHospital,
  CalendarX as MaterialCalendarX,
  CalendarAdd as MaterialCalendarAdd,
  AddLink as MaterialAddLink,
  Clock as MaterialClock,
  CalendarClock as MaterialCalendarClock,
  Procedures as MaterialProcedures,
  HandHoldingMedical as MaterialHandHoldingMedical,
  UserShared as MaterialUserShared,
  DualScreenClock as MaterialDualScreenClock,
  NotesMedical as MaterialNotesMedical,
  FileEarmarkMedicalFill as MaterialFileEarmarkMedicalFill,
  DocumentDismiss as MaterialDocumentDismiss,
  DocumentBulletListClock as MaterialDocumentBulletListClock,
  ClipboardTaskListRtl as MaterialClipboardTaskListRtl,
  BankIcon as MaterialBank,
  DocumentPerson as MaterialDocumentPerson,
  Receipt as MaterialReceipt,
  DocumentBulletList as MaterialDocumentBulletList,
  MonetizationOn as MaterialMonetizationOn,
  ReceiptAdd as MaterialReceiptAdd,
  GroupWork as MaterialGroupWork,
  ReceiptExpense as MaterialReceiptExpense,
  PriceChange as MaterialPriceChange,
  PeopleSwap as MaterialPeopleSwap,
  BuildingBankToolbox as MaterialBuildingBankToolbox,
  BuildingRetailMoney as MaterialBuildingRetailMoney,
  CreditCard as MaterialCreditCard,
  MoneyCheck as MaterialMoneyCheck,
  MoneyCalculator as MaterialMoneyCalculator,
  TextField as MaterialTextField,
  TrashBin as MaterialTrashBin,
  Safe as MaterialSafe,
  CardList as MaterialCardList,
} from '../../styles/icons';

export const Container = styled.div`
  width: 250px;
  height: 100%;
  background-color: var(--liteBackground);
`;
export const List = styled(MaterialList)``;
export const StyledSecuredListItem = styled(SecuredListItem)``;
export const StyledSecuredCollapseList = styled(SecuredCollapseList)``;
export const ListItemText = styled(MaterialListItemText)``;
export const ListItemIcon = styled(MaterialListItemIcon)`
  min-width: 40px !important;
`;

export const UserBadge = styled(MaterialUserBadge)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const FileEarmarkMedicalFill = styled(MaterialFileEarmarkMedicalFill)`
  width: 25px;
  height: 25px;

  fill: var(--secondary);
`;

export const BuildingRetailMoney = styled(MaterialBuildingRetailMoney)`
  width: 25px;
  height: 25px;

  fill: var(--secondary);
`;

export const BuildingBankToolbox = styled(MaterialBuildingBankToolbox)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const DocumentDismiss = styled(MaterialDocumentDismiss)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const DualScreenClock = styled(MaterialDualScreenClock)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const LabFlask = styled(MaterialLabFlask)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const ListAlt = styled(MaterialListAlt)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const CalendarSettings = styled(MaterialCalendarSettings)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const UserCircle = styled(MaterialUserCircle)`
  width: 25px;
  height: 25px;

  color: var(--secondary);
`;

export const OfficeBuilding = styled(MaterialOfficeBuilding)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const Admin = styled(MaterialAdmin)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const LayerGroup = styled(MaterialLayerGroup)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Truck = styled(MaterialTruck)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px Search as MaterialSearch;
  fill: var(--secondary);
`;

export const PackageIcon = styled(MaterialPackageIcon)`
  width: 25px;
  height: 25px Search as MaterialSearch;
  fill: var(--secondary);
`;

export const Settings = styled(MaterialSettings)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Boxes = styled(MaterialBoxes)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Money = styled(MaterialMoney)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const Exclamation = styled(MaterialExclamation)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Search = styled(MaterialSearch)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const ViewModule = styled(MaterialViewModule)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const ClipboardList = styled(MaterialClipboardList)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Cart = styled(MaterialCart)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const BoxAdd = styled(MaterialBoxAdd)`
  width: 27px;
  height: 27px;
  fill: var(--secondary);
`;

export const TruckLoading = styled(MaterialTruckLoading)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const LocationCity = styled(MaterialLocationCity)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const BoxProduct = styled(MaterialBoxProduct)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Transfer = styled(MaterialTransfer)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Warehouse = styled(MaterialWarehouse)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Grid = styled(MaterialGrid)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const ShoppingCart = styled(MaterialShoppingCart)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const ClipboardCheck = styled(MaterialClipboardCheck)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const CashRegister = styled(MaterialCashRegister)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const AccountBalance = styled(MaterialAccountBalance)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Report = styled(MaterialReport)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const BagPlusFill = styled(MaterialBagPlusFill)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const Tooth = styled(MaterialTooth)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const UserGroup = styled(MaterialUserGroup)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const GraphTrend = styled(MaterialGraphTrend)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const ShoppingBag = styled(MaterialShoppingBag)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Percent = styled(MaterialPercent)`
  width: 20px;
  height: 20px;
  color: var(--secondary);
`;

export const BalanceScale = styled(MaterialBalanceScale)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const Steps = styled(MaterialBarChartSteps)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const ListWarning = styled(MaterialListWarning)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const ListOS = styled(MaterialListOS)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const PatientIcon = styled(MaterialPatient)`
  width: 30px;
  height: 30px;

  fill: var(--secondary);
`;

export const XRay = styled(MaterialXray)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const TripOrigin = styled(MaterialTripOrigin)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const LocalHospital = styled(MaterialLocalHospital)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const CalendarX = styled(MaterialCalendarX)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const CalendarAdd = styled(MaterialCalendarAdd)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const AddLink = styled(MaterialAddLink)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Clock = styled(MaterialClock)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const CalendarClock = styled(MaterialCalendarClock)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Procedures = styled(MaterialProcedures)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const HandHoldingMedical = styled(MaterialHandHoldingMedical)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const UserShared = styled(MaterialUserShared)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const NotesMedical = styled(MaterialNotesMedical)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const DocumentClock = styled(MaterialDocumentBulletListClock)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const ClipboardTaskList = styled(MaterialClipboardTaskListRtl)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const Bank = styled(MaterialBank)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const DocumentPerson = styled(MaterialDocumentPerson)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const DocumentBulletList = styled(MaterialDocumentBulletList)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const Receipt = styled(MaterialReceipt)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PaymentIcon = styled(MaterialMonetizationOn)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const ReceiptAdd = styled(MaterialReceiptAdd)`
  width: 32px;
  height: 32px;
  color: var(--secondary);
`;

export const GroupWork = styled(MaterialGroupWork)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const ReceiptExpense = styled(MaterialReceiptExpense)`
  width: 32px;
  height: 32px;
  color: var(--secondary);
`;

export const PriceChange = styled(MaterialPriceChange)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const PeopleSwap = styled(MaterialPeopleSwap)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const CreditCard = styled(MaterialCreditCard)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const MoneyCheck = styled(MaterialMoneyCheck)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const CardList = styled(MaterialCardList)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const MoneyCalculator = styled(MaterialMoneyCalculator)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const TextField = styled(MaterialTextField)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const TrashBin = styled(MaterialTrashBin)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Safe = styled(MaterialSafe)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
