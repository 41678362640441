import styled from 'styled-components';
import { Receipt as MaterialReceipt } from '../../../../../styles/icons';
import { Button as MaterialButton } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Receipt = styled(MaterialReceipt)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const BackButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
