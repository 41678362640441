const Section = {
  tableSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo de Despesa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'incomeExpenseType',
      label: 'Tipo de Receita',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    create: 'SectionController@create',
    getAll: 'SectionController@getAll',
    findById: 'SectionController@findById',
    update: 'SectionController@patchUpdate',
    getAllBranches: 'SectionController@getAllBranches',
    getAllChildrenByParentId: 'SectionController@getAllChildrenByParentId',
    getTypeById: 'SectionController@getAllChildrenByParentId',
  },
};

export default Section;
