import React, { useState, useEffect } from 'react';
import Unit from '../../../../../forms/unit';
import CrudTable from '../../../../../components/crudTable';
import { Container, AddLink } from './styles';
import restApiClient from '../../../../../services/restApiClient';
import FilterTree from '../../../../../helpers/filterTree';

function RadiologyConfigList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = Unit.unitTypesSchema;

  useEffect(() => {
    restApiClient.unit
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('name', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Configurações'}
        modelActions={Unit.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          linkServices: {
            icon: <AddLink />,
            onClick: (id) => {
              history.push(
                `/radiology/config/unit-type-services/linked-services/${id}`
              );
            },
            title: 'Vincular Serviços',
            allowedActions: [Unit.actions.getAll],
          },
        }}
      />
    </Container>
  );
}

export default RadiologyConfigList;
