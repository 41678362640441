import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  Container,
  ContentContainer,
  Paper,
  Scheduler,
  Resources,
  WeekView,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  Toolbar,
  ViewSwitcher,
  ViewState,
  GroupingState,
  IntegratedGrouping,
  CancelButton,
  ButtonContainer
} from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';

function SchedulerComponent({ chairs, dentists, ...props }) {
  const history = useHistory();
  const resources = [{
    fieldName: 'chairId',
    title: 'Cadeiras',
    instances: chairs,
  }];
  const groupOrientation = viewName => viewName.split(' ')[0];
  const grouping = [{ resourceName: 'chairId' }];
  
  return (
    <Container>
      {isEmpty(chairs) ? (
        <CircularProgress />
      ) : (
        <ContentContainer>
          <Paper>
            <Scheduler
              data={dentists}
              locale='pt-br'
            >
              <ViewState
                currentDate={moment()}
                defaultCurrentDate={moment()}
              />

              <GroupingState
                grouping={grouping}
                groupOrientation={groupOrientation}
              />

              <WeekView
                startDayHour={8}
                endDayHour={18}
                excludedDays={[0]}
                cellDuration={60}
                name='Vertical'
              />

              <WeekView
                startDayHour={8}
                endDayHour={18}
                excludedDays={[0]}
                cellDuration={60}
                name='Horizontal'
              />

              <Appointments
                appointmentComponent={(appointmentProps) => (
                  <Appointments.Appointment
                    style={{
                      marginLeft: '4px',
                      width: '100%',
                    }}
                    {...appointmentProps}
                  />
                )}
              />
              <Resources
                data={resources}
                mainResourceName='chairId'
              />

              <IntegratedGrouping />
              <AppointmentTooltip />

              <GroupingPanel />
              <Toolbar />
              <ViewSwitcher />
            </Scheduler>
          </Paper>
          <ButtonContainer>
            <CancelButton
              onClick={() => history.goBack()}
              type='button'
              variant='contained'
              color='primary'
            >
              Voltar
            </CancelButton>
          </ButtonContainer>
        </ContentContainer>
      )}
    </Container>
  );
}

export default SchedulerComponent;
