import React, { useState, useEffect } from 'react';
import RecurrentExpense from '../../../../../forms/recurrentExpense';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container } from './styles';

function RecurrentExpenseList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = RecurrentExpense.tableSchema;

  useEffect(() => {
    restApiClient.recurrentExpense
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('unit.name', searchBy)
              .or()
              .like('employee.user.name', searchBy)
              .or()
              .like('patient.name', searchBy)
              .or()
              .like('dentist.user.name', searchBy)
              .or()
              .like('recurrentExpensable.social_name', searchBy)
              .or()
              .like('recurrentExpensable.fantasy_name', searchBy)
              .or()
              .like('expenseType.name', searchBy)
              .or()
              .like('expenseType.parent.name', searchBy)
              .or()
              .like('expenseType.parent.parent.name', searchBy)
              .or()
              .like('expenseType.parent.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Despesas Recorrentes'}
        modelActions={RecurrentExpense.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          update: {
            onClick: (expenseId, row) => {
              let recurrentExpensableType;
              if (row?.recurrentExpensableType) {
                let indexes = row?.recurrentExpensableType.split('\\');
                recurrentExpensableType =
                  indexes[2][0].toLowerCase() + indexes[2].substring(1);
              }
              history.push({
                pathname: `/financial/config/recurrent-expense/update/${expenseId}`,
                state: { recurrentExpensableType },
              });
            },
          },
          delete: {
            onClick: (recurrentExpenseId, index) => {
              restApiClient.recurrentExpense
                .delete(recurrentExpenseId)
                .then(() => {
                  const result = { ...rows };
                  if (index > -1) {
                    result.values.splice(index, 1);
                    result.count--;
                  }
                  setRows(result);
                });
            },
          },
        }}
        customCellRenderers={{
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          name: {
            renderer: (field, value, column, row) => {
              if (row?.employeeId) {
                return row?.employee?.user?.name;
              } else if (row?.patientId) {
                return row?.patient?.name;
              } else if (row?.dentistId) {
                return row?.dentist?.user?.name;
              } else if (row?.recurrentExpensable) {
                return row?.recurrentExpensable?.isPhysicalPerson
                  ? row?.recurrentExpensable?.socialName
                  : row?.recurrentExpensable?.fantasyName;
              } else {
                return '-';
              }
            },
          },
          expenseType: {
            renderer: (field, value) => {
              if (value.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value.name;
              }
            },
          },
        }}
      />
    </Container>
  );
}

export default RecurrentExpenseList;
