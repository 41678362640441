import React, { useState, useEffect, useCallback } from 'react';
import AdverseExpense from '../../../../forms/adverseExpense';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, Preview, Cancel, Send } from './styles';
import SubmitDialog from '../../../../components/submitDialog';
import ConsolidateExpenseDialog from '../../../../components/consolidateExpenseDialog';

function AdverseExpenseList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openConsolidateExpenseDialog, setOpenConsolidateExpenseDialog] =
    useState(false);
  const [adverseExpenseId, setAdverseExpenseId] = useState(null);
  const [adverseExpense, setAdverseExpense] = useState(null);
  const columns = AdverseExpense.tableSchema;

  const loadAdverseExpenses = useCallback(() => {
    restApiClient.adverseExpense
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('unit.name', searchBy)
              .or()
              .like('expenseType.name', searchBy)
              .or()
              .like('expenseType.parent.name', searchBy)
              .or()
              .like('expenseType.parent.parent.name', searchBy)
              .or()
              .like('expenseType.parent.parent.parent.name', searchBy)
              .or()
              .like('status', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadAdverseExpenses();
  }, [loadAdverseExpenses]);

  return (
    <Container>
      <CrudTable
        title={'Despesas Adversas'}
        modelActions={AdverseExpense.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              history.push('/financial/adverse-expense/create');
            },
          },
          consolidateAdverseExpense: {
            icon: <Send />,
            onClick: (id, row) => {
              setAdverseExpenseId(id);
              setAdverseExpense(row);
              setOpenConsolidateExpenseDialog(true);
            },
            title: 'Consolidar Despesa Adversa',
            selector: (row) => row?.status === 'Submetida',
            allowedActions: [AdverseExpense.actions.consolidateAdverseExpense],
          },
          update: {
            onClick: (expenseId) => {
              history.push(`/financial/adverse-expense/update/${expenseId}`);
            },
            selector: (row) => row.status === 'Submetida',
          },
          cancelAdverseExpense: {
            icon: <Cancel />,
            onClick: (adverseExpenseId) => {
              setOpenSubmitDialog(true);
              setAdverseExpenseId(adverseExpenseId);
            },
            title: 'Cancelar Despesa Adversa',
            selector: (row) => row?.status === 'Submetida',
            allowedActions: [AdverseExpense.actions.cancelAdverseExpense],
          },
          view: {
            icon: <Preview />,
            onClick: (expenseId) => {
              history.push({
                pathname: `/financial/adverse-expense/${expenseId}`,
                state: { readonly: true },
              });
            },
            title: 'Visualizar Despesa Adversa',
            allowedActions: [AdverseExpense.actions.findById],
          },
        }}
        customCellRenderers={{
          estimatedValue: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          expenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value.name;
              }
            },
          },
        }}
      />

      <ConsolidateExpenseDialog
        open={openConsolidateExpenseDialog}
        adverseExpense={adverseExpense}
        onCancel={() => {
          setAdverseExpense(null);
          setOpenConsolidateExpenseDialog(false);
        }}
        onSuccess={async (result) => {
          await restApiClient.adverseExpense
            .consolidateAdverseExpense(adverseExpenseId, result)
            .then(() => {
              setOpenConsolidateExpenseDialog(false);
              loadAdverseExpenses();
            });
        }}
      />

      <SubmitDialog
        open={openSubmitDialog}
        onCancel={() => setOpenSubmitDialog(false)}
        onSuccess={async () => {
          await restApiClient.adverseExpense
            .cancelAdverseExpense(adverseExpenseId)
            .then(() => {
              setOpenSubmitDialog(false);
              loadAdverseExpenses();
            });
        }}
        dialogText={
          'Ao cancelar uma despesa adversa o valor retornará para a conta bancária, você deseja continuar?'
        }
      />
    </Container>
  );
}

export default AdverseExpenseList;
