import React, { useState, useEffect, useCallback } from 'react';
import { useGlobal } from 'reactn';
import CashRegisterClose from '../../../../forms/cashRegisterClose';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  Preview,
  UnlockAlt,
  Assignment,
  Select,
  MenuItem,
  SelectContainer,
  FormControl,
  InputLabel,
} from './styles';
import SelectUnitCashRegisterDialog from '../../../../components/selectUnitCashRegisterDialog';

function CashRegisterCloseList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useGlobal('cashRegisterClose:searchBy');
  const [openDialog, setOpenDialog] = useState(false);
  const [units, setUnits] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useGlobal(
    'cashRegisterClose:selectedUnitId'
  );

  const columns = CashRegisterClose.tableSchema;

  const loadcashRegisterCloses = useCallback(() => {
    if (!selectedUnitId || selectedUnitId === 'all') {
      restApiClient.cashRegisterClose
        .getAll(
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp.notIn('status', ['Em Edição']);
                  rightOp
                    .dateLike('date', searchBy)
                    .or()
                    .like('numberCashRegister', searchBy)
                    .or()
                    .like('status', searchBy)
                    .or()
                    .like('unit.name', searchBy)
                    .or()
                    .like('employee.user.name', searchBy);
                })
                .toString()
            : new FilterTree().notIn('status', ['Em Edição']).toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    } else {
      restApiClient.cashRegisterClose
        .getAllByUnit(
          selectedUnitId ?? 'all',
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp.notIn('status', ['Em Edição']);
                  rightOp
                    .dateLike('date', searchBy)
                    .or()
                    .like('numberCashRegister', searchBy)
                    .or()
                    .like('status', searchBy)
                    .or()
                    .like('unit.name', searchBy)
                    .or()
                    .like('employee.user.name', searchBy);
                })
                .toString()
            : new FilterTree().notIn('status', ['Em Edição']).toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, selectedUnitId]);

  useEffect(() => {
    loadcashRegisterCloses();
  }, [loadcashRegisterCloses]);

  useEffect(() => {
    restApiClient.unit.getAll().then((e) => {
      setUnits(e.data);
    });
  }, []);

  return (
    <Container>
      <CrudTable
        title={'Fechamento de Caixa'}
        customHeaderComponent={
          <SelectContainer>
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-selectedUnitId-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnitId ?? 'all'}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnitId(e.target.value);
                }}
              >
                {[
                  <MenuItem value="all" key={'all'}>
                    <em>Todas</em>
                  </MenuItem>,
                ].concat(
                  units?.values.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </SelectContainer>
        }
        modelActions={CashRegisterClose.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpenDialog(true);
            },
            title: 'Criar Fechamento de Caixa',
            allowedActions: [
              CashRegisterClose.actions.create,
              CashRegisterClose.actions.setBlocked,
              CashRegisterClose.actions.getUnitLastCashRegister,
            ],
          },
          update: {
            onClick: (expenseId, row) => {
              history.push({
                pathname: `/financial/cashRegisterClose/update/${expenseId}`,
                state: {
                  adjust: row.status === 'Em Ajuste',
                },
              });
            },
            allowedActions: [
              CashRegisterClose.actions.findById,
              CashRegisterClose.actions.update,
              CashRegisterClose.actions.setBlocked,
              CashRegisterClose.actions.getAllByUnit,
            ],
            selector: (row) =>
              row.status === 'Em Edição' || row.status === 'Em Ajuste' || row.status === 'Em Avaliação',
          },
          unlockCashRegister: {
            icon: <UnlockAlt />,
            onClick: (id) => {
              restApiClient.cashRegisterClose
                .unlockCashRegister(id)
                .then(() => loadcashRegisterCloses());
            },
            allowedActions: [CashRegisterClose.actions.unlockCashRegister],
            title: 'Desbloquear Fechamento de Caixa',
            selector: (row) => row.status === 'Bloqueado',
          },
          evaluateCashRegister: {
            icon: <Assignment />,
            onClick: (id) => {
              history.push({
                pathname: `/financial/cashRegisterClose/${id}`,
                state: { evaluate: true },
              });
            },
            allowedActions: [
              CashRegisterClose.actions.setBlocked,
              CashRegisterClose.actions.setDisapproved,
            ],
            title: 'Avaliar Fechamento de Caixa',
            selector: (row) => row.status === 'Em Avaliação',
          },
          view: {
            icon: <Preview />,
            onClick: (cashRegisterCloseId) => {
              history.push({
                pathname: `/financial/cashRegisterClose/${cashRegisterCloseId}`,
                state: { readonly: true },
              });
            },
            title: 'Visualizar Fechamento de Caixa',
            selector: (row) => row.status !== 'Em Avaliação',
          },
        }}
        customCellRenderers={{
          date: {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          cashRegisterValues: {
            renderer: (field, value) => {
              return value
                .reduce((accumulator, currentValue) => {
                  return accumulator + currentValue.value;
                }, 0)
                .toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.status === 'Em Avaliação') {
            return '#ffe0e0';
          }
        }}
      />

      <SelectUnitCashRegisterDialog
        open={openDialog}
        onCancel={() => {
          setOpenDialog(false);
        }}
        onSuccess={(cashRegister, submitRestriction) => {
          history.push({
            pathname: '/financial/cashRegisterClose/create',
            state: { cashRegister, submitRestriction },
          });
        }}
      />
    </Container>
  );
}

export default CashRegisterCloseList;
