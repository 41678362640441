import React, { useState, useEffect, useCallback } from 'react';
import Item from '../../../forms/item';
import Unit from '../../../forms/unit';
import ItemBarcode from '../../../forms/itemBarcode';
import PurchaseEntry from '../../../forms/purchaseEntryItems';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import LocatorItemDialog from '../../../components/locatorItemDialog';
import {
  Container,
  PackageIcon,
  Barcode as MaterialBarcode,
  CodeDownload,
  ClockHistory,
  Close,
  Grid as MaterialGrid,
} from './styles';

function ItemList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsWithParent, setRowsWithParent] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [openLocator, setOpenLocator] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const columns = Item.tableSchema;

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const loadItems = useCallback(() => {
    restApiClient.item
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftOp, rightOp) => {
                leftOp.isNull('deletedAt');
                rightOp
                  .like('id', searchBy)
                  .or()
                  .like('name', searchBy)
                  .or()
                  .like('brand', searchBy)
                  .or()
                  .like('price', searchBy)
                  .or()
                  .like('itemLocators.locator', searchBy)
                  .or()
                  .like('group.name', searchBy)
                  .or()
                  .like('group.parent.name', searchBy)
                  .or()
                  .like('group.parent.parent.name', searchBy)
                  .or()
                  .like('barcode.barcode', searchBy);
              })
              .toString()
          : new FilterTree().isNull('deletedAt').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
        setRowsWithParent(
          e.data.values
            .filter((item) => item.child)
            .map((item) => item.child.id)
        );
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Itens'}
        modelActions={Item.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => history.push('/item/create'),
          },
          locator: {
            icon: <MaterialGrid />,
            onClick: (id) => {
              setOpenLocator(true);
              setSelectedId(id);
            },
            title: 'Definir Localizador',
            allowedActions: [
              [
                Item.itemLocatorActions.create,
                Item.itemLocatorActions.update,
                Unit.actions.getAll,
                Item.actions.findByIdAndUnit,
              ],
            ],
          },
          createBarcode: {
            icon: <MaterialBarcode />,
            onClick: (itemId) => {
              restApiClient.item
                .updateAutoBarcode(itemId)
                .then((result) => {
                  setRows({
                    count: rows.count,
                    values: [
                      ...rows.values.map((x) => {
                        if (x.id === itemId) x.autoBarcode = true;
                        return x;
                      }),
                    ],
                  });
                  setAlert({
                    message: 'O código de barras foi gerado com sucesso!',
                    severity: 'success',
                  });
                })
                .catch((e) =>
                  setAlert({
                    message: e.response.data?.errors ?? '',
                    severity: 'error',
                  })
                );
            },
            title: 'Criar código de barras',
            allowedActions: [ItemBarcode.actions.create],
            selector: (row) => row.autoBarcode === false,
          },
          downloadBarcode: {
            icon: <CodeDownload />,
            onClick: (itemId) => {
              history.push(`/item/barcodeExport/${itemId}`);
            },
            title: 'Baixar código de barras',
            allowedActions: [ItemBarcode.actions.findById],
            selector: (row) => row.autoBarcode === true,
          },
          update: {
            onClick: (itemId) => history.push(`/item/update/${itemId}`),
          },
          showHistory: {
            icon: <ClockHistory />,
            onClick: (itemId) => {
              history.push(`/item/history/${itemId}`);
            },
            title: 'Histórico',
            allowedActions: [PurchaseEntry.actions.getAllByItem],
          },
          customDelete: {
            icon: <Close />,
            onClick: (modelId) => {
              restApiClient.item.delete(modelId).then(() => loadItems());
            },
            title: 'Desativar',
            selector: (item) =>
              !item?.deletedAt &&
              !rowsWithParent.includes(item.id) &&
              item.stock.reduce(
                (acc, item) => acc + item.availableQuantity,
                0
              ) === 0,
            allowedActions: [Item.actions.delete],
          },
        }}
        customCellRenderers={{
          hasConversion: {
            renderer: (field, value) => {
              return value ? <PackageIcon /> : '-';
            },
          },
          group: {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value.name;
              }
            },
          },
          price: {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          itemLocators: {
            renderer: (field, value) => {
              return value[0]?.locator ?? '-';
            },
          },
        }}
      />

      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>

      <LocatorItemDialog
        open={openLocator}
        itemId={selectedId}
        onCancel={() => setOpenLocator(false)}
        onSuccess={() => {
          setOpenLocator(false);
          loadItems();
        }}
      />
    </Container>
  );
}

export default ItemList;
