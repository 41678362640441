const PurchaseFile = {
  actions: {
    create: 'PurchaseFileController@submitFile',
    downloadFile: 'PurchaseFileController@downloadFile',
    getAllByPurchaseId: 'PurchaseFileController@getAllByPurchaseId',
    submitFile: 'PurchaseFileController@submitFile',
    delete: 'PurchaseFileController@delete',
  },
};
export default PurchaseFile;
