const UnitFinance = {
  schema: {
    type: 'object',
    properties: {
      unitId: {
        type: 'number',
        format: 'selectUnitDialog',
        customErrors: '',
      },
      holderName: {
        type: 'string',
        customErrors: '',
      },
      bank: {
        type: 'string',
        customErrors: '',
      },
      agency: {
        type: 'string',
        customErrors: '',
      },
      agencyDigit: {
        type: 'string',
        customErrors: '',
        description: 'Dígito da agência, caso não tenha deixe vazio!',
      },
      account: {
        type: 'string',
        customErrors: '',
      },
      accountDigit: {
        type: 'string',
        customErrors: '',
        description: 'Dígito da conta, caso não tenha deixe vazio!',
      },
      createdAt: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
    },
    required: ['unitId', 'holderName', 'bank', 'agency', 'account'],
  },
  uischema: {
    type: 'Group',
    label: 'Cadastro de Conta Bancária',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
          {
            type: 'Control',
            label: 'Data de Criação no Banco',
            scope: '#/properties/createdAt',
          },
          {
            type: 'Control',
            label: 'Titular',
            scope: '#/properties/holderName',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Banco',
            scope: '#/properties/bank',
          },
          {
            type: 'Control',
            label: 'Agência',
            scope: '#/properties/agency',
          },
          {
            type: 'Control',
            label: 'Dígito da Agência',
            scope: '#/properties/agencyDigit',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Conta',
            scope: '#/properties/account',
          },
          {
            type: 'Control',
            label: 'Dígito da Conta',
            scope: '#/properties/accountDigit',
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Conta Bancária',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Titular',
            scope: '#/properties/holderName',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Banco',
            scope: '#/properties/bank',
          },
          {
            type: 'Control',
            label: 'Agência',
            scope: '#/properties/agency',
          },
          {
            type: 'Control',
            label: 'Dígito da Agência',
            scope: '#/properties/agencyDigit',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Conta',
            scope: '#/properties/account',
          },
          {
            type: 'Control',
            label: 'Dígito da Conta',
            scope: '#/properties/accountDigit',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'holderName', label: 'Titular', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo de Despesa',
      minWidth: 100,
      align: 'center',
    },
    { id: 'bank', label: 'Banco', minWidth: 100, align: 'center' },
    {
      id: 'bankAccount.agency',
      label: 'Agência-Dígito',
      minWidth: 180,
      align: 'center',
    },
    {
      id: 'bankAccount.account',
      label: 'Conta-Dígito',
      minWidth: 180,
      align: 'center',
    },
    { id: 'balanceValue', label: 'Saldo', minWidth: 180, align: 'center' },
  ],
  consolidationTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'holderName', label: 'Titular', minWidth: 100, align: 'center' },
    { id: 'bank', label: 'Banco', minWidth: 100, align: 'center' },
    {
      id: 'bankAccount.agency',
      label: 'Agência-Dígito',
      minWidth: 180,
      align: 'center',
    },
    {
      id: 'bankAccount.account',
      label: 'Conta-Dígito',
      minWidth: 180,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'date',
      label: 'Última Consolidação',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'consolidatedBalanceValue',
      label: 'Valor Consolidado',
      minWidth: 100,
      align: 'center',
    },
  ],
  statementTableSchema: [
    { id: 'date', label: 'Data', minWidth: 100, align: 'center' },
    { id: 'type', label: 'Operação', minWidth: 100, align: 'center' },
    { id: 'operable', label: 'Fornecedor', minWidth: 100, align: 'center' },
    { id: 'operable.unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    { id: 'operable.observation', label: 'Observação', minWidth: 100, align: 'center' },
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
  ],
  financialCashtableSchema: [
    { id: 'type', label: 'Tipo', minWidth: 100, align: 'center' },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo de Despesa',
      minWidth: 100,
      align: 'center',
    },
    { id: 'balanceValue', label: 'Valor', minWidth: 100, align: 'center' },
  ],
  adjustmentsTableSchema: [
    { id: 'createdAt', label: 'Data', minWidth: 100, align: 'center' },
    {
      id: 'previousBalance',
      label: 'Saldo Inicial',
      minWidth: 100,
      align: 'center',
    },
    { id: 'newBalance', label: 'Saldo Final', minWidth: 100, align: 'center' },
    {
      id: 'responsible.user.name',
      label: 'Responsável',
      minWidth: 100,
      align: 'center',
    },
  ],
  dialogTableSchema: [
    {
      id: 'bankAccount.holderName',
      label: 'Titular',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    { id: 'type', label: 'Tipo', minWidth: 100, align: 'center' },
    { id: 'bankAccount.bank', label: 'Banco', minWidth: 100, align: 'center' },
    {
      id: 'bankAccount.agency',
      label: 'Agência',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'bankAccount.account',
      label: 'Conta',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    create: 'UnitFinanceController@create',
    update: 'UnitFinanceController@patchUpdate',
    findById: 'UnitFinanceController@findById',
    consolidate: 'UnitFinanceController@consolidate',
    findByIdWithHistory: 'UnitFinanceController@findByIdWithHistory',
    getAll: 'UnitFinanceController@getAll',
    getAllWithConsolidation: 'UnitFinanceController@getAllWithConsolidation',
    adjustBalance: 'UnitFinanceController@adjustBalance',
    getAdjustmentsHistory:
      'UnitFinanceBalanceHistoryController@getAllByUnitFinance',
    restore: 'UnitFinanceController@restore',
    delete: 'UnitFinanceController@delete',
  },
};

export default UnitFinance;
