const Buyer = {
  schema: {
    type: 'object',
    properties: {
      isLegalPerson: {
        type: 'boolean',
      },
      cpf: {
        type: 'string',
        mask: '999.999.999-99',
        minLength: 11,
        maxLength: 14,
        customErrors: '',
        cpfDentist: '',
      },
      cnpj: {
        type: 'string',
        mask: '99.999.999/9999-99',
        customErrors: '',
        cnpj: '',
        minLength: 14,
        maxLength: 18,
      },
      socialName: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com um Nome Social',
        customErrors: '',
      },
      fantasyName: {
        type: 'string',
        customErrors: '',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com um Nome Fantasia',
      },
      email: {
        type: 'string',
        format: 'email',
        customErrors: '',
      },
      phoneNumber: {
        type: 'string',
        mask: '(99) 9999-9999',
        maskSelector: (value) => {
          const len = value.replace(/[\W_]/g, '').length;
          if (len > 10) return '(99) 99999-9999';
          else return '(99) 9999-9999';
        },
        customErrors: '',
        phone: '',
      },
      alternativePhoneNumber: {
        type: 'string',
        mask: '(99) 9999-9999',
        maskSelector: (value) => {
          const len = value.replace(/[\W_]/g, '').length;
          if (len > 10) return '(99) 99999-9999';
          else return '(99) 9999-9999';
        },
        customErrors: '',
        phone: '',
      },
      buyerAddresses: {
        customErrors: '',
        items: {
          type: 'object',
          properties: {
            cep: {
              type: 'string',
              description: 'Digite somente números',
              mask: '99999-999',
              cep: '',
              minLength: 8,
              maxLength: 8,
              customErrors: '',
            },
            address: {
              type: 'string',
              customErrors: '',
            },
            number: {
              type: 'string',
              minLength: 1,
              maxLength: 8,
              customErrors: '',
            },
            complement: {
              type: ['string', 'null'],
              maxLength: 128,
              customErrors: '',
            },
            neighborhood: {
              type: 'string',
              customErrors: '',
            },
            city: {
              type: 'string',
              customErrors: '',
            },
            state: {
              type: 'string',
              customErrors: '',
            },
          },
          required: [
            'cep',
            'address',
            'number',
            'neighborhood',
            'city',
            'state',
          ],
        },
      },
    },
    required: ['socialName', 'phoneNumber', 'buyerAddresses'],
  },

  uischema: {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Group',
        label: 'Cadastro de Comprador',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'É Pessoa Jurídica?',
                scope: '#/properties/isLegalPerson',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Social',
                scope: '#/properties/socialName',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Fantasia*',
                scope: '#/properties/fantasyName',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isLegalPerson',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CPF',
                scope: '#/properties/cpf',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isLegalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CNPJ',
                scope: '#/properties/cnpj',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isLegalPerson',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Email',
                scope: '#/properties/email',
              },
              {
                type: 'Control',
                label: 'Telefone',
                scope: '#/properties/phoneNumber',
              },
              {
                type: 'Control',
                label: 'Telefone Alternativo',
                scope: '#/properties/alternativePhoneNumber',
              },
            ],
          },
        ],
      },
      {
        type: 'Group',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Endereço(s) de Entrega',
            },
            scope: '#/properties/buyerAddresses',
            options: {
              elementLabelProp: 'address',
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Cep',
                        scope: '#/properties/cep',
                      },
                      {
                        type: 'Control',
                        label: 'Endereço',
                        scope: '#/properties/address',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Número',
                        scope: '#/properties/number',
                      },
                      {
                        type: 'Control',
                        label: 'Complemento',
                        scope: '#/properties/complement',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Bairro',
                        scope: '#/properties/neighborhood',
                      },
                      {
                        type: 'Control',
                        label: 'Município',
                        scope: '#/properties/city',
                      },
                      {
                        type: 'Control',
                        label: 'Estado',
                        scope: '#/properties/state',
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    ],
  },

  updateUischema: {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Group',
        label: 'Edição de Comprador',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Social',
                scope: '#/properties/socialName',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome Fantasia*',
                scope: '#/properties/fantasyName',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isLegalPerson',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CPF',
                scope: '#/properties/cpf',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isLegalPerson',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'CNPJ',
                scope: '#/properties/cnpj',
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: '#/properties/isLegalPerson',
                    schema: {
                      const: false,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Email',
                scope: '#/properties/email',
              },
              {
                type: 'Control',
                label: 'Telefone',
                scope: '#/properties/phoneNumber',
              },
              {
                type: 'Control',
                label: 'Telefone Alternativo',
                scope: '#/properties/alternativePhoneNumber',
              },
            ],
          },
        ],
      },
      {
        type: 'Group',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Endereço(s) de Entrega',
            },
            scope: '#/properties/buyerAddresses',
            options: {
              elementLabelProp: 'address',
              showSortButtons: true,
              detail: {
                type: 'VerticalLayout',
                elements: [
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Cep',
                        scope: '#/properties/cep',
                      },
                      {
                        type: 'Control',
                        label: 'Endereço',
                        scope: '#/properties/address',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Número',
                        scope: '#/properties/number',
                      },
                      {
                        type: 'Control',
                        label: 'Complemento',
                        scope: '#/properties/complement',
                      },
                    ],
                  },
                  {
                    type: 'HorizontalLayout',
                    elements: [
                      {
                        type: 'Control',
                        label: 'Bairro',
                        scope: '#/properties/neighborhood',
                      },
                      {
                        type: 'Control',
                        label: 'Município',
                        scope: '#/properties/city',
                      },
                      {
                        type: 'Control',
                        label: 'Estado',
                        scope: '#/properties/state',
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    ],
  },

  tableSchema: [
    { id: 'socialName', label: 'Nome Social', minWidth: 100, align: 'center' },
    {
      id: 'fantasyName',
      label: 'Nome Fantasia',
      minWidth: 100,
      align: 'center',
    },
  ],
  tableSchemaAddresses: [
    { id: 'cep', label: 'CEP', minWidth: 100, align: 'center' },
    {
      id: 'address',
      label: 'Endereço',
      minWidth: 100,
      align: 'center',
    },
    { id: 'number', label: 'Número', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'BuyerController@getAll',
    findById: 'BuyerController@findById',
    create: 'BuyerController@create',
    update: 'BuyerController@patchUpdate',
    delete: 'BuyerController@delete',
    getAllByBuyerId: 'BuyerAddressController@getAllByBuyerId',
    findByIdBuyerAddress: 'BuyerAddressController@findById',
  },
  data: {
    isLegalPerson: false,
    cnpj: undefined,
    cpf: undefined,
    fantasyName: undefined,
    buyerAddresses: [{}],
  },
};

export default Buyer;
