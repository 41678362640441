import customMaterialSelectRenderer from './customMaterialSelectRenderer';
import customSelectItemDialogRenderer from './customSelectItemDialogRenderer';
import customSelectGroupDialogRenderer from './customSelectGroupDialogRenderer';
import customSelectCityDialogRenderer from './customSelectCityDialogRenderer';
import customSelectUnitDialogRenderer from './customSelectUnitDialogRenderer';
import customSelectClinicDialogRenderer from './customSelectClinicDialogRenderer';
import customSelectBuyerDialogRenderer from './customSelectBuyerDialogRenderer';
import customSelectLabEmployeeDialogRenderer from './customSelectLabEmployeeDialogRenderer';
import customSelectStepDialogRenderer from './customSelectStepDialogRenderer';
import customInputTextControl from './customInputTextControl';
import customMoneyInputControl from './customMoneyInputControl';
import customFloatInputControl from './customFloatInputControl';
import customSelectSectionDialogRenderer from './customSelectSectionDialogRenderer';
import customSelectEndProductDialogRenderer from './customSelectEndProductDialogRenderer';
import customSelectToothNumberRenderer from './customSelectToothNumberRenderer';
import customSelectToothShadeDialogRenderer from './customSelectToothShadeDialogRenderer';
import customSelectDentistRenderer from './customSelectDentistRenderer';
import customSelectStatusRenderer from './customSelectStatusRenderer';
import customSelectOriginDialogRenderer from './customSelectOriginDialogRenderer';
import customSelectExecutorDialogRenderer from './customSelectExecutorDialogRenderer';
import customSelectTreatmentDialogRenderer from './customSelectTreatmentDialogRenderer';
import customSelectAppointmentDialogRenderer from './customSelectAppointmentDialogRenderer';
import customColorPickerRenderer from './customColorPickerControlRenderer';
import customSelectSpecialtyDialogRenderer from './customSelectSpecialtyDialogRenderer';
import customTimeInputControl from './customTimeInputControl';
import customEnumArrayRenderer from './customEnumArrayRenderer';
import customSelectProcedureDialogRenderer from './customSelectProcedureDialogRenderer';
import customEnumRenderer from './customEnumRenderer';
import customAttendanceDateControlRenderer from './customAttendanceDateControlRenderer';
import customSelectEmployeeDialogRenderer from './customSelectEmployeeDialogRenderer';
import customSelectServiceSupplierDialogRenderer from './customSelectServiceSupplierDialogRenderer';
import customSelectSupplierDialogRenderer from './customSelectSupplierDialogRenderer';
import customSelectAnySupplierDialogRenderer from './customSelectAnySupplierDialogRenderer';
import customSelectOutsourcedDialogRenderer from './customSelectOutsourcedDialogRenderer';
import customSelectPaymentTypeDialogRenderer from './customSelectPaymentTypeDialogRenderer';
import customSelectExpenseTypeDialogRenderer from './customSelectExpenseTypeDialogRenderer';
import customSelectSectorDialogRenderer from './customSelectSectorDialogRenderer';
import customMaterialArrayLayoutRenderer from './customMaterialArrayLayoutRenderer';
import customSelectUnitFinanceDialogRenderer from './customSelectUnitFinanceDialogRenderer';
import customSelectPatientDialogRenderer from './customSelectPatientDialogRenderer';
import customSelectDentistSpecialtyDialogRenderer from './customSelectDentistSpecialtyDialogRenderer';
import customPercentageInputControl from './customPercentageInputControl';

export const customRenderers = [
  customMaterialSelectRenderer,
  customSelectItemDialogRenderer,
  customSelectGroupDialogRenderer,
  customSelectCityDialogRenderer,
  customSelectUnitDialogRenderer,
  customSelectClinicDialogRenderer,
  customInputTextControl,
  customSelectBuyerDialogRenderer,
  customSelectLabEmployeeDialogRenderer,
  customSelectStepDialogRenderer,
  customMoneyInputControl,
  customFloatInputControl,
  customSelectSectionDialogRenderer,
  customSelectEndProductDialogRenderer,
  customSelectToothNumberRenderer,
  customSelectToothShadeDialogRenderer,
  customSelectOriginDialogRenderer,
  customSelectDentistRenderer,
  customSelectStatusRenderer,
  customSelectExecutorDialogRenderer,
  customSelectTreatmentDialogRenderer,
  customSelectAppointmentDialogRenderer,
  customColorPickerRenderer,
  customSelectSpecialtyDialogRenderer,
  customTimeInputControl,
  customEnumArrayRenderer,
  customSelectProcedureDialogRenderer,
  customEnumRenderer,
  customAttendanceDateControlRenderer,
  customSelectEmployeeDialogRenderer,
  customSelectServiceSupplierDialogRenderer,
  customSelectSupplierDialogRenderer,
  customSelectAnySupplierDialogRenderer,
  customSelectOutsourcedDialogRenderer,
  customSelectPaymentTypeDialogRenderer,
  customSelectExpenseTypeDialogRenderer,
  customSelectSectorDialogRenderer,
  customMaterialArrayLayoutRenderer,
  customSelectUnitFinanceDialogRenderer,
  customSelectPatientDialogRenderer,
  customSelectDentistSpecialtyDialogRenderer,
  customPercentageInputControl,
];

export const customCells = [
  customSelectLabEmployeeDialogRenderer,
  customSelectStepDialogRenderer,
  customInputTextControl,
];
