/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  CalendarPlus,
  Container,
  SchedulerContainer,
  Dialog,
  DialogContent,
} from './styles';
import { FormHelperText, Paper } from '@material-ui/core';
import restApiClient from '../../services/restApiClient';
import { isEmpty, isNil } from 'lodash';
import AdornedTextField from '../adornedTextField';
import moment from 'moment';
import { Unwrapped } from '@jsonforms/material-renderers';
import SchedulerComponent from '../schedulerComponent';

const { MaterialDateTimeControl } = Unwrapped;

function SelectAppointmentControl(props) {
  const [open, setOpen] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  useEffect(() => {
    if (props.config.completeData.treatmentId) {
      restApiClient.treatment
        .findById(props.config.completeData.treatmentId)
        .then((e) => {
          setSelectedTreatment(e.data);
        });
    }
  }, [props.config.completeData.treatmentId]);

  return !isNil(selectedTreatment) &&
    selectedTreatment.considerTime &&
    !props.config.completeData.fit &&
    props.config.unitType === 'radiology' ? (
    <Container>
      <AdornedTextField
        disabled={!props.enabled}
        error={!isEmpty(props.errors)}
        adornment={<CalendarPlus />}
        clickableAdornment
        onAdornmentClick={(x) => setOpen(true)}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={() => {
          if (props.enabled) {
            setOpen(true);
          }
        }}
        label="Calendário"
        InputLabelProps={{
          shrink: true,
          readOnly: true,
        }}
        value={
          !isEmpty(props.data)
            ? moment(props.data).format('DD/MM/YYYY HH:mm')
            : ''
        }
        helperText={
          !isEmpty(props.errors) ? (
            <FormHelperText error>{`${props.errors}`}</FormHelperText>
          ) : (
            ''
          )
        }
      />
      <Dialog open={open} onClose={(X) => setOpen(false)} maxWidth={false}>
        <DialogContent>
          <Paper>
            <SchedulerContainer>
              <SchedulerComponent
                treatment={selectedTreatment}
                onSuccess={(scheduling) => {
                  props.handleChange?.(
                    props.path,
                    scheduling
                  );
                  props.onSuccess?.(props.id);
                  setOpen(false);
                }}
              />
            </SchedulerContainer>
          </Paper>
        </DialogContent>
      </Dialog>
    </Container>
  ) : (
    <MaterialDateTimeControl {...props} />
  );
}

export default SelectAppointmentControl;
