import styled from 'styled-components';
import { DialogContent as MaterialDialogContent } from '@material-ui/core';
import CreateUpdateForm from '../createUpdateForm';

export const Container = styled.div`
`;

export const DialogContent = styled(MaterialDialogContent)`
  width: 450px !important;
  margin-bottom: 30px !important;
  padding: 0px !important;
`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)`
`;
