import {
  Paper,
  AddBox,
  EditBox,
  Container,
  CustomTreeView,
  HeaderContainer,
  ContentContainer,
  IconButton,
} from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import SecuredItem from '../../components/securedItem';
import restApiClient from '../../services/restApiClient';
import CreateUpdateGroupDialog from '../../components/createUpdateGroupDialog';
import TransferType from '../../forms/transferType';

function TransferTypeView() {
  const [data, setData] = useState({});
  const [parentId, setParentId] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState({
    key: '',
    actionLabel: '',
    depthLabel: '',
  });

  const loadTransferTypes = useCallback(() => {
    restApiClient.transferType.getAllTree().then((e) => {
      setData(e.data);
    });
  }, []);

  useEffect(() => {
    loadTransferTypes();
  }, [loadTransferTypes]);

  function switchLabel(depth, actionKey) {
    let depthLabel, actionLabel;

    if (actionKey === 'add') {
      actionLabel = 'Cadastro';
    } else if (actionKey === 'edit') {
      actionLabel = 'Edição';
    }

    switch (depth) {
      default:
        depthLabel = 'Tipo de Transferência';
        break;
    }

    setAction({
      key: actionKey,
      actionLabel: actionLabel,
      depthLabel: depthLabel,
    });
  }

  return (
    <Container>
      <Paper>
        <HeaderContainer>
          <h1>Tipos de Transferências</h1>
          <SecuredItem allowedActions={[TransferType.actions.create]}>
            <IconButton
              onClick={() => {
                switchLabel(-1, 'add');
                setParentId(undefined);
                setOpen(true);
              }}
              title={'Adicionar Tipo de Transferência'}
            >
              <AddBox />
            </IconButton>
          </SecuredItem>
        </HeaderContainer>

        <ContentContainer>
          <CustomTreeView
            actions={{
              edit: {
                icon: <EditBox />,
                onClick: (id, depth, actionKey) => {
                  switchLabel(depth - 1, actionKey);
                  setParentId(id);
                  setOpen(true);
                },
                maxDepth: 2,
                title: 'Editar',
                allowedActions: [
                  [TransferType.actions.findById, TransferType.actions.update],
                ],
              },
            }}
            data={data}
          />
        </ContentContainer>
      </Paper>

      <CreateUpdateGroupDialog
        open={open}
        action={action}
        parentId={parentId}
        restModel={restApiClient.transferType}
        onCancel={() => {
          setOpen(false);
          setParentId(undefined);
        }}
        onSuccess={() => {
          loadTransferTypes();
          setOpen(false);
        }}
      />
    </Container>
  );
}

export default TransferTypeView;
