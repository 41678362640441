import styled from 'styled-components';
import MaterialButton from '@material-ui/core/Button';
import SelectList from '../selectList';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UnassignedSelectList = styled(SelectList)`
  width: 90%;
  height: 90%;
  margin: 15px;
  overflow: auto;
  box-shadow: 0px 2px 1px -1px rgba(255, 0, 0, 0.2),
    0px 1px 1px 1px rgba(255, 0, 0, 0.14), 0px 1px 3px 1px rgba(255, 0, 0, 0.12) !important;
`;

export const AssignedSelectList = styled(SelectList)`
  width: 90%;
  height: 90%;
  margin: 15px;
  overflow: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 255, 0.2),
    0px 1px 1px 1px rgba(0, 0, 255, 0.14), 0px 1px 3px 1px rgba(0, 0, 255, 0.12) !important;
`;

export const Button = styled(MaterialButton)`
  margin-bottom: 5px !important;
`;
