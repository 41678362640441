import React from 'react';
import SecuredItem from '../securedItem';
import { ListItem } from './styles';
import { useRouteMatch } from 'react-router-dom';

function SecuredListItem({
  allowedRoles = [],
  allowedActions = [],
  allowDentist = undefined,
  allowCustom = undefined,
  ...props
}) {
  const match = !!useRouteMatch(props?.targetPath);

  return (
    <SecuredItem
      allowedRoles={allowedRoles}
      allowedActions={allowedActions}
      allowDentist={allowDentist}
      allowCustom={allowCustom}
    >
      <ListItem selected={match} {...props}>
        {props.children}
      </ListItem>
    </SecuredItem>
  );
}

export default SecuredListItem;
