import React, { useState, useEffect } from 'react';
import BudgetItem from '../../../forms/budgetItem';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import {
  Container,
  CancelButton,
  Checkbox,
  Import,
  ImportCsvButton,
} from './styles';
import isEmpty from 'lodash/isEmpty';
import ErrorDialog from '../../../components/errorDialog';
import SubmitDialog from '../../../components/submitDialog';

function ImportItemsList({ match, location, history }) {
  const previousBudgetId = match.params?.id;
  const crrBudgetId = location.state?.crrBudget;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [itemList, setItemList] = useState([]);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [error, setError] = useState(false);
  const columns = BudgetItem.importItemsTableSchema;

  useEffect(() => {
    restApiClient.budgetItem
      .getAllByBudgetId(
        previousBudgetId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('item.id', searchBy)
              .or()
              .like('item.name', searchBy)
              .or()
              .like('item.group.name', searchBy)
              .or()
              .like('item.group.parent.name', searchBy)
              .or()
              .like('item.group.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, previousBudgetId]);

  return (
    <Container>
      <CrudTable
        title={`Itens do Orçamento #${previousBudgetId}`}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
            <ImportCsvButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Import />}
              onClick={() => {
                if (!isEmpty(itemList)) {
                  setOpenSubmitDialog(true);
                } else {
                  setError('Nenhum item selecionado');
                }
              }}
            >
              Importar Itens
            </ImportCsvButton>
          </>
        }
        modelActions={BudgetItem.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          enabled: {
            renderer: (field, value, column, row) => {
              let enabled;
              let itemFind = itemList.find((x) => x?.itemId === row?.itemId);

              if (itemFind) enabled = itemFind?.enabled;
              else enabled = value;
              return (
                <Checkbox
                  key={row.id}
                  checked={enabled || false}
                  onChange={(e, value) => {
                    const newList = [...itemList];
                    let index = newList.findIndex(
                      (x) => x?.itemId === row?.itemId
                    );

                    if (index >= 0) {
                      newList.splice(index, 1);
                    } else {
                      const item = {
                        id: row.id,
                        itemId: row.itemId,
                        quantity: row.itemQuantity,
                        price: row.price,
                        enabled: value,
                      };
                      newList.push(item);
                    }
                    row.enabled = value;
                    setItemList(newList);
                  }}
                />
              );
            },
          },
          price: {
            renderer: (field, value) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          'item.group': {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value?.name;
              }
            },
          },
        }}
      />

      <SubmitDialog
        open={openSubmitDialog}
        onCancel={() => setOpenSubmitDialog(false)}
        onSuccess={() => {
          setOpenSubmitDialog(false);
          restApiClient.budget
            .importItems(crrBudgetId, {
              budgetItems: itemList,
            })
            .then(() => history.goBack());
        }}
        dialogText={'Tem certeza que deseja realizar a importação dos itens?'}
      />

      <ErrorDialog
        open={!!error}
        title="Erro"
        error={error}
        onClose={() => setError(false)}
      />
    </Container>
  );
}

export default ImportItemsList;
