import React, { useState, useEffect, useCallback } from 'react';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import PrintOrderDialog from '../../../../components/printOrderDialog';
import PrintTransferDialog from '../../../../components/printTransferDialog';
import FilterTree from '../../../../helpers/filterTree';
import { Container, AddBox, GetApp, ViewShow, Printer } from './styles';
import moment from 'moment';
import Order from '../../../../forms/order';
import OrderItem from '../../../../forms/orderItem';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function TransfersList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ submissionDate: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openOrder, setOpenOrder] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const columns = Order.tableSchema;

  async function loadOrders() {
    await restApiClient.order
      .getAllTransferOrders(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('toUnit.name', searchBy)
              .or()
              .like('employee.user.name', searchBy)
              .or()
              .like('submissionDate', searchBy)
              .or()
              .like('transferType.name', searchBy)
              .or()
              .like('sendDate', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }

  const handleAlertClose = useCallback(() => {
    setErrorMessage('');
  }, []);

  useEffect(() => {
    loadOrders();
  }, [rowsPerPage, page, orderBy, searchBy, submitConfirmationId]);

  return (
    <Container>
      <CrudTable
        title={'Transferências'}
        modelActions={Order.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: <span>Você tem certeza que deseja enviar o pedido?</span>,
            onSuccess: (id) => {
              if (!fetching) {
                setFetching(true);
                restApiClient.order
                  .approveTransferItem(id)
                  .then(() => {
                    setSubmitConfirmationId(-1);
                    setFetching(false);
                  })
                  .catch((e) => setErrorMessage(e.response.data?.errors ?? ''));
              }
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          separate: {
            icon: <AddBox />,
            onClick: (id) => history.push(`/stock/order/separateItems/${id}`),
            selector: (row) => row.status !== 'Enviado',
            title: 'Separar pedido',
            allowedActions: [
              [
                Order.actions.findById,
                Order.actions.separateOrder,
                OrderItem.actions.getAllByOrderWithItemStock,
              ],
            ],
          },
          send: {
            icon: <GetApp />,
            selector: (row) => row.status === 'Em separação',
            onClick: (id) => {
              setSubmitConfirmationId(id);
            },
            title: 'Enviar pedido',
            allowedActions: [Order.actions.approveTransfer],
          },
          view: {
            icon: <ViewShow />,
            selector: (row) => row.status === 'Enviado',
            onClick: (id) => {
              history.push(`/stock/order/view/${id}`);
            },
            title: 'Visualizar',
            allowedActions: [OrderItem.actions.getAllByOrderWithItemStock],
          },
          printOrder: {
            icon: <Printer />,
            selector: (row) =>
              row.status === 'Submetido' || row.status === 'Em separação',
            onClick: (id) => {
              setSelectedTransfer(id);
              setOpenOrder(true);
            },
            title: 'Imprimir Pedido',
            allowedActions: [Order.actions.findByIdByEmployee],
          },
          printTransfer: {
            icon: <Printer />,
            selector: (row) => row.status === 'Enviado',
            onClick: (id) => {
              setSelectedTransfer(id);
              setOpenTransfer(true);
            },
            title: 'Imprimir Transferência',
            allowedActions: [OrderItem.actions.getAllByOrderWithItemStock],
          },
        }}
        customCellRenderers={{
          'transferType.name': {
            renderer: (field, value) => {
              return value ? value : '-';
            },
          },
          submissionDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
          sendDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
          orderItem: {
            renderer: (field, value, column, row) => {
              var newValue = '-';
              if (row.status == 'Enviado') {
                newValue = value
                  .reduce((num, x) => {
                    if (x.orderId === row.id) {
                      return num + x?.approvedQuantity * x?.price;
                    }
                    return num;
                  }, 0)
                  .toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  });
              }
              return newValue;
            },
          },
        }}
      />

      <PrintOrderDialog
        open={openOrder}
        selectedTransfer={selectedTransfer}
        onCancel={() => {
          setSelectedTransfer(null);
          setOpenOrder(false);
        }}
      />

      <PrintTransferDialog
        open={openTransfer}
        selectedTransfer={selectedTransfer}
        onCancel={() => {
          setSelectedTransfer(null);
          setOpenTransfer(false);
        }}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default TransfersList;
