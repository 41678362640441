import React, { useState, useEffect, useRef } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import restApiClient from '../../../services/restApiClient';
import Barcode from 'react-barcode';
import {
  Container,
  AutoDiv,
  ExportPngButton,
  FilePng,
  CancelButton,
  FooterContainer,
  HeaderContainer,
} from './styles';

function generateBarcodeToPrint(barcode) {
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <AutoDiv ref={ref}>
      <Barcode value={barcode} />
    </AutoDiv>
  ));

  return ComponentToPrint;
}

function BarcodeExport({ history, match, ...props }) {
  const itemId = match.params?.id;
  const [item, setItem] = useState({});
  const [barcode, setBarcode] = useState({});
  const componentRef = useRef();
  const BarcodeToPrint = generateBarcodeToPrint(barcode);

  useEffect(() => {
    restApiClient.item.findById(itemId).then((e) => {
      setItem(e.data);
      var barcodeFind = e.data.barcode.find((x) => x.autoBarcode === true);
      setBarcode(barcodeFind.barcode);
    });
  }, [itemId]);

  return (
    <Container>
      <HeaderContainer>
        <h1>Código de barras automático - {item.name}</h1>
      </HeaderContainer>
      <AutoDiv ref={componentRef}>
        <BarcodeToPrint barcode={barcode} />
      </AutoDiv>
      <FooterContainer>
        <ExportPngButton
          variant="contained"
          color="primary"
          startIcon={<FilePng />}
          onClick={() =>
            exportComponentAsPNG(componentRef, {
              fileName: `${item.id}_${item.name}`.replace(/[^a-z0-9]/gi, '-'),
              html2CanvasOptions: {
                scale: 2,
              },
            })
          }
        >
          Exportar para png
        </ExportPngButton>
        <CancelButton
          onClick={() => history.goBack()}
          type="button"
          variant="contained"
          color="primary"
        >
          Voltar
        </CancelButton>
      </FooterContainer>
    </Container>
  );
}

export default BarcodeExport;
