import React, { useState } from 'react';
import Expense from '../../../../forms/expense';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import {
  Container,
  BackButton,
  FooterContainer,
  SubmitButton,
  CancelButton,
} from './styles';
import ExpenseInstallmentDialog from '../../../../components/expenseInstallmentDialog';
import SubmitDialog from '../../../../components/submitDialog';
import CheckDuplicateDialog from '../../../../components/checkDuplicateDialog';
import ExpenseApportionDialog from '../../../../components/expenseApportionDialog';
import first from 'lodash/first';

function ExpenseCreateUpdate(props) {
  const expenseId = parseInt(props.match?.params?.id ?? null);
  const readonly = props.location?.state?.readonly ?? false;
  const [openExpenseInstallment, setOpenExpenseInstallment] = useState(false);
  const [openExpenseApportion, setOpenExpenseApportion] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [expense, setExpense] = useState({});
  const [labelButton, setLabelButton] = useState(null);
  const [totalValue, setTotalValue] = useState(null);
  const [baseDate, setBaseDate] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [submitText, setSubmitText] = useState(null);
  const [confirmSubmit, setConfirmSubmit] = useState(() => {});
  let duplicate = false;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Expense}
        restGetDataById={async (id) => {
          const result = await restApiClient.expense.findById(id);
          result.data.supplierId = result.data.expensableId;
          result.data.agroup = result.data?.groupedExpenseCount > 0;
          result.data.unitFinanceId = first(
            result.data?.operable
          )?.unitFinanceId;

          return result;
        }}
        schemaSelector={(modelSchema, modelId) =>
          modelId ? modelSchema.updateSchema : modelSchema.schema
        }
        restCreateData={async (expense) => {
          switch (expense.responsableType) {
            case 'Funcionário':
              expense.patientId = undefined;
              expense.dentistId = undefined;
              expense.supplierId = undefined;
              break;
            case 'Fornecedor':
              expense.patientId = undefined;
              expense.dentistId = undefined;
              expense.employeeId = undefined;
              break;
            case 'Paciente':
              expense.employeeId = undefined;
              expense.dentistId = undefined;
              expense.supplierId = undefined;
              break;
            case 'Dentista':
              expense.patientId = undefined;
              expense.employeeId = undefined;
              expense.supplierId = undefined;
              break;
            default:
              expense.patientId = undefined;
              expense.employeeId = undefined;
              expense.dentistId = undefined;
              expense.supplierId = undefined;
              break;
          }

          if (!expense.expensePaid) {
            expense.payday = undefined;
          }

          // Verifica se despesa similar já foi cadastrada
          if (!openDuplicateDialog) {
            const checkDuplicate =
              await restApiClient.expense.checkDuplicateExpense(expense);
            if (checkDuplicate.data.result) {
              duplicate = true;
              setOpenDuplicateDialog(true);
              setSubmitText(
                'Atenção, essa despesa já está cadastrada! Deseja continuar?'
              );
              setExpense({ ...expense });
              return;
            }
          } else {
            setOpenDuplicateDialog(false);
          }

          //Parcelamento de despesa
          if (expense?.installment) {
            setExpense(expense);
            setTotalValue(expense.value);
            setBaseDate(expense.dueDate);
            setOpenExpenseInstallment(true);
          }
          // Rateio de despesa
          else if (expense?.apportion) {
            if (expense.value && expense.dueDate) {
              setExpense(expense);
              setTotalValue(expense.value);
              setBaseDate(expense.dueDate);
              setSelectedUnit(expense.unitId);
              setOpenExpenseApportion(true);
            }
          } else {
            const result = await restApiClient.expense.create(expense);
            return result;
          }
        }}
        onCreateSuccess={(request, data) => {
          if (data === 'submit' && !duplicate) props.history.goBack();
        }}
        restUpdateData={async (modelId, oldExpense, expense) => {
          let agroup = expense.agroup;
          delete expense.expensePaid;
          delete oldExpense.expensePaid;
          delete expense.readonly;
          delete oldExpense.readonly;
          delete expense.requestingUnitBoolean;
          delete oldExpense.requestingUnitBoolean;
          delete expense.recurrentExpense;
          delete oldExpense.recurrentExpense;
          delete expense.installment;
          delete oldExpense.installment;
          delete expense.employeeExpense;
          delete oldExpense.employeeExpense;
          delete expense.unitFinanceId;
          delete oldExpense.unitFinanceId;
          delete expense.disableExpensePaid;
          delete oldExpense.disableExpensePaid;
          delete expense.disableApportion;
          delete oldExpense.disableApportion;
          delete expense.disableInstallment;
          delete oldExpense.disableInstallment;
          delete expense.hideAgroup;
          delete oldExpense.hideAgroup;

          // Caso despesa possua rateio
          if (expense.apportion && expense.value && expense.dueDate) {
            setExpense(expense);
            setTotalValue(expense.value);
            setBaseDate(expense.dueDate);
            setSelectedUnit(expense.unitId);
            setOpenExpenseApportion(true);
            return;
          }

          delete expense.agroup;
          delete oldExpense.agroup;
          delete expense.supplierId;
          delete oldExpense.supplierId;
          delete expense.apportion;
          delete oldExpense.apportion;

          const result = restApiClient.expense
            .update(modelId, oldExpense, expense)
            .then((result) => {
              restApiClient.expense.agroupExpense(result.data.id, {
                agroup,
                previousValue: oldExpense.value,
              });
              props.history.goBack();
            });
          return result;
        }}
        modelData={{
          employeeId: undefined,
          supplierId: undefined,
          patientId: undefined,
          dentistId: undefined,
          expensePaid: false,
          readonly,
          recurrentExpense: false,
          installment: false,
          apportion: false,
          dueDate: undefined,
          value: undefined,
          hideAgroup: false,
        }}
        clearOnSuccess={!labelButton}
        onChange={(data) => {
          if (data.unitId) {
            setSelectedUnit(data.unitId);
          }
          if (data.installment) {
            data.disableExpensePaid = true;
            data.disableApportion = true;
            data.hideAgroup = true;
            setLabelButton('Parcelar Despesa');
          } else if (data.apportion) {
            data.disableExpensePaid = true;
            data.disableInstallment = true;
            setLabelButton('Ratear Despesa');
          } else if (data.expensePaid) {
            data.disableInstallment = true;
            data.disableApportion = true;
          } else {
            data.expensePaid = false;
            data.unitFinanceId = undefined;
            data.recurrentExpense = false;
            data.disableInstallment = false;
            data.disableApportion = false;
            data.disableExpensePaid = false;
            data.hideAgroup = false;
            setLabelButton(null);
          }

          if (data.agroup) {
            data.disableInstallment = true;
          }
        }}
        customButtonsBuilder={
          readonly
            ? () => (
                <>
                  <FooterContainer>
                    <BackButton
                      onClick={() => props.history.goBack()}
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      {'Voltar'}
                    </BackButton>
                  </FooterContainer>
                </>
              )
            : labelButton
            ? (submit) => (
                <>
                  <CancelButton
                    onClick={() => props.history.goBack()}
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Cancelar
                  </CancelButton>
                  <SubmitButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => submit()}
                  >
                    {labelButton}
                  </SubmitButton>
                </>
              )
            : !expenseId
            ? (submit) => (
                <>
                  <CancelButton
                    onClick={() => props.history.goBack()}
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Cancelar
                  </CancelButton>
                  <SubmitButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setConfirmSubmit(() => () => submit('submit'));
                      submit('submit');
                    }}
                  >
                    Cadastrar
                  </SubmitButton>
                  <SubmitButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setConfirmSubmit(() => () => submit());
                      submit();
                    }}
                  >
                    Cadastrar e Continuar
                  </SubmitButton>
                </>
              )
            : null
        }
        {...props}
      />

      <ExpenseInstallmentDialog
        open={openExpenseInstallment}
        total={totalValue}
        date={baseDate}
        onCancel={() => {
          setOpenExpenseInstallment(false);
          setBaseDate(null);
          setTotalValue(0);
        }}
        onSuccess={(installments) => {
          setOpenSubmitDialog(true);
          setSubmitText(
            'Atenção! Este pagamento está criando despesas parceladas, deseja continuar?'
          );
          setExpense({ ...expense, ...installments });
        }}
      />

      <ExpenseApportionDialog
        open={openExpenseApportion}
        total={totalValue}
        date={baseDate}
        selectedUnit={selectedUnit}
        onCancel={() => {
          setOpenExpenseApportion(false);
          setBaseDate(null);
          setTotalValue(0);
        }}
        onSuccess={async (apportions) => {
          setOpenSubmitDialog(true);
          setSubmitText(
            'Atenção! Esta despesa está sendo rateada entre várias unidades, deseja continuar?'
          );
          setExpense({ ...expense, ...apportions });
        }}
      />

      <SubmitDialog
        open={openSubmitDialog}
        onCancel={() => setOpenSubmitDialog(false)}
        onSuccess={async () => {
          if (expenseId) {
            await restApiClient.expense.updateExpenseWithApportion(
              expenseId,
              expense
            );
            setOpenExpenseApportion(false);
          } else {
            await restApiClient.expense.create(expense);
          }
          setOpenSubmitDialog(false);
          props.history.goBack();
        }}
        dialogText={submitText}
      />

      <CheckDuplicateDialog
        open={openDuplicateDialog}
        onCancel={() => setOpenDuplicateDialog(false)}
        onSuccess={async () => confirmSubmit()}
        dialogText={submitText}
      />
    </Container>
  );
}

export default ExpenseCreateUpdate;
