import React, { useState, useEffect } from 'react';
import ServiceSupplier from '../../../../../forms/serviceSupplier';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, Close, Restore } from './styles';

function ServiceSupplierList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ socialName: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = ServiceSupplier.tableSchema;

  useEffect(() => {
    restApiClient.serviceSupplier
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('socialName', searchBy)
              .or()
              .like('fantasyName', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Fornecedores de Serviços'}
        modelActions={ServiceSupplier.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () =>
              history.push('/financial/config/serviceSupplier/create'),
          },
          update: {
            onClick: (serviceSupplierId) =>
              history.push(
                `/financial/config/serviceSupplier/update/${serviceSupplierId}`
              ),
            selector: (row) => !row?.deletedAt,
          },
          customDelete: {
            icon: <Close />,
            onClick: (serviceSupplierId, index) => {
              restApiClient.serviceSupplier
                .delete(serviceSupplierId)
                .then(() => {
                  const result = { ...rows };
                  const restored = result.values.find(
                    (x) => x.addressId === serviceSupplierId
                  );
                  restored.deletedAt = 'datanova';
                  setRows(result);
                });
            },
            title: 'Desativar',
            selector: (row) => !row?.deletedAt,
            allowedActions: [[ServiceSupplier.actions.delete]],
          },
          restore: {
            icon: <Restore />,
            onClick: (supplierId, index) => {
              restApiClient.serviceSupplier.restore(supplierId).then(() => {
                const result = { ...rows };
                const restored = result.values.find(
                  (x) => x.addressId === supplierId
                );
                restored.deletedAt = null;
                setRows(result);
              });
            },
            title: 'Ativar',
            selector: (row) => row?.deletedAt,
            allowedActions: [[ServiceSupplier.actions.restore]],
          },
        }}
        customCellRenderers={{
          fantasyName: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default ServiceSupplierList;
