import styled from 'styled-components';
import { Numbers as MaterialNumbers } from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Numbers = styled(MaterialNumbers)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
