import React, { useState, useEffect, useCallback } from 'react';
import CashRegisterValue from '../../../../forms/cashRegisterValue';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import SelectServiceSupplierDialogControl from '../../../../components/selectServiceSupplierDialogControl';
import SelectExpenseTypeDialogControl from '../../../../components/selectExpenseTypeDialogControl';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  ErrorContainer,
  AddToList,
  ImportCsvButton,
  Import,
  PlaceholderContainer,
} from './styles';
import ImportCardCsvDialog from '../../../../components/importCardCsvDialog';
import first from 'lodash/first';

function CashRegisterCardList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [confirmationErrors, setConfirmationErrors] = useState(null);
  const [paymentErrors, setPaymentErrors] = useState(null);
  const [selectedServiceSupplier, setSelectedServiceSupplier] = useState(null);
  const [serviceSupplierError, setServiceSupplierError] = useState(null);
  const [selectedExpenseType, setSelectedExpenseType] = useState(null);
  const [expenseTypeError, setExpenseTypeError] = useState(null);

  const columns = CashRegisterValue.tableSchema;

  const loadcashRegisterValues = useCallback(() => {
    restApiClient.cashRegisterValue
      .getAllCards(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('status', searchBy)
              .or()
              .like('paymentType.name', searchBy)
              .or()
              .like('cashRegisterClose.date', searchBy)
              .or()
              .like('cashRegisterClose.number_cash_register', searchBy)
              .or()
              .like('cashRegisterClose.unit.name', searchBy)
              .or()
              .like('cashRegisterClose.employee.user.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadcashRegisterValues();
  }, [loadcashRegisterValues]);

  return (
    <Container>
      <CrudTable
        title={'Recebimento de Cartões'}
        customHeaderComponent={
          <>
            <ImportCsvButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Import />}
              onClick={() => setOpenConfirmationDialog(true)}
            >
              Agenda de Vendas
            </ImportCsvButton>
            <ImportCsvButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Import />}
              onClick={() => setOpenPaymentDialog(true)}
            >
              Recebíveis de Vendas
            </ImportCsvButton>
          </>
        }
        modelActions={CashRegisterValue.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <AddToList />,
            onClick: (cashRegisterValueId, row) => {
              history.push({
                pathname: `/financial/itemizedCard/${cashRegisterValueId}`,
                state: { cashRegisterValue: row },
              });
            },
            title: 'Visualizar Fechamento de Caixa',
          },
        }}
        customCellRenderers={{
          'cashRegisterClose.date': {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          liquid: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          confirmed: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.status === 'Aberto') {
            return '#ffe0e0';
          }
        }}
      />

      <ImportCardCsvDialog
        open={openConfirmationDialog}
        title="Agenda de vendas"
        placeholder={
          <PlaceholderContainer>
            <h3>Colunas Obrigatórias:</h3>
            <ul>
              <li>valor bruto</li>
              <li>valor liquido</li>
              <li>serial</li>
              <li>parcela</li>
            </ul>
          </PlaceholderContainer>
        }
        restSubmitData={restApiClient.itemizedCard.submitSalesModel}
        onError={(error) => {
          setConfirmationErrors(error.response.data.errors);
        }}
        onClose={() => {
          setOpenConfirmationDialog(false);
          setConfirmationErrors(null);
        }}
        onCancel={() => {
          setOpenConfirmationDialog(false);
          setConfirmationErrors(null);
        }}
        onSuccess={() => {
          loadcashRegisterValues();
          setOpenConfirmationDialog(false);
          setConfirmationErrors(null);
        }}
        errorMessage={
          confirmationErrors && (
            <ErrorContainer>
              <ul>
                {Object.keys(confirmationErrors).map((key) => {
                  return Object.keys(confirmationErrors[key]).map((key2) => {
                    return (
                      <li key={key}>{`Linha ${key}: ${key2} - ${first(
                        confirmationErrors[key][key2]
                      )}`}</li>
                    );
                  });
                })}
              </ul>
            </ErrorContainer>
          )
        }
      />

      <ImportCardCsvDialog
        open={openPaymentDialog}
        title="Recebíveis de Vendas"
        placeholder={
          <PlaceholderContainer>
            <h3>Colunas Obrigatórias:</h3>
            <ul>
              <li>data</li>
              <li>valor bruto</li>
              <li>valor liquido</li>
              <li>serial</li>
              <li>parcela</li>
            </ul>
          </PlaceholderContainer>
        }
        restSubmitData={async (submitFile) => {
          if (!selectedServiceSupplier) {
            setServiceSupplierError('Campo obrigatório');
          }
          if (!selectedExpenseType) {
            setExpenseTypeError('Campo obrigatório');
          }
          if (!selectedServiceSupplier || !selectedExpenseType) {
            throw new Error('teste');
          }
          const response = await restApiClient.itemizedCard.submitPaymentModel(
            submitFile,
            selectedExpenseType,
            selectedServiceSupplier
          );
          return response;
        }}
        onError={(error) => {
          if (error?.response?.data?.errors) {
            setPaymentErrors(error.response.data.errors);
          }
        }}
        onClose={() => {
          setOpenPaymentDialog(false);
          setPaymentErrors(null);
          setSelectedServiceSupplier(null);
          setSelectedExpenseType(null);
        }}
        onCancel={() => {
          setOpenPaymentDialog(false);
          setPaymentErrors(null);
          setSelectedServiceSupplier(null);
          setSelectedExpenseType(null);
        }}
        onSuccess={() => {
          loadcashRegisterValues();
          setOpenPaymentDialog(false);
          setPaymentErrors(null);
          setSelectedServiceSupplier(null);
          setSelectedExpenseType(null);
        }}
        customform={
          <>
            <SelectServiceSupplierDialogControl
              data={selectedServiceSupplier}
              errors={serviceSupplierError}
              onSuccess={(id) => {
                setSelectedServiceSupplier(id);
                setServiceSupplierError(null);
              }}
            />
            <SelectExpenseTypeDialogControl
              data={selectedExpenseType}
              errors={expenseTypeError}
              onSuccess={(id) => {
                setSelectedExpenseType(id);
                setExpenseTypeError(null);
              }}
            />
          </>
        }
        errorMessage={
          paymentErrors && (
            <ErrorContainer>
              <ul>
                {Object.keys(paymentErrors).map((key) => {
                  return Object.keys(paymentErrors[key]).map((key2) => {
                    return (
                      <li key={key}>{`Linha ${key}: ${key2} - ${first(
                        paymentErrors[key][key2]
                      )}`}</li>
                    );
                  });
                })}
              </ul>
            </ErrorContainer>
          )
        }
      />
    </Container>
  );
}

export default CashRegisterCardList;
