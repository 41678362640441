import React, { useState, useEffect } from 'react';
import OrderItem from '../../../../../forms/orderItem';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, CancelButton } from './styles';

function ViewAllItemsByOrder({ history, match }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const orderId = match.params?.id;
  const columns = OrderItem.tableSchemaAddItems;

  useEffect(() => {
    restApiClient.orderItem
      .getAllByOrder(
        orderId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('item.id', searchBy)
              .or()
              .like('item.name', searchBy)
              .or()
              .like('item.brand', searchBy)
              .or()
              .like('item.barcode.barcode', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={`Visualizar todos os Itens do Pedido #${orderId}`}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
        modelActions={OrderItem.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          approvedQuantity: {
            renderer: (field, value, column, row) => {
              if (row.order.status === 'Enviado') return value ?? 0;
              else return 0;
            },
          },
          'item.barcode': {
            renderer: (field, value, column, row) => {
              return value.map((item) => item.barcode).join(', ');
            },
          },
        }}
      />
    </Container>
  );
}

export default ViewAllItemsByOrder;
