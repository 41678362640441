import {
  and,
  hasType,
  rankWith,
  schemaMatches,
  schemaSubPathMatches,
  uiTypeIs,
} from '@jsonforms/core';

import { withJsonFormsMultiEnumProps } from '@jsonforms/react';
import EnumArrayRenderer from '../../components/enumArrayRenderer';

const hasOneOfItems = (schema) =>
  schema.oneOf !== undefined &&
  schema.oneOf.length > 0 &&
  schema.oneOf.every((entry) => {
    return entry.const !== undefined;
  });

const hasEnumItems = (schema) =>
  schema.type === 'string' && schema.enum !== undefined;

export const customEnumArrayRendererTester = rankWith(
  5,
  and(
    uiTypeIs('Control'),
    and(
      schemaMatches(
        (schema) =>
          hasType(schema, 'array') &&
          !Array.isArray(schema.items)
      ),
      schemaSubPathMatches('items', (schema) => {
        return hasOneOfItems(schema) || hasEnumItems(schema);
      })
    )
  )
);

export default {
  tester: customEnumArrayRendererTester,
  renderer: withJsonFormsMultiEnumProps(EnumArrayRenderer),
};
