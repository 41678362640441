import React from 'react';
import Expense from '../../../../forms/expense';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import {
  Container,
  BackButton,
  FooterContainer,
  SubmitButton,
  CancelButton,
} from './styles';

function ThrowRecurrentExpenseCreateUpdate(props) {
  const expense = props.location?.state?.expense ?? {};
  const readonly = props.location?.state?.readonly ?? false;
  let expensableType;
  let responsableType;

  if (expense?.expensableType) {
    let indexes = expense?.expensableType.split('\\');
    expensableType = indexes[2][0].toLowerCase() + indexes[2].substring(1);
  }

  if (expense.dentistId) {
    responsableType = 'Dentista';
  } else if (expense.employeeId) {
    responsableType = 'Funcionário';
  } else if (expense.patientId) {
    responsableType = 'Paciente';
  } else {
    responsableType = 'Fornecedor';
  }

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Expense}
        uischemaSelector={(modelSchema) => modelSchema.customUischema}
        schemaSelector={(modelSchema) => {
          const schema = { ...modelSchema.schema };
          switch (expensableType) {
            case 'outsourced':
              schema.properties.supplierId.format = 'selectOutsourcedDialog';
              break;
            case 'supplier':
              schema.properties.supplierId.format = 'selectSupplierDialog';
              break;
            case 'serviceSupplier':
              schema.properties.supplierId.format =
                'selectServiceSupplierDialog';
              break;
            default:
              break;
          }
          return schema;
        }}
        restCreateData={restApiClient.expense.create}
        onCreateSuccess={() => {
          props.history.goBack();
        }}
        modelData={{
          ...expense,
          expensePaid: false,
          supplierId: expense?.expensableId,
          expensableType,
          readonly,
          responsableType,
        }}
        customButtonsBuilder={
          readonly
            ? () => (
                <>
                  <FooterContainer>
                    <BackButton
                      onClick={() => props.history.goBack()}
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      {'Voltar'}
                    </BackButton>
                  </FooterContainer>
                </>
              )
            : (submit) => (
                <>
                  <CancelButton
                    onClick={() => props.history.goBack()}
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Cancelar
                  </CancelButton>
                  <SubmitButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => submit()}
                  >
                    Lançar Despesa
                  </SubmitButton>
                </>
              )
        }
        {...props}
      />
    </Container>
  );
}

export default ThrowRecurrentExpenseCreateUpdate;
