import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectExpenseTypeDialogControl from '../../components/selectExpenseTypeDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectExpenseTypeDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectExpenseTypeDialog'
  )
);

const customSelectExpenseTypeDialogControlTester = rankWith(
  4,
  isSelectExpenseTypeDialogControl
);

export default {
  tester: customSelectExpenseTypeDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectExpenseTypeDialogControl),
};
