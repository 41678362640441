import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportRadiologySchedulesCostUnits = {
  buildRadiologySchedulesCostUnitsPdf: (from, to, units, costUnits) => {
    const doc = new jsPDF();
    let y = 0;
    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(15, (y += 15),  `Relatório de Custo por Unidades (Origem)`);

    doc.setFontSize(12);
    doc.text(15, (y += 8), `Período: ${from} a ${to}`);
    let now = new Date
    doc.text(15, (y += 5), 'Emissão: ' + now.toLocaleDateString() + ' às ' + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds());
    //CORPO DO PDF
    Object.keys(costUnits).forEach(function(unit) {
      if (Math.ceil(y) >= 260){
        doc.addPage();
        y = 10;
      }
      if(unit !== 'totalOrigin'){
        // PARA CADA UNIDADE
        const unitSelected = units.values.find(
          (x) => x.addressId === parseInt(unit)
        );
        y += 8;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        doc.text(15, (y += 4), `Unidade: ${unitSelected?.name}`);
        y += 2;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(10);
        let bodyValue = [];
        Object.keys(costUnits[unit].origins).forEach(function(value) {
          const originValue = costUnits[unit].origins[value];
          bodyValue.push([
            originValue.name,
            { content: parseFloat(originValue.value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }), styles: { halign: 'left'} },
          ]);
        });
        doc.autoTable({
          startY: (y += 1),
          head: [[
            'Origem',
            {content: 'Valor',  styles: { halign: 'left'}},
          ]],
          body: bodyValue,
          theme: 'plain',
          tableWidth: 'wrap',
          styles: { fontSize: 9 },
        });
        y = doc.lastAutoTable.finalY;

        y += 2;
        doc.setLineWidth(0.8);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        let totalUnit = parseFloat(costUnits[unit].totalUnit ?? 0) + parseFloat(costUnits[unit].totalAdjustUnit ?? 0);
        doc.text(15, (y += 5), `Total: ${parseFloat(totalUnit).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`);
      } else {
        //Total geral
        y += 10;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        doc.text(15, (y += 4), `Total por Origem:`);
        y += 2;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(10);
        let bodyTotalValue = [];
        Object.keys(costUnits[unit].origins).forEach(function(value) {
          const originTotalValue = costUnits[unit].origins[value];
          bodyTotalValue.push([
            originTotalValue.name,
            { content:  parseFloat(originTotalValue.value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }), styles: { halign: 'left'} },
          ]);
        });
        doc.autoTable({
          startY: (y += 1),
          head: [[
            'Origem',
            {content: 'Valor',  styles: { halign: 'left'}},
          ]],
          body: bodyTotalValue,
          theme: 'plain',
          tableWidth: 'wrap',
          styles: { fontSize: 9 },
        });
        y = doc.lastAutoTable.finalY;

        y += 2;
        doc.setLineWidth(0.8);
        doc.setDrawColor(0, 0, 0);
        doc.line(10, y , 200, y);
        doc.setFontSize(12);
        let totalGlobal = parseFloat(costUnits[unit].totalGlobal ?? 0) + parseFloat(costUnits[unit].totalAdjustGlobal ?? 0);
        doc.text(15, (y += 4), `Total: ${parseFloat(totalGlobal).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`);
      }
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for(var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 290, String(i) + '/' + String(pageCount));
    }


    return doc;
  },
};

export default ReportRadiologySchedulesCostUnits;
