import React, { useState, useEffect, useCallback } from 'react';
import CashRegisterValue from '../../../../forms/cashRegisterValue';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, AddToList } from './styles';

function CashRegisterCheckList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const columns = CashRegisterValue.checkTableSchema;

  const loadcashRegisterCloses = useCallback(() => {
    restApiClient.cashRegisterValue
      .getAllChecks(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('status', searchBy)
              .or()
              .like('cashRegisterClose.date', searchBy)
              .or()
              .like('cashRegisterClose.number_cash_register', searchBy)
              .or()
              .like('cashRegisterClose.unit.name', searchBy)
              .or()
              .like('cashRegisterClose.employee.user.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadcashRegisterCloses();
  }, [loadcashRegisterCloses]);

  return (
    <Container>
      <CrudTable
        title={'Recebimento de Cheques'}
        modelActions={CashRegisterValue.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <AddToList />,
            onClick: (cashRegisterValueId, row) => {
              history.push({
                pathname: `/financial/itemizedCheck/${cashRegisterValueId}`,
                state: { cashRegisterValue: row },
              });
            },
            title: 'Visualizar Fechamento de Caixa',
          },
        }}
        customCellRenderers={{
          'cashRegisterClose.date': {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          const hasColor = row.itemizedCheck.reduce(
            (acc, x) =>
              acc || x.status === 'Pendente' || x.status === 'Em Compensação',
            false
          );
          if (hasColor) {
            return '#ffe0e0';
          }
        }}
      />
    </Container>
  );
}

export default CashRegisterCheckList;
