import React, { useState, useEffect, useCallback } from 'react';
import PurchaseEntryItems from '../../../forms/purchaseEntryItems';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, CancelButton, AddBox } from './styles';
import ManualEntryItemDialog from '../../../components/manualEntryItemDialog';
import CreateUpdateGroupDialog from '../../../components/createUpdateGroupDialog';

function ManualEntry({ history, match, ...props }) {
  const purchaseId = match.params?.id;
  const entryId = match.params?.entryId;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = PurchaseEntryItems.tableSchema;

  const loadItems = useCallback(() => {
    if (!open) {
      restApiClient.purchaseItem
        .getAllByPurchaseId(
          purchaseId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('item.id', searchBy)
                .or()
                .like('item.name', searchBy)
                .or()
                .like('purchase_quantity', searchBy)
                .or()
                .like('price', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          let newRows = e.data;
          newRows.values = e.data.values.map((rowProduct) => {
            rowProduct.subtotal =
              rowProduct.purchaseQuantity * rowProduct.price;
            if (isNaN(rowProduct.subtotal)) rowProduct.subtotal = 0;
            return rowProduct;
          });
          setRows(newRows);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, open]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <Container>
      <CrudTable
        title={'Visualizar Compra'}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          entryItem: {
            icon: <AddBox />,
            onClick: (id, row) => {
              setOpen(true);
              setSelectedRow(row);
            },
            title: 'Entrar itens à compra',
            allowedActions: [PurchaseEntryItems.actions.manualEntry],
          },
        }}
        customCellRenderers={{
          purchaseQuantity: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.entryQuantity > row.purchaseQuantity) {
            return '#e6ffe6';
          } else if (row.entryQuantity < row.purchaseQuantity) {
            return '#ffe0e0';
          }
        }}
      />

      <CreateUpdateGroupDialog
        open={open}
        restModel={{
          findById: async () => ({
            data: {
              quantity: 0,
            },
          }),
          update: async (purchaseEntryId, requestModelData, obj) => {
            return await restApiClient.purchaseEntryItem.manualEntry(entryId, {
              itemId: purchaseEntryId,
              quantity: obj.quantity,
            });
          },
        }}
        action={{
          key: 'edit',
          actionLabel: 'Entrada',
          depthLabel: 'Items',
        }}
        parentId={selectedRow?.itemId}
        fieldName="quantity"
        label="Quantidade"
        type="number"
        onCancel={() => {
          setOpen(false);
          setSelectedRow(undefined);
        }}
        onSuccess={() => {
          loadItems();
          setOpen(false);
        }}
      />
    </Container>
  );
}

export default ManualEntry;
