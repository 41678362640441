import React, { useState, useCallback, useEffect } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, NotesMedical, Check, Cancel } from './styles';
import DentistWorkedHour from '../../../../../forms/dentistWorkedHour';
import Specialty from '../../../../../forms/specialty';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import DisapprovedJusticationDialog from '../../../../../components/disapprovedJustificationDialog';
import ApproveScheduleDialog from '../../../../../components/appoveScheduleDialog';
import ErrorDialog from '../../../../../components/errorDialog';

function DentistScheduleAuditList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [dentistScheduleId, setDentistScheduleId] = useState('');
  const [openJustification, setOpenJustification] = useState('');
  const columns = DentistWorkedHour.auditTableSchema;
  const [error, setError] = useState({
    state: false,
    description: '',
  });
  const [openApprove, setOpenApprove] = useState('');
  const [selectedDentistWorkedHour, setSelectedDentistWorkedHour] =
    useState(null);

  const loadDentistWorkedHour = useCallback(() => {
    restApiClient.dentistWorkedHour
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild
                  .like('status', 'Submetida')
                  .isNull('replacedDentistId');
                rightChild
                  .like('dentist.user.name', searchBy)
                  .or()
                  .like('specialty.name', searchBy)
                  .or()
                  .like('unit.name', searchBy);
              })
              .toString()
          : new FilterTree()
              .like('status', 'Submetida')
              .isNull('replacedDentistId')
              .toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadDentistWorkedHour();
  }, [loadDentistWorkedHour]);

  return (
    <Container>
      <CrudTable
        title={'Auditar Agendas'}
        modelActions={DentistWorkedHour.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewProcedures: {
            onClick: (id) =>
              history.push(`/dentist/audit/schedule/${id}/attendances`),
            icon: <NotesMedical />,
            title: 'Visualizar Procedimentos',
            allowedActions: [
              DentistWorkedHour.dentistWorkedHourAttendanceActions
                .getAllByWorkedHour,
            ],
          },
          approvedSchedule: {
            icon: <Check />,
            onClick: (id, row) => {
              if (!isEmpty(row.workedHourAttendances)) {
                const evaluatedProcedures = row.workedHourAttendances.filter(
                  (x) =>
                    x.attendance.status === 'Aprovado' ||
                    x.attendance.status === 'Reprovado'
                ).length;

                if (evaluatedProcedures === row.workedHourAttendances.length) {
                  setOpenApprove(true);
                  setSelectedDentistWorkedHour(row);
                } else {
                  setError({
                    state: true,
                    description:
                      'Para aprovar uma agenda é necessário avaliar todos os procedimentos!',
                  });
                }
              } else {
                setError({
                  state: true,
                  description:
                    'Para aprovar uma agenda é necessário avaliar todos os procedimentos!',
                });
              }
            },
            title: 'Aprovar Agenda',
            allowedActions: [
              [
                DentistWorkedHour.actions.setApproved,
                DentistWorkedHour.actions.updateSpecialty,
                Specialty.actions.getAllBySpecialtyDentist,
              ],
            ],
          },
          disapprovedSchedule: {
            icon: <Cancel />,
            onClick: (id, row) => {
              if (!isEmpty(row.workedHourAttendances)) {
                const evaluatedProcedures = row.workedHourAttendances.filter(
                  (x) =>
                    x.attendance.status === 'Aprovado' ||
                    x.attendance.status === 'Reprovado'
                ).length;

                if (evaluatedProcedures === row.workedHourAttendances.length) {
                  setDentistScheduleId(id);
                  setOpenJustification(true);
                } else {
                  setError({
                    state: true,
                    description:
                      'Para reprovar uma agenda é necessário avaliar todos os procedimentos!',
                  });
                }
              } else {
                setError({
                  state: true,
                  description:
                    'Para reprovar uma agenda é necessário avaliar todos os procedimentos!',
                });
              }
            },
            title: 'Reprovar Agenda',
            allowedActions: [DentistWorkedHour.actions.setDisapproved],
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
          submitDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
          schedule: {
            renderer: (field, value, column, row) => {
              return row.beginTime && row.endTime
                ? `${row.beginTime} às ${row.endTime}`
                : '-';
            },
          },
          workedHourAttendances: {
            renderer: (field, value) => {
              if (!isEmpty(value)) {
                const evaluatedProcedures = value.filter(
                  (x) =>
                    x.attendance.status === 'Aprovado' ||
                    x.attendance.status === 'Reprovado'
                ).length;

                return `${evaluatedProcedures}/${value.length}`;
              } else {
                return '-';
              }
            },
          },
          'specialty.name': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />

      <DisapprovedJusticationDialog
        open={openJustification}
        cancelSubmit={true}
        onCancel={() => setOpenJustification(false)}
        onSuccess={(justification) => {
          setOpenJustification(false);
          restApiClient.dentistWorkedHour
            .setDisapproved(dentistScheduleId, justification)
            .then(() => loadDentistWorkedHour())
            .catch((e) => {
              setError({
                state: true,
                description: e.response.data.errors,
              });
            });
        }}
      />

      <ApproveScheduleDialog
        open={openApprove}
        dentistWorkedHour={selectedDentistWorkedHour}
        onCancel={() => setOpenApprove(false)}
        onSuccess={(id) => {
          setOpenApprove(false);
          restApiClient.dentistWorkedHour
            .setApproved(id)
            .then(() => loadDentistWorkedHour())
            .catch((e) => {
              setError({
                state: true,
                description: e.response.data.errors,
              });
            });
        }}
        onError={(error) => {
          setOpenApprove(false);
          setError(error);
        }}
      />

      <ErrorDialog
        open={error?.state}
        title="Erro"
        error={error?.description}
        onClose={() =>
          setError({
            state: false,
            description: '',
          })
        }
      />
    </Container>
  );
}

export default DentistScheduleAuditList;
