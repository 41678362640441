import React, { useState, useEffect, useCallback } from 'react';
import SectorExpenseType from '../../../../../forms/sectorExpenseType';
import ExpenseType from '../../../../../forms/expenseType';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, Receipt, BackButton } from './styles';
import ExpenseTypeDialog from '../../../../../components/expenseTypeDialog';

// import CreateUpdateGroupDialog from '../../../../../components/createUpdateGroupDialog';

function SectorExpenseTypeList({ history, match }) {
  const sectorId = match.params.id;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [openExpenseTypeDialog, setOpenExpenseTypeDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [modelData, setModelData] = useState(undefined);

  const columns = SectorExpenseType.tableSchema;

  const loadGroups = useCallback(() => {
    restApiClient.sectorExpenseType
      .getAllBySectorId(
        sectorId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('type', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, sectorId]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  return (
    <Container>
      <CrudTable
        title={'Tipos de Despesas de Setores'}
        customFooterComponent={
          <>
            <BackButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
          </>
        }
        modelActions={SectorExpenseType.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          link: {
            icon: <Receipt />,
            onClick: (id, row) => {
              setSelectedId(id);
              setModelData(row);
              setOpenExpenseTypeDialog(true);
            },
            maxDepth: 2,
            title: 'Vincular a um tipo de despesa',
            allowedActions: [
              [
                ExpenseType.actions.getAllLeaf,
                SectorExpenseType.actions.update,
                SectorExpenseType.actions.create,
              ],
            ],
          },
        }}
        customCellRenderers={{
          type: {
            renderer: (field, value) => {
              return value ? value.toUpperCase() : '-';
            },
          },
          expenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value?.name ?? '-';
              }
            },
          },
        }}
      />

      <ExpenseTypeDialog
        open={openExpenseTypeDialog}
        selectedId={selectedId}
        modelData={modelData}
        restModel={restApiClient.sectorExpenseType}
        onClose={() => {
          setOpenExpenseTypeDialog(false);
        }}
        onSuccess={() => {
          loadGroups();
          setOpenExpenseTypeDialog(false);
        }}
      />
    </Container>
  );
}

export default SectorExpenseTypeList;
