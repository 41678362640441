import React, { useState, useEffect } from 'react';
import UnitFinance from '../../../../../forms/unitFinance';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, BackButton, TextBulletListSquareWarning } from './styles';
import moment from 'moment/moment';
import JusticationDialog from '../../../../../components/justificationDialog';

function UnitFinanceAdjustmentsList({ history, match }) {
  const unitFinanceId = match.params?.id ?? -1;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [openJustification, setOpenJustification] = useState(false);
  const [justification, setJustification] = useState('');
  const columns = UnitFinance.adjustmentsTableSchema;

  useEffect(() => {
    restApiClient.unitFinanceBalanceHistory
      .getAllByUnitFinance(
        unitFinanceId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('responsible.user.name', searchBy).toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Histórico de Ajustes'}
        customFooterComponent={
          <BackButton
            onClick={() => history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Voltar
          </BackButton>
        }
        modelActions={UnitFinance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewJustification: {
            icon: <TextBulletListSquareWarning />,
            onClick: (id, row) => {
              setOpenJustification(true);
              setJustification(row?.justification);
            },
            title: 'Visualizar Justificativa',
            allowedActions: [UnitFinance.actions.getAdjustmentsHistory],
          },
        }}
        customCellRenderers={{
          createdAt: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
          previousBalance: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          newBalance: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />

      <JusticationDialog
        open={openJustification}
        title={'Justificativa - Ajuste'}
        justification={justification}
        onClose={() => setOpenJustification(false)}
      />
    </Container>
  );
}

export default UnitFinanceAdjustmentsList;
