/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, DialogContent, StyledCreateUpdateForm } from './styles';
import restApiClient from '../../services/restApiClient';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

function DentistPatientValueDialog({
  onSuccess,
  onError,
  onCancel,
  dentistPatientValue,
  unitId,
  dentistId,
  ...props
}) {
  const [forceId, setForceId] = useState(undefined);
  
  useEffect(() => {
    if (unitId) {
      restApiClient.dentistPatientValue.getAllByDentistAndUnit(dentistId, unitId).then((e) => {
        if (!isEmpty(e.data)) {
          setForceId(first(e.data)?.id);
        }
      });
    }
  }, [unitId]);
  
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={dentistPatientValue}
            restGetDataById={restApiClient.dentistPatientValue.findById}
            restCreateData={restApiClient.dentistPatientValue.create}
            restUpdateData={restApiClient.dentistPatientValue.update}
            onCreateSuccess={(request) => {
              setForceId(undefined);
              onSuccess?.(request);
            }}
            onUpdateSuccess={(request) => {
              setForceId(undefined);
              onSuccess?.(request);
            }}
            forceId={forceId}
            modelData={{
              unitId: unitId,
              dentistId: dentistId,
            }}
            onCancelClick={(request) => {
              setForceId(undefined);
              onCancel?.(request);
            }}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default DentistPatientValueDialog;
