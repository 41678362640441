import React from 'react';
import {
  BoldTextStyledH5,
  CenterContainer,
  DottedLine,
  Grid,
  TextStyledH6,
} from './styles';

function TicketOS({ orderService }) {

  return (
    <CenterContainer>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <BoldTextStyledH5>
            {`Prontuário: ${orderService?.patient?.recordNumber}`}
          </BoldTextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH6>
            {`Código Interno: ${orderService?.id}`}
          </TextStyledH6>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH6>
            {`Unidade: ${orderService?.orderServiceStep?.unit.name}`}
          </TextStyledH6>
        </Grid>
        { orderService?.orderServiceStep?.executorId ?
          (<Grid item xs={12}>
            <TextStyledH6>
              {`Executor: ${orderService?.orderServiceStep?.labExecutor?.user.name}`}
            </TextStyledH6>
          </Grid>) : null
        }
        { orderService?.orderServiceStep?.outsourcedId ? 
        (<Grid item xs={12}>
          <TextStyledH6>
            {`Executor: ${orderService?.orderServiceStep?.outsourcedExecutor?.isPhysicalPerson ?
              orderService?.orderServiceStep?.outsourcedExecutor?.socialName :
              orderService?.orderServiceStep?.outsourcedExecutor?.fantasyName}`}
          </TextStyledH6>
        </Grid>) : null
        }
        <Grid item xs={12}>
          <BoldTextStyledH5>
            {`Data: ${new Date(orderService?.orderServiceStep?.schedule?.labReturnDate + " 00:00:00").toLocaleDateString(
              'pt-br'
            )}`}
          </BoldTextStyledH5>
        </Grid>
      </Grid>
      <DottedLine />
    </CenterContainer>
  );
}

export default TicketOS;
