import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  CreditCard as MaterialCreditCard,
  MoneyCheck as MaterialMoneyCheck,
  Printer as MaterialPrinter,
  TextBulletListSquareWarning as MaterialTextBulletListSquareWarning,
  Send as MaterialSend,
} from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CreditCard = styled(MaterialCreditCard)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const MoneyCheck = styled(MaterialMoneyCheck)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const TextBulletListSquareWarning = styled(
  MaterialTextBulletListSquareWarning
)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PrintOS = styled.div`
  @media screen {
    display: none;
  }
`;
