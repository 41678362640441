/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
  Container,
  CancelButton,
  SubmitButton,
  DialogContent,
  Card,
  Dialog,
  DialogActions,
  ActionsContainer,
  HeaderContainer,
  CardHeader,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  Checkbox,
  FormControlLabel,
  ProgressContainer,
} from './styles';
import moment from 'moment';
import DayJsUtils from '@date-io/dayjs';
import { CircularProgress } from '@material-ui/core';
import restApiClient from '../../services/restApiClient';
import SelectUnitFinance from '../selectUnitFinanceDialogControl';
import first from 'lodash/first';

function SelectPaydayDialog({
  expenseId,
  onSuccess,
  onError,
  onCancel,
  ...props
}) {
  const [error, setError] = useState(false);
  const [fetchErrors, setFetchErrors] = useState(null);
  const [payday, setPayday] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [unitFinanceId, setUnitFinanceId] = useState(null);
  const [recurrentExpense, setRecurrentExpense] = useState(false);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (payday && !error && !fetchErrors) {
                const formatedDate = moment(new Date(payday)).format(
                  'YYYY-MM-DD'
                );
                setFetching(true);
                restApiClient.expense
                  .setPaid(expenseId, {
                    payday: formatedDate,
                    recurrentExpense,
                    unitFinanceId,
                  })
                  .then((e) => {
                    setError(false);
                    setFetchErrors(null);
                    setPayday(null);
                    setFetching(false);
                    setRecurrentExpense(false);
                    onSuccess?.(e.data);
                  })
                  .catch((e) => {
                    setFetching(false);
                    setFetchErrors(e.response.data.errors);
                  });
              } else {
                setError(true);
              }
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title="Despesa" />
              </HeaderContainer>
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-from"
                  label="Data do Pagamento"
                  format="DD/MM/YYYY"
                  value={payday}
                  onChange={(date) => {
                    setPayday(date);
                    setError(false);
                    setFetchErrors(null);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Editar data',
                  }}
                  cancelLabel="Cancelar"
                  okLabel="Selecionar"
                  invalidDateMessage="Data em formato inválido."
                  onError={(value) => setError(!!value)}
                  helperText={
                    error
                      ? 'Data em formato inválido.'
                      : fetchErrors?.payday?.length > 0
                      ? first(fetchErrors?.payday)
                      : ''
                  }
                  error={error || fetchErrors}
                  required
                />
              </MuiPickersUtilsProvider>
              <SelectUnitFinance
                label="Conta Bancária"
                size="small"
                onSuccess={(id) => {
                  setUnitFinanceId(id);
                }}
                visible
                enabled
                required
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={recurrentExpense}
                    onChange={(e) => {
                      setRecurrentExpense(e.target.checked);
                    }}
                    name="checked"
                  />
                }
                label="Despesa Recorrente"
              />
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setError(false);
                    setFetchErrors(null);
                    setPayday(null);
                    setRecurrentExpense(false);
                    onCancel?.();
                  }}
                >
                  Cancelar
                </CancelButton>
                {fetching ? (
                  <ProgressContainer>
                    <CircularProgress size={24} />
                  </ProgressContainer>
                ) : (
                  <SubmitButton
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Selecionar
                  </SubmitButton>
                )}
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default SelectPaydayDialog;
