import React, { useState, useEffect, useCallback } from 'react';
import OrderService from '../../../../forms/orderService';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  Preview,
  Check,
  Close,
  Printer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectContainer,
  UserEdit,
} from './styles';
import RejectOrderServiceDialog from '../../../../components/rejectOrderServiceDialog';
import OrderServiceStep from '../../../../forms/orderServiceStep';
import { useSelector } from 'react-redux';
import ConfirmationOutsourcedStepPriceDialog from '../../../../components/confirmationOutsourcedStepPriceDialog';
import ConcludeOrderServiceStepDialog from '../../../../components/concludeOrderServiceStepDialog';
import ExecutorDialog from '../../../../components/executorDialog';
import ErrorDialog from '../../../../components/errorDialog';
import moment from 'moment';
import OrderServicePrintSelectionDialog from '../../../../components/orderServicePrintSelectionDialog';

function ServiceQueueList({ history, props }) {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [orderServiceStepId, setOrderServiceStepId] = useState(null);
  const [orderService, setOrderService] = useState(null);
  const [orderServiceStep, setOrderServiceStep] = useState(null);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [labEmployees, setLabEmployees] = useState(null);
  const [selectedLabEmployee, setSelectedLabEmployee] = useState('all');
  const [holidays, setHolidays] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openExecutor, setOpenExecutor] = useState(false);
  const [openConclude, setOpenConclude] = useState(false);
  const [step, setStep] = useState('');
  const [outsourced, setOutsourced] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openPrintSelectionDialog, setOpenPrintSelectionDialog] =
    useState(false);

  const columns = OrderService.labTableSchema;

  const loadServiceOrders = useCallback(() => {
    restApiClient.orderServiceStep
      .getAllOrderServiceStepQueued(
        selectedLabEmployee,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('orderService.patient.record_number', searchBy)
              .or()
              .like('orderService.patient.name', searchBy)
              .or()
              .like('dentist.user.name', searchBy)
              .or()
              .like('step.name', searchBy)
              .or()
              .like('labExecutor.user.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedLabEmployee]);

  useEffect(() => {
    loadServiceOrders();
  }, [loadServiceOrders]);

  useEffect(() => {
    restApiClient.employee.getAllLabEmployee().then((e) => {
      setLabEmployees(e.data);
    });
  }, []);

  useEffect(() => {
    restApiClient.holiday.getAllByCityAndYear(user?.unitId).then((r) => {
      const listHolidays = r.data.values.map((holiday) => {
        let date = new Date(holiday.date + ' 00:00:00');
        return date.toLocaleDateString();
      });
      setHolidays(listHolidays);
    });
  }, []);

  return (
    <Container>
      <CrudTable
        title={'Ordens de Serviço em Fila'}
        customHeaderComponent={
          <SelectContainer>
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-selectedLabEmployee-native-simple">
                Selecione um Funcionário
              </InputLabel>
              <Select
                id="labEmployee"
                value={selectedLabEmployee || 'all'}
                label="Selecione um Funcionário"
                onChange={(e) => {
                  setSelectedLabEmployee(e.target.value);
                }}
              >
                {[
                  <MenuItem value={'all'} key={'all'}>
                    TODOS
                  </MenuItem>,
                ]
                  .concat(
                    labEmployees?.values.map((optionValue) => (
                      <MenuItem value={optionValue.id} key={optionValue.id}>
                        {optionValue.name}
                      </MenuItem>
                    ))
                  )
                  .concat(
                    <MenuItem value={'outsourceds'} key={'outsourceds'}>
                      TERCEIRIZADOS
                    </MenuItem>
                  )}
              </Select>
            </FormControl>
          </SelectContainer>
        }
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: (
              <span>
                Você tem certeza que deseja finalizar esta etapa da ordem de
                serviço?
              </span>
            ),
            onSuccess: (id, row) => {
              if (
                row.prevStep?.status === 'Recusada Dentista (Laboratório)' &&
                row.takeCost === null
              ) {
                setOpenErrorDialog(true);
              } else {
                setOrderServiceStepId(id);

                if (row?.outsourcedId) {
                  setStep(row?.step);
                  setOutsourced(row?.outsourcedExecutor);
                  setOpenConfirmation(true);
                } else {
                  setOpenConclude(true);
                }
              }
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          viewOrderService: {
            icon: <Preview />,
            onClick: (orderServiceStepId, row) => {
              history.push({
                pathname: `/lab/orderService/update/${
                  row.orderService.endProductId
                }/${orderServiceStepId}/${true}/${row.workflowPosition}/${
                  row.orderServiceId
                }`,
                state: {
                  dentistId: row.dentistId,
                  date: row.createdAt,
                  allowView: true,
                  patientName: row.orderService?.patient?.name,
                  recordNumber: row.orderService?.patient?.recordNumber,
                },
              });
            },
            allowedActions: [
              OrderServiceStep.actions.getAllOrderServiceStepQueued,
            ],
            title: 'Visualizar Ordem de Serviço',
          },
          printOS: {
            icon: <Printer />,
            onClick: async (orderServiceId, row) => {
              await setOrderService({
                ...row.orderService,
                orderServiceStep: row,
              });
              setOpenPrintSelectionDialog(true);
              // handlePrint();
            },
            title: 'Imprimir',
          },
          changeExecutor: {
            icon: <UserEdit />,
            onClick: (orderServiceId, row) => {
              setOrderServiceStep(row);
              setOpenExecutor(true);
            },
            title: 'Alterar Executor',
            allowedActions: [OrderServiceStep.actions.defineExecutor],
          },
          rejectOrderService: {
            icon: <Close />,
            onClick: (orderServiceId, row) => {
              setOrderServiceStepId(row.id);
              setOrderServiceStep(row);
              setOpen(true);
            },
            allowedActions: [OrderServiceStep.actions.setRejected],
            title: 'Recusar Ordem de Serviço',
          },
          concludeOrderService: {
            icon: <Check />,
            onClick: (id, row) => {
              setSubmitConfirmationId(id);
            },
            allowedActions: [OrderServiceStep.actions.setCompletedLaboratory],
            title: 'Concluir Etapa da Ordem de Serviço',
          },
        }}
        customCellRenderers={{
          'schedule.labReturnDate': {
            renderer: (field, value, column, row) => {
              const date = new Date(value + ' 00:00:00');
              return row.schedule.urgency
                ? `${date.toLocaleDateString('pt-br')} (Urgência)`
                : `${date.toLocaleDateString('pt-br')}`;
            },
          },
          executor: {
            renderer: (field, value, column, row) => {
              if (row?.executorId) {
                return row?.labExecutor?.user.name;
              } else if (row?.outsourcedId) {
                return row?.outsourcedExecutor?.isPhysicalPerson
                  ? row?.outsourcedExecutor?.socialName
                  : row?.outsourcedExecutor?.fantasyName;
              } else {
                return '-';
              }
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          const currentDate = new Date(
            moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00'
          );
          let tomorrowDate = new Date(
            new Date().setDate(new Date().getDate() + 1)
          );

          while (
            holidays.includes(tomorrowDate.toLocaleDateString()) ||
            tomorrowDate.getDay() === 0
          ) {
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
          }

          let labDate = new Date(row.schedule.labReturnDate + ' 00:00:00');
          tomorrowDate = new Date(
            moment(tomorrowDate).format('YYYY-MM-DD') + ' 00:00:00'
          );

          if (labDate <= currentDate || row.schedule.urgency) {
            return '#ffb5b5';
          } else if (
            labDate.toLocaleDateString() === tomorrowDate.toLocaleDateString()
          ) {
            return '#fcfab8';
          }
        }}
      />

      <RejectOrderServiceDialog
        open={open}
        orderServiceStepId={orderServiceStepId}
        nextStep={orderServiceStep?.nextStep}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          loadServiceOrders();
        }}
      />

      <ConfirmationOutsourcedStepPriceDialog
        open={openConfirmation}
        step={step}
        outsourced={outsourced}
        onCancel={() => setOpenConfirmation(false)}
        onSuccess={() => {
          setOpenConfirmation(false);
          setOpenConclude(true);
        }}
      />

      <ExecutorDialog
        open={openExecutor}
        onCancel={() => setOpenExecutor(false)}
        onSuccess={() => {
          setOpenExecutor(false);
          loadServiceOrders();
        }}
        orderServiceStep={orderServiceStep}
      />

      <ErrorDialog
        open={openErrorDialog}
        title="Erro"
        error="Esta etapa é um retrabalho e ainda não foi avaliada"
        onClose={() => {
          setOpenErrorDialog(false);
        }}
      />

      <ConcludeOrderServiceStepDialog
        open={openConclude}
        orderServiceStepId={orderServiceStepId}
        onCancel={() => setOpenConclude(false)}
        onSuccess={() => {
          setOpenConclude(false);
          loadServiceOrders();
        }}
      />

      <OrderServicePrintSelectionDialog
        open={openPrintSelectionDialog}
        orderService={orderService}
        onCancel={() => setOpenPrintSelectionDialog(false)}
        onSuccess={() => {
          setOpenPrintSelectionDialog(false);
          loadServiceOrders();
        }}
      />
    </Container>
  );
}

export default ServiceQueueList;
