const OpeningHour = {
  schema: {
    type: 'object',
    properties: {
      intervals: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            beginInterval: {
              title: 'Início',
              type: 'string',
              beginInterval: '',
              format: 'time',
              customErrors: '',
            },
            endInterval: {
              title: 'Término',
              type: 'string',
              endInterval:  '',
              format: 'time',
              customErrors: '',
            },
          },
          required: [
            'beginInterval',
            'endInterval'
          ],
        },
      },
    },
  },

  uischema: {
    type: 'Group',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Horário de Funcionamento',
            },
            scope: '#/properties/intervals',
          },
        ],
      },
    ],
  },
  actions: {
    getAllByDay: 'OpeningHourController@getAllByDay',
    update: 'OpeningHourController@update',
  },
};

export default OpeningHour;
