import styled from 'styled-components';
import { UserShield as MaterialUserShield } from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const UserShield = styled(MaterialUserShield)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;
