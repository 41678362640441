const EmployeeExpense = {
  tableSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'sector.name',
      label: 'Setor',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    create: 'EmployeeExpenseController@create',
    update: 'EmployeeExpenseController@patchUpdate',
    getAllByExpense: 'EmployeeExpenseController@getAllByExpense',
  },
};

export default EmployeeExpense;
