import styled from 'styled-components';
import { Save as MaterialSaveIcon } from '../../../styles/icons';
import MoneyInput from '../../../components/moneyInput';

import {
  Button as MaterialButton,
  Typography as MaterialTypography,
} from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 110px;
`;

export const Button = styled(MaterialButton)`
  color: #ffffff !important;
`;

export const SaveIcon = styled(MaterialSaveIcon)`
  width: 25px;
  height: 25px;
`;

export const StyledTypography = styled(MaterialTypography)`
  margin-left: 20px !important;
`;
