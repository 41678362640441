/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Employee from '../../forms/employee';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';
import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  UserCircle,
} from './styles';

const SelectLabEmployeeDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedLabEmployee, setSelectedLabEmployee] = React.useState({});
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = Employee.labEmployeeTableSchema;
  const ignoreList =
    config.completeData?.suggestedExecutors?.map((x) => x.employeeId) ?? [];

  const handleClickOpen = () => {
    if (enabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    restApiClient.employee
      .getAllLabEmployee(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    if (data) {
      restApiClient.employee.findById(data).then((e) => {
        setSelectedLabEmployee(e.data);
      });
    } else {
      setSelectedLabEmployee({});
    }
  }, [data]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<UserCircle />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label="Executor Sugerido"
          InputLabelProps={{
            shrink: true,
            readOnly: true,
          }}
          value={selectedLabEmployee?.name ?? ''}
        />

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <StyledCrudTable
              title={'Seleção de Funcionário do Laboratório'}
              modelActions={Employee.actions}
              columns={columns}
              rows={rows}
              page={page}
              setPage={(page) => setPage(page)}
              rowsPerPage={rowsPerPage}
              availableRowsPerPage={[3]}
              setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              onOrderByChange={(orderBy) => setOrderby(orderBy)}
              orderBy={orderBy}
              setOrderBy={(value) => setOrderby(value)}
              searchBy={searchBy}
              setSearchBy={(value) => setSearchBy(value)}
              actions={{
                insert: {
                  icon: <AddBox />,
                  onClick: (id, data) => {
                    setSelectedLabEmployee(data);
                    handleChange?.(path, id);
                    onSuccess?.(id);
                    handleClose();
                  },
                  title: 'Vincular',
                  allowedActions: [Employee.actions.getAllLabEmployee],
                  selector: (row) => !ignoreList.includes(row.id),
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Hidden>
  );
};

export default SelectLabEmployeeDialogControl;
