import React, { useState, useEffect, useCallback } from 'react';
import UnitRadiologyService from '../../../../../forms/unitRadiologyService';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import TreatmentsDialog from '../../../../../components/treatmentsDialog';
import { Container, Close, BackButton } from './styles';

function LinkedServicesList({ match, history }) {
  const unitId = match.params?.unitId;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);
  const columns = UnitRadiologyService.linkedServicesTableSchema;

  const loadServices = useCallback(() => {
    restApiClient.unitRadiologyService
      .getAllByUnit(
        unitId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('treatment.name', searchBy)
              .or()
              .like('treatment.price', searchBy)
              .or()
              .like('treatment.origin.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setServices(e.data.values);
        setRows(e.data);
      });
  }, [rowsPerPage, page, searchBy, orderBy]);

  useEffect(() => {
    loadServices();
  }, [loadServices]);

  return (
    <Container>
      <CrudTable
        title={'Serviços Vinculados'}
        modelActions={UnitRadiologyService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpen(true);
            },
            allowedActions: [UnitRadiologyService.actions.create],
          },
          removeService: {
            icon: <Close />,
            onClick: (id) => {
              restApiClient.unitRadiologyService.delete(id).then(() => {
                loadServices();
              });
            },
            title: 'Remover Serviço',
            allowedActions: [UnitRadiologyService.actions.delete],
          },
        }}
        customCellRenderers={{
          'treatment.price': {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customFooterComponent={
          <>
            <BackButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
          </>
        }
      />

      <TreatmentsDialog
        open={open}
        unitId={unitId}
        services={services}
        onCancel={() => {
          setOpen(false);
        }}
        onSuccess={() => {
          loadServices();
        }}
      />
    </Container>
  );
}

export default LinkedServicesList;
