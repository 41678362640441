import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectCityDialogControl from '../../components/selectCityDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectCityDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectCityDialog'
  )
);

const customSelectCityDialogControlTester = rankWith(
  4,
  isSelectCityDialogControl
);

export default {
  tester: customSelectCityDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectCityDialogControl),
};
