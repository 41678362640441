import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportOsOutsourced = {
  buildOsOutsourcedPdf: (from, to, result) => {
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;

    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(10);
    doc.text(`Período: ${from} a ${to}`, 160, (y += 8));
    doc.text(
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds(),
      160,
      (y += 5)
    );
    doc.setFontSize(16);
    doc.text(
      `Serviços Terceirizados`,
      doc.internal.pageSize.getWidth() / 2,
      (y += 10),
      { align: 'center' }
    );

    //CORPO DO PDF
    result.values.forEach(function (outsourced) {
      // PARA CADA ORDER SERVICE
      let cost = 0;
      y += 5;
      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0);
      doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
      y += 1;
      doc.setFontSize(11);
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(`Terceirizado: ${outsourced?.socialName}`, 5, (y += 4));
      y += 0.5;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.setFontSize(10);
      let bodyValue = [];
      outsourced.orderServiceSteps.forEach(function (orderServiceStep) {
        // PARA CADA ORDER SERVICE STEP
        let date = new Date(orderServiceStep.finishedLabService.createdAt);
        const crrCost =
          parseFloat(orderServiceStep?.finishedLabService?.price ?? 0) +
          parseFloat(orderServiceStep?.extraCost?.value ?? 0);
        cost += crrCost;

        bodyValue.push([
          orderServiceStep?.orderService?.id ?? '-',
          orderServiceStep.id ?? '-',
          orderServiceStep?.dentist?.user.name ?? '-',
          orderServiceStep?.unit?.name ?? '-',
          orderServiceStep?.step?.name ?? '-',
          orderServiceStep?.toothNumber
            ? orderServiceStep?.toothNumber.split(',').join(', ')
            : '-',
          orderServiceStep?.dentalArch ?? '-',
          orderServiceStep?.status ?? '-',
          date.toLocaleDateString(),
          crrCost.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }) ?? '-',
        ]);
      });
      doc.autoTable({
        startY: (y += 2),
        headStyles: { fillColor: [0, 0, 0] },
        head: [
          [
            'Id OS',
            'Id etapa',
            'Dentista',
            'Unidade',
            'Etapa',
            'Dentes',
            'Arcada',
            'Situação',
            'Data',
            'Custo',
          ],
        ],
        body: bodyValue,
        theme: 'grid',
        styles: {
          halign: 'center',
          valign: 'middle',
          fontSize: 8,
        },
        columnStyles: {
          5: { cellWidth: 18 },
          9: { cellWidth: 'wrap' },
        },
        margin: 5,
        rowPageBreak: 'avoid',
      });

      y = doc.lastAutoTable.finalY;

      y += 0.5;
      doc.text(
        `Total: ${cost.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`,
        5,
        (y += 4)
      );
      y += 0.5;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + '/' + String(pageCount), 198, 295);
    }
    return doc;
  },
};

export default ReportOsOutsourced;
