import React, { useState, useEffect, useCallback } from 'react';
import ItemizedCard from '../../../../forms/itemizedCard';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { CancelButton, Container, Divide } from './styles';
import ShowItemizedCardInstallment from '../../../../components/showItemizedCardInstallment';
import ItemizedCardInstallment from '../../../../forms/itemizedCardInstallment';
import CreateUpdateGroupDialog from '../../../../components/createUpdateGroupDialog';

function ItemizedCardListAll({ history, match, location }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [], valueSum: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedItemizedCard, setSelectedItemizedCard] = useState(null);
  const [selectedItemizedCardId, setSelectedItemizedCardId] = useState(null);
  const [action, setAction] = useState({
    key: '',
    actionLabel: '',
    depthLabel: '',
  });

  const columns = ItemizedCard.listAllTableSchema;

  const loadItemizedCards = useCallback(() => {
    restApiClient.itemizedCard
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('serial', searchBy)
              .or()
              .like('status', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadItemizedCards();
  }, [loadItemizedCards]);

  return (
    <Container>
      <CrudTable
        title={`Lista de NSU`}
        modelActions={ItemizedCard.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <Divide />,
            onClick: (itemizedCard) => {
              setSelectedItemizedCard(itemizedCard);
            },
            title: 'Visualizar parcelas',
            selector: (itemizedCard) => itemizedCard.installments > 1,
            allowedActions: [
              ItemizedCardInstallment.actions.getAllByItemizedCardId,
            ],
          },
          update: {
            onClick: (itemizedCard) => {
              setSelectedItemizedCardId(itemizedCard);
              setOpen(true);
              setAction({
                key: 'edit',
                actionLabel: 'Edição',
                depthLabel: 'Número de Parcelas',
              });
            },
            selector: (itemizedCard) =>
              ['Confirmado', 'Parcelado'].includes(itemizedCard.status) &&
              itemizedCard.itemizedCardInstallment.length === 0,
            allowedActions: [ItemizedCard.actions.updateInstallment],
          },
        }}
        customCellRenderers={{
          'cashRegisterValue.cashRegisterClose.date': {
            renderer: (field, value) => {
              return value
                ? new Date(`${value}T03:24:00`).toLocaleDateString('pt-br')
                : '-';
            },
          },
          date: {
            renderer: (field, value) => {
              return value
                ? new Date(`${value}T03:24:00`).toLocaleDateString('pt-br')
                : '-';
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          liquid: {
            renderer: (field, value) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <CreateUpdateGroupDialog
        open={open}
        restModel={{
          findById: restApiClient.itemizedCard.findById,
          update: async (parentId, requestModelData, obj) => {
            return await restApiClient.itemizedCard.updateInstallment(
              parentId,
              obj.installments
            );
          },
        }}
        action={action}
        parentId={selectedItemizedCardId}
        fieldName="installments"
        label="Parcela"
        type="number"
        onCancel={() => {
          setOpen(false);
          setSelectedItemizedCardId(undefined);
        }}
        onSuccess={() => {
          loadItemizedCards();
          setOpen(false);
        }}
      />

      <ShowItemizedCardInstallment
        itemizedCardId={selectedItemizedCard}
        handleClose={() => {
          setSelectedItemizedCard(null);
        }}
      />
    </Container>
  );
}

export default ItemizedCardListAll;
