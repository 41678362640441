import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  PriceChange,
  Close,
  ClockHistory,
  Restore,
  Receipt,
} from './styles';
import UnitFinance from '../../../../../forms/unitFinance';
import restApiClient from '../../../../../services/restApiClient';
import FilterTree from '../../../../../helpers/filterTree';
import CrudTable from '../../../../../components/crudTable';
import AdjustUnitFinanceBalanceDialog from '../../../../../components/adjustUnitFinanceBalanceDialog';
import ExpenseTypeDialog from '../../../../../components/expenseTypeDialog';
import moment from 'moment';

function BankAccountList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [openAdjustDialog, setOpenAdjustDialog] = useState(false);
  const [unitFinance, setUnitFinance] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const columns = UnitFinance.tableSchema;

  const loadBankAccounts = useCallback(() => {
    restApiClient.unitFinance
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild.eq('type', 'Banco');
                rightChild
                  .like('id', searchBy)
                  .or()
                  .like('bankAccount.holderName', searchBy)
                  .or()
                  .like('bankAccount.bank', searchBy)
                  .or()
                  .like('bankAccount.account', searchBy)
                  .or()
                  .like('bankAccount.agency', searchBy);
              })
              .toString()
          : new FilterTree().eq('type', 'Banco').toString(),
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        newRows.values = e.data.values.map((unitFinance) => {
          return {
            ...unitFinance,
            ...unitFinance.bankAccount,
            id: unitFinance.id,
            balanceValue: unitFinance.balanceValue,
            deletedAt: unitFinance.deletedAt,
          };
        });
        setRows(newRows);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadBankAccounts();
  }, [loadBankAccounts]);

  return (
    <Container>
      <CrudTable
        title={'Gestão de Contas Bancárias'}
        modelActions={UnitFinance.actions}
        columns={columns}
        customColumns={[
          {
            id: 'status',
            label: 'Situação',
            minWidth: 50,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () =>
              history.push('/financial/config/bank-account/create'),
          },
          update: {
            onClick: (id) =>
              history.push(`/financial/config/bank-account/update/${id}`),
            selector: (row) => !row?.deletedAt,
          },
          link: {
            icon: <Receipt />,
            onClick: (id) => {
              setSelectedId(id);
              setOpen(true);
            },
            maxDepth: 2,
            title: 'Vincular a um tipo de despesa',
            allowedActions: [
              [UnitFinance.actions.findById, UnitFinance.actions.update],
            ],
            selector: (row) => !row?.deletedAt,
          },
          adjustBalance: {
            icon: <PriceChange />,
            onClick: (id, row) => {
              setUnitFinance(row);
              setOpenAdjustDialog(true);
            },
            title: 'Ajustar Saldo',
            allowedActions: [UnitFinance.actions.adjustBalance],
            selector: (row) => !row?.deletedAt,
          },
          showAdjustments: {
            icon: <ClockHistory />,
            onClick: (unitFinanceId) =>
              history.push(
                `/financial/config/bank-account/${unitFinanceId}/adjustment-history`
              ),
            title: 'Visualizar Ajustes de Saldo',
            allowedActions: [UnitFinance.actions.getAdjustmentsHistory],
            selector: (row) => !row?.deletedAt,
          },
          customDelete: {
            icon: <Close />,
            onClick: (modelId) => {
              restApiClient.unitFinance
                .delete(modelId)
                .then(() => loadBankAccounts());
            },
            title: 'Desativar',
            selector: (row) =>
              !row?.deletedAt &&
              row?.balanceValue === 0 &&
              moment(row.date).diff(moment().add(-1, 'days'), 'days') === 0,
            allowedActions: [UnitFinance.actions.delete],
          },
          restore: {
            icon: <Restore />,
            onClick: (modelId) => {
              restApiClient.unitFinance
                .restore(modelId)
                .then(() => loadBankAccounts());
            },
            title: 'Ativar',
            selector: (row) => row?.deletedAt,
            allowedActions: [UnitFinance.actions.restore],
          },
        }}
        customCellRenderers={{
          expenseType: {
            renderer: (field, value, column, row) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value?.name ?? '-';
              }
            },
          },
          'bankAccount.account': {
            renderer: (field, value, column, row) => {
              return row.account
                ? `${row.account}${
                    row.accountDigit ? `-${row.accountDigit}` : ''
                  }`
                : '-';
            },
          },
          'bankAccount.agency': {
            renderer: (field, value, column, row) => {
              return row.agency
                ? `${row.agency}${row.agencyDigit ? `-${row.agencyDigit}` : ''}`
                : '-';
            },
          },
          balanceValue: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          status: {
            renderer: (field, value, column, row) => {
              return !row.deletedAt ? 'Ativo' : 'Desativado';
            },
          },
        }}
      />

      <ExpenseTypeDialog
        open={open}
        selectedId={selectedId}
        restModel={{ update: restApiClient.unitFinance.patchUpdate }}
        onClose={() => {
          setOpen(false);
        }}
        onSuccess={() => {
          loadBankAccounts();
          setOpen(false);
        }}
      />

      <AdjustUnitFinanceBalanceDialog
        open={openAdjustDialog}
        unitFinance={unitFinance}
        onCancel={() => setOpenAdjustDialog(false)}
        onSuccess={() => {
          setOpenAdjustDialog(false);
          loadBankAccounts();
        }}
      />
    </Container>
  );
}

export default BankAccountList;
