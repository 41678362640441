import React, { useState, useEffect } from 'react';
import Item from '../../../../forms/item';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Container } from './style';

function CreateItem(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [modelData, setModelData] = useState({
    hasConversion: false,
    hasRegistry: false,
    validityChecker: false,
    itemExists: true,
  });

  const handleAlertClose = (event, reason) => {
    setErrorMessage('');
  };

  const entryId = props.match.params?.id;

  useEffect(() => {
    if (entryId) {
      restApiClient.entry
        .findUnregisteredItemPendingById(entryId)
        .then((result) => {
          setModelData({
            ...modelData,
            name: result.data.pendencyList.pendencies.unregisteredItemPendency.name.toUpperCase(),
            barcode: result.data.pendencyList.pendencies
              .unregisteredItemPendency.barcode
              ? [
                  {
                    barcode:
                      result.data.pendencyList.pendencies
                        .unregisteredItemPendency.barcode,
                  },
                ].filter((x) => x)
              : undefined,
          });
        });
    }
  }, [entryId]);

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Item}
        schemaSelector={(modelSchema) => ({
          ...modelSchema.schema,
          required: [],
        })}
        uischemaSelector={(modelSchema, modelId) =>
          modelSchema.unregisteredUischema
        }
        modelData={modelData}
        restCreateData={async (item) => {
          const result = await restApiClient.item.createByPendentEntry(
            entryId,
            item
          );
          return result;
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        history={props.history}
        onError={(errors) => {
          console.log('teste');
          if (errors.barcode) {
            setErrorMessage(errors.barcode[0]);
          }
        }}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CreateItem;
