const Treatment = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        description: 'Nome',
        customErrors: '',
      },
      price: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      originId: {
        type: 'number',
        format: 'selectOriginDialog',
        customErrors: '',
      },
      executionTime: {
        type: 'string',
        format: 'time',
        customErrors: '',
      },
      considerTime: {
        type: 'boolean',
        customErrors: '',
      },
      color: {
        type: 'string',
        format: 'selectColorPicker',
        customErrors: '',
      },
    },
    required: [
      'name',
      'price',
      'color',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Tratamento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: 'Preço',
            scope: '#/properties/price',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tempo de Execução',
            scope: '#/properties/executionTime',
          },
          {
            type: 'Control',
            label: 'Considerar na agenda',
            scope: '#/properties/considerTime',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Preço',
            scope: '#/properties/originId',
          },
          {
            type: 'Control',
            label: 'Cor',
            scope: '#/properties/color',
          },
        ],
      },

    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 100, align: 'center' },
    { id: 'origin.name', label: 'Origem', minWidth: 100, align: 'center' },
  ],
  treatmentListSchema: [
    { id: 'name', label: 'Nome', minWidth: 50, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 50, align: 'center' },
    { id: 'origin.name', label: 'Origem', minWidth: 50, align: 'center' },
  ],
  actions: {
    getAll: 'TreatmentController@getAll',
    getAllByConsiderTime: 'TreatmentController@getAllByConsiderTime',
    findById: 'TreatmentController@findById',
    create: 'TreatmentController@create',
    update: 'TreatmentController@patchUpdate',
    delete: 'TreatmentController@delete',
  },
};

export default Treatment;
