import React, { useState } from 'react';
import { Container } from './styles';
import Dentist from '../../../../forms/dentist';
import restApiClient from '../../../../services/restApiClient';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import ShowPassDialog from '../../../../components/showPassDialog';
import { objectMap } from '../../../../extensions/object';
import isEqualWith from 'lodash/isEqualWith';
import isEmpty from 'lodash/isEmpty';


function DentistCreateUpdate(props) {
  const [password, setPassword] = useState('');
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Dentist}
        restGetDataById={ async (id) => {
          const result = await restApiClient.dentist.findById(id);
          let crrDentistBankAccounts = result.data.dentistBankAccounts;
          result.data.dentistBankAccounts = crrDentistBankAccounts[0];
          result.data = objectMap(result.data, (key, value) => { 
            if(value === null) return undefined;
            return value;
          })
          result.data.scheduleTypes = 
            result.data.dentistScheduleTypes.map((scheduleType) => scheduleType.type);
          return result;
        }}
        restCreateData={restApiClient.dentist.create}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          let added = undefined;
          let removed = undefined;
          if (modelData?.receivement !== "DEPÓSITO BANCÁRIO") {
            delete modelData?.dentistBankAccounts;
            removed = updateOriginalData?.dentistBankAccounts?.id;
          } else {
            if (updateOriginalData?.dentistBankAccounts) {
              const equal = isEqualWith(modelData?.dentistBankAccounts, updateOriginalData?.dentistBankAccounts, (obj1, obj2) => 
                obj1.holderName === obj2.holderName &&
                obj1.account === obj2.account &&
                obj1.accountDigit === obj2.accountDigit &&
                obj1.agency === obj2.agency &&
                obj1.agencyDigit === obj2.agencyDigit &&
                obj1.bank === obj2.bank
              );
              if (!equal) {
                added = modelData.dentistBankAccounts;
                delete added.id;
                removed = updateOriginalData?.dentistBankAccounts?.id;
              }
            } else {
              added = modelData.dentistBankAccounts;
              delete added.id;
            }
          }
          
          if(!isEmpty(modelData.scheduleTypes) || !isEmpty(updateOriginalData.scheduleTypes)) {
            const added = modelData?.scheduleTypes.filter(
              item => !updateOriginalData.dentistScheduleTypes.some(obj =>
                isEqualWith(obj, item, (obj1, obj2) =>
                  obj1.type === obj2,
                ),
              ),
            ).map(item => ({ type: item }));
            
            const removed = updateOriginalData.dentistScheduleTypes.filter(
              item => !modelData.scheduleTypes.some(obj =>
                isEqualWith(obj, item, (obj1, obj2) =>
                  obj1 === obj2.type,
                ),
              )).map(item => item.id);
            
            modelData.dentistScheduleTypes = { added, removed };
            updateOriginalData.dentistScheduleTypes = modelData.dentistScheduleTypes;
          }
          
          delete modelData?.scheduleTypes;
          delete updateOriginalData?.scheduleTypes;
          delete modelData?.dentistBankAccounts;
          delete updateOriginalData?.dentistBankAccounts;
          const result = await restApiClient.dentist.update(modelId, updateOriginalData, modelData, added, removed);
          return result;
        }}
        onCreateSuccess={(request) => {
          setPassword(request.data.password);
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        {...props}
      />
      <ShowPassDialog
        onClose={() => {
          props.history.goBack();
        }}
        password={password}
        open={password ? true : false}
      />
    </Container>
  );
}

export default DentistCreateUpdate;
