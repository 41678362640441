/* eslint-disable no-unused-expressions */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Recess from '../../forms/recess';
import { Container, DialogContent, StyledCreateUpdateForm } from './styles';
import restApiClient from '../../services/restApiClient';

function RecessDialog({ onSuccess, onError, onCancel, ...props }) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={Recess}
            restGetDataById={restApiClient.recess.findById}
            restCreateData={restApiClient.recess.create}
            onCreateSuccess={(request) => {
              onSuccess?.(request);
            }}
            onCancelClick={onCancel}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default RecessDialog;
