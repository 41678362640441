const DentistHourValue = {
  schema: {
    type: 'object',
    properties: {
      unitId: {
        type: 'number',
        customErrors: '',
      },
      dentistId: {
        type: 'number',
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
    },
    required: ['value'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de valor por hora na unidade',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: '',
            scope: '#/properties/value',
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Atualização de valor por hora na unidade',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: '',
            scope: '#/properties/value',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAllByDentistAndUnit: 'DentistHourValueController@getAllByDentistAndUnit',
    findById: 'DentistHourValueController@findById',
    updateDentistHourValues:
      'DentistHourValueController@updateDentistHourValues',
  },
};

export default DentistHourValue;
