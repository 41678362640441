import styled from 'styled-components';
import { AccountCircle } from '../../styles/icons';
import TreeView from '../../components/treeView';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    color: var(--secondary);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomTreeView = styled(TreeView)`
  width: 230px;
`;

export const AccountIcon = styled(AccountCircle)`
  width: 40px;
  height: 40px;
  margin-right: 8px;

  fill: var(--secondary);
`;

export const MiniAccountIcon = styled(AccountCircle)`
  width: 25px;
  height: 25px;

  fill: var(--secondary);
`;
