import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import DayJsUtils from '@date-io/dayjs';
import { useSecurity } from '../../../../extensions/hooks';
import SecuredItem from '../../../../components/securedItem';
import {
  Hidden,
  Select,
  FileCsv,
  FilePdf,
  AutoDiv,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectContainer,
  HeaderContainer,
  FooterContainer,
  ExportCsvButton,
  ExportPdfButton,
  ButtonContainer,
  CircularProgress,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import Unit from '../../../../forms/unit';
import Report from '../../../../forms/report';
import Dentist from '../../../../forms/dentist';
import restApiClient from '../../../../services/restApiClient';

function DentistReportList() {
  const [selectUnitVisible, setSelectUnitVisible] = useState(false);
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [selectDentistVisible, setSelectDentistVisible] = useState(false);
  const [units, setUnits] = useState(null);
  const [dentists, setDentists] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedDentist, setSelectedDentist] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateTo, setMaxDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [unitError, setUnitError] = useState('');
  const [dentistError, setDentistError] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const [reportError, setReportError] = useState('');
  const [finalDateError, setFinalDateError] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);

  const csvSecurity = new Map();
  csvSecurity.set('finishedProcedures', [
    [
      Unit.actions.getAllByType,
      Report.actions.getFinishedProceduresCSVReport,
      Dentist.actions.getAll,
    ],
  ]);
  csvSecurity.set('finishedHours', [
    [
      Unit.actions.getAll,
      Report.actions.getFinishedHoursCSVReport,
      Dentist.actions.getAll,
    ],
  ]);
  csvSecurity.set('finishedPatients', [
    [
      Unit.actions.getAll,
      Report.actions.getFinishedPatientsCSVReport,
      Dentist.actions.getAll,
    ],
  ]);
  csvSecurity.set('substitutePayments', [
    [
      Unit.actions.getAll,
      Report.actions.getFixedPaymentsCSVReport,
      Dentist.actions.getAll,
    ],
  ]);

  const pdfSecurity = new Map();

  const listReports = [
    useSecurity(
      { value: 'finishedProcedures', label: 'Pagamento Procedimento' },
      [
        [
          Unit.actions.getAll,
          Report.actions.getFinishedProceduresCSVReport,
          Dentist.actions.getAll,
        ],
      ]
    ),
    useSecurity({ value: 'finishedHours', label: 'Pagamento Hora' }, [
      [
        Unit.actions.getAll,
        Report.actions.getFinishedHoursCSVReport,
        Dentist.actions.getAll,
      ],
    ]),
    useSecurity({ value: 'finishedPatients', label: 'Pagamento Paciente' }, [
      [
        Unit.actions.getAll,
        Report.actions.getFinishedPatientsCSVReport,
        Dentist.actions.getAll,
      ],
    ]),
    useSecurity(
      { value: 'substitutePayments', label: 'Pagamento Substitutos(Fixos)' },
      [
        [
          Unit.actions.getAll,
          Report.actions.getFixedPaymentsCSVReport,
          Dentist.actions.getAll,
        ],
      ]
    ),
  ].filter((x) => !!x);

  useEffect(() => {
    if (
      [
        'finishedProcedures',
        'finishedHours',
        'finishedPatients',
        'substitutePayments',
      ].includes(selectedReport)
    ) {
      restApiClient.unit
        .getAllByType('clinic', null, null, null, { name: 'asc' })
        .then((e) => {
          setUnits(e.data);
        });
    }
  }, [selectedReport]);

  useEffect(() => {
    if (
      ['finishedProcedures', 'finishedHours', 'finishedPatients'].includes(
        selectedReport
      )
    ) {
      restApiClient.dentist
        .getAll(null, null, null, { name: 'asc' })
        .then((e) => {
          setDentists(e.data);
        });
    }
  }, [selectedReport]);

  async function getFinishedProceduresCSVReport(unitId, dentistId) {
    setFetching(true);
    restApiClient.attendance
      .getFinishedProceduresCsvReport(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId,
        dentistId
      )
      .then(() => {
        setFetching(false);
      });
  }

  async function getFinishedHoursCSVReport(unitId, dentistId) {
    setFetching(true);
    restApiClient.dentistWorkedHour
      .getFinishedHoursCSVReport(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId,
        dentistId
      )
      .then(() => {
        setFetching(false);
      });
  }

  async function getFinishedPatientsCSVReport(unitId, dentistId) {
    setFetching(true);
    restApiClient.finishedPatientService
      .getFinishedPatientsCSVReport(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId,
        dentistId
      )
      .then(() => {
        setFetching(false);
      });
  }

  async function getFixedPaymentsCSVReport(unitId, dentistId) {
    setFetching(true);
    restApiClient.substitute
      .getFixedPaymentsCSVReport(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then(() => {
        setFetching(false);
      });
  }

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios de Dentistas</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setSelectedUnit('');
                setDentistError(false);
                setSelectedDentist('');
                setUnitError(false);
                setDateFromError(false);
                setReportError('');
                switch (e.target.value) {
                  case 'finishedProcedures':
                    setSelectUnitVisible(true);
                    setSelectDentistVisible(true);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    break;
                  case 'finishedHours':
                    setSelectUnitVisible(true);
                    setSelectDentistVisible(true);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    break;
                  case 'finishedPatients':
                    setSelectUnitVisible(true);
                    setSelectDentistVisible(true);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    break;
                  case 'substitutePayments':
                    setSelectUnitVisible(true);
                    setSelectDentistVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    break;
                  default:
                    setSelectUnitVisible(false);
                    setSelectDentistVisible(false);
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
        <Hidden xsUp={!selectUnitVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(unitError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnit || ''}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                  setUnitError('');
                }}
              >
                {[
                  [
                    'finishedProcedures',
                    'finishedHours',
                    'finishedPatients',
                    'substitutePayments',
                  ].includes(selectedReport) ? (
                    <MenuItem value={-1} key={'all'}>
                      {'TODAS UNIDADES'}
                    </MenuItem>
                  ) : (
                    ''
                  ),
                ].concat(
                  units?.values.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>
                {!isEmpty(unitError) ? unitError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
        <Hidden xsUp={!selectDentistVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(dentistError)}
            >
              <InputLabel htmlFor="outlined-selectedDentist-native-simple">
                Selecione um Dentista
              </InputLabel>
              <Select
                value={selectedDentist || ''}
                label="Selecione um Dentista"
                onChange={(e) => {
                  setSelectedDentist(e.target.value);
                  setDentistError('');
                }}
              >
                {[
                  [
                    'finishedProcedures',
                    'finishedHours',
                    'finishedPatients',
                  ].includes(selectedReport) ? (
                    <MenuItem value={-1} key={'all'}>
                      {'TODOS DENTISTAS'}
                    </MenuItem>
                  ) : (
                    ''
                  ),
                ].concat(
                  dentists?.values.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>
                {!isEmpty(dentistError) ? dentistError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
      </AutoDiv>
      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setInitialDateError(value)}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateTo}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateTo
              ).format('DD/MM/YYYY')}.`}
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setFinalDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={csvSecurity.get(selectedReport)}>
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async () => {
                        switch (selectedReport) {
                          case 'finishedProcedures':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit && selectedDentist) {
                                await getFinishedProceduresCSVReport(
                                  selectedUnit,
                                  selectedDentist
                                );
                              } else {
                                setUnitError(
                                  !selectedUnit
                                    ? 'Selecione uma opção válida.'
                                    : false
                                );
                                setDentistError(
                                  !selectedDentist
                                    ? 'Selecione uma opção válida.'
                                    : false
                                );
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'finishedHours':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit && selectedDentist) {
                                await getFinishedHoursCSVReport(
                                  selectedUnit,
                                  selectedDentist
                                );
                              } else {
                                setUnitError(
                                  !selectedUnit
                                    ? 'Selecione uma opção válida.'
                                    : false
                                );
                                setDentistError(
                                  !selectedDentist
                                    ? 'Selecione uma opção válida.'
                                    : false
                                );
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'finishedPatients':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit && selectedDentist) {
                                await getFinishedPatientsCSVReport(
                                  selectedUnit,
                                  selectedDentist
                                );
                              } else {
                                setUnitError(
                                  !selectedUnit
                                    ? 'Selecione uma opção válida.'
                                    : false
                                );
                                setDentistError(
                                  !selectedDentist
                                    ? 'Selecione uma opção válida.'
                                    : false
                                );
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'substitutePayments':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit) {
                                await getFixedPaymentsCSVReport(selectedUnit);
                              } else {
                                setUnitError(
                                  !selectedUnit
                                    ? 'Selecione uma opção válida.'
                                    : false
                                );
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={pdfSecurity.get(selectedReport)}>
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async () => {
                        switch (selectedReport) {
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>
    </Container>
  );
}

export default DentistReportList;
