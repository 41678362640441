import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import {
  withJsonFormsCellProps,
  withJsonFormsEnumProps,
} from '@jsonforms/react';
import SelectOutsourcedDialogControl from '../../components/selectOutsourcedDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectOutsourcedDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectOutsourcedDialog'
  )
);

const customSelectOutsourcedDialogControlTester = rankWith(
  4,
  isSelectOutsourcedDialogControl
);

export default {
  tester: customSelectOutsourcedDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectOutsourcedDialogControl),
};
