import styled from 'styled-components';
import { ViewShow as MaterialViewShow } from '../../../../../../styles/icons';
import { Button as MaterialButton } from '@material-ui/core';


export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
