import React, { useState, useEffect } from 'react';
import Item from '../../../forms/item';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import {
  Container,
  SelectContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from './styles';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

function ItemList({ history }) {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [units, setUnits] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(user?.unitId ?? '');

  const columns = Item.searchTableSchema;

  async function loadItems() {
    if (selectedUnit) {
      restApiClient.item
        .getAllWithStock(
          selectedUnit,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('name', searchBy)
                .or()
                .like('brand', searchBy)
                .or()
                .like('barcode.barcode', searchBy)
                .or()
                .like('itemLocators.locator', searchBy)
                .or()
                .like('group.name', searchBy)
                .or()
                .like('group.parent.name', searchBy)
                .or()
                .like('group.parent.parent.name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }

  useEffect(() => {
    loadItems();
  }, [rowsPerPage, page, orderBy, searchBy, selectedUnit]);

  useEffect(() => {
    restApiClient.unit.getAll().then((e) => {
      setUnits(e.data);
    });
  }, []);

  return (
    <Container>
      <CrudTable
        title={'Busca de Itens'}
        customHeaderComponent={
          <SelectContainer>
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnit || ''}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                }}
              >
                {[
                  <MenuItem value="" key={'empty'}>
                    <em>Nenhuma</em>
                  </MenuItem>,
                ].concat(
                  units?.values.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </SelectContainer>
        }
        modelActions={Item.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          barcode: {
            renderer: (field, value) => {
              return !isEmpty(value)
                ? value.map((item) => item.barcode).join(', ')
                : '-';
            },
          },
          'stock.availableQuantity': {
            renderer: (field, value) => {
              return value ?? 0;
            },
          },
          group: {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value.name;
              }
            },
          },
          itemLocators: {
            renderer: (field, value) => {
              return value[0]?.locator ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default ItemList;
