import styled from 'styled-components';
import CrudTable from '../crudTable';
import MaterialDialog from '@material-ui/core/Dialog';
import { Button as MaterialButton } from '@material-ui/core';
import MaterialDialogActions from '@material-ui/core/DialogActions';
import { GetApp as MaterialAddBox } from '@styled-icons/material/GetApp';
import { DialogContent as MaterialDialogContent, } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Dialog = styled(MaterialDialog)``;

export const DialogActions = styled(MaterialDialogActions)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const DialogContent = styled(MaterialDialogContent)`
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 20%;
  color: #ffffff !important;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-top: 10px;
  margin-bottom: 5px;
  width: 95%;
`;

export const AddBox = styled(MaterialAddBox)`
  width: 20px;
  height: 20px;
  fill: #808080;
`;
