import React, { useEffect, useState } from 'react';
import DentistConsumption from '../../../../../forms/dentistConsumption';
import CrudTable from '../../../../../components/crudTable';
import { BackButton, Container, StyledTypography } from './styles';
import restApiClient from '../../../../../services/restApiClient';
import FilterTree from '../../../../../helpers/filterTree';
import moment from 'moment/moment';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

function ManageDentistConsumptionUnitClinic(props) {
  const unitId = useSelector((x) => x.authReducer.user.unitId);
  const dentistConsumptionId = parseInt(props.match?.params?.id ?? -1);
  const beginDate = props.location?.state?.beginDate;
  const endDate = props.location?.state?.endDate;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [total, setTotal] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const columns = DentistConsumption.dentistConsumptionValuesTableSchema;

  useEffect(() => {
    restApiClient.dentistConsumptionValue
      .getAllByDentistConsumptionId(
        dentistConsumptionId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('specialty.name', searchBy).toString()
          : null,
        orderBy
      )
      .then((result) => {
        let newRows = result.data;
        newRows.values = result.data.values.map((item) => ({
          ...item,
          name: item?.specialty.name,
        }));

        if (isEmpty(itemList)) setItemList(cloneDeep(newRows.values));
        setOriginalList(newRows.values);
        setRows(newRows);
      });
  }, [
    rowsPerPage,
    page,
    orderBy,
    searchBy,
    beginDate,
    endDate,
    unitId,
    dentistConsumptionId,
  ]);

  useEffect(() => {
    setTotal(
      sumBy(originalList, (item) => {
        let obj, subtotal;
        if ((obj = itemList.find((i) => i.specialtyId === item.specialtyId))) {
          subtotal = parseFloat(obj.subtotal ?? 0);
        } else {
          subtotal = 0;
        }
        return subtotal;
      })
    );
  }, [originalList, itemList]);

  return (
    <Container>
      <CrudTable
        title={`Consumo - ${moment(beginDate).format('DD/MM/YYYY')} à ${moment(
          endDate
        ).format('DD/MM/YYYY')}`}
        customInfosComponent={
          <StyledTypography variant="h6">
            Valor total:{' '}
            {(!isNaN(total) ? total : 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledTypography>
        }
        customFooterComponent={
          <>
            <BackButton
              onClick={() => props.history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
          </>
        }
        modelActions={DentistConsumption.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        availableRowsPerPage={[15]}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          value: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          correction: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          subtotal: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
    </Container>
  );
}

export default ManageDentistConsumptionUnitClinic;
