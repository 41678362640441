import { and, isStringControl, rankWith, schemaMatches } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import CustomAttendanceDateControl from '../../components/customAttendanceDateControl';
import isEmpty from 'lodash/isEmpty';

const isDateControl = and(
  isStringControl,
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'customAttendanceDate'
  )
);

const materialTextControlTester = rankWith(5, isDateControl);

export default {
  tester: materialTextControlTester,
  renderer: withJsonFormsControlProps(CustomAttendanceDateControl),
};
