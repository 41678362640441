import React, { useState, useEffect } from 'react';
import restApiClient from '../../../../services/restApiClient';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import ReportItemsStock from '../../../../pdf/reportItemsStock';
import ReportStockValue from '../../../../pdf/reportStockValue';
import ReportStockComsumptionUnit from '../../../../pdf/reportStockComsumptionUnit';
import ReportItemWithdraw from '../../../../pdf/reportItemWithdraw';
import ReportStockHistoryValue from '../../../../pdf/reportStockHistoryValue';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {
  HeaderContainer,
  FooterContainer,
  Container,
  Hidden,
  AutoDiv,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  SelectContainer,
  InputLabel,
  ExportCsvButton,
  ExportPdfButton,
  FileCsv,
  FilePdf,
  ButtonContainer,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import { useSecurity } from '../../../../extensions/hooks';
import Report from '../../../../forms/report';
import Item from '../../../../forms/item';
import Unit from '../../../../forms/unit';
import Group from '../../../../forms/group';
import SecuredItem from '../../../../components/securedItem';

function StockReportList({ history }) {
  const user = useSelector((x) => x.authReducer.user);
  const [selectUnitVisible, setSelectUnitVisible] = useState(false);
  const [selectGroupVisible, setSelectGroupVisible] = useState(false);
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [userUnit, setUserUnit] = useState(null);
  const [units, setUnits] = useState(null);
  const [groups, setGroups] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateTo, setMaxDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [unitError, setUnitError] = useState('');
  const [groupError, setGroupError] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const [reportError, setReportError] = useState('');
  const [finalDateError, setFinalDateError] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [dateIntervalMonthVisible, setDateIntervalMonthVisible] =
    useState(false);
  const [selectedDateMonth, setSelectedDateMonth] = useState(null);
  const [maxDateMonth, setMaxDateMonth] = useState(new Date());
  const [dateMonthError, setDateMonthError] = useState(false);

  const pdfSecurity = new Map();
  pdfSecurity.set('stockItems', [
    Item.actions.getAllItemInStockByUnit,
    Unit.actions.findById,
  ]);
  pdfSecurity.set('stockItemsByGroup', [
    Item.actions.getAllItemInStockByUnitAndGroup,
  ]);
  pdfSecurity.set('stockConsumptionUnit', [
    Report.actions.getAllGroupValuesByPeriod,
    Report.actions.getAllStockHistoriesValuesByPeriod,
    Unit.actions.getAll,
    Group.actions.getAllAsGroup,
  ]);
  pdfSecurity.set('withdrawItems', [
    Report.actions.getItemWithdrawReportByUnit,
    Unit.actions.getAll,
  ]);
  pdfSecurity.set('stockValue', [
    Report.actions.getStockValueByUnitId,
    Unit.actions.getAll,
    Group.actions.getAllAsGroup,
  ]);
  pdfSecurity.set('historyValueMonth', [Report.actions.getStockHistoryValue]);

  const csvSecurity = new Map();
  csvSecurity.set('stockItems', [Report.actions.getCsvItemInStockByUnit]);
  csvSecurity.set('stockItemsByGroup', [
    Report.actions.getCsvItemInStockByUnitAndGroup,
  ]);
  csvSecurity.set('withdrawItems', [
    Report.actions.getCsvItemWithdrawReportByUnit,
  ]);
  csvSecurity.set('stockAdjustmentHistory', [
    Report.actions.getCsvItemWithdrawReportByUnit,
  ]);
  csvSecurity.set('transferredItems', [Report.actions.getCsvTransferredItems]);
  csvSecurity.set('historyItems', [
    Report.actions.getStockHistoryValueCSVReport,
  ]);

  const listReports = [
    useSecurity({ value: 'stockItems', label: 'Itens do Estoque (Todos)' }, [
      [Report.actions.getCsvItemInStockByUnit, Unit.actions.findById],
      [Item.actions.getAllItemInStockByUnit, Unit.actions.findById],
    ]),
    useSecurity(
      { value: 'stockItemsByGroup', label: 'Itens do Estoque (Grupo)' },
      [
        [
          Report.actions.getCsvItemInStockByUnitAndGroup,
          Unit.actions.findById,
          Group.actions.getAllAsGroup,
        ],
        [
          Item.actions.getAllItemInStockByUnitAndGroup,
          Unit.actions.findById,
          Group.actions.getAllAsGroup,
        ],
      ]
    ),
    useSecurity(
      { value: 'stockConsumptionUnit', label: 'Custo Parcial (Grupo)' },
      [
        [
          Report.actions.getAllGroupValuesByPeriod,
          Report.actions.getAllStockHistoriesValuesByPeriod,
          Unit.actions.getAll,
          Group.actions.getAllAsGroup,
        ],
      ]
    ),
    useSecurity({ value: 'stockValue', label: 'Valor do Estoque (Grupo)' }, [
      [
        Report.actions.getStockValueByUnitId,
        Unit.actions.getAll,
        Group.actions.getAllAsGroup,
      ],
    ]),
    useSecurity({ value: 'withdrawItems', label: 'Relatório de Baixas' }, [
      [Report.actions.getItemWithdrawReportByUnit, Unit.actions.getAll],
      [Report.actions.getCsvItemWithdrawReportByUnit, Unit.actions.getAll],
    ]),
    useSecurity(
      { value: 'stockAdjustmentHistory', label: 'Relatório de Ajustes' },
      [
        [
          Report.actions.getCsvStockAdjustmentHistoryReportByUnit,
          Unit.actions.getAll,
        ],
      ]
    ),
    useSecurity({ value: 'transferredItems', label: 'Itens Transferidos' }, [
      [Report.actions.getCsvTransferredItems, Unit.actions.getAll],
    ]),
    useSecurity(
      { value: 'historyValueMonth', label: 'Valor Histórico (Mês)' },
      [[Report.actions.getStockHistoryValue]]
    ),
    useSecurity(
      { value: 'historyItems', label: 'Histórico de Itens (Todos)' },
      [[Report.actions.getStockHistoryValueCSVReport]]
    ),
  ].filter((x) => !!x);

  useEffect(() => {
    if (['stockItems', 'stockItemsByGroup'].includes(selectedReport)) {
      if (selectedReport === 'stockItemsByGroup') {
        restApiClient.unit.findById(user?.unitId ?? '').then((e) => {
          setUserUnit(e.data);
        });
      } else if (selectedReport === 'stockItems' && selectedUnit) {
        restApiClient.unit.findById(selectedUnit).then((e) => {
          setUserUnit(e.data);
        });
      }
    }
  }, [selectedReport, selectedUnit]);

  useEffect(() => {
    if (
      [
        'withdrawItems',
        'stockConsumptionUnit',
        'stockValue',
        'stockAdjustmentHistory',
        'transferredItems',
        'stockItems',
        'historyValueMonth',
      ].includes(selectedReport)
    ) {
      restApiClient.unit.getAll().then((e) => {
        setUnits(e.data);
      });
    }
  }, [selectedReport]);

  useEffect(() => {
    if (
      [
        'stockConsumptionUnit',
        'stockValue',
        'stockItemsByGroup',
        'historyValueMonth',
      ].includes(selectedReport)
    ) {
      restApiClient.group.getAllAsGroup().then((e) => {
        setGroups(e.data);
      });
    }
  }, [selectedReport]);

  async function getItemWithdrawCsv(unitId = null) {
    setFetching(true);
    restApiClient.itemWithdraw
      .getCsvWithdrawReportByUnit(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        setFetching(false);
      });
  }

  async function getStockAdjustmentHistoryCsv(unitId = null) {
    setFetching(true);
    restApiClient.stockHistory
      .getCsvStockAdjustmentHistoryReportByUnit(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        setFetching(false);
      });
  }

  async function getItemWithdrawPdf(unitId = null) {
    setFetching(true);
    restApiClient.itemWithdraw
      .getItemWithdrawReportByUnit(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportItemWithdraw.buildReportItemWithdrawPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data
        );
        doc.save(`RELATORIO_BAIXAS.pdf`);
        setFetching(false);
      });
  }

  async function loadData(unitId = null) {
    setFetching(true);
    restApiClient.transfer
      .getAllGroupValuesByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        loadAdjusts(e.data, unitId);
      });
  }

  async function loadAdjusts(transferData, unitId = null) {
    restApiClient.stockHistory
      .getAllStockHistoriesValuesByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        let stockHistoryData = e.data;
        let newStockComsumption = transferData;
        Object.keys(stockHistoryData).forEach(function (unit) {
          //para cada unidade do ajuste verificar se a unidade existe nas transferências
          // caso exista adicione apenas o result dentro do grupo
          // caso não exista adicione o grupo a unidade
          if (unit !== 'totalGroup') {
            Object.keys(stockHistoryData[unit].groups).forEach(function (
              value
            ) {
              if (newStockComsumption[unit]) {
                if (newStockComsumption[unit].groups[value]) {
                  const groupValue = newStockComsumption[unit].groups[value];
                  groupValue.result =
                    stockHistoryData[unit].groups[value].result;
                  groupValue.loss = stockHistoryData[unit].groups[value].loss;
                  newStockComsumption[unit].groups[value] = groupValue;
                } else {
                  newStockComsumption[unit].groups[value] =
                    stockHistoryData[unit].groups[value];
                }
              } else {
                newStockComsumption[unit] = stockHistoryData[unit];
              }
              newStockComsumption[unit].totalAdjustUnit =
                stockHistoryData[unit]?.totalAdjustUnit;
            });
          } else {
            Object.keys(stockHistoryData[unit].groups).forEach(function (
              value
            ) {
              if (stockHistoryData[unit].groups[value])
                if (newStockComsumption[unit]) {
                  if (newStockComsumption[unit].groups[value]) {
                    const groupValue = newStockComsumption[unit].groups[value];
                    groupValue.result =
                      stockHistoryData[unit].groups[value].result;
                    groupValue.loss = stockHistoryData[unit].groups[value].loss;
                    newStockComsumption[unit].groups[value] = groupValue;
                  } else {
                    newStockComsumption[unit].groups[value] =
                      stockHistoryData[unit].groups[value];
                  }
                } else {
                  newStockComsumption[unit] = stockHistoryData[unit];
                }
              newStockComsumption[unit].totalAdjustGlobal =
                stockHistoryData[unit]?.totalAdjustGlobal;
            });
          }
        });
        const doc = ReportStockComsumptionUnit.buildStockComsumptionPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          units,
          groups,
          newStockComsumption
        );
        doc.save(`RELATORIO_CUSTO_PARCIAL.pdf`);
        setFetching(false);
      });
  }

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios do Estoque</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setSelectedUnit('');
                setReportError('');
                switch (e.target.value) {
                  case 'stockItems':
                    setExportCsvVisible(true);
                    setExportPdfVisible(true);
                    setSelectUnitVisible(true);
                    setDateIntervalVisible(false);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(false);
                    break;
                  case 'stockItemsByGroup':
                    setExportCsvVisible(true);
                    setExportPdfVisible(true);
                    setSelectUnitVisible(false);
                    setDateIntervalVisible(false);
                    setSelectGroupVisible(true);
                    setDateIntervalMonthVisible(false);
                    break;
                  case 'stockConsumptionUnit':
                    setSelectUnitVisible(true);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(false);
                    break;
                  case 'withdrawItems':
                    setSelectUnitVisible(true);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(false);
                    break;
                  case 'stockValue':
                    setSelectUnitVisible(true);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(false);
                    setExportCsvVisible(false);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(false);
                    break;
                  case 'stockAdjustmentHistory':
                    setSelectUnitVisible(true);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(false);
                    break;
                  case 'transferredItems':
                    setSelectUnitVisible(true);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(false);
                    break;
                  case 'historyValueMonth':
                    setSelectUnitVisible(false);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(false);
                    setExportCsvVisible(false);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(true);
                    break;
                  case 'historyItems':
                    setSelectUnitVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setExportCsvVisible(true);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(true);
                    break;
                  default:
                    setSelectUnitVisible(false);
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setSelectGroupVisible(false);
                    setDateIntervalMonthVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
        <Hidden xsUp={!selectUnitVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(unitError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnit || ''}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                  setUnitError('');
                }}
              >
                {[
                  selectedReport !== 'transferredItems' &&
                  selectedReport !== 'stockItems' ? (
                    <MenuItem value={'all'} key={'all'}>
                      {'TODAS UNIDADES'}
                    </MenuItem>
                  ) : (
                    ''
                  ),
                ]
                  .concat(
                    units?.values.map((optionValue) =>
                      optionValue.type === 'distribution_center' &&
                      selectedReport !== 'stockValue' &&
                      selectedReport !== 'stockAdjustmentHistory' &&
                      selectedReport !== 'stockAdjustmentHistory' &&
                      selectedReport !== 'stockItems' ? (
                        ''
                      ) : (
                        <MenuItem value={optionValue.id} key={optionValue.id}>
                          {optionValue.name}
                        </MenuItem>
                      )
                    )
                  )
                  .concat([
                    <MenuItem value="" key={'empty'}>
                      <em>Nenhuma</em>
                    </MenuItem>,
                  ])}
              </Select>
              <FormHelperText>
                {!isEmpty(unitError) ? unitError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
        <Hidden xsUp={!selectGroupVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(groupError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma categoria
              </InputLabel>
              <Select
                value={selectedGroup || ''}
                label="Selecione uma categoria"
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                  setGroupError('');
                }}
              >
                {[
                  <MenuItem value="" key={'empty'}>
                    <em>Nenhuma</em>
                  </MenuItem>,
                ].concat(
                  groups?.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>
                {!isEmpty(groupError) ? groupError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
      </AutoDiv>

      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setInitialDateError(value)}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateTo}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateTo
              ).format('DD/MM/YYYY')}.`}
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setFinalDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <AutoDiv>
        <Hidden xsUp={!dateIntervalMonthVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-month"
              label="Mês/Ano"
              format="MM/YYYY"
              value={selectedDateMonth}
              views={['year', 'month']}
              onChange={(date) => {
                setDateMonthError(false);
                setSelectedDateMonth(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateMonth}
              maxDateMessage={`O mês deve ser menor do que ${dayjs(
                maxDateMonth
              ).format('MM/YYYY')}.`}
              onError={(value) => setDateMonthError(value)}
              helperText={dateMonthError ? 'Este campo é obrigatório.' : ''}
              error={dateMonthError}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem
                    allowedActions={[csvSecurity.get(selectedReport)]}
                  >
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'stockItems':
                            if (selectedUnit) {
                              setFetching(true);
                              restApiClient.item
                                .getCsvItemInStockByUnit(selectedUnit)
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setUnitError('Selecione uma opção válida.');
                            }
                            break;
                          case 'stockItemsByGroup':
                            if (selectedGroup) {
                              setFetching(true);
                              restApiClient.item
                                .getCsvItemInStockByUnitAndGroup(
                                  user?.unitId ?? '',
                                  selectedGroup
                                )
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setGroupError('Selecione uma opção válida.');
                            }
                            break;
                          case 'withdrawItems':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit == 'all') {
                                await getItemWithdrawCsv();
                              } else if (selectedUnit) {
                                await getItemWithdrawCsv(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'stockAdjustmentHistory':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit == 'all') {
                                await getStockAdjustmentHistoryCsv();
                              } else if (selectedUnit) {
                                await getStockAdjustmentHistoryCsv(
                                  selectedUnit
                                );
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'transferredItems':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit) {
                                setFetching(true);
                                restApiClient.transfer
                                  .getCsvTransferredItems(
                                    moment(selectedDateFrom).format(
                                      'DD-MM-YYYY'
                                    ),
                                    moment(selectedDateTo).format('DD-MM-YYYY'),
                                    selectedUnit
                                  )
                                  .then((e) => {
                                    setFetching(false);
                                  });
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'historyItems':
                            if (selectedDateMonth && !dateMonthError) {
                              setFetching(true);
                              restApiClient.monthlyStock
                                .getStockHistoryValueCSVReport(
                                  moment(selectedDateMonth).format('MM-YYYY')
                                )
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setDateMonthError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem
                    allowedActions={[pdfSecurity.get(selectedReport)]}
                  >
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'stockItems':
                            if (selectedUnit) {
                              setFetching(true);
                              restApiClient.item
                                .getAllItemInStockByUnit(selectedUnit)
                                .then((e) => {
                                  const doc = ReportItemsStock.buildItemListPdf(
                                    userUnit?.name,
                                    e.data.values
                                  );
                                  doc.save(
                                    `RELATORIO_ITENS_${userUnit?.name}.pdf`
                                  );
                                  setFetching(false);
                                });
                            } else {
                              setUnitError('Selecione uma opção válida.');
                            }
                            break;
                          case 'stockItemsByGroup':
                            if (selectedGroup) {
                              setFetching(true);
                              restApiClient.item
                                .getAllItemInStockByUnitAndGroup(
                                  user?.unitId ?? '',
                                  selectedGroup
                                )
                                .then((e) => {
                                  const doc = ReportItemsStock.buildItemListPdf(
                                    userUnit?.name,
                                    e.data.values
                                  );
                                  doc.save(
                                    `RELATORIO_ITENS_${userUnit?.name}_POR_GRUPO.pdf`
                                  );
                                  setFetching(false);
                                });
                            } else {
                              setGroupError('Selecione uma opção válida.');
                            }
                            break;
                          case 'stockConsumptionUnit':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit == 'all') {
                                await loadData();
                              } else if (selectedUnit) {
                                await loadData(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'stockValue':
                            setFetching(true);
                            restApiClient.stock
                              .getStockValueByUnitId(
                                selectedUnit === 'all' ? null : selectedUnit
                              )
                              .then((e) => {
                                const doc = ReportStockValue.buildStockValuePdf(
                                  units,
                                  groups,
                                  e.data
                                );
                                doc.save(`RELATORIO_VALOR_ESTOQUE.pdf`);
                                setFetching(false);
                              });
                            break;
                          case 'withdrawItems':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit == 'all') {
                                await getItemWithdrawPdf();
                              } else if (selectedUnit) {
                                await getItemWithdrawPdf(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'historyValueMonth':
                            if (selectedDateMonth && !dateMonthError) {
                              restApiClient.monthlyStock
                                .getStockHistoryValue(
                                  moment(selectedDateMonth).format('MM-YYYY')
                                )
                                .then((e) => {
                                  const doc =
                                    ReportStockHistoryValue.buildStockHistoryValuePdf(
                                      units,
                                      groups,
                                      e.data,
                                      selectedDateMonth
                                    );
                                  doc.save(
                                    `RELATORIO_VALOR_HISTORICO_ESTOQUE.pdf`
                                  );
                                  setFetching(false);
                                });
                            } else {
                              setDateMonthError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>
    </Container>
  );
}

export default StockReportList;
