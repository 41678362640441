import React from 'react';
import DentistOfficeHour from '../../forms/dentistOfficeHour';
import restApiClient from '../../services/restApiClient';
import {
  Container,
  Dialog,
  DialogContent,
  StyledCreateUpdateForm,
} from './styles';

function DentistOfficeHourDialog({
  onSuccess,
  onError,
  onCancel,
  selectedDentistOfficeHourId,
  chairDentistId,
  ...props
}) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            forceId={selectedDentistOfficeHourId}
            schemaSelector={(modelSchema, modelId) =>
              modelId ? modelSchema.updateSchema : modelSchema.schema
            }
            modelSchema={DentistOfficeHour}
            restGetDataById={restApiClient.officeHour.findById}
            restCreateData={restApiClient.officeHour.create}
            restUpdateData={restApiClient.officeHour.update}
            modelData={{
              chairDentistId: chairDentistId,
            }}
            onCreateSuccess={(request) => {
              onSuccess?.(request);
            }}
            onUpdateSuccess={async (request) => {
              await onSuccess?.(request);
            }}
            onCancelClick={onCancel}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default DentistOfficeHourDialog;
