import React, { useEffect } from 'react';
import restApiClient from '../../services/restApiClient';
import {
  FormControl,
  FormHelperText,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputLabel,
  NativeSelect,
  Container,
  LayerGroup,
  StyledAdornedTextField,
} from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';

const SelectGroupDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  config,
  errors,
  visible,
  noValidation,
  onSuccess,
  required,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState({});
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [selectedSubcategory, setSelectedSubcategory] = React.useState({});
  const [allGroup, setAllGroup] = React.useState([]);
  const [categoriesById, setCategoriesById] = React.useState([]);
  const [subcategoriesById, setSubcategoriesById] = React.useState([]);
  const [groupError, setGroupError] = React.useState([]);
  const [resultString, setResultString] = React.useState([]);

  useEffect(() => {
    if (data) {
      restApiClient.group.getTypeById(data).then((e) => {
        const response = e.data;
        switch (response.type) {
          case 'group':
            setSelectedGroup(response.group);
            setResultString(`${response.group.name}`);
            break;
          case 'category':
            setSelectedGroup(response.group);
            setSelectedCategory(response.category);
            setResultString(
              `${response.group.name} > ${response.category.name}`
            );
            break;
          case 'subcategory':
            setSelectedGroup(response.group);
            setSelectedCategory(response.category);
            setSelectedSubcategory(response.subcategory);
            setResultString(
              `${response.group.name} > ${response.category.name} > ${response.subcategory.name}`
            );
            break;
          default:
            break;
        }
      });
    }
  }, [data]);

  useEffect(() => {
    restApiClient.group.getAllAsGroup().then((e) => {
      setAllGroup(e.data);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedGroup)) {
      restApiClient.group
        .getAllChildrenByParentId(selectedGroup.id)
        .then((e) => {
          setCategoriesById(e.data);
        });
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (!isEmpty(selectedCategory)) {
      restApiClient.group
        .getAllChildrenByParentId(selectedCategory.id)
        .then((e) => {
          setSubcategoriesById(e.data);
        });
    }
  }, [selectedCategory]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <StyledAdornedTextField
          className={className}
          disabled={!enabled}
          // variant="outlined"
          size="small"
          error={!isEmpty(errors)}
          adornment={<LayerGroup />}
          clickableAdornment
          onAdornmentClick={() => {
            if (enabled) {
              setOpen(true);
            }
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={() => {
            if (enabled) {
              setOpen(true);
            }
          }}
          label="Grupo > Categoria > Subcategoria"
          InputLabelProps={{
            readOnly: true,
          }}
          required={required}
          value={resultString ?? ''}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
      </Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Categorização do Item</DialogTitle>
        <DialogContent>
          <FormControl error={!isEmpty(groupError)}>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
              Grupo
            </InputLabel>
            <NativeSelect
              value={selectedGroup?.id}
              onChange={(e) => {
                setSelectedGroup(
                  allGroup.find(
                    (item) => item.id === parseInt(e.target.value)
                  ) ?? {}
                );
                if (!isEmpty(groupError)) {
                  setGroupError({});
                }
                if (!isEmpty(selectedCategory)) {
                  setSelectedCategory({});
                }
                if (!isEmpty(selectedSubcategory)) {
                  setSelectedSubcategory({});
                }
              }}
            >
              <option aria-label="None" value="">
                Selecione
              </option>
              {allGroup.map((item) => (
                <option key={`group_${item.id}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </NativeSelect>
            <FormHelperText>
              {!isEmpty(groupError) ? groupError : ' '}
            </FormHelperText>
          </FormControl>
          {!isEmpty(selectedGroup) && !isEmpty(categoriesById) ? (
            <FormControl>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Categoria
              </InputLabel>
              <NativeSelect
                value={selectedCategory?.id}
                onChange={(e) => {
                  setSelectedCategory(
                    categoriesById.find(
                      (item) => item.id === parseInt(e.target.value) ?? {}
                    )
                  );
                  if (!isEmpty(selectedSubcategory)) {
                    setSelectedSubcategory({});
                  }
                }}
              >
                <option aria-label="None" value="">
                  Selecione
                </option>
                {categoriesById?.map((item) => (
                  <option key={`categoy_${item.id}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          ) : null}
          {!isEmpty(selectedCategory) && !isEmpty(subcategoriesById) ? (
            <FormControl>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Subcategoria
              </InputLabel>
              <NativeSelect
                value={selectedSubcategory?.id}
                onChange={(e) => {
                  setSelectedSubcategory(
                    subcategoriesById.find(
                      (item) => item.id === parseInt(e.target.value) ?? {}
                    )
                  );
                }}
              >
                <option aria-label="None" value="">
                  Selecione
                </option>
                {subcategoriesById?.map((item) => (
                  <option key={`subcategoy_${item.id}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectedGroup({});
              setSelectedCategory({});
              setSelectedSubcategory({});
              setOpen(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (noValidation) {
                if (!isEmpty(selectedSubcategory)) {
                  setResultString(
                    `${selectedGroup.name} > ${selectedCategory.name} > ${selectedSubcategory.name}`
                  );
                  onSuccess({
                    type: 'subcategory',
                    id: selectedSubcategory.id,
                  });
                } else if (!isEmpty(selectedCategory)) {
                  setResultString(
                    `${selectedGroup.name} > ${selectedCategory.name}`
                  );
                  onSuccess({ type: 'category', id: selectedCategory.id });
                } else if (!isEmpty(selectedGroup)) {
                  setResultString(`${selectedGroup.name}`);
                  onSuccess({ type: 'group', id: selectedGroup.id });
                } else {
                  setResultString(``);
                  onSuccess({ type: 'none' });
                }
                setOpen(false);
              } else {
                if (isEmpty(selectedGroup)) {
                  setGroupError('Campo Obrigatório');
                } else {
                  if (!isEmpty(selectedSubcategory)) {
                    setResultString(
                      `${selectedGroup.name} > ${selectedCategory.name} > ${selectedSubcategory.name}`
                    );
                    handleChange(path, selectedSubcategory.id);
                  } else if (!isEmpty(selectedCategory)) {
                    setResultString(
                      `${selectedGroup.name} > ${selectedCategory.name}`
                    );
                    handleChange(path, selectedCategory.id);
                  } else {
                    setResultString(`${selectedGroup.name}`);
                    handleChange(path, selectedGroup.id);
                  }
                  setOpen(false);
                }
              }
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Hidden>
  );
};

export default SelectGroupDialogControl;
