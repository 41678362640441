import styled from 'styled-components';
import {
  Box as MaterialBox,
  ViewShow as MaterialViewShow,
  Printer as MaterialPrinter,
  CalendarSync as MaterialCalendarSync
} from '../../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SelectContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PrintOS = styled.div`
  @media screen {
    display: none;
  }
`;

export const CalendarSync = styled(MaterialCalendarSync)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
