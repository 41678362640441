import React, { useState, useEffect } from 'react';
import BudgetItem from '../../../forms/budgetItem';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, CancelButton, FilePdf, ExportPdfButton } from './styles';
import BudgetPreview from '../../../pdf/budgetPreview';

function ViewBudgetItems({ match, history, location }) {
  const budgetId = match.params?.id;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [budget, setBudget] = useState('');
  const columns = BudgetItem.tableSchema;

  useEffect(() => {
    restApiClient.budgetItem
      .getAllByBudgetId(
        budgetId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('item.id', searchBy)
              .or()
              .like('item.name', searchBy)
              .or()
              .like('item.group.name', searchBy)
              .or()
              .like('item.group.parent.name', searchBy)
              .or()
              .like('item.group.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, budgetId]);

  useEffect(() => {
    restApiClient.budget.findById(budgetId).then((e) => {
      setBudget(e.data);
    });
  }, [budgetId]);

  return (
    <Container>
      <CrudTable
        title={`Visualizar todos os Itens do Orçamento #${budgetId}`}
        customFooterComponent={
          <>
            <ExportPdfButton
              type="button"
              variant="contained"
              color="primary"
              startIcon={<FilePdf />}
              onClick={async (e) => {
                const doc = BudgetPreview.buildBudgetPreviewPdf(budget);
                doc.save(`PREVIA_DE_ORCAMENTO_${budget.name}.pdf`);
              }}
            >
              Exportar para PDF
            </ExportPdfButton>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
        modelActions={BudgetItem.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          price: {
            renderer: (field, value) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          'item.group': {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value?.name;
              }
            },
          },
        }}
      />
    </Container>
  );
}

export default ViewBudgetItems;
