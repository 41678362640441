/* eslint-disable no-unused-expressions */
import React from 'react';
import ItemizedCheck from '../../forms/itemizedCheck';
import restApiClient from '../../services/restApiClient';
import CreateUpdateForm from '../createUpdateForm';
import { Container, Dialog } from './styles';

function CreateItemizedCheckDialog({
  onSuccess,
  onError,
  onCancel,
  cashRegisterValueId,
  ...props
}) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <CreateUpdateForm
          modelSchema={ItemizedCheck}
          restGetDataById={restApiClient.itemizedCheck.findById}
          restCreateData={restApiClient.itemizedCheck.create}
          restUpdateData={restApiClient.itemizedCheck.update}
          onCreateSuccess={(response) => {
            onSuccess?.({ ...response.data });
          }}
          onUpdateSuccess={(response) => {
            onSuccess?.({ ...response.data });
          }}
          onCancelClick={onCancel}
          modelData={{ cashRegisterValueId }}
          clearOnSuccess
          {...props}
        />
      </Dialog>
    </Container>
  );
}

export default CreateItemizedCheckDialog;
