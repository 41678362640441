import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import forEach from 'lodash/forEach';
import moment from 'moment/moment';

const ReportPayableExpenses = {
  buildPayableExpensesPdf: (from, to, result, title) => {
    const dates = result.dates;
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;

    doc.setFont('Arial');
    doc.setFontSize(10);
    doc.text(`Período: ${from} a ${to}`, 155, (y += 8));
    doc.text(
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds(),
      155,
      (y += 5)
    );
    doc.setFontSize(18);
    doc.text(
      title,
      doc.internal.pageSize.getWidth() / 2,
      (y += 10),
      { align: 'center' }
    );
    y += 3;
    //CORPO DO PDF
    forEach(dates, function (date, index) {
      // PARA CADA DATA
      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0);
      y += 10;
      doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
      doc.setFontSize(13);
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(`Data: ${moment(index).format('LL')}`, 5, (y += 6));
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      let bodyValue = [];
      forEach(date?.expenses, function (expense) {
        // PARA CADA EXPENSE
        let name;
        let expenseType;

        if (expense?.employeeId) {
          name = expense?.employee?.user?.name;
        } else if (expense?.patientId) {
          name = expense?.patient?.name;
        } else if (expense?.dentistId) {
          name = expense?.dentist?.user?.name;
        } else if (expense?.expensable) {
          name = expense?.expensable?.isPhysicalPerson
            ? expense?.expensable?.socialName
            : expense?.expensable?.fantasyName;
        } else {
          name = '-';
        }

        if (expense?.expenseType?.parent?.parent?.parent) {
          expenseType = `${expense.expenseType.parent.parent.parent.name} >
          ${expense.expenseType.parent.parent.name} > ${expense.expenseType.parent.name} > ${expense.expenseType.name}`;
        } else if (expense?.expenseType?.parent?.parent) {
          expenseType = `${expense.expenseType.parent.parent.name} > ${expense.expenseType.parent.name} > ${expense.expenseType.name}`;
        } else if (expense?.expenseType?.parent) {
          expenseType = `${expense.expenseType.parent.name} > ${expense.expenseType.name}`;
        } else {
          expenseType = expense.expenseType.name;
        }

        bodyValue.push([
          moment(expense.dueDate).format('DD/MM/YYYY'),
          name,
          expenseType,
          expense?.unit?.name ?? '-',
          expense?.observation ?? '-',
          expense?.paymentType?.name ?? '-',
          expense?.installmentNumber ?? '-',
          expense?.status ?? '-',
          `${parseFloat(expense?.value).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}`,
        ]);
      });
      doc.autoTable({
        startY: (y += 2),
        headStyles: {
          fillColor: [55, 112, 222],
          textColor: [255, 255, 255],
        },
        showHead: 'firstPage',
        head: [
          [
            'Venc',
            'Nome',
            'Tipo',
            'Unidade',
            'Observação',
            'Form pag',
            'Nº Parc',
            'Situação',
            'Valor',
          ],
        ],
        body: [
          ...bodyValue,
          [
            {
              content:
                `Subtotal Aberto: ${(date?.totalOpen ?? 0).toLocaleString(
                  'pt-br',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  }
                )}\n` +
                `Subtotal Previsão: ${(
                  date?.totalPrediction ?? 0
                ).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}\n` +
                `Subtotal Soma: ${(date?.total ?? 0).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`,
              colSpan: 9,
              styles: {
                halign: 'left',
                valign: 'middle',
                fontSize: 10,
                fontStyle: 'bold',
                textColor: [25, 25, 25],
                fillColor: [225, 225, 225],
              },
            },
          ],
        ],
        theme: 'grid',
        styles: {
          halign: 'center',
          valign: 'middle',
          fontSize: 8,
          textColor: '#000000',
        },
        columnStyles: {
          4: { cellWidth: 25 },
          8: { cellWidth: 25 },
        },
        margin: 5,
        rowPageBreak: 'avoid',
      });

      y = doc.lastAutoTable.finalY;
    });

    //TOTAL GLOBAL
    doc.autoTable({
      startY: y,
      body: [
        [
          {
            content:
              `Total Aberto: ${(result?.totalOpenGlobal ?? 0).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              )}\n` +
              `Total Previsão: ${(
                result?.totalPredictionGlobal ?? 0
              ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}\n` +
              `Total: ${(result?.totalGlobal ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}`,
            colSpan: 9,
            styles: {
              halign: 'right',
              valign: 'middle',
              lineWidth: 0.25,
              fontSize: 10,
              fontStyle: 'bold',
              lineColor: [169, 169, 169],
              textColor: [25, 25, 25],
              fillColor: [250, 250, 250],
            },
          },
        ],
      ],
      theme: 'plain',
      margin: 5,
      rowPageBreak: 'avoid',
    });
    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + '/' + String(pageCount), 200.5, 295.5);
    }
    return doc;
  },
};

export default ReportPayableExpenses;
