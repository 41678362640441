import styled from 'styled-components';
import {
  ViewShow as MaterialViewShow,
  GetApp as MaterialGetApp,
  AddBox as MaterialAddBox,
  Printer as MaterialPrinter,
} from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const GetApp = styled(MaterialGetApp)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
