import styled from 'styled-components';
import {
  Scheduler as MaterialScheduler,
  WeekView as MaterialWeekView,
  Appointments as MaterialAppointments,
  Toolbar as MaterialToolbar,
  DateNavigator as MaterialDateNavigator,
  TodayButton as MaterialTodayButton,
  AppointmentTooltip as MaterialAppointmentTooltip,
  DayView as MaterialDayView,
  Resources as MaterialResources,
  ViewSwitcher as MaterialViewSwitcher
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState as MaterialViewState } from '@devexpress/dx-react-scheduler';
import {
  FormControl as MaterialFormControl, InputLabel as MaterialInputLabel, MenuItem as MaterialMenuItem,
  Paper as MaterialPaper,
  Select as MaterialSelect,
} from '@material-ui/core';

export const Scheduler = styled(MaterialScheduler)``;

export const WeekView = styled(MaterialWeekView)``;

export const DayView = styled(MaterialDayView)``;

export const Resources = styled(MaterialResources)``;

export const Appointments = styled(MaterialAppointments)``;

export const Toolbar = styled(MaterialToolbar)``;

export const DateNavigator = styled(MaterialDateNavigator)``;

export const TodayButton = styled(MaterialTodayButton)``;

export const ViewState = styled(MaterialViewState)``;

export const ViewSwitcher = styled(MaterialViewSwitcher)``;

export const AppointmentTooltip = styled(MaterialAppointmentTooltip)``;

export const Container = styled.div`
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 600px;
`;

export const Paper = styled(MaterialPaper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const SchedulerContainer = styled.div``;

export const SelectContainer = styled.div`
  padding-top: 10px;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;
export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;
