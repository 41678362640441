import React, { useState, useEffect } from 'react';
import { Container, AutoDiv, StyledCarrousel } from './styles';
import OrderServiceStepCreateUpdate from '../createUpdateStep';
import restApiClient from '../../../../../services/restApiClient';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orderBy } from 'lodash';

function DentistCreateUpdateStepCarousel({ ...props }) {
  const [orderServiceStep, setOrderServiceStep] = useState([]);
  const [containsCreate, setContainsCreate] = useState([]);
  const [endProductId, setEndProductId] = useState([]);
  const [patientName, setPatientName] = useState([]);
  const [recordNumber, setRecordNumber] = useState([]);
  const [fetching, setFetching] = useState(false);
  const orderServiceId = props.match.params?.orderServiceId ?? -1;
  const workflowPosition = props.match.params?.workflowPosition ?? -1;

  const typeRequest = props.location?.state?.typeRequest;
  const dentistEdit = props?.location?.state?.dentistEdit;

  useEffect(() => {
    setFetching(true);
    restApiClient.orderServiceStep.getAllByOrderServiceId(orderServiceId, { id: 'desc' }).then((e) => {
      setOrderServiceStep(orderBy(e.data.values, 'workflowPosition', 'desc'));
      const crr = e.data.values.find(x => x.current);
      if (crr) {
        const containsNextStep = crr.orderService.endProduct.workflows.find(x => x.position === (parseInt(workflowPosition) + 1));
        setContainsCreate(!!containsNextStep && (crr.status === 'Etapa Concluída (Dentista)' || crr.status === 'Etapa Concluída e Recusada (Laboratório)'));
        setEndProductId(crr.orderService.endProduct.id);
        setPatientName(crr.orderService?.patient?.name);
        setRecordNumber(crr.orderService?.patient?.recordNumber);
      }
     
      setFetching(false);
    });
  }, [orderServiceId, workflowPosition]);
 
  const arrCreate = containsCreate && typeRequest === 'create' ? [
    <OrderServiceStepCreateUpdate
      key={-1}
      crrId={false}
      crrEndProductId={endProductId}
      crrViewForm={false}
      crrOrderServiceId={orderServiceId}
      crrWorkflowPosition={parseInt(workflowPosition) + 1}
      crrPatientName={patientName}
      crrRecordNumber={recordNumber}
      history={props.history}
      match={props.match}
    />
  ] : [];
 
  return (
    <Container>
      {fetching ? (
        <AutoDiv>
          <CircularProgress size={60} />
        </AutoDiv>
      ) : (
        <StyledCarrousel autoPlay={false}>
            {arrCreate.concat(
              orderServiceStep.map((row) => {
                return <OrderServiceStepCreateUpdate
                key={row.id}
                crrId={row.id}
                crrEndProductId={endProductId}
                crrViewForm={row.status !== 'Em Edição (Dentista)'}
                crrOrderServiceId={orderServiceId}
                crrWorkflowPosition={row.workflowPosition}
                crrOrderServiceStep={row}
                crrPatientName={row.orderService?.patient?.name}
                crrRecordNumber={row.orderService?.patient?.recordNumber}
                history={props.history}
                match={props.match}
                location={{ state: { 
                    date: row.createdAt,
                    dentistEdit: dentistEdit,
                  } 
                }}
              />;
              })
          )}
        </StyledCarrousel>
      )}
    </Container>
  );
}

export default DentistCreateUpdateStepCarousel;
