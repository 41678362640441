/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Container,
  AutoDiv,
  DialogContent,
  ActionsContainer,
  CancelButton,
  SubmitButton,
  CenterContainer,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  BodyTableCell,
  TableBody,
  TextStyledH6,
  TextStyledH5,
  DottedLine,
  HeaderTableCell,
  RightContainer,
  TotalText,
  Card,
} from './styles';
import ReactToPrint from 'react-to-print';
import restApiClient from '../../services/restApiClient';

function generateTransferToPrint(order, orderItem, userStock, globalTotal) {
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Container ref={ref}>
      <CenterContainer>
        <TextStyledH5>Transferência</TextStyledH5>
        <DottedLine />
      </CenterContainer>
      <AutoDiv>
        <TextStyledH6>Num Pedido: {order?.id}</TextStyledH6>
        <TextStyledH6>Destino: {order?.toUnit?.name}</TextStyledH6>
        <TextStyledH6>Solicitante: {order?.employee?.user?.name}</TextStyledH6>
        <TextStyledH6>Separador: {userStock?.name}</TextStyledH6>
      </AutoDiv>
      <CenterContainer>
        <DottedLine />
      </CenterContainer>
      <AutoDiv>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <HeaderTableCell align="left" style={{ minWidth: 30 }}>
                  Cod
                </HeaderTableCell>
                <HeaderTableCell align="left">Nome</HeaderTableCell>
                <HeaderTableCell align="center">Marca</HeaderTableCell>
                <HeaderTableCell align="center">Qtd</HeaderTableCell>
                <HeaderTableCell align="center">Valor Un</HeaderTableCell>
                <HeaderTableCell align="right">Subtotal</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderItem?.values
                .sort((a, b) => a.item.id - b.item.id)
                .map((row) => (
                  <>
                    <TableRow key={row.item.id}>
                      <BodyTableCell align="left">{row.item.id}</BodyTableCell>
                      <BodyTableCell colSpan={4} align="left">
                        {row.item.name}
                      </BodyTableCell>
                    </TableRow>
                    <TableRow key={row.item.id}>
                      <BodyTableCell colSpan={2} />
                      <BodyTableCell>{row.item.brand}</BodyTableCell>
                      <BodyTableCell align="center">
                        {row.approvedQuantity}
                      </BodyTableCell>
                      <BodyTableCell align="center">
                        {parseFloat(row.price).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </BodyTableCell>
                      <BodyTableCell align="right">
                        {row.subtotal.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </BodyTableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AutoDiv>
      <CenterContainer>
        <DottedLine />
      </CenterContainer>
      <RightContainer>
        <TotalText>
          Total:{' '}
          {globalTotal.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </TotalText>
      </RightContainer>
    </Container>
  ));

  return ComponentToPrint;
}

function PrintTransferDialog({ onCancel, selectedTransfer, ...props }) {
  const [order, setOrder] = useState(null);
  const [orderItems, setOrderItems] = useState(null);
  const [userStock, setUserStock] = useState(null);
  const [globalTotal, setGlobalTotal] = useState(0);
  const componentRef = useRef();
  const OrderToPrint = generateTransferToPrint(
    order,
    orderItems,
    userStock,
    globalTotal
  );

  async function loadOrder() {
    await restApiClient.orderItem
      .getAllByOrderWithItemStock(selectedTransfer)
      .then((e) => {
        setOrder(e.data?.values[0]?.order);
        let newOrderItems = e.data;
        newOrderItems.values = e.data.values.map((rowOrderItem) => {
          rowOrderItem.subtotal =
            rowOrderItem.approvedQuantity * rowOrderItem.price;
          if (isNaN(rowOrderItem.subtotal)) rowOrderItem.subtotal = 0;
          return rowOrderItem;
        });
        setOrderItems(newOrderItems);
        setGlobalTotal(
          newOrderItems.values.reduce((accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue.subtotal);
          }, 0)
        );
        loadUser();
      });
  }

  async function loadUser() {
    await restApiClient.user.profile().then((e) => {
      setUserStock(e);
    });
  }

  useEffect(() => {
    if (selectedTransfer !== null) loadOrder();
  }, [selectedTransfer]);
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <Card>
            <OrderToPrint ref={componentRef} />
          </Card>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <ReactToPrint
              trigger={() => (
                <SubmitButton variant="contained" color="primary">
                  Imprimir
                </SubmitButton>
              )}
              content={() => componentRef.current}
              pageStyle={`
                  @page {
                    size: 80mm 60cm;
                    margin: 2mm 2mm 2mm 2mm; 
                  }
                `}
            />
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default PrintTransferDialog;
