import styled from 'styled-components';
import MaterialFormControl from '@material-ui/core/FormControl';
import MaterialFormHelperText from '@material-ui/core/FormHelperText';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)``;

export const FormHelperText = styled(MaterialFormHelperText)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
