import React, { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  Container,
  CustomTreeView,
  HeaderContainer,
  ContentContainer,
  SelectContainer,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from './styles';
import isEqual from 'lodash/isEqual';
import restApiClient from '../../../../../services/restApiClient';
import DayJsUtils from '@date-io/dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';

function ClinicExpenseTypeTreeView() {
  const persistentFilter = JSON.parse(
    window.localStorage.getItem('clinicDreFilterState')
  ) || {
    selectedDateMonth: undefined,
  };
  const [data, setData] = useState({});
  const user = useSelector((x) => x.authReducer.user);
  const unit = user?.unit;
  const [selectedDateMonth, setSelectedDateMonth] = useState(
    persistentFilter.selectedDateMonth
      ? moment(persistentFilter.selectedDateMonth)
      : moment()
  );

  const loadExpenseTypes = useCallback(() => {
    if (selectedDateMonth.isValid()) {
      restApiClient.expenseType
        .getAllAsTreeClinic(
          selectedDateMonth.month() + 1,
          selectedDateMonth.year()
        )
        .then((e) => {
          if (!isEqual(data, e.data)) setData(e.data);
        });
    }
  }, [selectedDateMonth]);

  useEffect(() => {
    loadExpenseTypes();
  }, [loadExpenseTypes, selectedDateMonth]);

  useEffect(() => {
    window.localStorage.setItem(
      'clinicDreFilterState',
      JSON.stringify({
        selectedDateMonth,
      })
    );
  }, [selectedDateMonth]);

  return (
    unit?.showInDre && (
      <Container>
        <SelectContainer>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              style={{ width: '115px' }}
              margin="normal"
              id="date-month"
              label="Mês/Ano"
              format="MM/YYYY"
              value={selectedDateMonth}
              views={['year', 'month']}
              onChange={(date) => {
                setSelectedDateMonth(moment(date.toDate()));
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
            />
          </MuiPickersUtilsProvider>
        </SelectContainer>
        <Paper>
          <HeaderContainer>
            <h1>Visualizar DRE</h1>
          </HeaderContainer>

          <ContentContainer>
            <CustomTreeView
              nameSelector={(key, value) =>
                value.line ? `${value.line} - ${value.name}` : value.name
              }
              customContent={(key, value) => (
                <>
                  <span>
                    {`R$ ${value.value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </span>
                </>
              )}
              data={data}
            />
          </ContentContainer>
        </Paper>
      </Container>
    )
  );
}

export default ClinicExpenseTypeTreeView;
