const Entry = {
  tableSchemaItem: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    { id: 'hasConversion', label: 'Caixa', minWidth: 100, align: 'center' },
    {
      id: 'stock.availableQuantity',
      label: 'Quantidade',
      minWidth: 25,
      align: 'center',
    },
  ],
  unregisteredItemTableSchema: [
    {
      id: 'name',
      label: 'Nome do Item',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'barcode',
      label: 'Código de barras',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'pendency.pendencyList.entry.purchase.createdAt',
      label: 'Data de Criação',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    create: 'EntryController@create',
    getAllByPurchase: 'PurchaseEntryItemController@getAllByPurchase',
    getAllUnregisteredItem:
      'PurchaseEntryItemController@getAllUnregisteredItemPending',
    getAllUnregisteredItemPendingByPurchaseId:
      'PurchaseEntryItemController@getAllUnregisteredItemPendingByPurchaseId',
    findUnregisteredItemPendingById:
      'PurchaseEntryItemController@findUnregisteredItemPendingById',
  },
};

export default Entry;
