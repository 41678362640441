import React, { useState } from 'react';
import CashRegisterClose from '../../../../forms/cashRegisterClose';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import {
  BackButton,
  CancelButton,
  Container,
  FooterContainer,
  SaveButton,
  SubmitButton,
} from './styles';
import isEqualWith from 'lodash/isEqualWith';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SubmitDialog from '../../../../components/submitDialog';
import DisapprovalCashRegisterDialog from '../../../../components/disapprovalCashRegisterDialog';

function CashRegisterCloseCreateUpdate(props) {
  const cashRegisterCloseId = props.match.params.id ?? -1;
  const cashRegister = props.location?.state?.cashRegister ?? {};
  const readonly = props.location?.state?.readonly ?? false;
  const adjust = props.location?.state?.adjust ?? false;
  const evaluate = props.location?.state?.evaluate ?? false;
  const [errorMessage, setErrorMessage] = useState('');
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [openJustificationDialog, setOpenJustificationDialog] = useState(false);
  let error = false;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={CashRegisterClose}
        uischemaSelector={(modelSchema, modelId) => {
          return readonly || evaluate
            ? modelSchema.readonlyUischema
            : adjust
            ? modelSchema.adjustUischema
            : modelId
            ? modelSchema.updateUischema
            : modelSchema.uischema;
        }}
        restGetDataById={restApiClient.cashRegisterClose.findById}
        restCreateData={async (cashRegisterClose) => {
          const values = cashRegisterClose.cashRegisterValues;
          const hasDuplicates =
            values.length >
            uniqBy(values, (e) => [e.paymentTypeId, e.unitFinanceId].join())
              .length;

          if (!hasDuplicates) {
            return restApiClient.cashRegisterClose
              .create(cashRegisterClose)
              .then(async (result) => {
                await restApiClient.cashRegisterClose.setBlocked(
                  result.data.id
                );
              });
          } else {
            error = true;
            setErrorMessage(
              'Existe(m) conta(s) repetida(s)! Por favor, realoque o valor para alguma delas e exclua a(s) outra(s).'
            );
          }
        }}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          delete updateOriginalData.readonly;
          delete modelData.readonly;
          delete updateOriginalData.requestingUnitBoolean;
          delete modelData.requestingUnitBoolean;

          if (isEmpty(modelData.cashRegisterValues)) {
            error = true;
            setErrorMessage('É necessário informar pelo menos uma conta!');
          } else {
            const added = modelData.cashRegisterValues
              .filter((item) => !item.id)
              .filter(
                (item) =>
                  !updateOriginalData.cashRegisterValues.some((obj) =>
                    isEqualWith(
                      obj,
                      item,
                      (obj1, obj2) =>
                        obj1.paymentTypeId === obj2.paymentTypeId &&
                        obj1.unitFinanceId === obj2.unitFinanceId &&
                        obj1.value === obj2.value
                    )
                  )
              );

            //Verifica se já existe conta adicionada
            let duplicate = modelData.cashRegisterValues.reduce(
              (acc, item, index) => {
                let found = false;
                for (
                  let i = index + 1;
                  i < modelData.cashRegisterValues.length;
                  i++
                ) {
                  found |= isEqualWith(
                    item,
                    modelData.cashRegisterValues[i],
                    (obj1, obj2) =>
                      obj1.paymentTypeId === obj2.paymentTypeId &&
                      obj1.unitFinanceId === obj2.unitFinanceId
                  );
                }
                return acc || found;
              },
              false
            );

            if (duplicate) {
              error = true;
              setErrorMessage(
                'Existe(m) conta(s) repetida(s)! Por favor, realoque o valor para alguma delas e exclua a(s) outra(s).'
              );
            } else {
              error = false;
              const edited = modelData.cashRegisterValues
                .filter(
                  (item) =>
                    item.id &&
                    updateOriginalData.cashRegisterValues.find(
                      (obj) => item.id === obj.id
                    )?.value !== item.value
                )
                .map((item) => ({ id: item.id, value: item.value }));

              const removed = updateOriginalData.cashRegisterValues
                .filter(
                  (item) =>
                    !modelData.cashRegisterValues.some(
                      (obj) =>
                        isEqualWith(
                          obj,
                          item,
                          (obj1, obj2) => obj1.id === obj2.id
                        ) ||
                        isEqualWith(
                          obj,
                          item,
                          (obj1, obj2) =>
                            obj1.paymentTypeId === obj2.paymentTypeId &&
                            obj1.unitFinanceId === obj2.unitFinanceId &&
                            obj1.value === obj2.value
                        )
                    )
                )
                .map((item) => item.id);

              delete modelData.cashRegisterValues;
              delete updateOriginalData.cashRegisterValues;

              const result = restApiClient.cashRegisterClose
                .update(
                  modelId,
                  updateOriginalData,
                  modelData,
                  added,
                  edited,
                  removed
                )
                .then(async () => {
                  if (confirmSubmit)
                    await restApiClient.cashRegisterClose.setBlocked(modelId);
                });
              return result;
            }
          }
        }}
        onCreateSuccess={() => {
          if (!error) {
            props.history.goBack();
          }
        }}
        onUpdateSuccess={() => {
          if (!error) {
            props.history.goBack();
          }
        }}
        modelData={{
          ...cashRegister,
          cashRegisterValues: [{}],
          readonly: readonly || evaluate,
        }}
        customButtonsBuilder={(submit) =>
          evaluate ? (
            <>
              <BackButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
                color="primary"
                style={{ width: '30%' }}
              >
                Voltar
              </BackButton>
              <CancelButton
                onClick={() => {
                  setOpenJustificationDialog(true);
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Reprovar
              </CancelButton>
              <SaveButton
                onClick={() => {
                  restApiClient.cashRegisterClose
                    .setBlocked(cashRegisterCloseId)
                    .then(() => {
                      props.history.goBack();
                    });
                }}
                type="button"
                variant="contained"
                style={{ backgroundColor: '#4caf50', color: '#fff' }}
              >
                Aprovar
              </SaveButton>
            </>
          ) : readonly ? (
            <>
              <FooterContainer>
                <BackButton
                  onClick={() => props.history.goBack()}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Voltar
                </BackButton>
              </FooterContainer>
            </>
          ) : (
            <>
              <CancelButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
              >
                Cancelar
              </CancelButton>
              <SubmitButton
                type="button"
                variant="contained"
                id="submit"
                onClick={() => {
                  setOpenSubmitDialog(true);
                  setConfirmSubmit(() => () => submit('submit'));
                }}
              >
                {'Submeter'}
              </SubmitButton>
            </>
          )
        }
        {...props}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
      >
        <Alert onClose={() => setErrorMessage(null)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <SubmitDialog
        open={openSubmitDialog}
        onCancel={() => {
          setOpenSubmitDialog(false);
          setConfirmSubmit(false);
        }}
        onSuccess={async () => {
          setOpenSubmitDialog(false);
          confirmSubmit();
        }}
        dialogText={
          'Você tem certeza que deseja submeter este fechamento de caixa?'
        }
      />

      <DisapprovalCashRegisterDialog
        open={openJustificationDialog}
        cashRegisterCloseId={cashRegisterCloseId}
        cancelSubmit={true}
        onCancel={() => setOpenJustificationDialog(false)}
        onSuccess={() => {
          setOpenJustificationDialog(false);
          props.history.goBack();
        }}
      />
    </Container>
  );
}

export default CashRegisterCloseCreateUpdate;
