import React, { useState, useEffect } from 'react';
import Entry from '../../../../forms/entry';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import moment from 'moment';
import { Container, BoxRemove } from './styles';
import Item from '../../../../forms/item';

function UnregisteredItemPendingList({ history, match, ...props }) {
  const purchaseId = match.params?.id;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const columns = Entry.unregisteredItemTableSchema;

  useEffect(() => {
    restApiClient.entry
      .getAllUnregisteredItemByPurchaseId(
        purchaseId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('name', searchBy)
              .or()
              .like('barcode', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, purchaseId]);

  return (
    <Container>
      <CrudTable
        title={'Pendências de cadastro de item'}
        modelActions={Entry.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          solve: {
            icon: <BoxRemove />,
            onClick: (id, row) => {
              history.push(
                `/entry/pending/item/${row.pendency.pendencyList.entry.id}`
              );
            },
            title: 'Cadastrar Item',
            allowedActions: [
              [
                Entry.actions.findUnregisteredItemPendingById,
                Item.actions.createByPendentEntry,
              ],
            ],
          },
        }}
        customCellRenderers={{
          'pendency.pendencyList.entry.purchase.createdAt': {
            renderer: (index, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
        }}
      />
    </Container>
  );
}

export default UnregisteredItemPendingList;
