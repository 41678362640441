import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectDentistControl from '../../components/selectDentistControl';
import isEmpty from 'lodash/isEmpty';

const isSelectDentistControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectDentist'
  )
);

const customSelectDentistControlTester = rankWith(
  4,
  isSelectDentistControl
);

export default {
  tester: customSelectDentistControlTester,
  renderer: withJsonFormsEnumProps(SelectDentistControl),
};
