import {
  CenterContainer,
  DottedLine,
  Grid,
  TextStyledH5,
  TextStyledH5Bold,
  TextTitle,
  TextStyledH6,
} from './styles';
import React from 'react';
import moment from 'moment';

function VoucherRadiology({ crrRadiologySchedule }) {
  const radiologySchedule = crrRadiologySchedule;
  const address = radiologySchedule?.executorUnit?.address;

  return (
    <CenterContainer>
      <TextTitle>{`Encaminhamento #${radiologySchedule?.id}`}</TextTitle>
      <DottedLine />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Local: ${address?.address}, ${address?.number} - ${address?.neighborhood}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Nome: ${radiologySchedule?.patient?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Prontuário: ${radiologySchedule?.patient?.recordNumber ?? 'Não Informado'}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Data de Nascimento: ${moment(radiologySchedule?.patient?.birthDate).format('L')}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Unidade Solicitante: ${radiologySchedule?.requestingUnit.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Tratamento: ${radiologySchedule?.treatment?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5Bold>
            {`Data de agendamento:`}
          </TextStyledH5Bold>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5Bold>
            {moment(radiologySchedule?.scheduling).format('LLL')}
          </TextStyledH5Bold>
        </Grid>
      </Grid>
      <DottedLine />
    </CenterContainer>
  );
}

export default VoucherRadiology;
