import React, { useState } from 'react';
import CashRegisterClose from '../../../../forms/cashRegisterClose';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { BackButton, Container, FooterContainer } from './styles';
import isEqualWith from 'lodash/isEqualWith';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector } from 'react-redux';

function CashRegisterCloseCreateUpdate(props) {
  const employeeId = useSelector((x) => x.authReducer.user?.id ?? -1);
  const [errorMessage, setErrorMessage] = useState('');
  const [oldEmployeeId, setOldEmployeeId] = useState(null);
  const cashRegister = props.location?.state?.cashRegister ?? {};
  const readonly = props.location?.state?.readonly ?? false;
  let error = false;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={CashRegisterClose}
        uischemaSelector={(modelSchema, modelId) => {
          return readonly
            ? modelSchema.readonlyUischema
            : modelId
            ? modelSchema.updateUischema
            : modelSchema.uischema;
        }}
        restGetDataById={async (id) => {
          const result = await restApiClient.cashRegisterClose.findById(id);
          if (!readonly) {
            setOldEmployeeId(result.data.employeeId);
            result.data.employeeId = employeeId;
          }
          return result;
        }}
        restCreateData={async (cashRegisterClose) => {
          const values = cashRegisterClose.cashRegisterValues;
          const hasDuplicates =
            values.length >
            uniqBy(values, (e) => [e.paymentTypeId, e.unitFinanceId].join())
              .length;

          if (!hasDuplicates) {
            return restApiClient.cashRegisterClose.create(cashRegisterClose);
          } else {
            error = true;
            setErrorMessage(
              'Existe(m) conta(s) repetida(s)! Por favor, realoque o valor para alguma delas e exclua a(s) outra(s).'
            );
          }
        }}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          delete updateOriginalData.readonly;
          delete modelData.readonly;
          delete updateOriginalData.requestingUnitBoolean;
          delete modelData.requestingUnitBoolean;
          delete updateOriginalData.disableEmployee;
          delete modelData.disableEmployee;

          updateOriginalData.employeeId = oldEmployeeId;

          if (isEmpty(modelData.cashRegisterValues)) {
            error = true;
            setErrorMessage('É necessário informar pelo menos uma conta!');
          } else {
            const added = modelData.cashRegisterValues
              .filter((item) => !item.id)
              .filter(
                (item) =>
                  !updateOriginalData.cashRegisterValues.some((obj) =>
                    isEqualWith(
                      obj,
                      item,
                      (obj1, obj2) =>
                        obj1.paymentTypeId === obj2.paymentTypeId &&
                        obj1.unitFinanceId === obj2.unitFinanceId &&
                        obj1.value === obj2.value
                    )
                  )
              );

            //Verifica se já existe conta adicionada
            let duplicate = modelData.cashRegisterValues.reduce(
              (acc, item, index) => {
                let found = false;
                for (
                  let i = index + 1;
                  i < modelData.cashRegisterValues.length;
                  i++
                ) {
                  found |= isEqualWith(
                    item,
                    modelData.cashRegisterValues[i],
                    (obj1, obj2) =>
                      obj1.paymentTypeId === obj2.paymentTypeId &&
                      obj1.unitFinanceId === obj2.unitFinanceId
                  );
                }
                return acc || found;
              },
              false
            );

            if (duplicate) {
              error = true;
              setErrorMessage(
                'Existe(m) conta(s) repetida(s)! Por favor, realoque o valor para alguma delas e exclua a(s) outra(s).'
              );
            } else {
              error = false;
              const edited = modelData.cashRegisterValues
                .filter(
                  (item) =>
                    item.id &&
                    updateOriginalData.cashRegisterValues.find(
                      (obj) => item.id === obj.id
                    )?.value !== item.value
                )
                .map((item) => ({ id: item.id, value: item.value }));

              const removed = updateOriginalData.cashRegisterValues
                .filter(
                  (item) =>
                    !modelData.cashRegisterValues.some(
                      (obj) =>
                        isEqualWith(
                          obj,
                          item,
                          (obj1, obj2) => obj1.id === obj2.id
                        ) ||
                        isEqualWith(obj, item, (obj1, obj2) => {
                          return (
                            (obj1.id && obj2.id ? obj1.id === obj2.id : true) &&
                            obj1.paymentTypeId === obj2.paymentTypeId &&
                            obj1.unitFinanceId === obj2.unitFinanceId &&
                            obj1.value === obj2.value
                          );
                        })
                    )
                )
                .map((item) => item.id);

              delete modelData.cashRegisterValues;
              delete updateOriginalData.cashRegisterValues;

              const result = restApiClient.cashRegisterClose.update(
                modelId,
                updateOriginalData,
                modelData,
                added,
                edited,
                removed
              );
              return result;
            }
          }
        }}
        onCreateSuccess={() => {
          if (!error) {
            props.history.goBack();
          }
        }}
        onUpdateSuccess={() => {
          if (!error) {
            props.history.goBack();
          }
        }}
        modelData={{
          ...cashRegister,
          employeeId: !readonly ? employeeId : null,
          cashRegisterValues: [{}],
          readonly: readonly,
          disableEmployee: true,
        }}
        customButtonsBuilder={
          readonly
            ? () => (
                <>
                  <FooterContainer>
                    <BackButton
                      onClick={() => props.history.goBack()}
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      Voltar
                    </BackButton>
                  </FooterContainer>
                </>
              )
            : null
        }
        {...props}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
      >
        <Alert onClose={() => setErrorMessage(null)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CashRegisterCloseCreateUpdate;
