import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MaterialToolbar from '@material-ui/core/Toolbar';
import MaterialIconButton from '@material-ui/core/IconButton';
import { LogOut as MaterialLogOut } from '../../styles/icons';
import { SystemColors } from '../../styles/globalStyles';

export const Theme = createMuiTheme({
  palette: {
    primary: {
      main: SystemColors.primary,
    },
    secondary: {
      main: SystemColors.secondary,
    },
  },
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
export const ContentContainer = styled.div`
  flex-grow: 1;
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoutButton = styled(Button)`
  margin-right: 10px;
  color: #ffffff !important;
`;

export const IconButton = styled(MaterialIconButton)``;

export const LogOut = styled(MaterialLogOut)`
  width: 25px;
  height: 25px;

  fill: #ffffff;
`;

export const Toolbar = styled(MaterialToolbar)`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0 !important;
`;

export const Logo = styled.img`
  height: 60px;
  transform: scale(0.8);
`;
