import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  UnlockAlt as MaterialUnlockAlt,
} from '../../../../../../styles/icons';
import {
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  Button as MaterialButton,
} from '@material-ui/core';
import { Assignment as MaterialAssignment } from '@styled-icons/material/Assignment';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SelectContainer = styled.div`
  width: 300px;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const UnlockAlt = styled(MaterialUnlockAlt)`
  width: 22px;
  height: 22px;
  color: var(--secondary);
`;

export const Assignment = styled(MaterialAssignment)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;
export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
