import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const AutoDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 620px;
    width: 100%;
`;

export const StyledCarrousel = styled(Carousel)`
  width:100% !important;
`;
