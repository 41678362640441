/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Container,
  CancelButton,
  SubmitButton,
  DialogContent,
  Card,
  Dialog,
  DialogActions,
  ActionsContainer,
  HeaderContainer,
  CardHeader,
} from './styles';
import restApiClient from '../../services/restApiClient';
import SelectDentistSpecialty from '../selectDentistSpecialtyDialogControl';
import cloneDeep from 'lodash/cloneDeep';

function ApproveScheduleDialog({
  dentistWorkedHour,
  onSuccess,
  onError,
  onCancel,
  ...props
}) {
  const [specialtyId, setSpecialtyId] = useState(null);
  const [crrDentistWorkedHour, setCrrDentistWorkedHour] = useState(null);

  useEffect(() => {
    setCrrDentistWorkedHour(cloneDeep(dentistWorkedHour));
    setSpecialtyId(dentistWorkedHour?.specialtyId);
  }, [dentistWorkedHour]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (specialtyId) {
      if (crrDentistWorkedHour?.specialtyId !== specialtyId) {
        restApiClient.dentistWorkedHour
          .updateSpecialty(dentistWorkedHour.id, specialtyId)
          .then((e) => {
            setSpecialtyId(null);
            onSuccess?.(dentistWorkedHour.id);
          })
          .catch((e) => {
            onError?.({
              state: true,
              description: e.response.data.errors,
            });
          });
      } else {
        onSuccess?.(dentistWorkedHour.id);
      }
    }
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title="Aprovar Agenda" />
              </HeaderContainer>
              <SelectDentistSpecialty
                label="Especialidade"
                size="small"
                data={dentistWorkedHour?.specialtyId}
                forceDentistId={dentistWorkedHour?.dentistId}
                onSuccess={(id) => {
                  setSpecialtyId(id);
                }}
                visible
                enabled
                required
              />
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setSpecialtyId(null);
                    setCrrDentistWorkedHour(null);
                    onCancel?.();
                  }}
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Aprovar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default ApproveScheduleDialog;
