import styled from 'styled-components';
import {
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
  FormHelperText as MaterialFormHelperText,
  Card as MaterialCard,
  CardHeader as MaterialCardHeader,
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  Button as MaterialButton,
  TextField,
  DialogActions as MaterialDialogActions,
} from '@material-ui/core';

export const Container = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
`;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 0 !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 15px !important;
`;

export const Input = styled(TextField)`
  width: 100%;
  margin-bottom: 15px !important;
  color: white;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const FormHelperText = styled(MaterialFormHelperText)``;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
`;
export const CardHeader = styled(MaterialCardHeader)`
  padding: 0 !important;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const ProgressContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
