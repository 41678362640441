/* eslint-disable no-unused-expressions */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, DialogContent, StyledCreateUpdateForm } from './styles';
import restApiClient from '../../services/restApiClient';
import { useSelector } from 'react-redux';

function OrderServiceDialog({
  onSuccess,
  onError,
  onCancel,
  orderService,
  ...props
}) {
  const unitDentist = useSelector(
    (state) => state.authReducer?.user?.favoriteUnitId
  );
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={orderService}
            restGetDataById={async (id) => {
              const result = await restApiClient.orderService.findById(id);
              result.data.recordNumber = result.data.patient.recordNumber;
              result.data.name = result.data.patient.name;
              return result;
            }}
            restCreateData={restApiClient.orderService.create}
            onCreateSuccess={(request) => {
              onSuccess?.(request);
            }}
            modelData={{
              unitId: unitDentist,
              requestingUnitBoolean: !unitDentist,
            }}
            onCancelClick={onCancel}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default OrderServiceDialog;
