import React, { useState, useEffect, useCallback } from 'react';
import Chair from '../../../../forms/chair';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, CancelButton, UserShield, Calendar } from './styles';
import ChairDialog from '../../../../components/chairDialog';
import DentistOfficeHour from '../../../../forms/dentistOfficeHour';

function UnitChairList({ history, match, ...props }) {
  const unitId = parseInt(match?.params?.id ?? -1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [chairId, setChairId] = useState(false);

  const columns = Chair.tableSchema;

  const loadChairs = useCallback(() => {
    restApiClient.chair
      .getAllByUnit(
        unitId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('name', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, unitId]);

  useEffect(() => {
    loadChairs();
  }, [loadChairs]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Cadeiras'}
        modelActions={Chair.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => setOpen(true),
          },
          update: {
            onClick: (chairId) => {
              setChairId(chairId);
              setOpen(true);
            },
            allowedActions: [[Chair.actions.findById, Chair.actions.update]],
          },
          linkDentists: {
            icon: <UserShield />,
            title: 'Vincular Dentistas',
            onClick: (chairId) =>
              history.push(`/unit/chair/${chairId}/link-dentists`),
            allowedActions: [Chair.chairDentistActions.getAllByChair],
          },
          chairSchedule: {
            icon: <Calendar />,
            onClick: (chairId, data) =>
              history.push({
                pathname: `/unit/chair/${chairId}/schedule`,
                state: { chair: data },
              }),
            title: 'Agenda da unidade',
            allowedActions: [[DentistOfficeHour.actions.getAllByChair]],
          },
          delete: {
            onClick: (chairId, index) => {
              restApiClient.chair.delete(chairId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <ChairDialog
        open={open}
        chair={Chair}
        chairId={chairId}
        unitId={unitId}
        onCancel={() => {
          setOpen(false);
          setChairId(null);
        }}
        onSuccess={() => {
          loadChairs();
          setChairId(null);
          setOpen(false);
        }}
        {...props}
      />
    </Container>
  );
}

export default UnitChairList;
