const Role = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
    },
    required: ['name'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Cargos',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
    ],
  },
  tableSchema: [{ id: 'name', label: 'Nome', minWidth: 100, align: 'center' }],
  actions: {
    getAll: 'RoleController@getAll',
    getAllSystemActions: 'ActionController@getAll',
    findById: 'RoleController@findById',
    create: 'RoleController@create',
    update: 'RoleController@patchUpdate',
    delete: 'RoleController@delete',
  },
};

export default Role;
