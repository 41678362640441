import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  List,
  StyledSecuredListItem,
  StyledSecuredCollapseList,
  ListItemText,
  ListItemIcon,
  UserBadge,
  OfficeBuilding,
  LayerGroup,
  Truck,
  UserCircle,
  Settings,
  Boxes,
  Money,
  Exclamation,
  Search,
  Cart,
  BagPlusFill,
  BoxAdd,
  ClipboardList,
  LocationCity,
  Transfer,
  PackageIcon,
  Warehouse,
  Grid as MaterialGrid,
  AddBox,
  ShoppingCart,
  ClipboardCheck,
  CashRegister,
  AccountBalance,
  Report as ReportIcon,
  Tooth,
  UserGroup,
  Percent,
  ShoppingBag,
  Admin,
  LabFlask,
  Steps,
  CalendarSettings,
  ListAlt,
  ListWarning,
  ListOS,
  PatientIcon,
  XRay,
  TripOrigin,
  LocalHospital,
  CalendarX,
  CalendarAdd,
  AddLink,
  Clock,
  CalendarClock,
  Procedures,
  HandHoldingMedical,
  UserShared,
  DualScreenClock,
  NotesMedical,
  DocumentDismiss,
  FileEarmarkMedicalFill,
  DocumentClock,
  ClipboardTaskList,
  Bank,
  DocumentPerson,
  Receipt,
  DocumentBulletList,
  PaymentIcon,
  ReceiptAdd,
  GroupWork,
  ReceiptExpense,
  PriceChange,
  PeopleSwap,
  BuildingBankToolbox,
  BuildingRetailMoney,
  CreditCard,
  MoneyCheck,
  MoneyCalculator,
  TrashBin,
  Safe,
  CardList,
} from './styles';
import Employee from '../../forms/employee';
import Unit from '../../forms/unit';
import Group from '../../forms/group';
import Supplier from '../../forms/supplier';
import StockConfig from '../../forms/stockConfig';
import Item from '../../forms/item';
import Role from '../../forms/role';
import Purchase from '../../forms/purchase';
import Budget from '../../forms/budget';
import Order from '../../forms/order';
import Dentist from '../../forms/dentist';
import Buyer from '../../forms/buyer';
import Sale from '../../forms/sale';
import Report from '../../forms/report';
import ToothType from '../../forms/toothType';
import EndProduct from '../../forms/endProduct';
import Section from '../../forms/section';
import Step from '../../forms/step';
import Calendar from '../../forms/calendar';
import LabConfig from '../../forms/labConfig';
import OrderService from '../../forms/orderService';
import OrderServiceStep from '../../forms/orderServiceStep';
import Outsourced from '../../forms/outsourced';
import Patient from '../../forms/patient';
import Origin from '../../forms/origin';
import Treatment from '../../forms/treatment';
import UnitRadiologyService from '../../forms/unitRadiologyService';
import Recess from '../../forms/recess';
import RadiologySchedule from '../../forms/radiologySchedule';
import OpeningHour from '../../forms/openingHour';
import Specialty from '../../forms/specialty';
import Procedure from '../../forms/procedure';
import Substitute from '../../forms/substitute';
import TransferType from '../../forms/transferType';
import Attendance from '../../forms/attendance';
import DentistWorkedHour from '../../forms/dentistWorkedHour';
import ServiceSupplier from '../../forms/serviceSupplier';
import UnitFinance from '../../forms/unitFinance';
import ExpenseType from '../../forms/expenseType';
import PaymentType from '../../forms/paymentType';
import Expense from '../../forms/expense';
import Sector from '../../forms/sector';
import RecurrentExpense from '../../forms/recurrentExpense';
import BankingOperation from '../../forms/bankingOperation';
import AdverseExpense from '../../forms/adverseExpense';
import CashRegisterClose from '../../forms/cashRegisterClose';
import CashRegisterValue from '../../forms/cashRegisterValue';
import StockConsumption from '../../forms/stockConsumption';
import LabConsumption from '../../forms/labConsumption';
import RadiologyConsumption from '../../forms/radiologyConsumption';
import DentistConsumption from '../../forms/dentistConsumption';
import Stock from '../../forms/stock';
import ItemizedCard from '../../forms/itemizedCard';

function Menu() {
  const history = useHistory();

  return (
    <Container>
      <List>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <Admin />
              </ListItemIcon>
              <ListItemText primary="Administrativo" />
            </>
          }
        >
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <ReceiptAdd />
                </ListItemIcon>
                <ListItemText primary="Lançar Consumo" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAllWithConsumptions,
                  StockConsumption.actions.getAllByUnit,
                ],
              ]}
              onClick={() => history.push('/administrative/consumption/stock')}
              targetPath="/administrative/consumption/stock"
            >
              <ListItemIcon>
                <Warehouse />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAllWithConsumptions,
                  LabConsumption.actions.getAllByUnit,
                ],
              ]}
              onClick={() => history.push('/administrative/consumption/lab')}
              targetPath="/administrative/consumption/lab"
            >
              <ListItemIcon>
                <LabFlask />
              </ListItemIcon>
              <ListItemText primary="Laboratório" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAllWithConsumptions,
                  RadiologyConsumption.actions.getAllByUnit,
                ],
              ]}
              onClick={() =>
                history.push('/administrative/consumption/radiology')
              }
              targetPath="/administrative/consumption/radiology"
            >
              <ListItemIcon>
                <XRay />
              </ListItemIcon>
              <ListItemText primary="Radiologia" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAllWithConsumptions,
                  DentistConsumption.actions.getAllByUnit,
                ],
              ]}
              onClick={() =>
                history.push('/administrative/consumption/dentist')
              }
              targetPath="/administrative/consumption/dentist"
            >
              <ListItemIcon>
                <Tooth />
              </ListItemIcon>
              <ListItemText primary="Dentistas" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>

          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText primary="Relatórios" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [Report.actions.getCsvItemInStockByUnit, Unit.actions.findById],
                [Item.actions.getAllItemInStockByUnit, Unit.actions.findById],
                [
                  Report.actions.getCsvItemInStockByUnitAndGroup,
                  Unit.actions.findById,
                  Group.actions.getAllAsGroup,
                ],
                [
                  Item.actions.getAllItemInStockByUnitAndGroup,
                  Unit.actions.findById,
                  Group.actions.getAllAsGroup,
                ],
                [
                  Report.actions.getAllGroupValuesByPeriod,
                  Report.actions.getAllStockHistoriesValuesByPeriod,
                  Unit.actions.getAll,
                  Group.actions.getAllAsGroup,
                ],
                [
                  Report.actions.getItemWithdrawReportByUnit,
                  Unit.actions.getAll,
                ],
                [
                  Report.actions.getCsvItemWithdrawReportByUnit,
                  Unit.actions.getAll,
                ],
                [
                  Report.actions.getCsvStockAdjustmentHistoryReportByUnit,
                  Unit.actions.getAll,
                ],
                [Report.actions.getCsvTransferredItems, Unit.actions.getAll],
              ]}
              onClick={() => history.push('/report/stock')}
              targetPath="/report/stock"
            >
              <ListItemIcon>
                <Warehouse />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [Report.actions.getCompletedSalesReport],
                [Report.actions.getCsvCompletedSalesReport],
                [
                  Group.actions.getAllAsGroup,
                  Report.actions.getCsvCustomerPriceList,
                ],
              ]}
              onClick={() => history.push('/report/purchasing-group')}
              targetPath="/report/purchasing-group"
            >
              <ListItemIcon>
                <ShoppingBag />
              </ListItemIcon>
              <ListItemText primary="Setedental" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAll,
                  Section.actions.getAllAsBranches,
                  Report.actions.getAllOSFinishedLabByPeriod,
                ],
                [Report.actions.getReworkHistory],
                [
                  Unit.actions.getAll,
                  Report.actions.getFinishedLabStepsCsvReport,
                ],
                [Report.actions.getFinishedProducts],
              ]}
              onClick={() => history.push('/report/lab')}
              targetPath="/report/lab"
            >
              <ListItemIcon>
                <LabFlask />
              </ListItemIcon>
              <ListItemText primary="Laboratório" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAll,
                  Report.actions.getAllRadiologySchedulesByUnitByPeriod,
                ],
                [
                  Unit.actions.getAllByType,
                  Report.actions.getClinicSchedulesCSVReportByUnit,
                ],
                [Report.actions.getRadiologySchedulesCSVReport],
              ]}
              onClick={() => history.push('/report/radiology')}
              targetPath="/report/radiology"
            >
              <ListItemIcon>
                <XRay />
              </ListItemIcon>
              <ListItemText primary="Radiologia" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAll,
                  Report.actions.getFinishedProceduresCSVReport,
                  Dentist.actions.getAll,
                ],
                [
                  Unit.actions.getAll,
                  Report.actions.getFinishedHoursCSVReport,
                  Dentist.actions.getAll,
                ],
                [
                  Unit.actions.getAll,
                  Report.actions.getFinishedPatientsCSVReport,
                  Dentist.actions.getAll,
                ],
              ]}
              onClick={() => history.push('/report/dentist')}
              targetPath="/report/dentist"
            >
              <ListItemIcon>
                <Tooth />
              </ListItemIcon>
              <ListItemText primary="Dentistas" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Report.actions.getAllStockConsolidatedConsumptions,
                  Unit.actions.getAll,
                ],
                [
                  Report.actions.getAllLabConsolidatedConsumptions,
                  Unit.actions.getAll,
                ],
                [
                  Report.actions.getAllRadiologyConsolidatedConsumptions,
                  Unit.actions.getAll,
                ],
              ]}
              onClick={() => history.push('/report/consumption')}
              targetPath="/report/consumption"
            >
              <ListItemIcon>
                <ReceiptExpense />
              </ListItemIcon>
              <ListItemText primary="Consumo Consolidado" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredListItem
            button
            allowedActions={[Calendar.actions.getAll]}
            onClick={() => history.push('/calendar')}
            targetPath="/calendar"
          >
            <ListItemIcon>
              <CalendarSettings />
            </ListItemIcon>
            <ListItemText primary="Calendários de Feriados" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[Recess.actions.getAll]}
            onClick={() => history.push('/radiology/recess')}
            targetPath="/radiology/recess"
          >
            <ListItemIcon>
              <CalendarX />
            </ListItemIcon>
            <ListItemText primary="Calendário Radiologia" />
          </StyledSecuredListItem>
        </StyledSecuredCollapseList>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <MoneyCalculator />
              </ListItemIcon>
              <ListItemText primary="DRE" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[[ExpenseType.actions.getAllAsTree]]}
            allowCustom={(user) => user?.unit?.showInDre}
            onClick={() => history.push('/financial/config/expense-type')}
            targetPath="/financial/config/expense-type"
          >
            <ListItemIcon>
              <Receipt />
            </ListItemIcon>
            <ListItemText primary="Gerenciar DRE" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[
              [Report.actions.getDreCSV],
              [Report.actions.getDreByUnitCSV],
              [Report.actions.getDailyBankingHistoryByPeriod],
              [Report.actions.getMonthlyBankingHistory],
            ]}
            onClick={() => history.push('/dre/report')}
            targetPath="/dre/report"
          >
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios" />
          </StyledSecuredListItem>
        </StyledSecuredCollapseList>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <UserCircle />
              </ListItemIcon>
              <ListItemText primary="Funcionários" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[[Employee.actions.getAll]]}
            onClick={() => history.push('/employee')}
            targetPath="/employee/list"
          >
            <ListItemIcon>
              <UserCircle />
            </ListItemIcon>
            <ListItemText primary="Gestão de Funcionários" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[Role.actions.getAll]}
            onClick={() => history.push('/role')}
            targetPath="/role"
          >
            <ListItemIcon>
              <UserBadge />
            </ListItemIcon>
            <ListItemText primary="Gestão de Cargos" />
          </StyledSecuredListItem>

          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [Sector.actions.getAllTree, Sector.actions.create],
              ]}
              onClick={() => history.push('/employee/config/sector')}
              targetPath="/employee/config/sector"
            >
              <ListItemIcon>
                <GroupWork />
              </ListItemIcon>
              <ListItemText primary="Setor" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
        </StyledSecuredCollapseList>

        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <Tooth />
              </ListItemIcon>
              <ListItemText primary="Dentistas" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[[Dentist.actions.getAll, Dentist.actions.create]]}
            onClick={() => history.push('/dentist/list')}
            targetPath="/dentist/list"
          >
            <ListItemIcon>
              <Tooth />
            </ListItemIcon>
            <ListItemText primary="Gestão de Dentistas" />
          </StyledSecuredListItem>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <ClipboardTaskList />
                </ListItemIcon>
                <ListItemText primary="Auditorias" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Attendance.actions.getAllAttendancesFixedAndProductivity,
                  Dentist.actions.getAll,
                ],
              ]}
              onClick={() => history.push('/dentist/audit/procedures')}
              targetPath="/dentist/audit/procedures"
            >
              <ListItemIcon>
                <FileEarmarkMedicalFill />
              </ListItemIcon>
              <ListItemText primary="Auditar Procedimentos" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[DentistWorkedHour.actions.getAll]}
              onClick={() => history.push('/dentist/audit/schedule')}
              targetPath="/dentist/audit/schedule"
            >
              <ListItemIcon>
                <DocumentClock />
              </ListItemIcon>
              <ListItemText primary="Auditar Agenda" />
            </StyledSecuredListItem>
            <StyledSecuredCollapseList
              itemContent={
                <>
                  <ListItemIcon>
                    <PeopleSwap />
                  </ListItemIcon>
                  <ListItemText primary="Substituições" />
                </>
              }
            >
              <StyledSecuredListItem
                button
                allowedActions={[Attendance.actions.getAll]}
                onClick={() => history.push('/dentist/audit/replacements')}
                targetPath="/dentist/audit/replacements"
              >
                <ListItemIcon>
                  <DocumentPerson />
                </ListItemIcon>
                <ListItemText primary="Auditar Substituições" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[DentistWorkedHour.actions.getAll]}
                onClick={() =>
                  history.push('/dentist/audit/ScheduleReplacements')
                }
                targetPath="/dentist/audit/ScheduleReplacements"
              >
                <ListItemIcon>
                  <DocumentClock />
                </ListItemIcon>
                <ListItemText primary="Auditar Agenda Substituições" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[
                  [Substitute.actions.getAll, Attendance.actions.getAll],
                ]}
                onClick={() => history.push('/dentist/substitute')}
                targetPath="/dentist/substitute"
              >
                <ListItemIcon>
                  <UserShared />
                </ListItemIcon>
                <ListItemText primary="Substitutos" />
              </StyledSecuredListItem>
            </StyledSecuredCollapseList>
            <StyledSecuredCollapseList
              itemContent={
                <>
                  <ListItemIcon>
                    <DocumentDismiss />
                  </ListItemIcon>
                  <ListItemText primary="Reprovações" />
                </>
              }
            >
              <StyledSecuredListItem
                button
                allowedActions={[Attendance.actions.getAll]}
                onClick={() =>
                  history.push('/dentist/audit/procedures/disapproved')
                }
                targetPath="/dentist/audit/procedures/disapproved"
              >
                <ListItemIcon>
                  <FileEarmarkMedicalFill />
                </ListItemIcon>
                <ListItemText primary="Procedimentos Reprovados" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[Attendance.actions.getAll]}
                onClick={() =>
                  history.push('/dentist/audit/schedule/disapproved')
                }
                targetPath="/dentist/audit/schedule/disapproved"
              >
                <ListItemIcon>
                  <DocumentClock />
                </ListItemIcon>
                <ListItemText primary="Agendas Reprovadas" />
              </StyledSecuredListItem>
            </StyledSecuredCollapseList>
            <StyledSecuredListItem
              button
              allowedActions={[Attendance.actions.getAllByDate]}
              onClick={() => history.push('/dentist/audit/all-procedures')}
              targetPath="/dentist/audit/all-procedures"
            >
              <ListItemIcon>
                <DocumentBulletList />
              </ListItemIcon>
              <ListItemText primary="Todos Procedimentos" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[DentistWorkedHour.actions.getAllByDate]}
              onClick={() => history.push('/dentist/audit/all-schedules')}
              targetPath="/dentist/audit/all-schedules"
            >
              <ListItemIcon>
                <DocumentBulletList />
              </ListItemIcon>
              <ListItemText primary="Todas Agendas" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <ClipboardTaskList />
                </ListItemIcon>
                <ListItemText primary="Atendimentos" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowDentist={{
                dentistScheduleTypes: ['Procedimentos', 'Pacientes', 'Fixo'],
              }}
              onClick={() => history.push('/dentist/attendances')}
              targetPath="/dentist/attendances"
            >
              <ListItemIcon>
                <NotesMedical />
              </ListItemIcon>
              <ListItemText primary="Lançar Procedimentos" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowDentist={{ dentistScheduleTypes: ['Hora'] }}
              onClick={() => history.push('/dentist/dentistWorkedHour')}
              targetPath="/dentist/dentistWorkedHour"
            >
              <ListItemIcon>
                <DocumentClock />
              </ListItemIcon>
              <ListItemText primary="Lançar Agendas" />
            </StyledSecuredListItem>
            {/*<StyledSecuredListItem*/}
            {/*  button*/}
            {/*  allowDentist={{*/}
            {/*    dentistScheduleTypes: [*/}
            {/*      'Procedimentos',*/}
            {/*      'Pacientes',*/}
            {/*      'Fixo',*/}
            {/*      'Hora',*/}
            {/*    ],*/}
            {/*  }}*/}
            {/*  onClick={() => history.push('/dentist/submittedAttendances')}*/}
            {/*  targetPath="/dentist/submittedAttendances"*/}
            {/*>*/}
            {/*  <ListItemIcon>*/}
            {/*    <DocumentBulletList />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary="Atendimentos Submetidos" />*/}
            {/*</StyledSecuredListItem>*/}
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <LabFlask />
                </ListItemIcon>
                <ListItemText primary="Laboratório" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowDentist={{}}
              onClick={() => history.push('/dentist/lab/orderService')}
              targetPath="/dentist/lab/orderService"
            >
              <ListItemIcon>
                <ListAlt />
              </ListItemIcon>
              <ListItemText primary="Minhas OS" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowDentist={{}}
              onClick={() => history.push('/dentist/lab/allOrderService/')}
              targetPath="/dentist/lab/allOrderService/"
            >
              <ListItemIcon>
                <ListAlt />
              </ListItemIcon>
              <ListItemText primary="Todas OS" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowDentist={{}}
              onClick={() => history.push('/dentist/config/favorite-unit')}
              targetPath="/dentist/config/favorite-unit'"
            >
              <ListItemIcon>
                <OfficeBuilding />
              </ListItemIcon>
              <ListItemText primary="Unidade Favorita" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Specialty.actions.getAll]}
              onClick={() => history.push('/dentist/specialty')}
              targetPath="/dentist/specialty"
            >
              <ListItemIcon>
                <HandHoldingMedical />
              </ListItemIcon>
              <ListItemText primary="Especialidades" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Procedure.actions.getAll]}
              onClick={() => history.push('/dentist/procedure')}
              targetPath="/dentist/procedure"
            >
              <ListItemIcon>
                <Procedures />
              </ListItemIcon>
              <ListItemText primary="Procedimentos" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
        </StyledSecuredCollapseList>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <ShoppingBag />
              </ListItemIcon>
              <ListItemText primary="Setedental" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[
              [Item.actions.getAllWithStock, Unit.actions.getAll],
            ]}
            onClick={() => history.push('/shoppingGroup/searchItem')}
            targetPath="/shoppingGroup/searchItem"
          >
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary="Busca de Itens" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[[Sale.actions.getAll]]}
            onClick={() => history.push('/sale')}
            targetPath="/sale"
          >
            <ListItemIcon>
              <Transfer />
            </ListItemIcon>
            <ListItemText primary="Transferências" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[[Buyer.actions.getAll, Dentist.actions.findByCpf]]}
            onClick={() => history.push('/buyer')}
            targetPath="/buyer"
          >
            <ListItemIcon>
              <UserGroup />
            </ListItemIcon>
            <ListItemText primary="Compradores" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[
              Group.actions.getAll,
              Group.actions.setProfitMargin,
            ]}
            onClick={() => history.push('/profit-margin/category')}
            targetPath="/profit-margin/category"
          >
            <ListItemIcon>
              <Percent />
            </ListItemIcon>
            <ListItemText primary="Margem por Categoria" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[
              [Item.actions.getAll, Item.actions.setProfitMargin],
            ]}
            onClick={() => history.push('/profit-margin/individual')}
            targetPath="/profit-margin/individual"
          >
            <ListItemIcon>
              <Percent />
            </ListItemIcon>
            <ListItemText primary="Margem Individual" />
          </StyledSecuredListItem>
        </StyledSecuredCollapseList>
        <StyledSecuredListItem
          button
          allowedActions={[[Unit.actions.getAll, Unit.actions.create]]}
          onClick={() => history.push('/unit')}
          targetPath="/unit"
        >
          <ListItemIcon>
            <OfficeBuilding />
          </ListItemIcon>
          <ListItemText primary="Unidades" />
        </StyledSecuredListItem>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <Warehouse />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </>
          }
        >
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <PackageIcon />
                </ListItemIcon>
                <ListItemText primary="Itens" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [Item.actions.getAllWithStock, Unit.actions.getAll],
              ]}
              onClick={() => history.push('/item-search')}
              targetPath="/item-search"
            >
              <ListItemIcon>
                <Search />
              </ListItemIcon>
              <ListItemText primary="Busca de Itens" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Item.actions.getAll]}
              onClick={() => history.push('/item/list')}
              targetPath="/item/list"
            >
              <ListItemIcon>
                <AddBox />
              </ListItemIcon>
              <ListItemText primary="Cadastro de Itens" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Item.actions.getAllByUnitId,
                  Item.itemLocatorActions.create,
                  Item.itemLocatorActions.update,
                ],
              ]}
              onClick={() => history.push('/item-locator')}
              targetPath="/item-locator"
            >
              <ListItemIcon>
                <MaterialGrid />
              </ListItemIcon>
              <ListItemText primary="Localizadores" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[[Item.actions.getAll, Item.actions.restore]]}
              onClick={() => history.push('/disabled-items')}
              targetPath="/disabled-items"
            >
              <ListItemIcon>
                <TrashBin />
              </ListItemIcon>
              <ListItemText primary="Itens Desativados" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Item.actions.getAllWithStock,
                  Unit.actions.getAll,
                  Stock.actions.adjustQuantityStock,
                ],
              ]}
              onClick={() => history.push('/adjust-stock')}
              targetPath="/adjust-stock"
            >
              <ListItemIcon>
                <ClipboardCheck />
              </ListItemIcon>
              <ListItemText primary="Ajuste de Estoque" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Item.actions.updatePrices]}
              onClick={() => history.push('/item/update-item-prices-list')}
              targetPath="/item/update-item-prices-list"
            >
              <ListItemIcon>
                <PriceChange />
              </ListItemIcon>
              <ListItemText primary="Atualizar Preços" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>

          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Cart />
                </ListItemIcon>
                <ListItemText primary="Compras" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[Budget.actions.getAll]}
              onClick={() => history.push('/budget')}
              targetPath="/budget"
            >
              <ListItemIcon>
                <ClipboardList />
              </ListItemIcon>
              <ListItemText primary="Orçamento" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[Purchase.actions.getAllWithUnit]}
              onClick={() => history.push('/purchase')}
              targetPath="/purchase"
            >
              <ListItemIcon>
                <BagPlusFill />
              </ListItemIcon>
              <ListItemText primary="Pedidos" />
            </StyledSecuredListItem>

            <StyledSecuredCollapseList
              itemContent={
                <>
                  <ListItemIcon>
                    {/* <Badge badgeContent={100} color="error"> */}
                    <Exclamation />
                    {/* </Badge> */}
                  </ListItemIcon>
                  <ListItemText primary="Pendências" />
                </>
              }
            >
              <StyledSecuredListItem
                button
                allowedActions={[
                  Purchase.actions.getAllWithPendenciesItemPrice,
                ]}
                onClick={() => history.push('/item/pending/price')}
                targetPath="/item/pending/price"
              >
                <ListItemIcon>
                  {/* <Badge badgeContent={10} color="error"> */}
                  <Money />
                  {/* </Badge> */}
                </ListItemIcon>
                <ListItemText primary="Preço" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[
                  Purchase.actions.getAllWithPendenciesUnregisteredItem,
                ]}
                onClick={() => history.push('/unregistered-item')}
                targetPath="/unregistered-item"
              >
                <ListItemIcon>
                  {/* <Badge badgeContent={10} color="error"> */}
                  <BoxAdd />
                  {/* </Badge> */}
                </ListItemIcon>
                <ListItemText primary="Item Não Cadastrado" />
              </StyledSecuredListItem>
            </StyledSecuredCollapseList>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [StockConfig.actions.get, StockConfig.actions.update],
              ]}
              onClick={() => history.push('/stock-config')}
              targetPath="/stock-config"
            >
              <ListItemIcon>
                <DualScreenClock />
              </ListItemIcon>
              <ListItemText primary="Prazos" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[Supplier.actions.getAll]}
              onClick={() => history.push('/supplier')}
              targetPath="/supplier"
            >
              <ListItemIcon>
                <Truck />
              </ListItemIcon>
              <ListItemText primary="Fornecedores" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[
                [Group.actions.getAll, ExpenseType.actions.getAllLeaf],
              ]}
              onClick={() => history.push('/group')}
              targetPath="/group"
            >
              <ListItemIcon>
                <LayerGroup />
              </ListItemIcon>
              <ListItemText primary="Categorização" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[TransferType.actions.getAllTree]}
              onClick={() => history.push('/transferType')}
              targetPath="/transferType"
            >
              <ListItemIcon>
                <Transfer />
              </ListItemIcon>
              <ListItemText primary="Tipos de Transferência" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredListItem
            button
            allowedActions={[Purchase.actions.getAllWithUnit]}
            onClick={() => history.push('/stock/order/transfer')}
            targetPath="/stock/order/transfer"
          >
            <ListItemIcon>{<Transfer />}</ListItemIcon>
            <ListItemText primary="Transferências" />
          </StyledSecuredListItem>
        </StyledSecuredCollapseList>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <AccountBalance />
              </ListItemIcon>
              <ListItemText primary="Financeiro" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[Expense.actions.getAllByPeriod]}
            onClick={() => history.push('/financial/expenses')}
            targetPath="/financial/expenses"
          >
            <ListItemIcon>
              <ReceiptAdd />
            </ListItemIcon>
            <ListItemText primary="Lançar Despesas" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[Expense.actions.getAllByPeriod]}
            onClick={() => history.push('/financial/laborExpense')}
            targetPath="/financial/laborExpense"
          >
            <ListItemIcon>
              <DocumentPerson />
            </ListItemIcon>
            <ListItemText primary="Despesas Agrupadas" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[AdverseExpense.actions.getAll]}
            onClick={() => history.push('/financial/adverse-expenses')}
            targetPath="/financial/adverse-expenses"
          >
            <ListItemIcon>
              <ReceiptExpense />
            </ListItemIcon>
            <ListItemText primary="Despesas Adversas" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[BankingOperation.actions.getAll]}
            onClick={() => history.push('/financial/bankingOperation')}
            targetPath="/financial/bankingOperation"
          >
            <ListItemIcon>
              <BuildingBankToolbox />
            </ListItemIcon>
            <ListItemText primary="Operações Bancárias" />
          </StyledSecuredListItem>

          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <CashRegister />
                </ListItemIcon>
                <ListItemText primary="Caixa" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[CashRegisterClose.actions.getAll]}
              onClick={() => history.push('/financial/cashRegisterClose')}
              targetPath="/financial/cashRegisterClose"
            >
              <ListItemIcon>
                <BuildingRetailMoney />
              </ListItemIcon>
              <ListItemText primary="Fechamento de Caixa" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[CashRegisterClose.actions.getAll]}
              onClick={() => history.push('/financial/cashRegisterCard')}
              targetPath="/financial/cashRegisterCard"
            >
              <ListItemIcon>
                <CreditCard />
              </ListItemIcon>
              <ListItemText primary="Recebimento de Cartões" />
            </StyledSecuredListItem>

            <StyledSecuredListItem
              button
              allowedActions={[CashRegisterValue.actions.getAllChecks]}
              onClick={() => history.push('/financial/cashRegisterCheck')}
              targetPath="/financial/cashRegisterCheck"
            >
              <ListItemIcon>
                <MoneyCheck />
              </ListItemIcon>
              <ListItemText primary="Recebimento de Cheques" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[ItemizedCard.actions.getAll]}
              onClick={() => history.push('/financial/itemizedCard/all')}
              targetPath="/financial/itemizedCard/all"
            >
              <ListItemIcon>
                <CardList />
              </ListItemIcon>
              <ListItemText primary="Lista de NSU" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredListItem
            button
            allowedActions={[UnitFinance.actions.getAllWithConsolidation]}
            onClick={() =>
              history.push('/financial/bank-account-consolidation')
            }
            targetPath="/financial/bank-account-consolidation"
          >
            <ListItemIcon>
              <Safe />
            </ListItemIcon>
            <ListItemText primary="Consolidação" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[
              [
                Report.actions.getAllPayableExpensesByPeriod,
                Unit.actions.getAll,
              ],
              [
                Report.actions.getBankAccountMovements,
                UnitFinance.actions.getAll,
              ],
            ]}
            onClick={() => history.push('/financial/report')}
            targetPath="/financial/report"
          >
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios" />
          </StyledSecuredListItem>

          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[RecurrentExpense.actions.getAll]}
              onClick={() =>
                history.push('/financial/config/recurrent-expenses')
              }
              targetPath="/financial/config/recurrent-expenses"
            >
              <ListItemIcon>
                <ReceiptExpense />
              </ListItemIcon>
              <ListItemText primary="Despesas Recorrentes" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[ServiceSupplier.actions.getAll]}
              onClick={() => history.push('/financial/config/serviceSupplier')}
              targetPath="/financial/config/serviceSupplier"
            >
              <ListItemIcon>
                <Truck />
              </ListItemIcon>
              <ListItemText primary="Fornecedores de Serviço" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [UnitFinance.actions.getAll, UnitFinance.actions.create],
              ]}
              onClick={() => history.push('/financial/config/bank-account')}
              targetPath="/financial/config/bank-account"
            >
              <ListItemIcon>
                <Bank />
              </ListItemIcon>
              <ListItemText primary="Contas Bancárias" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [UnitFinance.actions.getAll, UnitFinance.actions.create],
              ]}
              onClick={() => history.push('/financial/config/financial-cash')}
              targetPath="/financial/config/financial-cash"
            >
              <ListItemIcon>
                <CashRegister />
              </ListItemIcon>
              <ListItemText primary="Caixa Financeiro" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [PaymentType.actions.getAll, PaymentType.actions.create],
              ]}
              onClick={() => history.push('/financial/config/payment-type')}
              targetPath="/financial/config/payment-type"
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Tipos de Pagamentos" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
        </StyledSecuredCollapseList>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <LocationCity />
              </ListItemIcon>
              <ListItemText primary="Clínica" />
            </>
          }
        >
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Warehouse />
                </ListItemIcon>
                <ListItemText primary="Estoque" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[[Item.actions.getAllWithStockClinic]]}
              onClick={() => history.push('/clinic/stock/searchItem')}
              targetPath="/clinic/stock/searchItem"
            >
              <ListItemIcon>
                <Search />
              </ListItemIcon>
              <ListItemText primary="Busca de Itens" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Item.actions.getAllByClinic,
                  Item.itemLocatorActions.create,
                  Item.itemLocatorActions.update,
                ],
              ]}
              onClick={() => history.push('/clinic/stock/locator')}
              targetPath="/clinic/stock/locator"
            >
              <ListItemIcon>
                <MaterialGrid />
              </ListItemIcon>
              <ListItemText primary="Localizadores" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Item.actions.getAllWithStockClinic,
                  Stock.actions.clinicAdjustQuantityStock,
                ],
              ]}
              onClick={() => history.push('/clinic/stock/adjust-stock')}
              targetPath="/clinic/stock/adjust-stock"
            >
              <ListItemIcon>
                <ClipboardCheck />
              </ListItemIcon>
              <ListItemText primary="Ajuste de Estoque" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Order.actions.getAllByUnit]}
              onClick={() => history.push('/clinic/stock/order')}
              targetPath="/clinic/stock/order"
            >
              <ListItemIcon>
                <ShoppingCart />
              </ListItemIcon>
              <ListItemText primary="Solicitação de Itens" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Item.actions.getAllItemInStockByClinic]}
              onClick={() => history.push('/clinic/stock/withdrawItem')}
              targetPath="/clinic/stock/withdrawItem"
            >
              <ListItemIcon>
                <Boxes />
              </ListItemIcon>
              <ListItemText primary="Baixa de itens" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <LabFlask />
                </ListItemIcon>
                <ListItemText primary="Laboratório" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  OrderServiceStep.actions
                    .getAllOrderServiceStepPendingByUnitId,
                  Unit.actions.getAll,
                ],
              ]}
              onClick={() => history.push('/clinic/lab/orderService/pending')}
              targetPath="/clinic/lab/orderService/pending"
            >
              <ListItemIcon>
                <ListWarning />
              </ListItemIcon>
              <ListItemText primary="OS Pendentes" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [OrderService.actions.getAllByClinic, Unit.actions.getAll],
              ]}
              onClick={() => history.push('/clinic/lab/orderService/all')}
              targetPath="/clinic/lab/orderService/all"
            >
              <ListItemIcon>
                <ListOS />
              </ListItemIcon>
              <ListItemText primary="Acompanhar OS" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <XRay />
                </ListItemIcon>
                <ListItemText primary="Radiologia" />
              </>
            }
          >
            <StyledSecuredCollapseList
              itemContent={
                <>
                  <ListItemIcon>
                    <CalendarAdd />
                  </ListItemIcon>
                  <ListItemText primary="Agenda" />
                </>
              }
            >
              <StyledSecuredListItem
                button
                allowedActions={[RadiologySchedule.actions.getAllByClinic]}
                onClick={() => history.push('/clinic/radiology/schedule/local')}
                targetPath="/clinic/radiology/schedule/local"
              >
                <ListItemIcon>
                  <CalendarAdd />
                </ListItemIcon>
                <ListItemText primary="Agenda Clínica" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[
                  RadiologySchedule.actions.getAllExecutedRadiologyByUnit,
                ]}
                onClick={() =>
                  history.push('/clinic/radiology/schedule/central')
                }
                targetPath="/clinic/radiology/schedule/central"
              >
                <ListItemIcon>
                  <CalendarAdd />
                </ListItemIcon>
                <ListItemText primary="Agenda Central" />
              </StyledSecuredListItem>
            </StyledSecuredCollapseList>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Tooth />
                </ListItemIcon>
                <ListItemText primary="Dentistas" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[[Attendance.actions.getAllInClinic]]}
              onClick={() => history.push('/clinic/dentist/audit-procedures')}
              targetPath="/clinic/dentist/audit-procedures"
            >
              <ListItemIcon>
                <FileEarmarkMedicalFill />
              </ListItemIcon>
              <ListItemText primary="Auditar Procedimentos" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[DentistWorkedHour.actions.getAllInClinic]}
              onClick={() => history.push('/clinic/dentist/audit-schedules')}
              targetPath="/clinic/dentist/audit-schedules"
            >
              <ListItemIcon>
                <DocumentClock />
              </ListItemIcon>
              <ListItemText primary="Auditar Agenda" />
            </StyledSecuredListItem>
            <StyledSecuredCollapseList
              itemContent={
                <>
                  <ListItemIcon>
                    <PeopleSwap />
                  </ListItemIcon>
                  <ListItemText primary="Substituições" />
                </>
              }
            >
              <StyledSecuredListItem
                button
                allowedActions={[Attendance.actions.getAllInClinic]}
                onClick={() =>
                  history.push('/clinic/dentist/audit/replacements')
                }
                targetPath="/clinic/dentist/audit/replacements"
              >
                <ListItemIcon>
                  <DocumentPerson />
                </ListItemIcon>
                <ListItemText primary="Auditar Substituições" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[DentistWorkedHour.actions.getAllInClinic]}
                onClick={() =>
                  history.push('/clinic/dentist/audit/schedule/replacements')
                }
                targetPath="/clinic/dentist/audit/schedule/replacements"
              >
                <ListItemIcon>
                  <DocumentClock />
                </ListItemIcon>
                <ListItemText primary="Auditar Agenda Substituições" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[
                  [
                    Substitute.actions.getAllInClinic,
                    Attendance.actions.getAllInClinic,
                  ],
                ]}
                onClick={() => history.push('/clinic/dentist/substitute')}
                targetPath="/clinic/dentist/substitute"
              >
                <ListItemIcon>
                  <UserShared />
                </ListItemIcon>
                <ListItemText primary="Substitutos" />
              </StyledSecuredListItem>
            </StyledSecuredCollapseList>
            <StyledSecuredCollapseList
              itemContent={
                <>
                  <ListItemIcon>
                    <DocumentDismiss />
                  </ListItemIcon>
                  <ListItemText primary="Reprovações" />
                </>
              }
            >
              <StyledSecuredListItem
                button
                allowedActions={[Attendance.actions.getAllInClinic]}
                onClick={() =>
                  history.push('/clinic/dentist/audit/procedures/disapproved')
                }
                targetPath="/clinic/dentist/audit/procedures/disapproved"
              >
                <ListItemIcon>
                  <FileEarmarkMedicalFill />
                </ListItemIcon>
                <ListItemText primary="Procedimentos Reprovados" />
              </StyledSecuredListItem>
              <StyledSecuredListItem
                button
                allowedActions={[Attendance.actions.getAllInClinic]}
                onClick={() =>
                  history.push('/clinic/dentist/audit/schedule/disapproved')
                }
                targetPath="/clinic/dentist/audit/schedule/disapproved"
              >
                <ListItemIcon>
                  <DocumentClock />
                </ListItemIcon>
                <ListItemText primary="Agendas Reprovadas" />
              </StyledSecuredListItem>
            </StyledSecuredCollapseList>
            <StyledSecuredListItem
              button
              allowedActions={[Attendance.actions.getAllInClinic]}
              onClick={() =>
                history.push('/clinic/dentist/audit/all-procedures')
              }
              targetPath="/clinic/dentist/audit/all-procedures"
            >
              <ListItemIcon>
                <DocumentBulletList />
              </ListItemIcon>
              <ListItemText primary="Todos Procedimentos" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[DentistWorkedHour.actions.getAllInClinic]}
              onClick={() =>
                history.push('/clinic/dentist/audit/all-schedules')
              }
              targetPath="/clinic/dentist/audit/all-schedules"
            >
              <ListItemIcon>
                <DocumentBulletList />
              </ListItemIcon>
              <ListItemText primary="Todas Agendas" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredListItem
            button
            allowedActions={[CashRegisterClose.actions.getAllByClinic]}
            onClick={() => history.push('/clinic/cashRegisterClose')}
            targetPath="/clinic/cashRegisterClose"
          >
            <ListItemIcon>
              <CashRegister />
            </ListItemIcon>
            <ListItemText primary="Caixa" />
          </StyledSecuredListItem>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText primary="Relatórios" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Report.clinicActions.getCsvItemInClinicStockByGroup,
                  Group.actions.getAllAsGroup,
                ],
                [
                  Item.actions.getAllItemInClinicStockByGroup,
                  Group.actions.getAllAsGroup,
                ],
                [
                  Report.clinicActions.getAllGroupValuesInClinicByPeriod,
                  Report.actions.getAllStockHistoriesValuesByPeriod,
                  Group.actions.getAllAsGroup,
                ],
                [Report.clinicActions.getCsvTransferredItemsInClinic],
              ]}
              onClick={() => history.push('/clinic/report/stock')}
              targetPath="/clinic/report/stock"
            >
              <ListItemIcon>
                <Warehouse />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [Report.clinicActions.getAllClinicRadiologySchedulesByPeriod],
                [Report.clinicActions.getRadiologySchedulesCSVReportClinic],
                [Report.clinicActions.getClinicSchedulesCSVReport],
              ]}
              onClick={() => history.push('/clinic/report/radiology')}
              targetPath="/report/radiology"
            >
              <ListItemIcon>
                <XRay />
              </ListItemIcon>
              <ListItemText primary="Radiologia" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Section.actions.getAllBranches,
                  Report.clinicActions.getLabCostInClinicByPeriod,
                ],
                [Report.clinicActions.getCsvPendingSteps],
                [Report.clinicActions.getFinishedLabStepsInClinicCSVReport],
              ]}
              onClick={() => history.push('/clinic/report/lab')}
              targetPath="/clinic/report/lab"
            >
              <ListItemIcon>
                <LabFlask />
              </ListItemIcon>
              <ListItemText primary="Laboratório" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText primary="Consumo Consolidado" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  StockConsumption.valuesActions.getAllByStockConsumptionId,
                  StockConsumption.actions.getAllByUnit,
                  StockConsumption.actions.getAllGroupsConsumptionByPeriod,
                  Unit.actions.findById,
                ],
              ]}
              onClick={() => history.push('/clinic/consumption/stock')}
              targetPath="/clinic/consumption/stock"
            >
              <ListItemIcon>
                <Warehouse />
              </ListItemIcon>
              <ListItemText primary="Estoque" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  RadiologyConsumption.valuesActions
                    .getAllByRadiologyConsumptionId,
                  RadiologyConsumption.actions.getAllByUnit,
                  RadiologyConsumption.actions.getAllOriginsConsumptionByPeriod,
                  Unit.actions.findById,
                ],
              ]}
              onClick={() => history.push('/clinic/consumption/radiology')}
              targetPath="/clinic/consumption/radiology"
            >
              <ListItemIcon>
                <XRay />
              </ListItemIcon>
              <ListItemText primary="Radiologia" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  LabConsumption.valuesActions.getAllByLabConsumptionId,
                  LabConsumption.actions.getAllByUnit,
                  LabConsumption.actions.getAllSectionsConsumptionByPeriod,
                  Unit.actions.findById,
                ],
              ]}
              onClick={() => history.push('/clinic/consumption/lab')}
              targetPath="/clinic/consumption/lab"
            >
              <ListItemIcon>
                <LabFlask />
              </ListItemIcon>
              <ListItemText primary="Laboratório" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  DentistConsumption.valuesActions.getAllByDentistConsumptionId,
                  DentistConsumption.actions.getAllByUnit,
                  DentistConsumption.actions
                    .getAllSpecialtiesConsumptionByPeriod,
                  Unit.actions.findById,
                ],
              ]}
              onClick={() => history.push('/clinic/consumption/dentist')}
              targetPath="/clinic/consumption/dentist"
            >
              <ListItemIcon>
                <Tooth />
              </ListItemIcon>
              <ListItemText primary="Dentistas" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <MoneyCalculator />
                </ListItemIcon>
                <ListItemText primary="DRE" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[[ExpenseType.actions.getAllAsTreeClinic]]}
              allowCustom={(user) => user?.unit?.showInDre}
              onClick={() =>
                history.push('/clinic/financial/config/expense-type')
              }
              targetPath="/financial/config/expense-type"
            >
              <ListItemIcon>
                <Receipt />
              </ListItemIcon>
              <ListItemText primary="Gerenciar DRE" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[[Report.actions.getClinicDreByUnitCSV]]}
              onClick={() => history.push('/dre/clinic/report')}
              targetPath="/dre/clinic/report"
            >
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Relatórios" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
        </StyledSecuredCollapseList>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <LabFlask />
              </ListItemIcon>
              <ListItemText primary="Laboratório" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[
              OrderServiceStep.actions.getAllOrderServiceStepSended,
            ]}
            onClick={() => history.push('/lab/orderService/pending')}
            targetPath="/lab/orderService/pending"
          >
            <ListItemIcon>
              <ListWarning />
            </ListItemIcon>
            <ListItemText primary="OS Pendentes" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[
              OrderServiceStep.actions.getAllOrderServiceStepQueued,
            ]}
            onClick={() => history.push('/lab/serviceQueue')}
            targetPath="/lab/serviceQueue"
          >
            <ListItemIcon>
              <ListOS />
            </ListItemIcon>
            <ListItemText primary="Fila de Serviço" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[
              OrderServiceStep.actions
                .getAllOrderServiceStepCompletedLaboratory,
            ]}
            onClick={() =>
              history.push('/lab/orderService/completed-laboratory')
            }
            targetPath="/lab/orderService/completed-laboratory"
          >
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary="Concluído Laboratório" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[
              [
                OrderService.actions.getAll,
                OrderServiceStep.actions.getAllByOrderServiceId,
              ],
            ]}
            onClick={() => history.push('/lab/allOrderService/')}
            targetPath="/lab/allOrderService/"
          >
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary="Todas OS" />
          </StyledSecuredListItem>
          <StyledSecuredListItem
            button
            allowedActions={[OrderService.actions.getAllOrderServiceRefused]}
            onClick={() => history.push('/lab/orderService/refused')}
            targetPath="/lab/orderService/refused"
          >
            <ListItemIcon>
              <ListWarning />
            </ListItemIcon>
            <ListItemText primary="Avaliar OS" />
          </StyledSecuredListItem>

          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [EndProduct.actions.getAll, EndProduct.actions.create],
              ]}
              onClick={() => history.push('/lab/endProduct')}
              targetPath="/lab/endProduct"
            >
              <ListItemIcon>
                <Boxes />
              </ListItemIcon>
              <ListItemText primary="Produto Final" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [ToothType.actions.getAll, ToothType.actions.create],
              ]}
              onClick={() => history.push('/lab/toothType')}
              targetPath="/lab/toothType"
            >
              <ListItemIcon>
                <Tooth />
              </ListItemIcon>
              <ListItemText primary="Tonalidade de Dente" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[[Step.actions.getAll, Step.actions.create]]}
              onClick={() => history.push('/lab/step')}
              targetPath="/lab/step"
            >
              <ListItemIcon>
                <Steps />
              </ListItemIcon>
              <ListItemText primary="Etapas" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Section.actions.getAll]}
              onClick={() => history.push('/lab/section')}
              targetPath="/lab/section"
            >
              <ListItemIcon>
                <LayerGroup />
              </ListItemIcon>
              <ListItemText primary="Seção" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[Outsourced.actions.getAll]}
              onClick={() => history.push('/outsourced')}
              targetPath="/outsourced"
            >
              <ListItemIcon>
                <Truck />
              </ListItemIcon>
              <ListItemText primary="Terceirizados" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [LabConfig.actions.getAll, LabConfig.actions.update],
              ]}
              onClick={() => history.push('/lab/config')}
              targetPath="/lab/config"
            >
              <ListItemIcon>
                <CalendarClock />
              </ListItemIcon>
              <ListItemText primary="Prazos" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
        </StyledSecuredCollapseList>
        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <PatientIcon />
              </ListItemIcon>
              <ListItemText primary="Pacientes" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[Patient.actions.getAll]}
            onClick={() => history.push('/patient')}
            targetPath="/patient"
          >
            <ListItemIcon>
              <PatientIcon />
            </ListItemIcon>
            <ListItemText primary="Gestão de Pacientes" />
          </StyledSecuredListItem>
        </StyledSecuredCollapseList>

        <StyledSecuredCollapseList
          itemContent={
            <>
              <ListItemIcon>
                <XRay />
              </ListItemIcon>
              <ListItemText primary="Radiologia" />
            </>
          }
        >
          <StyledSecuredListItem
            button
            allowedActions={[
              [RadiologySchedule.actions.getAllExecutedRadiology],
            ]}
            onClick={() => history.push('/radiology/schedule')}
            targetPath="/radiology/schedule"
          >
            <ListItemIcon>
              <CalendarAdd />
            </ListItemIcon>
            <ListItemText primary="Agenda Central" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[
              [Origin.actions.getAllTree, Origin.actions.create],
            ]}
            onClick={() => history.push('/radiology/origin')}
            targetPath="/radiology/origin"
          >
            <ListItemIcon>
              <TripOrigin />
            </ListItemIcon>
            <ListItemText primary="Origem" />
          </StyledSecuredListItem>

          <StyledSecuredListItem
            button
            allowedActions={[
              [Treatment.actions.create, Treatment.actions.getAll],
            ]}
            onClick={() => history.push('/radiology/treatment')}
            targetPath="/radiology/treatment"
          >
            <ListItemIcon>
              <LocalHospital />
            </ListItemIcon>
            <ListItemText primary="Tratamento" />
          </StyledSecuredListItem>

          <StyledSecuredCollapseList
            itemContent={
              <>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </>
            }
          >
            <StyledSecuredListItem
              button
              allowedActions={[
                [
                  Unit.actions.getAll,
                  UnitRadiologyService.actions.getAllByUnit,
                  UnitRadiologyService.actions.create,
                  UnitRadiologyService.actions.delete,
                ],
              ]}
              onClick={() =>
                history.push('/radiology/config/unit-type-services')
              }
              targetPath="/radiology/config/unit-type-services"
            >
              <ListItemIcon>
                <AddLink />
              </ListItemIcon>
              <ListItemText primary="Tratamentos Vinculados" />
            </StyledSecuredListItem>
            <StyledSecuredListItem
              button
              allowedActions={[
                [OpeningHour.actions.getAllByDay, OpeningHour.actions.update],
              ]}
              onClick={() => history.push('/radiology/config/opening-hours')}
              targetPath="/radiology/config/opening-hours"
            >
              <ListItemIcon>
                <Clock />
              </ListItemIcon>
              <ListItemText primary="Horário de Atendimento" />
            </StyledSecuredListItem>
          </StyledSecuredCollapseList>
        </StyledSecuredCollapseList>
      </List>
    </Container>
  );
}

export default Menu;
