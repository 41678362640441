import React, { useState, useCallback, useEffect } from 'react';
import Attendance from '../../../../../../forms/attendance';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import {
  Container,
  ViewShow,
  CancelButton
} from './styles';
import DentistWorkedHour from '../../../../../../forms/dentistWorkedHour';
import moment from 'moment';

function AttendancesHoursList({ history, ...props }) {
  const workedHour = props.location?.state?.workedHour;
  const actionAddDisabled = props.location?.state?.actionAddDisabled ?? false;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ attendanceId: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = DentistWorkedHour.internalAttendancesTableSchema;
  
  const loadAttendances = useCallback(() => {
    restApiClient.dentistWorkedHourAttendance
      .getAllByWorkedHour(
        workedHour.id,
        rowsPerPage,
        page * rowsPerPage,
        searchBy ?
          new FilterTree()
            .like('attendance.patient.name', searchBy)
            .or()
            .like('attendance.procedure.name', searchBy)
            .or()
            .like('attendance.status', searchBy)
            .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadAttendances();
  }, [loadAttendances]);
  
  return (
    <Container>
      <CrudTable
        title={'Meus Atendimentos'}
        modelActions={Attendance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => history.push({
              pathname: '/dentist/dentistWorkedHour/attendance/create',
              state: {
                workedHourId: workedHour?.id,
                unitId: workedHour?.unitId,
                attendanceDate: workedHour?.attendanceDate
              },
            }),
            title: 'Criar Atendimento',
            allowDentist: true,
            selector: () => !actionAddDisabled,
          },
          update: {
            onClick: (id, row) => history.push({
              pathname: `/dentist/dentistWorkedHour/attendance/update/${row.attendanceId}`,
              state: {
                workedHourId: workedHour?.id,
                unitId: workedHour?.unitId
              },
            }),
            allowDentist: true,
            selector: (row) => row?.attendance.status === 'Em Edição',
          },
          view: {
            icon: <ViewShow />,
            onClick: (id, row) => {
              history.push({
                pathname: `/dentist/dentistWorkedHour/attendance/${row.attendanceId}`,
                state: {
                  readonly: true,
                },
              });
            },
            title: 'Visualizar Atendimento',
            allowDentist: true,
            selector: (row) => row?.attendance.status !== 'Em Edição',
          },
          delete: {
            onClick: (id, index, row) => {
              restApiClient.dentistWorkedHourAttendance
                .delete(id)
                .then(() => {
                  restApiClient.attendance
                    .delete(row?.attendanceId)
                    .then(() => {
                      loadAttendances();
                    });
                });
            },
            allowDentist: true,
            selector: (row) => row?.attendance.status === 'Em Edição',
          },
        }}
        customCellRenderers={{
          'attendance.attendanceDate': {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />
    </Container>
  );
}

export default AttendancesHoursList;
