/* eslint-disable no-unused-expressions */
import React from 'react';
import { TextField } from './styles';
import { Hidden } from '@material-ui/core';

function TimeInput({
  id,
  className,
  handleChange,
  description,
  errors,
  label,
  schema,
  uischema,
  visible = true,
  required,
  config,
  input,
  inputProps,
  onChange,
  path,
  data,
  enabled,
  ...props
}) {
  const isValid = !errors || errors?.length === 0;
  const formHelperText = !isValid ? errors : null;
  
  const handleChangeEvent = (e) => {
    e.persist();
    handleChange?.(path, e.target.value);
    onChange?.(e, e.target.value);
  };
  
  return (
    <Hidden xsUp={!visible}>
      <TextField
        id={id}
        name={className}
        label={label}
        type="time"
        value={data ?? null}
        InputLabelProps={{
          shrink: true,
        }}
        required={required}
        disabled={!enabled}
        onChange={handleChangeEvent}
        error={!isValid}
        helperText={!isValid ? formHelperText : null}
        {...props}
      />
    </Hidden>
  );
}

export default TimeInput;
