/* eslint-disable no-unused-expressions */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, DialogContent, StyledCreateUpdateForm } from './styles';
import restApiClient from '../../services/restApiClient';

function BudgetDialog({
  onSuccess,
  onError,
  onCancel,
  budget,
  selectedBudget,
  ...props
}) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={budget}
            restGetDataById={restApiClient.budget.findById}
            restCreateData={restApiClient.budget.create}
            restUpdateData={async (modelId, updateOriginalData, modelData) => {
              delete updateOriginalData.requestingUnitBoolean;
              delete modelData.requestingUnitBoolean;

              return restApiClient.budget.update(
                modelId,
                updateOriginalData,
                modelData
              );
            }}
            onCreateSuccess={(request) => {
              onSuccess?.(request);
            }}
            onUpdateSuccess={(request) => {
              onSuccess?.(request);
            }}
            forceId={selectedBudget}
            onCancelClick={onCancel}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default BudgetDialog;
