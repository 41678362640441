/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  Container,
  DialogContent,
  InputTextarea,
  Dialog,
  ActionsContainer,
  CloseButton,
  DialogActions,
  Card,
  CardHeader,
  HeaderContainer,
  FormDiv,
} from './styles';

function JusticationDialog({ onClose, title, justification, ...props }) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <Card>
            <HeaderContainer>
              <CardHeader title={title} />
            </HeaderContainer>
            <FormDiv>
              <InputTextarea
                id="outlined-multiline-static"
                size="small"
                label="Justificativa"
                value={justification}
                multiline
                rows={5}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                  readOnly: true,
                }}
              />
            </FormDiv>
          </Card>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CloseButton
              onClick={() => {
                onClose?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Fechar
            </CloseButton>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default JusticationDialog;
