import React from 'react';
import { TextField } from './styles';
import NumberFormat from 'react-number-format';
import { Hidden } from '@material-ui/core';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values?.value,
          },
        });
      }}
      allowNegative={false}
      allowLeadingZeros={false}
      decimalSeparator=","
      decimalScale={2}
      suffix="%"
      {...other}
    />
  );
}

function PercentageInput({
  id,
  handleChange,
  description,
  className,
  errors,
  label,
  enabled = true,
  schema,
  uischema,
  visible = true,
  required,
  config,
  input,
  inputProps,
  onChange,
  path,
  data,
  ...props
}) {
  const isValid = !errors || errors?.length === 0;
  const formHelperText = !isValid ? errors : null;

  const handleChangeEvent = (e) => {
    handleChange?.(path, Number(e.target.value));
    onChange?.(e, Number(e.target.value));
  };

  return (
    <Hidden xsUp={!visible}>
      <TextField
        id={id}
        className={className}
        disabled={!enabled}
        label={label}
        value={data ?? null}
        required={required}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        onChange={handleChangeEvent}
        error={!isValid}
        helperText={!isValid ? formHelperText : null}
        {...props}
      />
    </Hidden>
  );
}

export default PercentageInput;
