import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  ExclamationTriangleFill as MaterialExclamationTriangleFill,
  Restore as MaterialRestore,
  Check as MaterialCheck,
  Cancel as MaterialCancel
} from '../../../../../styles/icons';
import { Printer as MaterialPrinter } from '@styled-icons/boxicons-solid/Printer';
import { AddToQueue as MaterialAddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { AddBox as MaterialAddBox } from '@styled-icons/material/AddBox';
import { ReceiptRefund as MaterialReceiptRefund } from '@styled-icons/heroicons-solid/ReceiptRefund';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ExclamationTriangleFill = styled(MaterialExclamationTriangleFill)`
  width: 25px;
  height: 25px;
  fill: red;
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddToQueue = styled(MaterialAddToQueue)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ReceiptRefund = styled(MaterialReceiptRefund)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Check = styled(MaterialCheck)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Cancel = styled(MaterialCancel)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PrintOS = styled.div`
  @media screen {
    display: none
  }
`;
