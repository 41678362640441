import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Purchase from '../../../forms/purchase';
import PurchaseItem from '../../../forms/purchaseItem';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  Container,
  ViewShow,
  FileInvoiceDollar,
  FileInvoice,
  FileDownload,
  Documents,
  CardChecklist,
  Merge,
} from './styles';
import moment from 'moment';
import InvoiceDialog from '../../../components/invoiceDialog';
import ErrorDialog from '../../../components/errorDialog';
import InvoiceListDialog from '../../../components/invoiceListDialog';
import FilesDialog from '../../../components/filesDialog';
import PurchaseFile from '../../../forms/purchaseFile';
import PurchaseEntry from '../../../forms/purchaseEntry';
import PurchaseDialog from '../../../components/purchaseDialog';

function PurchaseList({ history }) {
  const loggedIn = useSelector((state) => state.authReducer.user);
  const [page, setPage] = useState(0);
  const [errorOpen, setErrorOpen] = useState(false);
  const [checkoutSuccessOpen, setCheckoutSuccessOpen] = useState(false);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [purchaseFileOpen, setPurchaseFileOpen] = useState(false);
  const [purchaseEntryListOpen, setPurchaseEntryListOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
  const [invoiceConfirmation, setInvoiceConfirmation] = useState(-1);
  const [manualEntryConfirmation, setManualEntryConfirmation] = useState(-1);
  const [checkoutConfirmation, setCheckoutConfirmation] = useState(-1);
  const [purchaseMergeOpen, setPurchaseMergeOpen] = useState(false);
  const [purchaseMergeTarget, setPurchaseMergeTarget] = useState(-1);
  const columns = Purchase.tableSchema;

  async function loadPurchases() {
    if (checkoutConfirmation === -1 && !errorOpen) {
      await restApiClient.purchase
        .getAllWithUnit(
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('unit.name', searchBy)
                .or()
                .like('supplier.fantasy_name', searchBy)
                .or()
                .like('budget.name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }
  useEffect(() => {
    loadPurchases();
  }, [rowsPerPage, page, orderBy, searchBy, checkoutConfirmation, errorOpen]);

  return (
    <Container>
      <CrudTable
        title={'Listagem de Pedidos'}
        modelActions={Purchase.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id, row) =>
              row.entries.length === 0 && id === invoiceConfirmation,
            content: (
              <span>
                A entrada de NF-e impossibilitará futuras entradas manuais,
                deseja continuar?
              </span>
            ),
            onSuccess: (id, row) => {
              setInvoiceOpen(true);
              setInvoiceConfirmation(-1);
            },
            onCancel: () => {
              setInvoiceConfirmation(-1);
              setSelectedPurchase(null);
            },
          },
          {
            selector: (id, row) =>
              row.entries.length === 0 && id === manualEntryConfirmation,
            content: (
              <span>
                A entrada manual impossibilitará futuras entradas de NF-e,
                deseja continuar?
              </span>
            ),
            onSuccess: (purchaseId, row) => {
              if (row.entries?.length === 0 ?? true) {
                restApiClient.purchaseEntry
                  .create({ purchaseId: row.id })
                  .then((e) => {
                    history.push(
                      `/purchase/${purchaseId}/manualEntry/${e.data.id}/products/`
                    );
                  })
                  .catch((e) => console.log(e));
              }
            },
            onCancel: () => {
              setManualEntryConfirmation(-1);
            },
          },
          {
            selector: (id, row) => id === checkoutConfirmation,
            content: (
              <span>
                Ao encerrar esta compra não será mais possível entrar com novos
                itens, deseja continua?
              </span>
            ),
            onSuccess: (purchaseId, row) => {
              restApiClient.purchase
                .checkout(purchaseId)
                .then((x) => {
                  setCheckoutSuccessOpen(true);
                  setCheckoutConfirmation(-1);
                })
                .catch((x) => {
                  setErrorOpen(true);
                  setCheckoutConfirmation(-1);
                });
            },
            onCancel: () => {
              setCheckoutConfirmation(-1);
            },
          },
        ]}
        actions={{
          submitInvoice: {
            icon: <FileInvoiceDollar />,
            onClick: (purchaseId, row) => {
              if (row.entries.length === 0) {
                setInvoiceConfirmation(purchaseId);
                setSelectedPurchase(row);
              } else {
                setSelectedPurchase(row);
                setInvoiceOpen(true);
              }
            },
            title: 'Entrada de NF-e',
            allowedActions: [Purchase.actions.submitInvoice],
            selector: (row) =>
              (row.status === 'purchase_made' || row.status === 'pending') &&
              (!row.entries?.some((x) => !x.invoiceId) ?? true),
          },
          createEntry: {
            icon: <FileInvoice />,
            onClick: (purchaseId, row) => {
              if (row.entries.length === 0) {
                setManualEntryConfirmation(purchaseId);
              } else {
                history.push(
                  `/purchase/${purchaseId}/manualEntry/${row.entries[0].id}/products/`
                );
              }
            },
            title: 'Entrada manual de produtos',
            allowedActions: [PurchaseEntry.actions.create],
            selector: (row) =>
              (row.status === 'purchase_made' || row.status === 'pending') &&
              (!row.entries?.some((x) => !!x.invoiceId) ?? true),
          },
          viewAllPurchaseProducts: {
            icon: <ViewShow />,
            onClick: (purchaseId) => {
              history.push(`/purchase/view/products/${purchaseId}`);
            },
            title: 'Visualizar Compra',
            allowedActions: [PurchaseItem.actions.getAllByPurchaseId],
          },
          addFiles: {
            icon: <Documents />,
            onClick: (purchaseId) => {
              setPurchaseFileOpen(true);
              setSelectedPurchaseId(purchaseId);
            },
            title: 'Anexos',
            allowedActions: [PurchaseFile.actions.create],
          },
          listPurchaseEntry: {
            icon: <FileDownload />,
            onClick: (purchaseId) => {
              setPurchaseEntryListOpen(true);
              setSelectedPurchaseId(purchaseId);
            },
            title: 'Notas Fiscais',
            allowedActions: [PurchaseEntry.actions.getAllByPurchaseId],
            selector: (row) => row.entries?.some((x) => !!x.invoiceId) ?? false,
          },
          checkout: {
            icon: <CardChecklist />,
            onClick: (purchaseId) => {
              setCheckoutConfirmation(purchaseId);
            },
            title: 'Finalizar compra',
            allowedActions: [Purchase.actions.checkout],
            selector: (row) => row.status === 'pending',
          },
          merge: {
            icon: <Merge />,
            onClick: (purchaseId) => {
              setPurchaseMergeTarget(purchaseId);
              setPurchaseMergeOpen(true);
            },
            title: 'Agrupar',
            allowedActions: [
              [Purchase.actions.merge, Purchase.actions.getMergeable],
            ],
            selector: (row) => row.status === 'purchase_made',
          },
        }}
        customCellRenderers={{
          createdAt: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
          invoiceId: {
            renderer: (field, value) => {
              return value ? value.substring(0, 15) + '...' : '-';
            },
          },
          supplier: {
            renderer: (field, value, column, row) => {
              return row.supplier?.isPhysicalPerson
                ? row.supplier?.socialName
                : row.supplier?.fantasyName;
            },
          },
          'budget.name': {
            renderer: (field, value, column, row) => {
              return value ?? 'AGRUPADO';
            },
          },
        }}
      />

      <InvoiceDialog
        open={invoiceOpen}
        purchase={selectedPurchase}
        onCancel={() => setInvoiceOpen(false)}
        onSuccess={() => {
          loadPurchases();
          setInvoiceOpen(false);
        }}
      />

      <FilesDialog
        open={purchaseFileOpen}
        modelId={selectedPurchaseId}
        restGetData={restApiClient.purchaseFile.getAllByPurchaseId}
        restDeleteData={restApiClient.purchaseFile.delete}
        restSubmitData={restApiClient.purchaseFile.submitFile}
        restDownloadData={restApiClient.purchaseFile.downloadFile}
        actions={PurchaseFile.actions}
        onCancel={() => {
          setPurchaseFileOpen(false);
          setSelectedPurchaseId(null);
        }}
        onSuccess={() => {
          setPurchaseFileOpen(false);
          setSelectedPurchaseId(null);
        }}
      />

      <InvoiceListDialog
        open={purchaseEntryListOpen}
        modelId={selectedPurchaseId}
        onCancel={() => {
          setPurchaseEntryListOpen(false);
          setSelectedPurchaseId(null);
        }}
      />

      <PurchaseDialog
        open={purchaseMergeOpen}
        purchaseId={purchaseMergeTarget}
        onCancel={() => {
          setPurchaseMergeTarget(-1);
          setPurchaseMergeOpen(false);
        }}
        onSelect={(mergeList) => {
          restApiClient.purchase.merge(mergeList).then(() => {
            loadPurchases().then(() => {
              setPurchaseMergeTarget(-1);
              setPurchaseMergeOpen(false);
            });
          });
        }}
      />

      <ErrorDialog
        open={errorOpen}
        label="Error"
        error="Esta compra ainda possui pendências e não pode ser encerrada."
        onClose={() => setErrorOpen(false)}
      />

      <Snackbar
        open={checkoutSuccessOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setCheckoutSuccessOpen(false)}
      >
        <Alert onClose={() => setCheckoutSuccessOpen(false)} severity="success">
          {'Compra finalizada com sucesso'}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PurchaseList;
