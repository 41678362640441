import React, { useState, useEffect } from 'react';
import EmployeeExpense from '../../../forms/employeeExpense';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { isEqualWith, cloneDeep, isEmpty } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  Container,
  StyledMoneyInput,
  Button,
  SaveIcon,
  StyledTypography,
} from './styles';

function EmployeeExpenseList({ history, match, ...props }) {
  const expenseId = match.params?.id ?? -1;
  const unitId = match.params?.unitId ?? -1;
  const expenseValue = props.location?.state?.expenseValue ?? 0;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const columns = EmployeeExpense.tableSchema;

  const [originalEmployeeExpense, setoriginalEmployeeExpense] = useState({});
  const [originalEmployeeExpenseList, setOriginalEmployeeExpenseList] =
    useState([]);
  const [employeeExpenseList, setEmployeeExpenseList] = useState([]);
  const [total, setTotal] = useState(0);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const salvar = (e) => {
    if (expenseValue !== total) {
      setAlert({
        message: 'Os valores de despesas lançados estão divergentes!',
        severity: 'error',
      });
    }
    const added = employeeExpenseList.filter(
      (employeeExpense) =>
        !originalEmployeeExpenseList.some((obj) =>
          isEqualWith(
            obj,
            employeeExpense,
            (obj1, obj2) =>
              obj1.employeeId === obj2.employeeId &&
              obj1.expenseId === obj2.expenseId &&
              obj1.value === obj2.value
          )
        )
    );

    const removed = originalEmployeeExpenseList
      .filter(
        (employeeExpense) =>
          !employeeExpenseList.some((obj) =>
            isEqualWith(
              obj,
              employeeExpense,
              (obj1, obj2) =>
                obj1.employeeId === obj2.employeeId &&
                obj1.expenseId === obj2.expenseId &&
                obj1.value === obj2.value
            )
          )
      )
      .map((employeeExpense) => employeeExpense.id);
    restApiClient.expense
      .update(
        expenseId,
        originalEmployeeExpense,
        originalEmployeeExpense,
        added,
        removed
      )
      .then((result) => {
        if (result.status === 200) {
          loadList();
          setoriginalEmployeeExpense({});
          setAlert({
            message:
              'Os valores de despesas para funcionários foram salvos com sucesso!',
            severity: 'success',
          });
        }
      });
  };

  const loadList = async () => {
    restApiClient.employeeExpense.getAllByExpense(expenseId).then((e) => {
      const employeeExpense = e.data.values;
      restApiClient.employee
        .getAllByUnit(
          unitId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          let newRows = e.data;
          newRows.values = e.data.values.map((rowEmployee) => {
            const employeeExpenseInfo = employeeExpense.filter(
              (employeeExpense) =>
                employeeExpense.employeeId === rowEmployee.userId
            );
            if (employeeExpenseInfo) {
              rowEmployee.value = employeeExpenseInfo[0]?.value;
              if (isNaN(rowEmployee.value)) rowEmployee.value = 0;
            }
            return rowEmployee;
          });
          setRows(newRows);
        });
    });
  };

  useEffect(() => {
    if (!isEmpty(originalEmployeeExpense)) {
      loadList();
    }
  }, [
    rowsPerPage,
    page,
    orderBy,
    searchBy,
    expenseId,
    originalEmployeeExpense,
  ]);

  useEffect(() => {
    if (isEmpty(originalEmployeeExpense)) {
      restApiClient.employeeExpense.getAllByExpense(expenseId).then((e) => {
        let mapList = e.data.values.map((employeeExpense) => ({
          id: employeeExpense.id,
          employeeId: employeeExpense.employeeId,
          expenseId: employeeExpense.expenseId,
          value: employeeExpense.value,
        }));
        setOriginalEmployeeExpenseList(mapList);
        setEmployeeExpenseList(cloneDeep(mapList));
        setoriginalEmployeeExpense(e.data);
        setTotal(
          e.data.values.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value;
          }, 0)
        );
      });
    }
  }, [expenseId, originalEmployeeExpense]);

  return (
    <Container>
      <CrudTable
        title={'Despesa'}
        customFooterComponent={
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={salvar}
            >
              Salvar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={async (e) => {
                await salvar(e);
                history.goBack();
              }}
            >
              Salvar e Voltar
            </Button>
          </>
        }
        customInfosComponent={
          <>
            <StyledTypography variant="h6">
              Valor da despesa:{' '}
              {expenseValue?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </StyledTypography>
            <StyledTypography variant="h6">
              Valor total:{' '}
              {total?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </StyledTypography>
          </>
        }
        modelActions={EmployeeExpense.actions}
        columns={columns}
        customColumns={[
          {
            id: 'value',
            label: 'Valor',
            minWidth: 100,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          value: {
            renderer: (field, value, column, row) => {
              row.value = parseFloat(row.value);
              let employeeExpenseFind = employeeExpenseList.find(
                (x) => x.employeeId === row.userId
              );
              let newValue = 0;
              if (employeeExpenseFind) newValue = employeeExpenseFind?.value;
              else newValue = value;
              return (
                <StyledMoneyInput
                  value={newValue}
                  onChange={(e, value, valueMask) => {
                    let employeeExpense = null;
                    const newList = [...employeeExpenseList];
                    if (
                      (employeeExpense = newList.find(
                        (x) => x.employeeId === row.userId
                      ))
                    ) {
                      employeeExpense.value = value;
                    } else {
                      const employeeExpense = {
                        employeeId: row.userId,
                        value: value !== 0 ? value : 0,
                        expenseId: expenseId,
                      };
                      newList.push(employeeExpense);
                    }
                    row.value = value;
                    row.subtotal = row.value !== 0 ? row.value : 0;
                    if (isNaN(row.subtotal)) row.subtotal = 0;
                    setEmployeeExpenseList(newList);
                    setTotal(
                      newList.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue.value;
                      }, 0)
                    );
                  }}
                />
              );
            },
          },
          'sector.name': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />

      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default EmployeeExpenseList;
