/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  Dialog,
  Container,
  DialogTitle,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  DialogContentText,
  Typography,
} from './styles';

function ConfirmationBulkPaymentDialog({ onSuccess, onCancel, ...props }) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h6">Atenção</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Esta ação gera pagamentos em massa. Certeza que deseja continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <SubmitButton
              onClick={(e) => {
                onSuccess?.(e);
              }}
              variant="contained"
              color="primary"
            >
              Confirmar
            </SubmitButton>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ConfirmationBulkPaymentDialog;
