/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import UnitFinance from '../../forms/unitFinance';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import isEmpty from 'lodash/isEmpty';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';
import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  Bank,
} from './styles';
import first from 'lodash/first';

const SelectUnitFinanceDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  required,
  customType,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedUnitFinance, setSelectedUnitFinance] = React.useState({});
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = UnitFinance.dialogTableSchema;
  const [, index] = path?.split('.') ?? [];
  const paymentType = index
    ? config?.completeData?.cashRegisterValues?.[index]?.paymentTypeType
    : null;
  if (paymentType) {
    switch (paymentType) {
      case 'money':
        customType = 'Caixa';
        break;
      case 'check':
        customType = 'Cheque';
        break;
      default:
        customType = 'Banco';
        break;
    }
  }

  const handleClickOpen = () => {
    if (enabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPage(0);
  };

  useEffect(() => {
    restApiClient.unitFinance
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? customType
            ? new FilterTree()
                .and((leftChild, rightChild) => {
                  leftChild.isNull('deletedAt');
                  rightChild.and((leftOp, rightOp) => {
                    leftOp.eq('type', customType);
                    rightOp
                      .like('id', searchBy)
                      .or()
                      .like('bankAccount.holderName', searchBy)
                      .or()
                      .like('bankAccount.bank', searchBy)
                      .or()
                      .like('bankAccount.account', searchBy)
                      .or()
                      .like('bankAccount.agency', searchBy);
                  });
                })
                .toString()
            : new FilterTree()
                .and((leftChild, rightChild) => {
                  leftChild.isNull('deletedAt');
                  rightChild
                    .like('id', searchBy)
                    .or()
                    .like('type', searchBy)
                    .or()
                    .like('bankAccount.holderName', searchBy)
                    .or()
                    .like('bankAccount.bank', searchBy)
                    .or()
                    .like('bankAccount.account', searchBy)
                    .or()
                    .like('bankAccount.agency', searchBy);
                })
                .toString()
          : customType
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild.isNull('deletedAt');
                rightChild.like('type', customType);
              })
              .toString()
          : new FilterTree().isNull('deletedAt').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
        if (e.data.values.length === 1) {
          const selected = first(e.data.values);
          setSelectedUnitFinance(selected);
          handleChange?.(path, selected.id);
          onSuccess?.(selected.id);
        }
      });
  }, [rowsPerPage, page, orderBy, searchBy, customType]);

  useEffect(() => {
    if (data) {
      restApiClient.unitFinance.findById(data).then((e) => {
        setSelectedUnitFinance(e.data);
      });
    } else {
      setSelectedUnitFinance({});
    }
  }, [data]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          className={className}
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<Bank />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label={label}
          InputLabelProps={{
            readOnly: true,
          }}
          required={required}
          value={
            selectedUnitFinance?.bankAccount?.holderName
              ? `${selectedUnitFinance?.bankAccount?.holderName} / ${
                  selectedUnitFinance?.bankAccount?.bank
                } / ${selectedUnitFinance?.bankAccount?.agency}${
                  selectedUnitFinance?.bankAccount?.agencyDigit
                    ? `-${selectedUnitFinance?.bankAccount?.agencyDigit}`
                    : ''
                } / ${selectedUnitFinance?.bankAccount?.account}${
                  selectedUnitFinance?.bankAccount?.accountDigit
                    ? `-${selectedUnitFinance?.bankAccount?.accountDigit}`
                    : ''
                }`
              : selectedUnitFinance?.type === 'Caixa'
              ? `${selectedUnitFinance?.type} Financeiro`
              : selectedUnitFinance?.type === 'Cheque'
              ? `${selectedUnitFinance?.type}`
              : ''
          }
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
          {...props}
        />

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <StyledCrudTable
              title={'Seleção de Conta Bancária'}
              modelActions={UnitFinance.actions}
              columns={columns}
              rows={rows}
              page={page}
              setPage={(page) => setPage(page)}
              rowsPerPage={rowsPerPage}
              availableRowsPerPage={[3]}
              setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              onOrderByChange={(orderBy) => setOrderby(orderBy)}
              orderBy={orderBy}
              setOrderBy={(value) => setOrderby(value)}
              searchBy={searchBy}
              setSearchBy={(value) => setSearchBy(value)}
              actions={{
                insert: {
                  icon: <AddBox />,
                  onClick: (id, data) => {
                    setSelectedUnitFinance(data);
                    handleChange?.(path, id);
                    onSuccess?.(id);
                    handleClose();
                  },
                  title: 'Vincular',
                  allowedActions: [UnitFinance.actions.getAll],
                },
              }}
              customCellRenderers={{
                'bankAccount.holderName': {
                  renderer: (field, value) => {
                    return value ?? '-';
                  },
                },
                'bankAccount.bank': {
                  renderer: (field, value) => {
                    return value ?? '-';
                  },
                },
                'bankAccount.account': {
                  renderer: (field, value, column, row) => {
                    return value
                      ? `${value}${
                          row.bankAccount.accountDigit
                            ? `-${row.bankAccount.accountDigit}`
                            : ''
                        }`
                      : '-';
                  },
                },
                'bankAccount.agency': {
                  renderer: (field, value, column, row) => {
                    return value
                      ? `${value}${
                          row.bankAccount.agencyDigit
                            ? `-${row.bankAccount.agencyDigit}`
                            : ''
                        }`
                      : '-';
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Hidden>
  );
};

export default SelectUnitFinanceDialogControl;
