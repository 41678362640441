/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Budget from '../../forms/budget';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
} from './styles';
import isEmpty from 'lodash/isEmpty';

const BudgetInEditingListDialog = ({
  onSelect,
  onCancel,
  disableBudget,
  open,
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Budget.dialogTableSchema;

  useEffect(() => {
    if (open && disableBudget) {
      restApiClient.budget
        .getAllBudgetsInEditing(
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp.df('id', disableBudget);
                  rightOp
                    .like('name', searchBy)
                    .or()
                    .like('unit.name', searchBy)
                    .or()
                    .like('supplier.socialName', searchBy)
                    .or()
                    .like('supplier.fantasyName', searchBy);
                })
                .toString()
            : new FilterTree().df('id', disableBudget).toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, open, disableBudget]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onCancel}
      maxWidth="xl"
    >
      <DialogContent>
        <StyledCrudTable
          title={'Seleção de Orçamento'}
          modelActions={Budget.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[3]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderby(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderby(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            insert: {
              icon: <AddBox />,
              onClick: (id) => onSelect?.(id),
              title: 'Selecionar',
            },
          }}
          customCellRenderers={{
            supplier: {
              renderer: (field, value, column, row) => {
                return !isEmpty(row.supplier)
                  ? row.supplier?.isPhysicalPerson
                    ? row.supplier?.socialName
                    : row.supplier?.fantasyName
                  : '-';
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setPage(0);
            onCancel?.();
          }}
          color="primary"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BudgetInEditingListDialog;
