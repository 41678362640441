export default class FilterNode {
  isAggregation() {
    return ['and', 'or'].includes(this.op);
  }

  static buildUnary(field, op, value) {
    const result = new FilterNode();
    result.field = field;
    result.op = op;
    result.value = value;
    return result;
  }

  static buildAggregation(op, leftChild, rightChild) {
    const result = new FilterNode();
    result.leftChild = leftChild;
    result.op = op;
    result.rightChild = rightChild;
    return result;
  }

  static strictAnd(leftChild, rightChild) {
    return this.buildAggregation('strict_and', leftChild, rightChild);
  }

  static strictOr(leftChild, rightChild) {
    return this.buildAggregation('strict_or', leftChild, rightChild);
  }

  static regex(field, value) {
    return this.buildUnary(field, 'regex', value);
  }

  static and(leftChild, rightChild) {
    return this.buildAggregation('and', leftChild, rightChild);
  }

  static or(leftChild, rightChild) {
    return this.buildAggregation('or', leftChild, rightChild);
  }

  static eq(field, value) {
    return this.buildUnary(field, '=', value);
  }

  static df(field, value) {
    return this.buildUnary(field, '!=', value);
  }

  static gt(field, value) {
    return this.buildUnary(field, '>', value);
  }

  static lt(field, value) {
    return this.buildUnary(field, '<', value);
  }

  static gte(field, value) {
    return this.buildUnary(field, '>=', value);
  }

  static lte(field, value) {
    return this.buildUnary(field, '<=', value);
  }

  static like(field, value) {
    return this.buildUnary(field, 'like', value.replace(' ', '%'));
  }

  static isNull(field) {
    return this.buildUnary(field, 'is', 'NULL');
  }

  static notNull(field) {
    return this.buildUnary(field, 'not', 'NULL');
  }

  toString() {
    if (this.field && this.value) {
      return `${this.field} ${this.op} '${this.value}'`;
    } else if (this.leftChild && this.rightChild) {
      return `${
        this.op
      }(${this.leftChild.toString()}, ${this.rightChild.toString()})`;
    }

    throw new Error();
  }
}
