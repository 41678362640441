import styled from 'styled-components';
import {
  UserBadge as MaterialUserBadge,
  LockPassword as MaterialLockPassword,
  Restore as MaterialRestore,
  Close as MaterialClose,
} from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const UserBadge = styled(MaterialUserBadge)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const LockPassword = styled(MaterialLockPassword)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
