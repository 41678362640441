import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectAnySupplierDialogControl from '../../components/selectAnySupplierDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectAnySupplierDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectAnySupplierDialog'
  )
);

const customSelectAnySupplierDialogControlTester = rankWith(
  4,
  isSelectAnySupplierDialogControl
);

export default {
  tester: customSelectAnySupplierDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectAnySupplierDialogControl),
};
