const Sector = {
  actions: {
    create: 'SectorController@create',
    getAll: 'SectorController@getAll',
    getAllTree: 'SectorController@getAllTree',
    findById: 'SectorController@findById',
    update: 'SectorController@patchUpdate',
    delete: 'SectorController@delete',
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
  ],
  listSchema: [{ id: 'name', label: 'Nome', minWidth: 100, align: 'center' }],
};

export default Sector;
