/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Container,
  AutoDiv,
  DialogContent,
  ActionsContainer,
  CancelButton,
  SubmitButton,
  CenterContainer,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextStyledH6,
  TextStyledH5,
  Card,
  HeaderTableCell,
  BodyTableCell,
} from './styles';
import ReactToPrint from 'react-to-print';
import restApiClient from '../../services/restApiClient';
import { DottedLine } from '../printTransferDialog/styles';

function generateOrderToPrint(order, userStock) {
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Container ref={ref}>
      <CenterContainer>
        <TextStyledH5>Pedido</TextStyledH5>
        <DottedLine />
      </CenterContainer>
      <AutoDiv>
        <TextStyledH6>Num Pedido: {order?.id}</TextStyledH6>
        <TextStyledH6>Destino: {order?.toUnit?.name}</TextStyledH6>
        <TextStyledH6>Solicitante: {order?.employee?.user?.name}</TextStyledH6>
        <TextStyledH6>Separador: {userStock?.name}</TextStyledH6>
      </AutoDiv>
      <CenterContainer>
        <DottedLine />
      </CenterContainer>
      <AutoDiv>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <HeaderTableCell>Cod</HeaderTableCell>
                <HeaderTableCell>Nome</HeaderTableCell>
                <HeaderTableCell>Marca</HeaderTableCell>
                <HeaderTableCell align="center">Loc</HeaderTableCell>
                <HeaderTableCell align="center">Qtd</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order?.orderItem
                .sort((a, b) => a.item.id - b.item.id)
                .map((row) => (
                  <>
                    <TableRow key={row.item.id}>
                      <BodyTableCell component="th" scope="row">
                        {row.item.id}
                      </BodyTableCell>
                      <BodyTableCell colSpan={3}>{row.item.name}</BodyTableCell>
                    </TableRow>
                    <TableRow>
                      <BodyTableCell colSpan={2} />
                      <BodyTableCell>{row.item?.brand ?? '-'}</BodyTableCell>
                      <BodyTableCell align="center">
                        {row.item?.itemLocators[0]?.locator ?? '-'}
                      </BodyTableCell>
                      <BodyTableCell align="center">
                        {row.quantity}
                      </BodyTableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AutoDiv>
    </Container>
  ));

  return ComponentToPrint;
}

function PrintOrderDialog({ onCancel, selectedTransfer, ...props }) {
  const [order, setOrder] = useState(null);
  const [userStock, setUserStock] = useState(null);
  const componentRef = useRef();
  const OrderToPrint = generateOrderToPrint(order, userStock);

  async function loadOrder() {
    await restApiClient.order.findByIdByEmployee(selectedTransfer).then((e) => {
      setOrder(e.data);
      loadUser();
    });
  }

  async function loadUser() {
    await restApiClient.user.profile().then((e) => {
      setUserStock(e);
    });
  }

  useEffect(() => {
    if (selectedTransfer !== null) loadOrder();
  }, [selectedTransfer]);
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <Card>
            <OrderToPrint ref={componentRef} />
          </Card>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <ReactToPrint
              trigger={() => (
                <SubmitButton variant="contained" color="primary">
                  Imprimir
                </SubmitButton>
              )}
              content={() => componentRef.current}
              pageStyle={`
                  @page {
                    size: 80mm 60cm;
                    margin: 2mm 2mm 2mm 2mm; 
                  }
                `}
            />
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default PrintOrderDialog;
