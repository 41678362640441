import React, { useState, useEffect } from 'react';
import CashRegisterValue from '../../../../../forms/cashRegisterValue';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, AddToList, BackButton } from './styles';

function ClinicCashRegisterCheckList({ history, ...props }) {
  const cashRegisterId = props?.match?.params?.id ?? -1;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = CashRegisterValue.clinicCheckTableSchema;

  useEffect(() => {
    restApiClient.cashRegisterValue
      .getAllChecksByCashRegisterId(
        cashRegisterId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('status', searchBy)
              .or()
              .like('cashRegisterClose.date', searchBy)
              .or()
              .like('cashRegisterClose.number_cash_register', searchBy)
              .or()
              .like('cashRegisterClose.unit.name', searchBy)
              .or()
              .like('cashRegisterClose.employee.user.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, cashRegisterId]);

  return (
    <Container>
      <CrudTable
        title={'Lançamento de Cheques'}
        customFooterComponent={
          <BackButton
            onClick={() => history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Voltar
          </BackButton>
        }
        modelActions={CashRegisterValue.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <AddToList />,
            onClick: (cashRegisterValueId, row) => {
              history.push({
                pathname: `/clinic/cashRegisterClose/itemizedCheck/${cashRegisterValueId}`,
                state: { cashRegisterValue: row },
              });
            },
            title: 'Visualizar Fechamento de Caixa',
          },
        }}
        customCellRenderers={{
          'cashRegisterClose.date': {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          confirmed: {
            renderer: (field, value) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.value !== row.confirmed) {
            return '#ffe0e0';
          }
        }}
      />
    </Container>
  );
}

export default ClinicCashRegisterCheckList;
