import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import isEmpty from 'lodash/isEmpty';
import SelectAppointmentControl from '../../components/selectAppointmentControl';

const isSelectAppointmentDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectAppointmentDialog'
  )
);

const customSelectBuyerDialogControlTester = rankWith(
  4,
  isSelectAppointmentDialogControl
);

export default {
  tester: customSelectBuyerDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectAppointmentControl),
};
