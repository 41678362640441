import styled from 'styled-components';
import {
  Box as MaterialBox,
  AddBox as MaterialAddBox,
  Numbers as MaterialNumbers,
  Grid3x2GapFill as MaterialGrid,
} from '../../../../styles/icons';
import {
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
} from '@material-ui/core';
import { ViewModule as MaterialViewModule } from '@styled-icons/material/ViewModule';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const SelectContainer = styled.div`
  width: 300px;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ViewModule = styled(MaterialViewModule)`
  width: 30px;
  height: 30px;
  color: var(--secondary);
`;

export const Numbers = styled(MaterialNumbers)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const Grid = styled(MaterialGrid)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
