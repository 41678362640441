/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { isEmpty } from 'lodash';

import {
  Container,
  ActionsContainer,
  SubmitButton,
  Input,
  ContentContainer,
  DialogTitle,
  CancelButton,
  DialogContent,
} from './styles';
import restApiClient from '../../../services/restApiClient';

function ItemLocatorDialog({
  onSuccess,
  onError,
  onCancel,
  item,
  unitId,
  ...props
}) {
  const [locator, setLocator] = useState('');
  const [errors, setErrors] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemLocator = {
      itemId: item.id,
      unitId: unitId,
      locator: locator,
    };
    const oldItemLocator = item.itemLocators[0]?.id ? item.itemLocators[0] : '';
    if (oldItemLocator === '') {
      restApiClient.itemLocator
        .create(itemLocator)
        .then((result) => {
          setLocator('');
          onSuccess(result.data);
        })
        .catch((x) => {
          if (x.response?.status === 400) {
            onError?.(x.response);
            setErrors(x.response.data.errors.locator.join(' '));
          }
        });
    } else {
      restApiClient.itemLocator
        .update(oldItemLocator.id, oldItemLocator, itemLocator)
        .then((result) => {
          setLocator('');
          onSuccess(result.data);
        })
        .catch((x) => {
          if (x.response?.status === 400) {
            onError?.(x.response);
            setErrors(x.response.data.errors.locator.join(' '));
          }
        });
    }
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogTitle id="alert-dialog-title">Definir Localizador</DialogTitle>
          <DialogContent>
            <ContentContainer>
              <Input
                required
                type="text"
                label="Localizador"
                value={locator}
                error={!isEmpty(errors.quantity)}
                helperText={!isEmpty(errors) ? errors.join(' ') : ''}
                onChange={(e) => setLocator(e.target.value)}
              />
            </ContentContainer>
          </DialogContent>
          <DialogActions>
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  setErrors([]);
                  setLocator('');
                  onCancel?.();
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SubmitButton type="submit" variant="contained" color="primary">
                Entrar
              </SubmitButton>
            </ActionsContainer>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default ItemLocatorDialog;
