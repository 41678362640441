import styled from 'styled-components';
import {
  DialogContent as MaterialDialogContent,
  DialogActions as MaterialDialogActions,
} from '@material-ui/core';
import CrudTable from '../crudTable';
import ImportFileDialog from '../importFileDialog';
import MaterialButton from '@material-ui/core/Button';
import { FileDownload as MaterialFileDownload } from '@styled-icons/fa-solid/FileDownload';

export const Container = styled.div``;

export const DialogContent = styled(MaterialDialogContent)`
  width: 100%;
  height:100%
  padding: 0px !important;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-bottom: 50px !important ;
`;

export const DialogActions = styled(MaterialDialogActions)``;

export const Button = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const StyledImportFileDialog = styled(ImportFileDialog)`
  margin-bottom: 50px !important ;
`;

export const FileDownload = styled(MaterialFileDownload)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;
