import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
`;

export const AutoDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 620px;
    width: 100%;
`;
