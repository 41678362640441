import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  Button as MaterialButton,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  Card as MaterialCard,
  CardHeader as MaterialCardHeader,
  Checkbox as MaterialCheckbox,
  FormControlLabel as MaterialFormControlLabel,
  Grid as MaterialGrid,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';
import { Home as MaterialHome } from '../../styles/icons';
import CrudTable from '../crudTable';
import MoneyInput from '../moneyInput';
import SelectUnitFinanceDialog from '../selectUnitFinanceDialogControl';
import PercentageInput from '../percentageInput';

export const Container = styled.div``;

export const Dialog = styled(MaterialDialog)``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CardHeader = styled(MaterialCardHeader)`
  text-align: start;
  width: 100%;
  padding: 15px !important;
  font-size: 1.8em;
  font-weight: 500;
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: inherit;
  padding: 5px;
  h3 {
    font-weight: 600;
  }
`;

export const DialogContent = styled(MaterialDialogContent)`
  width: 850px;
  padding: 10px !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 0 !important;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 30%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 30%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Home = styled(MaterialHome)`
  width: 25px;
  height: 25px;
`;

export const Card = styled(MaterialCard)`
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
  height: 350px;
  overflow:auto;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 100px;
  text-align: center !important;
`;

export const MuiPickersUtilsProvider = styled(MaterialMuiPickersUtilsProvider)`
`;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  height: auto;
  width: 100%;
`;

export const Grid = styled(MaterialGrid)``;

export const Checkbox = styled(MaterialCheckbox)``;

export const FormControlLabel = styled(MaterialFormControlLabel)``;

export const SelectUnitFinance = styled(SelectUnitFinanceDialog)``;

export const StyledPercentageInput = styled(PercentageInput)`
  width: 60px;
  text-align: center !important;
`;
