/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Item from '../../forms/item';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
} from './styles';

const ItemDialog = ({ onSelect, onCancel, open, unitId, disabledIds }) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Item.dialogTableSchema;

  useEffect(() => {
    restApiClient.item
      .getAllWithStock(
        unitId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onCancel}
      maxWidth="xl"
    >
      <DialogContent>
        <StyledCrudTable
          title={'Seleção de Item'}
          modelActions={Item.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[3]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderby(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderby(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            insert: {
              icon: <AddBox />,
              onClick: onSelect,
              title: 'Selecionar',
              selector: (row) => !disabledIds.includes(row.id),
            },
          }}
          customCellRenderers={{
            stock: {
              renderer: (field, value) => {
                return value?.availableQuantity ?? 0;
              },
            },
            group: {
              renderer: (field, value) => {
                if (value?.parent) {
                  if (value.parent?.parent) {
                    return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                  } else {
                    return `${value.parent.name} > ${value.name}`;
                  }
                } else {
                  return value.name;
                }
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemDialog;
