import React from 'react';
import Patient from '../../../forms/patient';
import CreateUpdateForm from '../../../components/createUpdateForm';
import restApiClient from '../../../services/restApiClient';
import { Container } from './styles';

function PatientCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Patient}
        restGetDataById={restApiClient.patient.findById}
        restCreateData={async (patient) => {
          return await restApiClient.patient.create({ ...patient });
        }}
        restUpdateData={async (id, oldPatient, patient) => {
          delete patient.requestingUnitBoolean;
          return await restApiClient.patient.update(id, oldPatient, patient);
        }}
        onCreateSuccess={() => {
          props.history.goBack();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default PatientCreateUpdate;
