import { Container } from './styles';
import Buyer from '../../../forms/buyer';
import React, { useState, useEffect } from 'react';
import FilterTree from '../../../helpers/filterTree';
import CrudTable from '../../../components/crudTable';
import restApiClient from '../../../services/restApiClient';

function BuyerList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ socialName: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Buyer.tableSchema;

  useEffect(() => {
    restApiClient.buyer
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('socialName', searchBy)
              .or()
              .like('fantasyName', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Gestão de Compradores'}
        modelActions={Buyer.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => history.push('/buyer/create'),
          },
          update: {
            onClick: (buyerId) => history.push(`/buyer/update/${buyerId}`),
          },
          delete: {
            onClick: (buyerId, index) => {
              restApiClient.buyer.delete(buyerId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
        }}
        customCellRenderers={{
          fantasyName: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default BuyerList;
