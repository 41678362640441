/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Container,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  Card,
  HeaderContainer,
  SelectContainer,
  FormControl,
  StyledCrudTable,
  CardHeader,
  Grid,
  StyledMoneyInput,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  DatePickerCell,
  SelectUnitFinance,
  TextField,
} from './styles';
import Expense from '../../forms/expense';
import { toFixed } from '../../extensions/object';
import MoneyInput from '../moneyInput';
import DayJsUtils from '@date-io/dayjs';
import moment from 'moment';

function ExpenseInstallmentDialog({
  total,
  date,
  onSuccess,
  onCancel,
  ...props
}) {
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [orderBy, setOrderby] = useState({});
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [parcelsList, setParcelsList] = useState([]);
  const [errorParcel, setErrorParcel] = useState(false);
  const [errorTotalValue, setErrorTotalValue] = useState(false);
  const [totalValue, setTotalValue] = useState(null);
  const [baseDate, setBaseDate] = useState(null);
  const [firstExpensePaid, setFirstExpensePaid] = useState(null);
  const [payday, setPayday] = useState(null);
  const [errorPayday, setErrorPayday] = useState(false);
  const [unitFinanceId, setUnitFinanceId] = useState(false);
  const columns = Expense.InstallmentTableSchema;

  useEffect(() => {
    setTotalValue(total ?? 0);
    setBaseDate(
      moment(date, 'YYYY-MM-DD').isValid()
        ? moment(new Date(date + ' 00:00:00'), 'YYYY-MM-DD')
        : null
    );
  }, [total, date]);

  useEffect(() => {
    if (selectedParcel && baseDate && !isNaN(baseDate) && totalValue > 0) {
      const valueParcel = toFixed(totalValue / selectedParcel, 2);
      const firstParcel = parseFloat(
        (totalValue - valueParcel * (selectedParcel - 1)).toFixed(2)
      );

      const parcels = [];
      for (let i = 0; i < selectedParcel; i++) {
        if (i < selectedParcel) {
          let dueDay;
          let dueDate = new Date(baseDate);
          let lastDate = new Date(
            dueDate.getFullYear(),
            dueDate.getMonth() + i + 1,
            0
          );
          dueDate.setHours(0, 0, 0, 0);
          dueDay =
            dueDate.getDate() >= lastDate.getDate() ? 0 : dueDate.getDate();

          let parcel = {
            number: i + 1,
            value: i !== 0 ? valueParcel : firstParcel,
            dueDate: moment(
              dueDate.setMonth(
                dueDate.getMonth() + i + parseInt(!dueDay * 1),
                dueDay
              )
            ).format('YYYY-MM-DD'),
          };
          parcels.push(parcel);
        }
      }
      setRows({ count: selectedParcel, values: parcels });
      setParcelsList(parcels);
    }
  }, [selectedParcel, totalValue, baseDate]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent dividers>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const isValid = parcelsList.reduce(
                (acc, item) =>
                  acc &&
                  item.value > 0 &&
                  moment(item.dueDate, 'YYYY-MM-DD').isValid(),
                true
              );

              if (
                isValid &&
                selectedParcel &&
                baseDate &&
                !isNaN(baseDate) &&
                totalValue > 0
              ) {
                if (firstExpensePaid) {
                  if (payday?.isValid() && unitFinanceId) {
                    onSuccess?.({
                      installments: parcelsList,
                      firstExpensePaid,
                      payday: moment(new Date(payday)).format('YYYY-MM-DD'),
                      unitFinanceId,
                    });
                  } else {
                    setErrorPayday(true);
                  }
                } else {
                  onSuccess?.({ installments: parcelsList, firstExpensePaid });
                }
              } else {
                setErrorTotalValue(totalValue <= 0);
                setErrorParcel(!selectedParcel);
              }
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title="Parcelamento de Despesa" />
              </HeaderContainer>
              <SelectContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <MoneyInput
                      value={totalValue}
                      onChange={(e, value) => {
                        setErrorTotalValue(false);
                        setTotalValue(value);
                      }}
                      variant="outlined"
                      size="small"
                      label="Valor Total"
                      required
                      error={errorTotalValue}
                      helperText={
                        errorTotalValue ? 'Este campo é obrigatório.' : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DayJsUtils}>
                      <KeyboardDatePicker
                        id="base-date"
                        label="Data Base"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        size="small"
                        value={baseDate}
                        onChange={(date) => {
                          setBaseDate(date);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'Editar data',
                        }}
                        cancelLabel="Cancelar"
                        okLabel="Selecionar"
                        invalidDateMessage="Data em formato inválido."
                        onError={(value) => !!value}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      error={errorParcel}
                    >
                      <TextField
                        value={selectedParcel || ''}
                        label="Parcelas"
                        variant="outlined"
                        size="small"
                        required
                        type={'number'}
                        onChange={(e) => {
                          setErrorParcel(false);
                          setSelectedParcel(e.target.value);
                        }}
                      />
                      <FormHelperText>
                        {errorParcel ? 'Este campo é obrigatório.' : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </SelectContainer>
              {selectedParcel &&
              baseDate &&
              !isNaN(baseDate) &&
              totalValue > 0 ? (
                <>
                  <StyledCrudTable
                    emptyHeader
                    noPagination
                    modelActions={Expense.actions}
                    columns={columns}
                    rows={rows}
                    orderBy={orderBy}
                    setOrderBy={(value) => setOrderby(value)}
                    customCellRenderers={{
                      value: {
                        renderer: (field, value, column, row) => {
                          let itemFind = parcelsList.find(
                            (x) => x.number === row.number
                          );
                          let price;
                          if (itemFind) price = itemFind?.value;
                          else price = value;
                          return (
                            <StyledMoneyInput
                              value={price}
                              onChange={(e, value) => {
                                let item;
                                const newList = [...parcelsList];
                                if (
                                  (item = newList.find(
                                    (x) => x.number === row.number
                                  ))
                                ) {
                                  item.value = value;
                                } else {
                                  const item = {
                                    number: row.number,
                                    value: value,
                                    dueDate: row.dueDate,
                                  };
                                  newList.push(item);
                                }
                                setParcelsList(newList);
                                row.value = value;
                              }}
                              helperText={price <= 0 ? 'Valor inválido.' : ''}
                              error={price <= 0}
                              FormHelperTextProps={{
                                style: { fontSize: '0.7rem' },
                              }}
                            />
                          );
                        },
                      },
                      dueDate: {
                        renderer: (field, value, column, row) => {
                          let itemFind = parcelsList.find(
                            (x) => x.dueDate === row.dueDate
                          );

                          let dueDate;
                          if (itemFind) dueDate = itemFind?.dueDate;
                          else dueDate = value;
                          return (
                            <MuiPickersUtilsProvider utils={DayJsUtils}>
                              <DatePickerCell
                                id="due-date"
                                format="DD/MM/YYYY"
                                size="small"
                                value={dueDate}
                                onChange={(value) => {
                                  let item;
                                  let date = moment(value?.toDate()).format(
                                    'YYYY-MM-DD'
                                  );
                                  const newList = [...parcelsList];
                                  if (
                                    (item = newList.find(
                                      (x) => x.dueDate === row.dueDate
                                    ))
                                  ) {
                                    item.dueDate = date;
                                  } else {
                                    const item = {
                                      number: row.number,
                                      value: row.value,
                                      dueDate: date,
                                    };
                                    newList.push(item);
                                  }
                                  setParcelsList(newList);
                                  row.dueDate = date;
                                }}
                                cancelLabel="Cancelar"
                                okLabel="Selecionar"
                                invalidDateMessage="Data em formato inválido."
                                onError={(value) => !!value}
                                FormHelperTextProps={{
                                  style: { fontSize: '0.7rem' },
                                }}
                                required
                              />
                            </MuiPickersUtilsProvider>
                          );
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={firstExpensePaid}
                        onChange={(e) => {
                          setFirstExpensePaid(e.target.checked);
                        }}
                        name="checked"
                      />
                    }
                    label="Primeira Parcela Paga"
                  />
                  {firstExpensePaid ? (
                    <>
                      <MuiPickersUtilsProvider utils={DayJsUtils}>
                        <KeyboardDatePicker
                          id="payday"
                          label="Data de Pagamento"
                          format="DD/MM/YYYY"
                          inputVariant="outlined"
                          size="small"
                          value={payday}
                          onChange={(date) => {
                            setErrorPayday(false);
                            setPayday(date);
                          }}
                          cancelLabel="Cancelar"
                          okLabel="Selecionar"
                          invalidDateMessage="Data em formato inválido."
                          onError={(value) => setErrorPayday(!!value)}
                          helperText={
                            errorPayday ? 'Data em formato inválido.' : ''
                          }
                          error={errorPayday}
                          required
                        />
                      </MuiPickersUtilsProvider>
                      <SelectUnitFinance
                        label="Conta Bancária"
                        size="small"
                        variant="outlined"
                        onSuccess={(id) => {
                          setUnitFinanceId(id);
                        }}
                        visible
                        enabled
                        required
                      />
                    </>
                  ) : null}
                </>
              ) : null}
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setSelectedParcel(null);
                    setBaseDate(null);
                    setTotalValue(0);
                    setFirstExpensePaid(false);
                    setPayday(null);
                    setUnitFinanceId(null);
                    setErrorParcel(false);
                    setErrorTotalValue(false);
                    onCancel?.();
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Finalizar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default ExpenseInstallmentDialog;
