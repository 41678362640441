import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import restApiClient from '../../../services/restApiClient';

import {
  Container,
  ButtonsContainer,
  HeaderContainer,
  Paper,
  FormControl,
  StyledTransferList,
  CancelButton,
  SubmitButton,
  Form,
} from './styles';

function RoleActionLink(props) {
  const modelId = props.match.params?.id;

  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const [roleCurrentActions, setRoleCurrentActions] = useState([]);
  const [systemActions, setSystemActions] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);

  useEffect(() => {
    restApiClient.action
      .getAll()
      .then((result) => {
        const modules = result.data
          .map((item) => item?.module)
          .filter((item) => !!item)
          .flat()
          .filter(function (item, pos, arr) {
            return arr.indexOf(item) === pos;
          });
        setModules(modules);
        setSystemActions(result.data);
      })
      .catch((e) => console.error(e));

    restApiClient.role
      .findById(modelId)
      .then((result) => {
        setRoleName(result.data.name);
        setRoleCurrentActions(result.data.actions);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const newLeftData = systemActions.filter(
      (x) => !roleCurrentActions.some((y) => y.actionName === x.action)
    );
    const newRightData = systemActions.filter((x) =>
      roleCurrentActions.some((y) => y.actionName === x.action)
    );
    setLeftData(newLeftData);
    setRightData(newRightData);
  }, [roleCurrentActions, systemActions]);

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const removed = roleCurrentActions
            .filter((action) =>
              leftData.some((x) => x.action === action.actionName)
            )
            .map((x) => x.id);
          const added = rightData
            .map((x) => x.action)
            .filter((x) => !roleCurrentActions.some((y) => y.actionName === x));
          if (removed.length > 0 || added.length > 0) {
            restApiClient.role.updateActions(modelId, added, removed);
          }
          props.history.goBack();
        }}
      >
        <Paper>
          <HeaderContainer>
            <span>
              {roleName
                ? `Editar permissões de "${roleName}"`
                : 'Vínculo de permissões'}
            </span>
          </HeaderContainer>
          <FormControl>
            <InputLabel shrink>Modulos</InputLabel>
            <Select
              value={selectedModule}
              onChange={(e) => setSelectedModule(e.target.value)}
              displayEmpty
            >
              <MenuItem value={''}>Todos</MenuItem>
              {modules.map((x) => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <StyledTransferList
            leftData={leftData}
            leftDataFilter={(x) =>
              !selectedModule || x.module.includes(selectedModule)
            }
            rightData={rightData}
            rightDataFilter={(x) =>
              !selectedModule || x.module.includes(selectedModule)
            }
            labelSelector={(item) => item.description ?? ''}
            onChange={(leftData, rightData) => {
              setLeftData(leftData);
              setRightData(rightData);
            }}
          />
        </Paper>
        <ButtonsContainer>
          <CancelButton
            onClick={() => props.history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Cancelar
          </CancelButton>
          <SubmitButton type="submit" variant="contained" color="primary">
            {modelId ? 'Atualizar' : 'Cadastrar'}
          </SubmitButton>
        </ButtonsContainer>
      </Form>
    </Container>
  );
}

export default RoleActionLink;
