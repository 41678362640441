/* eslint-disable no-unused-expressions */
import React from 'react';
import { DialogContent, StyledCreateUpdateForm, Dialog } from './styles';

function ConsumptionDialog({
  onSuccess,
  onError,
  onCancel,
  unitId,
  consumptionId,
  modelSchema,
  restData,
  ...props
}) {
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogContent>
        <StyledCreateUpdateForm
          forceId={consumptionId}
          modelSchema={modelSchema}
          restGetDataById={restData.findById}
          restCreateData={restData.create}
          restUpdateData={restData.update}
          onCreateSuccess={(request) => {
            onSuccess?.(request);
          }}
          onUpdateSuccess={(request) => {
            onSuccess?.(request);
          }}
          modelData={{
            unitId: unitId,
          }}
          onCancelClick={onCancel}
          {...props}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ConsumptionDialog;
