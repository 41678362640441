import React from 'react';
import { TextField } from './styles';

function TextFieldInput(props) {
  return (
    <TextField
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export default TextFieldInput;
