import React, { useState, useCallback, useEffect } from 'react';
import Attendance from '../../../../../forms/attendance';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  Preview,
  TextBulletListSquareWarning,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  SelectContainer,
} from './styles';
import DisapprovedJusticationDialog from '../../../../../components/disapprovedJustificationDialog';
import moment from 'moment';
import DayJsUtils from '@date-io/dayjs';

function AllProceduresList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDisapproved, setOpenDisapproved] = useState(false);
  const [disapprovedJustification, setDisapprovedJustification] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dates, setDates] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const columns = Attendance.tableSchema;

  const loadAttendances = useCallback(() => {
    restApiClient.attendance
      .getAllByDate(
        selectedDate,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
            .like('id', searchBy)
            .or()
            .like('dentist.user.name', searchBy)
            .or()
            .like('patient.name', searchBy)
            .or()
            .like('procedure.name', searchBy)
            .or()
            .like('unit.name', searchBy)
            .or()
            .like('status', searchBy)
            .toString()
          : null,
        orderBy
      )
      .then((e) => {
        if(!selectedDate) {
          const listDates = e.data.values.map((attendance) => {
            let date = new Date(attendance.attendanceDate + ' 00:00:00');
            return date.getTime();
          });
          setDates(listDates);
        }
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedDate]);

  useEffect(() => {
    loadAttendances();
  }, [loadAttendances]);

  function disableSundays(dayJsDate) {
    const date = dayJsDate.toDate();
    return (date.getDay() === 0 || !(dates.includes(date.getTime())));
  }
  
  return (
    <Container>
      <CrudTable
        title={'Todos Procedimentos'}
        customHeaderComponent={
          <SelectContainer>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-from"
                inputVariant="outlined"
                size="small"
                label="Data Realização"
                format="DD/MM/YYYY"
                autoOk
                initialFocusedDate={selectedDate}
                value={selectedDate}
                inputValue={null}
                onChange={(date) => setSelectedDate(date ? moment(date.toDate()).format('YYYY-MM-DD') : null)}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={() => setOpenCalendar(true)}
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
                KeyboardButtonProps={{
                  'aria-label': 'Editar data',
                }}
                clearable
                shouldDisableDate={disableSundays}
                disableFuture
                cancelLabel="Cancelar"
                okLabel="Selecionar"
                clearLabel="Limpar"
              />
            </MuiPickersUtilsProvider>
          </SelectContainer>
        }
        modelActions={Attendance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewAttendance: {
            icon: <Preview />,
            onClick: (attendanceId) => {
              history.push({
                pathname: `/dentist/audit/all-procedures/attendance/${attendanceId}`,
                state: { readonly: true },
              });
            },
            title: 'Visualizar Atendimento',
            allowedActions: [Attendance.actions.findById],
          },
          viewRejection: {
            icon: <TextBulletListSquareWarning />,
            onClick: (orderServiceId, row) => {
              setOpenDisapproved(true);
              setDisapprovedJustification(row?.disapprovedJustification);
            },
            allowedActions: [Attendance.actions.getAll],
            title: 'Visualizar Justificativa da Reprovação',
            selector: (row) => row?.status === 'Reprovado',
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
      />

      <DisapprovedJusticationDialog
        open={openDisapproved}
        disapprovedJustification={disapprovedJustification}
        onCancel={() => setOpenDisapproved(false)}
      />
    </Container>
  );
}

export default AllProceduresList;
