import merge from 'lodash/merge';
import get from 'lodash/get';
import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { JsonFormsDispatch, withJsonFormsContext } from '@jsonforms/react';
import {
  composePaths,
  findUISchema,
  moveDown,
  moveUp,
  Resolve,
  update,
  getFirstPrimitiveProp,
  createId,
  removeId,
} from '@jsonforms/core';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Grid,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const iconStyle = { float: 'right' };

const JsonFormsExpandPanelRendererComponent = (props) => {
  const [labelHtmlId] = useState(createId('expand-panel'));

  useEffect(() => {
    return () => {
      removeId(labelHtmlId);
    };
  }, [labelHtmlId]);

  const {
    childLabel,
    isDateLabel,
    childPath,
    index,
    expanded,
    moveDown,
    moveUp,
    enableMoveDown,
    enableMoveUp,
    handleExpansion,
    removeItems,
    path,
    rootSchema,
    schema,
    uischema,
    uischemas,
    renderers,
    cells,
    enabled,
    config,
  } = props;

  const foundUISchema = useMemo(
    () =>
      findUISchema(
        uischemas,
        schema,
        uischema.scope,
        path,
        undefined,
        uischema,
        rootSchema
      ),
    [uischemas, schema, uischema.scope, path, uischema, rootSchema]
  );

  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  let excludable = false;
  if (config?.completeData?.cashRegisterValues) {
    const items = config?.completeData?.cashRegisterValues?.filter((x) => x.id);
    excludable = !Object.keys(items).includes(index.toString());
  }

  return (
    <Accordion
      aria-labelledby={labelHtmlId}
      expanded={expanded}
      onChange={handleExpansion(childPath)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems={'center'}>
          <Grid item xs={7} md={9}>
            <Grid container alignItems={'center'}>
              <Grid item xs={2} md={1}>
                <Avatar aria-label="Index">{index + 1}</Avatar>
              </Grid>
              <Grid item xs={10} md={11}>
                <span id={labelHtmlId}>
                  {isDateLabel
                    ? new Date(`${childLabel}T00:00:00`).toLocaleDateString(
                        'pt-br'
                      )
                    : childLabel}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} md={3}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {appliedUiSchemaOptions.showSortButtons ? (
                    <Fragment>
                      <Grid item>
                        <IconButton
                          onClick={moveUp(path, index)}
                          style={iconStyle}
                          disabled={!enableMoveUp}
                          aria-label={`Mover para cima`}
                          size="large"
                        >
                          <ArrowUpward />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={moveDown(path, index)}
                          style={iconStyle}
                          disabled={!enableMoveDown}
                          aria-label={`Mover para baixo`}
                          size="large"
                        >
                          <ArrowDownward />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  ) : null}
                  {enabled && excludable ? (
                    <Grid item>
                      <IconButton
                        onClick={removeItems(path, [index])}
                        style={iconStyle}
                        aria-label={`Excluir`}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <JsonFormsDispatch
          schema={schema}
          uischema={foundUISchema}
          path={childPath}
          key={childPath}
          renderers={renderers}
          cells={cells}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const ExpandPanelRenderer = JsonFormsExpandPanelRendererComponent;

/**
 * Maps state to dispatch properties of an expand pandel control.
 *
 * @param dispatch the store's dispatch method
 * @returns {DispatchPropsOfArrayControl} dispatch props of an expand panel control
 */
export const useCtxDispatchToExpandPanelProps = (dispatch) => ({
  removeItems: useCallback(
    (path, toDelete) => (event) => {
      event.stopPropagation();
      dispatch(
        update(path, (array) => {
          toDelete
            .sort()
            .reverse()
            .forEach((s) => array.splice(s, 1));
          return array;
        })
      );
    },
    [dispatch]
  ),
  moveUp: useCallback(
    (path, toMove) => (event) => {
      event.stopPropagation();
      dispatch(
        update(path, (array) => {
          moveUp(array, toMove);
          return array;
        })
      );
    },
    [dispatch]
  ),
  moveDown: useCallback(
    (path, toMove) => (event) => {
      event.stopPropagation();
      dispatch(
        update(path, (array) => {
          moveDown(array, toMove);
          return array;
        })
      );
    },
    [dispatch]
  ),
});

/**
 * Map state to control props.
 * @returns {StatePropsOfControl} state props for a control
 * @param Component
 */
export const withContextToExpandPanelProps =
  (Component) =>
  ({ ctx, props }) => {
    const dispatchProps = useCtxDispatchToExpandPanelProps(ctx.dispatch);
    const { childLabelProp, schema, path, index, uischemas } = props;
    const childPath = composePaths(path, `${index}`);
    const childData = Resolve.data(ctx.core.data, childPath);
    const isDateLabel =
      schema['properties'][childLabelProp]['format'] === 'date';
    const childLabel = childLabelProp
      ? get(childData, childLabelProp, '')
      : get(childData, getFirstPrimitiveProp(schema), '');

    return (
      <Component
        {...props}
        {...dispatchProps}
        childLabel={childLabel}
        isDateLabel={isDateLabel}
        childPath={childPath}
        uischemas={uischemas}
      />
    );
  };

export const withJsonFormsExpandPanelProps = (Component) =>
  withJsonFormsContext(withContextToExpandPanelProps(Component));

export default withJsonFormsExpandPanelProps(ExpandPanelRenderer);
