import styled from 'styled-components';
import { 
  Chair as MaterialChair,
  Calendar as MaterialCalendar
} from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ChairAlt = styled(MaterialChair)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Calendar = styled(MaterialCalendar)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
