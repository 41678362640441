const Purchase = {
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'budget.name', label: 'Orçamento', minWidth: 25, align: 'center' },
    {
      id: 'supplier.fantasyName',
      label: 'Fornecedor',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'createdAt',
      label: 'Data de Compra',
      minWidth: 25,
      align: 'center',
    },
  ],
  pendingItemListTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    {
      id: 'product.name',
      label: 'Nome do Item',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'purchaseQuantity',
      label: 'Quantidade orçada',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'receivedQuantity',
      label: 'Quantidade recebida',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    create: 'PurchaseController@create',
    merge: 'PurchaseController@merge',
    getMergeable: 'PurchaseController@getAllAvailableToMerge',
    update: 'PurchaseController@patchUpdate',
    findById: 'PurchaseController@findById',
    getAllWithUnit: 'PurchaseController@getAllWithUnit',
    submitInvoice: 'PurchaseController@submitInvoice',
    getAllWithPendenciesUnregisteredItem:
      'PurchaseController@getAllWithPendenciesUnregisteredItem',
    getAllWithPendenciesItemPrice:
      'PurchaseController@getAllWithPendenciesItemPrice',
    checkout: 'PurchaseController@checkout',
  },
};

export default Purchase;
