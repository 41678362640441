import styled from 'styled-components';
import {
  Table as MaterialTable,
  Close as MaterialClose,
  Restore as MaterialRestore,
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Table = styled(MaterialTable)`
  width: 20px;
  height: 20px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
