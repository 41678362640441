import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { isEmpty } from 'lodash';
import DayJsUtils from '@date-io/dayjs';
import { useSecurity } from '../../../../extensions/hooks';
import SecuredItem from '../../../../components/securedItem';
import {
  Hidden,
  Select,
  FileCsv,
  FilePdf,
  AutoDiv,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectContainer,
  HeaderContainer,
  FooterContainer,
  ExportCsvButton,
  ExportPdfButton,
  ButtonContainer,
  CircularProgress,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import Unit from '../../../../forms/unit';
import Report from '../../../../forms/report';
import restApiClient from '../../../../services/restApiClient';
import ReportLabCostUnits from '../../../../pdf/reportLabCostUnits';
import ReportReworkHistory from '../../../../pdf/reportReworkHistory';
import Section from '../../../../forms/section';
import SubmitDialog from '../../../../components/submitDialog';
import ReportFinishedProducts from '../../../../pdf/reportFinishedProducts';
import ReportOsOutsourced from '../../../../pdf/reportOsOutsourced';

function LabReportList() {
  const [selectUnitVisible, setSelectUnitVisible] = useState(false);
  const [selectSectionVisible, setSelectSectionVisible] = useState(false);
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [units, setUnits] = useState(null);
  const [sections, setSections] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateTo, setMaxDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [unitError, setUnitError] = useState('');
  const [sectionError, setSectionError] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const [reportError, setReportError] = useState('');
  const [finalDateError, setFinalDateError] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [openSubmit, setOpenSubmit] = useState(false);

  const pdfSecurity = new Map();
  pdfSecurity.set('labCostUnits', [
    [
      Unit.actions.getAll,
      Section.actions.getAllBranches,
      Report.actions.getAllOSFinishedLabByPeriod,
    ],
  ]);
  pdfSecurity.set('reworkHistory', [[Report.actions.getReworkHistory]]);
  pdfSecurity.set('finishedProducts', [Report.actions.getFinishedProducts]);
  pdfSecurity.set('osOutsourced', [Report.actions.getAllOSOutsourcedByPeriod]);

  const csvSecurity = new Map();
  csvSecurity.set('finishedSteps', [
    [Unit.actions.getAll, Report.actions.getFinishedLabStepsCsvReport],
  ]);
  csvSecurity.set('stepFlow', [[Report.actions.getStepFlowCSVReport]]);

  const listReports = [
    useSecurity({ value: 'labCostUnits', label: 'Custo Parcial (Seção)' }, [
      [
        Unit.actions.getAll,
        Section.actions.getAllBranches,
        Report.actions.getAllOSFinishedLabByPeriod,
      ],
    ]),
    useSecurity({ value: 'finishedSteps', label: 'Etapas Realizadas' }, [
      [Unit.actions.getAll, Report.actions.getFinishedLabStepsCsvReport],
    ]),
    useSecurity({ value: 'reworkHistory', label: 'Histórico de Retrabalhos' }, [
      [Report.actions.getReworkHistory],
    ]),
    useSecurity({ value: 'stepFlow', label: 'Fluxo de Etapas' }, [
      [Report.actions.getStepFlowCSVReport],
    ]),
    useSecurity({ value: 'finishedProducts', label: 'Produtos Finalizados' }, [
      [Report.actions.getFinishedProducts],
    ]),
    useSecurity({ value: 'osOutsourced', label: 'Serviços Terceirizados' }, [
      [Report.actions.getAllOSOutsourcedByPeriod],
    ]),
    useSecurity({ value: 'production', label: 'Produção (Executor)' }, [
      [Report.actions.getCsvProduction],
    ]),
  ].filter((x) => !!x);

  useEffect(() => {
    if (['labCostUnits', 'finishedSteps'].includes(selectedReport)) {
      restApiClient.unit.getAll().then((e) => {
        setUnits(e.data);
      });
    }
  }, [selectedReport]);

  useEffect(() => {
    if (['labCostUnits'].includes(selectedReport)) {
      restApiClient.section.getAllBranches().then((e) => {
        setSections(e.data);
      });
    }
  }, [selectedReport]);

  async function loadDataCostUnits(unitId = null) {
    setFetching(true);
    restApiClient.orderServiceStep
      .getAllOSFinishedLabByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        const doc = ReportLabCostUnits.buildLabCostUnitsPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          units,
          sections,
          e.data
        );
        doc.save(`RELATORIO_CUSTO_PARCIAL.pdf`);
        setFetching(false);
      })
      .catch((e) => {
        if (e.response?.status === 406) {
          setDialogText(e.response?.data?.errors);
          setOpenSubmit(true);
          setFetching(false);
        }
      });
  }

  async function getFinishedLabStepsCsvReport(unitId = null) {
    setFetching(true);
    restApiClient.orderServiceStep
      .getFinishedLabStepsCsvReport(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        unitId
      )
      .then((e) => {
        setFetching(false);
      });
  }

  async function getCsvProduction() {
    setFetching(true);
    restApiClient.employee
      .getCsvProduction(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY')
      )
      .then((e) => {
        setFetching(false);
      });
  }

  async function getCsvStepFlow() {
    setFetching(true);
    restApiClient.orderServiceStep
      .getCsvStepFlow(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY')
      )
      .then((e) => {
        setFetching(false);
      });
  }

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios do Laboratório</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setSelectedUnit('');
                setDateFromError(false);
                setReportError('');
                switch (e.target.value) {
                  case 'labCostUnits':
                    setSelectUnitVisible(true);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    setSelectSectionVisible(false);
                    break;
                  case 'finishedSteps':
                    setSelectUnitVisible(true);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectSectionVisible(false);
                    break;
                  case 'reworkHistory':
                    setSelectUnitVisible(false);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    setSelectSectionVisible(false);
                    break;
                  case 'finishedProducts':
                    setSelectUnitVisible(false);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    setSelectSectionVisible(false);
                    break;
                  case 'osOutsourced':
                    setSelectUnitVisible(false);
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    setSelectSectionVisible(false);
                    break;
                  case 'production':
                    setSelectUnitVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectSectionVisible(false);
                    break;
                  case 'stepFlow':
                    setSelectUnitVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectSectionVisible(false);
                    break;
                  default:
                    setSelectUnitVisible(false);
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setSelectSectionVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
        <Hidden xsUp={!selectUnitVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(unitError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnit || ''}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                  setUnitError('');
                }}
              >
                {[
                  ['labCostUnits', 'finishedSteps'].includes(selectedReport) ? (
                    <MenuItem value={'all'} key={'all'}>
                      {'TODAS UNIDADES'}
                    </MenuItem>
                  ) : (
                    ''
                  ),
                ]
                  .concat(
                    units?.values.map((optionValue) =>
                      optionValue.type === 'distribution_center' &&
                      ['labCostUnits', 'finishedSteps'].includes(
                        selectedReport
                      ) ? (
                        ''
                      ) : (
                        <MenuItem value={optionValue.id} key={optionValue.id}>
                          {optionValue.name}
                        </MenuItem>
                      )
                    )
                  )
                  .concat([
                    <MenuItem value="" key={'empty'}>
                      <em>Nenhuma</em>
                    </MenuItem>,
                  ])}
              </Select>
              <FormHelperText>
                {!isEmpty(unitError) ? unitError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
        <Hidden xsUp={!selectSectionVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(sectionError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma seção
              </InputLabel>
              <Select
                value={selectedSection || ''}
                label="Selecione uma seção"
                onChange={(e) => {
                  setSelectedSection(e.target.value);
                  setSectionError('');
                }}
              >
                {[
                  <MenuItem value="" key={'empty'}>
                    <em>Nenhuma</em>
                  </MenuItem>,
                ].concat(
                  sections?.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>
                {!isEmpty(sectionError) ? sectionError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
      </AutoDiv>

      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setInitialDateError(value)}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateTo}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateTo
              ).format('DD/MM/YYYY')}.`}
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setFinalDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={csvSecurity.get(selectedReport)}>
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'finishedSteps':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit == 'all') {
                                await getFinishedLabStepsCsvReport();
                              } else if (selectedUnit) {
                                await getFinishedLabStepsCsvReport(
                                  selectedUnit
                                );
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'production':
                            await getCsvProduction();
                            break;
                          case 'stepFlow':
                            await getCsvStepFlow();
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={pdfSecurity.get(selectedReport)}>
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'labCostUnits':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              if (selectedUnit == 'all') {
                                await loadDataCostUnits();
                              } else if (selectedUnit) {
                                await loadDataCostUnits(selectedUnit);
                              } else {
                                setUnitError('Selecione uma opção válida.');
                              }
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'reworkHistory':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              restApiClient.orderService
                                .getReworkHistory(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  const doc =
                                    ReportReworkHistory.buildReworkHistoryPdf(
                                      moment(selectedDateFrom).format(
                                        'DD/MM/YYYY'
                                      ),
                                      moment(selectedDateTo).format(
                                        'DD/MM/YYYY'
                                      ),
                                      e.data
                                    );
                                  doc.save(
                                    `RELATORIO_HISTORICO_RETRABALHOS.pdf`
                                  );
                                  setFetching(false);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'finishedProducts':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              restApiClient.endProduct
                                .getFinishedProducts(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  const doc =
                                    ReportFinishedProducts.buildFinishedProductsPdf(
                                      moment(selectedDateFrom).format(
                                        'DD/MM/YYYY'
                                      ),
                                      moment(selectedDateTo).format(
                                        'DD/MM/YYYY'
                                      ),
                                      e.data
                                    );
                                  doc.save(
                                    `RELATORIO_PRODUTOS_FINALIZADOS.pdf`
                                  );
                                  setFetching(false);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'osOutsourced':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              restApiClient.outsourced
                                .getAllOSOutsourcedByPeriod(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  const doc =
                                    ReportOsOutsourced.buildOsOutsourcedPdf(
                                      moment(selectedDateFrom).format(
                                        'DD/MM/YYYY'
                                      ),
                                      moment(selectedDateTo).format(
                                        'DD/MM/YYYY'
                                      ),
                                      e.data
                                    );
                                  doc.save(
                                    `RELATORIO_SERVICOS_TERCEIRIZADOS.pdf`
                                  );
                                  setFetching(false);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>

      <SubmitDialog
        open={openSubmit}
        onCancel={() => setOpenSubmit(false)}
        onSuccess={() => {
          setOpenSubmit(false);
        }}
        errorMessage={true}
        dialogText={dialogText}
      />
    </Container>
  );
}

export default LabReportList;
