import React, { useEffect, useState } from 'react';
import {
  Appointments,
  DateNavigator,
  Scheduler,
  TodayButton,
  Toolbar,
  WeekView,
  DayView,
  AppointmentTooltip,
  Container,
  Paper,
  SchedulerContainer,
  ViewState,
  Resources,
  ViewSwitcher,
} from './styles';
import moment from 'moment';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

function RadiologyCenterScheduleViewCard() {
  const [rows, setRows] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    restApiClient.treatment.getAll().then((e) => {
      const instances = e.data.values.map((x) => {
        return { id: x.id, text: x.name, color: x.color };
      });
      const crrResources = [
        {
          fieldName: 'location',
          title: 'Location',
          instances: instances,
        },
      ];
      setResources(crrResources);
    });

    restApiClient.radiologySchedule
      .getAllExecutedRadiology(
        null,
        null,
        null,
        new FilterTree().eq('status', 'Agendado').toString()
      )
      .then((e) => {
        const newValues = e.data.values.map((x) => {
          const [hour, minutes, seconds] = x.treatment.executionTime.split(':');
          return {
            title: `${x.patient.name} - ${x.treatment.name}`,
            startDate: moment(x.scheduling),
            endDate: moment(x.scheduling)
              .add(hour, 'hours')
              .add(minutes, 'minutes')
              .add(seconds, 'seconds'),
            id: x.id,
            location: x.treatment.id,
          };
        });
        setRows(newValues);
      });
  }, []);

  return (
    <Container>
      <Paper>
        <SchedulerContainer>
          <Scheduler data={rows} locale="pt-br">
            <ViewState />
            <DayView displayName={'Dia'} startDayHour={8} endDayHour={18} />
            <WeekView
              displayName={'Semana'}
              startDayHour={8}
              endDayHour={18}
              excludedDays={[0, 7]}
            />
            <Toolbar />
            <DateNavigator />
            <TodayButton messages={{ today: 'Hoje' }} />
            <Appointments
              appointmentComponent={(appointmentProps) => (
                <Appointments.Appointment
                  style={{
                    height: '45px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '4px',
                    width: '100%',
                  }}
                  {...appointmentProps}
                />
              )}
            />
            <ViewSwitcher />
            <AppointmentTooltip />
            <Resources data={resources} />
          </Scheduler>
        </SchedulerContainer>
      </Paper>
    </Container>
  );
}

export default RadiologyCenterScheduleViewCard;
