import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useGlobal } from 'reactn';
import Expense from '../../../../forms/expense';
import EmployeeExpense from '../../../../forms/employeeExpense';
import Employee from '../../../../forms/employee';
import Address from '../../../../forms/address';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  HeaderContainer,
  Preview,
  Payments,
  StyledTypography,
  SelectContainer,
  UserPlus,
  AutoDiv,
  Delete,
  ReceiptAdd,
  FileDownload,
  PrintReceipt,
  ArrowUndoCircle,
} from './styles';
import DateRangePickersComponent from '../../../../components/dateRangePickersComponent';
import moment from 'moment';
import SelectPaydayDialog from '../../../../components/selectPaydayDialog';
import ExpenseReceipt from '../../../../components/expenseReceipt';
import { useReactToPrint } from 'react-to-print';

function ExpenseList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [selectedDateTo, setSelectedDateTo] = useGlobal('selectedDateTo');
  const [selectedDateFrom, setSelectedDateFrom] = useGlobal('selectedDateFrom');
  const [openPaydayDialog, setOpenPaydayDialog] = useState(false);
  const [expenseId, setExpenseId] = useState(false);
  const [toPay, setToPay] = useState(0);
  const [total, setTotal] = useState(0);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [expenseObj, setExpenseObj] = useState(null);
  const componentRefExpenseReceipt = useRef();

  const columns = Expense.tableSchema;

  const loadExpenses = useCallback(() => {
    restApiClient.expense
      .getAllByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('@query.status', searchBy)
              .or()
              .like('@units.name', searchBy)
              .or()
              .like('@users.name', searchBy)
              .or()
              .like('@patients.name', searchBy)
              .or()
              .like('@suppliers.social_name', searchBy)
              .or()
              .like('@suppliers.fantasy_name', searchBy)
              .or()
              .like('@service_suppliers.social_name', searchBy)
              .or()
              .like('@service_suppliers.fantasy_name', searchBy)
              .or()
              .like('@outsourceds.social_name', searchBy)
              .or()
              .like('@outsourceds.fantasy_name', searchBy)
              .or()
              .like('@expense_types.name', searchBy)
              .or()
              .like('@papa_expense_types.name', searchBy)
              .or()
              .like('@grandpa_expense_types.name', searchBy)
              .or()
              .like('@grandgrandpa_expense_types.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
        setToPay(parseFloat(e.data.totalToPay));
        setTotal(parseFloat(e.data.totalExpense));
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    loadExpenses();
  }, [loadExpenses]);

  const handlePrint = useReactToPrint({
    documentTitle: `RECIBO_DESPESA`,
    content: () => componentRefExpenseReceipt.current,
  });

  return (
    <Container>
      <CrudTable
        title={'Despesas'}
        customHeaderComponent={
          <HeaderContainer>
            <AutoDiv>
              <StyledTypography variant="h6">
                A pagar:{' '}
                {toPay?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </StyledTypography>
              <StyledTypography variant="h6">
                Total:{' '}
                {total?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </StyledTypography>
            </AutoDiv>
            <SelectContainer>
              <DateRangePickersComponent
                dateTo={selectedDateTo ?? new Date()}
                dateFrom={selectedDateFrom ?? new Date()}
                handleChange={(to, from) => {
                  setSelectedDateTo(to);
                  setSelectedDateFrom(from);
                }}
              />
            </SelectContainer>
          </HeaderContainer>
        }
        modelActions={Expense.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id, row) => row.expenseId === submitConfirmationId,
            content: (
              <span>
                Você tem certeza que deseja prosseguir com essa operação?
              </span>
            ),
            onSuccess: () => {
              restApiClient.expense.delete(expenseId).then(() => {
                loadExpenses();
                setSubmitConfirmationId(-1);
              });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          add: {
            onClick: () => {
              history.push({
                pathname: '/financial/expense/create',
              });
            },
            allowedActions: [
              Address.actions.getAll,
              Address.actions.findById,
              Employee.actions.getAll,
              Expense.actions.checkDuplicate,
            ],
          },
          update: {
            onClick: (expenseId, row) => {
              let expensableType;
              if (row?.expensableType) {
                let indexes = row?.expensableType.split('\\');
                expensableType =
                  indexes[2][0].toLowerCase() + indexes[2].substring(1);
              }
              history.push({
                pathname: `/financial/expense/update/${row?.expenseId}`,
                state: { expensableType },
              });
            },
            allowedActions: [
              [
                Expense.actions.update,
                Expense.actions.findById,
                Expense.actions.updateExpenseWithApportion,
                Expense.actions.agroupExpense,
              ],
            ],
            selector: (row) => row.status === 'Aberta',
          },
          setPayday: {
            icon: <Payments />,
            onClick: (expenseId, row) => {
              setOpenPaydayDialog(true);
              setExpenseId(row?.expenseId);
            },
            title: 'Pagar Despesa',
            selector: (row) => row.status === 'Aberta',
            allowedActions: [[Expense.actions.setPaid]],
          },
          setCompensation: {
            icon: <ArrowUndoCircle />,
            onClick: (expenseId, row) => {
              restApiClient.expense
                .setUndonePayment(row.expenseId)
                .then(() => loadExpenses());
            },
            allowedActions: [Expense.actions.setUndonePayment],
            title: 'Desfazer Pagamento',
            selector: (row) => row?.status === 'Paga',
          },
          view: {
            icon: <Preview />,
            onClick: (expenseId, row) => {
              if (row?.status === 'Paga' && row?.expenseId) {
                let expensableType;
                if (row?.expensableType) {
                  let indexes = row?.expensableType.split('\\');
                  expensableType =
                    indexes[2][0].toLowerCase() + indexes[2].substring(1);
                }
                history.push({
                  pathname: `/financial/expense/${row?.expenseId}`,
                  state: { readonly: true, expensableType },
                });
              } else {
                history.push({
                  pathname: `/financial/expenses/recurrent/view`,
                  state: { expense: row, readonly: true },
                });
              }
            },
            title: 'Visualizar Despesa',
            selector: (row) => row?.status !== 'Aberta',
          },
          employeeExpense: {
            icon: <UserPlus />,
            onClick: (expenseId, row) => {
              history.push({
                pathname: `/financial/expenses/employee-expense/${row?.expenseId}/unit/${row?.unitId}`,
                state: { expenseValue: row.value },
              });
            },
            title: 'Distribuição da Despesa',
            allowedActions: [
              [
                EmployeeExpense.actions.getAllByExpense,
                Expense.actions.update,
                Employee.actions.getAllByUnit,
              ],
            ],
            selector: (row) =>
              row?.status !== 'Previsão' && row?.status !== 'Pag Desfeito',
          },
          throwExpense: {
            icon: <ReceiptAdd />,
            onClick: (expenseId, row) => {
              history.push({
                pathname: `/financial/expenses/recurrent/create`,
                state: {
                  expense: row,
                },
              });
            },
            title: 'Lançar Despesa',
            selector: (row) => row?.status === 'Previsão',
          },
          receiptCreate: {
            icon: <FileDownload />,
            onClick: async (expenseId, row) => {
              await setExpenseObj(row);
              handlePrint();
            },
            title: 'Gerar Recibo',
            selector: (row) => row?.status !== 'Pag Desfeito',
          },
          customDelete: {
            icon: <Delete />,
            onClick: (id, row) => {
              setExpenseId(row.expenseId);
              setSubmitConfirmationId(row.expenseId);
            },
            title: 'Excluir Despesa',
            selector: (row) => row.status === 'Aberta',
            allowedActions: [[Expense.actions.delete]],
          },
        }}
        customCellRenderers={{
          dueDate: {
            renderer: (index, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          name: {
            renderer: (field, value, column, row) => {
              if (row?.employeeId) {
                return row?.employee?.user?.name;
              } else if (row?.patientId) {
                return row?.patient?.name;
              } else if (row?.dentistId) {
                return row?.dentist?.user?.name;
              } else if (row?.expensable) {
                return row?.expensable?.isPhysicalPerson
                  ? row?.expensable?.socialName
                  : row?.expensable?.fantasyName;
              } else {
                return '-';
              }
            },
          },
          expenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value.name;
              }
            },
          },
          installmentNumber: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (
            moment(row.dueDate).isBefore(moment(), 'day') &&
            row.status === 'Aberta'
          ) {
            return '#ffe0e0';
          }
        }}
      />

      <SelectPaydayDialog
        open={openPaydayDialog}
        expenseId={expenseId}
        onCancel={() => setOpenPaydayDialog(false)}
        onSuccess={() => {
          setOpenPaydayDialog(false);
          loadExpenses();
        }}
      />

      <PrintReceipt ref={componentRefExpenseReceipt}>
        <ExpenseReceipt expense={expenseObj} />
      </PrintReceipt>
    </Container>
  );
}

export default ExpenseList;
