import styled from 'styled-components';

import {
  AddBox as MaterialAddBox,
  DateAdd as MaterialDateAdd,
} from '../../../styles/icons';

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const DateAdd = styled(MaterialDateAdd)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;
