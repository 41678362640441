import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  Payments as MaterialPayments,
  UserPlus as MaterialUserPlus,
  Delete as MaterialDelete,
  ReceiptAdd as MaterialReceiptAdd,
  FileDownload as MaterialFileDownload,
  ArrowUndoCircle as MaterialArrowUndoCircle,
} from '../../../../../styles/icons';
import {
  Button as MaterialButton,
  Typography as MaterialTypography,
} from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const AutoDiv = styled.div`
  width: auto;
  margin-right: auto;
  margin-left: 15px;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ArrowUndoCircle = styled(MaterialArrowUndoCircle)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const FileDownload = styled(MaterialFileDownload)`
  width: 20px;
  height: 20px;
  color: var(--secondary);
`;

export const Payments = styled(MaterialPayments)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const UserPlus = styled(MaterialUserPlus)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const StyledTypography = styled(MaterialTypography)`
  line-height: 1.25 !important;
`;

export const SelectContainer = styled.div`
  width: 400px;
  margin-right: auto;
`;

export const Delete = styled(MaterialDelete)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ReceiptAdd = styled(MaterialReceiptAdd)`
  width: 26px;
  height: 26px;
  color: var(--secondary);
`;

export const PrintReceipt = styled.div`
  @media screen {
    display: none;
  }
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
