const Chair = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
    },
    required: ['name'],
  },
  uischema: {
    type: 'Group',
    label: 'Cadastro de Cadeira',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
    ],
  },
  tableSchema: [{ id: 'name', label: 'Nome', minWidth: 100, align: 'center' }],
  chairDentistTableSchema: [
    { id: 'dentist.user.name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'dentist.dentistScheduleTypes',
      label: 'Tipo Agenda',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    findById: 'ChairController@findById',
    create: 'ChairController@create',
    getAll: 'ChairController@getAll',
    getAllByUnit: 'ChairController@getAllByUnit',
    update: 'ChairController@update',
    delete: 'ChairController@delete',
  },

  chairDentistActions: {
    create: 'ChairDentistController@create',
    getAllByChair: 'ChairDentistController@getAllByChair',
    delete: 'ChairDentistController@delete',
  },
};

export default Chair;
