/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { isEmpty } from 'lodash';
import {
  Container,
  ActionsContainer,
  SubmitButton,
  Input,
  ContentContainer,
  CancelButton,
  DialogContent,
  FormHelperText,
  CardHeader, 
  HeaderContainer,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
} from './styles';
import restApiClient from '../../services/restApiClient';

function AdjustStockDialog({
  onSuccess,
  onError,
  onCancel,
  item,
  unitId,
  ...props
}) {
  const [quantity, setQuantity] = useState('');
  const [type, setType] = useState('');
  const [errors, setErrors] = useState([]);
  const typesStockHistory = [
    { value: 'input', label: 'Entrada' },
    { value: 'output', label: 'Saída' },
    { value: 'loss', label: 'Perda' },
    { value: 'warranty', label: 'Garantia' },
    { value: 'return', label: 'Devolução da Unidade' },
    { value: 'supplier_change', label: 'Troca Fornecedor'}
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    restApiClient.stockHistory
      .create({
        unitId,
        itemId: item.id,
        quantity: parseInt(quantity),
        type,
      })
      .then(() => {
        onSuccess?.();
        setQuantity('');
        setType('');
        setErrors([]);
      })
      .catch((x) => {
        if (x.response?.status === 400) {
          onError?.(x.response);
          setErrors(x.response.data.errors);
        }
      });
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Ajustar Quantidade" />
              </HeaderContainer>
              <ContentContainer>
                <FormControl required size="small" error={!isEmpty(errors?.type)}>
                  <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                    Tipo de Ajuste
                  </InputLabel>
                  <Select
                    value={type || ''}
                    label="Tipo de Ajuste"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    {[
                      <MenuItem value="" key={'empty'}>
                        <em>Nenhuma</em>
                      </MenuItem>,
                    ].concat(
                      typesStockHistory?.map((optionValue) => (
                        <MenuItem
                          value={optionValue.value}
                          key={optionValue.value}
                        >
                          {optionValue.label}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  <FormHelperText>
                    {!isEmpty(errors?.type) ? errors?.type.join(' ') : ''}
                  </FormHelperText>
                </FormControl>
                <Input
                  required
                  type="number"
                  label="Quantidade"
                  value={quantity}
                  InputProps={{ inputProps: { min: 1 } }}
                  onChange={(e) => {
                    setErrors([]);
                    setQuantity(e.target.value);
                  }}
                  error={!isEmpty(errors?.quantity)}
                  helperText={
                    !isEmpty(errors?.quantity) ? errors?.quantity.join(' ') : ''
                  }
                />
              </ContentContainer>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setErrors([]);
                    setType('');
                    setQuantity('');
                    onCancel?.();
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Ajustar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </Container>
  );
}

export default AdjustStockDialog;
