import React, { useState, useEffect, useCallback } from 'react';
import Chair from '../../../../../forms/chair';
import Dentist from '../../../../../forms/dentist';
import Procedure from '../../../../../forms/procedure';
import DentistOfficeHour from '../../../../../forms/dentistOfficeHour';
import DentistHourValue from '../../../../../forms/dentistHourValue';
import DentistFixedValue from '../../../../../forms/dentistFixedValue';
import DentistPatientValue from '../../../../../forms/dentistPatientValue';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import DentistFixedValueDialog from '../../../../../components/dentistFixedValueDialog';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  CancelButton,
  Close,
  CalendarAdd,
  Procedures,
  Hourglass,
  PriceChange,
  PatientIcon,
} from './styles';
import AddDentistToChairDialog from '../../../../../components/addDentistToChairDialog';
import DentistPatientValueDialog from '../../../../../components/dentistPatientValueDialog';
import isEmpty from 'lodash/isEmpty';

function LinkedDentistsChairList({ history, match, ...props }) {
  const chairId = parseInt(match?.params?.id ?? -1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openDentistHour, setOpenDentistHour] = useState(false);
  const [openDentistFixed, setOpenDentistFixed] = useState(false);
  const [openDentistPatient, setOpenDentistPatient] = useState(false);
  const [dentists, setDentists] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [selectedDentistId, setSelectedDentistId] = useState(null);
  const [selectedDentistFixedId, setSelectedDentistFixedId] = useState(null);

  const columns = Chair.chairDentistTableSchema;

  const loadChairDentists = useCallback(() => {
    restApiClient.chairDentist
      .getAllByChair(
        chairId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('dentist.user.name', searchBy).toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
        setDentists(e.data.values);
      });
  }, [rowsPerPage, page, orderBy, searchBy, chairId]);

  useEffect(() => {
    loadChairDentists();
  }, [loadChairDentists]);

  return (
    <Container>
      <CrudTable
        title={'Dentistas Vinculados'}
        modelActions={Chair.chairDentistActions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => setOpen(true),
          },
          schedule: {
            icon: <CalendarAdd />,
            onClick: (id) =>
              history.push(
                `/unit/chair/${chairId}/linked-dentist/${id}/schedule`
              ),
            allowedActions: [[DentistOfficeHour.actions.getAllByChairDentist]],
            title: 'Agenda',
          },
          valueProcedures: {
            icon: <Procedures />,
            onClick: (id, row) => {
              history.push({
                pathname: `/unit/${row?.chair?.unitId}/chair/${chairId}/linked-dentist/${id}/procedures`,
                state: {
                  dentist: row?.dentist?.user,
                },
              });
            },
            allowedActions: [
              [
                Procedure.actions.getAllBySpecialtyDentist,
                Dentist.dentistProcedurePriceActions.getAllByDentistAndUnit,
              ],
            ],
            title: 'Valor Procedimentos',
            selector: (row) =>
              !isEmpty(
                row?.dentist?.dentistScheduleTypes.filter(
                  (x) => x.type === 'Procedimentos'
                )
              ),
          },
          valueHour: {
            icon: <Hourglass />,
            onClick: (id, row) => {
              history.push({
                pathname: `/unit/${row?.chair?.unitId}/chair/${chairId}/linked-dentist/${id}/specialties`,
                state: {
                  dentist: row?.dentist?.user,
                },
              });
            },
            allowedActions: [
              [
                DentistHourValue.actions.updateDentistHourValues,
                DentistHourValue.actions.getAllByDentistAndUnit,
                DentistHourValue.actions.findById,
              ],
            ],
            title: 'Valor Hora',
            selector: (row) =>
              !isEmpty(
                row?.dentist?.dentistScheduleTypes.filter(
                  (x) => x.type === 'Hora'
                )
              ),
          },
          valuePatient: {
            icon: <PatientIcon />,
            onClick: (id, row) => {
              setSelectedUnitId(row.chair.unitId);
              setSelectedDentistId(row.dentistId);
              setOpenDentistPatient(true);
            },
            allowedActions: [
              [
                DentistPatientValue.actions.create,
                DentistPatientValue.actions.patchUpdate,
                DentistPatientValue.actions.getAllByDentistAndUnit,
                DentistPatientValue.actions.findById,
              ],
            ],
            title: 'Valor Paciente',
            selector: (row) =>
              !isEmpty(
                row?.dentist?.dentistScheduleTypes.filter(
                  (x) => x.type === 'Pacientes'
                )
              ),
          },
          valueFixed: {
            icon: <PriceChange />,
            onClick: (id, row) => {
              setSelectedDentistFixedId(row.dentistId);
              setOpenDentistFixed(true);
            },
            allowedActions: [
              [
                DentistFixedValue.actions.create,
                DentistFixedValue.actions.patchUpdate,
                DentistFixedValue.actions.getAllByDentist,
                DentistFixedValue.actions.findById,
              ],
            ],
            title: 'Valor Fixo',
            selector: (row) =>
              !isEmpty(
                row?.dentist?.dentistScheduleTypes.filter(
                  (x) => x.type === 'Fixo'
                )
              ),
          },
          removeDentist: {
            icon: <Close />,
            onClick: (id) => {
              restApiClient.chairDentist.delete(id).then(() => {
                loadChairDentists();
              });
            },
            allowedActions: [Chair.chairDentistActions.delete],
            title: 'Desvincular Dentista',
          },
        }}
        customCellRenderers={{
          'dentist.dentistScheduleTypes': {
            renderer: (field, value) => {
              const scheduleTypes = value.map((item) => item.type);
              if (!isEmpty(scheduleTypes)) {
                return scheduleTypes.join(' / ');
              } else {
                return '-';
              }
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <AddDentistToChairDialog
        open={open}
        chairId={chairId}
        dentists={dentists}
        onCancel={() => {
          setOpen(false);
        }}
        onSuccess={() => {
          loadChairDentists();
        }}
      />

      <DentistFixedValueDialog
        open={openDentistFixed}
        dentistFixedValue={DentistFixedValue}
        dentistFixedId={selectedDentistFixedId}
        onCancel={() => {
          setOpenDentistFixed(false);
          setSelectedDentistFixedId(null);
        }}
        onSuccess={(result) => {
          setOpenDentistFixed(false);
          setSelectedDentistFixedId(null);
        }}
        {...props}
      />

      <DentistPatientValueDialog
        open={openDentistPatient}
        dentistPatientValue={DentistPatientValue}
        unitId={selectedUnitId}
        dentistId={selectedDentistId}
        onCancel={() => {
          setOpenDentistPatient(false);
          setSelectedUnitId(null);
          setSelectedDentistId(null);
        }}
        onSuccess={() => {
          setOpenDentistPatient(false);
          setSelectedUnitId(null);
          setSelectedDentistId(null);
        }}
        {...props}
      />
    </Container>
  );
}

export default LinkedDentistsChairList;
