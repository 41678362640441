import styled from 'styled-components';
import MaterialSelect from '@material-ui/core/Select';
import MaterialButton from '@material-ui/core/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import MaterialDialogActions from '@material-ui/core/DialogActions';
import MaterialDialogContent from '@material-ui/core/DialogContent';
import {
  LocalHospital as MaterialLocalHospital,
  AddBox as MaterialAddBox,
} from '../../styles/icons';
import CrudTable from '../crudTable';

export const Select = styled(MaterialSelect)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Button = styled(MaterialButton)``;

export const Dialog = styled(MaterialDialog)``;

export const DialogActions = styled(MaterialDialogActions)``;

export const DialogContent = styled(MaterialDialogContent)`
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AddBox = styled(MaterialAddBox)`
  width: 20px;
  height: 20px;
  fill: #808080;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-top: 10px;
  margin-bottom: 5px;
  width: 90%;
`;

export const LocalHospital = styled(MaterialLocalHospital)`
  width: 25px;
  height: 25px;
  fill: #808080;
`;
