import React, { useState, useEffect, useCallback, useRef } from 'react';
import OrderService from '../../../../../../forms/orderService';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import {
  Container,
  Send,
  AddToQueue,
  ExclamationTriangleFill,
  PrintOS,
  Check,
  Preview,
} from './styles';
import { useSelector } from 'react-redux';
import PatientReturnDialog from '../../../../../../components/patientReturnDialog';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ViewUrgencyDialog from '../../../../../../components/viewUrgencyDialog';
import VoucherOS from '../../../../../../components/voucherOS';
import { useReactToPrint } from 'react-to-print';
import OrderServiceStep from '../../../../../../forms/orderServiceStep';

function ClinicOrderServicePendingList({ history, props }) {
  const selectedUnit = useSelector((x) => x.authReducer.user?.unitId ?? -1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [beginDate, setBeginDate] = useState(null);
  const [orderService, setOrderService] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [openRejection, setOpenRejection] = useState(false);
  const [openRejectionAdjustment, setOpenRejectionAdjustment] = useState(false);
  const [justification, setJustification] = useState(null);
  const [orderServiceStepId, setOrderServiceStepId] = useState(null);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const columns = OrderService.clinicOSPendingTableSchema;
  const componentRef = useRef();

  const loadServiceOrders = useCallback(() => {
    restApiClient.orderServiceStep
      .getAllOrderServiceStepPendingByUnitId(
        selectedUnit,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('orderService.id', searchBy)
              .or()
              .like('orderService.patient.name', searchBy)
              .or()
              .like('orderService.patient.record_number', searchBy)
              .or()
              .like('dentist.user.name', searchBy)
              .or()
              .like('orderService.endProduct.name', searchBy)
              .or()
              .like('step.name', searchBy)
              .or()
              .like('status', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
        setBeginDate(new Date(e.data.values[0]?.beginDate + ' 00:00:00'));
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedUnit]);

  useEffect(() => {
    loadServiceOrders();
  }, [loadServiceOrders]);

  useEffect(() => {
    if (history?.location?.state?.updateSuccess) {
      setOpenMessage(true);
    }
  }, []);

  const handleAlertClose = (event, reason) => {
    setOpenMessage(false);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Ordem de Serviço`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }`,
  });

  return (
    <Container>
      <CrudTable
        title={'Ordens de Serviço Pendentes'}
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: (
              <span>
                Deseja confirmar o recebimento dessa ordem de serviço?
              </span>
            ),
            onSuccess: () => {
              restApiClient.orderServiceStep
                .setReceived(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                  setSubmitConfirmationId(-1);
                });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          delete: {
            onClick: (orderServiceStepId) => {
              restApiClient.orderServiceStep
                .delete(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                });
            },
            allowedActions: [OrderServiceStep.actions.delete],
            selector: (row) => {
              return (
                (row?.status === 'Submetida (Clínica)' ||
                  row?.status === 'Submetida em Grupo') &&
                row?.current
              );
            },
          },
          view: {
            icon: <Preview />,
            onClick: (orderServiceId, row) => {
              history.push({
                pathname: `/lab/orderService/update/${
                  row.orderService.endProductId
                }/${row.id}/${true}/${row.workflowPosition}/${
                  row.orderServiceId
                }`,
                state: {
                  dentistId: row.dentistId,
                  date: row.createdAt,
                  allowView: true,
                  patientName: row.orderService?.patient?.name,
                  recordNumber: row.orderService?.patient?.recordNumber,
                },
              });
            },
            allowedActions: [
              OrderServiceStep.actions.getAllOrderServiceStepPendingByUnitId,
            ],
            title: 'Visualizar OS',
            selector: (row) =>
              row?.status === 'Ajuste (Dentista)' ||
              row?.status === 'Em Trânsito (Clínica)',
          },
          viewRejection: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceId, row) => {
              setOrderServiceStepId(row.id);
              setOrderService(row);
              setOpenRejection(true);
              setJustification(row?.rejectedJustification);
              setPatientId(row.orderService.patientId);
            },
            allowedActions: [
              [
                OrderServiceStep.actions.create,
                OrderServiceStep.actions.fixOSByClinic,
              ],
            ],
            title: 'Visualizar Justificativa da Recusa',
            selector: (row) => row?.status === 'Reagendamento (Clínica)',
          },
          viewRejectionAdjustment: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceId, row) => {
              setOpenRejectionAdjustment(true);
              setJustification(row?.rejectedJustification);
            },
            allowedActions: [
              OrderServiceStep.actions.getAllOrderServiceStepPendingByUnitId,
            ],
            title: 'Visualizar Justificativa da Recusa',
            selector: (row) => row?.status === 'Ajuste (Dentista)',
          },
          addOrderServiceStep: {
            icon: <AddToQueue />,
            onClick: (orderServiceId, row) => {
              history.push({
                pathname: `/clinic/lab/orderService/update/${row.orderService.id}/${row.workflowPosition}`,
                state: {
                  dentistId: row.dentistId,
                  date: row.createdAt,
                },
              });
            },
            title: 'Editar Ordem de Serviço',
            selector: (row) =>
              row?.status === 'Submetida (Clínica)' ||
              row?.status === 'Submetida em Grupo' ||
              row?.status === 'Retrabalho (Clínica)',
          },
          submitted: {
            icon: <Send />,
            onClick: (orderServiceId, row) => {
              setPatientId(row.orderService.patientId);
              setOrderService(row);
              setOrderServiceStepId(row.id);
              setOpen(true);
            },
            title: 'Enviar ao laboratório',
            allowedActions: [
              OrderServiceStep.actions.getAllOrderServiceStepPendingByUnitId,
            ],
            selector: (row) =>
              row?.status === 'Submetida (Clínica)' ||
              row?.status === 'Submetida em Grupo' ||
              row?.status === 'Retrabalho (Clínica)',
          },
          receiveOrderService: {
            icon: <Check />,
            onClick: (id, row) => {
              setOrderServiceStepId(row.id);
              setSubmitConfirmationId(id);
            },
            title: 'Receber Ordem de Serviço',
            allowedActions: [OrderServiceStep.actions.setReceived],
            selector: (row) => row?.status === 'Em Trânsito (Clínica)',
          },
        }}
      />

      <PatientReturnDialog
        open={open}
        beginDate={beginDate}
        unitId={selectedUnit}
        orderServiceStepId={orderServiceStepId}
        patientId={patientId}
        onCancel={() => setOpen(false)}
        onSuccess={(schedule) => {
          setOrderService({
            ...orderService.orderService,
            orderServiceStep: {
              ...orderService,
              schedule: schedule,
            },
          });
          setOpen(false);
          loadServiceOrders();
          handlePrint();
        }}
      />

      <Snackbar
        open={openMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {'OS Salva com Sucesso'}
        </Alert>
      </Snackbar>

      <ViewUrgencyDialog
        isClinic
        open={openRejection}
        title={'Recusa - Justificativa'}
        justification={justification}
        orderServiceStepId={orderServiceStepId}
        onCancel={() => {
          setOpenRejection(false);
        }}
        onSuccess={(orderServiceStep) => {
          setOpenRejection(false);
          const modelOrderServiceStep = {
            orderServiceId: orderServiceStep.orderServiceId,
            dentistId: orderServiceStep.dentistId,
            stepId: orderServiceStep.stepId,
            unitId: orderServiceStep.unitId,
            opposite: orderServiceStep.opposite
              ? orderServiceStep.opposite
              : false,
            waxBite: orderServiceStep.waxBite
              ? orderServiceStep.waxBite
              : false,
            toothNumber: orderServiceStep.toothNumber,
            dentalArch: orderServiceStep.dentalArch,
            toothShadeId: orderServiceStep.toothShadeId,
            observation: orderServiceStep.observation,
            status: 'Submetida (Clínica)',
            current: orderServiceStep.current,
          };
          restApiClient.orderServiceStep
            .create(modelOrderServiceStep)
            .then((e) => {
              setOrderServiceStepId(e.data.id);
              loadServiceOrders();
            });
          setOpen(true);
        }}
      />

      <ViewUrgencyDialog
        open={openRejectionAdjustment}
        title={'Recusa - Justificativa'}
        justification={justification}
        onCancel={() => {
          setOpenRejectionAdjustment(false);
        }}
      />

      <PrintOS ref={componentRef}>
        <VoucherOS isClinic orderService={orderService} />
      </PrintOS>
    </Container>
  );
}

export default ClinicOrderServicePendingList;
