import React, { useState } from 'react';
import {
  InnerList,
  ListItem,
  ExpandLess,
  ExpandMore,
  StyledCollapse,
} from './styles';

function CollapseList({ children, itemContent }) {
  const [collapsed, steCollapsed] = useState(false);
  return (
    <>
      <ListItem button onClick={() => steCollapsed(!collapsed)}>
        {itemContent}
        {collapsed ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <StyledCollapse in={collapsed} timeout="auto" unmountOnExit>
        <InnerList>{children}</InnerList>
      </StyledCollapse>
    </>
  );
}

export default CollapseList;
