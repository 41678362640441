import React, { useEffect } from 'react';
import restApiClient from '../../services/restApiClient';
import AdornedTextField from '../adornedTextField';
import {
  FormControl,
  FormHelperText,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputLabel,
  NativeSelect,
  Container,
  Tooth,
} from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';

const SelectToothShadeDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  config,
  errors,
  visible,
  noValidation,
  onSuccess,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState({});
  const [selectedShade, setSelectedShade] = React.useState({});
  const [allType, setAllType] = React.useState([]);
  const [shadesById, setShadesById] = React.useState([]);
  const [typeError, setTypeError] = React.useState([]);
  const [shadeError, setShadeError] = React.useState([]);
  const [resultString, setResultString] = React.useState([]);


  useEffect(() => {
    if (typeof data === 'undefined') {
      setSelectedType({});
      setSelectedShade({});
      setResultString([]);
      setTypeError({});
      setShadeError({});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      restApiClient.toothShade.findById(data).then((e) => {
        const response = e.data;
        setSelectedType(response.toothType);
        setSelectedShade(response);
        setResultString(
          `${response.toothType.toothType} > ${response.name}`
        );
      });
    }
  }, [data]);

  useEffect(() => {
    restApiClient.toothType.getAll().then((e) => {
      setAllType(e.data.values);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedType)) {
      restApiClient.toothShade.getAllByToothTypeId(selectedType.id).then((e) => {
        setShadesById(e.data.values);
      });
    }
  }, [selectedType]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<Tooth />}
          clickableAdornment
          onAdornmentClick={() => {
            if (enabled) {
              setOpen(true);
            }
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={() => {
            if (enabled) {
              setOpen(true);
            }
          }}
          label="Tipo > Tonalidade"
          InputLabelProps={{
            shrink: true,
            readOnly: true,
          }}
          value={resultString ?? ''}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>Seleção de Tonalidade do Dente</DialogTitle>
          <DialogContent>
            <FormControl error={!isEmpty(typeError)}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Tipo
              </InputLabel>
              <NativeSelect
                value={selectedType?.id}
                onChange={(e) => {
                  setSelectedType(
                    allType.find(
                      (toothType) => toothType.id === parseInt(e.target.value)
                    ) ?? {}
                  );
                  if (!isEmpty(typeError)) {
                    setTypeError({});
                  }
                  if (!isEmpty(shadeError)) {
                    setShadeError({});
                  }
                  if (!isEmpty(selectedShade)) {
                    setSelectedShade({});
                  }
                }}
              >
                <option aria-label="None" value="">
                  Selecione
                </option>
                {allType.map((toothType) => (
                  <option key={`toothType_${toothType.id}`} value={toothType.id}>
                    {toothType.toothType}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>
                {!isEmpty(typeError) ? typeError : ' '}
              </FormHelperText>
            </FormControl>
            {!isEmpty(selectedType) && !isEmpty(shadesById) ? (
              <FormControl error={!isEmpty(shadeError)}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Tonalidade
                </InputLabel>
                <NativeSelect
                  value={selectedShade?.id}
                  onChange={(e) => {
                    setSelectedShade(
                      shadesById.find(
                        (toothShade) => toothShade.id === parseInt(e.target.value) ?? {}
                      )
                    );
                    if (!isEmpty(typeError)) {
                      setTypeError({});
                    }
                    if (!isEmpty(shadeError)) {
                      setShadeError({});
                    }
                  }}
                >
                  <option aria-label="None" value="">
                    Selecione
                  </option>
                  {shadesById?.map((toothShade) => (
                    <option key={`categoy_${toothShade.id}`} value={toothShade.id}>
                      {toothShade.name}
                    </option>
                  ))}
                </NativeSelect>
                <FormHelperText>
                  {!isEmpty(shadeError) ? shadeError : ' '}
                </FormHelperText>
              </FormControl>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedType({});
                setSelectedShade({});
                setResultString([]);
                setTypeError({});
                setShadeError({});
                handleChange(path, undefined);
                setOpen(false);
              }}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                if (isEmpty(selectedType)) {
                  setTypeError('Campo Obrigatório');
                } else {
                  if (!isEmpty(selectedShade)) {
                    setResultString(
                      `${selectedType.toothType} > ${selectedShade.name}`
                    );
                    handleChange(path, selectedShade.id);
                    setOpen(false);
                  } else {
                    setShadeError('Campo Obrigatório');
                  }
                }
              }}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Hidden>
  );
};

export default SelectToothShadeDialogControl;
