import styled from 'styled-components';
import { AssignmentTurnedIn as MaterialAssignmentTurnedIn } from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const AssignmentTurnedIn = styled(MaterialAssignmentTurnedIn)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;