/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Item from '../../forms/item';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
  SubmitButton,
  AddBox,
} from './styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const SeparateSaleItemsDialog = ({
  onSuccess,
  onError,
  onCancel,
  sale,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [orderBy, setOrderBy] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = Item.separateSaleTableSchema;
  const unitId = useSelector((x) => x.authReducer.user.unitId);
  const [listItens, setListItens] = useState([]);
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const handleAlertClose = () => setAlert({ message: '', severity: '' });

  useEffect(() => {
    setListItens(sale?.saleItems);
  }, [sale]);

  useEffect(() => {
    restApiClient.item
      .getAllWithStock(
        unitId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('name', searchBy)
              .or()
              .like('brand', searchBy)
              .or()
              .like('barcode.barcode', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogContent>
        <StyledCrudTable
          title={'Itens'}
          modelActions={Item.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[5]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderBy(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderBy(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            selectItemForSale: {
              icon: <AddBox />,
              onClick: (id, row) => {
                if (row.stock && row?.stock?.availableQuantity > 0) {
                  restApiClient.saleItem
                    .create({ saleId: sale.id, itemId: id })
                    .then((r) => {
                      setListItens([...listItens, r.data]);
                      onSuccess(r.data);
                    });
                } else {
                  setAlert({
                    message: `Item não existe em estoque.`,
                    severity: 'error',
                  });
                }
              },
              allowedActions: [Item.actions.getAllWithStock],
              selector: (row) =>
                !listItens?.some(function (obj) {
                  return obj.itemId === row.id;
                }),
            },
          }}
          customCellRenderers={{
            stock: {
              renderer: (field, value) => {
                return value?.availableQuantity
                  ? value?.availableQuantity
                  : '-';
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <SubmitButton
          onClick={() => {
            onCancel?.();
            setPage(0);
            setSearchBy('');
          }}
          type="button"
          variant="contained"
          color="primary"
        >
          Fechar
        </SubmitButton>
      </DialogActions>
      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default SeparateSaleItemsDialog;
