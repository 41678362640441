import React, { useState, useCallback, useEffect } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  NotesMedical,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  SelectContainer,
} from './styles';
import DentistWorkedHour from '../../../../../forms/dentistWorkedHour';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import DayJsUtils from '@date-io/dayjs';

function AllProceduresSchedulesList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [dates, setDates] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const columns = DentistWorkedHour.allSchedulesTableSchema;

  const loadDentistWorkedHour = useCallback(() => {
    restApiClient.dentistWorkedHour
      .getAllByDate(
        selectedDate,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('dentist.user.name', searchBy)
              .or()
              .like('unit.name', searchBy)
              .or()
              .like('specialty.name', searchBy)
              .or()
              .like('status', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        if (!selectedDate) {
          const listDates = e.data.values.map((attendance) => {
            let date = new Date(attendance.attendanceDate + ' 00:00:00');
            return date.getTime();
          });
          setDates(listDates);
        }
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedDate]);

  useEffect(() => {
    loadDentistWorkedHour();
  }, [loadDentistWorkedHour]);

  function disableSundays(dayJsDate) {
    const date = dayJsDate.toDate();
    return date.getDay() === 0 || !dates.includes(date.getTime());
  }

  return (
    <Container>
      <CrudTable
        title={'Todas Agendas'}
        customHeaderComponent={
          <SelectContainer>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-from"
                inputVariant="outlined"
                size="small"
                label="Data Realização"
                format="DD/MM/YYYY"
                autoOk
                initialFocusedDate={selectedDate}
                value={selectedDate}
                inputValue={null}
                onChange={(date) =>
                  setSelectedDate(
                    date ? moment(date.toDate()).format('YYYY-MM-DD') : null
                  )
                }
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={() => setOpenCalendar(true)}
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
                KeyboardButtonProps={{
                  'aria-label': 'Editar data',
                }}
                clearable
                shouldDisableDate={disableSundays}
                disableFuture
                cancelLabel="Cancelar"
                okLabel="Selecionar"
                clearLabel="Limpar"
              />
            </MuiPickersUtilsProvider>
          </SelectContainer>
        }
        modelActions={DentistWorkedHour.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewProcedures: {
            onClick: (id) =>
              history.push(
                `/dentist/audit/all-schedules/schedule/${id}/attendances`
              ),
            icon: <NotesMedical />,
            title: 'Visualizar Procedimentos',
            allowedActions: [
              DentistWorkedHour.dentistWorkedHourAttendanceActions
                .getAllByWorkedHour,
            ],
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
          'substituted.user.name': {
            renderer: (field, value) => {
              return value ? value : '-';
            },
          },
          schedule: {
            renderer: (field, value, column, row) => {
              return row.beginTime && row.endTime
                ? `${row.beginTime} às ${row.endTime}`
                : '-';
            },
          },
          workedHourAttendances: {
            renderer: (field, value) => {
              if (!isEmpty(value)) {
                const evaluatedProcedures = value.filter(
                  (x) =>
                    x.attendance.status === 'Aprovado' ||
                    x.attendance.status === 'Reprovado'
                ).length;

                return `${evaluatedProcedures}/${value.length}`;
              } else {
                return '-';
              }
            },
          },
          'specialty.name': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default AllProceduresSchedulesList;
