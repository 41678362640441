import styled from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';
import { FilePdf as MaterialFilePdf } from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const FilePdf = styled(MaterialFilePdf)`
  width: 25px;
  height: 25px;
`;

export const ExportPdfButton = styled(MaterialButton)`
  color: #ffffff !important;
  background: #de261d !important;
  width: 205px;
`;
