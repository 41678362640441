import { and, isNumberControl, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import FloatInputControl from '../../components/floatInput';
import isEmpty from 'lodash/isEmpty';

const isFloatInputControl = and(
  isNumberControl,
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'float'
  )
);

const materialTextControlTester = rankWith(5, isFloatInputControl);

export default {
  tester: materialTextControlTester,
  renderer: withJsonFormsControlProps(FloatInputControl),
};
