import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectPatientDialogControl from '../../components/selectPatientDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectPatientDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectPatientDialog'
  )
);

const customSelectPatientDialogControlTester = rankWith(
  4,
  isSelectPatientDialogControl
);

export default {
  tester: customSelectPatientDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectPatientDialogControl),
};
