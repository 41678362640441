/* eslint-disable no-unused-expressions */
import React, { useState, useRef } from 'react';
import {
  Container,
  DialogContent,
  Dialog,
  FormDiv,
  ActionsContainer,
  CancelButton,
  DialogActions,
  SubmitButton,
  Card,
  CardHeader,
  HeaderContainer,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  PrintOS
} from './styles';
import { useReactToPrint } from 'react-to-print';
import TicketOS from '../ticketOS';
import VoucherOS from '../voucherOS';

function OrderServicePrintSelectionDialog({
  onSuccess,
  onCancel,
  orderService,
  ...props
}) {
  const [printType, setPrintType] = useState(null);
  const componentRefTicketOS = useRef();
  const componentRefVoucherOS = useRef();
  
  const handlePrint = useReactToPrint({
    documentTitle: printType === 'service_ticket' ? 
      `Ticket de Serviço` : `Comprovante - Ordem de Serviço`,
    content: () => printType === 'service_ticket' ? 
      componentRefTicketOS.current : componentRefVoucherOS.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }`,
  });
  
  return (
    <Container>
      <Dialog
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        {...props}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handlePrint();
            setPrintType(null);
            onSuccess?.();
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title='Impressão de OS' />
              </HeaderContainer>
              <FormDiv>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend' required>
                        Tipo de Impressão
                      </FormLabel>
                      <RadioGroup
                        row={true}
                        aria-label='print_type'
                        name='print_type'
                        value={printType ?? null}
                        onChange={(e) => {
                          setPrintType(e.target.value);
                        }}
                      >
                        <FormControlLabel value='service_ticket' control={<Radio required />} label='Ticket de serviço' />
                        <FormControlLabel value='step' control={<Radio required />} label='Etapa' />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </FormDiv>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setPrintType(null);
                    onCancel?.();
                  }}
                  type='button'
                  variant='contained'
                  color='primary'
                >
                  Cancelar
                </CancelButton>
                <SubmitButton
                  type='submit'
                  variant='contained'
                  color='primary'
                >
                  Imprimir
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>

      <PrintOS ref={componentRefTicketOS}>
        <TicketOS orderService={orderService} />
      </PrintOS>

      <PrintOS ref={componentRefVoucherOS}>
        <VoucherOS isClinic orderService={orderService} />
      </PrintOS>
    </Container>
  );
}

export default OrderServicePrintSelectionDialog;
