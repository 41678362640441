const Group = {
  tableSchema: [{ id: 'name', label: 'Nome', minWidth: 100, align: 'center' }],
  listSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo de Despesa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'incomeExpenseType',
      label: 'Tipo de Receita',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'GroupController@getAll',
    getAllAsGroup: 'GroupController@getAllAsGroup',
    findById: 'GroupController@findById',
    create: 'GroupController@create',
    update: 'GroupController@patchUpdate',
    setProfitMargin: 'ProfitMarginCategoryController@update',
  },
};

export default Group;
