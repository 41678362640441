import { and, isNumberControl, rankWith, schemaMatches } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import MoneyInputControl from '../../components/moneyInput';
import isEmpty from 'lodash/isEmpty';

const isMoneyInputControl = and(
  isNumberControl,
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'money'
  )
);

const materialTextControlTester = rankWith(5, isMoneyInputControl);

export default {
  tester: materialTextControlTester,
  renderer: withJsonFormsControlProps(MoneyInputControl),
};
