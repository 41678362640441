/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import {
  Dialog,
  Container,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  Card,
  CardHeader,
  FormDiv,
  Grid,
  HeaderContainer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectContainer,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from './styles';
import FilterTree from '../../helpers/filterTree';
import restApiClient from '../../services/restApiClient';
import { isEmpty } from 'lodash';

function ExecutorDialog({ onSuccess, onCancel, orderServiceStep, ...props }) {
  const [checkedOutsourced, setCheckedOutsourced] = React.useState(false);
  const [selectedExecutor, setSelectedExecutor] = React.useState(null);
  const [selectedOutsourced, setSelectedOutsourced] = React.useState(null);
  const [executors, setExecutors] = React.useState(null);
  const [outsourceds, setOutsourceds] = React.useState(null);
  const [labEmployees, setLabEmployees] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const model = {
      isOutsourced: checkedOutsourced,
      outsourcedId: selectedOutsourced,
      executorId: selectedExecutor,
    };

    restApiClient.orderServiceStep
      .defineExecutor(orderServiceStep.id, model)
      .then((e) => {
        setSelectedExecutor(null);
        setSelectedOutsourced(null);
        setCheckedOutsourced(false);
        onSuccess?.();
      })
      .catch((result) => {
        setErrors(result?.response?.data.errors);
      });
  };

  useEffect(() => {
    if (props.open) {
      if (checkedOutsourced) {
        restApiClient.outsourced.getAll().then((e) => {
          setOutsourceds(e.data);
        });
      } else {
        restApiClient.employee
          .getAllLabEmployeeWithBusyLevel(
            null,
            null,
            new FilterTree()
              .eq('stepSuggestedExecutor.step_id', `${orderServiceStep.stepId}`)
              .toString()
          )
          .then((e) => {
            if (e.data.count > 0) {
              setExecutors(e.data);
            } else {
              restApiClient.employee.getAllLabEmployee().then((e) => {
                setLabEmployees(e.data);
              });
            }
          });
      }
    }
  }, [props.open, checkedOutsourced]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Seleção de Executor" />
              </HeaderContainer>
              <FormDiv>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedOutsourced}
                            onChange={(e) => {
                              setErrors({});
                              setSelectedExecutor(null);
                              setSelectedOutsourced(null);
                              setCheckedOutsourced(e.target.checked);
                            }}
                            name="checked"
                          />
                        }
                        label="Terceirizar"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {checkedOutsourced ? (
                      <SelectContainer>
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={!isEmpty(errors?.outsourcedId)}
                        >
                          <InputLabel htmlFor="outlined-selectedOutsourced-native-simple">
                            {`Selecione um Terceirizado`}
                          </InputLabel>
                          <Select
                            id="labEmployee"
                            value={selectedOutsourced || ''}
                            label={`Selecione um Terceirizado`}
                            onChange={(e) => {
                              setErrors({});
                              setSelectedExecutor(null);
                              setSelectedOutsourced(e.target.value);
                            }}
                            required
                          >
                            {outsourceds?.values.map((optionValue) => (
                              <MenuItem
                                value={optionValue.id}
                                key={optionValue.id}
                              >
                                {optionValue?.isPhysicalPerson
                                  ? optionValue?.socialName
                                  : optionValue?.fantasyName}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {!isEmpty(errors?.outsourcedId)
                              ? errors?.outsourcedId.join(' ')
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      </SelectContainer>
                    ) : (
                      <SelectContainer>
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={!isEmpty(errors?.executorId)}
                        >
                          <InputLabel htmlFor="outlined-selectedExecutor-native-simple">
                            {`Selecione um Funcionário`}
                          </InputLabel>
                          <Select
                            id="labEmployee"
                            value={selectedExecutor || ''}
                            label={`Selecione um Funcionário`}
                            onChange={(e) => {
                              setErrors({});
                              setSelectedOutsourced(null);
                              setSelectedExecutor(e.target.value);
                            }}
                            required
                          >
                            {executors?.count > 0
                              ? executors?.values.map((optionValue) => (
                                  <MenuItem
                                    value={optionValue.id}
                                    key={optionValue.id}
                                  >
                                    {`${optionValue.name} (${optionValue.busyLevelName})`}
                                  </MenuItem>
                                ))
                              : labEmployees?.values.map((optionValue) => (
                                  <MenuItem
                                    value={optionValue.id}
                                    key={optionValue.id}
                                  >
                                    {optionValue.name}
                                  </MenuItem>
                                ))}
                          </Select>
                          <FormHelperText>
                            {!isEmpty(errors?.executorId)
                              ? errors?.executorId.join(' ')
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      </SelectContainer>
                    )}
                  </Grid>
                </Grid>
              </FormDiv>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    onCancel?.();
                    setSelectedExecutor(null);
                    setSelectedOutsourced(null);
                    setCheckedOutsourced(false);
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Salvar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </Container>
  );
}

export default ExecutorDialog;
