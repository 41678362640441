import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectEmployeeDialogControl from '../../components/selectEmployeeDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectEmployeeDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectEmployeeDialog'
  )
);

const customSelectEmployeeDialogControlTester = rankWith(
  4,
  isSelectEmployeeDialogControl
);

export default {
  tester: customSelectEmployeeDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectEmployeeDialogControl),
};
