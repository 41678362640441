import React, { useState, useCallback, useEffect } from 'react';
import Attendance from '../../../../../forms/attendance';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { 
  Container,
  ViewShow
} from './styles';
import { useSelector } from 'react-redux';
import SwitchScheduleTypeDialog from '../../../../../components/switchScheduleTypeDialog';
import moment from 'moment';

function ProceduresAttendancesList({ history, ...props }) {
  const user = useSelector((state) => state.authReducer?.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [attendanceId, setAttendanceId] = useState(false);
  const [attendance, setAttendance] = useState(null);
  const columns = Attendance.tableSchema;
  
  const loadAttendances = useCallback(() => {
    restApiClient.attendance
      .getAllByDentist(
        user?.id,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
            .and((leftChild, rightChild) => {
              leftChild
                .df('type', 'Hora')
                .df('status', 'Reprovado')
                .df('status', 'Aprovado')
              rightChild
                .like('patient.name', searchBy)
                .or()
                .like('procedure.name', searchBy)
                .or()
                .like('unit.name', searchBy)
                .or()
                .like('status', searchBy);
            })
            .toString()
          : new FilterTree()
              .df('type', 'Hora')
              .df('status', 'Reprovado')
              .df('status', 'Aprovado')
            .toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadAttendances();
  }, [loadAttendances]);
  
  return (
    <Container>
      <CrudTable
        title={'Meus Atendimentos'}
        modelActions={Attendance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpenDialog(true);
            },
            title: 'Criar Atendimento',
            allowDentist: true,
          },
          update: {
            onClick: (id, row) => {
              setAttendanceId(id);
              setAttendance(row)
              setOpenDialog(true);
            },
            allowDentist: true,
            selector: (row) => row?.status === 'Em Edição', 
          },
          view: {
            icon: <ViewShow />,
            onClick: (attendanceId) => {
              history.push({
                pathname: `/dentist/attendance/${attendanceId}`,
                state: {
                  readonly: true,
                },
              });
            },
            title: 'Visualizar Atendimento',
            allowDentist: true,
            selector: (row) => row?.status !== 'Em Edição',
          },
          delete: {
            onClick: (attendanceId) => {
              restApiClient.attendance
                .delete(attendanceId)
                .then(() => {
                  loadAttendances();
                });
            },
            allowDentist: true,
            selector: (row) => row?.status === 'Em Edição',
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
      />
      <SwitchScheduleTypeDialog
        open={openDialog}
        dentist={user}
        attendance={attendance}
        onCancel={() => {
          setOpenDialog(false)
          setAttendanceId(false);
          setAttendance(null);
        }}
        onSuccess={(type, replacedDentistId, attendanceDate, dateUnitId) => {
          if(attendanceId) {
            history.push({
              pathname: `/dentist/attendance/update/${attendanceId}`,
              state: { type, replacedDentistId,  attendanceDate, dateUnitId }
            });
          } else {
            history.push({
              pathname: '/dentist/attendance/create',
              state: { type, replacedDentistId, attendanceDate, dateUnitId }
            });
          }
        }}
      />
    </Container>
  );
}

export default ProceduresAttendancesList;
