import styled from 'styled-components';
import { Grid as MaterialGrid } from '@material-ui/core';

export const TextStyledH5 = styled.h5`
  font-family: Courier New, sans-serif !important;
  font-weight: normal;
`;

export const BoldTextStyledH5 = styled.h5`
  font-family: Courier New, sans-serif !important;
`;

export const TextTitle = styled.h3`
  font-family: Courier New, sans-serif !important;
`;

export const Grid = styled(MaterialGrid)``;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DottedLine = styled.div`
  border-bottom: 1px dotted #696969;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: lighter;
`;

export const TextStyledH6 = styled.h6`
  font-family: Courier New, sans-serif !important;
  font-weight: normal;
`;
