/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import restApiClient from '../../services/restApiClient';

import {
  Dialog,
  Container,
  DialogTitle,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  InputTextarea,
  Typography,
} from './styles';

function SubmitObservationDialog({
  onSuccess,
  onCancel,
  dialogTitle,
  itemizedCheckId,
  ...props
}) {
  const [observation, setObservation] = useState('');

  useEffect(() => {
    if (itemizedCheckId) {
      restApiClient.itemizedCheck.findById(itemizedCheckId).then((e) => {
        setObservation(e.data?.observation);
      });
    } else {
      setObservation('');
    }
  }, [itemizedCheckId]);
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6">{dialogTitle}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <InputTextarea
            id="outlined-multiline-static"
            size="small"
            label="Observação"
            multiline
            rows={4}
            variant="outlined"
            onChange={(e) => {
              setObservation(e.target.value);
            }}
            inputProps={{ maxLength: 500 }}
            value={observation}
          />
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <SubmitButton
              onClick={() => {
                onSuccess?.(observation);
              }}
              variant="contained"
              color="primary"
            >
              Salvar
            </SubmitButton>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SubmitObservationDialog;
