import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsCellProps, withJsonFormsEnumProps } from '@jsonforms/react';
import SelectExecutorDialogControl from '../../components/selectExecutorDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectExecutorDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && 
      schema.format === 'selectExecutorDialog' &&
      schema.type === 'number'
  )
);

const customSelectExecutorDialogControlTester = rankWith(
  4,
  isSelectExecutorDialogControl
);

export default {
  tester: customSelectExecutorDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectExecutorDialogControl),
  cell: withJsonFormsCellProps(SelectExecutorDialogControl),
};
