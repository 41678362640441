import React, { useState, useEffect, useCallback } from 'react';
import Budget from '../../../forms/budget';
import Item from '../../../forms/item';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import BudgetDialog from '../../../components/budgetDialog';
import FilterTree from '../../../helpers/filterTree';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  Container,
  AddBox,
  AssignmentTurnedIn,
  Preview,
  Send,
  Documents,
  PriceChange,
  Import,
} from './styles';
import BudgetItem from '../../../forms/budgetItem';
import FilesDialog from '../../../components/filesDialog';
import BudgetFile from '../../../forms/budgetFile';
import CreateUpdateGroupDialog from '../../../components/createUpdateGroupDialog';
import BudgetInEditingListDialog from '../../../components/budgetInEditingListDialog';

function BudgetList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openCostShare, setOpenCostShare] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedBudget, setSelectedBudget] = useState(false);
  const [budgetFileOpen, setBudgetFileOpen] = useState(false);
  const [budgetsInEditingListOpen, setBudgetsInEditingListOpen] =
    useState(false);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [action, setAction] = useState({
    key: '',
    actionLabel: '',
    depthLabel: '',
  });
  const columns = Budget.tableSchema;

  const loadBudgets = useCallback(() => {
    restApiClient.budget
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('name', searchBy)
              .or()
              .like('expectedDate', searchBy)
              .or()
              .like('unit.name', searchBy)
              .or()
              .like('supplier.socialName', searchBy)
              .or()
              .like('supplier.fantasyName', searchBy)
              .or()
              .like('updatedAt', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadBudgets();
  }, [loadBudgets]);

  const handleAlertClose = (event, reason) => {
    setErrorMessage('');
  };

  return (
    <Container>
      <CrudTable
        title={'Listagem de Orçamentos'}
        modelActions={Budget.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id, row) => row.id === submitConfirmationId,
            content: (
              <span>Você tem certeza que deseja finalizar o orçamento?</span>
            ),
            onSuccess: () => {
              restApiClient.budget
                .makePurchase(submitConfirmationId)
                .then((result) => {
                  loadBudgets();
                  setSubmitConfirmationId(-1);
                })
                .catch((e) => setErrorMessage(e.response.data?.errors ?? ''));
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          add: {
            icon: <AddBox />,
            onClick: (id) => setOpen(true),
            title: 'Criar Orçamento',
            allowedActions: [Budget.actions.create],
          },
          update: {
            onClick: (budgetId) => {
              setSelectedBudget(budgetId);
              setOpen(true);
            },
            allowedActions: [Budget.actions.update],
            selector: (row) => row.status === 'editing',
          },
          costShare: {
            icon: <PriceChange />,
            onClick: (budgetId) => {
              setAction({
                key: 'edit',
                actionLabel: 'Rateio',
                depthLabel: 'valor de Orçamento',
              });
              setSelectedBudget(budgetId);
              setOpenCostShare(true);
            },
            title: 'Ratear Valores',
            allowedActions: [Budget.actions.costShare],
          },
          manageItems: {
            icon: <AssignmentTurnedIn />,
            onClick: (budgetId) => {
              history.push(`/budget/itemList/${budgetId}`);
            },
            title: 'Gerenciar Itens',
            allowedActions: [Item.actions.getAllWithItems],
            selector: (row) => row.status === 'editing',
          },
          viewItems: {
            icon: <Preview />,
            onClick: (budgetId) => {
              history.push(`/budget/view-budget-items/${budgetId}`);
            },
            title: 'Visualizar Itens',
            allowedActions: [
              BudgetItem.actions.getAllByBudgetId,
              Budget.actions.findById,
            ],
            selector: (row) => row?.budgetItemsCount > 0,
          },
          importItems: {
            icon: <Import />,
            onClick: (budgetId) => {
              setSelectedBudget(budgetId);
              setBudgetsInEditingListOpen(true);
            },
            title: 'Importar Itens',
            allowedActions: [
              [
                Budget.actions.getAllBudgetsInEditing,
                Budget.actions.importItems,
                BudgetItem.actions.getAllByBudgetId,
              ],
            ],
            selector: (row) =>
              row.status === 'editing' &&
              rows.values.some(
                (obj) =>
                  obj.id !== row?.id &&
                  obj.status === 'editing' &&
                  obj.budgetItemsCount > 0
              ),
          },
          makePurchase: {
            icon: <Send />,
            onClick: (budgetId) => {
              setSubmitConfirmationId(budgetId);
            },
            title: 'Efetuar Compra',
            allowedActions: [Budget.actions.makePurchase],
            selector: (row) =>
              row.status === 'editing' && row.budgetItemsCount > 0,
          },
          delete: {
            onClick: (budgetId, index) => {
              restApiClient.budget.delete(budgetId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
            allowedActions: [Budget.actions.delete],
            selector: (row) => row.status === 'editing',
          },
          addFiles: {
            icon: <Documents />,
            onClick: (budgetId) => {
              setBudgetFileOpen(true);
              setSelectedBudget(budgetId);
            },
            title: 'Anexos',
            allowedActions: [BudgetFile.actions.getAllByBudgetId],
          },
        }}
        customCellRenderers={{
          expectedDate: {
            renderer: (field, value) => {
              return new Date(`${value}T00:00:00`).toLocaleDateString('pt-br');
            },
          },
          status: {
            renderer: (field, value) => {
              switch (value) {
                case 'editing':
                  return 'Em edição';
                case 'under_negotiation':
                  return 'Em Negociação';
                case 'completed_purchase':
                  return 'Compra Realizada';
                case 'purchase_made':
                  return 'Compra Efetuada';
                default:
                  return '';
              }
            },
          },
          updatedAt: {
            renderer: (field, value) => {
              const date = new Date(value);
              return `${date.toLocaleDateString(
                'pt-br'
              )} às ${date.toLocaleTimeString('pt-br')}`;
            },
          },
          total: {
            renderer: (field, value) => {
              return parseFloat(value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          supplier: {
            renderer: (field, value, column, row) => {
              return row.supplier?.isPhysicalPerson
                ? row.supplier?.socialName
                : row.supplier?.fantasyName;
            },
          },
        }}
      />

      <BudgetDialog
        open={open}
        budget={Budget}
        selectedBudget={selectedBudget}
        onCancel={() => {
          setOpen(false);
          setSelectedBudget(null);
        }}
        onSuccess={() => {
          setSelectedBudget(null);
          setOpen(false);
          loadBudgets();
        }}
        {...props}
      />

      <CreateUpdateGroupDialog
        open={openCostShare}
        restModel={{
          findById: async () => ({
            data: {
              value: 0,
            },
          }),
          update: async (budgetId, requestModelData, obj) => {
            return await restApiClient.budget.costShare(budgetId, obj.value);
          },
        }}
        action={action}
        parentId={selectedBudget}
        fieldName="value"
        label="Valor"
        type="number"
        onCancel={() => {
          setOpenCostShare(false);
          setSelectedBudget(undefined);
        }}
        onSuccess={() => {
          loadBudgets();
          setOpenCostShare(false);
        }}
      />

      <FilesDialog
        open={budgetFileOpen}
        modelId={selectedBudget}
        restGetData={restApiClient.budgetFile.getAllByBudgetId}
        restDeleteData={restApiClient.budgetFile.delete}
        restSubmitData={restApiClient.budgetFile.submitFile}
        restDownloadData={restApiClient.budgetFile.downloadFile}
        actions={BudgetFile.actions}
        onCancel={() => {
          setBudgetFileOpen(false);
          setSelectedBudget(null);
        }}
        onSuccess={(result) => {
          setBudgetFileOpen(false);
          setSelectedBudget(null);
        }}
        {...props}
      />

      <BudgetInEditingListDialog
        open={budgetsInEditingListOpen}
        disableBudget={selectedBudget}
        onCancel={() => {
          setBudgetsInEditingListOpen(false);
          setSelectedBudget(null);
        }}
        onSelect={(budgetId) => {
          history.push({
            pathname: `/budget/budget-items/${budgetId}`,
            state: { crrBudget: selectedBudget },
          });
          setBudgetsInEditingListOpen(false);
          setSelectedBudget(null);
        }}
        {...props}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default BudgetList;
