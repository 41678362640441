import styled from 'styled-components';
import {
  AddToList as MaterialAddToList,
  Import as MaterialImport,
} from '../../../../styles/icons';
import { Button as MaterialButton } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const PlaceholderContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  font-size: 0.95rem;
  color: red;
`;

export const AddToList = styled(MaterialAddToList)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ImportCsvButton = styled(MaterialButton)`
  color: #ffffff !important;
  background: #2dbd17 !important;
`;

export const Import = styled(MaterialImport)`
  width: 25px;
  height: 25px;
`;
