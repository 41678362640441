import React, { useEffect } from 'react';
import restApiClient from '../../services/restApiClient';
import {
  FormControl,
  InputLabel,
  NativeSelect,
  Container,
} from './styles';
import { Hidden } from '@material-ui/core';

const SelectCityDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  config,
  errors,
  visible,
  noValidation,
  onSuccess,
  ...props
}) => {
  const [allCity, setAllCity] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState('');

  useEffect(() => {
    restApiClient.unit.getAll().then((e) => {
      const cities = e.data.values.map((unit) => {
        return unit.city;
      });
      const crrUniqueCities = cities.filter((el, i, arr) => arr.indexOf(el) == i);
      const uniqueCities = crrUniqueCities.map((city) => {
        return {
          name: city
        };
      });
      setAllCity(uniqueCities);
    });
  }, []);

  return (
    <Hidden xsUp={!visible}>
      <Container>
          <FormControl>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
              Município
            </InputLabel>
            <NativeSelect
              value={selectedCity}
              onChange={(e) => {
                setSelectedCity(e.target.value);
                handleChange(path, e.target.value);
              }}
            >
              <option aria-label="None" value="">
                Selecione
              </option>
              {allCity.map((city) => (
                <option key={`city_${city.name}`} value={city.name}>
                  {city.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
      </Container>
    </Hidden>
  );
};

export default SelectCityDialogControl;
