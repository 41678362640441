import React, { useState, useEffect, useCallback } from 'react';
import Item from '../../../../forms/item';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, Numbers } from './styles';
import { useSelector } from 'react-redux';
import Stock from '../../../../forms/stock';
import ClinicAdjustStockDialog from '../../../../components/clinicAdjustStockDialog';
import isEmpty from 'lodash/isEmpty';

function AdjustStockClinicList() {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [openAdjust, setOpenAdjust] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const selectedUnit = user?.unitId ?? '';

  const columns = Item.adjustClinicTableSchema;

  const loadItems = useCallback(() => {
    if (selectedUnit) {
      restApiClient.item
        .getAllWithStockClinic(
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('name', searchBy)
                .or()
                .like('brand', searchBy)
                .or()
                .like('barcode.barcode', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, selectedUnit]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <Container>
      <CrudTable
        title={'Ajuste de Estoque'}
        modelActions={Item.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          adjustQuantity: {
            icon: <Numbers />,
            onClick: (id, row) => {
              setSelectedItem(row);
              setOpenAdjust(true);
            },
            title: 'Ajustar quantidade',
            allowedActions: [Stock.actions.clinicAdjustQuantityStock],
          },
        }}
        customCellRenderers={{
          barcode: {
            renderer: (field, value) => {
              return !isEmpty(value)
                ? value.map((item) => item.barcode).join(', ')
                : '-';
            },
          },
          'stock.availableQuantity': {
            renderer: (field, value) => {
              return value ?? 0;
            },
          },
        }}
      />

      <ClinicAdjustStockDialog
        open={openAdjust}
        item={selectedItem}
        onCancel={() => setOpenAdjust(false)}
        onSuccess={() => {
          setOpenAdjust(false);
          loadItems();
        }}
      />
    </Container>
  );
}

export default AdjustStockClinicList;
