import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  DialogContent as MaterialDialogContent,
} from '@material-ui/core';
import CreateUpdateForm from '../createUpdateForm';

export const Dialog = styled(MaterialDialog)``;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 0 !important;
`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)`
  margin-bottom: 25px !important;
`;
