import React, { useState, useEffect, useCallback } from 'react';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, SaveIcon, StyledPercentageInput } from './styles';
import Group from '../../../forms/group';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ProfitMarginConfirmationDialog from '../../../components/profitMarginConfirmationDialog';

function ProfitMarginList() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [profitMarginCategoryList, setProfitMarginCategoryList] = useState([]);
  const [alert, setAlert] = useState('');
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [groupId, setGroupId] = useState({});
  const columns = Group.tableSchema;

  const handleAlertClose = () => setAlert('');

  const loadGroups = useCallback(() => {
    restApiClient.group
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftOp, rightOp) => {
                leftOp.isNull('parentId');
                rightOp.like('name', searchBy);
              })
              .toString()
          : new FilterTree().isNull('parentId').toString(),
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        let newList = [];
        newRows.values = e.data.values.map((rowGroup) => {
          if (rowGroup?.profitMarginCategory?.groupId === rowGroup.id) {
            rowGroup.profitMargin = rowGroup.profitMarginCategory.profitMargin;
            newList.push(rowGroup.profitMarginCategory);
          }
          setProfitMarginCategoryList(newList);
          return rowGroup;
        });
        setRows(newRows);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  return (
    <Container>
      <CrudTable
        title={`Margem de Lucro por Categoria`}
        modelActions={Group.actions}
        columns={columns}
        customColumns={[
          {
            id: 'profitMargin',
            label: 'Margem de Lucro(%)',
            minWidth: 50,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          updateProfitMargin: {
            icon: <SaveIcon />,
            onClick: (id) => {
              const profitMarginCategory = profitMarginCategoryList.find(
                (obj) => obj.groupId === id
              );

              const isValid =
                profitMarginCategory?.profitMargin > 0 ||
                isNaN(profitMarginCategory?.profitMargin);

              if (isValid) {
                setGroupId(id);
                setOpen(true);
                setData(profitMarginCategory);
              }
            },
            title: 'Atualizar Margem de Lucro',
            allowedActions: [Group.actions.setProfitMargin],
          },
        }}
        customCellRenderers={{
          profitMargin: {
            renderer: (field, value, column, row) => {
              row.profitMargin = parseFloat(row.profitMargin);
              let profitMarginCategoryFind = profitMarginCategoryList.find(
                (x) => x.groupId === row.id
              );
              let profitMargin;
              if (profitMarginCategoryFind)
                profitMargin = profitMarginCategoryFind?.profitMargin;
              else profitMargin = value;
              const error = profitMargin <= 0;
              return (
                <StyledPercentageInput
                  value={profitMargin}
                  error={error}
                  helperText={error ? 'valor deve ser maior 0.' : ''}
                  onChange={(e) => {
                    let profitMarginCategory;
                    const newList = [...profitMarginCategoryList];
                    if (
                      (profitMarginCategory = newList.find(
                        (x) => x.groupId === row.id
                      ))
                    ) {
                      profitMarginCategory.profitMargin = parseFloat(
                        e.target.value
                      );
                    } else {
                      const profitMarginCategory = {
                        groupId: row.id,
                        profitMargin: parseFloat(e.target.value),
                      };
                      newList.push(profitMarginCategory);
                    }
                    row.profitMargin = parseFloat(e.target.value);
                    setProfitMarginCategoryList(newList);
                  }}
                />
              );
            },
          },
        }}
      />
      <Snackbar
        open={!!alert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {alert}
        </Alert>
      </Snackbar>
      <ProfitMarginConfirmationDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={(confirmation) => {
          setOpen(false);
          restApiClient.profitMarginCategory
            .update({ ...data, groupId, deleteIndividual: confirmation })
            .then(() => {
              setAlert('Alterações salvas com sucesso!');
              setProfitMarginCategoryList([]);
              loadGroups();
            });
        }}
      />
    </Container>
  );
}

export default ProfitMarginList;
