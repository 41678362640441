import React, { useRef, useState, useEffect } from 'react';
import OrderServiceStep from '../../../../forms/orderServiceStep';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import {
  Container,
  FooterContainer,
  SaveButton,
  CancelButton,
  BackButton,
  AutoDiv,
  SubmitButton,
  PrintOS
} from './styles';
import { objectMap } from '../../../../extensions/object';
import { ButtonsContainer } from '../../../../components/createUpdateForm/styles';
import RejectOrderServiceDialog from '../../../../components/rejectOrderServiceDialog';
import TicketOS from '../../../../components/ticketOS';
import { useReactToPrint } from 'react-to-print';
import ExecutorDialog from '../../../../components/executorDialog';
import SubmitDialog from '../../../../components/submitDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

function LabOrderServiceStepCreateUpdate({
  crrAllowView = null,
  crrViewForm = true,
  crrEndProductId = null,
  crrOrderServiceId = null,
  crrWorkflowPosition = null,
  crrId = null,
  crrOrderServiceStep = null,
  crrDentistId = null,
  crrNextStep = false,
  crrPatientName = null,
  crrRecordNumber = null,
  ...props
}) {
  const [orderServiceStepId, setOrderServiceStepId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [groupStep, setGroupStep] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [orderService, setOrderService] = useState(null);
  const [openExecutor, setOpenExecutor] = useState(false);
  const [showDentalArch, setShowDentalArch] = useState(false);
  const [showToothNumber, setShowToothNumber] = useState(false);
  const [showToothShade, setShowToothShade] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [stepId, setStepId] = useState(-1);
  const [nextStep, setNextStep] = useState(crrNextStep ?? false);

  const endProductId = parseInt(
    crrEndProductId ?? props.match?.params?.endProductId ?? -1
  );

  let create = props.match?.params?.create ?? false;

  const orderServiceId = parseInt(
    crrOrderServiceId ?? props.match?.params?.orderServiceId ?? -1
  );
  const workflowPosition = parseInt(
    crrWorkflowPosition ?? props.match?.params?.workflowPosition ?? -1
  );
  let viewForm = crrViewForm ?? props.match?.params?.viewForm ?? -1;
  let newViewForm = false;

  const patientName = crrPatientName ?? props.location.state?.patientName ?? '';
  const recordNumber = parseInt(
    crrRecordNumber ?? props.location.state?.recordNumber ?? -1
  );

  if (!create) {
    newViewForm = true;
  } 

  const date = props.location?.state?.date
    ? new Date(props.location?.state.date).toLocaleDateString('pt-br')
    : new Date().toLocaleDateString();
  const dentistId = crrDentistId ?? props.location.state?.dentistId ?? -1;
  const allowView = crrAllowView ?? props.location.state?.allowView ?? false;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    documentTitle: `Ticket de Serviço`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 2mm 2mm 2mm 2mm;   
      }`,
  });

   useEffect(() => {
    if (orderServiceId) {
      restApiClient.orderServiceStep.getAllByOrderServiceId(orderServiceId).then((e) => {
        const crr = e.data.values.find(x => x.current);
        setNextStep(crr.nextStep);
      });
    }
  }, [orderServiceId]);

  useEffect(() => {
    if (endProductId) {
      restApiClient.endProduct.findById(endProductId).then((e) => {
        let firstStepFind = e.data.workflows.find(
          (x) => x.position === parseInt(workflowPosition)
        );
        if (firstStepFind) {
          let step = firstStepFind.step;
          setShowDentalArch(step.dentalArch);
          setShowToothNumber(step.toothNumber);
          setShowToothShade(step.toothColor);
          setStepId(step.id);
        }
      });
    }
  }, [endProductId, workflowPosition]);

  return stepId !== -1 ? (
    <Container>
      <CreateUpdateForm
        modelSchema={OrderServiceStep}
        forceId={crrId}
        restGetDataById={async (id) => {
          setOrderServiceStepId(id);
          let result = await restApiClient.orderServiceStep.findById(id);
          result.data = objectMap(result.data, (key, value) =>
            value !== null ? value : undefined
          );
          setOrderService({
            ...result.data.orderService,
            orderServiceStep: result.data,
          });
          return result;
        }}
        restUpdateData={async (id, oldOrderServiceStep, orderServiceStep) => {
          delete oldOrderServiceStep.dentistId;
          delete oldOrderServiceStep.date;
          delete oldOrderServiceStep.endProductId;
          delete oldOrderServiceStep.stepId;
          delete oldOrderServiceStep.enableFields;
          delete oldOrderServiceStep.showDentalArch;
          delete oldOrderServiceStep.showToothNumber;
          delete oldOrderServiceStep.showToothShade;
          delete oldOrderServiceStep.viewForm;
          delete oldOrderServiceStep.workflowPosition;
          delete oldOrderServiceStep.showGroupStep;
          delete oldOrderServiceStep.groupStep;
          delete oldOrderServiceStep.status;
          delete oldOrderServiceStep.executorId;
          delete oldOrderServiceStep.dentistId;
          delete oldOrderServiceStep.patientName;
          delete oldOrderServiceStep.recordNumber;
          delete oldOrderServiceStep.requestingUnitBoolean;
          
          delete orderServiceStep.date;
          delete orderServiceStep.endProductId;
          delete orderServiceStep.stepId;
          delete orderServiceStep.enableFields;
          delete orderServiceStep.showDentalArch;
          delete orderServiceStep.showToothNumber;
          delete orderServiceStep.showToothShade;
          delete orderServiceStep.viewForm;
          delete orderServiceStep.workflowPosition;
          delete orderServiceStep.showGroupStep;
          delete orderServiceStep.groupStep;
          delete orderServiceStep.status;
          delete orderServiceStep.executorId;
          delete orderServiceStep.dentistId;
          delete orderServiceStep.patientName;
          delete orderServiceStep.recordNumber;
          delete orderServiceStep.requestingUnitBoolean;
          
          if (!orderServiceStep.skipStep) {
            // se apagou o skip, deve ser atualizado a justificativa
            orderServiceStep.skipJustify = 'null';
          } else {
            orderServiceStep.dentalArch = 'null';
            orderServiceStep.toothShadeId = 'null';
            orderServiceStep.toothNumber = 'null';
          }

          const result = await restApiClient.orderServiceStep.update(
            id,
            oldOrderServiceStep,
            orderServiceStep
          );
          return result;
        }}
        onUpdateSuccess={(request, data) => {
          if (data === 'submit') {
            // SUBMETER
            if (request.data.skipStep && request.data.skipJustify !== null) {
              // SUBMETER OS - PULAR ETAPA
              restApiClient.orderService
                .findById(request.data.orderServiceId)
                .then((e) => {
                  const endProductId = e.data.endProduct.id;
                  const skipStepFind = e.data.endProduct.workflows.find(
                    (x) => x.stepId === request.data.stepId
                  );
                  const nextStep = e.data.endProduct.workflows.find(
                    (x) => x.position === skipStepFind.position + 1
                  );
                  if (nextStep) {
                    const nextOrderServiceStep = {
                      orderServiceId: request.data.orderServiceId,
                      dentistId: request.data.dentistId,
                      stepId: nextStep.stepId,
                      unitId: request.data.unitId,
                      scheduleId: request.data.scheduleId,
                      status: 'Em Edição (Laboratório)',
                      current: true,
                    };
                    restApiClient.orderServiceStep
                      .setSkippedLab(request.data.id)
                      .then((e) => {
                        props.history.goBack();
                        restApiClient.orderServiceStep
                          .create(nextOrderServiceStep)
                          .then((e) => {
                            props.history.push(
                              `/lab/orderService/update/${endProductId}/${e.data.id}/false/${nextStep.position}/${e.data.orderServiceId}/true`
                            );
                          });
                      });
                  } else {
                    restApiClient.orderServiceStep
                      .setSkippedLab(request.data.id)
                      .then((e) => {
                        props.history.goBack();
                      });
                  }
                });
            } else {
              restApiClient.orderService
                .findById(request.data.orderServiceId)
                .then((e) => {
                  const skipStepFind = e.data.endProduct.workflows.find(
                    (x) => x.stepId === request.data.stepId
                  );
                  const lastStep = e.data.endProduct.workflows.find(
                    (x) => x.position === skipStepFind.position - 1
                  );
                  if (lastStep) {
                    const step = e.data.orderServiceSteps.find(
                      (x) => x.stepId === lastStep.stepId
                    );
                    if (step && step.status === 'Agrupado') {
                      // SUBMETER OS COM ETAPA ANTERIOR AGRUPADA
                      restApiClient.orderServiceStep
                        .setSended(request.data.id)
                        .then((result) => {
                          setOrderService({
                            ...result.data.orderService,
                            orderServiceStep: result.data,
                          });
                          props.history.goBack();
                        });
                    } else {
                      restApiClient.orderServiceStep
                        .setSended(request.data.id)
                        .then((result) => {
                          setOrderService({
                            ...result.data.orderService,
                            orderServiceStep: result.data,
                          });
                          props.history.goBack();
                        });
                    }
                  } else {
                    //SUBMETER OS NORMAL
                    restApiClient.orderServiceStep
                      .setSended(request.data.id)
                      .then((result) => {
                        setOrderService({
                          ...result.data.orderService,
                          orderServiceStep: result.data,
                        });
                        props.history.goBack();
                      });
                  }
                });
            }
          } else if (data === 'nextStep') {
            // AGRUPAR COM PRÓXIMA ETAPA
            restApiClient.orderService
              .findById(request.data.orderServiceId)
              .then((e) => {
                const endProductId = e.data.endProduct.id;
                const skipStepFind = e.data.endProduct.workflows.find(
                  (x) => x.stepId === request.data.stepId
                );
                const nextStep = e.data.endProduct.workflows.find(
                  (x) => x.position === skipStepFind.position + 1
                );
                const nextOrderServiceStep = {
                  orderServiceId: request.data.orderServiceId,
                  dentistId: request.data.dentistId,
                  stepId: nextStep.stepId,
                  unitId: request.data.unitId,
                  scheduleId: request.data.scheduleId,
                  status: 'Em Edição (Laboratório)',
                  current: true,
                };
                restApiClient.orderServiceStep
                  .setGrouped(request.data.id)
                  .then((result) => {
                    setOrderService({
                      ...result.data.orderService,
                      orderServiceStep: result.data,
                    });
                    props.history.goBack();
                    restApiClient.orderServiceStep
                      .create(nextOrderServiceStep)
                      .then((e) => {
                        props.history.push(
                          `/lab/orderService/update/${endProductId}/${e.data.id}/false/${nextStep.position}/${e.data.orderServiceId}/true`
                        );
                      });
                  });
              });
          } else {
            //SALVAR FORM
            props.history.goBack();
            props.history.replace({
              pathname: `/lab/orderService`,
              state: {
                updateSuccess: true,
              },
            });
          }
        }}
        onChange={(data) => {
          if (data.skipStep) {
            setGroupStep(false);
          } else {
            setGroupStep(data.groupStep);
          }
        }}
        modelData={{
          date: date,
          enableFields: false,
          showDentalArch: showDentalArch,
          showToothNumber: showToothNumber,
          showToothShade: showToothShade,
          stepId: stepId,
          showGroupStep: false,
          skipStep: false,
          endProductId: parseInt(endProductId),
          dentistId: dentistId,
          orderServiceId: orderServiceId,
          viewForm: newViewForm,
          workflowPosition: parseInt(workflowPosition),
          patientName: patientName,
          recordNumber: recordNumber,
        }}
        customButtonsBuilder={(submit) =>
           create ? groupStep ? (
            <>
              <CancelButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SaveButton
                type="button"
                variant="contained"
                color="primary"
                id="salvarTop"
                onClick={() => submit()}
              >
                {'Salvar'}
              </SaveButton>
              <SubmitButton
                type="button"
                variant="contained"
                color="primary"
                id="teste1"
                onClick={() => {
                  setOpenSubmit(true);
                  setDialogText(
                    'Ao agrupar com a próxima etapa, a mesma não poderá mais ser editada. Deseja continuar?'
                  );
                  setConfirmSubmit(() => () => submit('nextStep'));
                }}
              >
                {'Próxima etapa'}
              </SubmitButton>
            </>
          ) : (
            <>
              <CancelButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SaveButton
                type="button"
                variant="contained"
                color="primary"
                id="salvarTop"
                onClick={() => submit()}
              >
                {'Salvar'}
              </SaveButton>
              <SubmitButton
                type="button"
                variant="contained"
                color="primary"
                id="teste1"
                onClick={() => {
                  setOpenSubmit(true);
                  setDialogText(
                    'Ao submeter a OS, a mesma não poderá mais ser editada. Deseja continuar?'
                  );
                  setConfirmSubmit(() => () => submit('submit'));
                }}
              >
                {'Submeter'}
              </SubmitButton>
            </>
          ) : (
          viewForm && !allowView ? (
            <>
              <FooterContainer>
                <ButtonsContainer>
                  <CancelButton
                    onClick={() => {
                      setOpen(true);
                    }}
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Recusar
                  </CancelButton>
                  <SaveButton
                    onClick={() => {
                      setOpenExecutor(true);
                    }}
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Aceitar
                  </SaveButton>
                </ButtonsContainer>
              </FooterContainer>
            </>
          ) : (
            <>
              <FooterContainer>
                <BackButton
                  onClick={() => props.history.goBack()}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Voltar
                </BackButton>
              </FooterContainer>
            </>
          ))
        }
        {...props}
      />

      <SubmitDialog
        open={openSubmit}
        onCancel={() => setOpenSubmit(false)}
        onSuccess={() => {
          setOpenSubmit(false);
          confirmSubmit();
        }}
        dialogText={dialogText}
      />

      <RejectOrderServiceDialog
        open={open}
        orderServiceStepId={orderServiceStepId}
        nextStep={nextStep}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          props.history.goBack();
        }}
      />

      <ExecutorDialog
        open={openExecutor}
        onCancel={() => setOpenExecutor(false)}
        onSuccess={() => {
          setOpenExecutor(false);
          restApiClient.orderServiceStep
            .setQueued(orderServiceStepId)
            .then((e) => {
              setOrderService((prevState) => {
                return { ...prevState, orderServiceStep: e.data };
              });
              handlePrint();
              props.history.goBack();
            });
        }}
        orderServiceStep={orderService?.orderServiceStep}
      />

      <PrintOS ref={componentRef}>
        <TicketOS orderService={orderService} />
      </PrintOS>
    </Container>
  ) : (
    <AutoDiv>
      <CircularProgress size={60} />
    </AutoDiv>
  );
}

export default LabOrderServiceStepCreateUpdate;
