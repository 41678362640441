import React from 'react';
import Unit from '../../../forms/unit';
import CreateUpdateForm from '../../../components/createUpdateForm';
import restApiClient from '../../../services/restApiClient';
import { Container } from './styles';

function UnitCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Unit}
        restGetDataById={restApiClient.unit.findById}
        restCreateData={restApiClient.unit.create}
        restUpdateData={restApiClient.unit.update}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default UnitCreateUpdate;
