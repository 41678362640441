import styled from 'styled-components';
import { 
  NotesMedical as MaterialNotesMedical,
  Send as MaterialSend,
  ViewShow as MaterialViewShow,
  Delete as MaterialDelete
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const NotesMedical = styled(MaterialNotesMedical)`
  width: 23px;
  height: 23px;
  color: var(--secondary);
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Delete = styled(MaterialDelete)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

