const Order = {
  tableSchema: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'toUnit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'transferType.name',
      label: 'Tipo de Transferência',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'submissionDate',
      label: 'Data de Submissão',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'employee.user.name',
      label: 'Solicitante',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Status', minWidth: 100, align: 'center' },
    {
      id: 'sendDate',
      label: 'Data de Envio',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'orderItem',
      label: 'Valor',
      minWidth: 100,
      align: 'center',
    },
  ],
  productListTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome do Produto', minWidth: 100, align: 'center' },
  ],
  itemListTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome do item', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 25, align: 'center' },
    { id: 'barcode', label: 'Código de Barras', minWidth: 25, align: 'center' },
    {
      id: 'stock',
      label: 'Quantidade Clínica',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'price',
      label: 'Preço',
      minWidth: 25,
      align: 'center',
    },
  ],
  actions: {
    getAllByUnit: 'OrderController@getAllByUnit',
    create: 'OrderController@create',
    suggestedTransfer: 'OrderController@suggestedTransfer',
    findById: 'OrderController@findById',
    findByIdByEmployee: 'OrderController@findByIdByEmployee',
    updateByClinic: 'OrderController@updateByClinic',
    updateByStock: 'OrderController@updateByStock',
    delete: 'OrderController@delete',
    submitOrder: 'OrderController@submitOrder',
    separateOrder: 'OrderController@separateOrder',
    approveTransfer: 'OrderController@approveTransfer',
  },
};

export default Order;
