/* eslint-disable no-unused-expressions */
import React from 'react';
import { Container, FormHelperText } from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';

const SelectStatusControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  ...props
}) => {
  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          disabled={true}
          error={!isEmpty(errors)}
          label="Situação"
          InputLabelProps={{
            shrink: true,
            readOnly: true,
          }}
          value={data}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
        {data === 'Recusada Dentista (Laboratório)' ? (
          <AdornedTextField
            disabled={true}
            error={true}
            label="Motivo"
            InputLabelProps={{
              shrink: true,
              readOnly: true,
            }}
            value={config.completeData.dentistRejectedJustification}
          />
        ) : data === 'Reagendamento (Clínica)' ? (
          <AdornedTextField
            disabled={true}
            error={true}
            label="Motivo"
            InputLabelProps={{
              shrink: true,
              readOnly: true,
            }}
            value={config.completeData.rejectedJustification}
          />
        ) : data === 'Ajuste (Dentista)' ? (
          <AdornedTextField
            disabled={true}
            error={true}
            label="Motivo"
            InputLabelProps={{
              shrink: true,
              readOnly: true,
            }}
            value={config.completeData.rejectedJustification}
          />
        ) : data === 'Corrigido Dentista (Clínica)' ? (
          <AdornedTextField
            disabled={true}
            error={true}
            label="Motivo"
            InputLabelProps={{
              shrink: true,
              readOnly: true,
            }}
            value={config.completeData.rejectedJustification}
          />
        ) : data === 'Corrigido Auxiliar (Laboratório)' ? (
          <AdornedTextField
            disabled={true}
            error={true}
            label="Motivo"
            InputLabelProps={{
              shrink: true,
              readOnly: true,
            }}
            value={config.completeData.rejectedJustification}
          />
        ) : (
          ''
        )}
      </Container>
    </Hidden>
  );
};

export default SelectStatusControl;
