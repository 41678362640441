/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  Dialog,
  Container,
  DialogTitle,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  ActionsErrorContainer,
  DialogContentText,
  Typography,
} from './styles';

function CheckDuplicateDialog({
  onSuccess,
  onCancel,
  dialogText,
  errorMessage = false,
  ...props
}) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h6">Atenção!</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!errorMessage ? (
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  onCancel?.();
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Não
              </CancelButton>
              <SubmitButton
                onClick={() => {
                  onSuccess?.();
                }}
                variant="contained"
                color="primary"
              >
                Sim
              </SubmitButton>
            </ActionsContainer>
          ) : (
            <ActionsErrorContainer>
              <SubmitButton
                onClick={() => {
                  onSuccess?.();
                }}
                variant="contained"
                color="primary"
              >
                Ok
              </SubmitButton>
            </ActionsErrorContainer>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CheckDuplicateDialog;
