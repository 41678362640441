import styled from 'styled-components';
import MoneyInput from '../../../../components/moneyInput';
import { Button as MaterialButton } from '@material-ui/core';
import { Save as MaterialSaveIcon } from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 110px;
`;

export const Button = styled(MaterialButton)`
  color: #ffffff !important;
`;

export const SaveIcon = styled(MaterialSaveIcon)`
  width: 25px;
  height: 25px;
`;
