import styled from 'styled-components';
import MaterialIconButton from '@material-ui/core/IconButton';
import MaterialTreeItem from '@material-ui/lab/TreeItem';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const LabelContainer = styled.span`
  width: 30%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
`;

export const GroupTreeItem = styled(MaterialTreeItem)`
  border-bottom: 1px groove;
`;

export const TreeItem = styled(MaterialTreeItem)``;

export const ActionContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
`;

export const IconButton = styled(MaterialIconButton)``;
