import React, { useCallback, useEffect, useState } from 'react';
import { Container, PriceChange, ClockHistory, Receipt } from './styles';
import UnitFinance from '../../../../../forms/unitFinance';
import restApiClient from '../../../../../services/restApiClient';
import FilterTree from '../../../../../helpers/filterTree';
import CrudTable from '../../../../../components/crudTable';
import AdjustUnitFinanceBalanceDialog from '../../../../../components/adjustUnitFinanceBalanceDialog';
import ExpenseTypeDialog from '../../../../../components/expenseTypeDialog';

function FinancialCashList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [unitFinance, setUnitFinance] = useState(null);
  const [openAdjustDialog, setOpenAdjustDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const columns = UnitFinance.financialCashtableSchema;

  const loadUnitFinances = useCallback(() => {
    restApiClient.unitFinance
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild.df('type', 'Banco');
                rightChild
                  .like('id', searchBy)
                  .or()
                  .like('unit.name', searchBy)
                  .or()
                  .like('type', searchBy);
              })
              .toString()
          : new FilterTree().df('type', 'Banco').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadUnitFinances();
  }, [loadUnitFinances]);

  return (
    <Container>
      <CrudTable
        title={'Caixa Financeiro'}
        modelActions={UnitFinance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          link: {
            icon: <Receipt />,
            onClick: (id) => {
              setSelectedId(id);
              setOpen(true);
            },
            maxDepth: 2,
            title: 'Vincular a um tipo de despesa',
            allowedActions: [
              [UnitFinance.actions.findById, UnitFinance.actions.update],
            ],
          },
          adjustBalance: {
            icon: <PriceChange />,
            onClick: (id, row) => {
              setUnitFinance(row);
              setOpenAdjustDialog(true);
            },
            title: 'Ajustar Saldo',
            allowedActions: [UnitFinance.actions.adjustBalance],
          },
          showAdjustments: {
            icon: <ClockHistory />,
            onClick: (unitFinanceId) =>
              history.push(
                `/financial/config/financial-cash/${unitFinanceId}/adjustment-history`
              ),
            title: 'Visualizar Ajustes de Saldo',
            allowedActions: [UnitFinance.actions.getAdjustmentsHistory],
          },
        }}
        customCellRenderers={{
          expenseType: {
            renderer: (field, value, column, row) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value?.name ?? '-';
              }
            },
          },
          balanceValue: {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />

      <ExpenseTypeDialog
        open={open}
        selectedId={selectedId}
        restModel={{ update: restApiClient.unitFinance.patchUpdate }}
        onClose={() => {
          setOpen(false);
        }}
        onSuccess={() => {
          loadUnitFinances();
          setOpen(false);
        }}
      />

      <AdjustUnitFinanceBalanceDialog
        open={openAdjustDialog}
        unitFinance={unitFinance}
        onCancel={() => setOpenAdjustDialog(false)}
        onSuccess={() => {
          setOpenAdjustDialog(false);
          loadUnitFinances();
        }}
      />
    </Container>
  );
}

export default FinancialCashList;
