import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportItemWithdraw = {
  buildReportItemWithdrawPdf: (from, to, result) => {
    const doc = new jsPDF();
    let y = 0;
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(5, (y += 15), `Relatório de Baixas`);

    doc.setFontSize(12);
    doc.text(5, (y += 8), `Período: ${from} a ${to}`);
    let now = new Date();
    doc.text(
      5,
      (y += 5),
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds()
    );
    //CORPO DO PDF
    Object.keys(result).forEach(function (unit) {
      // PARA CADA UNIDADE
      y += 8;
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0);
      doc.line(5, y, 205, y);
      doc.setFontSize(12);
      doc.text(5, (y += 5), `Unidade: ${result[unit]?.name}`);
      doc.setFontSize(10);
      let bodyValue = [];
      Object.keys(result[unit].itemWithdraws).forEach(function (value) {
        const itemWithdraw = result[unit].itemWithdraws[value];
        let date = new Date(itemWithdraw.createdAt);
        bodyValue.push([
          date.toLocaleDateString(),
          itemWithdraw?.itemWithTrashed.name !== ''
            ? itemWithdraw?.itemWithTrashed.name
            : '-',
          itemWithdraw?.itemWithTrashed.brand !== ''
            ? itemWithdraw?.itemWithTrashed.brand
            : '-',
          itemWithdraw?.quantity !== '' ? itemWithdraw?.quantity : '-',
        ]);
      });
      doc.autoTable({
        startY: (y += 2),
        headStyles: { fillColor: [0, 0, 0] },
        head: [['Data', 'Item', 'Marca', 'Quantidade']],
        body: bodyValue,
        theme: 'grid',
        styles: {
          halign: 'center',
          valign: 'middle',
          fontSize: 8,
        },
        margin: 5,
      });

      y = doc.lastAutoTable.finalY;
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 295, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default ReportItemWithdraw;
