import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '../../store/ducks/auth';
import LogoImg from '../../assets/img/logo.png';

import {
  Container,
  LoginCard,
  Input,
  SubmitButton,
  Field,
  Logo,
  Form,
} from './styles';

const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const error = useSelector((state) => state.authReducer.error);

  const dispatch = useDispatch();

  return (
    <Container>
      <LoginCard>
        <Logo src={LogoImg}></Logo>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(Creators.login(login, password));
          }}
        >
          <Field>
            <Input
              error={error ? true : false}
              label={error ? 'Login incorreto' : 'Login'}
              onChange={(e) => setLogin(e.target.value)}
              variant="filled"
              required
            />
          </Field>
          <Field>
            <Input
              error={error ? true : false}
              label={error ? 'Senha incorreta' : 'Senha'}
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              type="password"
              required
            />
          </Field>
          <SubmitButton type="submit" variant="contained" color="primary">
            Login
          </SubmitButton>
        </Form>
      </LoginCard>
    </Container>
  );
};

export default Login;
