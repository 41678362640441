import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectTreatmentDialogControl from '../../components/selectTreatmentDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectTreatmentDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectTreatmentDialog'
  )
);

const customSelectTreatmentDialogControlTester = rankWith(
  4,
  isSelectTreatmentDialogControl
);

export default {
  tester: customSelectTreatmentDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectTreatmentDialogControl),
};
