import styled from 'styled-components';

export const ReceiptMain = styled.div`
  display: inline-block;
  width: 96%;
  padding: 15px;
  font-size: 12px;
  border: 1px solid #000;
  margin: 15px;
`;

export const ReceiptTitle = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
`;

export const Text = styled.p`
  font-size: 20px;
`;

export const ReceiptSection = styled.div`
  margin-top: 10px;
`;

export const ReceiptSignature = styled.div`
  height: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
