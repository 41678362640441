const AdverseExpense = {
  schema: {
    type: 'object',
    properties: {
      expenseTypeId: {
        format: 'selectExpenseTypeDialog',
        customErrors: '',
      },
      dueDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      estimatedValue: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      paymentTypeId: {
        format: 'selectPaymentTypeDialog',
        customErrors: '',
      },
      responsible: {
        format: 'selectEmployeeDialog',
        customErrors: '',
      },
      unitFinanceId: {
        format: 'selectUnitFinanceDialog',
        customErrors: '',
      },
      observation: {
        type: ['string', 'null'],
        customErrors: '',
      },
    },
    required: [
      'expenseTypeId',
      'unitId',
      'estimatedValue',
      'paymentTypeId',
      'unitFinanceId',
    ],
  },
  uischema: {
    type: 'Group',
    label: 'Cadastro de Despesa Adversa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
          {
            type: 'Control',
            label: 'Responsável',
            scope: '#/properties/responsible',
          },
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Forma de Pagamento',
            scope: '#/properties/paymentTypeId',
          },
          {
            type: 'Control',
            label: 'Vencimento',
            scope: '#/properties/dueDate',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Conta bancária',
            scope: '#/properties/unitFinanceId',
          },
          {
            type: 'Control',
            label: 'Valor',
            scope: '#/properties/estimatedValue',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Observação',
                scope: '#/properties/observation',
              },
            ],
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Despesa Adversa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
          {
            type: 'Control',
            label: 'Responsável',
            scope: '#/properties/responsible',
          },
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Forma de Pagamento',
            scope: '#/properties/paymentTypeId',
          },
          {
            type: 'Control',
            label: 'Vencimento',
            scope: '#/properties/dueDate',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Conta bancária',
            scope: '#/properties/unitFinanceId',
          },
          {
            type: 'Control',
            label: 'Valor',
            scope: '#/properties/estimatedValue',
          },
        ],
        options: {
          readonly: true,
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Observação',
                scope: '#/properties/observation',
              },
            ],
          },
        ],
      },
    ],
    rule: {
      effect: 'DISABLE',
      condition: {
        scope: '#/properties/readonly',
        schema: {
          const: true,
        },
      },
    },
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo Despesa',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'estimatedValue',
      label: 'Valor Estimado',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
  ],
  actions: {
    create: 'AdverseExpenseController@create',
    update: 'AdverseExpenseController@patchUpdate',
    findById: 'AdverseExpenseController@findById',
    getAll: 'AdverseExpenseController@getAll',
    cancelAdverseExpense: 'AdverseExpenseController@cancelAdverseExpense',
    consolidateAdverseExpense:
      'AdverseExpenseController@consolidateAdverseExpense',
    delete: 'AdverseExpenseController@delete',
  },
};

export default AdverseExpense;
