import React, { useState, useEffect, useCallback } from 'react';
import Order from '../../../../../forms/order';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  StyledTextFieldInput,
  Button,
  SaveIcon,
  StyledTypography,
} from './styles';
import { cloneDeep, isEqualWith, isEmpty } from 'lodash';
import SecuredItem from '../../../../../components/securedItem';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { CircularProgress } from '@material-ui/core';

function ItemsToOrderList({ history, match, ...props }) {
  const orderId = match.params?.id;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const columns = Order.itemListTableSchema;
  const [itemList, setItemList] = useState([]);
  const [originalItemList, setOriginalItemList] = useState([]);
  const [order, setOrder] = useState({});
  const [total, setTotal] = useState(0);
  const [fetching, setFetching] = React.useState(false);

  useEffect(() => {
    restApiClient.order.findById(orderId).then((e) => {
      const orderItem = e.data.orderItem;
      restApiClient.item
        .getAllWhereHasStockInDistributionCenter(
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('name', searchBy)
                .or()
                .like('brand', searchBy)
                .or()
                .like('barcode.barcode', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          let newRows = e.data;
          newRows.values = e.data.values.map((rowItem) => {
            const orderItemInfo = orderItem.filter(
              (item) => item.itemId === rowItem.id
            );
            if (orderItemInfo) {
              rowItem.orderQuantity = orderItemInfo[0]?.quantity;
            }
            rowItem.subtotal = (rowItem.orderQuantity ?? 0) * rowItem.price;
            return rowItem;
          });
          setRows(newRows);
        });
    });
  }, [rowsPerPage, page, orderBy, searchBy, orderId]);

  useEffect(() => {
    if (isEmpty(order)) {
      restApiClient.order.findById(orderId).then((e) => {
        setOrder(e.data);
        setOriginalItemList(e.data.orderItem);
        setItemList(cloneDeep(e.data.orderItem));
        setTotal(
          e.data.orderItem.reduce((accumulator, currentValue) => {
            return (
              accumulator + currentValue.quantity * currentValue?.item?.price
            );
          }, 0)
        );
      });
    }
  }, [orderId, order]);

  const saveListItems = async () => {
    setFetching(true);
    const added = itemList
      .filter(
        (item) =>
          !originalItemList.some((obj) =>
            isEqualWith(
              obj,
              item,
              (obj1, obj2) =>
                obj1.itemId === obj2.itemId &&
                obj1.quantity === obj2.quantity &&
                obj1.orderId === obj2.orderId
            )
          )
      )
      .filter((item) => item.quantity > 0)
      .map(({ orderId, itemId, quantity }) => ({
        orderId,
        itemId,
        quantity,
      }));

    const removed = originalItemList
      .filter(
        (item) =>
          !itemList.some((obj) =>
            isEqualWith(
              obj,
              item,
              (obj1, obj2) =>
                obj1.itemId === obj2.itemId &&
                obj1.quantity === obj2.quantity &&
                obj1.orderId === obj2.orderId
            )
          )
      )
      .map((item) => item.id);
    await restApiClient.order.updateByClinic(orderId, '', '', added, removed);
  };

  const handleAlertClose = useCallback(() => {
    setSuccessMessage('');
  }, []);

  return (
    <Container>
      <CrudTable
        title={'Adição de Itens ao Pedido'}
        customFooterComponent={
          <>
            <SecuredItem allowedActions={[Order.actions.updateByClinic]}>
              {fetching ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    saveListItems().then(() => {
                      setFetching(false);
                      setOrder({});
                      setSuccessMessage('Lista salva com sucesso.');
                    });
                  }}
                >
                  Salvar
                </Button>
              )}
            </SecuredItem>
            <SecuredItem allowedActions={[Order.actions.updateByClinic]}>
              {fetching ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    saveListItems().then(() => {
                      setFetching(false);
                      history.goBack();
                    });
                  }}
                >
                  Salvar e Voltar
                </Button>
              )}
            </SecuredItem>
          </>
        }
        modelActions={Order.actions}
        columns={columns}
        customColumns={[
          {
            id: 'orderQuantity',
            label: 'Quantidade Pedido',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'subtotal',
            label: 'Subtotal',
            minWidth: 50,
            align: 'center',
          },
        ]}
        customInfosComponent={
          <StyledTypography variant="h6">
            Valor total:{' '}
            {total?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledTypography>
        }
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          quantity: {
            renderer: (field, value, column, row) => {
              return value ?? '-';
            },
          },
          orderQuantity: {
            renderer: (field, value, column, row) => {
              let itemFind = itemList.find((x) => x.itemId === row.id);
              let quantity = '';
              if (itemFind)
                quantity = itemFind?.quantity === 0 ? '' : itemFind?.quantity;
              else quantity = value;
              return (
                <StyledTextFieldInput
                  value={quantity}
                  onChange={(e) => {
                    let item = null;
                    const newList = [...itemList];
                    if ((item = newList.find((x) => x.itemId === row.id))) {
                      item.quantity = parseInt(
                        e.target.value !== '' ? e.target.value : 0
                      );
                    } else {
                      const item = {
                        itemId: row.id,
                        quantity: parseInt(
                          e.target.value !== '' ? e.target.value : 0
                        ),
                        orderId: orderId,
                        price: row.price,
                      };
                      newList.push(item);
                    }

                    row.quantity =
                      e.target.value !== '' ? parseInt(e.target.value) : 0;
                    row.subtotal = row.quantity * row?.price;
                    setTotal(
                      newList.reduce((accumulator, currentValue) => {
                        return (
                          accumulator +
                          currentValue.quantity *
                            (currentValue.item
                              ? currentValue?.item.price
                              : currentValue?.price)
                        );
                      }, 0)
                    );
                    setItemList(newList);
                  }}
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                />
              );
            },
          },
          barcode: {
            renderer: (field, value, column, row) => {
              return value.map((item) => item.barcode).join(', ');
            },
          },
          stock: {
            renderer: (field, value) => {
              return value[0]?.availableQuantity ?? 0;
            },
          },
          price: {
            renderer: (field, value, column, row) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          subtotal: {
            renderer: (field, value, column, row) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />

      <Snackbar
        open={!!successMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ItemsToOrderList;
