/* eslint-disable no-unused-expressions */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  Container,
  DialogContent,
  StyledCreateUpdateForm,
  CancelButton,
  SubmitButton,
} from './styles';
import restApiClient from '../../services/restApiClient';
import first from 'lodash/first';
import moment from 'moment';
import { useSelector } from 'react-redux';

function DentistWorkedHourDialog({
  onSuccess,
  onError,
  onCancel,
  dentistWorkedHour,
  dentistWorkedHourId,
  actionSubmit,
  ...props
}) {
  const dentist = useSelector((state) => state.authReducer?.user);
  const endTime = actionSubmit ? moment(new Date()).format('HH:mm') : null;

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={dentistWorkedHour}
            schemaSelector={(modelSchema) =>
              actionSubmit ? modelSchema.submitSchema : modelSchema.schema
            }
            uischemaSelector={(modelSchema, modelId) =>
              actionSubmit
                ? modelSchema.submitUiSchema
                : modelId
                ? modelSchema.updateUischema
                : modelSchema.uischema
            }
            restGetDataById={async (id) => {
              const result = await restApiClient.dentistWorkedHour.findById(id);
              return result;
            }}
            restCreateData={restApiClient.dentistWorkedHour.create}
            restUpdateData={async (modelId, updateOriginalData, modelData) => {
              delete modelData.requestingUnitBoolean;
              delete updateOriginalData.requestingUnitBoolean;

              return restApiClient.dentistWorkedHour.update(
                modelId,
                updateOriginalData,
                modelData
              );
            }}
            onCreateSuccess={(request) => {
              onSuccess?.(request);
            }}
            onUpdateSuccess={async (request, data) => {
              if (data === 'submit') {
                await restApiClient.dentistWorkedHour
                  .setSubmitted(request.data.id)
                  .then()
                  .catch((e) => {
                    onError?.(
                      first(e.response.data.errors?.attendanceDate) ??
                        e.response.data.errors?.dentistWorkedHour
                    );
                  });
              }
              onSuccess?.(request);
            }}
            modelData={{
              dentistId: dentist?.id,
              unitId: dentist?.favoriteUnitId,
              endTime: endTime,
              isSubstitute: false,
            }}
            onChange={(data) => {
              if (!data.isSubstitute && data.status !== 'Em Edição')
                data.replacedDentistId = undefined;
            }}
            onError={(errors) => {
              onError?.(
                first(errors?.attendanceDate) ?? errors?.dentistWorkedHour
              );
            }}
            forceId={dentistWorkedHourId}
            onCancelClick={onCancel}
            customButtonsBuilder={
              actionSubmit
                ? (submit) => (
                    <>
                      <CancelButton
                        onClick={() => onCancel?.()}
                        type="button"
                        variant="contained"
                        color="primary"
                      >
                        Cancelar
                      </CancelButton>
                      <SubmitButton
                        type="button"
                        variant="contained"
                        color="primary"
                        id="submit"
                        onClick={() => submit('submit')}
                      >
                        {'Submeter'}
                      </SubmitButton>
                    </>
                  )
                : null
            }
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default DentistWorkedHourDialog;
