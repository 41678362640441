import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { Container } from './styles';

function SelectList({
  data,
  dataFilter,
  checked,
  labelSelector,
  onChange,
  className,
}) {
  return (
    <Container className={className}>
      <List dense>
        {data?.map((value, index, arr) =>
          !dataFilter || dataFilter(value, index, arr) ? (
            <ListItem
              key={`${labelSelector?.(value) ?? value}_${index}`}
              button
              onClick={() => {
                const currentIndex = checked?.indexOf(index);
                const newChecked = [...checked];

                if (!checked?.includes(index)) {
                  newChecked.push(index);
                } else {
                  newChecked.splice(currentIndex, 1);
                }

                onChange(newChecked);
              }}
            >
              <ListItemIcon>
                <Checkbox checked={checked?.includes(index)} />
              </ListItemIcon>
              <ListItemText
                id={`transfer-list-item-${value}-label`}
                primary={labelSelector?.(value) ?? value}
              />
            </ListItem>
          ) : null
        )}
        <ListItem />
      </List>
    </Container>
  );
}

export default SelectList;
