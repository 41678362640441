import styled from 'styled-components';
import {
  Button,
  Card as MaterialCard, CardHeader as MaterialCardHeader,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogTitle as MaterialDialogTitle,
  FormControl as MaterialFormControl,
  FormControlLabel as MaterialFormControlLabel,
  FormLabel as MaterialFormLabel,
  Radio as MaterialRadio,
  RadioGroup as MaterialRadioGroup,
  FormGroup as MaterialFormGroup
} from '@material-ui/core';

export const SubmitButton = styled(Button)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(Button)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Dialog = styled(MaterialDialog)``;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  width: 450px !important;
  padding: 15px !important;
`;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 0 !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px 10px 10px;
  min-height: 150px;
`;

export const CardHeader = styled(MaterialCardHeader)``;

export const FormControl = styled(MaterialFormControl)`
`;

export const FormLabel = styled(MaterialFormLabel)``;

export const FormControlLabel = styled(MaterialFormControlLabel)``;

export const Radio = styled(MaterialRadio)``;

export const RadioGroup = styled(MaterialRadioGroup)``;

export const FormGroup = styled(MaterialFormGroup)``;


