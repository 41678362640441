/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { isEmpty } from 'lodash';
import {
  Container,
  ActionsContainer,
  SubmitButton,
  Input,
  ContentContainer,
  CancelButton,
  DialogContent,
  CardHeader,
  HeaderContainer,
  Card,
  DialogActions,
} from './styles';
import restApiClient from '../../services/restApiClient';

function WithdrawClinicStockDialog({
  onSuccess,
  onError,
  onCancel,
  item,
  ...props
}) {
  const [requestItem, setRequestItem] = useState(item);
  const [quantity, setQuantity] = useState(item);
  const [errors, setErrors] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    restApiClient.itemWithdraw
      .withdrawItem(requestItem)
      .then((x) => {
        onSuccess?.();
        setQuantity('');
        setRequestItem({});
        setErrors('');
      })
      .catch((x) => {
        if (x.response?.status === 400) {
          onError?.(x.response);
          setErrors(x.response.data.errors);
          console.log(x.response.data.errors);
        }
      });
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Dar baixa do item" />
              </HeaderContainer>
              <ContentContainer>
                <Input
                  required
                  type="number"
                  label="Quantidade"
                  value={quantity}
                  InputProps={{ inputProps: { min: 1 } }}
                  onChange={(e) => {
                    setErrors('');
                    const crrItem = item;
                    crrItem.withdrawQuantity = e.target.value;
                    setQuantity(e.target.value);
                    setRequestItem(crrItem);
                  }}
                  error={!isEmpty(errors)}
                  helperText={!isEmpty(errors) ? errors : ''}
                />
              </ContentContainer>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setErrors('');
                    setQuantity('');
                    setRequestItem({});
                    onCancel?.();
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Ajustar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </Container>
  );
}

export default WithdrawClinicStockDialog;
