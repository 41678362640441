import React, { useState, useEffect, useCallback } from 'react';
import Item from '../../../forms/item';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, PackageIcon, Restore } from './styles';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

function DisabledItemsList() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = Item.disableTableSchema;

  const loadItems = useCallback(() => {
    restApiClient.item
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftOp, rightOp) => {
                leftOp.notNull('deletedAt');
                rightOp
                  .like('id', searchBy)
                  .or()
                  .like('name', searchBy)
                  .or()
                  .like('brand', searchBy)
                  .or()
                  .like('barcode.barcode', searchBy);
              })
              .toString()
          : new FilterTree().notNull('deletedAt').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <Container>
      <CrudTable
        title={'Itens Desativados'}
        modelActions={Item.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          restore: {
            icon: <Restore />,
            onClick: (modelId) => {
              restApiClient.item.restore(modelId).then(() => loadItems());
            },
            title: 'Ativar',
            selector: (row) => row?.deletedAt,
            allowedActions: [Item.actions.restore],
          },
        }}
        customCellRenderers={{
          hasConversion: {
            renderer: (field, value) => {
              return value ? <PackageIcon /> : '-';
            },
          },
          barcode: {
            renderer: (field, value) => {
              return !isEmpty(value)
                ? value.map((item) => item.barcode).join(', ')
                : '-';
            },
          },
          deletedAt: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default DisabledItemsList;
