import React, { useState, useEffect } from 'react';
import Outsourced from '../../../../forms/outsourced';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, Table, Close, Restore } from './styles';
import Step from '../../../../forms/step';

function OutsourcedList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ socialName: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Outsourced.tableSchema;

  useEffect(() => {
    restApiClient.outsourced
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('socialName', searchBy)
              .or()
              .like('fantasyName', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Terceirizados'}
        modelActions={Outsourced.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => history.push('/outsourced/create'),
          },
          update: {
            onClick: (outsourcedId) =>
              history.push(`/outsourced/update/${outsourcedId}`),
            selector: (row) => !row?.deletedAt,
          },
          priceTable: {
            icon: <Table />,
            onClick: (outsourcedId, row) => {
              history.push({
                pathname: `/outsourced/priceTableList/${outsourcedId}`,
                state: {
                  name: row?.isPhysicalPerson
                    ? row?.socialName
                    : row?.fantasyName,
                },
              });
            },
            allowedActions: [
              [
                Outsourced.actions.update,
                Outsourced.actions.findById,
                Step.actions.getAll,
              ],
            ],
            title: 'Tabela de Preços',
            selector: (row) => !row?.deletedAt,
          },
          customDelete: {
            icon: <Close />,
            onClick: (outsourcedId, index) => {
              restApiClient.outsourced.delete(outsourcedId).then(() => {
                const result = { ...rows };
                const restored = result.values.find(
                  (x) => x.addressId === outsourcedId
                );
                restored.deletedAt = 'datanova';
                setRows(result);
              });
            },
            title: 'Desativar',
            selector: (row) => !row?.deletedAt,
            allowedActions: [[Outsourced.actions.delete]],
          },
          restore: {
            icon: <Restore />,
            onClick: (supplierId, index) => {
              restApiClient.outsourced.restore(supplierId).then(() => {
                const result = { ...rows };
                const restored = result.values.find(
                  (x) => x.addressId === supplierId
                );
                restored.deletedAt = null;
                setRows(result);
              });
            },
            title: 'Ativar',
            selector: (row) => row?.deletedAt,
            allowedActions: [[Outsourced.actions.restore]],
          },
        }}
        customCellRenderers={{
          fantasyName: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default OutsourcedList;
