import React, { useEffect, useState } from 'react';
import RadiologyConsumption from '../../../../../forms/radiologyConsumption';
import CrudTable from '../../../../../components/crudTable';
import { BackButton, Container, StyledTypography } from './styles';
import restApiClient from '../../../../../services/restApiClient';
import FilterTree from '../../../../../helpers/filterTree';
import moment from 'moment/moment';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

function ManageRadiologyConsumptionUnitClinic(props) {
  const unitId = useSelector((x) => x.authReducer.user.unitId);
  const radiologyConsumptionId = parseInt(props.match?.params?.id ?? -1);
  const beginDate = props.location?.state?.beginDate;
  const endDate = props.location?.state?.endDate;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [total, setTotal] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const columns = RadiologyConsumption.radiologyConsumptionValuesTableSchema;

  useEffect(() => {
    restApiClient.radiologyConsumptionValue
      .getAllByRadiologyConsumptionId(
        radiologyConsumptionId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('origin.name', searchBy).toString()
          : null,
        orderBy
      )
      .then((result) => {
        if (result.data.count > 0) {
          let newRows = result.data;
          newRows.values = result.data.values.map((item) => ({
            ...item,
            name: item.origin.name,
          }));

          if (isEmpty(itemList)) setItemList(cloneDeep(newRows.values));
          setOriginalList(newRows.values);
          setRows(newRows);
        } else {
          restApiClient.radiologyConsumption
            .getAllOriginsConsumptionByPeriod(
              moment(beginDate).format('DD-MM-YYYY'),
              moment(endDate).format('DD-MM-YYYY'),
              unitId,
              rowsPerPage,
              page * rowsPerPage,
              searchBy
                ? new FilterTree().like('name', searchBy).toString()
                : null,
              orderBy
            )
            .then((e) => {
              let newRows = e.data;
              newRows.values = e.data.values.map((item) => ({
                ...item,
                id: undefined,
                originId: item.id,
                correction: 0,
              }));

              if (isEmpty(itemList)) setItemList(cloneDeep(newRows.values));
              setOriginalList(newRows.values);
              setRows(newRows);
            });
        }
      });
  }, [
    rowsPerPage,
    page,
    orderBy,
    searchBy,
    beginDate,
    endDate,
    unitId,
    radiologyConsumptionId,
  ]);

  useEffect(() => {
    setTotal(
      sumBy(originalList, (item) => {
        let obj, subtotal;
        if ((obj = itemList.find((i) => i.originId === item.originId))) {
          subtotal = parseFloat(obj.subtotal ?? 0);
        } else {
          subtotal = 0;
        }
        return subtotal;
      })
    );
  }, [originalList, itemList]);

  return (
    <Container>
      <CrudTable
        title={`Consumo - ${moment(beginDate).format('DD/MM/YYYY')} à ${moment(
          endDate
        ).format('DD/MM/YYYY')}`}
        customInfosComponent={
          <StyledTypography variant="h6">
            Valor total:{' '}
            {(!isNaN(total) ? total : 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledTypography>
        }
        customFooterComponent={
          <BackButton
            onClick={() => props.history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Voltar
          </BackButton>
        }
        modelActions={RadiologyConsumption.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        availableRowsPerPage={[15]}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          value: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          correction: {
            renderer: (field, value, column, row) => {
              row.correction = parseFloat(row.correction ?? 0);
              let itemFind = itemList.find((x) => x.originId === row.originId);
              let correction = 0;
              if (itemFind) correction = itemFind?.correction;

              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          subtotal: {
            renderer: (field, value, column, row) => {
              let subtotal;
              let itemFind = itemList.find((x) => x.originId === row.originId);
              if (itemFind) {
                subtotal = parseFloat(itemFind.subtotal);
              } else {
                subtotal = value;
              }

              return (!isNaN(subtotal) ? subtotal : 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
    </Container>
  );
}

export default ManageRadiologyConsumptionUnitClinic;
