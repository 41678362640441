import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectSectorDialogControl from '../../components/selectSectorDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectSectorDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectSectorDialog'
  )
);

const customSelectSectorDialogControlTester = rankWith(
  4,
  isSelectSectorDialogControl
);

export default {
  tester: customSelectSectorDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectSectorDialogControl),
};
