const Substitute = {
  schema: {
    type: 'object',
    properties: {
      substituteDentist: {
        type: 'number',
        format: 'selectDentist',
        customErrors: '',
      },
      replacedDentist: {
        type: 'number',
        format: 'selectDentist',
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      replacementDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
    },
    required: [
      'substituteDentist',
      'replacedDentist',
      'unitId',
      'value',
      'replacementDate',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Substituto',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Dentista Substituto',
                scope: '#/properties/substituteDentist',
              },
              {
                type: 'Control',
                label: 'Dentista Substituído',
                scope: '#/properties/replacedDentist',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Unidade',
                scope: '#/properties/unitId',
                rule: {
                  effect: 'DISABLE',
                  condition: {
                    scope: '#/properties/isClinic',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Valor',
                scope: '#/properties/value',
              },
              {
                type: 'Control',
                label: 'Data da Substituição',
                scope: '#/properties/replacementDate',
              },
            ],
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Edição de Substituto',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Dentista Substituto',
                scope: '#/properties/substituteDentist',
              },
              {
                type: 'Control',
                label: 'Dentista Substituído',
                scope: '#/properties/replacedDentist',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Unidade',
                scope: '#/properties/unitId',
                rule: {
                  effect: 'DISABLE',
                  condition: {
                    scope: '#/properties/isClinic',
                    schema: {
                      const: true,
                    },
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Valor',
                scope: '#/properties/value',
              },
              {
                type: 'Control',
                label: 'Data da Substituição',
                scope: '#/properties/replacementDate',
              },
            ],
          },
        ],
      },
    ],
  },
  uischemaAlternative: {
    type: 'Group',
    label: 'Visualização de Substituto',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Dentista Substituto',
                scope: '#/properties/substituteDentist',
              },
              {
                type: 'Control',
                label: 'Dentista Substituído',
                scope: '#/properties/replacedDentist',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Unidade',
                scope: '#/properties/unitId',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Valor',
                scope: '#/properties/value',
              },
              {
                type: 'Control',
                label: 'Data da Substituição',
                scope: '#/properties/replacementDate',
              },
            ],
          },
        ],
      },
    ],
    options: {
      readonly: true,
    },
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    {
      id: 'substituteDentist.user.name',
      label: 'Substituto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'replacedDentist.user.name',
      label: 'Substituído',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'replacementDate',
      label: 'Data Substituição',
      minWidth: 25,
      align: 'center',
    },
  ],
  alternativeTableSchema: [
    {
      id: 'substituteDentist.user.name',
      label: 'Substituto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'replacedDentist.user.name',
      label: 'Substituído',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'replacementDate',
      label: 'Data Substituição',
      minWidth: 25,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'SubstituteController@getAll',
    getAllInClinic: 'SubstituteController@getAllInClinic',
    getAllByReplacement: 'SubstituteController@getAllByReplacement',
    findById: 'SubstituteController@findById',
    create: 'SubstituteController@create',
    update: 'SubstituteController@patchUpdate',
    delete: 'SubstituteController@delete',
  },
};

export default Substitute;
