import React from 'react';
import { Container } from './styles';
import Buyer from '../../../forms/buyer';
import restApiClient from '../../../services/restApiClient';
import CreateUpdateForm from '../../../components/createUpdateForm';
import { isEqualWith } from 'lodash';

function BuyerCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Buyer}
        restGetDataById={restApiClient.buyer.findById}
        restCreateData={restApiClient.buyer.create}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          const added = modelData.buyerAddresses.filter(
            (item) =>
              !updateOriginalData.buyerAddresses.some((obj) =>
                isEqualWith(
                  obj,
                  item,
                  (obj1, obj2) =>
                    obj1.cep === obj2.cep &&
                    obj1.address === obj2.address &&
                    obj1.number === obj2.number &&
                    obj1.complement === obj2.complement &&
                    obj1.neighborhood === obj2.neighborhood &&
                    obj1.city === obj2.city &&
                    obj1.state === obj2.state
                )
              )
          );

          const removed = updateOriginalData.buyerAddresses
            .filter(
              (item) =>
                !modelData.buyerAddresses.some((obj) =>
                  isEqualWith(
                    obj,
                    item,
                    (obj1, obj2) =>
                      obj1.cep === obj2.cep &&
                      obj1.address === obj2.address &&
                      obj1.number === obj2.number &&
                      obj1.complement === obj2.complement &&
                      obj1.neighborhood === obj2.neighborhood &&
                      obj1.city === obj2.city &&
                      obj1.state === obj2.state
                  )
                )
            )
            .map((item) => item.id);
          delete modelData.buyerAddresses;
          delete modelData.asyncModelDataUpdate;
          delete modelData.dentistId;
          delete updateOriginalData.buyerAddresses;
          const result = await restApiClient.buyer.update(
            modelId,
            updateOriginalData,
            modelData,
            added,
            removed
          );
          return result;
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default BuyerCreateUpdate;
