import styled from 'styled-components';
import {
  Button as MaterialButton,
  Typography as MaterialTypography,
} from '@material-ui/core';
import { Save as MaterialSaveIcon } from '../../../../../styles/icons';
import MoneyInput from '../../../../../components/moneyInput';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const BackButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 100px !important;
  text-align: center !important;
`;

export const StyledTypography = styled(MaterialTypography)`
  margin-left: 20px !important;
`;

export const Button = styled(MaterialButton)`
  color: #ffffff !important;
`;

export const SaveIcon = styled(MaterialSaveIcon)`
  width: 25px;
  height: 25px;
`;
