const Budget = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
      expectedDuration: {
        type: 'integer',
        minimum: 0,
        customErrors: '',
      },
      expectedDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      supplierId: {
        format: 'selectSupplierDialog',
        customErrors: '',
      },
    },
    required: ['name', 'expectedDuration', 'expectedDate'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Orçamento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Prevista Compra',
            scope: '#/properties/expectedDate',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Duração + Margem',
            scope: '#/properties/expectedDuration',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Fornecedor',
            scope: '#/properties/supplierId',
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Atualização de Orçamento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Prevista de Compra',
            scope: '#/properties/expectedDate',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Duração + Margem',
            scope: '#/properties/expectedDuration',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Fornecedor',
            scope: '#/properties/supplierId',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
    {
      id: 'expectedDate',
      label: 'Data Prevista de Compra',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'supplier',
      label: 'Fornecedor',
      minWidth: 100,
      align: 'center',
    },
    { id: 'total', label: 'Valor', minWidth: 100, align: 'center' },
  ],
  dialogTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'supplier',
      label: 'Fornecedor',
      minWidth: 100,
      align: 'center',
    },
  ],
  itemListTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome do Item', minWidth: 100, align: 'center' },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    {
      id: 'quantity',
      label: 'Quantidade Atual',
      minWidth: 100,
      align: 'center',
    },
  ],
  itemListViewTableSchema: [
    {
      id: 'item.name',
      label: 'Nome do Item',
      minWidth: 100,
      align: 'center',
    },
    { id: 'item.brand', label: 'Marca', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'BudgetController@getAll',
    getAllBudgetsInEditing: 'BudgetController@getAllBudgetsInEditing',
    findById: 'BudgetController@findById',
    costShare: 'BudgetController@costShare',
    create: 'BudgetController@create',
    makePurchase: 'BudgetController@makePurchase',
    importItems: 'BudgetController@importItems',
    update: 'BudgetController@patchUpdate',
    delete: 'BudgetController@delete',
    getCsvCosts: 'BudgetController@getCsvCosts',
  },
};

export default Budget;
