import styled from 'styled-components';
import {
  Box as MaterialBox,
  AddBox as MaterialAddBox,
} from '../../../styles/icons';
import { Button as MaterialButton } from '@material-ui/core';
import { Save as MaterialSaveIcon } from '@styled-icons/material/Save';
import PercentageInput from '../../../components/percentageInput';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CancelButton = styled(MaterialButton)`
  width: 30%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Button = styled(MaterialButton)`
  color: #ffffff !important;
`;

export const SaveIcon = styled(MaterialSaveIcon)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const StyledPercentageInput = styled(PercentageInput)`
  width: 70px;
  text-align: center !important;
`;
