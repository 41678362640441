import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'moment/locale/pt-br';

dayjs.locale('pt-br');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
