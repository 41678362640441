import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectGroupDialogControl from '../../components/selectGroupDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectGroupDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectGroupDialog'
  )
);

const customSelectGroupDialogControlTester = rankWith(
  4,
  isSelectGroupDialogControl
);

export default {
  tester: customSelectGroupDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectGroupDialogControl),
};
