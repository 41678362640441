import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MaterialDialogTitle from '@material-ui/core/DialogTitle';
import { ClipboardCopy as MaterialClipboardCopy } from '../../styles/icons';

export const Container = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  width: 250px;
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PasswordSpan = styled.span`
  text-align: center;
  background-color: #f0faff;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px;
  font-size: 30px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(Button)`
  width: 80%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  text-align: center;
`;

export const ClipboardCopy = styled(MaterialClipboardCopy)`
  width: 35px;
  height: 35px;

  color: var(--primary);
`;
