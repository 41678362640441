import styled from 'styled-components';
import { BoxRemove as MaterialBoxRemove } from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const BoxRemove = styled(MaterialBoxRemove)`
  width: 27px;
  height: 27px;
  fill: var(--secondary);
`;
