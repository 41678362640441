import React from 'react';
import {
  CenterContainer,
  DottedLine,
  Grid,
  TextStyledH5,
  TextTitle,
  BoldTextStyledH5,
  TextSubtitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from './styles';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

function SummaryTicketCashRegister({ cashRegister }) {
  const payments = cashRegister?.payments ?? [];

  return (
    <CenterContainer>
      <TextTitle>{'OdontoCG'}</TextTitle>
      <DottedLine />
      <TextSubtitle>{'FECHAMENTO DE CAIXA'}</TextSubtitle>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <BoldTextStyledH5>
            {`Caixa: ${cashRegister?.numberCashRegister}`}
          </BoldTextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Data do Caixa: ${new Date(
              `${cashRegister?.date}T03:24:00`
            ).toLocaleDateString('pt-br')}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>{`Unidade: ${cashRegister?.unit?.name}`}</TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Usuário: ${cashRegister?.employee?.user?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Envio do Caixa: ${new Date(
              `${cashRegister?.sendDate}`
            ).toLocaleDateString('pt-br', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })}`}
          </TextStyledH5>
        </Grid>
      </Grid>
      <DottedLine />
      <TextSubtitle>{'RESUMO'}</TextSubtitle>
      <Table>
        <TableBody size="small">
          {map(payments, (obj, index) => {
            return (
              <TableRow key={index}>
                <TableCell style={{ width: 200 }}>
                  <TextStyledH5>{`${obj?.name}`}</TextStyledH5>
                </TableCell>
                <TableCell align="left">
                  <TextStyledH5>
                    {`${obj?.value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </TextStyledH5>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow key={cashRegister?.id} style={{ height: 0 }}>
            <TableCell style={{ borderBottom: 'none' }}>
              <BoldTextStyledH5>{'TOTAL'}</BoldTextStyledH5>
            </TableCell>
            <TableCell align="left" style={{ borderBottom: 'none' }}>
              <BoldTextStyledH5>
                {`${sumBy(payments, 'value')?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}
              </BoldTextStyledH5>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DottedLine />
    </CenterContainer>
  );
}

export default SummaryTicketCashRegister;
