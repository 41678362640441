import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Container,
  ActionsContainer,
  ContentContainer,
  PasswordContainer,
  PasswordSpan,
  DialogTitle,
  CloseButton,
  ClipboardCopy,
} from './styles';
import { IconButton } from '@material-ui/core';

function ShowPassDialog({ password, onClose, ...props }) {
  const [passwordCopied, setPasswordCopied] = useState(false);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-title">Senha do novo usuário</DialogTitle>
        <DialogContent>
          <ContentContainer>
            <PasswordContainer>
              <PasswordSpan>{password}</PasswordSpan>
              <CopyToClipboard
                text={password}
                onCopy={() => setPasswordCopied(true)}
              >
                <IconButton>
                  <ClipboardCopy />
                </IconButton>
              </CopyToClipboard>
            </PasswordContainer>
            {passwordCopied ? (
              <Alert severity="success">Senha copiada com sucesso</Alert>
            ) : null}
          </ContentContainer>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CloseButton onClick={onClose} autoFocus>
              Fechar
            </CloseButton>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ShowPassDialog;
