import React, { useState, useEffect, useCallback } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, StyledMoneyInput, Button, SaveIcon } from './styles';
import Procedure from '../../../../../forms/procedure';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { cloneDeep, first, isEmpty, isEqualWith } from 'lodash';

function ValueProceduresList(props) {
  const unitId = parseInt(props.match.params?.unitId) ?? -1;
  const dentist = props.location?.state?.dentist;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [dentistProcedureList, setDentistProcedureList] = useState([]);
  const [originalDentistProcedureList, setOriginalDentistProcedureList] =
    useState([]);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const columns = Procedure.dentistProceduresTableSchema;

  const loadProcedures = useCallback(() => {
    restApiClient.procedure
      .getAllBySpecialtyDentist(
        dentist?.id,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        restApiClient.dentistProcedurePrice
          .getAllByDentistAndUnit(dentist?.id, unitId)
          .then((x) => {
            newRows.values = e.data.values.map((row) => {
              const procedureInfo = x.data.procedures.filter(
                (dentistProcedure) => dentistProcedure.procedureId === row.id
              );
              if (!isEmpty(procedureInfo)) {
                row.newPrice = parseFloat(first(procedureInfo)?.price);
              } else {
                row.newPrice = row.price;
              }
              return row;
            });
            setDentistProcedureList(x.data.procedures);
            setOriginalDentistProcedureList(cloneDeep(x.data.procedures));
            setRows(newRows);
          });
      });
  });

  useEffect(() => {
    loadProcedures();
  }, [rowsPerPage, page, orderBy, searchBy]);

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const salvar = async () => {
    const added = dentistProcedureList
      .filter(
        (dentistProcedure) =>
          !originalDentistProcedureList.some((obj) =>
            isEqualWith(
              obj,
              dentistProcedure,
              (obj1, obj2) =>
                obj1.id === obj2.id &&
                obj1.dentistId === obj2.dentistId &&
                obj1.unitId === obj2.unitId &&
                obj1.procedureId === obj2.procedureId &&
                obj1.price === obj2.price
            )
          )
      )
      .filter((item) => item.price > 0);

    const removed = originalDentistProcedureList
      .filter(
        (dentistProcedure) =>
          !dentistProcedureList.some((obj) =>
            isEqualWith(
              obj,
              dentistProcedure,
              (obj1, obj2) =>
                obj1.id === obj2.id &&
                obj1.dentistId === obj2.dentistId &&
                obj1.unitId === obj2.unitId &&
                obj1.procedureId === obj2.procedureId &&
                obj1.price === obj2.price
            )
          )
      )
      .map((dentistProcedure) => dentistProcedure.id);

    if (!isEmpty(added) || !isEmpty(removed)) {
      restApiClient.dentist
        .updateDentistProcedure(dentist?.id, dentist, dentist, added, removed)
        .then((result) => {
          if (result.status === 200) {
            loadProcedures();
            setOriginalDentistProcedureList([]);
            setAlert({
              message:
                'Os valores dos procedimentos foram alterados com sucesso!',
              severity: 'success',
            });
          }
        });
    }
  };
  return (
    <Container>
      <CrudTable
        title={`Procedimentos - ${dentist?.name}`}
        modelActions={Procedure.actions}
        columns={columns}
        customFooterComponent={
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={salvar}
            >
              Salvar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={async (e) => {
                await salvar(e);
                props.history.goBack();
              }}
            >
              Salvar e Voltar
            </Button>
          </>
        }
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{}}
        customCellRenderers={{
          price: {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          newPrice: {
            renderer: (field, value, column, row) => {
              row.newPrice = parseFloat(row.newPrice);
              let procedureFind = dentistProcedureList.find(
                (x) => x.procedureId === row.id
              );
              let newPrice = 0;
              if (procedureFind) newPrice = procedureFind?.price;
              else newPrice = value;
              return (
                <StyledMoneyInput
                  value={newPrice}
                  onChange={(e, value) => {
                    let procedure = null;
                    const newList = [...dentistProcedureList];
                    if (
                      (procedure = newList.find(
                        (x) => x.procedureId === row.id
                      ))
                    ) {
                      procedure.price = value;
                    } else {
                      const procedure = {
                        procedureId: row.id,
                        dentistId: dentist?.id,
                        unitId: unitId,
                        price: value,
                      };
                      newList.push(procedure);
                    }
                    row.newPrice = value;
                    setDentistProcedureList(newList);
                  }}
                />
              );
            },
          },
        }}
      />

      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ValueProceduresList;
