import axios from 'axios';

const client = axios.create({
  baseURL: 'https://viacep.com.br/ws',
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const viaCepClient = {
  find: async (cep) => {
    const result = await client.get(`/${cep}/json`);
    return result.data;
  },
};

export default viaCepClient;
