import React, { useState, useEffect } from 'react';
import { Container, AutoDiv, StyledCarrousel } from './styles';
import ClinicOrderServiceStepCreateUpdate from '../createUpdateStep';
import restApiClient from '../../../../../../services/restApiClient';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orderBy } from 'lodash';

function ClinicCreateUpdateStepCarousel({ ...props }) {
  const [orderServiceStep, setOrderServiceStep] = useState([]);
  const [endProductId, setEndProductId] = useState([]);
  const [fetching, setFetching] = useState(false);
  const orderServiceId = props.match.params?.orderServiceId ?? -1;
  const workflowPosition = props.match.params?.workflowPosition ?? -1;

  useEffect(() => {
    setFetching(true);
    restApiClient.orderServiceStep
      .getAllByOrderServiceId(orderServiceId, { id: 'desc' })
      .then((e) => {
        setOrderServiceStep(orderBy(e.data.values, 'workflowPosition', 'desc'));
        const crr = e.data.values.find((x) => x.current);
        if (crr) {
          setEndProductId(crr.orderService.endProduct.id);
        }
        setFetching(false);
      });
  }, [orderServiceId, workflowPosition]);

  return (
    <Container>
      {fetching ? (
        <AutoDiv>
          <CircularProgress size={60} />
        </AutoDiv>
      ) : (
        <StyledCarrousel autoPlay={false}>
          {orderServiceStep.map((row) => {
            return (
              <ClinicOrderServiceStepCreateUpdate
                key={row.id}
                crrId={row.id}
                crrEndProductId={endProductId}
                crrViewForm={
                  !(
                    row.status === 'Agrupado' ||
                    row.status === 'Submetida em Grupo' ||
                    row.status === 'Submetida (Clínica)' ||
                    row.status === 'Retrabalho (Clínica)'
                  )
                }
                crrOrderServiceId={orderServiceId}
                crrWorkflowPosition={row.workflowPosition}
                crrOrderServiceStep={row}
                crrPatientName={row.orderService?.patient?.name}
                crrRecordNumber={row.orderService?.patient?.recordNumber}
                history={props.history}
                match={props.match}
                location={{
                  state: { date: row.createdAt, dentistId: row.dentistId },
                }}
              />
            );
          })}
        </StyledCarrousel>
      )}
    </Container>
  );
}

export default ClinicCreateUpdateStepCarousel;
