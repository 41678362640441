import React, { useState, useEffect, useCallback, useRef } from 'react';
import CashRegisterClose from '../../../../forms/cashRegisterClose';
import CashRegisterValue from '../../../../forms/cashRegisterValue';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  Preview,
  CreditCard,
  MoneyCheck,
  Send,
  TextBulletListSquareWarning,
  Printer,
  PrintOS,
} from './styles';
import SelectUnitCashRegisterDialog from '../../../../components/selectUnitCashRegisterDialog';
import { useSelector } from 'react-redux';
import UnitFinance from '../../../../forms/unitFinance';
import PaymentType from '../../../../forms/paymentType';
import DisapprovalCashRegisterDialog from '../../../../components/disapprovalCashRegisterDialog';
import { useReactToPrint } from 'react-to-print';
import SummaryTicket from '../../../../components/summaryTicketCashRegister';
import ErrorDialog from '../../../../components/errorDialog';

function ClinicCashRegisterCloseList({ history }) {
  const employeeUnit = useSelector((x) => x.authReducer.user?.unitId ?? -1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ numberCashRegister: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openDisapprovedDialog, setOpenDisapprovedDialog] = useState(false);
  const [justification, setJustification] = useState('');
  const [cashRegister, setCashRegister] = useState(null);
  const [errorMessageDialog, setErrorMessageDialog] = useState(false);
  const columns = CashRegisterClose.tableSchema;
  const componentRef = useRef();

  const loadCashRegisters = useCallback(() => {
    restApiClient.cashRegisterClose
      .getAllByClinic(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('numberCashRegister', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('unit.name', searchBy)
              .or()
              .like('employee.user.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadCashRegisters();
  }, [loadCashRegisters]);

  const handlePrint = useReactToPrint({
    documentTitle: `Resumo - Fechamento de Caixa`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 2mm 2mm 2mm 2mm;   
      }
      @media print { 
        grid {
          page-break-inside: avoid;
        }
      }`,
  });

  const fetchPayments = (values) => {
    const result = Object.values(
      values.reduce((obj, el) => {
        obj[el.paymentTypeId] = {
          name: el.paymentType.name,
          value: (obj[el.paymentTypeId]?.value || 0) + el.value,
        };
        return obj;
      }, {})
    );

    return result;
  };

  return (
    <Container>
      <CrudTable
        title={'Fechamento de Caixa'}
        modelActions={CashRegisterClose.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpenDialog(true);
            },
            title: 'Criar Fechamento de Caixa',
            allowedActions: [
              CashRegisterClose.actions.create,
              CashRegisterClose.actions.getUnitLastCashRegister,
            ],
          },
          update: {
            onClick: (expenseId) => {
              history.push(`/clinic/cashRegisterClose/update/${expenseId}`);
            },
            allowedActions: [
              [
                CashRegisterClose.actions.update,
                CashRegisterClose.actions.findById,
                PaymentType.actions.getAll,
                PaymentType.actions.findById,
                UnitFinance.actions.getAll,
                UnitFinance.actions.findById,
              ],
            ],
            selector: (row) =>
              row.status === 'Em Edição' || row.status === 'Reprovado',
          },
          throwCards: {
            icon: <CreditCard />,
            title: 'Lançar Crédito/Débito',
            onClick: (cashRegisterCloseId) => {
              history.push(
                `/clinic/cashRegisterClose/${cashRegisterCloseId}/cards`
              );
            },
            selector: (row) =>
              (row.status === 'Em Edição' || row.status === 'Reprovado') &&
              row.cashRegisterValues.some(
                (item) =>
                  item.paymentType.name === 'CRÉDITO' ||
                  item.paymentType.name === 'DÉBITO'
              ),
            allowedActions: [
              CashRegisterValue.actions.getAllCardsByCashRegisterId,
            ],
          },
          throwCheck: {
            icon: <MoneyCheck />,
            title: 'Lançar Cheque',
            onClick: (cashRegisterCloseId) => {
              history.push(
                `/clinic/cashRegisterClose/${cashRegisterCloseId}/checks`
              );
            },
            selector: (row) =>
              (row.status === 'Em Edição' || row.status === 'Reprovado') &&
              row.cashRegisterValues.some(
                (item) => item.paymentType.name === 'CHEQUE'
              ),
            allowedActions: [
              CashRegisterValue.actions.getAllChecksByCashRegisterId,
            ],
          },
          send: {
            icon: <Send />,
            title: 'Enviar',
            onClick: (cashRegisterCloseId, row) => {
              if (!row?.errorValues) {
                restApiClient.cashRegisterClose
                  .setEvaluation(cashRegisterCloseId)
                  .then(async (e) => {
                    const payments = fetchPayments(e.data.cashRegisterValues);
                    await setCashRegister({
                      ...e.data,
                      payments,
                    });
                    handlePrint();
                    loadCashRegisters();
                  })
                  .catch((e) => {
                    setErrorMessageDialog(e.response.data.errors);
                  });
              } else {
                setErrorMessageDialog(
                  'Encontramos um problema no lançamento de cartões ou cheques, ' +
                    'por favor, verifique se os valores foram lançados corretamente.'
                );
              }
            },
            allowedActions: [CashRegisterClose.actions.setEvaluation],
            selector: (row) =>
              row.status === 'Em Edição' || row.status === 'Reprovado',
          },
          viewDisapprovedJustification: {
            onClick: (id, row) => {
              setOpenDisapprovedDialog(true);
              setJustification(row.disapprovalJustification);
            },
            icon: <TextBulletListSquareWarning />,
            title: 'Visualizar Justificativa',
            selector: (row) => row.status === 'Reprovado',
          },
          view: {
            icon: <Preview />,
            onClick: (cashRegisterCloseId) => {
              history.push({
                pathname: `/clinic/cashRegisterClose/${cashRegisterCloseId}`,
                state: { readonly: true },
              });
            },
            title: 'Visualizar Fechamento de Caixa',
            allowedActions: [
              [
                PaymentType.actions.getAll,
                PaymentType.actions.findById,
                UnitFinance.actions.getAll,
                UnitFinance.actions.findById,
              ],
            ],
          },
          printOS: {
            icon: <Printer />,
            onClick: async (id, row) => {
              const payments = fetchPayments(row.cashRegisterValues);

              await setCashRegister({
                ...row,
                payments,
              });
              handlePrint();
            },
            title: 'Imprimir Resumo',
            selector: (row) => row?.status === 'Em Avaliação',
          },
        }}
        customCellRenderers={{
          date: {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          cashRegisterValues: {
            renderer: (field, value) => {
              return value
                .reduce((accumulator, currentValue) => {
                  return accumulator + currentValue.value;
                }, 0)
                .toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.status === 'Reprovado' || row.status === 'Em Edição') {
            return '#ffe0e0';
          }
        }}
      />

      <SelectUnitCashRegisterDialog
        open={openDialog}
        employeeUnit={employeeUnit}
        onCancel={() => {
          setOpenDialog(false);
        }}
        onSuccess={(cashRegister, submitRestriction) => {
          history.push({
            pathname: '/clinic/cashRegisterClose/create',
            state: { cashRegister, submitRestriction },
          });
        }}
        enabled={false}
      />

      <DisapprovalCashRegisterDialog
        open={openDisapprovedDialog}
        onCancel={() => setOpenDisapprovedDialog(false)}
        disapprovalJustification={justification}
      />

      <PrintOS ref={componentRef}>
        <SummaryTicket cashRegister={cashRegister} />
      </PrintOS>

      <ErrorDialog
        open={!!errorMessageDialog}
        title="Erro"
        error={errorMessageDialog}
        onClose={() => setErrorMessageDialog(false)}
      />
    </Container>
  );
}

export default ClinicCashRegisterCloseList;
