import React, { useState, useEffect, useCallback } from 'react';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, StyledMoneyInput, Button, SaveIcon } from './styles';
import Step from '../../../../forms/step';
import Outsourced from '../../../../forms/outsourced';
import { cloneDeep, isEmpty, isEqualWith } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function OutsourcedPriceTableList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [originalOutsourced, setOriginalOutsourced] = useState([]);
  const [outsourced, setOutsourced] = useState([]);
  const [originalOutsourcedStepList, setOriginalOutsourcedStepList] = useState(
    []
  );
  const [outsourcedStepList, setOutsourcedStepList] = useState([]);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const titleName = props.location.state?.name ?? '';
  const outsourcedId = props.match.params?.id
    ? parseInt(props.match.params?.id)
    : -1;
  const columns = Step.outsourcedTableSchema;

  const loadSteps = useCallback(() => {
    restApiClient.step
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('section.name', searchBy)
              .or()
              .like('section.parent.name', searchBy)
              .or()
              .like('section.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((stepResult) => {
        let newRows = stepResult.data;
        restApiClient.outsourced
          .findById(outsourcedId)
          .then((outSourcedResult) => {
            if (isEmpty(originalOutsourced)) {
              setOriginalOutsourcedStepList(
                outSourcedResult.data.outsourcedPriceTables
              );
              setOutsourcedStepList(
                cloneDeep(outSourcedResult.data.outsourcedPriceTables)
              );
              setOriginalOutsourced(outSourcedResult.data);
              setOutsourced(cloneDeep(outSourcedResult.data));
            }
            newRows.values = stepResult.data.values.map((rowStep) => {
              const outsourcedStepInfo =
                outSourcedResult.data.outsourcedPriceTables.filter(
                  (outsourcedStep) => outsourcedStep.stepId === rowStep.id
                );
              if (outsourcedStepInfo) {
                rowStep.price = parseFloat(outsourcedStepInfo[0]?.price);
              }
              return rowStep;
            });
            setRows(newRows);
          });
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadSteps();
  }, [loadSteps]);

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const salvar = () => {
    const added = outsourcedStepList.filter(
      (outsourcedStep) =>
        !originalOutsourcedStepList.some((obj) =>
          isEqualWith(
            obj,
            outsourcedStep,
            (obj1, obj2) =>
              obj1.stepId === obj2.stepId &&
              obj1.outsourcedId === obj2.outsourcedId &&
              obj1.price === obj2.price
          )
        )
    );
    const removed = originalOutsourcedStepList
      .filter(
        (outsourcedStep) =>
          !outsourcedStepList.some((obj) =>
            isEqualWith(
              obj,
              outsourcedStep,
              (obj1, obj2) =>
                obj1.stepId === obj2.stepId &&
                obj1.outsourcedId === obj2.outsourcedId &&
                obj1.price === obj2.price
            )
          )
      )
      .map((outsourcedStep) => outsourcedStep.id);
    restApiClient.outsourced
      .update(
        outsourcedId,
        originalOutsourced,
        outsourced,
        added,
        removed,
        true
      )
      .then((result) => {
        if (result.status === 200) {
          loadSteps();
          setOriginalOutsourced([]);
          setAlert({
            message: 'Tabela de Preços salva com sucesso!',
            severity: 'success',
          });
        }
      });
  };

  return (
    <Container>
      <CrudTable
        title={`Tabela de Preços - ${titleName}`}
        modelActions={Outsourced.actions}
        columns={columns}
        customColumns={[
          {
            id: 'price',
            label: 'Preço',
            minWidth: 50,
            align: 'center',
          },
        ]}
        customFooterComponent={
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={salvar}
            >
              Salvar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={async (e) => {
                await salvar(e);
                history.goBack();
              }}
            >
              Salvar e Voltar
            </Button>
          </>
        }
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          section: {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value.name;
              }
            },
          },
          price: {
            renderer: (field, value, column, row) => {
              row.price = parseFloat(row.price);
              let outsourcedStepFind = outsourcedStepList?.find(
                (x) => x.stepId === row.id
              );
              let price = 0;
              if (outsourcedStepFind) price = outsourcedStepFind?.price;
              else price = value;
              return (
                <StyledMoneyInput
                  value={price}
                  onChange={(e, value, valueMask) => {
                    let outsourcedStep = null;
                    const newList = [...outsourcedStepList];
                    if (
                      (outsourcedStep = newList.find(
                        (x) => x.stepId === row.id
                      ))
                    ) {
                      outsourcedStep.price = value;
                    } else {
                      const outsourcedStep = {
                        outsourcedId: outsourcedId,
                        stepId: row.id,
                        price: value,
                      };
                      newList.push(outsourcedStep);
                    }
                    row.price = value;
                    setOutsourcedStepList(newList);
                  }}
                />
              );
            },
          },
        }}
      />
      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default OutsourcedPriceTableList;
