/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Container,
  DialogContent,
  InputTextarea,
  Dialog,
  ActionsContainer,
  CancelButton,
  DialogActions,
  Card,
  CardHeader,
  HeaderContainer,
  SubmitButton,
  FormDiv,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from './styles';
import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import restApiClient from '../../services/restApiClient';
import MoneyInput from '../moneyInput';

function ViewUrgencyDialog({
  onSuccess,
  onCancel,
  justification,
  rejectedReason,
  title,
  orderServiceStepId = null,
  crrOrderServiceStep,
  workflowPosition = null,
  isClinic = false,
  evaluate = false,
  editCheck = true,
  ...props
}) {
  const [answer, setAnswer] = useState('');
  const [refusalAdjustmentType, setRefusalAdjustmentType] = useState('');
  const [orderServiceStep, setOrderServiceStep] = useState({});
  const [laboratory, setLaboratory] = useState({});
  const [errors, setErrors] = useState({});
  const [errorRefusalAdjustmentType, setErrorRefusalAdjustmentType] = useState(
    {}
  );
  const [reworkCost, setReworkCost] = useState(0);

  useEffect(() => {
    setAnswer('');
    setRefusalAdjustmentType('');
    if (orderServiceStepId) {
      restApiClient.orderServiceStep.findById(orderServiceStepId).then((e) => {
        setOrderServiceStep(e.data);
        if (e.data?.takeCost) setAnswer(e.data.takeCost);
        if (e.data?.refusalAdjustmentType)
          setRefusalAdjustmentType(e.data.refusalAdjustmentType);
        if (e.data?.reworkCost) setReworkCost(parseFloat(e.data?.reworkCost));
      });
    }
  }, [orderServiceStepId]);

  useEffect(() => {
    restApiClient.unit.getAllByType('laboratory').then((e) => {
      setLaboratory(e.data.values[0]);
    });
  }, []);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <Card>
            <HeaderContainer>
              <CardHeader title={title} />
            </HeaderContainer>
            <FormDiv>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <InputTextarea
                    id="outlined-multiline-static"
                    size="small"
                    label="Justificativa"
                    value={justification}
                    multiline
                    rows={4}
                    variant="outlined"
                    inputProps={{ maxLength: 250, readOnly: true }}
                  />
                </Grid>
                {evaluate ? (
                  rejectedReason === 'skip_step' ? (
                    editCheck ? (
                      <Grid item xs={12} sm={12}>
                        <FormControl
                          component="fieldset"
                          error={!isEmpty(errors)}
                        >
                          <FormLabel component="legend" required>
                            Deseja pular esta etapa?
                          </FormLabel>
                          <RadioGroup
                            row={true}
                            aria-label="reason"
                            name="reason"
                            value={answer ?? null}
                            onChange={(e) => {
                              setErrors({});
                              setAnswer(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio required />}
                              label="Sim"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio required />}
                              label="Não"
                            />
                          </RadioGroup>
                          <FormHelperText>
                            {!isEmpty(errors) ? errors : ''}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    ) : null
                  ) : (
                    <Grid item xs={12} sm={12}>
                      <FormControl
                        component="fieldset"
                        error={!isEmpty(errors)}
                      >
                        <FormLabel component="legend" required>
                          Quem deve absorver este custo?
                        </FormLabel>
                        <RadioGroup
                          row={false}
                          aria-label="reason"
                          name="reason"
                          value={answer ?? null}
                          onChange={(e) => {
                            setErrors({});
                            setAnswer(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            disabled={!editCheck}
                            value="laboratory"
                            control={<Radio required />}
                            label="Laboratório"
                          />
                          <FormControlLabel
                            disabled={!editCheck}
                            value="dentist"
                            control={<Radio required />}
                            label="Dentista"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {!isEmpty(errors) ? errors : ''}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        component="fieldset"
                        error={!isEmpty(errorRefusalAdjustmentType)}
                      >
                        <FormLabel component="legend" required>
                          Esta recusa se trata de:
                        </FormLabel>
                        <RadioGroup
                          row={false}
                          aria-label="refusalAdjustmentType"
                          name="refusalAdjustmentType"
                          value={refusalAdjustmentType ?? null}
                          onChange={(e) => {
                            setErrorRefusalAdjustmentType({});
                            setRefusalAdjustmentType(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            disabled={!editCheck}
                            value="rework"
                            control={<Radio required />}
                            label="Retrabalho"
                          />
                          <FormControlLabel
                            disabled={!editCheck}
                            value="adjustment"
                            control={<Radio required />}
                            label="Ajuste"
                          />
                          <FormControlLabel
                            disabled={!editCheck}
                            value="dentist_refusal"
                            control={<Radio required />}
                            label="Recusa do dentista"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {!isEmpty(errorRefusalAdjustmentType)
                            ? errorRefusalAdjustmentType
                            : ''}
                        </FormHelperText>
                      </FormControl>
                      {refusalAdjustmentType !== 'adjustment' &&
                      crrOrderServiceStep?.status !==
                        'Etapa Concluída e Recusada (Laboratório)' ? (
                        <MoneyInput
                          value={reworkCost}
                          inputProps={{ readOnly: !editCheck }}
                          onChange={(e, value) => {
                            setErrors({});
                            setReworkCost(value);
                          }}
                          variant="outlined"
                          size="small"
                          label="Valor do retrabalho"
                          error={!isEmpty(errors?.value)}
                          helperText={
                            !isEmpty(errors?.value)
                              ? errors?.value.join(' ')
                              : ''
                          }
                        />
                      ) : null}
                    </Grid>
                  )
                ) : null}
              </Grid>
            </FormDiv>
          </Card>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                if (editCheck) {
                  setAnswer('');
                  setRefusalAdjustmentType('');
                  setErrors({});
                  setErrorRefusalAdjustmentType({});
                }
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Fechar
            </CancelButton>
            {evaluate && editCheck ? (
              <SubmitButton
                onClick={() => {
                  if (answer !== '') {
                    if (rejectedReason === 'skip_step') {
                      switch (answer) {
                        case 'yes':
                          restApiClient.orderService
                            .findById(orderServiceStep.orderServiceId)
                            .then((e) => {
                              const endProductId = e.data.endProduct.id;
                              const skipStepFind =
                                e.data.endProduct.workflows.find(
                                  (x) => x.stepId === orderServiceStep.stepId
                                );
                              const newWorkflowPosition =
                                skipStepFind.position + 1;
                              const nextStep = e.data.endProduct.workflows.find(
                                (x) => x.position === newWorkflowPosition
                              );
                              const nextOrderServiceStep = {
                                orderServiceId: orderServiceStep.orderServiceId,
                                dentistId: orderServiceStep.dentistId,
                                stepId: nextStep.stepId,
                                unitId: orderServiceStep.unitId,
                                scheduleId: orderServiceStep.scheduleId,
                                status: 'Em Edição (Laboratório)',
                                current: true,
                              };
                              restApiClient.orderServiceStep
                                .setSkippedLab(orderServiceStep.id)
                                .then((result) => {
                                  props.history.goBack();
                                  restApiClient.orderServiceStep
                                    .create(nextOrderServiceStep)
                                    .then((e) => {
                                      props.history.push({
                                        pathname: `/lab/orderService/update/${endProductId}/${e.data.id}/false/${newWorkflowPosition}/${e.data.orderServiceId}/true`,
                                        state: {
                                          dentistId: e.data.dentistId,
                                          patientName:
                                            e.data.orderService.patient.name,
                                          recordNumber:
                                            e.data.orderService.patient
                                              .recordNumber,
                                        },
                                      });
                                    });
                                });
                            });
                          break;
                        case 'no':
                          restApiClient.orderServiceStep
                            .setSended(orderServiceStepId)
                            .then((e) => {
                              onSuccess?.(e.data);
                            });
                          break;
                        default:
                          break;
                      }
                    } else {
                      if (refusalAdjustmentType !== '') {
                        if (
                          answer === 'laboratory' &&
                          crrOrderServiceStep?.status ===
                            'Etapa Concluída e Recusada (Laboratório)'
                        ) {
                          const oldFinishedLabService =
                            crrOrderServiceStep.finishedLabService;
                          let finishedLabService = {
                            ...crrOrderServiceStep.finishedLabService,
                          };
                          finishedLabService.costUnitId = laboratory.id;
                          restApiClient.finishedLabService
                            .update(
                              oldFinishedLabService.id,
                              oldFinishedLabService,
                              finishedLabService
                            )
                            .then((e) => {
                              restApiClient.orderServiceStep
                                .setTakeCost(orderServiceStepId, {
                                  takeCost: answer,
                                  refusalAdjustmentType,
                                  reworkCost,
                                })
                                .then((e) => {
                                  onSuccess?.(e.data);
                                });
                            });
                        } else {
                          restApiClient.orderServiceStep
                            .setTakeCost(orderServiceStepId, {
                              takeCost: answer,
                              refusalAdjustmentType,
                              reworkCost,
                            })
                            .then((e) => {
                              onSuccess?.(e.data);
                            });
                        }
                        setAnswer('');
                        setRefusalAdjustmentType('');
                      } else {
                        setErrorRefusalAdjustmentType(
                          'Este campo é obrigatório'
                        );
                      }
                    }
                  } else {
                    setErrors('Este campo é obrigatório');
                  }
                }}
                variant="contained"
                color="primary"
              >
                Salvar
              </SubmitButton>
            ) : null}
            {orderServiceStepId && !evaluate && editCheck ? (
              <SubmitButton
                onClick={() => {
                  if (isClinic) {
                    restApiClient.orderServiceStep
                      .fixOSByClinic(orderServiceStepId)
                      .then((e) => {
                        onSuccess?.(e.data);
                      });
                  } else {
                    restApiClient.orderServiceStep
                      .fixOSByDentist(orderServiceStepId)
                      .then((e) => {
                        onSuccess?.(e.data);
                      });
                  }
                }}
                variant="contained"
                color="primary"
              >
                Corrigir
              </SubmitButton>
            ) : null}
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ViewUrgencyDialog;
