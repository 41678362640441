import React from 'react';
import MaterialTreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  Container,
  RowContainer,
  IconButton,
  ActionContainer,
  TreeItem,
  GroupTreeItem,
  LabelContainer,
} from './styles';
import {
  objectToArray,
  isNullOrEmptyObject,
  objectFilter,
  objectSort,
} from '../../extensions/object';
import SecuredItem from '../securedItem';

function TreeView({
  data,
  nameSelector,
  customContent,
  actions,
  className,
  ...props
}) {
  const renderTree = (data, depth = 0, prev = null) => {
    const CurrentTreeItem = depth === 0 ? GroupTreeItem : TreeItem;
    return objectToArray(data, (key, value) => (
      <CurrentTreeItem
        key={value.id}
        nodeId={value.id}
        label={
          <RowContainer>
            <LabelContainer>
              {nameSelector ? nameSelector(key, value) : value.name}
            </LabelContainer>
            {customContent?.(key, value)}
            <ActionContainer>
              {objectToArray(
                objectFilter(
                  actions ?? {},
                  (actionKey, actionValue) =>
                    actionValue.icon &&
                    actionValue.onClick &&
                    (!actionValue.selector ||
                      actionValue.selector(key, value, data))
                ),
                (actionKey, actionValue) =>
                  actionValue.maxDepth === undefined ||
                  depth <= actionValue.maxDepth ? (
                    <SecuredItem
                      key={actionKey}
                      allowedActions={actionValue.allowedActions}
                    >
                      <IconButton
                        key={actionKey}
                        onClick={(e) => {
                          e.stopPropagation();
                          actionValue.onClick(
                            value.id,
                            depth,
                            actionKey,
                            value,
                            prev
                          );
                        }}
                        title={actionValue.title}
                      >
                        {typeof actionValue.icon === 'function'
                          ? actionValue.icon(value)
                          : actionValue.icon}
                      </IconButton>
                    </SecuredItem>
                  ) : null
              )}
            </ActionContainer>
          </RowContainer>
        }
      >
        {!isNullOrEmptyObject(value.children)
          ? renderTree(objectSort(value.children), depth + 1, value)
          : null}
      </CurrentTreeItem>
    ));
  };

  return (
    <Container className={className}>
      <MaterialTreeView
        {...props}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {renderTree(objectSort(data))}
      </MaterialTreeView>
    </Container>
  );
}

export default TreeView;
