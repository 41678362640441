import React from 'react';
import Attendance from '../../../../../forms/attendance';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container, BackButton, FooterContainer } from './styles';

function ClinicAllProceduresCreateUpdate(props) {
  const readonly = props.location?.state?.readonly ?? false;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Attendance}
        schemaSelector={(modelSchema, modelId) =>
          modelId ? modelSchema.updateSchema : modelSchema.schema
        }
        restGetDataById={async (id) => {
          const result = await restApiClient.attendance.findById(id);
          result.data.recordNumber = result.data.patient.recordNumber;
          result.data.recordNumberUninformed =
            !result.data.patient.recordNumber;
          result.data.name = result.data.patient.name;
          result.data.showToothNumber = !!result.data.toothNumber;
          result.data.showDentalArch = !!result.data.dentalArch;
          return result;
        }}
        modelData={{
          requestingUnitBoolean: true,
          recordNumberFound: false,
          readonly,
          procedures: [{}],
        }}
        customButtonsBuilder={() => (
          <>
            <FooterContainer>
              <BackButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Voltar
              </BackButton>
            </FooterContainer>
          </>
        )}
        {...props}
      />
    </Container>
  );
}

export default ClinicAllProceduresCreateUpdate;
