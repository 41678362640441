import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectBuyerDialogControl from '../../components/selectBuyerDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectBuyerDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectBuyerDialog'
  )
);

const customSelectBuyerDialogControlTester = rankWith(
  4,
  isSelectBuyerDialogControl
);

export default {
  tester: customSelectBuyerDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectBuyerDialogControl),
};
