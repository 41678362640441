import React, { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  AddBox,
  EditBox,
  Container,
  CustomTreeView,
  HeaderContainer,
  ContentContainer,
  IconButton,
  Delete,
  SelectContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  MoneyCalculator,
  ReceiptAdd,
  Statement,
  PackageIcon,
  LabFlask,
  XRay,
} from './styles';
import isEqual from 'lodash/isEqual';
import SecuredItem from '../../../../../components/securedItem';
import restApiClient from '../../../../../services/restApiClient';
import CreateUpdateExpenseTypeDialog from '../../../../../components/createUpdateExpenseTypeDialog';
import Expense from '../../../../../forms/expense';
import CashRegisterValue from '../../../../../forms/cashRegisterValue';
import StockConsumption from '../../../../../forms/stockConsumption';
import LabConsumption from '../../../../../forms/labConsumption';
import RadiologyConsumption from '../../../../../forms/radiologyConsumption';
import ExpenseType from '../../../../../forms/expenseType';
import ExpenseTypeExpenseType from '../../../../../forms/ExpenseTypeExpenseType';
import isEmpty from 'lodash/isEmpty';
import DayJsUtils from '@date-io/dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Badge, CircularProgress } from '@material-ui/core';
import FilterTree from '../../../../../helpers/filterTree';

function ExpenseTypeTreeView({ history }) {
  const persistentFilter = JSON.parse(
    window.localStorage.getItem('dreFilterState')
  ) || {
    type: 1,
    selectedUnitId: undefined,
    selectedDateMonth: undefined,
  };
  const [data, setData] = useState({});
  const [consolidatedAt, setConsolidatedAt] = useState('');
  const [expenseTypeId, setExpenseTypeId] = useState(undefined);
  const [parent, setParent] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState({
    key: '',
    actionLabel: '',
    depthLabel: '',
  });
  const user = useSelector((x) => x.authReducer.user);
  const unit = user?.unit;
  const [units, setUnits] = useState(null);
  const [type, setType] = useState(persistentFilter.type ?? 1);
  const [selectedUnitId, setSelectedUnitId] = useState(
    persistentFilter.selectedUnitId ?? user?.unitId ?? -1
  );
  const [selectedDateMonth, setSelectedDateMonth] = useState(
    persistentFilter.selectedDateMonth
      ? moment(persistentFilter.selectedDateMonth)
      : moment()
  );
  const [showSelectUnit, setShowSelectUnit] = useState(true);
  const [fetching, setFetching] = useState(false);

  const loadExpenseTypes = useCallback(() => {
    if (selectedDateMonth.isValid()) {
      setFetching(true);
      restApiClient.expenseType
        .getAllAsTree(
          type,
          selectedDateMonth.month() + 1,
          selectedDateMonth.year(),
          selectedUnitId
        )
        .then((e) => {
          if (!isEqual(data, e.data)) {
            setData(e.data.tree);
            setConsolidatedAt(
              moment(e.data.consolidatedAt).format('DD/MM/YYYY')
            );
          }
          setFetching(false);
        });
    }
  }, [type, selectedDateMonth, selectedUnitId]);

  useEffect(() => {
    loadExpenseTypes();
  }, [loadExpenseTypes, selectedDateMonth, selectedUnitId]);

  useEffect(() => {
    window.localStorage.setItem(
      'dreFilterState',
      JSON.stringify({
        type,
        selectedUnitId,
        selectedDateMonth,
      })
    );
  }, [type, selectedUnitId, selectedDateMonth]);

  useEffect(() => {
    const filterTree = new FilterTree();
    switch (type) {
      default:
      case 1:
        filterTree.eq('showInDre', true).eq('type', 'clinic');
        setShowSelectUnit(true);
        break;
      case 2:
        filterTree.and((leftOp, rightOp) => {
          leftOp.eq('showInDre', true);
          rightOp.in('type', ['laboratory', 'radiology']);
        });
        setShowSelectUnit(true);
        break;
      case 3:
        filterTree.eq('showInDre', true);
        setShowSelectUnit(true);
        break;
      case 4:
        setSelectedUnitId(-1);
        setShowSelectUnit(false);
        return;
    }

    restApiClient.unit
      .getAll(null, null, filterTree.toString(), {
        id: 'asc',
      })
      .then((e) => {
        setUnits(e.data);
      });
  }, [type]);

  function switchLabel(depth, actionKey) {
    let depthLabel, actionLabel;

    if (actionKey === 'add') {
      actionLabel = 'Cadastro';
    } else if (actionKey === 'edit') {
      actionLabel = 'Edição';
    }

    switch (depth) {
      case 0:
        depthLabel = 'Tipo Secundário';
        break;
      case 1:
        depthLabel = 'Tipo Terciário';
        break;
      case 2:
        depthLabel = 'Tipo Quaternário';
        break;
      default:
        depthLabel = 'Tipo';
        break;
    }

    setAction({
      key: actionKey,
      actionLabel: actionLabel,
      depthLabel: depthLabel,
    });
  }

  return (
    unit?.showInDre && (
      <Container>
        <SelectContainer>
          <FormControl
            size="small"
            style={{ marginTop: '3px', width: '190px' }}
          >
            <InputLabel htmlFor="outlined-selectedUnitId-native-simple">
              Selecione o tipo da DRE
            </InputLabel>
            <Select
              value={type || ''}
              label="Selecione o tipo da DRE"
              onChange={(e) => {
                setType(e.target.value);
                if (e.target.value === 2) {
                  setSelectedUnitId(-1);
                } else {
                  setSelectedUnitId(user?.unitId ?? undefined);
                }
              }}
            >
              {[
                { id: 1, name: 'Clínico' },
                { id: 2, name: 'Intercompany' },
                { id: 3, name: 'Clínico - Intercompany' },
                { id: 4, name: 'Estoque' },
              ].map((optionValue) => (
                <MenuItem value={optionValue.id} key={optionValue.id}>
                  {optionValue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            disabled={!showSelectUnit}
            size="small"
            style={{ marginTop: '3px', width: '320px' }}
          >
            <InputLabel htmlFor="outlined-selectedUnitId-native-simple">
              Selecione uma unidade
            </InputLabel>
            <Select
              value={selectedUnitId || ''}
              label="Selecione uma Unidade"
              onChange={(e) => {
                setSelectedUnitId(e.target.value);
              }}
            >
              {[
                <MenuItem value="-1" key={'empty'}>
                  TODAS
                </MenuItem>,
              ].concat(
                units?.values.map((optionValue) => (
                  <MenuItem value={optionValue.id} key={optionValue.id}>
                    {optionValue.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>

          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              style={{ width: '115px' }}
              margin="normal"
              id="date-month"
              label="Mês/Ano"
              format="MM/YYYY"
              value={selectedDateMonth}
              views={['year', 'month']}
              onChange={(date) => {
                setSelectedDateMonth(moment(date.toDate()));
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
            />
          </MuiPickersUtilsProvider>
        </SelectContainer>
        <Paper>
          <HeaderContainer>
            <h1>Gerenciar DRE {`(Consolidado dia ${consolidatedAt})`}</h1>
            <SecuredItem allowedActions={[ExpenseType.actions.create]}>
              <IconButton
                onClick={() => {
                  switchLabel(-1, 'add');
                  setExpenseTypeId(undefined);
                  setParent(undefined);
                  setOpen(true);
                }}
                title={'Adicionar Tipo de Despesa'}
              >
                <AddBox />
              </IconButton>
            </SecuredItem>
          </HeaderContainer>

          <ContentContainer>
          {fetching ? <CircularProgress /> :
            (<CustomTreeView
              nameSelector={(key, value) =>
                value.line ? `${value.line} - ${value.name}` : value.name
              }
              customContent={(key, value) => (
                <>
                  <span>
                    {`R$ ${value.value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </span>
                </>
              )}
              actions={{
                field: {
                  icon: <ReceiptAdd />,
                  onClick: (id, depth, actionKey, row) => {
                    history.push({
                      pathname: `/financial/config/expense-type/${id}/expense-type`,
                      state: { expenseType: row },
                    });
                  },
                  maxDepth: 0,
                  title: 'Vincular Campos',
                  allowedActions: [
                    [
                      ExpenseTypeExpenseType.actions.create,
                      ExpenseType.actions.getAll,
                    ],
                  ],
                  selector: (key, row) =>
                    isEmpty(row?.children) && row?.totalExpensesCount === 0,
                },
                viewExpenses: {
                  icon: (row) => (
                    <Badge badgeContent={row.expensesCount} color="secondary">
                      <Statement />
                    </Badge>
                  ),
                  onClick: (id, depth, actionKey, row) => {
                    if (selectedDateMonth.isValid()) {
                      history.push({
                        pathname: `/financial/config/expense-type/${id}/expenses`,
                        state: {
                          expenseType: row,
                          type,
                          month: selectedDateMonth.month() + 1,
                          year: selectedDateMonth.year(),
                          unitId: selectedUnitId,
                        },
                      });
                    }
                  },
                  title: 'Visualizar Despesas',
                  allowedActions: [Expense.actions.getAllByMonth],
                  selector: (key, row) =>
                    row?.expensesCount > 0 && row.value !== '0,00',
                },
                viewPayments: {
                  icon: (row) => <MoneyCalculator />,
                  onClick: (id, depth, actionKey, row) => {
                    if (selectedDateMonth.isValid()) {
                      history.push({
                        pathname: `/financial/config/expense-type/${id}/payments`,
                        state: {
                          expenseType: row,
                          type,
                          month: selectedDateMonth.month() + 1,
                          year: selectedDateMonth.year(),
                          unitId: selectedUnitId,
                        },
                      });
                    }
                  },
                  title: 'Visualizar Receitas',
                  allowedActions: [CashRegisterValue.actions.getAllToDre],
                  selector: (key, row) =>
                    row?.paymentType && row.value !== '0,00',
                },
                viewStockConsumption: {
                  icon: (row) => (
                    <Badge
                      badgeContent={row.stockConsumptionCount}
                      color="secondary"
                    >
                      <PackageIcon />
                    </Badge>
                  ),
                  onClick: (id, depth, actionKey, row) => {
                    if (selectedDateMonth.isValid()) {
                      history.push({
                        pathname: `/financial/config/expense-type/${id}/stockConsumption`,
                        state: {
                          expenseType: row,
                          type,
                          month: selectedDateMonth.month() + 1,
                          year: selectedDateMonth.year(),
                          unitId: selectedUnitId,
                        },
                      });
                    }
                  },
                  title: 'Visualizar Consumo do Estoque',
                  allowedActions: [StockConsumption.valuesActions.getAllToDre],
                  selector: (key, row) =>
                    row?.stockConsumptionCount > 0 && row.value !== '0,00',
                },
                viewLabConsumption: {
                  icon: (row) => (
                    <Badge
                      badgeContent={row.labConsumptionCount}
                      color="secondary"
                    >
                      <LabFlask />
                    </Badge>
                  ),
                  onClick: (id, depth, actionKey, row) => {
                    if (selectedDateMonth.isValid()) {
                      history.push({
                        pathname: `/financial/config/expense-type/${id}/labConsumption`,
                        state: {
                          expenseType: row,
                          type,
                          month: selectedDateMonth.month() + 1,
                          year: selectedDateMonth.year(),
                          unitId: selectedUnitId,
                        },
                      });
                    }
                  },
                  title: 'Visualizar Consumo do Laboratório',
                  allowedActions: [LabConsumption.valuesActions.getAllToDre],
                  selector: (key, row) =>
                    row?.labConsumptionCount > 0 && row.value !== '0,00',
                },
                viewRadiologyConsumption: {
                  icon: (row) => (
                    <Badge
                      badgeContent={row.radiologyConsumptionCount}
                      color="secondary"
                    >
                      <XRay />
                    </Badge>
                  ),
                  onClick: (id, depth, actionKey, row) => {
                    if (selectedDateMonth.isValid()) {
                      history.push({
                        pathname: `/financial/config/expense-type/${id}/radiologyConsumption`,
                        state: {
                          expenseType: row,
                          type,
                          month: selectedDateMonth.month() + 1,
                          year: selectedDateMonth.year(),
                          unitId: selectedUnitId,
                        },
                      });
                    }
                  },
                  title: 'Visualizar Consumo da Rediologia',
                  allowedActions: [
                    RadiologyConsumption.valuesActions.getAllToDre,
                  ],
                  selector: (key, row) =>
                    row?.radiologyConsumptionCount > 0 && row.value !== '0,00',
                },
                add: {
                  icon: <AddBox />,
                  onClick: (id, depth, actionKey, value) => {
                    switchLabel(depth, actionKey);
                    setExpenseTypeId(id);
                    setParent(value);
                    setOpen(true);
                  },
                  maxDepth: 2,
                  title: 'Adicionar',
                  allowedActions: [ExpenseType.actions.create],
                },
                edit: {
                  icon: <EditBox />,
                  onClick: (id, depth, actionKey, value, parent) => {
                    switchLabel(depth - 1, actionKey);
                    setExpenseTypeId(id);
                    setParent(parent);
                    setOpen(true);
                  },
                  maxDepth: 3,
                  title: 'Editar',
                  allowedActions: [
                    [ExpenseType.actions.findById, ExpenseType.actions.update],
                  ],
                },
                remove: {
                  icon: <Delete />,
                  onClick: (id) => {
                    restApiClient.expenseType.delete(id).then(() => {
                      loadExpenseTypes();
                    });
                  },
                  maxDepth: 3,
                  title: 'Excluir',
                  allowedActions: [
                    [ExpenseType.actions.findById, ExpenseType.actions.delete],
                  ],
                  selector: (key, row) =>
                    isEmpty(row?.children) &&
                    isEmpty(row?.paymentType) &&
                    row?.expensesCount === 0 &&
                    row?.expenseTypeCount === 0 &&
                    row?.stockConsumptionCount === 0,
                },
              }}
              data={data}
            />)}
          </ContentContainer>
        </Paper>

        <CreateUpdateExpenseTypeDialog
          open={open}
          action={action}
          expenseTypeId={expenseTypeId}
          parent={parent}
          restModel={restApiClient.expenseType}
          onCancel={() => {
            setOpen(false);
            setExpenseTypeId(undefined);
            setParent(undefined);
          }}
          onSuccess={() => {
            loadExpenseTypes();
            setOpen(false);
          }}
        />
      </Container>
    )
  );
}

export default ExpenseTypeTreeView;
