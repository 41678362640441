import styled from 'styled-components';
import {
  AddBox as AddBoxMaterial,
  Edit as EditBoxMaterial,
  Delete as MaterialDelete,
  TextGrammarSettings as TextGrammarSettingsMaterial,
} from '../../../../../styles/icons';
import TreeView from '../../../../../components/treeView';
import {
  Paper as PaperMaterial,
  IconButton as MaterialIconButton,
} from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  h1 {
    width: 100%;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 22px;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  width: 70%;
  margin-bottom: 10px;
`;

export const CustomTreeView = styled(TreeView)`
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Paper = styled(PaperMaterial)`
  margin-top: 50px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconButton = styled(MaterialIconButton)``;

export const AddBox = styled(AddBoxMaterial)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const EditBox = styled(EditBoxMaterial)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Delete = styled(MaterialDelete)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const TextGrammarSettings = styled(TextGrammarSettingsMaterial)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
