import styled from 'styled-components';
import {
  PackageIcon as MaterialPackageIcon,
  Barcode as MaterialBarcode,
  CodeDownload as MaterialCodeDownload,
  ClockHistory as MaterialClockHistory,
  Close as MaterialClose,
  Grid3x2GapFill as MaterialGrid,
} from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const PackageIcon = styled(MaterialPackageIcon)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Barcode = styled(MaterialBarcode)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CodeDownload = styled(MaterialCodeDownload)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const ClockHistory = styled(MaterialClockHistory)`
  width: 20px;
  height: 20px;
  color: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Grid = styled(MaterialGrid)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
