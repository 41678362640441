import React, { useState, useEffect, useCallback } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, StyledMoneyInput, Button, SaveIcon } from './styles';
import Specialty from '../../../../../forms/specialty';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { cloneDeep, first, isEmpty, isEqualWith } from 'lodash';

function ValueHourList(props) {
  const unitId = parseInt(props.match.params?.unitId) ?? -1;
  const dentist = props.location?.state?.dentist;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [dentistSpecialtyList, setDentistSpecialtyList] = useState([]);
  const [originalDentistSpecialtyList, setOriginalDentistSpecialtyList] =
    useState([]);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const columns = Specialty.tableSchema;

  const loadSpecialties = useCallback(() => {
    restApiClient.specialty
      .getAllBySpecialtyDentist(
        dentist?.id,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        restApiClient.dentistHourValue
          .getAllByDentistAndUnit(dentist?.id, unitId)
          .then((x) => {
            newRows.values = e.data.values.map((row) => {
              const specialtyInfo = x.data.filter(
                (dentistSpecialty) => dentistSpecialty.specialtyId === row.id
              );
              if (!isEmpty(specialtyInfo)) {
                row.newValue = parseFloat(first(specialtyInfo)?.value);
              } else {
                row.newValue = row.value;
              }
              return row;
            });
            setDentistSpecialtyList(x.data);
            setOriginalDentistSpecialtyList(cloneDeep(x.data));
            setRows(newRows);
          });
      });
  }, [rowsPerPage, page, orderBy, searchBy, unitId, dentist]);

  useEffect(() => {
    loadSpecialties();
  }, [loadSpecialties]);

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const salvar = async () => {
    const added = dentistSpecialtyList
      .filter(
        (dentistSpecialty) =>
          !originalDentistSpecialtyList.some((obj) =>
            isEqualWith(
              obj,
              dentistSpecialty,
              (obj1, obj2) =>
                obj1.dentistId === obj2.dentistId &&
                obj1.unitId === obj2.unitId &&
                obj1.specialtyId === obj2.specialtyId &&
                obj1.value === obj2.value
            )
          )
      )
      .filter((item) => item.value > 0);

    const removed = originalDentistSpecialtyList
      .filter(
        (dentistSpecialty) =>
          !dentistSpecialtyList.some((obj) =>
            isEqualWith(
              obj,
              dentistSpecialty,
              (obj1, obj2) =>
                obj1.id === obj2.id &&
                obj1.dentistId === obj2.dentistId &&
                obj1.unitId === obj2.unitId &&
                obj1.specialtyId === obj2.specialtyId &&
                obj1.value === obj2.value
            )
          )
      )
      .map((dentistSpecialty) => dentistSpecialty.id);

    if (!isEmpty(added) || !isEmpty(removed)) {
      restApiClient.dentistHourValue
        .updateDentistHourValues(added, removed)
        .then((result) => {
          if (result.status === 200) {
            loadSpecialties();
            setOriginalDentistSpecialtyList([]);
            setAlert({
              message:
                'Os valores dos procedimentos foram alterados com sucesso!',
              severity: 'success',
            });
          }
        });
    }
  };
  return (
    <Container>
      <CrudTable
        title={`Especialidades - ${dentist?.name}`}
        modelActions={Specialty.actions}
        columns={columns}
        customFooterComponent={
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={salvar}
            >
              Salvar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={async (e) => {
                await salvar(e);
                props.history.goBack();
              }}
            >
              Salvar e Voltar
            </Button>
          </>
        }
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{}}
        customColumns={[
          {
            id: 'newValue',
            label: 'Preço',
            minWidth: 50,
            align: 'center',
          },
        ]}
        customCellRenderers={{
          newValue: {
            renderer: (field, value, column, row) => {
              row.newValue = parseFloat(row.newValue);
              let specialtyFind = dentistSpecialtyList.find(
                (x) => x.specialtyId === row.id
              );
              let newValue = 0;
              if (specialtyFind) newValue = specialtyFind?.value;
              else newValue = value;
              return (
                <StyledMoneyInput
                  value={newValue}
                  onChange={(e, value) => {
                    let specialty = null;
                    const newList = [...dentistSpecialtyList];
                    if (
                      (specialty = newList.find(
                        (x) => x.specialtyId === row.id
                      ))
                    ) {
                      specialty.value = value;
                    } else {
                      const specialty = {
                        specialtyId: row.id,
                        dentistId: dentist?.id,
                        unitId: unitId,
                        value: value,
                      };
                      newList.push(specialty);
                    }
                    row.newValue = value;
                    setDentistSpecialtyList(newList);
                  }}
                />
              );
            },
          },
        }}
      />

      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ValueHourList;
