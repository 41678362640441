import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OrderService from '../../../../forms/orderService';
import OrderServiceStep from '../../../../forms/orderServiceStep';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import ViewUrgencyDialog from '../../../../components/viewUrgencyDialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  Container,
  Preview,
  ExclamationTriangleFill,
  AddToQueue,
  TextBulletListSquareWarning,
} from './styles';

function LabAllOrderServiceRefusedList({ history, ...props }) {
  const userData = useSelector((state) => state.authReducer?.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [justification, setJustification] = useState('');
  const [rejectedReason, setRejectedReason] = useState('');
  const [workflowPosition, setWorkflowPosition] = useState('');
  const [orderServiceStepId, setOrderServiceStepId] = useState('');
  const [orderServiceStep, setOrderServiceStep] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [editCheck, setEditCheck] = useState(true);
  const columns = OrderService.labAllRefusedOSTableSchema;

  const loadRefusedList = () => {
    restApiClient.orderService
      .getAllOrderServiceRefused(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('orderService.patient.name', searchBy)
              .or()
              .like('orderService.patient.record_number', searchBy)
              .or()
              .like('dentistUser.name', searchBy)
              .or()
              .like('orderService.endProduct.name', searchBy)
              .or()
              .like('step.name', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('unit.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  };

  useEffect(() => {
    loadRefusedList();
  }, [rowsPerPage, page, orderBy, searchBy, userData]);

  const handleAlertClose = (event, reason) => {
    setOpenMessage(false);
  };

  return (
    <Container>
      <CrudTable
        title={'Todas Ordens de Serviço Recusadas'}
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          addOrderServiceStep: {
            icon: <AddToQueue />,
            onClick: (orderServiceStepId, row) => {
              history.push({
                pathname: `/lab/orderService/update/${row.orderService.endProductId}/${orderServiceStepId}/false/${row.workflowPosition}/${row.orderServiceId}/true`,
                state: {
                  date: row.createdAt,
                  patientName: row.orderService.patient.name,
                  recordNumber: row.orderService.patient.recordNumber,
                  dentistId: row.dentistId
                },
              });
            },
            title: 'Atualizar uma etapa',
            selector: (row) => row.status === 'Em Edição (Laboratório)',
          },
          view: {
            icon: <Preview />,
            onClick: (orderServiceStepId, row) => {
              history.push({
                pathname: `/lab/orderService/update/${row.orderService.id}/${row.workflowPosition}`,
                state: {
                  dentistId: row.dentistId,
                  date: row.createdAt,
                  allowView: true,
                  patientName: row.orderService.patient.name,
                  recordNumber: row.orderService.patient.recordNumber,
                },
              });
            },
            title: 'Visualizar OS',
          },
          evaluateDentistRejectedJustification: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceId, row) => {
              setOpen(true);
              setEditCheck(true);
              setOrderServiceStep(row);
              setOrderServiceStepId(row.id);
              setRejectedReason(row.rejectedReason);
              setWorkflowPosition(row.workflowPosition);
              if (row.rejectedReason === 'skip_step') {
                setJustification(row.rejectedJustification);
              } else {
                setJustification(row.dentistRejectedJustification);
              }
            },
            title: 'Avaliar Justificativa',
            allowedActions: [OrderServiceStep.actions.setTakeCost],
            selector: (row) =>
              row.status === 'Recusada Dentista (Laboratório)' ||
              row.status === 'Avaliar (Pular Etapa)' ||
              row.status === 'Etapa Concluída e Recusada (Laboratório)',
          },
          viewDentistRejectedJustification: {
            icon: <TextBulletListSquareWarning />,
            onClick: (orderServiceId, row) => {
              setOpen(true);
              setEditCheck(false);
              setOrderServiceStep(row);
              setOrderServiceStepId(row.id);
              setRejectedReason(row.rejectedReason);
              setWorkflowPosition(row.workflowPosition);
              if (row.rejectedReason === 'skip_step') {
                setJustification(row.rejectedJustification);
              } else {
                setJustification(row.dentistRejectedJustification);
              }
            },
            title: 'Visualizar Justificativa',
            allowedActions: [OrderServiceStep.actions.setTakeCost],
            selector: (row) =>
              row.status === 'Recusa Avaliada' ||
              row.status === 'Pulada Laboratório',
          },
        }}
      />

      <ViewUrgencyDialog
        open={open}
        title={'Recusa - Justificativa'}
        evaluate={true}
        editCheck={editCheck}
        justification={justification}
        rejectedReason={rejectedReason}
        workflowPosition={workflowPosition}
        orderServiceStepId={orderServiceStepId}
        crrOrderServiceStep={orderServiceStep}
        history={history}
        onCancel={() => {
          setOpen(false);
        }}
        onSuccess={() => {
          loadRefusedList();
          setOrderServiceStepId('');
          setOpen(false);
          setOpenMessage(true);
        }}
      />

      <Snackbar
        open={openMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {'OS Salva com Sucesso'}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default LabAllOrderServiceRefusedList;
