import React, { useState, useEffect, useCallback } from 'react';
import Origin from '../../../../forms/origin';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, MoneySettings, Receipt } from './styles';
import ExpenseTypeDialog from '../../../../components/expenseTypeDialog';
import CreateUpdateGroupDialog from '../../../../components/createUpdateGroupDialog';

function OriginView({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('expense');
  const [action, setAction] = useState({
    key: '',
    actionLabel: '',
    depthLabel: '',
  });
  const [openExpenseTypeDialog, setOpenExpenseTypeDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);

  const columns = Origin.listSchema;

  const loadOrigins = useCallback(() => {
    restApiClient.origin
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('name', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadOrigins();
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Origem'}
        modelActions={Origin.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpen(true);
              setAction({
                key: 'add',
                actionLabel: 'Cadastro',
                depthLabel: 'Origem',
              });
            },
          },
          update: {
            onClick: (originId) => {
              setSelectedId(originId);
              setOpen(true);
              setAction({
                key: 'edit',
                actionLabel: 'Edição',
                depthLabel: 'Origem',
              });
            },
            allowedActions: [[Origin.actions.findById, Origin.actions.update]],
          },
          link: {
            icon: <Receipt />,
            onClick: (id, depth, actionKey) => {
              setSelectedId(id);
              setType('expense');
              setOpenExpenseTypeDialog(true);
            },
            maxDepth: 2,
            title: 'Vincular a um tipo de despesa',
            allowedActions: [[Origin.actions.findById, Origin.actions.update]],
          },
          linkIncome: {
            icon: <MoneySettings />,
            onClick: (id, depth, actionKey) => {
              setSelectedId(id);
              setType('income');
              setOpenExpenseTypeDialog(true);
            },
            maxDepth: 2,
            title: 'Vincular a um tipo de despesa',
            allowedActions: [[Origin.actions.findById, Origin.actions.update]],
          },
          delete: {
            onClick: (originId, index) => {
              restApiClient.origin.delete(originId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
            selector: (row) => row?.treatmentsCount === 0,
          },
        }}
        customCellRenderers={{
          expenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value?.name ?? '-';
              }
            },
          },
          incomeExpenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value?.name ?? '-';
              }
            },
          },
        }}
      />

      <ExpenseTypeDialog
        open={openExpenseTypeDialog}
        selectedId={selectedId}
        restModel={restApiClient.origin}
        type={type}
        onClose={() => {
          setOpenExpenseTypeDialog(false);
        }}
        onSuccess={() => {
          loadOrigins();
          setOpenExpenseTypeDialog(false);
        }}
      />

      <CreateUpdateGroupDialog
        open={open}
        restModel={restApiClient.origin}
        action={action}
        parentId={selectedId}
        onCancel={() => {
          setOpen(false);
          setSelectedId(undefined);
        }}
        onSuccess={() => {
          loadOrigins();
          setOpen(false);
        }}
      />
    </Container>
  );
}

export default OriginView;
