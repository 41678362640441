import styled from 'styled-components';
import MaterialSelect from '@material-ui/core/Select';
import MaterialFormControl from '@material-ui/core/FormControl';
import MaterialButton from '@material-ui/core/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import MaterialDialogTitle from '@material-ui/core/DialogTitle';
import MaterialDialogContent from '@material-ui/core/DialogContent';
import MaterialInputLabel from '@material-ui/core/InputLabel';
import MaterialInput from '@material-ui/core/Input';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import MaterialNativeSelect from '@material-ui/core/NativeSelect';
import MaterialFormHelperText from '@material-ui/core/FormHelperText';
import AdornedTextField from '../adornedTextField';
import {
  GetApp as MaterialAddBox,
  LayerGroup as MaterialLayerGroup,
} from '../../styles/icons';

export const Select = styled(MaterialSelect)``;

export const StyledAdornedTextField = styled(AdornedTextField)`
  margin-top: 3px !important;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
  margin-bottom: 10px !important;
`;

export const Button = styled(MaterialButton)``;

export const Dialog = styled(MaterialDialog)``;

export const DialogTitle = styled(MaterialDialogTitle)`
  text-align: center !important;
`;
export const DialogContent = styled(MaterialDialogContent)`
  width: 400px !important;
`;

export const DialogActions = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between !important;
`;

export const NativeSelect = styled(MaterialNativeSelect)``;

export const FormHelperText = styled(MaterialFormHelperText)``;

export const InputLabel = styled(MaterialInputLabel)``;

export const Input = styled(MaterialInput)``;

export const MenuItem = styled(MaterialMenuItem)``;

export const AddBox = styled(MaterialAddBox)`
  width: 20px;
  height: 20px;
  fill: #808080;
`;

export const LayerGroup = styled(MaterialLayerGroup)`
  width: 25px;
  height: 25px;
  color: #808080;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
