const ItemizedCard = {
  schema: {
    type: 'object',
    properties: {
      cashRegisterValueId: {
        type: 'integer',
        customErrors: '',
      },
      serial: {
        type: 'string',
        minLength: 5,
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
    },
    required: ['cashRegisterValueId', 'serial', 'value'],
  },

  uischema: {
    type: 'Group',
    label: 'Lançar entrada de cartão',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Serial',
            scope: '#/properties/serial',
          },
          {
            type: 'Control',
            label: 'Valor',
            scope: '#/properties/value',
          },
        ],
      },
    ],
  },
  listAllTableSchema: [
    {
      id: 'serial',
      label: 'Serial',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterValue.cashRegisterClose.numberCashRegister',
      label: 'Número do Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterValue.cashRegisterClose.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'cashRegisterValue.cashRegisterClose.date',
      label: 'Data do Caixa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'date',
      label: 'Data do Pagamento',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor Bruto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'liquid',
      label: 'Valor Líquido',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'installments',
      label: 'Total de Parcelas',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  tableSchema: [
    {
      id: 'serial',
      label: 'Serial',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'date',
      label: 'Data Pagamento',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor Bruto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'liquid',
      label: 'Valor Líquido',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'installments',
      label: 'Total de Parcelas',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  clinicTableSchema: [
    {
      id: 'serial',
      label: 'Serial',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor Bruto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'ItemizedCardController@getAll',
    updateInstallment: 'ItemizedCardController@updateInstallment',
    getAllByCashRegisterValueId:
      'ItemizedCardController@getAllByCashRegisterValueId',
    create: 'ItemizedCardController@create',
    delete: 'ItemizedCardController@delete',
  },
};

export default ItemizedCard;
