import styled from 'styled-components';
import MaterialPaper from '@material-ui/core/Paper';
import MaterialIconButton from '@material-ui/core/IconButton';
import AdornedTextField from '../adornedTextField';
import MaterialTableCell from '@material-ui/core/TableCell';
import MaterialTableSortLabel from '@material-ui/core/TableSortLabel';
import MaterialTableBody from '@material-ui/core/TableBody';
import {
  AddBox as MaterialAddBox,
  Edit as MaterialEdit,
  Delete as MaterialDelete,
  Close as MaterialClose,
  Check as MaterialCheck,
  Search as MaterialSearch,
} from '../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Paper = styled(MaterialPaper)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableBody = styled(MaterialTableBody)`
  tr:nth-child(even) {
    background-color: #f9fdff;
  }
`;

export const TableCell = styled(MaterialTableCell)`
  text-align: center;
  background-color: ${(props) =>
    props.customColor ? props.customColor : 'transparent'};
`;

export const HeaderTableCell = styled(TableCell)`
  text-align: center;
  background-color: var(--liteBackground);
`;

export const ActionCell = styled(TableCell)`
  width: 150px;
  background-color: ${(props) =>
    props.customColor ? props.customColor : 'transparent'};
`;

export const DeleteConfirmationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  span {
    width: 80%;
    font-size: 20px;
  }
`;

export const TableSortLabel = styled(MaterialTableSortLabel)`
  padding-left: 22px !important;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
  h1 {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 22px;
  }
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchField = styled(AdornedTextField)`
  width: 300px;
  margin-right: 10px !important;
`;

export const IconButton = styled(MaterialIconButton)``;

export const ActionButton = styled(MaterialIconButton)``;

export const AddBox = styled(MaterialAddBox)`
  width: 27px;
  height: 27px;
  fill: var(--secondary);
`;

export const Edit = styled(MaterialEdit)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Delete = styled(MaterialDelete)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Save = styled(MaterialCheck)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Search = styled(MaterialSearch)`
  width: 25px;
  height: 25px;
  fill: #808080;
`;

export const FooterContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin: auto !important;
  }
`;
