const ExpenseTypeExpenseType = {
  actions: {
    getAllByDreFieldId: 'ExpenseTypeExpenseTypeController@getAllByDreFieldId',
    create: 'ExpenseTypeExpenseTypeController@create',
    delete: 'ExpenseTypeExpenseTypeController@delete',
  },
  tableSchema: [
    {
      id: 'name',
      label: 'Nome',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'operator',
      label: 'Operador',
      minWidth: 100,
      align: 'center',
    },
  ],
};

export default ExpenseTypeExpenseType;
