import styled from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
