import React from 'react';
import UnitFinance from '../../../../../forms/unitFinance';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container } from './styles';

function BankAccountCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={UnitFinance}
        restGetDataById={async (id) => {
          const result = await restApiClient.unitFinance.findById(id);
          let newResult = result.data;
          newResult.data = Object.assign({}, newResult.bankAccount, newResult);
          return newResult;
        }}
        restCreateData={restApiClient.unitFinance.create}
        restUpdateData={async (id, updateOriginalData, modelData) => {
          const result = await restApiClient.unitFinance.update(id, modelData);
          return result;
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default BankAccountCreateUpdate;
