import styled from 'styled-components';
import { 
  Box as MaterialBox,
  Send as MaterialSend,
  Printer as MaterialPrinter,
  AddToQueue as MaterialAddToQueue,
  Check as MaterialCheck,
  ExclamationTriangleFill as MaterialExclamationTriangleFill,
  Preview as MaterialPreview
} from '../../../../../../styles/icons';
import {
  Grid as MaterialGrid,
} from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddToQueue = styled(MaterialAddToQueue)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PrintOS = styled.div`
  @media screen {
    display: none
  }
`;

export const TextStyledH5 = styled.h5`
  font-family: Courier New, sans-serif !important;
  font-weight: normal;
`;

export const TextTitle = styled.h3`
  font-family: Courier New, sans-serif !important;
`;

export const Grid = styled(MaterialGrid)``;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DottedLine = styled.div`
  border-bottom: 1px dotted #696969;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: lighter;
`;

export const ExclamationTriangleFill = styled(MaterialExclamationTriangleFill)`
  width: 25px;
  height: 25px;
  fill: red;
`;

export const Check = styled(MaterialCheck)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
