import React, { useState, useEffect } from 'react';
import Calendar from '../../../forms/calendar';
import Holiday from '../../../forms/holiday';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import CalendarDialog from '../../../components/calendarDialog';
import { Container, AddBox, DateAdd } from './styles';

function CalendarList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ year: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const columns = Calendar.tableSchema;

  useEffect(() => {
    restApiClient.calendar
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('city', searchBy)
              .or()
              .like('year', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Calendários'}
        modelActions={Calendar.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            icon: <AddBox />,
            onClick: (id) => setOpen(true),
            title: 'Criar novo calendário',
            allowedActions: [Calendar.actions.create],
          },
          addHolidays: {
            icon: <DateAdd />,
            onClick: (calendarId) => {
              history.push(`/calendar/holidays/${calendarId}`);
            },
            title: 'Cadastrar datas',
            allowedActions: [Holiday.actions.getAllByCalendarId],
          },
        }}
      />

      <CalendarDialog
        open={open}
        calendar={Calendar}
        onCancel={() => {
          setOpen(false);
        }}
        onSuccess={(result) => {
          const calendar = result.data;
          const newRows = [...rows.values];
          newRows[newRows.length] = calendar;
          setRows({ ...rows, values: newRows });
          setOrderby({ year: 'desc' });
          setOpen(false);
        }}
        {...props}
      />
    </Container>
  );
}

export default CalendarList;
