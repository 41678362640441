import React, { useState, useEffect, useCallback } from 'react';
import LabConsumption from '../../../../../forms/labConsumption';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, Preview } from './styles';
import startCase from 'lodash/startCase';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';

function ThrowLabConsumptionUnitClinic(props) {
  const unitId = useSelector((x) => x.authReducer.user.unitId);
  const [labelUnit, setLabelUnit] = useState('');
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ endDate: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = LabConsumption.tableSchema;

  const loadIntervals = useCallback(() => {
    restApiClient.labConsumption
      .getAllByUnit(
        unitId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                rightChild.like('id', searchBy);
                leftChild.eq('status', 'Enviado');
              })
              .toString()
          : new FilterTree().eq('status', 'Enviado').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, unitId]);

  useEffect(() => {
    loadIntervals();
  }, [loadIntervals]);

  useEffect(() => {
    restApiClient.unit.findById(unitId).then((e) => {
      setLabelUnit(e.data.name);
    });
  }, []);

  return (
    <Container>
      <CrudTable
        title={`${startCase(labelUnit.toLowerCase())}`}
        modelActions={LabConsumption.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewConsumption: {
            onClick: (id, row) => {
              props.history.push({
                pathname: `/clinic/consumption/lab/view/${id}`,
                state: {
                  beginDate: row.beginDate,
                  endDate: row.endDate,
                  noEdit: true,
                },
              });
            },
            icon: <Preview />,
            title: 'Visualizar Consumo',
            allowedActions: [
              LabConsumption.valuesActions.getAllByLabConsumptionId,
            ],
            selector: (row) => row.status === 'Enviado',
          },
        }}
        customCellRenderers={{
          beginDate: {
            renderer: (field, value) => {
              return `${moment(value).format('DD/MM/YYYY')}`;
            },
          },
          endDate: {
            renderer: (field, value) => {
              return `${moment(value).format('DD/MM/YYYY')}`;
            },
          },
          total: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value
                ? value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default ThrowLabConsumptionUnitClinic;
