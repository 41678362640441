import React, { useState } from 'react';
import {
  Container,
  KeyboardDatePicker, 
  MuiPickersUtilsProvider
} from './styles';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';

function DateRangePickersComponent({ dateTo, dateFrom, handleChange, ...props }) {
  const [selectedDateTo, setSelectedDateTo] = useState(dateTo);
  const [selectedDateFrom, setSelectedDateFrom] = useState(dateFrom);
  
  return (
    <Container>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-from"
          inputVariant="outlined"
          size="small"
          label="Data Inicial"
          format="DD/MM/YYYY"
          value={selectedDateFrom}
          onChange={(date) => {
            setSelectedDateFrom(date?.toDate());
            handleChange?.(selectedDateTo, date?.toDate());
          }}
          KeyboardButtonProps={{
            'aria-label': 'Editar data',
          }}
          cancelLabel="Cancelar"
          okLabel="Selecionar"
          invalidDateMessage="Data em formato inválido."
          maxDate={selectedDateTo}
          maxDateMessage={`A data deve ser menor do que ${dayjs(
            selectedDateTo
          ).format('DD/MM/YYYY')}.`}
        />
        <KeyboardDatePicker
          margin="normal"
          id="date-to"
          inputVariant="outlined"
          size="small"
          label="Data Final"
          format="DD/MM/YYYY"
          value={selectedDateTo}
          onChange={(date) => {
            setSelectedDateTo(date.toDate());
            handleChange?.(date.toDate(), selectedDateFrom);
          }}
          KeyboardButtonProps={{
            'aria-label': 'Editar data',
          }}
          cancelLabel="Cancelar"
          okLabel="Selecionar"
          invalidDateMessage="Data em formato inválido."
          minDate={selectedDateFrom}
          minDateMessage={`A data deve ser maior que ${dayjs(
            selectedDateFrom
          ).format('DD/MM/YYYY')}.`}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
}

export default DateRangePickersComponent;
