import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsCellProps, withJsonFormsEnumProps } from '@jsonforms/react';
import SelectStepDialogControl from '../../components/selectStepDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectStepDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && 
      schema.format === 'selectStepDialog' &&
      schema.type === 'number'
  )
);

const customSelectStepDialogControlTester = rankWith(
  4,
  isSelectStepDialogControl
);

export default {
  tester: customSelectStepDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectStepDialogControl),
  cell: withJsonFormsCellProps(SelectStepDialogControl),
};
