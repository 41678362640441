/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Dentist from '../../forms/dentist';
import Chair from '../../forms/chair';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import {
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
  SubmitButton,
  AddBox,
} from './styles';

const AddDentistToChairDialog = ({
  onSuccess,
  onError,
  onCancel,
  chairId,
  dentists,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  let ignoreList = dentists;
  const columns = Dentist.tableSchema;

  useEffect(() => {
    restApiClient.dentist
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
            .like('name', searchBy)
            .or()
            .like('cro_number', searchBy)
            .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogContent>
        <StyledCrudTable
          title={'Dentistas'}
          modelActions={Dentist.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[5]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderBy(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderBy(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            selectDentist: {
              icon: <AddBox />,
              onClick: (id) => {
                restApiClient.chairDentist
                  .create({ chairId: chairId, dentistId: id })
                  .then((r) => {
                    ignoreList = [...ignoreList, r.data];
                    onSuccess(r.data);
                  });
              },
              allowedActions: [Chair.chairDentistActions.create],
              selector: (row) => !ignoreList?.some(function (obj) {
                return obj.dentistId === row.id;
              }),
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <SubmitButton
          onClick={() => {
            onCancel?.();
            setPage(0);
            setSearchBy('');
          }}
          type="button"
          variant="contained"
          color="primary"
        >
          Fechar
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddDentistToChairDialog;
