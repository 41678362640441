import styled from 'styled-components';
import {
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
} from '@material-ui/core';
import { Button as MaterialButton } from '@material-ui/core';
import MaterialDialogActions from '@material-ui/core/DialogActions';

export const ContentContainer = styled.div`
  width: 350px;
  height: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    min-height: 0px !important;
  }
  margin-bottom: 15px;
`;

export const ErrorSpan = styled.span`
  color: red;
  margin-right: 20px;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  margin-bottom: 5px;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const DialogActions = styled(MaterialDialogActions)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 5px;
`;
