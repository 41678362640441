/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import IntlCurrencyInput from 'react-intl-currency-input';
import { TextField } from './styles';
import { Hidden } from '@material-ui/core';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

function BrlIntlCurrencyInput({ onChange, value, allowNegative, ...props }) {
  const [negative, setNegative] = useState(false);
  if (value == 0 && negative) {
    value = -0.0000000001;
  }
  return (
    <IntlCurrencyInput
      currency="BRL"
      config={currencyConfig}
      onKeyPress={(e, char, code) => {
        if (Math.abs(value) > 0 && Math.abs(value) < 0.000000001) {
          value = 0;
        }
        if (value === 0 && char === 'Backspace' && allowNegative) {
          setNegative(false);
        }
        if (char === '-' && allowNegative) {
          setNegative(!negative);
        }
      }}
      value={value}
      onChange={(e, value, maskedValue) => {
        if (Math.abs(value) > 0 && Math.abs(value) < 0.000000001) {
          value = 0;
        }
        if ((negative && value > 0) || (!negative && value < 0)) {
          onChange(e, value * -1, `- ${maskedValue}`);
        } else if (negative) {
          onChange(e, value, `- ${maskedValue}`);
        } else {
          onChange(e, value, maskedValue);
        }
      }}
      {...props}
    />
  );
}

function MoneyInput({
  id,
  handleChange,
  description,
  className,
  errors,
  label,
  enabled = true,
  schema,
  uischema,
  visible = true,
  required,
  config,
  input,
  inputProps,
  onChange,
  path,
  data,
  allowNegative,
  ...props
}) {
  const isValid = !errors || errors?.length === 0;
  const formHelperText = !isValid ? errors : null;
  const activateAllowNegative = allowNegative || schema?.allowNegative;
  return (
    <Hidden xsUp={!visible}>
      <TextField
        id={id}
        className={className}
        disabled={!enabled}
        label={label}
        value={data ?? null}
        required={required}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: BrlIntlCurrencyInput,
          ...inputProps,
        }}
        inputProps={{
          allowNegative: activateAllowNegative,
          ...inputProps,
        }}
        onChange={(e, value, maskedValue) => {
          handleChange?.(path, value);
          onChange?.(e, value, maskedValue);
        }}
        error={!isValid}
        helperText={!isValid ? formHelperText : null}
        {...props}
      />
    </Hidden>
  );
}

export default MoneyInput;
