const PurchaseEntry = {
  tableSchema: [
    {
      id: 'item.id',
      label: 'ID',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'item.name',
      label: 'Nome do Item',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'item.brand',
      label: 'Marca do Item',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'purchaseQuantity',
      label: 'Quantidade Compra',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'receivedQuantity',
      label: 'Quantidade Recebida',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    manualEntry: 'PurchaseEntryItemController@manualEntry',
    getAllUnregisteredItemPending:
      'PurchaseEntryItemController@getAllUnregisteredItemPending',
    getAllUnregisteredItemPendingByPurchaseId:
      'PurchaseEntryItemController@getAllUnregisteredItemPendingByPurchaseId',
    findUnregisteredItemPendingById:
      'PurchaseEntryItemController@findUnregisteredItemPendingById',
    getAllByPurchase: 'PurchaseEntryItemController@getAllByPurchase',
    getAllByItem: 'PurchaseEntryItemController@getAllByItem',
  },
};
export default PurchaseEntry;
