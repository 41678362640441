/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import ItemizedCheck from '../../forms/itemizedCheck';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import isEmpty from 'lodash/isEmpty';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';
import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  MoneyCheck,
} from './styles';
import { SelectItemizedCheck } from '../switchOperationTypeDialog/styles';

const SelectItemizedCheckDialogControl = ({
  data,
  className,
  id,
  enabled,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible,
  disabled,
  required,
  customStatus,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedItemizedCheck, setSelectedItemizedCheck] = React.useState({});
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = ItemizedCheck.dialogTableSchema;

  const handleClickOpen = () => {
    if (enabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    restApiClient.itemizedCheck
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? customStatus
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp.in('status', customStatus);
                  rightOp
                    .like('number', searchBy)
                    .or()
                    .like('value', searchBy)
                    .or()
                    .like('name', searchBy);
                })
                .toString()
            : new FilterTree()
                .like('number', searchBy)
                .or()
                .like('status', searchBy)
                .or()
                .like('value', searchBy)
                .or()
                .like('name', searchBy)
                .toString()
          : customStatus
          ? new FilterTree().in('status', customStatus).toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, customStatus]);

  useEffect(() => {
    if (data) {
      restApiClient.itemizedCheck.findById(data).then((e) => {
        setSelectedItemizedCheck(e.data);
      });
    } else {
      setSelectedItemizedCheck({});
    }
  }, [data]);

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          className={className}
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<MoneyCheck />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label={label}
          InputLabelProps={{
            readOnly: true,
          }}
          required={required}
          value={
            selectedItemizedCheck.number
              ? `${selectedItemizedCheck.number}`
              : ''
          }
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
          {...props}
        />

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <StyledCrudTable
              title={'Seleção de Cheque'}
              modelActions={ItemizedCheck.actions}
              columns={columns}
              rows={rows}
              page={page}
              setPage={(page) => setPage(page)}
              rowsPerPage={rowsPerPage}
              availableRowsPerPage={[3]}
              setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              onOrderByChange={(orderBy) => setOrderby(orderBy)}
              orderBy={orderBy}
              setOrderBy={(value) => setOrderby(value)}
              searchBy={searchBy}
              setSearchBy={(value) => setSearchBy(value)}
              actions={{
                insert: {
                  icon: <AddBox />,
                  onClick: (id, data) => {
                    setSelectedItemizedCheck(data);
                    handleChange?.(path, id);
                    onSuccess?.(id, data.value);
                    handleClose();
                  },
                  title: 'Vincular',
                  allowedActions: [ItemizedCheck.actions.getAll],
                },
              }}
              customCellRenderers={{
                value: {
                  renderer: (field, value) => {
                    return value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    });
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Hidden>
  );
};

export default SelectItemizedCheckDialogControl;
