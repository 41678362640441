import React from 'react';
import extenso from 'extenso';
import {
  ReceiptMain,
  ReceiptTitle,
  ReceiptSection,
  ReceiptSignature,
  Text,
} from './styles';

function ExpenseReceipt({ expense }) {
  let supplierOrEmployee = '';
  if (expense?.employeeId) {
    supplierOrEmployee = expense?.employee?.user?.name;
  } else if (expense?.patientId) {
    supplierOrEmployee = expense?.patient?.name;
  } else if (expense?.expensable) {
    supplierOrEmployee = expense?.expensable?.isPhysicalPerson
      ? expense?.expensable?.socialName
      : expense?.expensable?.fantasyName;
  } else {
    supplierOrEmployee = '-';
  }

  if (expense) {
    return (
      <ReceiptMain>
        <ReceiptTitle>Recibo</ReceiptTitle>

        <ReceiptSection>
          <Text>
            <b>Número:</b> {expense?.expenseId}
          </Text>
        </ReceiptSection>

        <ReceiptSection>
          <Text>
            <b>Valor:</b>{' '}
            {expense?.value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
        </ReceiptSection>

        <ReceiptSection>
          <Text>
            Recebi(emos) de OdontoClinic a importância de{' '}
            {expense?.value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}{' '}
            (
            {extenso(expense?.value.toLocaleString('pt-br'), {
              mode: 'currency',
              currency: { type: 'BRL' },
            })}
            ), referente a {expense?.observation}.
          </Text>
        </ReceiptSection>

        <ReceiptSection>
          <Text>
            <b>Emitente:</b> {supplierOrEmployee}
          </Text>
        </ReceiptSection>

        <br></br>

        <ReceiptSignature>
          <Text>
            <b>_______________________________________</b>
          </Text>
          <Text>Assinatura</Text>
        </ReceiptSignature>

        <br></br>

        <ReceiptSection>
          <Text>
            <b>
              Emissão {new Date().toLocaleDateString('pt-br')} às{' '}
              {new Date().toLocaleTimeString('pt-br')}
            </b>
          </Text>
        </ReceiptSection>
      </ReceiptMain>
    );
  } else {
    return '';
  }
}

export default ExpenseReceipt;
