import styled from 'styled-components';
import {
  LockPassword as MaterialLockPassword,
  CardChecklist as MaterialCardChecklist,
  Restore as MaterialRestore,
  Close as MaterialClose,
} from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const LockPassword = styled(MaterialLockPassword)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CardChecklist = styled(MaterialCardChecklist)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
