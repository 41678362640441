const OrderService = {
  schema: {
    type: 'object',
    properties: {
      recordNumber: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
        recordNumber: '',
      },
      name: {
        type: 'string',
        minLength: 3,
        customErrors: '',
      },
      endProductId: {
        type: 'number',
        format: 'selectEndProductDialog',
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      patientId: {
        type: 'number',
        customErrors: '',
      },
      requestingUnitBoolean: {
        type: 'boolean',
        customErrors: '',
      },
      patientLoaded: {
        type: 'boolean',
      },
    },
    required: ['recordNumber', 'name', 'endProductId'],
  },
  uischema: {
    type: 'Group',
    label: 'Criação de Ordem de Serviço',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade do Paciente',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número do Prontuário',
            scope: '#/properties/recordNumber',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/requestingUnitBoolean',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome do Paciente',
            scope: '#/properties/name',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/requestingUnitBoolean',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Produto Final',
            scope: '#/properties/endProductId',
          },
        ],
        rule: {
          effect: 'SHOW',
          condition: {
            scope: '#/properties/patientLoaded',
            schema: {
              const: true,
            },
          },
        },
      },
    ],
  },
  tableSchema: [
    {
      id: 'orderService.id',
      label: 'Código Interno',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.name',
      label: 'Paciente',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.endProduct.name',
      label: 'Produto Final',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
  ],
  dentistTableSchema: [
    {
      id: 'orderService.id',
      label: 'Código Interno',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.name',
      label: 'Paciente',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.endProduct.name',
      label: 'Produto Final',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'unit.name',
      label: 'Unidade',
      minWidth: 25,
      align: 'center',
    },
  ],
  labAllOSTableSchema: [
    {
      id: 'orderServiceStep.id',
      label: 'Id Etapa',
      minWidth: 25,
      align: 'center',
    },
    { id: 'patient.name', label: 'Paciente', minWidth: 25, align: 'center' },
    {
      id: 'patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.dentistUser.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'endProduct.name',
      label: 'Produto Final',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.unit.name',
      label: 'Unidade',
      minWidth: 25,
      align: 'center',
    },
  ],
  labAllRefusedOSTableSchema: [
    { id: 'id', label: 'Id Etapa', minWidth: 25, align: 'center' },
    {
      id: 'orderService.patient.name',
      label: 'Paciente',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'dentistUser.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.endProduct.name',
      label: 'Produto Final',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'unit.name',
      label: 'Unidade',
      minWidth: 25,
      align: 'center',
    },
  ],
  clinicOSPendingTableSchema: [
    {
      id: 'id',
      label: 'Id Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.name',
      label: 'Paciente',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.endProduct.name',
      label: 'Produto Final',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
  ],
  clinicTableSchema: [
    { id: 'id', label: 'Id OS', minWidth: 25, align: 'center' },
    {
      id: 'orderServiceStep.id',
      label: 'Id Etapa',
      minWidth: 25,
      align: 'center',
    },
    { id: 'patient.name', label: 'Paciente', minWidth: 25, align: 'center' },
    {
      id: 'patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.dentistUser.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'endProduct.name',
      label: 'Produto Final',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.schedule.patientReturnDate',
      label: 'Data de Retorno (Paciente)',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.schedule.labReturnDate',
      label: 'Data de Retorno (Laboratório)',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderServiceStep.status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
  ],
  labOSPendingTableSchema: [
    { id: 'id', label: 'Id Etapa', minWidth: 25, align: 'center' },
    {
      id: 'orderService.patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.name',
      label: 'Paciente',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'schedule.labReturnDate',
      label: 'Data Laboratório',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.suggestedExecutors',
      label: 'Executor Sugerido',
      minWidth: 25,
      align: 'center',
    },
  ],
  labTableSchema: [
    { id: 'id', label: 'Id Etapa', minWidth: 25, align: 'center' },
    {
      id: 'orderService.patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'schedule.labReturnDate',
      label: 'Data Laboratório',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Situação',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'executor',
      label: 'Executor',
      minWidth: 25,
      align: 'center',
    },
  ],
  labOSCompletedLaboratoryTableSchema: [
    { id: 'id', label: 'Id Etapa', minWidth: 25, align: 'center' },
    {
      id: 'orderService.patient.name',
      label: 'Paciente',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.patient.recordNumber',
      label: 'Prontuário',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'orderService.endProduct.name',
      label: 'Produto Final',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'step.name',
      label: 'Etapa',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'schedule.labReturnDate',
      label: 'Data de Retorno (Laboratório)',
      minWidth: 25,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'OrderServiceController@getAll',
    getAllOrderServiceRefused:
      'OrderServiceController@getAllOrderServiceRefused',
    getAllByClinic: 'OrderServiceController@getAllByClinic',
    findById: 'OrderServiceController@findById',
    create: 'OrderServiceController@create',
    delete: 'OrderServiceController@delete',
  },
};

export default OrderService;
