import React, { useState, useEffect, useCallback } from 'react';
import Sale from '../../../forms/sale';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import SeparateSaleItemsDialog from '../../../components/separateSaleItemsDialog';
import SecuredItem from '../../../components/securedItem';
import { isEqualWith, cloneDeep, isEmpty } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {
  Container,
  SaveButton,
  PaymentButton,
  SaveIcon,
  StyledTextFieldInput,
  StyledMoneyInput,
  StyledTypography,
  CancelButton,
  StyledPercentageInput,
  Money,
} from './styles';
import Item from '../../../forms/item';
import PaymentDialog from '../../../components/paymentDialog';
import { toFixed } from '../../../extensions/object';

function SaleList({ match, history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [originalItemList, setOriginalItemList] = useState([]);
  const [sale, setSale] = useState({});
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [total, setTotal] = useState(0);
  const [manualValue, setManualValue] = useState(false);
  const [closeOperation, setCloseOperation] = useState(false);
  const saleId = match.params?.id;
  const columns = Sale.SaleItemsTableSchema;

  const handleAlertClose = () => setAlert({ message: '', severity: '' });

  const rebuildTotal = (items) => {
    setTotal(
      items.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          (currentValue.transferQuantity ? currentValue.transferQuantity : 0) *
            (currentValue.price ? currentValue.price : 0)
        );
      }, 0)
    );
  };

  useEffect(() => {
    restApiClient.sale.findById(saleId).then((e) => {
      setSale(e.data);
    });
  }, [saleId, open, closeOperation]);

  const loadSaleItems = useCallback(() => {
    restApiClient.saleItem
      .getAllBySaleId(
        saleId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('item.name', searchBy)
              .or()
              .like('item.brand', searchBy)
              .or()
              .like('item.itemLocators.locator', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        newRows.values = e.data.values.map((rowItem) => {
          if (!isEmpty(rowItem?.item.profitMarginIndividual)) {
            rowItem.originalPrice = toFixed(
              rowItem.item.price +
                rowItem.item.price *
                  (rowItem?.item.profitMarginIndividual.profitMargin / 100),
              2
            );
          } else if (!isEmpty(rowItem?.item.group?.profitMarginCategory)) {
            rowItem.originalPrice = toFixed(
              rowItem.item.price +
                rowItem.item.price *
                  (rowItem?.item.group?.profitMarginCategory.profitMargin /
                    100),
              2
            );
          } else {
            rowItem.originalPrice = toFixed(parseFloat(rowItem.item.price), 2);
          }

          if (!rowItem.price) {
            rowItem.price = parseFloat(rowItem.originalPrice);
          }

          if (!rowItem.transferQuantity) {
            rowItem.transferQuantity = null;
          }

          rowItem.discount = null;
          rowItem.costPrice = rowItem?.item?.price;
          return rowItem;
        });
        setRows(newRows);
        setItemList(e.data.values);
        setOriginalItemList(cloneDeep(e.data.values));
        rebuildTotal(e.data.values);
      });
  }, [saleId, rowsPerPage, page, searchBy, orderBy]);

  useEffect(() => {
    loadSaleItems();
  }, [loadSaleItems]);

  const salvar = async () => {
    if (!isEmpty(itemList) || !isEmpty(originalItemList)) {
      const isValid = itemList.reduce(
        (acc, value) =>
          acc &&
          value.price >= value.item.price &&
          value.transferQuantity <= value.item?.stock[0].availableQuantity &&
          value.transferQuantity != null &&
          value.transferQuantity > 0,
        true
      );

      if (isValid) {
        const added = itemList.filter(
          (item) =>
            !originalItemList.some((obj) =>
              isEqualWith(
                obj,
                item,
                (obj1, obj2) =>
                  obj1.id === obj2.id &&
                  obj1.itemId === obj2.itemId &&
                  obj1.transferQuantity === obj2.transferQuantity &&
                  obj1.saleId === obj2.saleId &&
                  obj1.price === obj2.price &&
                  obj1.costPrice === obj2.costPrice
              )
            )
        );

        const removed = originalItemList
          .filter(
            (item) =>
              !itemList.some((obj) =>
                isEqualWith(
                  obj,
                  item,
                  (obj1, obj2) =>
                    obj1.id === obj2.id &&
                    obj1.itemId === obj2.itemId &&
                    obj1.transferQuantity === obj2.transferQuantity &&
                    obj1.saleId === obj2.saleId &&
                    obj1.price === obj2.price &&
                    obj1.costPrice === obj2.costPrice
                )
              )
          )
          .map((item) => item.id);

        if (!isEmpty(added) || !isEmpty(removed)) {
          restApiClient.sale
            .update(saleId, sale, sale, added, removed)
            .then((result) => {
              if (result.status === 200) {
                loadSaleItems();
                setAlert({
                  message: 'A transferência foi salva com sucesso!',
                  severity: 'success',
                });
              }
            });
        } else {
          setAlert({
            message: 'Sem alterações na transferência.',
            severity: 'warning',
          });
        }
      } else {
        setAlert({
          message: 'Transferência não pode ser salva.',
          severity: 'error',
        });
      }
    } else {
      setAlert({
        message: 'Nenhum item adicionado a listagem!',
        severity: 'error',
      });
    }
  };

  const finalizeOperation = async () => {
    if (!isEmpty(itemList)) {
      const validationFields = itemList.reduce(
        (acc, value) =>
          acc &&
          value.price >= value.item.price &&
          value.transferQuantity <= value.item?.stock[0].availableQuantity &&
          (value.transferQuantity != null ? value.transferQuantity > 0 : true),
        true
      );

      const isValid = itemList.reduce(
        (acc, value) =>
          acc && value.price != null && value.transferQuantity != null,
        true
      );

      if (validationFields && isValid) {
        const added = itemList.filter(
          (item) =>
            !originalItemList.some((obj) =>
              isEqualWith(
                obj,
                item,
                (obj1, obj2) =>
                  obj1.id === obj2.id &&
                  obj1.itemId === obj2.itemId &&
                  obj1.transferQuantity === obj2.transferQuantity &&
                  obj1.saleId === obj2.saleId &&
                  obj1.price === obj2.price &&
                  obj1.costPrice === obj2.costPrice
              )
            )
        );

        const removed = originalItemList
          .filter(
            (item) =>
              !itemList.some((obj) =>
                isEqualWith(
                  obj,
                  item,
                  (obj1, obj2) =>
                    obj1.id === obj2.id &&
                    obj1.itemId === obj2.itemId &&
                    obj1.transferQuantity === obj2.transferQuantity &&
                    obj1.saleId === obj2.saleId &&
                    obj1.price === obj2.price &&
                    obj1.costPrice === obj2.costPrice
                )
              )
          )
          .map((item) => item.id);

        await restApiClient.sale
          .update(saleId, sale, sale, added, removed)
          .then(async (r) => {
            setOpenPayment(true);
          });
      } else {
        setAlert({
          message:
            'Para finalizar uma operação de transfêrencia ' +
            'é necessário preencher todos os campos da lista.',
          severity: 'error',
        });
      }
    } else {
      setAlert({
        message: 'Nenhum item adicionado a listagem!',
        severity: 'error',
      });
    }
  };

  return (
    <Container>
      <CrudTable
        title={'Gerenciar Itens'}
        customInfosComponent={
          <StyledTypography variant="h6">
            Valor total:{' '}
            {total?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledTypography>
        }
        customFooterComponent={
          <>
            {sale?.status !== 'completed_operation' ? (
              <>
                <CancelButton
                  onClick={() => history.goBack()}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Voltar
                </CancelButton>
                <SecuredItem allowedActions={[Sale.actions.update]}>
                  <SaveButton
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={salvar}
                  >
                    Salvar
                  </SaveButton>
                </SecuredItem>
                <SecuredItem
                  allowedActions={[
                    Sale.actions.update,
                    Sale.actions.completeTransfer,
                  ]}
                >
                  <PaymentButton
                    variant="contained"
                    color="primary"
                    startIcon={<Money />}
                    onClick={finalizeOperation}
                  >
                    Pagamento
                  </PaymentButton>
                </SecuredItem>
              </>
            ) : (
              <CancelButton
                onClick={() => history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Voltar
              </CancelButton>
            )}
          </>
        }
        modelActions={Sale.actions}
        columns={columns}
        customColumns={[
          {
            id: 'transferQuantity',
            label: 'Qtd',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'price',
            label: 'Valor',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'discount',
            label: 'Desconto',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'subtotal',
            label: 'Subtotal',
            minWidth: 50,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={
          sale.status !== 'completed_operation'
            ? {
                add: {
                  onClick: () => {
                    setOpen(true);
                  },
                  allowedActions: [
                    Sale.actions.createSaleItem,
                    Item.actions.getAllWithStock,
                  ],
                  selector: () => sale.status !== 'completed_operation',
                },
                delete: {
                  onClick: (id, index) => {
                    restApiClient.saleItem.delete(id).then(() => {
                      const result = { ...rows };
                      if (index > -1) {
                        result.values.splice(index, 1);
                        result.count--;
                      }
                      setRows(result);
                      const newItemList = [...itemList];
                      if (index > -1) {
                        newItemList.splice(index, 1);
                      }
                      setItemList(newItemList);

                      const newOriginalItemList = [...originalItemList];
                      if (index > -1) {
                        newOriginalItemList.splice(index, 1);
                      }
                      setOriginalItemList(newOriginalItemList);

                      rebuildTotal(newItemList);
                    });
                  },
                  allowedActions: [Sale.actions.deleteSaleItem],
                  selector: () => sale.status !== 'completed_operation',
                },
              }
            : null
        }
        customCellRenderers={{
          'item.stock': {
            renderer: (field, value) => {
              return value[0]?.availableQuantity
                ? value[0]?.availableQuantity
                : '-';
            },
          },
          'item.itemLocators': {
            renderer: (field, value) => {
              return value[0]?.locator ?? '-';
            },
          },
          transferQuantity: {
            renderer: (field, value, column, row) => {
              let itemFind = itemList.find((x) => x.id === row.id);
              let transferQuantity;
              if (itemFind) transferQuantity = itemFind?.transferQuantity;
              else transferQuantity = value;
              let error;
              if (sale.status === 'operation_in_progress')
                error =
                  transferQuantity > row.item?.stock[0].availableQuantity ||
                  (transferQuantity != null && transferQuantity < 1);
              return sale.status !== 'completed_operation' ? (
                <StyledTextFieldInput
                  key={row.id}
                  value={transferQuantity}
                  helperText={error ? 'Qtd inválida.' : ''}
                  error={error}
                  onChange={(e) => {
                    let item;
                    const newList = [...itemList];
                    if ((item = newList.find((x) => x.id === row.id))) {
                      item.transferQuantity = parseInt(e.target.value);
                    } else {
                      const item = {
                        itemId: row.id,
                        transferQuantity: parseInt(e.target.value),
                        saleId: row.saleId,
                        price: row.price,
                        costPrice: row.costPrice,
                      };
                      newList.push(item);
                    }
                    row.transferQuantity =
                      e.target.value !== '' ? parseInt(e.target.value) : null;
                    setItemList(newList);
                    rebuildTotal(newList);
                  }}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      style: { textAlign: 'center' },
                    },
                  }}
                />
              ) : (
                transferQuantity
              );
            },
          },
          price: {
            renderer: (field, value, column, row) => {
              let itemFind = itemList.find((x) => x.id === row.id);
              let price;
              if (itemFind) price = itemFind?.price;
              else price = value;
              const error = price < (row.item?.price ?? 0);
              return sale.status !== 'completed_operation' ? (
                <StyledMoneyInput
                  value={price}
                  helperText={error ? 'Valor inválido.' : ''}
                  error={error}
                  onChange={(e, value) => {
                    if (value < row.originalPrice) {
                      row.discount = (
                        ((row.originalPrice - value) / row.originalPrice) *
                        100
                      )
                        .toFixed(2)
                        .toString()
                        .replace('.', ',');
                    } else {
                      row.discount = '';
                    }
                    setManualValue(true);
                    let item;
                    const newList = [...itemList];
                    if ((item = newList.find((x) => x.id === row.id))) {
                      item.price = value;
                    } else {
                      const item = {
                        id: row.id,
                        itemId: row.itemId,
                        transferQuantity: row.transferQuantity,
                        saleId: row.saleId,
                        price: value,
                        costPrice: row.costPrice,
                      };
                      newList.push(item);
                    }
                    row.price = value ? value : null;
                    setItemList(newList);
                    rebuildTotal(newList);
                  }}
                  inputProps={{
                    style: { textAlign: 'center' },
                  }}
                />
              ) : (
                (price ?? 0).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })
              );
            },
          },
          discount: {
            renderer: (field, value, column, row) => {
              let formattedDiscount = 0;
              let itemFind = itemList.find((x) => x.id === row.id);
              let discount;
              if (itemFind?.discount) discount = itemFind?.discount;
              else if (row.price < row.originalPrice) {
                discount = (
                  (
                    (row.originalPrice.toPrecision(4) - row.price.toFixed(4)) /
                    row.originalPrice
                  ).toPrecision(3) * 100
                )
                  .toFixed(2)
                  .toString()
                  .replace('.', ',');
              } else discount = value;

              if (discount)
                formattedDiscount =
                  parseFloat(discount.replace(',', '.')) / 100;

              return sale.status !== 'completed_operation' ? (
                <StyledPercentageInput
                  key={row.id}
                  value={discount}
                  onChange={(e) => {
                    if (!manualValue) {
                      if (e.target.value !== row.discount) {
                        if (e.target.value != 0) {
                          row.price = toFixed(
                            row.originalPrice -
                              row.originalPrice * (e.target.value / 100),
                            2
                          );
                        } else {
                          row.price = row.originalPrice;
                        }
                        let item;
                        const newList = [...itemList];
                        if ((item = newList.find((x) => x.id === row.id))) {
                          item.price = row.price;
                        } else {
                          const item = {
                            id: row.id,
                            itemId: row.itemId,
                            transferQuantity: row.transferQuantity,
                            saleId: row.saleId,
                            price: row.price,
                            costPrice: row.costPrice,
                          };
                          newList.push(item);
                        }
                        setItemList(newList);
                        rebuildTotal(newList);
                      }
                      row.discount = e.target.value.replace('.', ',');
                    } else {
                      setManualValue(false);
                    }
                  }}
                  inputProps={{
                    style: { textAlign: 'center' },
                  }}
                />
              ) : (
                formattedDiscount.toLocaleString('pt-br', {
                  style: 'percent',
                  minimumFractionDigits: 2,
                })
              );
            },
          },
          subtotal: {
            renderer: (field, value, column, row) => {
              value = row.price * row.transferQuantity;
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>

      <SeparateSaleItemsDialog
        open={open}
        sale={sale}
        onCancel={() => {
          setOpen(false);
        }}
        onSuccess={() => {
          loadSaleItems();
        }}
      />

      <PaymentDialog
        open={openPayment}
        total={total.toFixed(2)}
        sale={sale}
        onCancel={() => {
          setOpenPayment(false);
          loadSaleItems();
          setCloseOperation(true);
        }}
        onSuccess={async (paymentId, buyerAddressId) => {
          await restApiClient.sale
            .completeTransfer(sale.id, {
              paymentId: paymentId,
              buyerAddressId: buyerAddressId,
            })
            .then((result) => {
              if (result.status === 200) {
                setOpenPayment(false);
                loadSaleItems();
                setCloseOperation(true);
                setAlert({
                  message: 'Transferência realizada com sucesso.',
                  severity: 'success',
                });
              }
            });
        }}
      />
    </Container>
  );
}

export default SaleList;
