/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Container,
  CancelButton,
  SubmitButton,
  DialogContent,
  Card,
  Dialog,
  DialogActions,
  ActionsContainer,
  HeaderContainer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CardHeader,
  Checkbox,
  SelectDentist,
  Hidden,
  SelectUnit,
} from './styles';
import first from 'lodash/first';
import { useSelector } from 'react-redux';
import CustomAttendanceDate from '../customAttendanceDateControl';
import isEmpty from 'lodash/isEmpty';

function SwitchScheduleTypeDialog({
  onSuccess,
  onError,
  onCancel,
  attendance,
  dentist,
  ...props
}) {
  const loggedDentist = useSelector((state) => state.authReducer?.user);
  const [type, setType] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [replacedDentist, setReplacedDentist] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateUnitId, setDateUnitId] = useState(null);
  const [dentistScheduleTypes, setDentistScheduleTypes] = useState([]);

  useEffect(() => {
    const scheduleTypes = dentist.dentistScheduleTypes
      .filter(
        (x) =>
          x.type === 'Fixo' ||
          x.type === 'Procedimentos' ||
          x.type === 'Pacientes'
      )
      .map((x) => x.type);

    if (scheduleTypes.length < 2) {
      setType(first(scheduleTypes));
      setDisabled(true);
    } else {
      setType(attendance?.type);
    }
    setDentistScheduleTypes(scheduleTypes);
    setChecked(!!attendance?.replacedDentistId);
    setReplacedDentist(attendance?.replacedDentistId);
    setDateUnitId(attendance?.unitId ?? loggedDentist?.favoriteUnitId);
  }, [dentist, attendance, disabled]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSuccess?.(
                type,
                checked ? replacedDentist : null,
                selectedDate,
                dateUnitId
              );
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title={'Lançar Procedimento(s)'} />
              </HeaderContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                    name="checked"
                  />
                }
                label="Substituto"
              />
              <FormControl component="fieldset">
                <FormLabel component="legend" required>
                  Tipo de Agenda
                </FormLabel>
                <RadioGroup
                  row={true}
                  aria-label="type"
                  name="type"
                  value={type ?? null}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  {dentistScheduleTypes.includes('Procedimentos') ? (
                    <FormControlLabel
                      value="Procedimentos"
                      control={<Radio required />}
                      label="Procedimentos"
                      disabled={disabled}
                    />
                  ) : null}
                  {dentistScheduleTypes.includes('Pacientes') ? (
                    <FormControlLabel
                      value="Pacientes"
                      control={<Radio required />}
                      label="Pacientes"
                      disabled={disabled}
                    />
                  ) : null}
                  {dentistScheduleTypes.includes('Fixo') ? (
                    <FormControlLabel
                      value="Fixo"
                      control={<Radio required />}
                      label="Fixo"
                      disabled={disabled}
                    />
                  ) : null}
                </RadioGroup>
              </FormControl>
              {checked ? (
                <SelectDentist
                  data={replacedDentist}
                  label="Dentista Substituído"
                  size="small"
                  selectedDentist={loggedDentist.id}
                  onSuccess={(id) => setReplacedDentist(id)}
                  visible
                  enabled
                  required
                />
              ) : null}
              <SelectUnit
                size="small"
                visible
                enabled={isEmpty(attendance)}
                required
                data={dateUnitId}
                onSuccess={(id) => {
                  setDateUnitId(id);
                }}
              />
              <Hidden xsUp={!dateUnitId}>
                <CustomAttendanceDate
                  data={selectedDate ?? attendance?.attendanceDate}
                  label="Data Atendimento"
                  size="small"
                  onSuccess={(date) => setSelectedDate(date)}
                  visible
                  enabled
                  required
                  cancelLabel="Cancelar"
                  okLabel="Confirmar"
                  forceUnitId={dateUnitId}
                />
              </Hidden>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    onCancel?.();
                    setType(null);
                    setDisabled(false);
                    setChecked(false);
                    setReplacedDentist(null);
                    setSelectedDate(null);
                    setDateUnitId(null);
                  }}
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Selecionar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default SwitchScheduleTypeDialog;
