import styled from 'styled-components';
import {
  Box as MaterialBox,
  Send as MaterialSend,
  Printer as MaterialPrinter,
  AddToQueue as MaterialAddToQueue,
  Preview as MaterialPreview,
  Check as MaterialCheck, 
  Restore as MaterialRestore,
  Cancel as MaterialCancel
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddToQueue = styled(MaterialAddToQueue)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PrintOS = styled.div`
  @media screen {
    display: none
  }
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Check = styled(MaterialCheck)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Cancel = styled(MaterialCancel)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
