import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const FooterContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin: auto !important;
  }
`;

export const BackButton = styled(Button)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
