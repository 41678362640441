const Specialty = {
  actions: {
    create: 'SpecialtyController@create',
    getAll: 'SpecialtyController@getAll',
    getAllTree: 'SpecialtyController@getAllTree',
    getAllBySpecialtyDentist: 'SpecialtyController@getAllBySpecialtyDentist',
    findById: 'SpecialtyController@findById',
    update: 'SpecialtyController@patchUpdate',
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
  ],
  listSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo de Despesa',
      minWidth: 100,
      align: 'center',
    },
  ],
};

export default Specialty;
