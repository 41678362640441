import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

const ReportBankAccountMovements = {
  buildPdf: (from, to, result) => {
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;

    doc.setFont('Arial');
    doc.setFontSize(10);
    doc.text(`Período: ${from} a ${to}`, 160, (y += 8));
    doc.text(
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds(),
      160,
      (y += 5)
    );
    doc.setFontSize(18);
    doc.text(`Movimentações`, doc.internal.pageSize.getWidth() / 2, (y += 10), {
      align: 'center',
    });
    y += 3;
    //CORPO DO PDF
    doc.setLineWidth(0.3);
    doc.setDrawColor(0, 0, 0);
    y += 10;
    doc.setFontSize(11);
    doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
    y += 1;
    if (y >= doc.internal.pageSize.height - 25) {
      doc.addPage();
      y = 5;
    }

    if (result?.type === 'Banco') {
      doc.text(`Titular: ${result?.bankAccount?.holderName}`, 5, (y += 4));
      y += 0.75;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(`Banco: ${result?.bankAccount?.bank}`, 5, (y += 4));
      y += 0.75;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(
        `Agência-Dígito: ${result?.bankAccount?.agency}${
          result?.bankAccount?.agencyDigit
            ? `-${result?.bankAccount?.agencyDigit}`
            : ''
        }`,
        5,
        (y += 4)
      );
      y += 0.75;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(
        `Conta-Dígito: ${result?.bankAccount?.account}${
          result?.bankAccount?.accountDigit
            ? `-${result?.bankAccount?.accountDigit}`
            : ''
        }`,
        5,
        (y += 4)
      );
      y += 0.75;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(`Unidade: ${result?.unit?.name}`, 5, (y += 4));
      y += 0.75;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
    } else {
      doc.text(
        `Tipo: ${
          result?.type === 'Caixa'
            ? `${result?.type} Financeiro`
            : result?.type === 'Cheque'
            ? `${result?.type}`
            : '-'
        }`,
        5,
        (y += 4)
      );
      y += 0.75;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(`Unidade: ${result?.unit?.name}`, 5, (y += 4));
      y += 0.75;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
    }
    y += 2;
    if (y >= doc.internal.pageSize.height - 10) {
      doc.addPage();
      y = 5;
    }
    doc.setFontSize(10);
    let bodyValues;
    let columnValues = [];
    let columns = ['Dia', 'Operação', 'Fornecedor', 'Valor'];

    const sortedAccountHistories = sortBy(
      result.accountHistories,
      'date'
    );

    //CORPO DO PDF
    forEach(sortedAccountHistories, function (accountHistory, index) {
      bodyValues = [];
      // PARA CADA DATA
      let date = Object.keys(result.accountHistories)[index];
      let previousDate = moment(date).subtract(1, 'days');
      let initialBalance = result.dailyUnitFinances.find(
        (x) => x.date === previousDate.format('YYYY-MM-DD')
      );
      let finalBalance = result.dailyUnitFinances.find((x) => x.date === date);

      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.setFontSize(13);
      doc.text(`Data: ${moment(date).format('LL')}`, 5, (y += 8));

      forEach(accountHistory, function (value) {
        // PARA CADA HISTORICO
        bodyValues.push([
          moment(value?.date).format('DD/MM/YYYY'),
          value?.type ?? '-',
          value?.responsable ?? '-',
          parseFloat(value?.value).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        ]);
      });

      // nomes das colunas da tabela
      forEach(columns, function (head) {
        columnValues.push({
          content: head,
          styles: {
            fillColor: [55, 112, 222],
            textColor: [255, 255, 255],
            lineWidth: 0.25,
          },
        });
      });

      doc.autoTable({
        startY: (y += 3),
        showHead: 'firstPage',
        styles: {
          halign: 'center',
          valign: 'middle',
          overflow: 'linebreak',
        },
        headStyles: {
          halign: 'center',
          valign: 'middle',
          fontSize: 10,
          fontStyle: 'bold',
          textColor: [25, 25, 25],
          fillColor: [235, 235, 235],
          lineWidth: 0.25,
        },
        bodyStyles: {
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        head: [
          [
            {
              content: 'Saldo Inicial',
              colSpan: 3,
            },
            {
              content: !isEmpty(initialBalance)
                ? parseFloat(initialBalance?.balanceValue ?? 0).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )
                : 'Não Disponível',
              colSpan: 1,
            },
          ],
        ],
        body: [
          columnValues,
          ...bodyValues,
          [
            {
              content: 'Saldo Final',
              styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
                fontStyle: 'bold',
                textColor: [25, 25, 25],
                fillColor: [235, 235, 235],
              },
              colSpan: 3,
            },
            {
              content: !isEmpty(finalBalance)
                ? parseFloat(finalBalance?.balanceValue ?? 0).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )
                : 'Não Disponível',
              styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
                fontStyle: 'bold',
                textColor: [25, 25, 25],
                fillColor: [235, 235, 235],
              },
              colSpan: 1,
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 40 },
          2: { cellWidth: 80 },
          3: { cellWidth: 'wrap' },
        },
        theme: 'grid',
        margin: 5,
        rowPageBreak: 'avoid',
      });
      y = doc.lastAutoTable.finalY + 5;
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + '/' + String(pageCount), 198, 295);
    }
    return doc;
  },
};

export default ReportBankAccountMovements;
