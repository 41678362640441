import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const BudgetPreview = {
  buildBudgetPreviewPdf: (budget) => {
    const doc = new jsPDF({});
    let y = 0;
    doc.setFontSize(22);
    doc.text(
      `Prévia de Orçamento`,
      doc.internal.pageSize.getWidth() / 2,
      (y += 20),
      null,
      null,
      'center'
    );

    doc.setFontSize(14);
    let now = new Date();
    doc.text(15, (y += 10), 'Nome: ' + budget.name);
    doc.text(
      15,
      (y += 6),
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds()
    );

    doc.autoTable({
      startY: (y += 5),
      headStyles: { fillColor: [0, 70, 171] },
      head: [['Nome', 'Quantidade Pedido']],
      body: budget.budgetItems.map((x) => {
        return [
          x.item.name !== '' ? x.item.name : '-',
          x.itemQuantity !== '' ? x.itemQuantity : '-',
        ];
      }),
      theme: 'grid',
      styles: {
        halign: 'center',
        valign: 'middle',
        fontSize: 8,
      },
      margin: 5,
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 295, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default BudgetPreview;
