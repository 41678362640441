import styled from 'styled-components';
import {
  Box as MaterialBox,
  AddBox as MaterialAddBox,
  ViewShow as MaterialViewShow,
  FileInvoiceDollar as MaterialFileInvoiceDollar,
  FileInvoice as MaterialFileInvoice,
  FileDownload as MaterialFileDownload,
  CardChecklist as MaterialCardChecklist,
  Merge as MaterialMerge,
} from '../../../styles/icons';
import { Button as MaterialButton } from '@material-ui/core';
import { Documents as MaterialDocuments } from '@styled-icons/ionicons-sharp/Documents';

export const CardChecklist = styled(MaterialCardChecklist)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const SubmitButton = styled(MaterialButton)`
  width: 15%;
  color: #ffffff !important;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Merge = styled(MaterialMerge)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const FileInvoiceDollar = styled(MaterialFileInvoiceDollar)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const FileInvoice = styled(MaterialFileInvoice)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const FileDownload = styled(MaterialFileDownload)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Documents = styled(MaterialDocuments)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
