import { useState, useRef, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import merge from 'lodash/merge';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';
import ReactInputMask from 'react-input-mask';

function MaskedInputTextControl(props) {
  const {
    data,
    config,
    className,
    id,
    enabled,
    uischema,
    isValid,
    path,
    handleChange,
    schema,
    muiInputProps,
  } = props;

  const inputRef = useRef();
  const [selectionStart, setSelectionStart] = useState();

  const [showAdornment, setShowAdornment] = useState(false);
  const mask = schema?.maskSelector
    ? schema?.maskSelector(data ?? '')
    : schema?.mask;
  const maxLength = schema.maxLength;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  let inputProps;
  if (appliedUiSchemaOptions.restrict) {
    inputProps = { maxLength: maxLength };
  } else {
    inputProps = {};
  }

  inputProps = merge(inputProps, muiInputProps);

  if (appliedUiSchemaOptions.trim && maxLength !== undefined) {
    inputProps.size = maxLength;
  }

  useEffect(() => {
    if (selectionStart !== undefined) {
      inputRef.current.selectionStart = selectionStart;
      inputRef.current.selectionEnd = selectionStart;
    }
  }, [data, selectionStart]);

  const onChange = (ev) => {
    setSelectionStart(inputRef.current.selectionStart);
    handleChange(
      path,
      mask ? ev.target.value.replace(/\W/g, '') : ev.target.value
    );
  };

  const beforeMaskedStateChange = (nextState, currentState, value, crr) => {
    if (schema?.maskSelector) {
      const newMask = schema?.maskSelector(currentState.value + (value ?? ''));
      if (newMask !== mask) {
        if (currentState.selection === null) {
          return {
            selection: {
              start: 0,
              end: 1,
            },
            value: nextState.value + (value ?? ''),
          };
        } else {
          return {
            selection: {
              start: currentState.selection.start + 1,
              end: currentState.selection.end + 1,
            },
            value: currentState.value + (value ?? ''),
          };
        }
      }
    }
    return {
      ...nextState,
    };
  };

  return (
    <ReactInputMask
      disabled={!enabled}
      value={data || ''}
      mask={mask}
      onChange={onChange}
      beforeMaskedValueChange={beforeMaskedStateChange}
    >
      {(inputMaskProps) => {
        return (
          <Input
            inputRef={inputRef}
            type={
              appliedUiSchemaOptions.format === 'password' ? 'password' : 'text'
            }
            value={data || ''}
            onChange={onChange}
            className={className}
            id={id}
            disabled={!enabled}
            autoFocus={appliedUiSchemaOptions.focus}
            multiline={appliedUiSchemaOptions.multi}
            fullWidth={!appliedUiSchemaOptions.trim || maxLength === undefined}
            inputProps={inputProps}
            error={!isValid}
            onPointerEnter={() => setShowAdornment(true)}
            onPointerLeave={() => setShowAdornment(false)}
            endAdornment={
              // Use visibility instead of 'Hidden' so the layout doesn't change when the icon is shown
              <InputAdornment
                position="end"
                style={{
                  visibility:
                    !showAdornment || !enabled || data === undefined
                      ? 'hidden'
                      : 'visible',
                }}
              >
                <IconButton
                  aria-label="Clear input field"
                  onClick={() => handleChange(path, undefined)}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            }
            {...inputMaskProps}
          />
        );
      }}
    </ReactInputMask>
  );
}

export default MaskedInputTextControl;
