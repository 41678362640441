import styled from 'styled-components';
import { FilePng as MaterialFilePng } from '../../../styles/icons';

import { Button as MaterialButton } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AutoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ExportPngButton = styled(MaterialButton)`
  color: #ffffff !important;
  background: #2862eb !important;
`;

export const FilePng = styled(MaterialFilePng)`
  width: 25px;
  height: 25px;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
  margin-top: 10px !important;
`;

export const FooterContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  span {
    font-size: 1.5rem;
    font-weight: 400;
  }
`;
