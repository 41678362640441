import {
  CenterContainer,
  DottedLine,
  Grid,
  TextStyledH5,
  TextTitle,
  BoldTextStyledH5
} from './styles';
import React from 'react';
import Barcode from 'react-barcode';

function VoucherOS({ orderService, isClinic = false, printJustification = false }) {
  
  return (
    <CenterContainer>
      <TextTitle>{`Ordem de Serviço #${orderService?.id}`}</TextTitle>
      <DottedLine />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Unidade: ${orderService?.orderServiceStep?.unit?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Dentista: ${orderService?.orderServiceStep?.dentist?.user?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Prontuário: ${orderService?.patient?.recordNumber}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Paciente: ${orderService?.patient?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Produto final: ${orderService?.endProduct?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Etapa: ${orderService?.orderServiceStep?.step?.name}`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Antagonista: ${
              orderService?.orderServiceStep?.opposite ? 'Sim' : 'Não'
            }`}
          </TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            {`Mordida em Cera: ${
              orderService?.orderServiceStep?.waxBite ? 'Sim' : 'Não'
            }`}
          </TextStyledH5>
        </Grid>
        {orderService?.orderServiceStep?.step?.dentalArch &&
        orderService?.orderServiceStep?.dentalArch ? (
          <Grid item xs={12}>
            <TextStyledH5>
              {`Arcadas: ${orderService.orderServiceStep.dentalArch}`}
            </TextStyledH5>
          </Grid>
        ) : null}
        {orderService?.orderServiceStep?.step?.toothColor &&
        orderService?.orderServiceStep?.toothShadeId ? (
          <Grid item xs={12}>
            <TextStyledH5>
              {`Tipo > Tonalidade: 
                    ${orderService?.orderServiceStep?.toothShade?.toothType?.toothType} > ${orderService?.orderServiceStep?.toothShade?.name}`}
            </TextStyledH5>
          </Grid>
        ) : null}
        {orderService?.orderServiceStep?.step?.toothNumber &&
        orderService?.orderServiceStep?.toothNumber ? (
          <Grid item xs={12}>
            <TextStyledH5>
              {`Dente(s): ${orderService.orderServiceStep.toothNumber}`}
            </TextStyledH5>
          </Grid>
        ) : null}
        { printJustification ? (
          <Grid item xs={12}>
            <TextStyledH5>
              {`Justificativa: ${printJustification}`}
            </TextStyledH5>
          </Grid> ) : null
        }
        { isClinic ? (
          <Grid item xs={12}>
            <TextStyledH5>
              {`Data de retorno(Laboratório): ${orderService?.orderServiceStep?.schedule?.labReturnDate ? 
                new Date(orderService?.orderServiceStep?.schedule?.labReturnDate + ' 00:00:00')
                  .toLocaleDateString('pt-br') : '-'}`}
            </TextStyledH5>
          </Grid> ) : null
        }
        { orderService?.orderServiceStep?.observation ?
          <Grid item xs={12}>
            <TextStyledH5>
              {`Observação: ${orderService?.orderServiceStep?.observation}`}
            </TextStyledH5>
          </Grid> : null
        }
        { orderService?.orderServiceStep?.schedule?.urgency ?
          orderService?.orderServiceStep?.schedule?.justification ?
          (<Grid item xs={12}>
            <BoldTextStyledH5>
              {`URGENTE: ${orderService?.orderServiceStep?.schedule?.justification }`}
            </BoldTextStyledH5>
          </Grid>) :
          (<Grid item xs={12}>
            <BoldTextStyledH5>
              {`Urgente`}
            </BoldTextStyledH5>
          </Grid>) : null
        }
      </Grid>
      <DottedLine />
      <Barcode value={orderService?.orderServiceStep?.id} />
      <DottedLine />
    </CenterContainer>
  );
}

export default VoucherOS;
