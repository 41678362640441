const DentistWorkedHour = {
  schema: {
    type: 'object',
    properties: {
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      attendanceDate: {
        type: 'string',
        format: 'customAttendanceDate',
        customErrors: '',
      },
      beginTime: {
        type: 'string',
        format: 'time',
        beginTime: '',
        customErrors: '',
      },
      replacedDentistId: {
        type: 'number',
        format: 'selectDentist',
        customErrors: '',
      },
      isSubstitute: {
        type: 'boolean',
        customErrors: '',
      },
      specialtyId: {
        format: 'selectDentistSpecialtyDialog',
        customErrors: '',
      },
    },
    required: ['unitId', 'attendanceDate', 'beginTime', 'specialtyId'],
  },
  submitSchema: {
    type: 'object',
    properties: {
      beginTime: {
        type: 'string',
        format: 'time',
        beginTime: '',
        customErrors: '',
      },
      endTime: {
        type: 'string',
        format: 'time',
        endTime: '',
        customErrors: '',
      },
    },
    required: ['beginTime', 'endTime'],
  },
  uischema: {
    type: 'Group',
    label: 'Cadastro de Horário de Atendimento',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Substituto',
            scope: '#/properties/isSubstitute',
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dentista Substituído',
            scope: '#/properties/replacedDentistId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/isSubstitute',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Atendimento',
            scope: '#/properties/attendanceDate',
          },
          {
            type: 'Control',
            label: 'Início',
            scope: '#/properties/beginTime',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/unitId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Especialidade',
            scope: '#/properties/specialtyId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/unitId',
            schema: { enum: [null, undefined] },
          },
        },
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Horário de Atendimento',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dentista Substituído',
            scope: '#/properties/replacedDentistId',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/replacedDentistId',
                schema: { enum: [null, undefined] },
              },
            },
          },
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Atendimento',
            scope: '#/properties/attendanceDate',
          },
          {
            type: 'Control',
            label: 'Início',
            scope: '#/properties/beginTime',
          },
        ],
      },
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Especialidade',
            scope: '#/properties/specialtyId',
          },
        ],
      },
    ],
  },
  submitUiSchema: {
    type: 'Group',
    label: 'Submissão de Agenda',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Início',
            scope: '#/properties/beginTime',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: 'Término',
            scope: '#/properties/endTime',
          },
        ],
      },
    ],
  },
  tableSchema: [
    {
      id: 'attendanceDate',
      label: 'Data Atendimento',
      minWidth: 25,
      align: 'center',
    },
    { id: 'beginTime', label: 'Início', minWidth: 25, align: 'center' },
    { id: 'endTime', label: 'Término', minWidth: 25, align: 'center' },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'specialty.name',
      label: 'Especialidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 50, align: 'center' },
  ],
  auditTableSchema: [
    {
      id: 'attendanceDate',
      label: 'Data Agenda',
      minWidth: 25,
      align: 'center',
    },
    { id: 'schedule', label: 'Horário', minWidth: 25, align: 'center' },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'specialty.name',
      label: 'Especialidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    { id: 'submitDate', label: 'Submissão', minWidth: 100, align: 'center' },
    {
      id: 'workedHourAttendances',
      label: 'Quantidade Procedimentos',
      minWidth: 25,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 50, align: 'center' },
  ],
  auditSubstituteTableSchema: [
    {
      id: 'attendanceDate',
      label: 'Data Agenda',
      minWidth: 25,
      align: 'center',
    },
    { id: 'schedule', label: 'Horário', minWidth: 25, align: 'center' },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'substituted.user.name',
      label: 'Substituído',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'specialty.name',
      label: 'Especialidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    { id: 'submitDate', label: 'Submissão', minWidth: 100, align: 'center' },
    {
      id: 'workedHourAttendances',
      label: 'Quantidade Procedimentos',
      minWidth: 25,
      align: 'center',
    },
  ],
  allSchedulesTableSchema: [
    {
      id: 'attendanceDate',
      label: 'Data Agenda',
      minWidth: 25,
      align: 'center',
    },
    { id: 'schedule', label: 'Horário', minWidth: 25, align: 'center' },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'substituted.user.name',
      label: 'Substituído',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'specialty.name',
      label: 'Especialidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'workedHourAttendances',
      label: 'Quantidade Procedimentos',
      minWidth: 25,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 25, align: 'center' },
  ],
  attendancesTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    {
      id: 'dentist.user.name',
      label: 'Dentista',
      minWidth: 100,
      align: 'center',
    },
    { id: 'type', label: 'Tipo Agenda', minWidth: 100, align: 'center' },
    { id: 'patient.name', label: 'Paciente', minWidth: 100, align: 'center' },
    {
      id: 'procedure.name',
      label: 'Procedimento',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    {
      id: 'attendanceDate',
      label: 'Data Realização',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
  ],
  internalAttendancesTableSchema: [
    {
      id: 'attendance.id',
      label: 'Código Interno',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'attendance.dentist.user.name',
      label: 'Dentista',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'attendance.type',
      label: 'Tipo Agenda',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'attendance.patient.name',
      label: 'Paciente',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'attendance.procedure.name',
      label: 'Procedimento',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'attendance.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'attendance.attendanceDate',
      label: 'Data Realização',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'attendance.status',
      label: 'Situação',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'DentistWorkedHourController@getAll',
    getAllInClinic: 'DentistWorkedHourController@getAllInClinic',
    getAllByDate: 'DentistWorkedHourController@getAllByDate',
    getAllByDentist: 'DentistWorkedHourController@getAllByDentist',
    findById: 'DentistWorkedHourController@findById',
    setApproved: 'DentistWorkedHourController@setApproved',
    setDisapproved: 'DentistWorkedHourController@setDisapproved',
    create: 'DentistWorkedHourController@create',
    delete: 'DentistWorkedHourController@delete',
    updateSpecialty: 'DentistWorkedHourController@updateSpecialty',
  },
  dentistWorkedHourAttendanceActions: {
    getAllByWorkedHour:
      'DentistWorkedHourAttendanceController@getAllByWorkedHour',
  },
};

export default DentistWorkedHour;
