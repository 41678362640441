import React, { useState, useEffect, useCallback, useRef } from 'react';
import Expense from '../../../../../../forms/expense';
import Employee from '../../../../../../forms/employee';
import EmployeeExpense from '../../../../../../forms/employeeExpense';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import {
  Container,
  Preview,
  Payments,
  UserPlus,
  Delete,
  ReceiptAdd,
  FileDownload,
  ArrowUndoCircle,
  PrintReceipt,
  CancelButton,
} from './styles';
import moment from 'moment';
import SelectPaydayDialog from '../../../../../../components/selectPaydayDialog';
import { useReactToPrint } from 'react-to-print';
import ExpenseReceipt from '../../../../../../components/expenseReceipt';

function PendingExpenseList({ history, location }) {
  const consolidationDate = location?.state?.consolidationDate;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openPaydayDialog, setOpenPaydayDialog] = useState(false);
  const [expenseId, setExpenseId] = useState(false);
  const [expenseObj, setExpenseObj] = useState(null);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const componentRefExpenseReceipt = useRef();
  const columns = Expense.tableSchema;

  const loadExpenses = useCallback(() => {
    if (consolidationDate) {
      restApiClient.expense
        .getAll(
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp
                    .like('unit.name', searchBy)
                    .or()
                    .like('patient.name', searchBy)
                    .or()
                    .like('dentist.user.name', searchBy)
                    .or()
                    .like('employee.user.name', searchBy)
                    .or()
                    .like('expensable.socialName', searchBy)
                    .or()
                    .like('expensable.fantasyName', searchBy);
                  rightOp
                    .eq('status', 'Aberta')
                    .lte('dueDate', consolidationDate);
                })
                .toString()
            : new FilterTree()
                .eq('status', 'Aberta')
                .lte('dueDate', consolidationDate)
                .toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadExpenses();
  }, [loadExpenses]);

  const handlePrint = useReactToPrint({
    documentTitle: `RECIBO_DESPESA`,
    content: () => componentRefExpenseReceipt.current,
  });

  return (
    <Container>
      <CrudTable
        title={`Despesas vinculadas`}
        modelActions={Expense.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id, row) => row.id === submitConfirmationId,
            content: (
              <span>
                Você tem certeza que deseja prosseguir com essa operação?
              </span>
            ),
            onSuccess: () => {
              restApiClient.expense.delete(expenseId).then(() => {
                loadExpenses();
                setSubmitConfirmationId(-1);
              });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          update: {
            onClick: (expenseId, row) => {
              let expensableType;
              if (row?.expensableType) {
                let indexes = row?.expensableType.split('\\');
                expensableType =
                  indexes[2][0].toLowerCase() + indexes[2].substring(1);
              }
              history.push({
                pathname: `/financial/expense/update/${row?.id}`,
                state: { expensableType },
              });
            },
            allowedActions: [
              [
                Expense.actions.update,
                Expense.actions.findById,
                Expense.actions.updateExpenseWithApportion,
              ],
            ],
            selector: (row) => row.status === 'Aberta',
          },
          setPayday: {
            icon: <Payments />,
            onClick: (expenseId, row) => {
              setOpenPaydayDialog(true);
              setExpenseId(row?.id);
            },
            title: 'Pagar Despesa',
            selector: (row) => row.status === 'Aberta',
            allowedActions: [[Expense.actions.setPaid]],
          },
          setCompensation: {
            icon: <ArrowUndoCircle />,
            onClick: (expenseId, row) => {
              restApiClient.expense
                .setUndonePayment(row.id)
                .then(() => loadExpenses());
            },
            allowedActions: [Expense.actions.setUndonePayment],
            title: 'Desfazer Pagamento',
            selector: (row) => row?.status === 'Paga',
          },
          view: {
            icon: <Preview />,
            onClick: (expenseId, row) => {
              if (row?.status === 'Paga' && row?.id) {
                let expensableType;
                if (row?.expensableType) {
                  let indexes = row?.expensableType.split('\\');
                  expensableType =
                    indexes[2][0].toLowerCase() + indexes[2].substring(1);
                }
                history.push({
                  pathname: `/financial/expense/${row?.id}`,
                  state: { readonly: true, expensableType },
                });
              } else {
                history.push({
                  pathname: `/financial/expenses/recurrent/view`,
                  state: { expense: row, readonly: true },
                });
              }
            },
            title: 'Visualizar Despesa',
            selector: (row) => row?.status !== 'Aberta',
          },
          employeeExpense: {
            icon: <UserPlus />,
            onClick: (expenseId, row) => {
              history.push({
                pathname: `/financial/expenses/employee-expense/${row?.id}/unit/${row?.unitId}`,
                state: { expenseValue: row.value },
              });
            },
            title: 'Distribuição da Despesa',
            allowedActions: [
              [
                EmployeeExpense.actions.getAllByExpense,
                Expense.actions.update,
                Employee.actions.getAllByUnit,
              ],
            ],
            selector: (row) =>
              row?.status !== 'Previsão' && row?.status !== 'Pag Desfeito',
          },
          throwExpense: {
            icon: <ReceiptAdd />,
            onClick: (expenseId, row) => {
              history.push({
                pathname: `/financial/expenses/recurrent/create`,
                state: {
                  expense: row,
                },
              });
            },
            title: 'Lançar Despesa',
            selector: (row) => row?.status === 'Previsão',
          },
          receiptCreate: {
            icon: <FileDownload />,
            onClick: async (expenseId, row) => {
              await setExpenseObj(row);
              handlePrint();
            },
            title: 'Gerar Recibo',
            selector: (row) => row?.status !== 'Pag Desfeito',
          },
          customDelete: {
            icon: <Delete />,
            onClick: (id, row) => {
              setExpenseId(row.id);
              setSubmitConfirmationId(row.id);
            },
            title: 'Excluir Despesa',
            selector: (row) => row.status === 'Aberta',
            allowedActions: [[Expense.actions.delete]],
          },
        }}
        customCellRenderers={{
          date: {
            renderer: (index, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          currentValue: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          dueDate: {
            renderer: (index, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
          name: {
            renderer: (field, value, column, row) => {
              if (row?.employeeId) {
                return row?.employee?.user?.name;
              } else if (row?.patientId) {
                return row?.patient?.name;
              } else if (row?.dentistId) {
                return row?.dentist?.user?.name;
              } else if (row?.expensable) {
                return row?.expensable?.isPhysicalPerson
                  ? row?.expensable?.socialName
                  : row?.expensable?.fantasyName;
              } else {
                return '-';
              }
            },
          },
          expenseType: {
            renderer: (field, value) => {
              if (value?.parent?.parent?.parent) {
                return `${value.parent.parent.parent.name} > ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent?.parent) {
                return `${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
              } else if (value?.parent) {
                return `${value.parent.name} > ${value.name}`;
              } else {
                return value.name;
              }
            },
          },
          installmentNumber: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <SelectPaydayDialog
        open={openPaydayDialog}
        expenseId={expenseId}
        onCancel={() => setOpenPaydayDialog(false)}
        onSuccess={() => {
          setOpenPaydayDialog(false);
          loadExpenses();
        }}
      />

      <PrintReceipt ref={componentRefExpenseReceipt}>
        <ExpenseReceipt expense={expenseObj} />
      </PrintReceipt>
    </Container>
  );
}

export default PendingExpenseList;
