const ExpenseType = {
  tableSchema: [{ id: 'name', label: 'Nome', minWidth: 10, align: 'center' }],
  actions: {
    create: 'ExpenseTypeController@create',
    getAllAsTree: 'ExpenseTypeController@getAllAsTree',
    getAllAsTreeClinic: 'ExpenseTypeController@getAllAsTreeClinic',
    getAllLeaf: 'ExpenseTypeController@getAllLeaf',
    getAll: 'ExpenseTypeController@getAll',
    findById: 'ExpenseTypeController@findById',
    update: 'ExpenseTypeController@patchUpdate',
    getAllAsExpenseType: 'ExpenseTypeController@getAllAsExpenseType',
    getAllChildrenByParentId: 'ExpenseTypeController@getAllChildrenByParentId',
    getTypeById: 'ExpenseTypeController@getAllChildrenByParentId',
    delete: 'ExpenseTypeController@delete',
  },
};

export default ExpenseType;
