import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectOriginDialogControl from '../../components/selectOriginDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectOriginDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectOriginDialog'
  )
);

const customSelectOriginDialogControlTester = rankWith(
  4,
  isSelectOriginDialogControl
);

export default {
  tester: customSelectOriginDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectOriginDialogControl),
};
