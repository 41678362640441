import React from 'react';
import ServiceSupplier from '../../../../../forms/serviceSupplier';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container } from './styles';
import { objectMap } from '../../../../../extensions/object';
import { isEqualWith } from 'lodash';

function ServiceSupplierCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={ServiceSupplier}
        restGetDataById={ async (id) => {
          const result = await restApiClient.serviceSupplier.findById(id);
          result.data = objectMap(result.data, (key, value) => { 
            if(value === null) return undefined;
            return value;
          })
          return result;
        }}
        restCreateData={restApiClient.serviceSupplier.create}
        restUpdateData={ async (modelId, updateOriginalData, modelData) => {
          
          const added = modelData.serviceSupplierBankAccounts.filter(
            item => !updateOriginalData.serviceSupplierBankAccounts.some(obj => 
              isEqualWith(obj, item, (obj1, obj2) => 
                obj1.account === obj2.account &&
                obj1.accountDigit === obj2.accountDigit &&
                obj1.agency === obj2.agency &&
                obj1.agencyDigit === obj2.agencyDigit &&
                obj1.bank === obj2.bank
              )
            )
          );
          
          const removed = updateOriginalData.serviceSupplierBankAccounts.filter(
            item => !modelData.serviceSupplierBankAccounts.some(obj =>
              isEqualWith(obj, item, (obj1, obj2) =>
                obj1.account === obj2.account &&
                obj1.accountDigit === obj2.accountDigit &&
                obj1.agency === obj2.agency &&
                obj1.agencyDigit === obj2.agencyDigit &&
                obj1.bank === obj2.bank
              )
            )
          ).map(item => item.id);
          delete modelData.serviceSupplierBankAccounts;
          delete updateOriginalData.serviceSupplierBankAccounts;
          const result = await restApiClient.serviceSupplier.update(
            modelId, updateOriginalData, modelData, added, removed);
          return result;
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default ServiceSupplierCreateUpdate;
