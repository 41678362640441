import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import SchedulerComponent from '../../../../components/chairScheduleComponent';
import restApiClient from '../../../../services/restApiClient';
import map from 'lodash/map';
import findKey from 'lodash/findKey';
import moment from 'moment';
import { SystemColors } from '../../../../styles/globalStyles';

function ChairSchedule({ history, match, ...props }) {
  const chairId = parseInt(match?.params?.id ?? -1);
  const chair = props.location?.state?.chair;
  const [dentists, setDentists] = useState([]);
  const weekDays = {
    0: 'Domingo',
    1: 'Segunda-Feira',
    2: 'Terça-Feira',
    3: 'Quarta-Feira',
    4: 'Quinta-Feira',
    5: 'Sexta-Feira',
    6: 'Sábado',
  };

  useEffect(() => {
    restApiClient.officeHour.getAllByChair(chairId).then((x) => {
      let newDentists = x.data;
      newDentists = map(newDentists, function (item) {
        const [beginHour, beginMinutes] = item.beginInterval.split(':');
        const [endHour, endMinutes] = item.endInterval.split(':');

        let numberDay = findKey(weekDays, function (day) {
          return day === item.dayOfWeek;
        });

        return {
          id: item?.id,
          title: item?.chairDentist?.dentist?.user.name,
          chairId: item?.chairDentist.chairId,
          startDate: moment()
            .day(numberDay)
            .hour(beginHour)
            .minute(beginMinutes)
            .second(0)
            .millisecond(1)
            .format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment()
            .day(numberDay)
            .hour(endHour)
            .minute(endMinutes)
            .second(0)
            .millisecond(1)
            .format('YYYY-MM-DD HH:mm:ss'),
        };
      });
      setDentists(newDentists);
    });
  }, []);

  return (
    <Container>
      <SchedulerComponent
        chairs={[
          {
            id: chair?.id,
            text: chair?.name,
            color: SystemColors.primary,
          },
        ]}
        dentists={dentists}
        {...props}
      />
    </Container>
  );
}

export default ChairSchedule;
