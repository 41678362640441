import React, { useState, useEffect } from 'react';
import Holiday from '../../../forms/holiday';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import HolidayDialog from '../../../components/holidayDialog';
import { Container, CancelButton, AddBox } from './styles';

function HolidayList({ match, history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ date: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [calendar, setCalendar] = useState({});
  const [open, setOpen] = useState(false);

  const calendarId = match.params?.id;
  const columns = Holiday.tableSchema;

  useEffect(() => {
    restApiClient.holiday
      .getAllByCalendarId(
        calendarId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('date', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    restApiClient.calendar.findById(calendarId).then((e) => {
      setCalendar(e.data);
    });
  }, [calendarId]);

  return (
    <Container>
      <CrudTable
        title={`Visualizar todos os feriados do Calendário - ${calendar.city}/${calendar.year}`}
        customFooterComponent={
          <CancelButton
            onClick={() => history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Voltar
          </CancelButton>
        }
        modelActions={Holiday.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            icon: <AddBox />,
            onClick: (id) => setOpen(true),
            title: 'Cadastrar data',
            allowedActions: [Holiday.actions.create],
          },
          delete: {
            onClick: (holidayId, index) => {
              restApiClient.holiday.delete(holidayId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
            allowedActions: [Holiday.actions.delete],
          },
        }}
        customCellRenderers={{
          date: {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
        }}
      />

      <HolidayDialog
        open={open}
        holiday={Holiday}
        calendarId={calendarId}
        onCancel={() => {
          setOpen(false);
        }}
        onSuccess={(result) => {
          const holiday = result.data;
          const newRows = [...rows.values];
          newRows[newRows.length] = holiday;
          setRows({ ...rows, values: newRows });
          setOrderby({ date: 'asc' });
          setOpen(false);
        }}
        {...props}
      />
    </Container>
  );
}

export default HolidayList;
