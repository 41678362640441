import styled from 'styled-components';
import { DialogContent as MaterialDialogContent } from '@material-ui/core';
import CreateUpdateForm from '../createUpdateForm';

export const Container = styled.div``;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 0px !important;
  width: 600px !important;

`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)`
  margin-bottom: 50px !important ;
`;
