import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  DialogContent as MaterialDialogContent,
  Button as MaterialButton,
  Table as MaterialTable,
  TableBody as MaterialTableBody,
  TableCell as MaterialTableCell,
  TableContainer as MaterialTableContainer,
  TableHead as MaterialTableHead,
  TableRow as MaterialTableRow,
  Paper as MaterialPaper,
  Grid as MaterialGrid,
  Card as MaterialCard,
} from '@material-ui/core';

export const Container = styled.div``;

export const TextStyledH5 = styled.h5`
  font-family: sans-serif !important;
`;

export const TextStyledH1 = styled.h1`
  font-family: sans-serif !important;
  margin-bottom: 15px;
`;

export const Grid = styled(MaterialGrid)``;

export const Dialog = styled(MaterialDialog)``;

export const DialogContent = styled(MaterialDialogContent)`
  height: auto !important;
  max-height: 500px !important;
  padding: 5px !important;
`;

export const SignatureLine = styled.div`
  border-bottom: 0.5px solid #000000;
  width: 40%;
  padding-top: 40px;
  margin: 0 auto;
`;

export const Signature = styled.div`
  width: 40%;
  margin: 0 auto;
  text-align: center;
  font-family: Arial sans-serif !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const AutoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Table = styled(MaterialTable)`
  margin: 0px !important;
  border: none !important;
  box-shadow: none !important;
`;

export const TableBody = styled(MaterialTableBody)`
  margin: 0px !important;
  border: none !important;
  box-shadow: none !important;
`;

export const TableCell = styled(MaterialTableCell)`
  font-family: Verdana sans-serif !important;
  font-size: 15px !important;
  font-weight: 100 !important;
  margin: 0px !important;
  padding: 0px !important;
  border-bottom: none !important;
`;

export const TotalFont = styled.h5`
  font-family: Verdana sans-serif !important;
  font-size: 15px !important;
  font-weight: 800 !important;
  margin: 0px !important;
  padding: 0px !important;
`;

export const TableContainer = styled(MaterialTableContainer)`
  width: 100%;
  margin: 0px !important;
  box-shadow: none !important;
`;

export const TableHead = styled(MaterialTableHead)`
  font-family: Arial sans-serif !important;
  font-size: 8px !important;
  font-weight: 900 !important;
  margin: 0px !important;
`;

export const TableRow = styled(MaterialTableRow)`
  font-family: Arial sans-serif !important;
  font-size: 8px !important;
  font-weight: 900 !important;
  margin: 0px !important;
  padding: 0px !important;
`;

export const Paper = styled(MaterialPaper)`
  font-family: Arial sans-serif !important;
  font-size: 8px !important;
  font-weight: 900 !important;
  margin: 0px !important;
`;

export const DottedLine = styled.div`
  border-bottom: 1px dotted Black;
  letter-spacing: 6px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Line = styled.div`
  border-bottom: 1px solid Black;
  letter-spacing: 6px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  padding: 10px;
`;
