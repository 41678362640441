import React from 'react';

import { Select, FormControl } from './styles';
import { MenuItem } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

const SelectControl = ({
                         data,
                         className,
                         id,
                         enabled,
                         uischema,
                         schema,
                         path,
                         label,
                         handleChange,
                         config,
                         errors,
                         ...props
                       }) => {
  const isValid = errors.length === 0;
  
  return (
    <FormControl error={!isValid}>
      <InputLabel>{label}</InputLabel>
      <Select
        className={className}
        id={id}
        disabled={!enabled}
        error={!isValid}
        value={data || ''}
        onChange={ev => handleChange(path, ev.target.value)}
      >
        {[<MenuItem value='' key={'empty'}/>].concat(
          config[`${path}Data`]?.map(optionValue => (
            <MenuItem value={optionValue.id} key={optionValue.id}>
              {optionValue.name}
            </MenuItem>
          )),
        )}
      </Select>
      <FormHelperText>{!isValid ? errors : ' '}</FormHelperText>
    </FormControl>
  );
};

export default SelectControl;
