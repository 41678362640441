import styled from 'styled-components';
import {
  Button as MaterialButton,
  Checkbox as MaterialCheckbox,
} from '@material-ui/core';
import { Import as MaterialImport } from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CancelButton = styled(MaterialButton)`
  width: 150px;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const ImportCsvButton = styled(MaterialButton)`
  color: #ffffff !important;
  background: #2dbd17 !important;
`;

export const Import = styled(MaterialImport)`
  width: 25px;
  height: 25px;
`;

export const Checkbox = styled(MaterialCheckbox)``;
