import React from 'react';
import { useSelector } from 'react-redux';
import RadiologySchedule from '../../../../../forms/radiologySchedule';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container, BackButton, FooterContainer } from './styles';

function RadiologyScheduleCreateUpdate(props) {
  const userData = useSelector((state) => state.authReducer?.user);
  const isRadiology = props.location?.state?.isRadiology ?? false;
  const update = props.match?.params?.update
    ? props.match?.params?.update === 'true'
    : false;
  const viewForm = props.location?.state?.viewForm ?? false;
  const reschedule = props.location?.state?.reschedule ?? false;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={RadiologySchedule}
        restGetDataById={async (id) => {
          const result = await restApiClient.radiologySchedule.findById(id);
          result.data.recordNumber = result.data.patient.recordNumber;
          result.data.name = result.data.patient.name;
          result.data.birthDate = result.data.patient.birthDate;
          result.data.sex = result.data.patient.sex;
          result.data.phoneNumber = result.data.patient.phoneNumber;
          result.data.scheduling = new Date(
            result.data.scheduling
          ).toISOString();
          result.data.recordNumberUninformed =
            !result.data.patient.recordNumber;
          return result;
        }}
        restCreateData={async (radiologySchedule) => {
          if (radiologySchedule.recordNumberUninformed) {
            delete radiologySchedule.patientId;
            delete radiologySchedule.recordNumber;
          }
          return await restApiClient.radiologySchedule.create(
            radiologySchedule
          );
        }}
        restUpdateData={async (id, oldRadiologySchedule, radiologySchedule) => {
          delete oldRadiologySchedule.update;
          delete radiologySchedule.update;
          delete oldRadiologySchedule.isRadiology;
          delete radiologySchedule.isRadiology;
          delete oldRadiologySchedule.reschedule;
          delete radiologySchedule.reschedule;
          delete oldRadiologySchedule.requestingUnitBoolean;
          delete radiologySchedule.requestingUnitBoolean;
          delete oldRadiologySchedule.userUnit;
          delete radiologySchedule.userUnit;

          const result = await restApiClient.radiologySchedule.update(
            id,
            oldRadiologySchedule,
            radiologySchedule
          );
          return result;
        }}
        modelData={{
          update: update,
          isRadiology: isRadiology,
          fit: false,
          recordNumberUninformed: false,
          reschedule: reschedule,
          requestingUnitBoolean: !userData.unitId,
          userUnit: userData.unitId,
          requestingUnit: userData.unitId,
        }}
        config={{
          unitType: 'clinic',
          unitId: userData?.unitId,
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        customButtonsBuilder={
          viewForm
            ? () => (
                <>
                  <FooterContainer>
                    <BackButton
                      onClick={() => props.history.goBack()}
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      Voltar
                    </BackButton>
                  </FooterContainer>
                </>
              )
            : null
        }
        {...props}
      />
    </Container>
  );
}

export default RadiologyScheduleCreateUpdate;
