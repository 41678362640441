import React, { useState, useEffect, useCallback } from 'react';
import CashRegisterValue from '../../../../../forms/cashRegisterValue';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, AddToList } from './styles';

function DrePaymentList({ history, match, location }) {
  const expenseType = location?.state?.expenseType;
  const expenseTypeId = match?.params?.expenseTypeId ?? -1;
  const type = location?.state?.type;
  const month = location?.state?.month;
  const year = location?.state?.year;
  const unitId = location?.state?.unitId;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = CashRegisterValue.drePaymentTableSchema;

  const loadcashRegisterCloses = useCallback(() => {
    if (type && month && year && expenseTypeId && unitId && expenseType) {
      restApiClient.cashRegisterValue
        .getAllToDre(
          expenseType.paymentType.type ?? 'default',
          month,
          year,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp
                    .eq('paymentType.expenseTypeId', expenseTypeId)
                    .conditionals((tree) => {
                      if (unitId != -1) {
                        tree.and().eq('cashRegisterClose.unitId', unitId);
                      } else {
                        switch (type) {
                          default:
                          case 1:
                            tree
                              .and()
                              .eq('cashRegisterClose.unit.type', 'clinic')
                              .and()
                              .eq('cashRegisterClose.unit.showInDre', true);
                            break;
                          case 2:
                            tree
                              .and()
                              .df('cashRegisterClose.unit.type', 'clinic');
                            break;
                          case 4:
                            tree.and().df('unit.type', 'distribution_center');
                            break;
                        }
                      }
                    });
                  rightOp
                    .like('status', searchBy)
                    .or()
                    .like('cashRegisterClose.date', searchBy)
                    .or()
                    .like('cashRegisterClose.number_cash_register', searchBy)
                    .or()
                    .like('cashRegisterClose.unit.name', searchBy)
                    .or()
                    .like('cashRegisterClose.employee.user.name', searchBy);
                })
                .toString()
            : new FilterTree()
                .eq('paymentType.expenseTypeId', expenseTypeId)
                .conditionals((tree) => {
                  if (unitId != -1) {
                    tree.and().eq('cashRegisterClose.unitId', unitId);
                  } else {
                    switch (type) {
                      default:
                      case 1:
                        tree
                          .and()
                          .eq('cashRegisterClose.unit.type', 'clinic')
                          .and()
                          .eq('cashRegisterClose.unit.showInDre', true);
                        break;
                      case 2:
                        tree.and().df('cashRegisterClose.unit.type', 'clinic');
                        break;
                      case 4:
                        tree.and().df('unit.type', 'distribution_center');
                        break;
                    }
                  }
                })
                .toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadcashRegisterCloses();
  }, [loadcashRegisterCloses]);

  return (
    <Container>
      <CrudTable
        title={`Recebimentos vinculados a ${expenseType.name}`}
        modelActions={CashRegisterValue.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          'cashRegisterClose.date': {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          value: {
            renderer: (field, value, column, row) => {
              if (row.itemizedCardLiquidSum || row.itemizedCardInstallmentSum) {
                value =
                  parseFloat(row.itemizedCardLiquidSum ?? 0) +
                  parseFloat(row.itemizedCardInstallmentSum ?? 0);
              }
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
    </Container>
  );
}

export default DrePaymentList;
