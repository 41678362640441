const DentistFavoriteUnit = {
  schema: {
    type: 'object',
    properties: {
      favoriteUnitId: {
        format: 'selectClinicDialog',
        customErrors: '',
      },
    },
  },

  uischema: {
    type: 'Group',
    label: 'Unidade Favorita',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Selecione uma unidade',
            scope: '#/properties/favoriteUnitId',
          },
        ],
      },
    ],
  },
};

export default DentistFavoriteUnit;
