import styled from 'styled-components';
import {
  NotesMedical as MaterialNotesMedical,
  TextBulletListSquareWarning as MaterialTextBulletListSquareWarning,
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const NotesMedical = styled(MaterialNotesMedical)`
  width: 23px;
  height: 23px;
  color: var(--secondary);
`;

export const TextBulletListSquareWarning = styled(
  MaterialTextBulletListSquareWarning
)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;
