const TransferType = {
  actions: {
    create: 'TransferTypeController@create',
    getAll: 'TransferTypeController@getAll',
    getAllTree: 'TransferTypeController@getAllTree',
    findById: 'TransferTypeController@findById',
    update: 'TransferTypeController@patchUpdate',
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
  ],
};

export default TransferType;
