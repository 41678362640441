import React, { useState, useCallback, useEffect } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, NotesMedical, TextBulletListSquareWarning } from './styles';
import DentistWorkedHour from '../../../../../forms/dentistWorkedHour';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DisapprovedJusticationDialog from '../../../../../components/disapprovedJustificationDialog';

function ClinicDentistScheduleAuditList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openJustification, setOpenJustification] = useState('');
  const [justification, setJustification] = useState('');
  const columns = DentistWorkedHour.auditSubstituteTableSchema;
  const [error, setError] = useState({
    state: false,
    description: '',
  });

  const loadDentistWorkedHour = useCallback(() => {
    restApiClient.dentistWorkedHour
      .getAllInClinic(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild.like('status', 'Reprovada');
                rightChild
                  .like('dentist.user.name', searchBy)
                  .or()
                  .like('specialty.name', searchBy)
                  .or()
                  .like('unit.name', searchBy);
              })
              .toString()
          : new FilterTree().like('status', 'Reprovada').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadDentistWorkedHour();
  }, [loadDentistWorkedHour]);

  return (
    <Container>
      <CrudTable
        title={'Agendas Reprovadas'}
        modelActions={DentistWorkedHour.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewProcedures: {
            onClick: (id) => {
              history.push({
                pathname: `/clinic/dentist/audit/schedule/disapproved/${id}/attendances`,
                state: { disapproved: true },
              });
            },
            icon: <NotesMedical />,
            title: 'Visualizar Procedimentos',
            allowedActions: [
              DentistWorkedHour.dentistWorkedHourAttendanceActions
                .getAllByWorkedHour,
            ],
          },
          viewDisapprovedJustification: {
            onClick: (id, row) => {
              setOpenJustification(true);
              setJustification(row.disapprovedJustification);
            },
            icon: <TextBulletListSquareWarning />,
            title: 'Visualizar Justificativa',
            allowedActions: [
              DentistWorkedHour.dentistWorkedHourAttendanceActions
                .getAllByWorkedHour,
            ],
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
          'substituted.user.name': {
            renderer: (field, value) => {
              return value ? value : '-';
            },
          },
          submitDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
          schedule: {
            renderer: (field, value, column, row) => {
              return row.beginTime && row.endTime
                ? `${row.beginTime} às ${row.endTime}`
                : '-';
            },
          },
          workedHourAttendances: {
            renderer: (field, value) => {
              if (!isEmpty(value)) {
                const evaluatedProcedures = value.filter(
                  (x) =>
                    x.attendance.status === 'Aprovado' ||
                    x.attendance.status === 'Reprovado'
                ).length;

                return `${evaluatedProcedures}/${value.length}`;
              } else {
                return '-';
              }
            },
          },
          'specialty.name': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />

      <DisapprovedJusticationDialog
        open={openJustification}
        cancelSubmit={true}
        onCancel={() => setOpenJustification(false)}
        disapprovedJustification={justification}
      />

      <Snackbar
        open={error.state}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() =>
          setError({
            state: false,
            description: '',
          })
        }
      >
        <Alert
          onClose={() => setError({ state: false, description: '' })}
          severity="error"
        >
          {error.description}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ClinicDentistScheduleAuditList;
