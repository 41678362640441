/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import ExpenseType from '../../forms/expenseType';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
  Plus,
  Minus,
} from './styles';

const SelectExpenseTypeFieldDialogControl = ({
  open,
  forbidIds,
  onClose,
  onSuccess,
  expenseTypeId,
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const [fetching, setFetching] = useState(false);

  const columns = ExpenseType.tableSchema;

  useEffect(() => {
    restApiClient.expenseType
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        forbidIds && forbidIds.length > 0
          ? searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp.notIn('id', forbidIds);
                  rightOp
                    .like('name', searchBy)
                    .or()
                    .like('parent.name', searchBy)
                    .or()
                    .like('parent.parent.name', searchBy)
                    .or()
                    .like('parent.parent.parent.name', searchBy);
                })
                .toString()
            : new FilterTree().notIn('id', forbidIds).toString()
          : searchBy
          ? new FilterTree()
              .like('name', searchBy)
              .or()
              .like('parent.name', searchBy)
              .or()
              .like('parent.parent.name', searchBy)
              .or()
              .like('parent.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        const rows = e.data;
        let expenseTypes = e.data.values;
        expenseTypes = expenseTypes.map((expenseType) => {
          if (expenseType?.parent?.parent?.parent) {
            expenseType.name = `${expenseType.parent.parent.parent.name} > ${expenseType.parent.parent.name} > ${expenseType.parent.name} > ${expenseType.name}`;
          } else if (expenseType?.parent?.parent) {
            expenseType.name = `${expenseType.parent.parent.name} > ${expenseType.parent.name} > ${expenseType.name}`;
          } else if (expenseType?.parent) {
            expenseType.name = `${expenseType.parent.name} > ${expenseType.name}`;
          }
          return expenseType;
        });
        rows.values = expenseTypes;
        setRows(rows);
      });
  }, [rowsPerPage, page, orderBy, searchBy, forbidIds]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <StyledCrudTable
          title={'Seleção de Tipo de Despesa'}
          modelActions={ExpenseType.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[3]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderby(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderby(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            plus: {
              icon: <Plus />,
              onClick: (id, data) => {
                setFetching(true);
                restApiClient.expenseTypeExpenseType
                  .create({
                    expenseTypeId,
                    childId: id,
                    operator: '+',
                  })
                  .then(() => {
                    onSuccess?.(id);
                    setFetching(false);
                  });
              },
              title: 'Soma',
              allowedActions: [ExpenseType.actions.getAll],
              selector: () => !fetching,
            },
            minus: {
              icon: <Minus />,
              onClick: (id, data) => {
                setFetching(true);
                restApiClient.expenseTypeExpenseType
                  .create({
                    expenseTypeId,
                    childId: id,
                    operator: '-',
                  })
                  .then(() => {
                    onSuccess?.(id);
                    setFetching(false);
                  });
              },
              title: 'Subtração',
              allowedActions: [ExpenseType.actions.getAll],
              selector: () => !fetching,
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectExpenseTypeFieldDialogControl;
