/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import ExpenseType from '../../forms/expenseType';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
  FormHelperText,
  Receipt,
} from './styles';
import { isEmpty } from 'lodash';
import { Hidden } from '@material-ui/core';
import AdornedTextField from '../adornedTextField';

const SelectExpenseTypeDialogControl = ({
  data,
  className,
  id,
  enabled = true,
  uischema,
  schema,
  path,
  label,
  handleChange,
  onSuccess,
  config,
  errors,
  visible = true,
  disabled,
  required,
  shrink,
  forbidIds,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedExpenseType, setSelectedExpenseType] = React.useState({});

  const handleClickOpen = () => {
    if (enabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const columns = ExpenseType.tableSchema;

  useEffect(() => {
    restApiClient.expenseType
      .getAllLeaf(
        rowsPerPage,
        page * rowsPerPage,
        forbidIds
          ? searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp.notIn('id', forbidIds);
                  rightOp
                    .like('name', searchBy)
                    .or()
                    .like('parent.name', searchBy)
                    .or()
                    .like('parent.parent.name', searchBy)
                    .or()
                    .like('parent.parent.parent.name', searchBy);
                })
                .toString()
            : new FilterTree().notIn('id', forbidIds).toString()
          : searchBy
          ? new FilterTree()
              .like('name', searchBy)
              .or()
              .like('parent.name', searchBy)
              .or()
              .like('parent.parent.name', searchBy)
              .or()
              .like('parent.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        const rows = e.data;
        let expenseTypes = e.data.values;
        expenseTypes = expenseTypes.map((expenseType) => {
          if (expenseType?.parent?.parent?.parent) {
            expenseType.name = `${expenseType.parent.parent.parent.name} > ${expenseType.parent.parent.name} > ${expenseType.parent.name} > ${expenseType.name}`;
          } else if (expenseType?.parent?.parent) {
            expenseType.name = `${expenseType.parent.parent.name} > ${expenseType.parent.name} > ${expenseType.name}`;
          } else if (expenseType?.parent) {
            expenseType.name = `${expenseType.parent.name} > ${expenseType.name}`;
          }
          return expenseType;
        });
        rows.values = expenseTypes;
        setRows(rows);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    if (data) {
      restApiClient.expenseType.findById(data).then((e) => {
        setSelectedExpenseType(e.data);
      });
    } else {
      setSelectedExpenseType({});
    }
  }, [data]);

  let expenseTypeName = '';

  if (selectedExpenseType?.parent?.parent?.parent) {
    expenseTypeName = `${selectedExpenseType.parent.parent.parent.name} > ${selectedExpenseType.parent.parent.name} > ${selectedExpenseType.parent.name} > ${selectedExpenseType.name}`;
  } else if (selectedExpenseType?.parent?.parent) {
    expenseTypeName = `${selectedExpenseType.parent.parent.name} > ${selectedExpenseType.parent.name} > ${selectedExpenseType.name}`;
  } else if (selectedExpenseType?.parent) {
    expenseTypeName = `${selectedExpenseType.parent.name} > ${selectedExpenseType.name}`;
  } else {
    expenseTypeName = selectedExpenseType.name;
  }

  return (
    <Hidden xsUp={!visible}>
      <Container>
        <AdornedTextField
          className={className}
          disabled={!enabled}
          error={!isEmpty(errors)}
          adornment={<Receipt />}
          clickableAdornment
          onAdornmentClick={handleClickOpen}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleClickOpen}
          label="Tipo de Despesa"
          InputLabelProps={{
            readOnly: true,
            shrink,
          }}
          value={expenseTypeName ?? ''}
          required={required}
          helperText={
            !isEmpty(errors) ? (
              <FormHelperText error>{`${errors}`}</FormHelperText>
            ) : (
              ''
            )
          }
        />
      </Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <StyledCrudTable
            title={'Seleção de Tipo de Despesa'}
            modelActions={ExpenseType.actions}
            columns={columns}
            rows={rows}
            page={page}
            setPage={(page) => setPage(page)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[3]}
            setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            onOrderByChange={(orderBy) => setOrderby(orderBy)}
            orderBy={orderBy}
            setOrderBy={(value) => setOrderby(value)}
            searchBy={searchBy}
            setSearchBy={(value) => setSearchBy(value)}
            actions={{
              insert: {
                icon: <AddBox />,
                onClick: (id, data) => {
                  setSelectedExpenseType(data);
                  handleChange?.(path, id);
                  onSuccess?.(id);
                  handleClose();
                },
                title: 'Vincular',
                allowedActions: [ExpenseType.actions.getAllLeaf],
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Hidden>
  );
};

export default SelectExpenseTypeDialogControl;
