import React, { useState, useEffect } from 'react';
import Employee from '../../forms/employee';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import { Container, StyledCrudTable } from './styles';

function LabCriticalCard(props) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Employee.labEmployeeBusyLevelTableSchema;

  useEffect(() => {
    restApiClient.employee
      .getAllLabEmployeeWithBusyLevel(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <StyledCrudTable
        title={'Ocupação do laboratório'}
        modelActions={Employee.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        availableRowsPerPage={[6]}
      />
    </Container>
  );
}

export default LabCriticalCard;
