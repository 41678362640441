const ToothType = {
  schema: {
    type: 'object',
    properties: {
      toothType: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
      toothShades: {
        type: 'array',
        items: {
          type: 'string',
          upperCased: true,
          maxLength: 64,
        },
      },
    },
    required: ['toothType'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Tipo de Dente',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tipo',
            scope: '#/properties/toothType',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Tonalidades',
            },
            scope: '#/properties/toothShades',
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Atualização de Tipo de Dente',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tipo',
            scope: '#/properties/toothType',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: {
              text: 'Tonalidades',
            },
            scope: '#/properties/toothShades',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'toothType', label: 'Tipo', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'ToothTypeController@getAll',
    findById: 'ToothTypeController@findById',
    create: 'ToothTypeController@create',
    update: 'ToothTypeController@patchUpdate',
    delete: 'ToothTypeController@delete',
  },
};

export default ToothType;
