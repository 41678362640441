import React, { useState, useEffect, useCallback } from 'react';
import OrderService from '../../../../forms/orderService';
import { Container, Preview, GetApp } from './styles';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import restApiClient from '../../../../services/restApiClient';
import OrderServiceStep from '../../../../forms/orderServiceStep';

function LabAllOrderServiceCompletedLaboratoryList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [orderServiceStepId, setOrderServiceStepId] = useState(null);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const columns = OrderService.labOSCompletedLaboratoryTableSchema;

  const loadServiceOrders = useCallback(() => {
    restApiClient.orderServiceStep
      .getAllOrderServiceStepCompletedLaboratory(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('orderService.patient.name', searchBy)
              .or()
              .like('orderService.patient.record_number', searchBy)
              .or()
              .like('dentist.user.name', searchBy)
              .or()
              .like('orderService.endProduct.name', searchBy)
              .or()
              .like('step.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadServiceOrders();
  }, [loadServiceOrders]);

  return (
    <Container>
      <CrudTable
        title={'Ordens de Serviço Concluídas pelo Laboratório'}
        modelActions={OrderServiceStep.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: (
              <span>Deseja enviar ordem de serviço para a unidade?</span>
            ),
            onSuccess: () => {
              restApiClient.orderServiceStep
                .setInTransit(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                  setSubmitConfirmationId(-1);
                });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          viewOS: {
            icon: <Preview />,
            onClick: (orderServiceStepId, row) => {
              history.push({
                pathname: `/lab/orderService/update/${
                  row.orderService.endProductId
                }/${orderServiceStepId}/${true}/${row.workflowPosition}/${
                  row.orderServiceId
                }`,
                state: {
                  dentistId: row.dentistId,
                  date: row.createdAt,
                  allowView: true,
                  patientName: row.orderService?.patient?.name,
                  recordNumber: row.orderService?.patient?.recordNumber,
                },
              });
            },
            title: 'Visualizar Etapa',
            allowedActions: [
              OrderServiceStep.actions
                .getAllOrderServiceStepCompletedLaboratory,
            ],
          },
          sendOS: {
            icon: <GetApp />,
            onClick: (id, row) => {
              setOrderServiceStepId(row.id);
              setSubmitConfirmationId(id);
            },
            title: 'Enviar Ordem de Serviço',
            allowedActions: [OrderServiceStep.actions.setInTransit],
          },
        }}
        customCellRenderers={{
          'schedule.labReturnDate': {
            renderer: (field, value) => {
              const date = new Date(value + ' 00:00:00');
              return `${date.toLocaleDateString('pt-br')}`;
            },
          },
        }}
      />
    </Container>
  );
}

export default LabAllOrderServiceCompletedLaboratoryList;
