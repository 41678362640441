import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectSectionDialogControl from '../../components/selectSectionDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectSectionDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectSectionDialog'
  )
);

const customSelectSectionDialogControlTester = rankWith(
  4,
  isSelectSectionDialogControl
);

export default {
  tester: customSelectSectionDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectSectionDialogControl),
};
