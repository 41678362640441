import React, { useState } from 'react';
import Step from '../../../../forms/step';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { Container } from './styles';
import { isEqualWith } from 'lodash';

function StepCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Step}
        restGetDataById={restApiClient.step.findById}
        restCreateData={restApiClient.step.create}
        restUpdateData={ async (modelId, updateOriginalData, modelData) => {
          const added = modelData.suggestedExecutors.filter(
            item => !updateOriginalData.suggestedExecutors.some(obj =>
              isEqualWith(obj, item, (obj1, obj2) =>
                obj1.employeeId === obj2.employeeId &&
                obj1.stepId === obj2.stepId
              )
            )
          );

          const removed = updateOriginalData.suggestedExecutors.filter(
            item => !modelData.suggestedExecutors.some(obj =>
              isEqualWith(obj, item, (obj1, obj2) =>
                obj1.employeeId === obj2.employeeId &&
                obj1.stepId === obj2.stepId
              )
            )
          ).map(item => item.id);
          delete modelData.suggestedExecutors;
          delete updateOriginalData.suggestedExecutors;
          const result = await restApiClient.step.update(
            modelId, updateOriginalData, modelData, added, removed);
          return result;
        }}
        onCreateSuccess={() => {
          props.history.goBack();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        {...props}
      />
      
    </Container>
  );
}

export default StepCreateUpdate;
