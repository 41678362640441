import React, { useState, useEffect } from 'react';
import Patient from '../../../forms/patient';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container } from './styles';

function PatientList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ recordNumber: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Patient.tableSchema;

  useEffect(() => {
    restApiClient.patient
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('name', searchBy)
              .or()
              .like('unit.name', searchBy)
              .or()
              .like('recordNumber', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Gestão de Pacientes'}
        modelActions={Patient.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: (patientId) => history.push(`/patient/create`),
          },
          update: {
            onClick: (patientId) =>
              history.push(`/patient/update/${patientId}`),
          },
        }}
        customCellRenderers={{
          recordNumber: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default PatientList;
