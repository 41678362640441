const Recess = {
  schema: {
    type: 'object',
    properties: {
      date: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      beginTime: {
        type: 'string',
        beginTime: '',
        format: 'time',
        customErrors: '',
      },
      endTime: {
        type: 'string',
        endTime:  '',
        format: 'time',
        customErrors: '',
      },
    },
    required: ['date', 'beginTime', 'endTime'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Feriado',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dia',
            scope: '#/properties/date',
          },
          {
            type: 'Control',
            label: 'Início',
            scope: '#/properties/beginTime',
          },
          {
            type: 'Control',
            label: 'Término',
            scope: '#/properties/endTime',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'date', label: 'Dia', minWidth: 100, align: 'center' },
    { id: 'beginTime', label: 'Início', minWidth: 100, align: 'center' },
    { id: 'endTime', label: 'Término', minWidth: 100, align: 'center' },
  ],
  data: {
    beginTime: '00:00',
    endTime: '23:59',
  },
  actions: {
    getAll: 'RecessController@getAll',
    getAllByCalendarId: 'RecessController@getAllByCalendarId',
    findById: 'RecessController@findById',
    create: 'RecessController@create',
    delete: 'RecessController@delete',
  },
};

export default Recess;
