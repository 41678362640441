/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PurchaseEntry from '../../forms/purchaseEntry';
import Dialog from '@material-ui/core/Dialog';
import restApiClient from '../../services/restApiClient';
import {
  Container,
  DialogContent,
  DialogActions,
  StyledCrudTable,
  Button,
  FileDownload,
} from './styles';
import FilterTree from '../../helpers/filterTree';

function InvoiceListDialog({
  onSuccess,
  onError,
  onCancel,
  modelId,
  ...props
}) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  useEffect(() => {
    if (modelId) {
      restApiClient.purchaseEntry
        .getAllByPurchaseId(
          modelId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree().like('invoiceId', searchBy).toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, modelId]);

  const columns = PurchaseEntry.tableSchema;

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCrudTable
            title={'Anexos'}
            columns={columns}
            rows={rows}
            page={page}
            setPage={(page) => setPage(page)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[3]}
            setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            onOrderByChange={(orderBy) => setOrderby(orderBy)}
            orderBy={orderBy}
            setOrderBy={(value) => setOrderby(value)}
            searchBy={searchBy}
            setSearchBy={(value) => setSearchBy(value)}
            actions={{
              download: {
                icon: <FileDownload />,
                onClick: async (id) => {
                  await restApiClient.purchaseEntry.downloadInvoice(id);
                },
                title: 'Download Anexo',
                allowedActions: [PurchaseEntry.actions.downloadInvoice],
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCancel?.();
            }}
            type="button"
            variant="contained"
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default InvoiceListDialog;
