/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
} from './styles';
import ItemizedCardInstallment from '../../forms/itemizedCardInstallment';

const ShowItemizedCardInstallment = ({ itemizedCardId, handleClose }) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const columns = ItemizedCardInstallment.tableSchema;

  useEffect(() => {
    if (itemizedCardId) {
      restApiClient.itemizedCardInstallment
        .getAllByItemizedCardId(
          itemizedCardId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy ? new FilterTree().like('id', searchBy).toString() : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [itemizedCardId, rowsPerPage, page, orderBy, searchBy]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={!!itemizedCardId}
      onClose={handleClose}
    >
      <DialogContent>
        <StyledCrudTable
          title={'Visualização de Parcelas'}
          modelActions={ItemizedCardInstallment.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[3]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderby(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderby(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          customCellRenderers={{
            date: {
              renderer: (field, value) => {
                return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
              },
            },
            value: {
              renderer: (field, value) => {
                return value.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
              },
            },
            liquid: {
              renderer: (field, value) => {
                return value.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowItemizedCardInstallment;
