import React, { useState, useEffect } from 'react';
import Attendance from '../../../../../forms/attendance';
import Substitute from '../../../../../forms/substitute';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  FileEarmarkMedicalFill,
  TextBulletListSquareWarning,
  Preview,
} from './styles';
import moment from 'moment';
import DisapprovedJusticationDialog from '../../../../../components/disapprovedJustificationDialog';

function ClinicProceduresAuditDisapprovedList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDisapproved, setOpenDisapproved] = useState(false);
  const [disapprovedJustification, setDisapprovedJustification] =
    useState(null);
  const columns = Attendance.tableSchema;

  useEffect(() => {
    restApiClient.attendance
      .getAllAttendancesClinic(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild.like('status', 'Reprovado');
                rightChild
                  .like('id', searchBy)
                  .or()
                  .like('patient.name', searchBy)
                  .or()
                  .like('procedure.name', searchBy)
                  .or()
                  .like('unit.name', searchBy)
                  .or()
                  .like('status', searchBy);
              })
              .toString()
          : new FilterTree().like('status', 'Reprovado').toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Procedimentos Reprovados'}
        modelActions={Attendance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          view: {
            icon: <Preview />,
            onClick: (id) => {
              history.push({
                pathname: `/clinic/dentist/audit/procedures/disapproved/${id}`,
                state: {
                  readonly: true,
                },
              });
            },
            title: 'Visualizar Atendimento',
            allowedActions: [[Attendance.actions.findById]],
          },
          auditReplacement: {
            icon: <FileEarmarkMedicalFill />,
            onClick: (id, row) => {
              history.push({
                pathname: `/clinic/dentist/audit/replacement/procedure/${id}`,
                state: {
                  readonly: true,
                  disaproved: true,
                  substituteDentistId: row.dentistId,
                  replacedDentistId: row.replacedDentistId,
                },
              });
            },
            title: 'Auditar Substituição',
            allowedActions: [
              [
                Attendance.actions.findById,
                Attendance.actions.setApproved,
                Attendance.actions.setDisapproved,
                Substitute.actions.findById,
                Substitute.actions.getAllByReplacement,
              ],
            ],
            selector: (procedure) => procedure.type === 'Substituição',
          },
          auditProductivity: {
            icon: <FileEarmarkMedicalFill />,
            onClick: (id) => {
              history.push({
                pathname: `/clinic/dentist/audit/procedures/disapproved/procedure/${id}`,
                state: {
                  readonly: true,
                  disaproved: true,
                },
              });
            },
            title: 'Auditar Procedimento',
            allowedActions: [
              [
                Attendance.actions.findById,
                Attendance.actions.setApproved,
                Attendance.actions.setDisapproved,
              ],
            ],
            selector: (procedure) =>
              procedure.type === 'Procedimentos' ||
              procedure.type === 'Fixo' ||
              procedure.type === 'Pacientes',
          },
          viewRejection: {
            icon: <TextBulletListSquareWarning />,
            onClick: (orderServiceId, row) => {
              setOpenDisapproved(true);
              setDisapprovedJustification(row?.disapprovedJustification);
            },
            title: 'Visualizar Justificativa da Reprovação',
            selector: (row) => row?.disapprovedJustification !== null,
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
      />

      <DisapprovedJusticationDialog
        open={openDisapproved}
        disapprovedJustification={disapprovedJustification}
        onCancel={() => setOpenDisapproved(false)}
      />
    </Container>
  );
}

export default ClinicProceduresAuditDisapprovedList;
