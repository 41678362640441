/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import JsonForm from '../jsonForm';
import { isEmpty, isEqual, size } from 'lodash';

import {
  Container,
  Form,
  SubmitButton,
  CancelButton,
  ButtonsContainer,
  ProgressContainer,
} from './styles';
import {
  objectFilter,
  isNullOrEmptyObject,
  objectReduce,
} from '../../extensions/object';
import { CircularProgress } from '@material-ui/core';

class CustomEvent extends Event {
  constructor(data, a, b = {}) {
    super(a, b);
    this.data = data;
  }
}

function CreateUpdateForm({
  modelSchema,
  schemaSelector,
  uischemaSelector,
  restCreateData,
  restUpdateData,
  restGetDataById,
  forceId,
  onCancelClick,
  onCreateSuccess,
  onUpdateSuccess,
  onError,
  match,
  history,
  className,
  config,
  customButtonsBuilder,
  onChange,
  mapCustomErrors,
  clearOnSuccess,
  ...props
}) {
  const [updateOriginalData, setUpdateOriginalData] = useState({});
  const [modelData, setModelData] = useState(
    props.modelData ?? modelSchema.data ?? {}
  );
  const [requestErrors, setRequestErrors] = useState({});
  const [allErrors, setAllErrors] = useState(!!forceId);
  const [crrErrors, setCrrErrors] = useState([]);
  const [fetching, setFetching] = useState(false);
  const modelId = forceId ?? match?.params?.id;
  const schema = schemaSelector?.(modelSchema, modelId) ?? modelSchema.schema;
  const uischema =
    uischemaSelector?.(modelSchema, modelId) ??
    (modelId
      ? modelSchema.updateUischema ?? modelSchema.uischema
      : modelSchema.uischema);
  const refForm = useRef(null);

  const requestData = () => {
    restGetDataById(modelId).then((e) => {
      const data = objectFilter(e.data, (key, value) => value !== null);
      setUpdateOriginalData(data);
      setAllErrors(true);
      setModelData({ ...modelData, ...data });
      onChange?.(data);
    });
  };

  useEffect(() => {
    if (modelId) {
      requestData();
    }
  }, [modelId]);

  useEffect(() => {
    if (props.modelData) {
      const data = { ...props.modelData, ...modelData };
      setModelData(data);
      onChange?.(data);
    }
  }, [props.modelData]);

  return (
    <Container className={className}>
      <Form
        ref={refForm}
        onSubmit={(event) => {
          event.persist();
          event.preventDefault();
          setAllErrors(true);
          if (isEmpty(crrErrors)) {
            if (!modelId) {
              setFetching(true);
              restCreateData({ ...modelData })
                .then((x) => {
                  if (clearOnSuccess) {
                    setModelData(props.modelData ?? modelSchema.data ?? {});
                    setAllErrors(false);
                  }
                  onCreateSuccess?.(x, event.nativeEvent.data);
                  setFetching(false);
                })
                .catch((x) => {
                  if (x.response?.status === 400) {
                    const newModelData = objectReduce(
                      x.response.data.errors,
                      (res, key) => {
                        if (!res[key]) res[key] = null;
                        return res;
                      },
                      { ...modelData }
                    );
                    setModelData(newModelData);
                    onChange?.(newModelData);
                    let errors = x.response.data.errors;
                    if (mapCustomErrors) {
                      errors = mapCustomErrors(errors, newModelData);
                    }
                    setRequestErrors(errors);
                    onError?.(errors);
                  } else if (x.response?.status === 500) {
                    onError?.(x);
                  }
                  setFetching(false);
                });
            } else {
              setFetching(true);
              restUpdateData(
                modelId,
                { ...updateOriginalData },
                { ...modelData }
              )
                .then((x) => {
                  if (clearOnSuccess) {
                    setModelData(props.modelData ?? modelSchema.data ?? {});
                    setAllErrors(false);
                  } else {
                    setModelData({ ...modelData });
                    onChange?.({ ...modelData });
                    if (forceId === -1) {
                      requestData();
                    }
                  }
                  onUpdateSuccess?.(x, event.nativeEvent.data);
                  setFetching(false);
                })
                .catch((x) => {
                  if (x.response?.status === 400) {
                    const newModelData = objectReduce(
                      x.response.data.errors,
                      (res, key) => {
                        if (!res[key]) res[key] = null;
                        return res;
                      },
                      { ...modelData }
                    );
                    setModelData(newModelData);
                    let errors = x.response.data.errors;

                    if (mapCustomErrors) {
                      errors = mapCustomErrors(errors, newModelData);
                    }
                    setRequestErrors(errors);
                    onError?.(x.response.data.errors);
                    setFetching(false);
                  }
                });
            }
          }
        }}
      >
        <JsonForm
          schema={schema}
          uischema={uischema}
          data={modelData}
          allErrors={allErrors}
          onChange={({ errors, data, isAsyncUpdate }) => {
            if (!isNullOrEmptyObject(requestErrors)) {
              let newErrors = objectFilter(
                requestErrors,
                (key) => data[key] === modelData[key]
              );
              if (!isEqual(requestErrors, newErrors)) {
                setRequestErrors(newErrors);
              }
            }
            if (!isNullOrEmptyObject(data)) {
              setCrrErrors(errors);
              if (isAsyncUpdate) {
                const rawData = { ...modelData, ...data };
                setModelData(rawData);
                onChange?.(rawData);
              } else if (
                size(data) >= size(modelData) &&
                !isEqual(data, modelData)
              ) {
                setModelData(data);
                onChange?.(data);
              }
            }
          }}
          customErrors={requestErrors}
          config={{
            completeData: modelData,
            cancelLabel: 'Cancelar',
            clearLabel: 'Limpar',
            okLabel: 'Confirmar',
            dateFormat: 'DD/MM/YYYY',
            dateTimeFormat: 'DD/MM/YYYY HH:mm',
            ...config,
          }}
          {...props}
        />
        <ButtonsContainer>
          {customButtonsBuilder ? (
            customButtonsBuilder(
              (data) =>
                refForm.current.dispatchEvent(new CustomEvent(data, 'submit')),
              modelData
            )
          ) : (
            <>
              <CancelButton
                onClick={onCancelClick ? onCancelClick : () => history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              {fetching ? (
                <ProgressContainer>
                  <CircularProgress size={24} />
                </ProgressContainer>
              ) : (
                <SubmitButton type="submit" variant="contained" color="primary">
                  {modelId ? 'Atualizar' : 'Cadastrar'}
                </SubmitButton>
              )}
            </>
          )}
        </ButtonsContainer>
      </Form>
    </Container>
  );
}

export default CreateUpdateForm;
