export const localize_pt_BR = (errors) => {
  if (!(errors && errors.length)) return;
  for (let i = 0; i < errors.length; i++) {
    let e = errors[i];
    let out;
    let cond;
    let n;
    switch (e.keyword) {
      case '$ref':
        out = 'Não é possível resolver a referência.' + e.params.ref;
        break;
      case 'additionalItems':
        out = 'Não são permitidos itens adicionais.';
        break;
      case 'additionalProperties':
        out = 'Não são permitidas propriedades adicionais.';
        break;
      case 'anyOf':
        out = 'Os dados não correspondem a nenhum esquema de "anyOf".';
        break;
      case 'const':
        out = 'Deve ser igual à constante.';
        break;
      case 'contains':
        out = 'Deve conter um item válido.';
        break;
      case 'custom':
        out = 'Deve passar a validação de palavras-chave "' + e.keyword + '".';
        break;
      case 'dependencies':
        out = '';
        n = e.params.depsCount;
        out += 'Deve ter propriedade';
        if (n !== 1) {
          out += 's';
        }
        out +=
          ' ' +
          e.params.deps +
          ' quando a propriedade ' +
          e.params.property +
          ' estiver presente.';
        break;
      case 'enum':
        out = 'Deve ser igual a um dos valores predefinidos.';
        break;
      case 'exclusiveMaximum':
        out = '';
        cond = e.params.comparison + ' ' + e.params.limit;
        out += 'Deve ser ' + cond + '.';
        break;
      case 'exclusiveMinimum':
        out = '';
        cond = e.params.comparison + ' ' + e.params.limit;
        out += 'Deve ser ' + cond + '.';
        break;
      case 'false schema':
        out = 'O schema booleano é "false".';
        break;
      case 'format':
        out = 'Formato "' + e.params.format + '" inválido.';
        break;
      case 'formatExclusiveMaximum':
        out = 'formatExclusiveMaximum deve ser um booleano.';
        break;
      case 'formatExclusiveMinimum':
        out = 'formatExclusiveMinimum deve ser um booleano.';
        break;
      case 'formatMaximum':
        out = '';
        cond = e.params.comparison + ' ' + e.params.limit;
        out += 'Deve ser ' + cond + '.';
        break;
      case 'formatMinimum':
        out = '';
        cond = e.params.comparison + ' ' + e.params.limit;
        out += 'Deve ser ' + cond + '.';
        break;
      case 'if':
        out = 'Deve corresponder ao schema "' + e.params.failingKeyword + '".';
        break;
      case 'maximum':
        out = '';
        cond = e.params.comparison + ' ' + e.params.limit;
        out += 'Deve ser ' + cond + '.';
        break;
      case 'maxItems':
        out = '';
        n = e.params.limit;
        out += 'Não deve ter mais que ' + n + ' elemento';
        if (n !== 1) {
          out += 's.';
        } else {
          out += '.';
        }
        break;
      case 'maxLength':
        out = '';
        n = e.params.limit;
        out += 'Não deve ter mais que ' + n + ' caracter';
        if (n !== 1) {
          out += 'es.';
        } else {
          out += '.';
        }
        break;
      case 'maxProperties':
        out = '';
        n = e.params.limit;
        out += 'Não deve ter mais que ' + n + ' propriedade';
        if (n !== 1) {
          out += 's.';
        } else {
          out += '.';
        }
        break;
      case 'minimum':
        out = '';
        cond = e.params.comparison + ' ' + e.params.limit;
        out += 'Deve ser ' + cond + '.';
        break;
      case 'minItems':
        out = '';
        n = e.params.limit;
        out += 'Não deve ter menos que ' + n + ' elemento';
        if (n !== 1) {
          out += 's.';
        } else {
          out += '.';
        }
        break;
      case 'minLength':
        out = '';
        n = e.params.limit;
        out += 'Não deve ter menos que ' + n + ' caracter';
        if (n !== 1) {
          out += 'es.';
        } else {
          out += '.';
        }
        break;
      case 'minProperties':
        out = '';
        n = e.params.limit;
        out += 'Não deve ter menos que ' + n + ' propriedade';
        if (n !== 1) {
          out += 's.';
        } else {
          out += '.';
        }
        break;
      case 'multipleOf':
        out = 'Deve ser múltiplo de ' + e.params.multipleOf + '.';
        break;
      case 'not':
        out = 'Não deve ser valido segundo o esquema em "not".';
        break;
      case 'oneOf':
        out = 'Deve corresponder exatamente com um esquema em "oneOf".';
        break;
      case 'pattern':
        out = 'Deve corresponder ao padrão "' + e.params.pattern + '".';
        break;
      case 'patternRequired':
        out =
          'Deve ter a propriedade de padrão de correspondência "' +
          e.params.missingPattern +
          '".';
        break;
      case 'propertyNames':
        out =
          "O nome da propriedade '" + e.params.propertyName + "' é inválido.";
        break;
      case 'required':
        out = 'Este campo é obrigatório.';
        break;
      case 'switch':
        out =
          'Deve passar a validação "switch" de palavra-chave, o caso ' +
          e.params.caseIndex +
          ' falha.';
        break;
      case 'type':
        out = 'Deve ser ' + e.params.type + '.';
        break;
      case 'uniqueItems':
        out =
          'Não deve ter itens duplicados (os itens ## ' +
          e.params.j +
          ' e ' +
          e.params.i +
          ' são idênticos).';
        break;
      default:
        continue;
    }
    e.message = out;
  }
};
