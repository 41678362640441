import styled from 'styled-components';
import {
  Box as MaterialBox,
  AddBox as MaterialAddBox,
} from '../../../styles/icons';
import {
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  Button as MaterialButton,
} from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SelectContainer = styled.div`
  width: 300px;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
  /* margin-top: 11px !important; */
`;
export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;
export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;
export const MenuItem = styled(MaterialMenuItem)``;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
