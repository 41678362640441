import styled from 'styled-components';
import MoneyInput from '../../../../components/moneyInput';
import { Check as MaterialCheck } from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 110px;
`;

export const Check = styled(MaterialCheck)`
  width: 25px;
  height: 25px;
`;
