import styled from 'styled-components';
import { Button as MaterialButton, } from '@material-ui/core';
import { 
  Close as MaterialClose,
  CalendarAdd as MaterialCalendarAdd,
  PriceChange as MaterialPriceChange,
  Hourglass as MaterialHourglass,
  Procedures as MaterialProcedures,
  Patient as MaterialPatient
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CalendarAdd = styled(MaterialCalendarAdd)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Procedures = styled(MaterialProcedures)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const PriceChange = styled(MaterialPriceChange)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Hourglass = styled(MaterialHourglass)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const PatientIcon = styled(MaterialPatient)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
