import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectToothNumberControl from '../../components/selectToothNumberControl';
import isEmpty from 'lodash/isEmpty';

const isSelectToothNumberControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectToothNumber'
  )
);

const customSelectToothNumberControlTester = rankWith(
  4,
  isSelectToothNumberControl
);

export default {
  tester: customSelectToothNumberControlTester,
  renderer: withJsonFormsEnumProps(SelectToothNumberControl),
};
