import React, { useState, useEffect } from 'react';
import restApiClient from '../../../../services/restApiClient';

import {
  Container,
  ButtonsContainer,
  HeaderContainer,
  Paper,
  StyledTransferList,
  CancelButton,
  SubmitButton,
  Form,
} from './styles';

function ManageSpecialties(props) {
  const dentistId = props.match.params?.id;

  const [userCurrentSpecialties, setUserCurrentSpecialties] = useState([]);
  const [systemSpecialties, setSystemSpecialties] = useState([]);
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);

  useEffect(() => {
    restApiClient.specialty
      .getAll()
      .then((result) => {
        setSystemSpecialties(result.data.values);
      })
      .catch((e) => console.error(e));

    restApiClient.dentist
      .findById(dentistId)
      .then((result) => {
        setUserCurrentSpecialties(result.data.dentistSpecialties);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const newLeftData = systemSpecialties.filter(
      (x) => !userCurrentSpecialties.some((y) => y.id === x.id)
    );
    const newRightData = systemSpecialties.filter((x) =>
      userCurrentSpecialties.some((y) => y.id === x.id)
    );
    setLeftData(newLeftData);
    setRightData(newRightData);
  }, [userCurrentSpecialties, systemSpecialties]);

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const removed = userCurrentSpecialties
            .filter((action) => leftData.some((x) => x.id === action.id))
            .map((x) => x.id);
          const added = rightData
            .filter((x) => !userCurrentSpecialties.some((y) => y.id === x.id))
            .map((x) => x.id);
          if (removed.length > 0 || added.length > 0) {
            restApiClient.dentist.update(dentistId, {}, {}, added, removed, true);
          }
          props.history.goBack();
        }}
      >
        <Paper>
          <HeaderContainer>
            <span>
              {'Vínculo de Especialidades'}
            </span>
          </HeaderContainer>
          <StyledTransferList
            leftData={leftData}
            rightData={rightData}
            labelSelector={(item) => item.name ?? ''}
            onChange={(leftData, rightData) => {
              setLeftData(leftData);
              setRightData(rightData);
            }}
          />
        </Paper>
        <ButtonsContainer>
          <CancelButton
            onClick={() => props.history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Cancelar
          </CancelButton>
          <SubmitButton type="submit" variant="contained" color="primary">
            {'Atualizar'}
          </SubmitButton>
        </ButtonsContainer>
      </Form>
    </Container>
  );
}

export default ManageSpecialties;
