import React, {useEffect}from 'react';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import Validator from '../../helpers/validator';

import { Container } from './styles';
import { customRenderers, customCells } from '../../renderers';

function JsonForm({ customErrors, allErrors, ...props }) {
  useEffect(() => {
    Validator.cache = {};
  }, []);
  
  const options = {
    schemaId: 'auto',
    allErrors: allErrors,
    jsonPointers: true,
    errorDataPath: allErrors ? 'property' : undefined,
    verbose: true,
  };
  
  const validator = new Validator(options);
  validator.addCustomFormats();
  validator.addCustomKeywords(customErrors);
  Validator.asyncUpdate = (data) => {
    props.onChange({ data, isAsyncUpdate: true });
  };
  return (
    <Container>
      <JsonForms
        renderers={[...materialRenderers, ...customRenderers]}
        cells={[...materialCells, ...customCells]}
        ajv={validator}
        {...props}
      />
    </Container>
  );
}

export default JsonForm;
