import React, { useState, useEffect, useCallback } from 'react';
import ExpenseTypeExpenseType from '../../../../../../forms/ExpenseTypeExpenseType';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import { CancelButton, Container } from './styles';
import SelectExpenseTypeFieldDialogControl from '../../../../../../components/selectExpenseTypeFieldDialogControl';

function ExpenseTypeExpenseTypeList({ history, match, location }) {
  const expenseTypeId = match?.params?.expenseTypeId ?? -1;
  const expenseType = location?.state?.expenseType;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);

  const columns = ExpenseTypeExpenseType.tableSchema;

  const loadExpenseTypeExpenseTypes = useCallback(() => {
    if (expenseTypeId) {
      restApiClient.expenseTypeExpenseType
        .getAllByExpenseTypeId(
          expenseTypeId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('expenseType.parent.name', searchBy)
                .or()
                .like('expenseType.parent.parent.name', searchBy)
                .or()
                .like('expenseType.parent.parent.parent.name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, expenseTypeId]);

  useEffect(() => {
    loadExpenseTypeExpenseTypes();
  }, [loadExpenseTypeExpenseTypes]);

  return (
    <Container>
      <CrudTable
        title={`Vincular tipo de despesa no campo ${expenseType?.name}`}
        modelActions={ExpenseTypeExpenseType.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpen(true);
            },
            title: 'Vincular tipo de despesa',
          },
          delete: {
            onClick: (expenseTypeExpenseTypeId, index) => {
              restApiClient.expenseTypeExpenseType
                .delete(expenseTypeExpenseTypeId)
                .then(() => {
                  loadExpenseTypeExpenseTypes();
                });
            },
          },
        }}
        customCellRenderers={{
          name: {
            renderer: (field, value, column, row) => {
              if (row.expenseType) {
                if (row.expenseType?.parent?.parent?.parent) {
                  return `${row.expenseType.parent.parent.parent.name} > ${row.expenseType.parent.parent.name} > ${row.expenseType.parent.name} > ${row.expenseType.name}`;
                } else if (row.expenseType?.parent?.parent) {
                  return `${row.expenseType.parent.parent.name} > ${row.expenseType.parent.name} > ${row.expenseType.name}`;
                } else if (row.expenseType?.parent) {
                  return `${row.expenseType.parent.name} > ${row.expenseType.name}`;
                } else {
                  return row.expenseType?.name ?? '-';
                }
              } else {
                return row.child.name;
              }
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <SelectExpenseTypeFieldDialogControl
        forbidIds={rows.values.map((x) => x.childId)}
        expenseTypeId={expenseTypeId}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={() => {
          loadExpenseTypeExpenseTypes();
        }}
      />
    </Container>
  );
}

export default ExpenseTypeExpenseTypeList;
