import React, { useState, useEffect, useCallback } from 'react';
import DentistOfficeHour from '../../../../../forms/dentistOfficeHour';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, CancelButton } from './styles';
import DentistOfficeHourDialog from '../../../../../components/dentistOfficeHourDialog';
import _ from 'lodash';

function OfficeHoursList({ history, match, ...props }) {
  const chairDentistId = parseInt(match?.params?.chairDentistId ?? -1);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ beginInterval: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [dentistOfficeHourId, setDentistOfficeHourId] = useState(false);

  const columns = DentistOfficeHour.tableSchema;

  const loadOfficeHours = useCallback(() => {
    restApiClient.officeHour
      .getAllByChairDentist(
        chairDentistId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('dayOfWeek', searchBy).toString()
          : null,
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        newRows.values = _.orderBy(e.data.values, 'numberDay', 'asc');
        setRows(newRows);
      });
  }, [rowsPerPage, page, orderBy, searchBy, chairDentistId]);

  useEffect(() => {
    loadOfficeHours();
  }, [loadOfficeHours]);

  return (
    <Container>
      <CrudTable
        title={'Horários de Atendimentos'}
        modelActions={DentistOfficeHour.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setDentistOfficeHourId(false);
              setOpen(true);
            },
          },
          update: {
            onClick: (id) => {
              setDentistOfficeHourId(id);
              setOpen(true);
            },
          },
          delete: {
            onClick: (id, index) => {
              restApiClient.officeHour.delete(id).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <DentistOfficeHourDialog
        open={open}
        selectedDentistOfficeHourId={dentistOfficeHourId}
        chairDentistId={chairDentistId}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          loadOfficeHours();
        }}
        {...props}
      />
    </Container>
  );
}

export default OfficeHoursList;
