const SectorExpenseType = {
  actions: {
    create: 'SectorExpenseTypeController@create',
    getAllBySectorId: 'SectorExpenseTypeController@getAllBySectorId',
    update: 'SectorExpenseTypeController@patchUpdate',
  },
  tableSchema: [
    { id: 'type', label: 'Despesa', minWidth: 100, align: 'center' },
    {
      id: 'expenseType',
      label: 'Tipo de Despesa',
      minWidth: 100,
      align: 'center',
    },
  ],
};
export default SectorExpenseType;
