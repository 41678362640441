import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  Button as MaterialButton,
  DialogTitle as MaterialDialogTitle,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  Typography as MaterialTypography,
  CardHeader as MaterialCardHeader,
  Card as MaterialCard,
  Grid as MaterialGrid,
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  FormHelperText as MaterialFormHelperText,
  Checkbox as MaterialCheckbox,
  FormControlLabel as MaterialFormControlLabel,
  FormGroup as MaterialFormGroup,
  TextField as MaterialTextField,
} from '@material-ui/core';
import MoneyInput from '../moneyInput';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Dialog = styled(MaterialDialog)``;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;

  & > * {
    margin: auto !important;
  }
`;

export const Typography = styled(MaterialTypography)``;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 8px 0px 8px 0px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  width: 500px !important;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const CardHeader = styled(MaterialCardHeader)``;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Grid = styled(MaterialGrid)``;

export const FormDiv = styled.div`
  margin: 0px 15px 15px 15px;
`;

export const SelectContainer = styled.div`
  width: 300px;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const FormHelperText = styled(MaterialFormHelperText)``;

export const Checkbox = styled(MaterialCheckbox)``;

export const FormControlLabel = styled(MaterialFormControlLabel)``;

export const FormGroup = styled(MaterialFormGroup)``;

export const StyledMoneyInput = styled(MoneyInput)``;

export const TextField = styled(MaterialTextField)`
  width: 100%;
  color: white;
`;
