import React from 'react';
import RecurrentExpense from '../../../../../forms/recurrentExpense';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container } from './styles';

function RecurrentExpenseCreateUpdate(props) {
  const recurrentExpensableType =
    props.location?.state?.recurrentExpensableType ?? undefined;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={RecurrentExpense}
        schemaSelector={(modelSchema) => {
          const schema = { ...modelSchema.schema };
          switch (recurrentExpensableType) {
            case 'outsourced':
              schema.properties.supplierId.format = 'selectOutsourcedDialog';
              break;
            case 'supplier':
              schema.properties.supplierId.format = 'selectSupplierDialog';
              break;
            case 'serviceSupplier':
              schema.properties.supplierId.format =
                'selectServiceSupplierDialog';
              break;
            default:
              break;
          }
          return schema;
        }}
        restGetDataById={async (id) => {
          const result = await restApiClient.recurrentExpense.findById(id);
          result.data.supplierId = result.data.recurrentExpensableId;
          return result;
        }}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          delete updateOriginalData.requestingUnitBoolean;
          delete modelData.requestingUnitBoolean;

          const result = await restApiClient.recurrentExpense.update(
            modelId,
            updateOriginalData,
            modelData
          );
          return result;
        }}
        onCreateSuccess={() => {
          props.history.goBack();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default RecurrentExpenseCreateUpdate;
