const StockConfig = {
  schema: {
    type: 'object',
    properties: {
      endDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      safetyStock: {
        type: 'integer',
        minimum: 1,
        customErrors: '',
      },
      monthCountForAverage: {
        type: 'integer',
        customErrors: '',
      },
      withdrawMinAverage: {
        type: 'integer',
        customErrors: '',
        minimum: 0,
      },
      stockProfitMargin: {
        type: 'number',
        format: 'percentage',
        customErrors: '',
      },
    },
    required: [
      'safetyStock',
      'endDate',
      'monthCountForAverage',
      'withdrawMinAverage',
      'stockProfitMargin',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Prazos do estoque',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Período de duração do estoque',
            scope: '#/properties/endDate',
          },
          {
            type: 'Control',
            label: 'Estoque de segurança',
            scope: '#/properties/safetyStock',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Meses para a média',
            scope: '#/properties/monthCountForAverage',
          },
          {
            type: 'Control',
            label: 'Média mínima de baixas',
            scope: '#/properties/withdrawMinAverage',
          },
          {
            type: 'Control',
            label: 'Margem de Lucro',
            scope: '#/properties/stockProfitMargin',
          },
        ],
      },
    ],
  },
  actions: {
    get: 'StockConfigController@get',
    update: 'StockConfigController@patchUpdate',
  },
};

export default StockConfig;
