import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectUnitDialogControl from '../../components/selectUnitDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectUnitDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectUnitDialog'
  )
);

const customSelectUnitDialogControlTester = rankWith(
  4,
  isSelectUnitDialogControl
);

export default {
  tester: customSelectUnitDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectUnitDialogControl),
};
