/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import {
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
} from './styles';
import Buyer from '../../forms/buyer';
import { AddBox, Button } from '../selectBuyerDialogControl/styles';

const SelectBuyerAddressDialog = ({
  onSuccess,
  onError,
  onCancel,
  buyerId,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const columns = Buyer.tableSchemaAddresses;

  const handleClose = () => {
    onCancel?.();
  };

  useEffect(() => {
    if (props.open) {
      restApiClient.buyerAddress
        .getAllByBuyerId(
          buyerId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('name', searchBy)
                .or()
                .like('brand', searchBy)
                .or()
                .like('barcode.barcode', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, props.open]);

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogContent>
        <StyledCrudTable
          title={'Endereços'}
          modelActions={Buyer.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[5]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderBy(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderBy(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            insert: {
              icon: <AddBox />,
              onClick: (id, data) => {
                onSuccess?.(data);
                handleClose();
              },
              title: 'Vincular',
              allowedActions: [Buyer.actions.getAllByBuyerId],
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectBuyerAddressDialog;
