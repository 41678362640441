import React, { useState, useEffect, useCallback } from 'react';
import RadiologySchedule from '../../../../../../forms/radiologySchedule';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import moment from 'moment';
import {
  Container,
  CheckmarkDone,
  QueuePlayNext,
  ViewShow,
  SelectContainer,
  ClockDismiss,
} from './styles';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import DateRangePickersComponent from '../../../../../../components/dateRangePickersComponent';

function RadiologyScheduleList({ history }) {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ scheduling: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
  const [openMessage, setOpenMessage] = useState(false);

  const columns = RadiologySchedule.tableSchema;

  const loadRadiologySchedules = useCallback(() => {
    restApiClient.radiologySchedule
      .getAllByClinicDate(
        user?.unitId,
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('scheduling', searchBy)
              .or()
              .like('patient.name', searchBy)
              .or()
              .like('patient.record_number', searchBy)
              .or()
              .like('treatment.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    loadRadiologySchedules();
  }, [loadRadiologySchedules]);

  const handleAlertClose = (event, reason) => {
    setOpenMessage(false);
  };

  return (
    <Container>
      <CrudTable
        title={'Agendamentos da Clínica'}
        customHeaderComponent={
          <SelectContainer>
            <DateRangePickersComponent
              dateTo={selectedDateTo}
              dateFrom={selectedDateFrom}
              handleChange={(to, from) => {
                setSelectedDateTo(to);
                setSelectedDateFrom(from);
              }}
            />
          </SelectContainer>
        }
        modelActions={RadiologySchedule.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => history.push('/clinic/radiology/schedule/create'),
          },
          update: {
            onClick: (radiologyScheduleId) =>
              history.push(
                `/clinic/radiology/schedule/update/${radiologyScheduleId}/true`
              ),
            selector: (row) => row?.status === 'Agendado',
          },
          delete: {
            onClick: (radiologyScheduleId, index) => {
              restApiClient.radiologySchedule
                .delete(radiologyScheduleId)
                .then(() => {
                  const result = { ...rows };
                  if (index > -1) {
                    result.values.splice(index, 1);
                    result.count--;
                  }
                  setRows(result);
                });
            },
            selector: (row) => row?.status === 'Agendado',
          },
          setOnAttendance: {
            icon: <QueuePlayNext />,
            onClick: (radiologyScheduleId, row) => {
              restApiClient.radiologySchedule
                .setOnAttendance(radiologyScheduleId)
                .then(() => {
                  setOpenMessage(true);
                  loadRadiologySchedules();
                });
            },
            title: 'Iniciar Atendimento',
            allowedActions: [RadiologySchedule.actions.setOnAttendance],
            selector: (row) => row?.status === 'Agendado',
          },
          setFinished: {
            icon: <CheckmarkDone />,
            onClick: (radiologyScheduleId, row) => {
              restApiClient.radiologySchedule
                .setFinished(radiologyScheduleId)
                .then(() => {
                  setOpenMessage(true);
                  loadRadiologySchedules();
                });
            },
            title: 'Finalizar Atendimento',
            allowedActions: [RadiologySchedule.actions.setFinished],
            selector: (row) => row?.status === 'Em Atendimento',
          },
          missedAttendance: {
            icon: <ClockDismiss />,
            onClick: (radiologyScheduleId) => {
              restApiClient.radiologySchedule
                .setMissed(radiologyScheduleId)
                .then(() => {
                  setOpenMessage(true);
                  loadRadiologySchedules();
                });
            },
            title: 'Falta',
            allowedActions: [RadiologySchedule.actions.setMissed],
            selector: (row) =>
              row?.status === 'Agendado' &&
              new Date(row.scheduling).getTime() < new Date().getTime(),
          },
          view: {
            icon: <ViewShow />,
            onClick: (radiologyScheduleId) => {
              history.push({
                pathname: `/clinic/radiology/schedule/update/${radiologyScheduleId}/false`,
                state: {
                  viewForm: true,
                },
              });
            },
            title: 'Visualizar agendamento',
            allowedActions: [RadiologySchedule.actions.update],
            selector: (row) => row?.status !== 'Agendado',
          },
        }}
        customCellRenderers={{
          'patient.recordNumber': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
          scheduling: {
            renderer: (field, value) => {
              const date = new Date(value);
              let options = {
                minute: 'numeric' || '2-digit',
                hour: 'numeric' || '2-digit',
                day: '2-digit' || 'numeric',
                month: '2-digit' || 'short',
                year: 'numeric' || '2-digit',
                hourCycle: 'h23',
              };
              return value
                ? `${date.toLocaleDateString('pt-br', options)}`
                : '-';
            },
          },
        }}
      />

      <Snackbar
        open={openMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {'Agendamento atualizado com Sucesso'}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default RadiologyScheduleList;
