import React from 'react';
import AdverseExpense from '../../../../forms/adverseExpense';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { Container, BackButton, FooterContainer } from './styles';

function AdverseExpenseCreateUpdate(props) {
  const readonly = props.location?.state?.readonly ?? false;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={AdverseExpense}
        restGetDataById={restApiClient.adverseExpense.findById}
        restCreateData={restApiClient.adverseExpense.create}
        restUpdateData={async (modelId, oldExpense, expense) => {
          delete expense.readonly;
          delete oldExpense.readonly;
          delete expense.requestingUnitBoolean;
          delete oldExpense.requestingUnitBoolean;

          const result = restApiClient.adverseExpense.update(
            modelId,
            oldExpense,
            expense
          );
          return result;
        }}
        onCreateSuccess={() => {
          props.history.goBack();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        modelData={{ readonly }}
        customButtonsBuilder={
          readonly
            ? () => (
                <>
                  <FooterContainer>
                    <BackButton
                      onClick={() => props.history.goBack()}
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      {'Voltar'}
                    </BackButton>
                  </FooterContainer>
                </>
              )
            : null
        }
        {...props}
      />
    </Container>
  );
}

export default AdverseExpenseCreateUpdate;
