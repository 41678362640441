import styled from 'styled-components';
import MaterialFormControl from '@material-ui/core/FormControl';
import MaterialInputLabel from '@material-ui/core/InputLabel';
import MaterialNativeSelect from '@material-ui/core/NativeSelect';

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
  margin-bottom: 10px !important;
`;

export const NativeSelect = styled(MaterialNativeSelect)``;


export const InputLabel = styled(MaterialInputLabel)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
