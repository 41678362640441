/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, DialogContent, StyledCreateUpdateForm } from './styles';
import restApiClient from '../../services/restApiClient';
import { isEmpty } from 'lodash';

function DentistFixedValueDialog({
  onSuccess,
  onError,
  onCancel,
  dentistFixedValue,
  dentistFixedId,
  ...props
}) {
  const [forceFixedId, setForceFixedId] = useState(undefined);
  useEffect(() => {
    if (dentistFixedId) {
      restApiClient.dentistFixedValue.getAllByDentist(dentistFixedId).then((e) => {
        if (!isEmpty(e.data)) {
          setForceFixedId(e.data[0].id);
        }
      });
    }
  }, [dentistFixedId]);
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={dentistFixedValue}
            restGetDataById={restApiClient.dentistFixedValue.findById}
            restCreateData={restApiClient.dentistFixedValue.create}
            restUpdateData={restApiClient.dentistFixedValue.update}
            onCreateSuccess={(request) => {
              setForceFixedId(undefined);
              onSuccess?.(request);
            }}
            onUpdateSuccess={(request) => {
              setForceFixedId(undefined);
              onSuccess?.(request);
            }}
            forceId={forceFixedId}
            modelData={{
              dentistId: dentistFixedId,
            }}
            onCancelClick={(request) => {
              setForceFixedId(undefined);
              onCancel?.(request);
            }}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default DentistFixedValueDialog;
