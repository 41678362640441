import React, { useState, useEffect } from 'react';
import OrderItem from '../../../../forms/orderItem';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { useSelector } from 'react-redux';
import { Container, CancelButton } from './styles';

function ViewCompletedTransferOrder({ match, history }) {
  const unitId = useSelector((x) => x.authReducer.user.unitId);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const orderId = match.params?.id;
  const columns = OrderItem.tableSchemaSeparateOrder;

  useEffect(() => {
    restApiClient.orderItem
      .getAllByOrderWithItemStock(
        orderId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('item.id', searchBy)
              .or()
              .like('item.name', searchBy)
              .or()
              .like('item.brand', searchBy)
              .or()
              .like('item.itemLocators.locator', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={`Visualizar todos os Itens do Pedido #${orderId}`}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
        modelActions={OrderItem.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          'item.itemLocators': {
            renderer: (field, value) => {
              return value.find((x) => x.unitId === unitId)?.locator ?? '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default ViewCompletedTransferOrder;
