import { and, isNumberControl, rankWith, schemaMatches } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import PercentageInputControl from '../../components/percentageInput';
import isEmpty from 'lodash/isEmpty';

const isPercentageInputControl = and(
  isNumberControl,
  schemaMatches((schema) => !isEmpty(schema) && schema.format === 'percentage')
);

const materialTextControlTester = rankWith(5, isPercentageInputControl);

export default {
  tester: materialTextControlTester,
  renderer: withJsonFormsControlProps(PercentageInputControl),
};
