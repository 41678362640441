import React from 'react';
import EndProduct from '../../../../forms/endProduct';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { Container } from './styles';
import { isEqualWith } from 'lodash';
import { BackButton, FooterContainer } from '../../../radiology/schedule/createUpdate/styles';

function EndProductCreateUpdate(props) {
  const disableFields = props.location?.state?.disableFields ?? false;
  
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={EndProduct}
        restGetDataById={restApiClient.endProduct.findById}
        restCreateData={async (endProduct) => {
          if(endProduct.workflows){
            Object.keys(endProduct.workflows).forEach(function(index) {
              if(endProduct.workflows[index].stepId){
                endProduct.workflows[index].position = index;
              }
            });
          }
          const result = await restApiClient.endProduct.create(
            endProduct
          );
          return result;
        }}
        restUpdateData={ async (modelId, updateOriginalData, modelData) => {
          delete updateOriginalData.disableFields;
          delete modelData.disableFields;
          
          Object.keys(modelData.workflows).forEach(function(index) {
            modelData.workflows[index].position = index;
          });
          const added = modelData.workflows.filter(
            item => !updateOriginalData.workflows.some(obj =>
              isEqualWith(obj, item, (obj1, obj2) =>
                obj1.stepId === obj2.stepId &&
                obj1.position === obj2.position
              )
            )
          );
          const removed = updateOriginalData.workflows.filter(
            item => !modelData.workflows.some(obj =>
              isEqualWith(obj, item, (obj1, obj2) =>
                obj1.stepId === obj2.stepId &&
                obj1.position === obj2.position
              )
            )
          ).map(item => item.id);
          delete modelData.workflows;
          delete updateOriginalData.workflows;
          const result = await restApiClient.endProduct.update(
            modelId, updateOriginalData, modelData, added, removed);
          return result;
        }}
        modelData={{
          disableFields: disableFields,
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        customButtonsBuilder={
          disableFields ?
            () => (
              <>
                <FooterContainer>
                  <BackButton
                    onClick={() => props.history.goBack()}
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Voltar
                  </BackButton>
                </FooterContainer>
              </>
            ) : null
        }
        {...props}
      />
    </Container>
  );
}

export default EndProductCreateUpdate;
