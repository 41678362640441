import styled from 'styled-components';

import {
  ExclamationTriangleFill as MaterialExclamationTriangleFill,
  Preview as MaterialPreview,
  Assignment as MaterialAssignment
} from '../../../../styles/icons';
import { Printer as MaterialPrinter } from '@styled-icons/boxicons-solid/Printer';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ExclamationTriangleFill = styled(MaterialExclamationTriangleFill)`
  width: 25px;
  height: 25px;
  fill: red;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Assignment = styled(MaterialAssignment)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
export const PrintOS = styled.div`
  @media screen {
    display: none
  }
`;
