import styled from 'styled-components';
import {
  PriceChange as MaterialPriceChange,
  Restore as MaterialRestore,
  Close as MaterialClose,
  ClockHistory as MaterialClockHistory,
  Receipt as MaterialReceipt,
  MoneyCalculator as MaterialMoneyCalculator,
  ExclamationTriangleFill as MaterialExclamationTriangleFill,
} from '../../../../../../styles/icons';

import { IconButton as MaterialIconButton } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const IconButton = styled(MaterialIconButton)`
  margin-right: 5px !important;
`;

export const PriceChange = styled(MaterialPriceChange)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ClockHistory = styled(MaterialClockHistory)`
  width: 20px;
  height: 20px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Receipt = styled(MaterialReceipt)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const MoneyCalculator = styled(MaterialMoneyCalculator)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const ExclamationTriangleFill = styled(MaterialExclamationTriangleFill)`
  width: 25px;
  height: 25px;
  fill: red;
`;
