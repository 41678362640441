import styled from 'styled-components';
import {
  Box as MaterialBox,
  AddBox as MaterialAddBox,
  AssignmentTurnedIn as MaterialAssignmentTurnedIn,
  Preview as MaterialPreview,
  CardChecklist as MaterialCardChecklist,
  Send as MaterialSend,
  ClipboardList as MaterialClipboardList,
  Documents as MaterialDocuments,
  PriceChange as MaterialPriceChange,
  Import as MaterialImport,
} from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AssignmentTurnedIn = styled(MaterialAssignmentTurnedIn)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CardChecklist = styled(MaterialCardChecklist)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Import = styled(MaterialImport)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ClipboardList = styled(MaterialClipboardList)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const Documents = styled(MaterialDocuments)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PriceChange = styled(MaterialPriceChange)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
