import React from 'react';
import restApiClient from '../../services/restApiClient';
import { 
  Container, 
  DialogContent, 
  StyledCreateUpdateForm,
  ContentContainer,
} from './styles';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';

function SaleCreateUpdate({
  onSuccess,
  onError,
  onCancel,
  sale,
  selectedSale,
  ...props
}) {
  const history = useHistory();
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <ContentContainer>
            <StyledCreateUpdateForm
              modelSchema={sale}
              restGetDataById={restApiClient.sale.findById}
              restCreateData={restApiClient.sale.create}
              restUpdateData={restApiClient.sale.update}
              onCreateSuccess={(model) => {
                history.push(`/sale/add-items/${model.data.id}`);
              }}
              onUpdateSuccess={() => {
                history.goBack();
              }}
              forceId={selectedSale}
              onCancelClick={onCancel}
              {...props}
            />
          </ContentContainer>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default SaleCreateUpdate;
