import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

const ReportReworkHistory = {
  buildPdf: (from, to, result) => {
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;

    doc.setFont('Arial');
    doc.setFontSize(10);
    doc.text(`Período: ${from} a ${to}`, 160, (y += 8));
    doc.text(
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds(),
      160,
      (y += 5)
    );
    doc.setFontSize(18);
    doc.text(
      `Histórico Bancário (Diário)`,
      doc.internal.pageSize.getWidth() / 2,
      (y += 10),
      { align: 'center' }
    );
    y += 3;
    //CORPO DO PDF
    result.forEach(function (unitFinance) {
      // PARA CADA CONTA FINANCEIRA
      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0);
      y += 10;
      doc.setFontSize(11);
      doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
      y += 1;
      if (y >= doc.internal.pageSize.height - 25) {
        doc.addPage();
        y = 5;
      }

      if (unitFinance?.type === 'Banco') {
        doc.text(
          `Titular: ${unitFinance?.bankAccount?.holderName}`,
          5,
          (y += 4)
        );
        y += 0.75;
        if (y >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 5;
        }
        doc.text(`Banco: ${unitFinance?.bankAccount?.bank}`, 5, (y += 4));
        y += 0.75;
        if (y >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 5;
        }
        doc.text(
          `Agência-Dígito: ${unitFinance?.bankAccount?.agency}${
            unitFinance?.bankAccount?.agencyDigit
              ? `-${unitFinance?.bankAccount?.agencyDigit}`
              : ''
          }`,
          5,
          (y += 4)
        );
        y += 0.75;
        if (y >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 5;
        }
        doc.text(
          `Conta-Dígito: ${unitFinance?.bankAccount?.account}${
            unitFinance?.bankAccount?.accountDigit
              ? `-${unitFinance?.bankAccount?.accountDigit}`
              : ''
          }`,
          5,
          (y += 4)
        );
        y += 0.75;
        if (y >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 5;
        }
        doc.text(`Unidade: ${unitFinance?.unit?.name}`, 5, (y += 4));
        y += 0.75;
        if (y >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 5;
        }
      } else {
        doc.text(
          `Tipo: ${
            unitFinance?.type === 'Caixa'
              ? `${unitFinance?.type} Financeiro`
              : unitFinance?.type === 'Cheque'
              ? `${unitFinance?.type}`
              : '-'
          }`,
          5,
          (y += 4)
        );
        y += 0.75;
        if (y >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 5;
        }
        doc.text(`Unidade: ${unitFinance?.unit?.name}`, 5, (y += 4));
        y += 0.75;
        if (y >= doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 5;
        }
      }
      y += 2;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.setFontSize(10);
      let bodyValues = [];
      unitFinance.dailyUnitFinances.forEach(function (daily) {
        // PARA CADA DIARIA
        bodyValues.push([
          moment(daily?.date).format('DD/MM/YYYY'),
          parseFloat(daily?.balanceValue).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        ]);
      });
      doc.autoTable({
        startY: (y += 2),
        showHead: 'firstPage',
        styles: {
          halign: 'center',
          valign: 'middle',
          overflow: 'linebreak',
        },
        headStyles: {
          fillColor: [55, 112, 222],
          textColor: [255, 255, 255],
        },
        bodyStyles: {
          fontSize: 10,
          textColor: [0, 0, 0],
        },
        head: [['Dia', 'Saldo']],
        body: [
          ...bodyValues,
          [
            {
              content: 'Balanço',
              styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
                fontStyle: 'bold',
                textColor: [25, 25, 25],
                fillColor: [225, 225, 225],
              },
            },
            {
              content: parseFloat(unitFinance?.dailyBalance).toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
                fontStyle: 'bold',
                textColor: [25, 25, 25],
                fillColor: [225, 225, 225],
              },
            },
          ],
        ],
        columnStyles: {
          0: { cellWidth: 100 },
          1: { cellWidth: 'auto' },
        },
        theme: 'grid',
        margin: 5,
        rowPageBreak: 'avoid',
      });
      y = doc.lastAutoTable.finalY;
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + '/' + String(pageCount), 198, 295);
    }
    return doc;
  },
};

export default ReportReworkHistory;
