import range from 'lodash/range';
import React, { useState, useCallback } from 'react';
import { composePaths, createDefaultValue } from '@jsonforms/core';
import map from 'lodash/map';
import merge from 'lodash/merge';
import JsonFormsExpandPanelRenderer from '../jsonFormsExpandPanelRenderer';
import { Grid, Hidden, Table, TableCell, Typography } from '@material-ui/core';
import { ValidationProps } from '@jsonforms/material-renderers';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TableRow from '@material-ui/core/TableRow';
import Badge from '@material-ui/core/Badge';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const JsonFormsArrayLayout = (props) => {
  const ValidationIcon: React.FC<ValidationProps> = ({ errorMessages, id }) => {
    return (
      <Tooltip id={id} title={errorMessages}>
        <Badge
          overlap="rectangular"
          style={{ color: '#f44336' }}
          badgeContent={errorMessages.split('\n').length}
        >
          <ErrorOutlineIcon color="error" />
        </Badge>
      </Tooltip>
    );
  };

  const [expanded, setExpanded] = useState(false);
  const handleChange = useCallback(
    (panel) => (_event, expandedPanel) => {
      setExpanded(expandedPanel ? panel : false);
    },
    []
  );
  const isExpanded = (index) =>
    expanded === composePaths(props.path, `${index}`);

  const {
    data,
    path,
    schema,
    uischema,
    errors,
    addItem,
    renderers,
    cells,
    label,
    required,
    visible,
    enabled,
    rootSchema,
    config,
    uischemas,
  } = props;
  const appliedUiSchemaOptions = merge({}, config, props.uischema.options);

  const fixedCellSmall = {
    padding: 0,
    border: 0,
  };

  const fixedCellDisable = {
    paddingLeft: 0,
    border: 1,
  };

  return (
    <Hidden xsUp={!visible}>
      <Table>
        <TableRow>
          {label || errors.length > 0 ? (
            <TableCell style={enabled ? fixedCellSmall : fixedCellDisable}>
              <Grid container justify={'flex-start'} alignItems={'center'}>
                <Grid item>
                  <Typography variant={'h6'}>{label}</Typography>
                </Grid>
                <Grid item style={{ paddingLeft: 8 }}>
                  <Hidden smUp={errors.length === 0 || !enabled}>
                    <Grid item>
                      <ValidationIcon
                        id="tooltip-validation"
                        errorMessages={errors}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </TableCell>
          ) : null}
          {enabled ? (
            <TableCell align="right" style={fixedCellSmall}>
              <Tooltip
                id="tooltip-add"
                title={`Adicionar ${label}`}
                placement="bottom"
              >
                <IconButton
                  aria-label={`Adicionar ${label}`}
                  onClick={addItem(path, createDefaultValue(schema))}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          ) : null}
        </TableRow>
      </Table>
      <div>
        {data > 0 ? (
          map(range(data), (index) => {
            return (
              <JsonFormsExpandPanelRenderer
                enabled={enabled}
                index={index}
                expanded={isExpanded(index)}
                schema={schema}
                path={path}
                handleExpansion={handleChange}
                uischema={uischema}
                renderers={renderers}
                cells={cells}
                key={index}
                required={required}
                rootSchema={rootSchema}
                enableMoveUp={index !== 0}
                enableMoveDown={index < data - 1}
                config={config}
                childLabelProp={appliedUiSchemaOptions.elementLabelProp}
                uischemas={uischemas}
              />
            );
          })
        ) : (
          <p>Sem Dados</p>
        )}
      </div>
    </Hidden>
  );
};

export default JsonFormsArrayLayout;
