import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportReworkHistory = {
  buildReworkHistoryPdf: (from, to, result) => {
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;

    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(10);
    doc.text(`Período: ${from} a ${to}`, 160, (y += 8));
    doc.text(
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds(),
      160,
      (y += 5)
    );
    doc.setFontSize(16);
    doc.text(
      `Histórico de Retrabalhos`,
      doc.internal.pageSize.getWidth() / 2,
      (y += 10),
      { align: 'center' }
    );

    //CORPO DO PDF
    result.forEach(function (orderService) {
      // PARA CADA ORDER SERVICE
      y += 5;
      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0);
      doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
      y += 1;
      doc.setFontSize(11);
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(`Ordem de Serviço: ${orderService?.id}`, 5, (y += 4));
      y += 0.5;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(`Produto Final: ${orderService?.endProduct.name}`, 5, (y += 4));
      y += 0.5;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.text(
        `Paciente: ${orderService?.patient.recordNumber} (${orderService?.patient.name})`,
        5,
        (y += 4)
      );
      y += 2;
      if (y >= doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 5;
      }
      doc.setFontSize(10);
      let bodyValue = [];
      orderService.orderServiceSteps.forEach(function (orderServiceStep) {
        // PARA CADA ORDER SERVICE STEP
        let date = new Date(orderServiceStep.updatedAt);
        let takeCost;
        let executor = '';

        switch (orderServiceStep?.takeCost) {
          case 'laboratory':
            takeCost = 'Laboratório';
            break;
          case 'dentist':
            takeCost = 'Dentista';
            break;
          default:
            takeCost = '';
            break;
        }

        if (orderServiceStep?.executorId) {
          executor = orderServiceStep?.labExecutor.user.name;
        } else if (orderServiceStep?.outsourcedId) {
          executor = orderServiceStep?.outsourcedExecutor.isPhysicalPerson
            ? orderServiceStep?.outsourcedExecutor.socialName
            : orderServiceStep?.outsourcedExecutor.fantasyName;
        }

        bodyValue.push([
          orderServiceStep?.id ?? '-',
          orderServiceStep?.dentist?.user.name ?? '-',
          orderServiceStep?.unit?.name ?? '-',
          orderServiceStep?.step?.name ?? '-',
          orderServiceStep?.toothNumber
            ? orderServiceStep?.toothNumber.split(',').join(', ')
            : '-',
          orderServiceStep?.status ?? '-',
          date.toLocaleDateString(),
          orderServiceStep?.dentistRejectedJustification ?? '-',
          takeCost !== '' ? takeCost : '-',
          executor !== '' ? executor : '-',
          orderServiceStep?.finishedLabService
            ? `${parseFloat(
                (orderServiceStep?.finishedLabService?.price ?? 0) +
                  (orderServiceStep?.extraCost?.value ?? 0)
              ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}` + ''
            : '-',
        ]);
      });
      doc.autoTable({
        startY: (y += 2),
        headStyles: { fillColor: [0, 0, 0] },
        head: [
          [
            'Id',
            'Dentista',
            'Unidade',
            'Etapa',
            'Dentes',
            'Situação',
            'Data',
            'Justificativa Dentista',
            'Centro de custo',
            'Executor',
            'Custo',
          ],
        ],
        body: bodyValue,
        theme: 'grid',
        styles: {
          halign: 'center',
          valign: 'middle',
          fontSize: 8,
        },
        columnStyles: {
          4: { cellWidth: 18 },
          10: { cellWidth: 'wrap' },
        },
        margin: 5,
        rowPageBreak: 'avoid',
      });

      y = doc.lastAutoTable.finalY;
    });

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + '/' + String(pageCount), 198, 295);
    }
    return doc;
  },
};

export default ReportReworkHistory;
