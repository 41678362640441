/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, DialogContent, StyledCreateUpdateForm, StyledText } from './styles';
import restApiClient from '../../services/restApiClient';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function CalendarDialog({
  onSuccess,
  onError,
  onCancel,
  calendar,
  ...props
}) {
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={calendar}
            restGetDataById={restApiClient.calendar.findById}
            restCreateData={restApiClient.calendar.create}
            onCreateSuccess={(request) => {
              onSuccess?.(request);
            }}
            onError={(x) => {
              setAlert({ message: 'Este calendário já existe!', severity: 'error' });
            }}
            onCancelClick={onCancel}
            {...props}
          />
          <Snackbar
            open={!!alert?.message}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert
              onClose={handleAlertClose}
              severity={alert?.severity ? `${alert?.severity}` : 'success'}
            >
              {alert?.message}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default CalendarDialog;
