import styled from 'styled-components';
import { Preview as MaterialPreview } from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
