import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Creators } from '../../store/ducks/auth';

import {
  Container,
  LoginCard,
  Input,
  SubmitButton,
  DescriptionSpan,
  Field,
  Form,
} from './styles';
import restApiClient from '../../services/restApiClient';
import { isNullOrEmptyObject } from '../../extensions/object';

const PasswordChange = () => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [requestErrors, setRequestErrors] = useState({});

  return (
    <Container>
      <LoginCard>
        <h1>Troca de senha necessária!</h1>
        <DescriptionSpan>
          Para sua segurança insira uma nova senha.
        </DescriptionSpan>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            restApiClient.user
              .changePassword(password, passwordConfirmation)
              .then((e) => {
                dispatch(Creators.logout());
              })
              .catch((e) => {
                if (e.response?.status === 400) {
                  setRequestErrors(e.response.data.errors);
                }
              });
          }}
        >
          <Field>
            <Input
              error={!isNullOrEmptyObject(requestErrors)}
              label={'Senha'}
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              type="password"
              required
              helperText={
                !isNullOrEmptyObject(requestErrors)
                  ? requestErrors['password'][0]
                  : ''
              }
            />
          </Field>
          <Field>
            <Input
              label={'Confirmação da senha'}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              variant="filled"
              type="password"
              required
            />
          </Field>
          <SubmitButton type="submit" variant="contained" color="primary">
            Alterar Senha
          </SubmitButton>
        </Form>
      </LoginCard>
    </Container>
  );
};

export default PasswordChange;
