import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  DialogContent as MaterialDialogContent,
} from '@material-ui/core';

import { CalendarPlus as MaterialCalendarPlus } from '../../styles/icons';

export const Container = styled.div``;

export const SchedulerContainer = styled.div``;

export const Dialog = styled(MaterialDialog)``;

export const CalendarPlus = styled(MaterialCalendarPlus)`
  width: 25px;
  height: 25px;
`;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 16px 16px 16px 16px !important;
`;
