import styled from 'styled-components';
import {
  Box as MaterialBox,
  CheckmarkDone as MaterialCheckmarkDone,
  QueuePlayNext as MaterialQueuePlayNext,
  ViewShow as MaterialViewShow,
  ClockDismiss as MaterialClockDismiss,
  CalendarSync as MaterialCalendarSync,
  Printer as MaterialPrinter,
  UserCheck as MaterialUserCheck,
} from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SelectContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CheckmarkDone = styled(MaterialCheckmarkDone)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

export const QueuePlayNext = styled(MaterialQueuePlayNext)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ClockDismiss = styled(MaterialClockDismiss)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const UserCheck = styled(MaterialUserCheck)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CalendarSync = styled(MaterialCalendarSync)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PrintOS = styled.div`
  @media screen {
    display: none;
  }
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
