import styled from 'styled-components';
import {
  AddBox as MaterialAddBox,
  ViewShow as MaterialViewShow,
  GetApp as MaterialGetApp,
  AddShoppingCart as MaterialAddShoppingCart,
} from '../../../../../styles/icons';
import { Button as MaterialButon } from '@material-ui/core';

export const Button = styled(MaterialButon)``;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const GetApp = styled(MaterialGetApp)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddShoppingCart = styled(MaterialAddShoppingCart)`
  width: 20px;
  height: 20px;
`;
