import React, { useState, useEffect, useCallback } from 'react';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, Button, SaveIcon, StyledPercentageInput } from './styles';
import Item from '../../../forms/item';
import { isEmpty } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function ProfitMarginIndividualList() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [profitMarginIndividualList, setProfitMarginIndividualList] = useState(
    []
  );
  const [alert, setAlert] = useState('');
  const columns = Item.tableSchemaUpdatePrices;

  const handleAlertClose = () => {
    setAlert('');
  };

  const loadItems = useCallback(() => {
    restApiClient.item
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('group.name', searchBy)
              .or()
              .like('group.parent.name', searchBy)
              .or()
              .like('group.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        newRows.values = e.data.values.map((row) => {
          if (!isEmpty(row?.profitMarginIndividual)) {
            if (row.profitMarginIndividual.itemId === row.id) {
              row.profitMargin = row.profitMarginIndividual.profitMargin;
            }
          } else if (!isEmpty(row?.group?.profitMarginCategory)) {
            if (row.group.profitMarginCategory.groupId === row.group.id) {
              row.profitMargin = row.group.profitMarginCategory.profitMargin;
            }
          }
          return row;
        });
        setRows(newRows);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const salvar = async () => {
    const profitList = profitMarginIndividualList.filter(
      (profitMarginIndividual) =>
        profitMarginIndividual.profitMargin > 0 ||
        isNaN(profitMarginIndividual.profitMargin)
    );

    const isValid = profitList.reduce(
      (acc, value) =>
        acc && (value.profitMargin > 0 || isNaN(value.profitMargin)),
      true
    );
    if (isValid && !isEmpty(profitList)) {
      const result = await restApiClient.profitMarginIndividual.update(
        profitList
      );

      if (result.status === 200) {
        setAlert('Alterações salvas com sucesso!');
        setProfitMarginIndividualList([]);
        loadItems();
      }
    }
  };

  return (
    <Container>
      <CrudTable
        title={`Margem de Lucro Individual`}
        customFooterComponent={
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={salvar}
            >
              Salvar
            </Button>
          </>
        }
        modelActions={Item.actions}
        columns={columns}
        customColumns={[
          {
            id: 'profitMargin',
            label: 'Margem de Lucro(%)',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'salePrice',
            label: 'Preço Venda',
            minWidth: 50,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          price: {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          profitMargin: {
            renderer: (field, value, column, row) => {
              row.profitMargin = parseFloat(row.profitMargin);
              let profitMarginIndividualFind = profitMarginIndividualList.find(
                (x) => x.itemId === row.id
              );
              let profitMargin;
              if (profitMarginIndividualFind)
                profitMargin = profitMarginIndividualFind?.profitMargin;
              else profitMargin = value;
              const error = isEmpty(profitMargin) && profitMargin <= 0;
              return (
                <StyledPercentageInput
                  value={profitMargin}
                  error={error}
                  helperText={error ? 'valor deve ser maior 0.' : ''}
                  onChange={(e) => {
                    let profitMarginIndividual;
                    const newList = [...profitMarginIndividualList];
                    if (
                      (profitMarginIndividual = newList.find(
                        (x) => x.itemId === row.id
                      ))
                    ) {
                      profitMarginIndividual.profitMargin = parseFloat(
                        e.target.value
                      );
                    } else {
                      const profitMarginIndividual = {
                        itemId: row.id,
                        profitMargin: parseFloat(e.target.value),
                      };
                      newList.push(profitMarginIndividual);
                    }
                    row.profitMargin = parseFloat(e.target.value);
                    setProfitMarginIndividualList(newList);
                  }}
                />
              );
            },
          },
          group: {
            renderer: (field, value) => {
              if (value?.parent) {
                if (value.parent?.parent) {
                  return ` ${value.parent.parent.name} > ${value.parent.name} > ${value.name}`;
                } else {
                  return `${value.parent.name} > ${value.name}`;
                }
              } else {
                return value.name;
              }
            },
          },
          salePrice: {
            renderer: (field, value, column, row) => {
              value = row.price + row.price * (row.profitMargin / 100);
              if (isNaN(value)) value = 0;
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
      <Snackbar
        open={!!alert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {alert}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ProfitMarginIndividualList;
