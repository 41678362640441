/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  Dialog,
  Container,
  DialogTitle,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  Typography,
  DialogContentText,
  FormGroup,
  Grid,
  FormControlLabel,
  Checkbox,
  InputTextarea,
  StyledMoneyInput,
} from './styles';
import restApiClient from '../../services/restApiClient';
import { isEmpty } from 'lodash';

function ConcludeOrderServiceStepDialog({
  orderServiceStepId,
  onSuccess,
  onCancel,
  ...props
}) {
  const [checked, setChecked] = React.useState(false);
  const [description, setDescription] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checked) {
      restApiClient.extraCost
        .create({
          orderServiceStepId: orderServiceStepId,
          description: description,
          value: value,
        })
        .then(() => {
          setErrors({});
          restApiClient.orderServiceStep
            .setCompletedLaboratory(orderServiceStepId)
            .then(() => {
              onSuccess?.();
              setChecked(false);
            });
        })
        .catch((result) => {
          setErrors(result.response.data.errors);
        });
    } else {
      restApiClient.orderServiceStep
        .setCompletedLaboratory(orderServiceStepId)
        .then(() => {
          onSuccess?.();
        });
    }
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h6">Atenção</Typography>
        </DialogTitle>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
              Você tem certeza que deseja finalizar esta etapa da ordem de
              serviço?
            </DialogContentText>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setErrors({});
                      setDescription(null);
                      setValue(null);
                      setChecked(e.target.checked);
                    }}
                    name="checked"
                  />
                }
                label="Custo extra"
              />
            </FormGroup>
            {checked ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputTextarea
                      id="outlined-multiline-static"
                      size="small"
                      label="Descrição"
                      value={description ?? ''}
                      onChange={(e) => {
                        setErrors({});
                        setDescription(e.target.value);
                      }}
                      multiline
                      rows={3}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                      required
                      error={!isEmpty(errors?.description)}
                      helperText={
                        !isEmpty(errors?.description)
                          ? errors?.description.join(' ')
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledMoneyInput
                      value={value}
                      onChange={(e, value) => {
                        setErrors({});
                        setValue(value);
                      }}
                      variant="outlined"
                      size="small"
                      label="Valor"
                      error={!isEmpty(errors?.value)}
                      helperText={
                        !isEmpty(errors?.value) ? errors?.value.join(' ') : ''
                      }
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  onCancel?.();
                  setChecked(false);
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SubmitButton type="submit" variant="contained" color="primary">
                Concluir
              </SubmitButton>
            </ActionsContainer>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default ConcludeOrderServiceStepDialog;
