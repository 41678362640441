import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProgressContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 80%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  span {
    text-align: center;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SubmitButton = styled(Button)`
  width: 30%;
  color: #ffffff !important;
`;

export const CancelButton = styled(Button)`
  width: 30%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
