import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import EmployeeList from '../pages/employee/list';
import EmployeeCreateUpdate from '../pages/employee/createUpdate';
import UnitList from '../pages/unit/list';
import UnitCreateUpdate from '../pages/unit/createUpdate';
import UnitChairList from '../pages/unit/chair/list';
import ScheduleUnit from '../pages/unit/schedule';
import ChairSchedule from '../pages/unit/chair/schedule';
import LinkedDentistsChairList from '../pages/unit/chair/linkedDentists/list';
import OfficeHoursList from '../pages/unit/chair/linkedDentists/officeHoursList';
import ValueProceduresList from '../pages/unit/chair/linkedDentists/valueProcedures';
import DentistList from '../pages/dentist/manageDentist/list';
import DentistCreateUpdate from '../pages/dentist/manageDentist/createUpdate';
import BuyerList from '../pages/buyer/list';
import BuyerCreateUpdate from '../pages/buyer/createUpdate';
import RoleList from '../pages/role/list';
import RoleCreateUpdate from '../pages/role/createUpdate';
import GroupTreeView from '../pages/group/list';
import SupplierList from '../pages/supplier/list';
import SupplierCreateUpdate from '../pages/supplier/createUpdate';
import ItemList from '../pages/item/list';
import ItemCreateUpdate from '../pages/item/createUpdate';
import RoleActionLink from '../pages/role/actionLink';
import EmployeeRoleLink from '../pages/employee/roleLink';
import StockConfig from '../pages/stockConfig';
import PurchaseItemList from '../pages/purchase/itemList';
import PurchaseManualEntry from '../pages/purchase/manualEntry';
import ItemSearch from '../pages/item/search';
import PricePending from '../pages/pendencies/price/list';
import PricePendingItemList from '../pages/pendencies/price/itemList';
import AdjustStock from '../pages/item/adjustStock/list';
import Purchase from '../pages/purchase/list';
import UnregisteredItemPurchaseList from '../pages/pendencies/unregisteredItem/list';
import UnregisteredItemPendingList from '../pages/pendencies/unregisteredItem/itemList';
import CreateItem from '../pages/pendencies/unregisteredItem/createItem';
import BudgetList from '../pages/budget/list';
import BudgetItemList from '../pages/budget/itemList';
import ViewBudgetItems from '../pages/budget/viewBudgetItems';
import UpdateItemPricesList from '../pages/item/updateItemPricesList';
import BankAccountList from '../pages/financial/config/bankAccount/list';
import BankAccountCreateUpdate from '../pages/financial/config/bankAccount/createUpdate';
import BarcodeExport from '../pages/item/barcodeExport';
import StockReportList from '../pages/report/stock/list';
import PurchasingGroupReportList from '../pages/report/purchasingGroup/list';
import LabReportList from '../pages/report/lab/list';
import RadiologyReportList from '../pages/report/radiology/list';
import ProfitMarginList from '../pages/profitMargin/category';
import ProfitMarginIndividualList from '../pages/profitMargin/individual';
import SaleList from '../pages/sale/list';
import AddItemsOnSale from '../pages/sale/addItemsOnSale';
import ToothTypeList from '../pages/lab/toothType/list';
import ToothTypeCreateUpdate from '../pages/lab/toothType/createUpdate';
import EndProductList from '../pages/lab/endProduct/list';
import EndProductCreateUpdate from '../pages/lab/endProduct/createUpdate';
import StepList from '../pages/lab/step/list';
import StepCreateUpdate from '../pages/lab/step/createUpdate';
import SectionTreeView from '../pages/lab/section/list';
import CalendarList from '../pages/calendar/list';
import HolidayList from '../pages/calendar/holidayList';
import LabConfig from '../pages/lab/labConfig';
import OrderServiceList from '../pages/dentist/lab/orderService/list';
import AllOrderServiceList from '../pages/dentist/lab/orderService/listAllOrderService';
import LabAllOrderServiceList from '../pages/lab/orderService/listAllOrderService';
import ClinicOrderServicePendingList from '../pages/clinic/lab/orderService/pending/list';
import ClinicOrderServiceList from '../pages/clinic/lab/orderService/list';
import LabOrderServiceList from '../pages/lab/orderService/list';
import OrderServiceStepCreateUpdate from '../pages/dentist/lab/orderService/createUpdateStep';
import LabOrderServiceStepCreateUpdate from '../pages/lab/orderService/createUpdateStep';
import ServiceQueueList from '../pages/lab/ServiceQueue/list';
import OutsourcedList from '../pages/lab/outsourced/list';
import OutsourcedCreateUpdate from '../pages/lab/outsourced/createUpdate';
import OutsourcedPriceTableList from '../pages/lab/outsourced/priceTableList';
import DentistCreateUpdateStepCarousel from '../pages/dentist/lab/orderService/createUpdateStepCarousel';
import ClinicCreateUpdateStepCarousel from '../pages/clinic/lab/orderService/pending/createUpdateStepCarousel';
import LabCreateUpdateStepCarousel from '../pages/lab/orderService/createUpdateStepCarousel';
import PatientList from '../pages/patient/list';
import PatientCreateUpdate from '../pages/patient/createUpdate';
import LabAllOrderServiceRefusedList from '../pages/lab/orderService/listAllOrderServiceRefused';
import OriginView from '../pages/radiology/origin/list';
import TreatmentList from '../pages/radiology/treatment/list';
import TreatmentCreateUpdate from '../pages/radiology/treatment/createUpdate';
import RadiologyConfig from '../pages/radiology/config/unitRadiologyServices/list';
import LinkedServicesList from '../pages/radiology/config/unitRadiologyServices/linkedServices';
import HistoryList from '../pages/item/historyList';
import SearchItemList from '../pages/shoppingGroup/searchItem';
import RecessList from '../pages/radiology/recess';
import ClinicRadiologyScheduleList from '../pages/clinic/radiology/schedule/local/list';
import CentralRadiologyScheduleList from '../pages/clinic/radiology/schedule/central/list';
import ClinicRadiologyScheduleCreateUpdate from '../pages/clinic/radiology/schedule/createUpdate';
import OpeningHoursCreateUpdate from '../pages/radiology/config/openingHours';
import LabAllOrderServiceCompletedLaboratoryList from '../pages/lab/orderService/listAllOrderServiceCompletedLaboratory';
import RadiologyScheduleList from '../pages/radiology/schedule/list';
import RadiologyScheduleCreateUpdate from '../pages/radiology/schedule/createUpdate';
import ClinicStockReport from '../pages/clinic/report/stock/list';
import ClinicRadiologyReport from '../pages/clinic/report/radiology/list';
import SpecialtyView from '../pages/dentist/specialty/list';
import TransferTypeView from '../pages/transferType';
import ProcedureList from '../pages/dentist/procedure/list';
import ProcedureCreateUpdate from '../pages/dentist/procedure/createUpdate';
import ManageSpecialties from '../pages/dentist/manageDentist/manageSpecialties';
import SubstituteList from '../pages/dentist/substitute/list';
import SubstituteCreateUpdate from '../pages/dentist/substitute/createUpdate';
import ProceduresAttendancesList from '../pages/dentist/attendances/proceduresAttendances/list';
import AttendanceCreateUpdate from '../pages/dentist/attendances/proceduresAttendances/createUpdate';
import ProceduresAuditList from '../pages/dentist/audit/proceduresAudit/list';
import ProceduresAuditCreateUpdate from '../pages/dentist/audit/proceduresAudit/createUpdate';
import ProceduresAuditDisapprovedList from '../pages/dentist/audit/proceduresAuditDisapproved/list';
import ProceduresAuditDisapprovedCreateUpdate from '../pages/dentist/audit/proceduresAuditDisapproved/createUpdate';
import ScheduleAuditDisapprovedList from '../pages/dentist/audit/scheduleAuditDisapproved/list';
import DentistWorkedHourList from '../pages/dentist/attendances/dentistWorkedHour/list';
import AttendancesHoursList from '../pages/dentist/attendances/dentistWorkedHour/attendances/list';
import AttendanceHoursCreateUpdate from '../pages/dentist/attendances/dentistWorkedHour/attendances/createUpdate';
import DentistScheduleAuditList from '../pages/dentist/audit/scheduleAudit/list';
import ScheduleAttendancesAuditList from '../pages/dentist/audit/scheduleAudit/attendances/list';
import AllProceduresList from '../pages/dentist/audit/allProcedures/list';
import AllProceduresCreateUpdate from '../pages/dentist/audit/allProcedures/createUpdate';
import AllSchedulesProceduresList from '../pages/dentist/audit/allSchedules/list';
import AllProceduresInScheduleList from '../pages/dentist/audit/allSchedules/attendances/list';
import ServiceSupplierList from '../pages/financial/config/serviceSupplier/list';
import ServiceSupplierCreateUpdate from '../pages/financial/config/serviceSupplier/createUpdate';
import ReplacementsAuditList from '../pages/dentist/audit/replacementsAudit/list';
import ReplacementsAuditCreateUpdate from '../pages/dentist/audit/replacementsAudit/createUpdate';
import ReplacementsScheduleAuditList from '../pages/dentist/audit/replacementsScheduleAudit/list';
import ExpenseTypeTreeView from '../pages/financial/config/expenseType/list';
import PaymentTypeList from '../pages/financial/config/paymentType/list';
import ExpenseList from '../pages/financial/expense/list';
import ExpenseCreateUpdate from '../pages/financial/expense/createUpdate';
import SectorView from '../pages/employee/config/sector/list';
import RecurrentExpenseList from '../pages/financial/config/recurrentExpense/list';
import RecurrentExpenseCreateUpdate from '../pages/financial/config/recurrentExpense/createUpdate';
import DentistReportList from '../pages/report/dentist/list';
import EmployeeExpenseList from '../pages/financial/employeeExpense';
import FinancialCashList from '../pages/financial/config/financialCash/list';
import BankingOperationList from '../pages/financial/bankingOperation/list';
import ClinicLabReportList from '../pages/clinic/report/lab/list';
import DentistFavoriteUnitCreateUpdate from '../pages/dentist/favoriteUnit/createUpdate';
import AdverseExpenseList from '../pages/financial/adverseExpense/list';
import AdverseExpenseCreateUpdate from '../pages/financial/adverseExpense/createUpdate';
import CashRegisterCloseList from '../pages/financial/cashRegisterClose/list';
import CashRegisterCloseCreateUpdate from '../pages/financial/cashRegisterClose/createUpdate';
import ThrowRecurrentExpenseCreateUpdate from '../pages/financial/expense/throwExpense';
import FinancialReportList from '../pages/financial/report';
import CashRegisterCardList from '../pages/financial/cashRegisterCard/list';
import ItemizedCardList from '../pages/financial/cashRegisterCard/itemizedCard/list';
import CashRegisterCheckList from '../pages/financial/cashRegisterCheck/list';
import ItemizedCheckList from '../pages/financial/cashRegisterCheck/itemizedCheck/list';
import StockConsumptionList from '../pages/administrative/consumption/stock/list';
import ThrowStockConsumptionUnitList from '../pages/administrative/consumption/stock/throwStockConsumption';
import ManageStockConsumptionUnit from '../pages/administrative/consumption/stock/manageStockConsumptionUnit';
import LabConsumptionList from '../pages/administrative/consumption/lab/list';
import ThrowLabConsumptionUnitList from '../pages/administrative/consumption/lab/throwLabConsumption';
import ManageLabConsumptionUnit from '../pages/administrative/consumption/lab/manageLabConsumptionUnit';
import ThrowStockConsumptionUnitListClinic from '../pages/clinic/consumption/stock/throwStockConsumption';
import ManageStockConsumptionUnitClinic from '../pages/clinic/consumption/stock/manageStockConsumptionUnit';
import RadiologyConsumptionList from '../pages/administrative/consumption/radiology/list';
import ThrowRadiologyConsumptionUnitList from '../pages/administrative/consumption/radiology/throwRadiologyConsumption';
import ManageRadiologyConsumptionUnit from '../pages/administrative/consumption/radiology/manageRadiologyConsumptionUnit';
import ClinicCashRegisterCloseList from '../pages/clinic/cashRegister/list';
import ClinicCashRegisterCloseCreateUpdate from '../pages/clinic/cashRegister/createUpdate';
import GroupedExpenseList from '../pages/financial/groupedExpense/list';
import ExpenseGroupedList from '../pages/financial/groupedExpense/expensesGrouped/list';
import ThrowRadiologyConsumptionUnitListClinic from '../pages/clinic/consumption/radiology/throwRadiologyConsumption';
import ManageRadiologyConsumptionUnitClinic from '../pages/clinic/consumption/radiology/manageRadiologyConsumptionUnit';
import ThrowLabConsumptionUnitListClinic from '../pages/clinic/consumption/lab/throwLabConsumption';
import ManageLabConsumptionUnitClinic from '../pages/clinic/consumption/lab/manageLabConsumptionUnit';
import ClinicCashRegisterCardList from '../pages/clinic/cashRegister/cashRegisterCard/list';
import ClinicCashRegisterCheckList from '../pages/clinic/cashRegister/cashRegisterCheck/list';
import ClinicItemizedCardList from '../pages/clinic/cashRegister/cashRegisterCard/itemizedCard/list';
import ClinicItemizedCheckList from '../pages/clinic/cashRegister/cashRegisterCheck/itemizedCheck/list';
import SectorExpenseTypeList from '../pages/employee/config/sector/sectorExpenseType';
import ExpenseTypeExpenseTypeList from '../pages/administrative/dre/config/field/expenseTypeList';
import ClinicProceduresAuditList from '../pages/clinic/dentist/proceduresAudit/list';
import ClinicProceduresAuditCreateUpdate from '../pages/clinic/dentist/proceduresAudit/createUpdate';
import ClinicDentistScheduleAuditList from '../pages/clinic/dentist/scheduleAudit/list';
import ClinicScheduleAttendancesAuditList from '../pages/clinic/dentist/scheduleAudit/attendances/list';
import ClinicAllProceduresList from '../pages/clinic/dentist/allProcedures/list';
import ClinicAllProceduresCreateUpdate from '../pages/clinic/dentist/allProcedures/createUpdate';
import ClinicAllSchedulesProceduresList from '../pages/clinic/dentist/allSchedules/list';
import ClinicAllProceduresInScheduleList from '../pages/clinic/dentist/allSchedules/attendances/list';
import ClinicScheduleAuditDisapprovedList from '../pages/clinic/dentist/scheduleAuditDisapproved/list';
import ClinicProceduresAuditDisapprovedList from '../pages/clinic/dentist/proceduresAuditDisapproved/list';
import ClinicProceduresAuditDisapprovedCreateUpdate from '../pages/clinic/dentist/proceduresAuditDisapproved/createUpdate';
import ClinicReplacementsAuditList from '../pages/clinic/dentist/replacementsAudit/list';
import ClinicReplacementsScheduleAuditList from '../pages/clinic/dentist/replacementsScheduleAudit/list';
import ClinicReplacementsAuditCreateUpdate from '../pages/clinic/dentist/replacementsAudit/createUpdate';
import ClinicSubstituteList from '../pages/clinic/dentist/substitute/list';
import ClinicSubstituteCreateUpdate from '../pages/clinic/dentist/substitute/createUpdate';
import DreExpenseList from '../pages/administrative/dre/expenses/list';
import DreReportList from '../pages/dre/report';
import DrePaymentList from '../pages/administrative/dre/payments/list';
import DreStockConsumptionList from '../pages/administrative/dre/stockConsumption';
import DreLabConsumptionList from '../pages/administrative/dre/labConsumption';
import DreRadiologyConsumptionList from '../pages/administrative/dre/radiologyConsumption';
import UnitFinanceAdjustmentsList from '../pages/financial/config/bankAccount/viewAdjustments';
import ClinicExpenseTypeTreeView from '../pages/dre/clinic/expenseType/list';
import ClinicDreReportList from '../pages/dre/clinic/report';
import DisabledItemsList from '../pages/item/disabledItems';
import ItemLocatorList from '../pages/item/itemLocator/list';
import DentistConsumptionList from '../pages/administrative/consumption/dentist/list';
import ThrowDentistConsumptionUnitList from '../pages/administrative/consumption/dentist/throwDentistConsumption';
import ManageDentistConsumptionUnit from '../pages/administrative/consumption/dentist/manageDentistConsumptionUnit';
import ThrowDentistConsumptionUnitListClinic from '../pages/clinic/consumption/dentist/throwDentistConsumption';
import ManageDentistConsumptionUnitClinic from '../pages/clinic/consumption/dentist/manageDentistConsumptionUnit';
import ItemListClinic from '../pages/clinic/stock/search';
import ItemLocatorClinicList from '../pages/clinic/stock/itemLocator';
import AdjustStockClinicList from '../pages/clinic/stock/adjust';
import BankAccountConsolidationList from '../pages/financial/config/bankAccount/consolidation/list';
import BankAccountStatement from '../pages/financial/config/bankAccount/statement';
import ItemOrder from '../pages/clinic/stock/order/list';
import ItemsToOrderList from '../pages/clinic/stock/order/addItemsToOrder';
import ViewAllItemsByOrder from '../pages/clinic/stock/order/view';
import ClinicListItemWithdraw from '../pages/clinic/stock/withdrawItem';
import TransfersList from '../pages/stock/transfer/list';
import SeparateOrderItems from '../pages/stock/transfer/separateOrderItems';
import ViewCompletedTransferOrder from '../pages/stock/transfer/viewCompletedTransferOrder';
import ItemizedCardListAll from '../pages/financial/itemizedCard/list';
import ConsumptionReportList from '../pages/report/consumption/list';
import ValueHourList from '../pages/unit/chair/linkedDentists/valueHourList';
import PendingExpenseList from '../pages/financial/config/bankAccount/consolidation/expenses';
import PendingCashRegisterCloseList from '../pages/financial/config/bankAccount/consolidation/cashRegisterClose';
import ImportItemsList from '../pages/budget/importItems';

const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/profile" component={Profile} />

      <Route path="/employee" exact component={EmployeeList} />
      <Route path="/employee/create" component={EmployeeCreateUpdate} />
      <Route path="/employee/update/:id" component={EmployeeCreateUpdate} />
      <Route
        path="/employee/:id/role/link"
        exact
        component={EmployeeRoleLink}
      />

      <Route path="/unit" exact component={UnitList} />
      <Route path="/unit/create" component={UnitCreateUpdate} />
      <Route path="/unit/update/:id" exact component={UnitCreateUpdate} />
      <Route path="/unit/:id/chairs" exact component={UnitChairList} />
      <Route path="/unit/:id/schedule" exact component={ScheduleUnit} />
      <Route
        path="/unit/chair/:id/link-dentists"
        exact
        component={LinkedDentistsChairList}
      />
      <Route path="/unit/chair/:id/schedule" exact component={ChairSchedule} />
      <Route
        path="/unit/chair/:chairId/linked-dentist/:chairDentistId/schedule"
        exact
        component={OfficeHoursList}
      />
      <Route
        path="/unit/:unitId/chair/:chairId/linked-dentist/:chairDentistId/procedures"
        exact
        component={ValueProceduresList}
      />
      <Route
        path="/unit/:unitId/chair/:chairId/linked-dentist/:chairDentistId/specialties"
        exact
        component={ValueHourList}
      />

      <Route path="/dentist/list" exact component={DentistList} />
      <Route path="/dentist/create" component={DentistCreateUpdate} />
      <Route path="/dentist/update/:id" exact component={DentistCreateUpdate} />

      <Route path="/buyer" exact component={BuyerList} />
      <Route path="/buyer/create" component={BuyerCreateUpdate} />
      <Route path="/buyer/update/:id" exact component={BuyerCreateUpdate} />

      <Route path="/role" exact component={RoleList} />
      <Route path="/role/create" component={RoleCreateUpdate} />
      <Route path="/role/update/:id" exact component={RoleCreateUpdate} />
      <Route path="/role/:id/action/link" exact component={RoleActionLink} />

      <Route path="/group" exact component={GroupTreeView} />
      <Route path="/supplier" exact component={SupplierList} />
      <Route path="/supplier/create" component={SupplierCreateUpdate} />
      <Route
        path="/supplier/update/:id"
        exact
        component={SupplierCreateUpdate}
      />
      <Route path="/item/list" exact component={ItemList} />
      <Route path="/entry/pending/item/:id" exact component={CreateItem} />
      <Route path="/item/create" exact component={ItemCreateUpdate} />
      <Route path="/item/update/:id" exact component={ItemCreateUpdate} />
      <Route path="/stock-config" exact component={StockConfig} />
      <Route path="/item/history/:id" exact component={HistoryList} />
      <Route
        path="/purchase/view/products/:id"
        exact
        component={PurchaseItemList}
      />
      <Route
        path="/purchase/:id/manualEntry/:entryId/products/"
        exact
        component={PurchaseManualEntry}
      />
      <Route path="/item-search" exact component={ItemSearch} />
      <Route path="/item/pending/price" exact component={PricePending} />
      <Route
        path="/item/pending/price/:id"
        exact
        component={PricePendingItemList}
      />
      <Route
        path="/unregistered-item"
        exact
        component={UnregisteredItemPurchaseList}
      />
      <Route
        path="/unregistered-item/list/:id"
        exact
        component={UnregisteredItemPendingList}
      />
      <Route path="/adjust-stock" exact component={AdjustStock} />
      <Route path="/purchase" exact component={Purchase} />
      <Route path="/budget" exact component={BudgetList} />
      <Route path="/budget/itemList/:id" exact component={BudgetItemList} />
      <Route
        path="/budget/view-budget-items/:id"
        exact
        component={ViewBudgetItems}
      />
      <Route
        path="/budget/budget-items/:id"
        exact
        component={ImportItemsList}
      />
      <Route
        path="/item/update-item-prices-list"
        exact
        component={UpdateItemPricesList}
      />
      <Route
        path="/financial/config/bank-account"
        exact
        component={BankAccountList}
      />
      <Route
        path="/financial/bank-account-consolidation"
        exact
        component={BankAccountConsolidationList}
      />
      <Route
        path="/financial/bank-account-consolidation/:unitFinanceId/statement"
        exact
        component={BankAccountStatement}
      />
      <Route
        path="/financial/config/bank-account/create"
        exact
        component={BankAccountCreateUpdate}
      />
      <Route
        path="/financial/config/bank-account/update/:id"
        exact
        component={BankAccountCreateUpdate}
      />
      <Route
        path="/financial/expenses/employee-expense/:id/unit/:unitId"
        exact
        component={EmployeeExpenseList}
      />

      <Route path="/item/barcodeExport/:id" exact component={BarcodeExport} />
      <Route path="/report/stock" exact component={StockReportList} />
      <Route
        path="/report/purchasing-group"
        exact
        component={PurchasingGroupReportList}
      />
      <Route path="/report/lab" exact component={LabReportList} />
      <Route path="/report/radiology" exact component={RadiologyReportList} />
      <Route path="/report/dentist" exact component={DentistReportList} />
      <Route
        path="/profit-margin/category"
        exact
        component={ProfitMarginList}
      />
      <Route
        path="/profit-margin/individual"
        exact
        component={ProfitMarginIndividualList}
      />

      <Route path="/sale" exact component={SaleList} />
      <Route
        path="/profit-margin/category"
        exact
        component={ProfitMarginList}
      />
      <Route
        path="/profit-margin/individual"
        exact
        component={ProfitMarginIndividualList}
      />

      <Route path="/sale" exact component={SaleList} />
      <Route path="/sale/add-items/:id" exact component={AddItemsOnSale} />
      <Route path="/lab/toothType" exact component={ToothTypeList} />
      <Route
        path="/lab/toothType/create"
        exact
        component={ToothTypeCreateUpdate}
      />
      <Route
        path="/lab/toothType/update/:id"
        exact
        component={ToothTypeCreateUpdate}
      />

      <Route path="/lab/endProduct" exact component={EndProductList} />
      <Route
        path="/lab/endProduct/create"
        exact
        component={EndProductCreateUpdate}
      />
      <Route
        path="/lab/endProduct/update/:id"
        exact
        component={EndProductCreateUpdate}
      />
      <Route path="/lab/section" exact component={SectionTreeView} />

      <Route path="/lab/step" exact component={StepList} />
      <Route path="/lab/step/create" exact component={StepCreateUpdate} />
      <Route path="/lab/step/update/:id" exact component={StepCreateUpdate} />

      <Route path="/calendar" exact component={CalendarList} />
      <Route path="/calendar/holidays/:id" exact component={HolidayList} />
      <Route path="/lab/config" exact component={LabConfig} />

      <Route path="/radiology/recess" exact component={RecessList} />

      <Route
        path="/dentist/specialties/:id"
        exact
        component={ManageSpecialties}
      />

      <Route
        path="/dentist/lab/orderService"
        exact
        component={OrderServiceList}
      />
      <Route
        path="/dentist/lab/allOrderService"
        exact
        component={AllOrderServiceList}
      />
      <Route
        path="/dentist/lab/orderService/create/:orderServiceId/:endProductId/:workflowPosition"
        exact
        component={OrderServiceStepCreateUpdate}
      />
      <Route
        path="/dentist/lab/orderService/update/:orderServiceId/:workflowPosition"
        exact
        component={DentistCreateUpdateStepCarousel}
      />

      <Route
        path="/clinic/lab/orderService/update/:orderServiceId/:workflowPosition"
        exact
        component={ClinicCreateUpdateStepCarousel}
      />

      <Route
        path="/clinic/lab/orderService/pending"
        exact
        component={ClinicOrderServicePendingList}
      />
      <Route
        path="/clinic/lab/orderService/all"
        exact
        component={ClinicOrderServiceList}
      />

      <Route
        path="/lab/orderService/pending"
        exact
        component={LabOrderServiceList}
      />
      <Route
        path="/lab/orderService/refused"
        exact
        component={LabAllOrderServiceRefusedList}
      />
      <Route
        path="/lab/orderService/completed-laboratory"
        exact
        component={LabAllOrderServiceCompletedLaboratoryList}
      />
      <Route
        path="/lab/orderService/update/:endProductId/:id/:viewForm/:workflowPosition"
        exact
        component={LabOrderServiceStepCreateUpdate}
      />

      <Route
        path="/lab/orderService/update/:endProductId/:id/:viewForm/:workflowPosition/:orderServiceId"
        exact
        component={LabOrderServiceStepCreateUpdate}
      />

      <Route
        path="/lab/orderService/update/:endProductId/:id/:viewForm/:workflowPosition/:orderServiceId/:create"
        exact
        component={LabOrderServiceStepCreateUpdate}
      />

      <Route
        path="/lab/orderService/update/:orderServiceId/:workflowPosition"
        exact
        component={LabCreateUpdateStepCarousel}
      />
      <Route path="/lab/serviceQueue" exact component={ServiceQueueList} />
      <Route
        path="/lab/allOrderService"
        exact
        component={LabAllOrderServiceList}
      />

      <Route path="/outsourced" exact component={OutsourcedList} />
      <Route path="/outsourced/create" component={OutsourcedCreateUpdate} />
      <Route
        path="/outsourced/update/:id"
        exact
        component={OutsourcedCreateUpdate}
      />
      <Route
        path="/outsourced/priceTableList/:id"
        component={OutsourcedPriceTableList}
      />
      <Route
        path="/outsourced/priceTableList/:id"
        component={OutsourcedPriceTableList}
      />

      <Route path="/patient" exact component={PatientList} />
      <Route path="/patient/create" exact component={PatientCreateUpdate} />
      <Route path="/patient/update/:id" exact component={PatientCreateUpdate} />

      <Route path="/radiology/origin" exact component={OriginView} />
      <Route path="/radiology/treatment" exact component={TreatmentList} />
      <Route
        path="/radiology/treatment/create"
        exact
        component={TreatmentCreateUpdate}
      />
      <Route
        path="/radiology/treatment/update/:id"
        exact
        component={TreatmentCreateUpdate}
      />

      <Route path="/dentist/specialty" exact component={SpecialtyView} />
      <Route path="/dentist/procedure" exact component={ProcedureList} />
      <Route
        path="/dentist/procedure/create"
        exact
        component={ProcedureCreateUpdate}
      />
      <Route
        path="/dentist/procedure/update/:id"
        exact
        component={ProcedureCreateUpdate}
      />

      <Route
        path="/radiology/config/unit-type-services"
        exact
        component={RadiologyConfig}
      />
      <Route
        path="/radiology/config/unit-type-services/linked-services/:unitId"
        exact
        component={LinkedServicesList}
      />
      <Route
        path="/radiology/config/opening-hours"
        exact
        component={OpeningHoursCreateUpdate}
      />
      <Route
        path="/shoppingGroup/searchItem"
        exact
        component={SearchItemList}
      />

      <Route
        path="/clinic/radiology/schedule/local"
        exact
        component={ClinicRadiologyScheduleList}
      />
      <Route
        path="/clinic/radiology/schedule/central"
        exact
        component={CentralRadiologyScheduleList}
      />
      <Route
        path="/clinic/radiology/schedule/create"
        exact
        component={ClinicRadiologyScheduleCreateUpdate}
      />
      <Route
        path="/clinic/radiology/schedule/update/:id/:update"
        exact
        component={ClinicRadiologyScheduleCreateUpdate}
      />

      <Route
        path="/radiology/schedule"
        exact
        component={RadiologyScheduleList}
      />
      <Route
        path="/radiology/schedule/create"
        exact
        component={RadiologyScheduleCreateUpdate}
      />
      <Route
        path="/radiology/schedule/update/:id/:update"
        exact
        component={RadiologyScheduleCreateUpdate}
      />
      <Route path="/clinic/report/stock" exact component={ClinicStockReport} />
      <Route
        path="/clinic/report/radiology"
        exact
        component={ClinicRadiologyReport}
      />
      <Route path="/clinic/report/lab" exact component={ClinicLabReportList} />

      <Route path="/dentist/substitute" exact component={SubstituteList} />
      <Route
        path="/dentist/substitute/create"
        exact
        component={SubstituteCreateUpdate}
      />
      <Route
        path="/dentist/substitute/update/:id"
        exact
        component={SubstituteCreateUpdate}
      />
      <Route
        path="/dentist/attendances"
        exact
        component={ProceduresAttendancesList}
      />

      <Route
        path="/dentist/attendance/create"
        exact
        component={AttendanceCreateUpdate}
      />

      <Route
        path="/dentist/attendance/update/:id"
        exact
        component={AttendanceCreateUpdate}
      />

      <Route
        path="/dentist/attendance/:id"
        exact
        component={AttendanceCreateUpdate}
      />

      <Route
        path="/dentist/audit/procedures"
        exact
        component={ProceduresAuditList}
      />

      <Route
        path={[
          '/dentist/audit/procedure/:id',
          '/dentist/audit/ScheduleReplacements/procedure/:id',
        ]}
        exact
        component={ProceduresAuditCreateUpdate}
      />

      <Route
        path="/dentist/audit/schedule"
        exact
        component={DentistScheduleAuditList}
      />

      <Route
        path={[
          '/dentist/audit/schedule/:id/attendances',
          '/dentist/audit/ScheduleReplacements/:id/attendances',
        ]}
        exact
        component={ScheduleAttendancesAuditList}
      />

      <Route
        path="/dentist/audit/procedures/disapproved"
        exact
        component={ProceduresAuditDisapprovedList}
      />

      <Route
        path="/dentist/audit/procedures/disapproved/:id"
        exact
        component={ProceduresAuditDisapprovedCreateUpdate}
      />

      <Route
        path="/dentist/audit/schedule/disapproved"
        exact
        component={ScheduleAuditDisapprovedList}
      />

      <Route
        path="/dentist/substitute/:id"
        exact
        component={SubstituteCreateUpdate}
      />

      <Route
        path="/dentist/dentistWorkedHour"
        exact
        component={DentistWorkedHourList}
      />

      <Route
        path="/dentist/dentistWorkedHour/attendances"
        exact
        component={AttendancesHoursList}
      />

      <Route
        path={[
          '/dentist/dentistWorkedHour/attendance/create',
          '/dentist/dentistWorkedHour/attendance/update/:id',
          '/dentist/dentistWorkedHour/attendance/:id',
        ]}
        exact
        component={AttendanceHoursCreateUpdate}
      />

      {/*<Route*/}
      {/*  path="/dentist/submittedAttendances"*/}
      {/*  exact*/}
      {/*  component={SubmittedAttendancesList}*/}
      {/*/>*/}

      <Route
        path="/dentist/audit/all-procedures"
        exact
        component={AllProceduresList}
      />

      <Route
        path="/dentist/audit/all-procedures/attendance/:id"
        exact
        component={AllProceduresCreateUpdate}
      />

      <Route
        path="/dentist/audit/all-schedules"
        exact
        component={AllSchedulesProceduresList}
      />

      <Route
        path="/dentist/audit/all-schedules/schedule/:id/attendances"
        exact
        component={AllProceduresInScheduleList}
      />

      <Route
        path="/dentist/audit/all-schedules/schedule/:scheduleId/attendance/:id"
        exact
        component={AllProceduresCreateUpdate}
      />

      <Route path="/transferType" exact component={TransferTypeView} />

      <Route
        path="/financial/config/serviceSupplier"
        exact
        component={ServiceSupplierList}
      />
      <Route
        path="/financial/config/serviceSupplier/create"
        component={ServiceSupplierCreateUpdate}
      />
      <Route
        path="/financial/config/serviceSupplier/update/:id"
        exact
        component={ServiceSupplierCreateUpdate}
      />
      <Route
        path="/dentist/audit/replacements"
        exact
        component={ReplacementsAuditList}
      />

      <Route
        path="/dentist/audit/replacement/procedure/:id"
        exact
        component={ReplacementsAuditCreateUpdate}
      />

      <Route
        path="/dentist/audit/ScheduleReplacements"
        exact
        component={ReplacementsScheduleAuditList}
      />

      <Route
        path="/financial/config/expense-type"
        exact
        component={ExpenseTypeTreeView}
      />

      <Route
        path="/clinic/financial/config/expense-type"
        exact
        component={ClinicExpenseTypeTreeView}
      />

      <Route
        path="/financial/config/payment-type"
        exact
        component={PaymentTypeList}
      />

      <Route path="/financial/expenses" exact component={ExpenseList} />
      <Route path="/financial/expense/create" component={ExpenseCreateUpdate} />
      <Route
        path="/financial/expense/update/:id"
        exact
        component={ExpenseCreateUpdate}
      />
      <Route
        path="/financial/expense/:id"
        exact
        component={ExpenseCreateUpdate}
      />

      <Route path="/employee/config/sector" exact component={SectorView} />

      <Route
        path="/financial/config/expense-type/:expenseTypeId/expense-type"
        exact
        component={ExpenseTypeExpenseTypeList}
      />

      <Route
        path="/financial/config/expense-type/:expenseTypeId/expenses"
        exact
        component={DreExpenseList}
      />

      <Route
        path="/financial/bank-account-consolidation/expenses"
        exact
        component={PendingExpenseList}
      />

      <Route
        path="/financial/config/expense-type/:expenseTypeId/payments"
        exact
        component={DrePaymentList}
      />

      <Route
        path="/financial/config/expense-type/:expenseTypeId/stockConsumption"
        exact
        component={DreStockConsumptionList}
      />

      <Route
        path="/financial/config/expense-type/:expenseTypeId/labConsumption"
        exact
        component={DreLabConsumptionList}
      />

      <Route
        path="/financial/config/expense-type/:expenseTypeId/radiologyConsumption"
        exact
        component={DreRadiologyConsumptionList}
      />

      <Route
        path="/financial/config/recurrent-expenses"
        exact
        component={RecurrentExpenseList}
      />
      <Route
        path="/financial/config/recurrent-expense/update/:id"
        exact
        component={RecurrentExpenseCreateUpdate}
      />
      <Route
        path="/financial/config/financial-cash"
        exact
        component={FinancialCashList}
      />

      <Route
        path="/financial/bankingOperation"
        exact
        component={BankingOperationList}
      />

      <Route
        path="/dentist/config/favorite-unit"
        exact
        component={DentistFavoriteUnitCreateUpdate}
      />

      <Route
        path="/financial/adverse-expenses"
        exact
        component={AdverseExpenseList}
      />

      <Route
        path="/financial/adverse-expense/create"
        exact
        component={AdverseExpenseCreateUpdate}
      />

      <Route
        path="/financial/adverse-expense/update/:id"
        exact
        component={AdverseExpenseCreateUpdate}
      />

      <Route
        path="/financial/laborExpense"
        exact
        component={GroupedExpenseList}
      />

      <Route
        path="/financial/expenses/grouped/:groupedId"
        exact
        component={ExpenseGroupedList}
      />

      <Route
        path="/financial/adverse-expense/:id"
        exact
        component={AdverseExpenseCreateUpdate}
      />

      <Route
        path="/financial/cashRegisterClose"
        exact
        component={CashRegisterCloseList}
      />

      <Route
        path="/financial/bank-account-consolidation/cashRegisterClose"
        exact
        component={PendingCashRegisterCloseList}
      />

      <Route
        path={[
          '/financial/cashRegisterClose/create',
          '/financial/cashRegisterClose/update/:id',
        ]}
        component={CashRegisterCloseCreateUpdate}
      />
      <Route
        path="/financial/cashRegisterClose/:id"
        exact
        component={CashRegisterCloseCreateUpdate}
      />

      <Route
        path="/financial/cashRegisterCard"
        exact
        component={CashRegisterCardList}
      />

      <Route
        path="/financial/itemizedCard/all"
        exact
        component={ItemizedCardListAll}
      />

      <Route
        path="/financial/itemizedCard/:cashRegisterValueId"
        exact
        component={ItemizedCardList}
      />

      <Route
        path="/financial/cashRegisterCheck"
        exact
        component={CashRegisterCheckList}
      />

      <Route
        path="/financial/itemizedCheck/:cashRegisterValueId"
        exact
        component={ItemizedCheckList}
      />

      <Route
        path={[
          '/financial/expenses/recurrent/create',
          '/financial/expenses/recurrent/view',
        ]}
        exact
        component={ThrowRecurrentExpenseCreateUpdate}
      />

      <Route path="/financial/report" exact component={FinancialReportList} />

      <Route
        path="/administrative/consumption/stock"
        exact
        component={StockConsumptionList}
      />

      <Route
        path="/administrative/stock-consumption/unit/:id"
        exact
        component={ThrowStockConsumptionUnitList}
      />

      <Route
        path={[
          '/administrative/view-stock-consumption/:id/unit/:unitId',
          '/administrative/manage-stock-consumption/:id/unit/:unitId',
        ]}
        exact
        component={ManageStockConsumptionUnit}
      />

      <Route
        path="/clinic/consumption/stock"
        exact
        component={ThrowStockConsumptionUnitListClinic}
      />

      <Route
        path={['/clinic/consumption/stock/view/:id']}
        exact
        component={ManageStockConsumptionUnitClinic}
      />

      <Route
        path="/clinic/consumption/lab"
        exact
        component={ThrowLabConsumptionUnitListClinic}
      />

      <Route
        path={['/clinic/consumption/lab/view/:id']}
        exact
        component={ManageLabConsumptionUnitClinic}
      />

      <Route
        path="/clinic/consumption/radiology"
        exact
        component={ThrowRadiologyConsumptionUnitListClinic}
      />

      <Route
        path={['/clinic/consumption/radiology/view/:id']}
        exact
        component={ManageRadiologyConsumptionUnitClinic}
      />

      <Route
        path="/administrative/consumption/lab"
        exact
        component={LabConsumptionList}
      />

      <Route
        path="/administrative/lab-consumption/unit/:id"
        exact
        component={ThrowLabConsumptionUnitList}
      />

      <Route
        path={[
          '/administrative/view-lab-consumption/:id/unit/:unitId',
          '/administrative/manage-lab-consumption/:id/unit/:unitId',
        ]}
        exact
        component={ManageLabConsumptionUnit}
      />

      <Route
        path="/administrative/consumption/radiology"
        exact
        component={RadiologyConsumptionList}
      />

      <Route
        path="/administrative/radiology-consumption/unit/:id"
        exact
        component={ThrowRadiologyConsumptionUnitList}
      />

      <Route
        path={[
          '/administrative/view-radiology-consumption/:id/unit/:unitId',
          '/administrative/manage-radiology-consumption/:id/unit/:unitId',
        ]}
        exact
        component={ManageRadiologyConsumptionUnit}
      />

      <Route
        path="/clinic/cashRegisterClose"
        exact
        component={ClinicCashRegisterCloseList}
      />

      <Route
        path={[
          '/clinic/cashRegisterClose/create',
          '/clinic/cashRegisterClose/update/:id',
          '/clinic/cashRegisterClose/:id',
        ]}
        exact
        component={ClinicCashRegisterCloseCreateUpdate}
      />

      <Route
        path="/clinic/cashRegisterClose/:id/cards"
        exact
        component={ClinicCashRegisterCardList}
      />

      <Route
        path="/clinic/cashRegisterClose/:id/checks"
        exact
        component={ClinicCashRegisterCheckList}
      />

      <Route
        path="/clinic/cashRegisterClose/itemizedCard/:cashRegisterValueId"
        exact
        component={ClinicItemizedCardList}
      />

      <Route
        path="/clinic/cashRegisterClose/itemizedCheck/:cashRegisterValueId"
        exact
        component={ClinicItemizedCheckList}
      />

      <Route
        path="/employee/config/sector/:id/expense-types"
        exact
        component={SectorExpenseTypeList}
      />

      <Route
        path="/clinic/dentist/audit-procedures"
        exact
        component={ClinicProceduresAuditList}
      />

      <Route
        path={[
          '/clinic/dentist/audit-procedures/procedure/:id',
          '/clinic/dentist/audit-schedules/procedure/:id',
          '/clinic/dentist/audit/schedule/replacements/procedure/:id',
          '/clinic/dentist/audit/procedures/disapproved/procedure/:id',
        ]}
        exact
        component={ClinicProceduresAuditCreateUpdate}
      />

      <Route
        path="/clinic/dentist/audit-schedules"
        exact
        component={ClinicDentistScheduleAuditList}
      />

      <Route
        path={[
          '/clinic/dentist/audit-schedules/:id/attendances',
          '/clinic/dentist/audit/schedule/disapproved/:id/attendances',
          '/clinic/dentist/audit/schedule/replacements/:id/attendances',
        ]}
        exact
        component={ClinicScheduleAttendancesAuditList}
      />

      <Route
        path="/clinic/dentist/audit/all-procedures"
        exact
        component={ClinicAllProceduresList}
      />

      <Route
        path={[
          '/clinic/dentist/audit/all-schedules/schedule/:scheduleId/attendance/:id',
          '/clinic/dentist/audit/all-procedures/attendance/:id',
          '/clinic/dentist/audit-schedules/procedure/:id/view',
          '/clinic/dentist/audit/schedule/disapproved/procedure/:id/view',
          '/clinic/dentist/audit/schedule/replacements/procedure/:id/view',
        ]}
        exact
        component={ClinicAllProceduresCreateUpdate}
      />

      <Route
        path="/clinic/dentist/audit/all-schedules"
        exact
        component={ClinicAllSchedulesProceduresList}
      />

      <Route
        path="/clinic/dentist/audit/all-schedules/schedule/:id/attendances"
        exact
        component={ClinicAllProceduresInScheduleList}
      />

      <Route
        path="/clinic/dentist/audit/schedule/disapproved"
        exact
        component={ClinicScheduleAuditDisapprovedList}
      />

      <Route
        path="/clinic/dentist/audit/procedures/disapproved"
        exact
        component={ClinicProceduresAuditDisapprovedList}
      />

      <Route
        path="/clinic/dentist/audit/procedures/disapproved/:id"
        exact
        component={ClinicProceduresAuditDisapprovedCreateUpdate}
      />

      <Route
        path="/clinic/dentist/audit/replacements"
        exact
        component={ClinicReplacementsAuditList}
      />

      <Route
        path="/clinic/dentist/audit/replacements/procedure/:id"
        exact
        component={ClinicReplacementsAuditCreateUpdate}
      />

      <Route
        path="/clinic/dentist/audit/schedule/replacements"
        exact
        component={ClinicReplacementsScheduleAuditList}
      />

      <Route
        path="/clinic/dentist/substitute"
        exact
        component={ClinicSubstituteList}
      />

      <Route
        path={[
          '/clinic/dentist/substitute/create',
          '/clinic/dentist/substitute/update/:id',
          '/clinic/dentist/substitute/:id',
        ]}
        exact
        component={ClinicSubstituteCreateUpdate}
      />

      <Route path="/dre/report" exact component={DreReportList} />

      <Route path="/dre/clinic/report" exact component={ClinicDreReportList} />

      <Route
        path={[
          '/financial/config/bank-account/:id/adjustment-history',
          '/financial/config/financial-cash/:id/adjustment-history',
        ]}
        exact
        component={UnitFinanceAdjustmentsList}
      />

      <Route path="/disabled-items" exact component={DisabledItemsList} />

      <Route path="/item-locator" exact component={ItemLocatorList} />

      <Route
        path="/administrative/consumption/dentist"
        exact
        component={DentistConsumptionList}
      />

      <Route
        path="/administrative/dentist-consumption/unit/:id"
        exact
        component={ThrowDentistConsumptionUnitList}
      />

      <Route
        path={[
          '/administrative/view-dentist-consumption/:id/unit/:unitId',
          '/administrative/manage-dentist-consumption/:id/unit/:unitId',
        ]}
        exact
        component={ManageDentistConsumptionUnit}
      />

      <Route
        path="/clinic/consumption/dentist"
        exact
        component={ThrowDentistConsumptionUnitListClinic}
      />

      <Route
        path={['/clinic/consumption/dentist/view/:id']}
        exact
        component={ManageDentistConsumptionUnitClinic}
      />

      <Route
        path={['/clinic/stock/searchItem']}
        exact
        component={ItemListClinic}
      />

      <Route
        path={['/clinic/stock/locator']}
        exact
        component={ItemLocatorClinicList}
      />

      <Route
        path={['/clinic/stock/adjust-stock']}
        exact
        component={AdjustStockClinicList}
      />
      <Route path={['/clinic/stock/order']} exact component={ItemOrder} />

      <Route
        path={['/clinic/stock/order/addItems/:id']}
        exact
        component={ItemsToOrderList}
      />

      <Route
        path={['/clinic/stock/order/view/:id']}
        exact
        component={ViewAllItemsByOrder}
      />

      <Route
        path={['/clinic/stock/withdrawItem']}
        exact
        component={ClinicListItemWithdraw}
      />

      <Route path={['/stock/order/transfer']} exact component={TransfersList} />

      <Route
        path={['/stock/order/separateItems/:id']}
        exact
        component={SeparateOrderItems}
      />

      <Route
        path={['/stock/order/view/:id']}
        exact
        component={ViewCompletedTransferOrder}
      />

      <Route
        path="/report/consumption"
        exact
        component={ConsumptionReportList}
      />
    </Switch>
  );
};

export default AppRoutes;
