/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import {
  ActionsContainer,
  AutoDiv,
  CancelButton,
  CenterContainer,
  Container,
  DialogContent,
  Paper,
  SubmitButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextStyledH1,
  TextStyledH5,
  DottedLine,
  Grid,
  Line,
  Signature,
  SignatureLine,
  TotalFont,
  Dialog,
  Card,
} from './styles';
import DialogActions from '@material-ui/core/DialogActions';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import format from 'telefone/format';

const pdf = (sale, ref) => {
  return (
    <Container ref={ref}>
      <section>{body(sale)}</section>
      <section>{body(sale)}</section>
    </Container>
  );
};

const body = (sale) => {
  return (
    <Container>
      <CenterContainer>
        <TextStyledH1>Setedental</TextStyledH1>
      </CenterContainer>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextStyledH5>Transferência: {sale?.id}</TextStyledH5>
        </Grid>
        <Grid item xs={4}>
          <TextStyledH5>
            Data: {moment(sale?.updatedAt).format('DD/MM/YYYY')}
          </TextStyledH5>
        </Grid>
        <Grid item xs={8}>
          <TextStyledH5>
            Cliente:{' '}
            {sale?.buyer.isLegalPerson
              ? sale?.buyer.fantasyName
              : sale?.buyer.socialName}
          </TextStyledH5>
        </Grid>
        <Grid item xs={4}>
          <TextStyledH5>
            CPF/CNPJ:{' '}
            {sale?.buyer.isLegalPerson
              ? cnpj.format(sale?.buyer?.cnpj)
              : cpf.format(sale?.buyer?.cpf)}
          </TextStyledH5>
        </Grid>
        <Grid item xs={8}>
          <TextStyledH5>
            Telefone:{' '}
            {sale?.buyer.alternativePhoneNumber
              ? format(sale?.buyer.phoneNumber) +
                ', ' +
                format(sale?.buyer.alternativePhoneNumber)
              : format(sale?.buyer.phoneNumber)}
          </TextStyledH5>
        </Grid>
        <Grid item xs={4}>
          <TextStyledH5>Vendedor: {sale?.employee.user.name}</TextStyledH5>
        </Grid>
        <Grid item xs={12}>
          <TextStyledH5>
            Endereço:{' '}
            {`${sale?.buyerAddress?.address}, ${sale?.buyerAddress?.number}
          - ${sale?.buyerAddress?.neighborhood}, ${sale?.buyerAddress?.city} - 
          ${sale?.buyerAddress?.state}, ${sale?.buyerAddress?.cep}`}
          </TextStyledH5>
        </Grid>
      </Grid>
      <CenterContainer>
        <Line />
      </CenterContainer>
      <AutoDiv>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Cod</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Qtd</TableCell>
                <TableCell align="right">Valor Unit</TableCell>
                <TableCell align="right">Valor Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sale?.saleItems
                .sort((a, b) => a.item.id - b.item.id)
                .map((row) => (
                  <>
                    <TableRow key={row.item.id}>
                      <TableCell component="th" scope="row">
                        {row.item.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.item.name}
                      </TableCell>
                      <TableCell align="right">
                        {row.transferQuantity}
                      </TableCell>
                      <TableCell align="right">
                        {Number(row.price).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {(row.price * row.transferQuantity).toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              <TableRow key={sale?.id}>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <TotalFont>Total</TotalFont>
                </TableCell>
                <TableCell align="right">
                  {Number(sale?.payment.amount).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TextStyledH5>
          Forma de Pagamento:{' '}
          {!isEmpty(sale?.payment?.installments)
            ? sale?.payment?.installments.length +
              'x(' +
              sale?.payment.installments
                .map((e) =>
                  Number(e.price).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                )
                .join(', ') +
              ')'
            : '1x(' +
              Number(sale?.payment.amount).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }) +
              ')'}
        </TextStyledH5>
        <SignatureLine />
        <Signature>
          <TextStyledH5>Assinatura</TextStyledH5>
        </Signature>
      </AutoDiv>
      <CenterContainer>
        <DottedLine />
      </CenterContainer>
    </Container>
  );
};

function generateSaleToPrint(sale) {
  return React.forwardRef((props, ref) => pdf(sale, ref));
}

function PrintSaleDialog({ onCancel, selectedSale, ...props }) {
  const [sale, setSale] = useState(null);
  const componentRef = useRef();
  const SaleToPrint = generateSaleToPrint(sale);

  useEffect(() => {
    if (selectedSale) {
      setSale(selectedSale);
    }
  }, [selectedSale]);
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <Card>{body(sale)}</Card>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <ReactToPrint
              trigger={() => (
                <SubmitButton variant="contained" color="primary">
                  Imprimir
                </SubmitButton>
              )}
              documentTitle={`Comprovante de Transferência #${sale?.id}`}
              content={() => componentRef.current}
              pageStyle={`
                  @page {
                    size: auto;
                    margin: 5mm 5mm 5mm 5mm;        
                  }
                  @media print { 
                    section {
                      page-break-inside: avoid;
                    }
                  }
              `}
            />
          </ActionsContainer>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'none' }}>
        <SaleToPrint ref={componentRef} />
      </div>
    </Container>
  );
}

export default PrintSaleDialog;
