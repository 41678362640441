import React, { useState, useEffect, useCallback } from 'react';
import ItemizedCard from '../../../../../forms/itemizedCard';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  CancelButton,
  Container,
  HeaderContainer,
  AutoDiv,
  StyledTypography,
  Divide,
} from './styles';
import CreateItemizedCardDialog from '../../../../../components/createItemizedCardDialog';
import ShowItemizedCardInstallment from '../../../../../components/showItemizedCardInstallment';
import ItemizedCardInstallment from '../../../../../forms/itemizedCardInstallment';

function ItemizedCardList({ history, match, location }) {
  const cashRegisterValueId = match?.params?.cashRegisterValueId ?? -1;
  const cashRegisterValue = location?.state?.cashRegisterValue;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [], valueSum: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectdItemizedCard, setSelectdItemizedCard] = useState(null);

  const columns = ItemizedCard.tableSchema;

  const loadcashRegisterCloses = useCallback(() => {
    if (cashRegisterValueId) {
      restApiClient.itemizedCard.getAllByCashRegisterValueId(
        cashRegisterValueId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('serial', searchBy).or().like('status', searchBy).toString()
          : null,
        orderBy
      ).then((e) => {
        setRows(e.data);
      });
    }
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadcashRegisterCloses();
  }, [loadcashRegisterCloses]);

  return (
    <Container>
      <CrudTable
        title={`Lançamento de Cartões - Caixa ${cashRegisterValue?.cashRegisterClose.numberCashRegister}`}
        customHeaderComponent={
          <HeaderContainer>
            <AutoDiv>
              <StyledTypography variant="h6">
                {`Lançado: ${rows?.valueSum?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })} de ${cashRegisterValue?.value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}
              </StyledTypography>
            </AutoDiv>
          </HeaderContainer>
        }
        modelActions={ItemizedCard.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpenDialog(true);
            },
            title: 'Lançar entrada de cartão',
          },
          view: {
            icon: <Divide />,
            onClick: (itemizedCard) => {
              setSelectdItemizedCard(itemizedCard);
            },
            title: 'Visualizar parcelas',
            selector: (itemizedCard) => itemizedCard.installments > 1,
            allowedActions: [
              ItemizedCardInstallment.actions.getAllByItemizedCardId,
            ],
          },
          delete: {
            onClick: (itemizedCardId, index) => {
              restApiClient.itemizedCard.delete(itemizedCardId).then(() => {
                loadcashRegisterCloses();
              });
            },
            allowedActions: [ItemizedCard.actions.delete],
            selector: (row) => row.status === 'Pendente',
          },
        }}
        customCellRenderers={{
          date: {
            renderer: (field, value) => {
              return value ? new Date(`${value}T03:24:00`).toLocaleDateString('pt-br') : '-';
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          liquid: {
            renderer: (field, value) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <CreateItemizedCardDialog
        open={openDialog}
        onCancel={() => {
          setOpenDialog(false);
        }}
        onSuccess={(data) => {
          setRows({
            ...rows,
            values: [
              ...rows.values,
              { ...data, status: 'Pendente', installments: 1 },
            ],
            valueSum: rows.valueSum + data.value,
          });

          if (cashRegisterValue?.value === rows.valueSum + data.value) {
            setOpenDialog(false);
          }
        }}
        cashRegisterValueId={parseInt(cashRegisterValueId)}
      />

      <ShowItemizedCardInstallment
        itemizedCardId={selectdItemizedCard}
        handleClose={() => {
          setSelectdItemizedCard(null);
        }}
      />
    </Container>
  );
}

export default ItemizedCardList;
