import styled from 'styled-components';
import MoneyInput from '../../../components/moneyInput';
import TextFieldInput from '../../../components/textFieldInput';
import {
  Button as MaterialButton,
  Typography as MaterialTypography,
} from '@material-ui/core';
import { Save as MaterialSaveIcon } from '@styled-icons/material/Save';
import PercentageInput from '../../../components/percentageInput';
import { MonetizationOn as MaterialMonetizationOn } from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 110px;
`;

export const StyledTextFieldInput = styled(TextFieldInput)`
  width: 50px;
`;

export const PaymentButton = styled(MaterialButton)`
  background: #2dbd17 !important;
`;

export const SaveButton = styled(MaterialButton)``;

export const SaveIcon = styled(MaterialSaveIcon)`
  width: 25px;
  height: 25px;
`;

export const StyledTypography = styled(MaterialTypography)`
  margin-left: 20px !important;
`;

export const StyledPercentageInput = styled(PercentageInput)`
  width: 70px;
  text-align: center !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 110px;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Money = styled(MaterialMonetizationOn)`
  width: 25px;
  height: 25px;
`;
