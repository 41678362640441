import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportItemsStock = {
  buildItemListPdf: (unitName, items) => {
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    let y = 0;
    doc.setFontSize(22);
    doc.text(
      `Relatório - Itens em estoque`,
      doc.internal.pageSize.getWidth() / 2,
      (y += 20),
      null,
      null,
      'center'
    );

    doc.setFontSize(14);
    doc.text(15, (y += 10), 'Unidade: ' + unitName);
    let now = new Date();
    doc.text(
      15,
      (y += 10),
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds()
    );

    doc.autoTable({
      startY: (y += 10),
      headStyles: { fillColor: [0, 70, 171] },
      head: [
        ['id', 'Nome', 'Marca', 'Localizador', 'Categorização', 'Quantidade'],
      ],
      body: items.map((x) => {
        let group = '';
        if (x.group?.parent?.parent) {
          group = `${x.group.parent.parent.name} -> ${x.group.parent.name} -> ${x.group.name}`;
        } else if (x.group?.parent) {
          group = `${x.group.parent.name} -> ${x.group.name}`;
        } else if (x.group) {
          group = `${x.group.name}`;
        }
        let quantity = x.stock.reduce((accumulator, currentValue) => {
          return accumulator + parseInt(currentValue.availableQuantity);
        }, 0);
        return [
          x.id !== '' ? x.id : '-',
          x.name !== '' ? x.name : '-',
          x.brand !== '' ? x.brand : '-',
          x.itemLocator?.locator != null ? x.itemLocator?.locator : '-',
          group !== '' ? group : '-',
          quantity !== '' ? quantity : '-',
        ];
      }),
      theme: 'grid',
      styles: {
        halign: 'center',
        valign: 'middle',
        cellPadding: { top: 1, right: 0, bottom: 1, left: 0 },
        fontSize: 7,
      },
      columnStyles: { 8: { cellWidth: 25 }, 9: { cellWidth: 25 } },
      margin: 4,
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(285, 209, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default ReportItemsStock;
