import React, { useState, useEffect } from 'react';
import Item from '../../../../forms/item';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, StyledMoneyInput, Check } from './styles';

function PricePendingItemList({ history, match, ...props }) {
  const purchaseId = match.params?.id;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Item.pricePendentTableSchema;

  useEffect(() => {
    restApiClient.item
      .getAllPricePending(
        purchaseId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('brand', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Pendências de preço'}
        modelActions={Item.actions}
        columns={columns}
        customColumns={[
          {
            id: 'newValue',
            label: 'Novo valor',
            minWidth: 50,
            align: 'center',
            allowedActions: [Item.actions.updatePrice],
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          submit: {
            icon: <Check />,
            onClick: (id, row) => {
              restApiClient.item
                .updatePrice(id, row.newValue ?? row.lastEntry.price)
                .then((result) => {
                  setRows({
                    count: rows.count - 1,
                    values: rows.values.filter((item) => item.id !== id),
                  });
                });
            },
            allowedActions: [Item.actions.updatePrice],
          },
        }}
        customCellRenderers={{
          newValue: {
            renderer: (id, value, column, row) => {
              return (
                <StyledMoneyInput
                  value={value ?? row.lastEntry.price}
                  onChange={(e, value, maskedValue) => {
                    var crrRow = rows.values.find((x) => x.id === row.id);
                    crrRow[id] = value;
                    setRows({
                      count: rows.count,
                      values: [...rows.values],
                    });
                  }}
                />
              );
            },
          },
          'product.price': {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          lastEntry: {
            renderer: (field, value) => {
              return value.price.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
    </Container>
  );
}

export default PricePendingItemList;
