import React from 'react';

import CollapseList from '../collapseList';
import SecuredItem from '../securedItem';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

function SecuredCollapseList({ allowedRoles = [], ...props }) {
  const undefinedMerge = (obj1, obj2) => {
    if(!obj1 && !obj2) {
      return undefined;
    } 
    else if(isEmpty(obj1) || isEmpty(obj2)) {
      return {};
    }
    else {
      return merge(obj1, obj2);
    }
  };
  const getPermissionRecursive = (children) =>
    Array.isArray(children)
      ? children.reduce(
          (arr, value) =>
            value.props.allowedActions
              ? arr.concat(value.props.allowedActions)
              : value.props.children
              ? arr.concat(getPermissionRecursive(value.props.children))
              : arr,
          []
        )
      : children.props.allowedActions
      ? children.props.allowedActions
      : children.props.children
      ? getPermissionRecursive(children.props.children)
      : [];
  const getDentistPermissionRecursive = (children) =>
    Array.isArray(children)
      ? children.reduce(
          (allowDentist, value) =>
            value.props.allowDentist
              ? undefinedMerge(allowDentist, value.props.allowDentist)
              : value.props.children
              ? undefinedMerge(allowDentist, getDentistPermissionRecursive(value.props.children))
              : allowDentist,
          undefined
        )
      : children.props.allowDentist
      ? children.props.allowDentist
      : children.props.children
      ? getDentistPermissionRecursive(children.props.children)
      : undefined;
  
  return (
    <SecuredItem
      allowedRoles={allowedRoles}
      allowedActions={getPermissionRecursive(props.children)}
      allowDentist={getDentistPermissionRecursive(props.children)}
    >
      <CollapseList {...props}>{props.children}</CollapseList>
    </SecuredItem>
  );
}

export default SecuredCollapseList;
