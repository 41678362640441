const Dentist = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com um nome',
        customErrors: '',
      },
      login: {
        type: 'string',
        format: 'login',
        upperCased: false,
        minLength: 2,
        maxLength: 32,
        customErrors: '',
      },
      email: {
        type: 'string',
        format: 'email',
        customErrors: '',
      },
      cpf: {
        type: 'string',
        mask: '999.999.999-99',
        minLength: 11,
        maxLength: 14,
        customErrors: '',
        cpf: '',
      },
      rg: {
        type: 'string',
        customErrors: '',
        minLength: 5,
        maxLength: 14,
      },
      croNumber: {
        type: 'string',
        customErrors: '',
        minLength: 3,
        maxLength: 50,
      },
      phoneNumber: {
        type: 'string',
        mask: '(99) 9999-9999',
        maskSelector: (value) => {
          const len = value.replace(/[\W_]/g, '').length;
          if (len > 10) return '(99) 99999-9999';
          else return '(99) 9999-9999';
        },
        customErrors: '',
        phone: '',
      },
      scheduleTypes: {
        type: 'array',
        items: {
          type: 'string',
          enum: ['Procedimentos', 'Hora', 'Fixo', 'Pacientes'],
        },
      },
      receivement: {
        enum: [
          'DINHEIRO',
          'DEPÓSITO BANCÁRIO',
        ],
        customErrors: '',
      },
      dentistBankAccounts: {
        type: 'object',
        properties: {
          holderName: {
            type: 'string',
            customErrors: '',
          },
          bank: {
            type: 'string',
            customErrors: '',
          },
          agency: {
            type: 'string',
            customErrors: '',
          },
          agencyDigit: {
            type: 'string',
            customErrors: '',
            description: 'Dígito da agência, caso não tenha deixe vazio!',
          },
          account: {
            type: 'string',
            customErrors: '',
          },
          accountDigit: {
            type: 'string',
            customErrors: '',
            description: 'Dígito da conta, caso não tenha deixe vazio!',
          },
        },
        customErrors: '',
      },
    },
    required: ['name', 'login', 'cpf', 'rg', 'croNumber', 'phoneNumber'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Dentista',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Login',
            scope: '#/properties/login',
          },
          {
            type: 'Control',
            label: 'Telefone',
            scope: '#/properties/phoneNumber',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Email',
            scope: '#/properties/email',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'CPF',
            scope: '#/properties/cpf',
          },
          {
            type: 'Control',
            label: 'RG',
            scope: '#/properties/rg',
          },
          {
            type: 'Control',
            label: 'Número CRO',
            scope: '#/properties/croNumber',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tipo de Agenda',
            scope: '#/properties/scheduleTypes',
          },
        ]
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Recebimento',
            scope: '#/properties/receivement',
          },
        ],
      },
      {
        type: 'Group',
        elements: [
          {
            label: {
              text: 'Dados Bancários',
            },
            type: 'Control',
            scope: '#/properties/dentistBankAccounts',
            options: {
              detail: {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: 'Titular',
                    scope: '#/properties/holderName',
                  },
                  {
                    type: 'Control',
                    label: 'Banco',
                    scope: '#/properties/bank',
                  },
                  {
                    type: 'Control',
                    label: 'Conta',
                    scope: '#/properties/account',
                  },
                  {
                    type: 'Control',
                    label: 'Dígito da Conta',
                    scope: '#/properties/accountDigit',
                  },
                  {
                    type: 'Control',
                    label: 'Agência',
                    scope: '#/properties/agency',
                  },
                  {
                    type: 'Control',
                    label: 'Dígito da Agência',
                    scope: '#/properties/agencyDigit',
                  },
                ],
              },
            },
          },
        ],
        rule: {
          effect: 'SHOW',
          condition: {
            scope: '#/properties/receivement',
            schema: {
              const: 'DEPÓSITO BANCÁRIO',
            },
          },
        },
      },
    ],
  },
  tableSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'croNumber', label: 'Número CRO', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'DentistController@getAll',
    findById: 'DentistController@findById',
    findByCpf: 'DentistController@findByCpf',
    create: 'DentistController@create',
    update: 'DentistController@patchUpdate',
    delete: 'DentistController@delete',
    resetPassword: 'DentistController@resetPassword',
  },
  dentistProcedurePriceActions: {
    getAllByDentistAndUnit: 'DentistProcedurePriceController@getAllByDentistAndUnit',
  },
  data: {
    receivement: undefined
  },
};

export default Dentist;
