import styled from 'styled-components';
import MaterialButton from '@material-ui/core/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import MaterialDialogContent from '@material-ui/core/DialogContent';
import MaterialFormHelperText from '@material-ui/core/FormHelperText';
import CrudTable from '../crudTable';
import {
  GetApp as MaterialAddBox,
  MoneyCheck as MaterialMoneyCheck,
} from '../../styles/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Button = styled(MaterialButton)``;

export const Dialog = styled(MaterialDialog)``;

export const DialogActions = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between !important;
`;

export const DialogContent = styled(MaterialDialogContent)``;

export const AddBox = styled(MaterialAddBox)`
  width: 20px;
  height: 20px;
  fill: #808080;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-top: 10px;
  margin-bottom: 5px;
  width: 98%;
`;

export const FormHelperText = styled(MaterialFormHelperText)``;

export const MoneyCheck = styled(MaterialMoneyCheck)`
  width: 20px;
  height: 20px;
  fill: #808080;
`;
