import styled from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';
import {
  Preview as MaterialPreview,
  Send as MaterialSend,
  ClipboardList as MaterialClipboardList,
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const BackButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ClipboardList = styled(MaterialClipboardList)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;
