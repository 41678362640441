import React from 'react';
import Treatment from '../../../../forms/treatment';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { Container } from './styles';

function TreatmentCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Treatment}
        restGetDataById={restApiClient.treatment.findById}
        restCreateData={restApiClient.treatment.create}
        restUpdateData={restApiClient.treatment.update}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default TreatmentCreateUpdate;
