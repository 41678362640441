import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  Cancel as MaterialCancel,
} from '../../../../styles/icons';
import { Send as MaterialSend } from '@styled-icons/material/Send';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Cancel = styled(MaterialCancel)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
