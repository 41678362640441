import styled from 'styled-components';
import {
  Button as MaterialButton,
  Typography as MaterialTypography,
} from '@material-ui/core';
import {
  SendClock as MaterialSendClock,
  AssignmentReturn as MaterialAssignmentReturn,
  ViewShow as MaterialViewShow,
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SendClock = styled(MaterialSendClock)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AssignmentReturn = styled(MaterialAssignmentReturn)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ViewShow = styled(MaterialViewShow)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const AutoDiv = styled.div`
  width: auto;
  margin-right: auto;
  margin-left: 15px;
`;

export const StyledTypography = styled(MaterialTypography)`
  line-height: 1.25 !important;
`;
