import React, { useState, useEffect } from 'react';
import Item from '../../../forms/item';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import {
  Container,
  SelectContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from './styles';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

function SearchItemList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Item.shoppingGroupSearchTableSchema;

  async function loadItems() {
    restApiClient.item
      .getAllWithSaleAvg(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('brand', searchBy)
              .or()
              .like('barcode.barcode', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }

  useEffect(() => {
    loadItems();
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Busca de Itens'}
        modelActions={Item.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          barcode: {
            renderer: (field, value) => {
              return !isEmpty(value)
                ? value.map((item) => item.barcode).join(', ')
                : '-';
            },
          },
          'stock.availableQuantity': {
            renderer: (field, value) => {
              return value ?? 0;
            },
          },
          salesAvg: {
            renderer: (field, value) => {
              const crrValue = parseFloat(value);
              if (!isNaN(crrValue)) return crrValue.toFixed(2);
              else return '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default SearchItemList;
