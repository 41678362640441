import React, { useState, useEffect } from 'react';
import Role from '../../../forms/role';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, UserShield } from './style';

function RoleList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Role.tableSchema;

  useEffect(() => {
    restApiClient.role
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('name', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Cargos'}
        modelActions={Role.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: { onClick: () => history.push('/role/create') },
          update: {
            onClick: (roleId) => history.push(`/role/update/${roleId}`),
          },
          delete: {
            onClick: (roleId, index) => {
              restApiClient.role.delete(roleId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
          link: {
            icon: <UserShield />,
            title: 'Vincular Permissões',
            onClick: (roleId) => history.push(`/role/${roleId}/action/link`),
            allowedActions: [
              [
                Role.actions.getAllSystemActions,
                Role.actions.findById,
                Role.actions.update,
              ],
            ],
          },
        }}
      />
    </Container>
  );
}

export default RoleList;
