import React from 'react';
import Substitute from '../../../../forms/substitute';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { 
  Container,
  BackButton, 
  FooterContainer
} from './styles';

function SubstituteCreateUpdate(props) {
  const readonly = props.location?.state?.readonly ?? false;
  
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Substitute}
        uischemaSelector={(modelSchema, modelId) => {
          return readonly ?
            modelSchema.uischemaAlternative :
            modelId ? modelSchema.updateUischema : modelSchema.uischema;
        }}
        restGetDataById={restApiClient.substitute.findById}
        restCreateData={restApiClient.substitute.create}
        restUpdateData={ async (modelId, originalData, modelData) => {
          delete originalData.requestingUnitBoolean;
          delete modelData.requestingUnitBoolean;

          const result = await restApiClient.substitute.update(modelId,
            originalData, modelData);
          
          return result;
        }}
        onCreateSuccess={() => {
          props.history.goBack();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        customButtonsBuilder={
          readonly ?
            () => (
              <>
                <FooterContainer>
                  <BackButton
                    onClick={() => props.history.goBack()}
                    type="button"
                    variant="contained"
                    color="primary"
                  >
                    Voltar
                  </BackButton>
                </FooterContainer>
              </>
            ) : null
        }
        {...props}
      />
    </Container>
  );
}

export default SubstituteCreateUpdate;
