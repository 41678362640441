import AJV from 'ajv';
import { localize_pt_BR } from '../i18n';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import telefone from 'telefone';
import stateRegistryValidator from 'validate_ie';
import stateRegistryMask from 'br-masks';
import restApiClient from '../../services/restApiClient';
import viaCepClient from '../../services/viaCepClient';
import moment from 'moment';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

class Validator extends AJV {
  static cache = {};
  static asyncUpdate = undefined;

  compile(schema) {
    const handler = {
      apply: (target, thisArg, argumentsList) => {
        let result = target(...argumentsList);
        localize_pt_BR(target.errors);
        return result;
      },
    };
    const validator = super.compile(schema);
    let newValidator = new Proxy(validator, handler);

    return newValidator;
  }

  addCustomFormats() {
    this.addFormat('select', '');
    this.addFormat('selectItemDialog', '');
    this.addFormat('selectGroupDialog', '');
    this.addFormat('selectSectionDialog', '');
    this.addFormat('selectUnitDialog', '');
    this.addFormat('selectClinicDialog', '');
    this.addFormat('selectBuyerDialog', '');
    this.addFormat('selectLabEmployeeDialog', '');
    this.addFormat('selectCityDialog', '');
    this.addFormat('selectStepDialog', '');
    this.addFormat('selectEndProductDialog', '');
    this.addFormat('selectToothNumber', '');
    this.addFormat('selectToothShadeDialog', '');
    this.addFormat('selectOriginDialog', '');
    this.addFormat('selectTreatmentDialog', '');
    this.addFormat('selectExecutorDialog', '');
    this.addFormat('selectAppointmentDialog', '');
    this.addFormat('selectSpecialtyDialog', '');
    this.addFormat('selectDentist', '');
    this.addFormat('selectStatus', '');
    this.addFormat('selectColorPicker', '');
    this.addFormat('selectProcedureDialog', '');
    this.addFormat('login', '^[A-Za-z0-9._-]*$');
    this.addFormat('money', '');
    this.addFormat('percentage', '');
    this.addFormat('float', '');
    this.addFormat('time', '');
    this.addFormat('customEnum', '');
    this.addFormat('customAttendanceDate', '');
    this.addFormat('selectEmployeeDialog', '');
    this.addFormat('selectPatientDialog', '');
    this.addFormat('selectServiceSupplierDialog', '');
    this.addFormat('selectSupplierDialog', '');
    this.addFormat('selectAnySupplierDialog', '');
    this.addFormat('selectOutsourcedDialog', '');
    this.addFormat('selectExpenseTypeDialog', '');
    this.addFormat('selectPaymentTypeDialog', '');
    this.addFormat('selectSectorDialog', '');
    this.addFormat('selectUnitFinanceDialog', '');
    this.addFormat('selectDentistSpecialtyDialog', '');
  }

  addCustomKeywords(customErrors) {
    this.addKeyword('customErrors', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const [, value, schema, fullPathName, , fieldName, fullModel] =
          arguments;

        let baseFieldName = first(
          fullPathName.replace('/', ' ').trim().split('/')
        );
        if (!customErrors || !(baseFieldName in customErrors)) return true;

        isValid.errors = [];
        const pathName = fullPathName.replace(`/${baseFieldName}`, '');
        if (
          baseFieldName === fieldName &&
          schema.type === 'array' &&
          isArray(value)
        ) {
          if (
            isArray(customErrors[baseFieldName]) &&
            isString(first(customErrors[baseFieldName]))
          ) {
            isValid.errors.push({
              keyword: 'customErrors',
              message: customErrors[fieldName][0],
              params: {
                keyword: 'customErrors',
              },
            });
          } else {
            Object.entries(customErrors[baseFieldName]).forEach(
              ([index, modelErrors]) => {
                if (index < value.length) {
                  Object.entries(modelErrors).forEach(
                    ([errorField, errors]) => {
                      if (!(errorField in value)) {
                        fullModel[baseFieldName][index][errorField] = '';
                      }
                    }
                  );
                }
              }
            );
          }
        } else if (pathName.length > 0) {
          const [index] = pathName.slice(1).split('/');
          if (
            typeof customErrors[baseFieldName] === 'object' &&
            index in customErrors[baseFieldName] &&
            typeof customErrors[baseFieldName][index] === 'object' &&
            fieldName in customErrors[baseFieldName][index]
          ) {
            isValid.errors.push({
              keyword: 'customErrors',
              message: customErrors[baseFieldName][index][fieldName],
              params: {
                keyword: 'customErrors',
              },
            });
          }
        } else {
          isValid.errors.push({
            keyword: 'customErrors',
            message: customErrors[fieldName][0],
            params: {
              keyword: 'customErrors',
            },
          });
        }
        return false;
      },
    });
    this.addKeyword('cpf', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        const crrCpf = args[1]?.replace(/\D/g, '');
        if (crrCpf?.length >= 11 && crrCpf?.length <= 14) {
          if (cpf.isValid(args[1])) {
            return true;
          }
          isValid.errors = [];
          isValid.errors.push({
            keyword: 'cpf',
            message: 'O cpf inserido é inválido',
            params: {
              keyword: 'cpf',
            },
          });
          return false;
        }
        return true;
      },
    });
    this.addKeyword('cpfDentist', {
      errors: true,
      modifying: false,
      validate: function isValid() {
        const args = arguments;
        const cpfCurrent = args[1]?.replace(/\D/g, '');
        args[4][args[5]] = cpfCurrent;
        if (cpfCurrent?.length === 11) {
          if (cpf.isValid(cpfCurrent)) {
            if (Validator.cache['cpf']?.cpf.replace('-', '') !== cpfCurrent) {
              Validator.cache['cpf'] = { cpf: cpfCurrent };
              restApiClient.dentist.findByCpf(args[1]).then((r) => {
                Validator.cache['cpf'] = r.data;
                args[4]['asyncModelDataUpdate'] = true;
                if (r.data.name)
                  args[4]['socialName'] = r.data.name.toUpperCase();
                if (r.data?.email)
                  args[4]['email'] = r.data.email.toUpperCase();
                if (r.data?.phoneNumber)
                  args[4]['phoneNumber'] = r.data.phoneNumber.toUpperCase();
                if (r.data?.id) args[4]['dentistId'] = r.data.id;
                Validator.asyncUpdate(args[4]);
              });
            } else {
              const result = Validator.cache['cpf'];
              if (result.name && args[4]['socialName'] == null)
                args[4]['socialName'] = result.name.toUpperCase();
              if (result?.email && args[4]['email'] == null)
                args[4]['email'] = result.email.toUpperCase();
              if (result?.phoneNumber && args[4]['phoneNumber'] == null)
                args[4]['phoneNumber'] = result.phoneNumber.toUpperCase();
              if (result?.id && args[4]['dentistId'] == null)
                args[4]['dentistId'] = result.id;
            }
          } else {
            isValid.errors = [];
            isValid.errors.push({
              keyword: 'cpf',
              message: 'O cpf inserido é inválido',
              params: {
                keyword: 'cpf',
              },
            });
            return false;
          }
        }
        return true;
      },
    });
    this.addKeyword('recordNumber', {
      errors: true,
      modifying: false,
      validate: function isValid() {
        const args = arguments;
        if (args[1]) {
          if (Validator.cache['recordNumber'] !== args[1]) {
            if (Validator.cache['recordNumberTimeOut'])
              clearTimeout(Validator.cache['recordNumberTimeOut']);
            args[4]['patientId'] = undefined;
            args[4]['patientLoaded'] = undefined;
            const crrSearchTimeout = setTimeout(() => {
              const unitId =
                args[4]['requestingUnit'] ??
                args[4]['userUnit'] ??
                args[4]['unitId'] ??
                null;
              restApiClient.patient
                .findByRecordNumber(args[1], unitId)
                .then((r) => {
                  if (r.data.name) args[4]['name'] = r.data.name.toUpperCase();
                  if (r.data.birthDate) args[4]['birthDate'] = r.data.birthDate;
                  if (r.data.sex) args[4]['sex'] = r.data.sex.toUpperCase();
                  if (r.data.phoneNumber)
                    args[4]['phoneNumber'] = r.data.phoneNumber;
                  if (r.data.id) args[4]['patientId'] = r.data.id;
                  if (r.data.unitId) args[4]['unitId'] = r.data.unitId;
                })
                .finally(() => {
                  args[4]['patientLoaded'] = true;
                  Validator.asyncUpdate(args[4]);
                });
            }, 700);
            Validator.cache['recordNumberTimeOut'] = crrSearchTimeout;
            Validator.cache['recordNumber'] = args[1];
          } else {
            const result = Validator.cache['recordNumber'];
            if (result.name && args[4]['name'] == null) {
              args[4]['name'] = result.name.toUpperCase();
            }
            if (result.birthDate && args[4]['birthDate'] == null) {
              args[4]['birthDate'] = result.birthDate;
            }
            if (result.sex && args[4]['sex'] == null) {
              args[4]['sex'] = result.sex.toUpperCase();
            }
            if (result.phoneNumber && args[4]['phoneNumber'] == null) {
              args[4]['phoneNumber'] = result.phoneNumber;
            }
            if (result.unitId && args[4]['unitId'] == null) {
              args[4]['unitId'] = result.unitId;
            }
          }
        }
        return true;
      },
    });
    this.addKeyword('handleAsyncChanges', {
      errors: true,
      validate: function isValid() {
        const args = arguments;
        const func = args[0];
        if (args[1] && Validator.cache[args[3]] !== args[1]) {
          func(args[4], Validator);
          Validator.cache[args[3]] = args[1];
        }
        return true;
      },
    });
    this.addKeyword('cnpj', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        const crrCnpj = args[1]?.replace(/\D/g, '');
        if (crrCnpj?.length >= 14 && crrCnpj?.length <= 18) {
          if (cnpj.isValid(args[1])) {
            return true;
          }
          isValid.errors = [];
          isValid.errors.push({
            keyword: 'cnpj',
            message: 'O CNPJ inserido é inválido',
            params: {
              keyword: 'cnpj',
            },
          });
          return false;
        }
        return true;
      },
    });
    this.addKeyword('phone', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        args[4][args[5]] = args[1]?.replace(/\D/g, '');

        if (args[1]?.replace(/\D/g, '') != null) {
          if (telefone.parse(args[1])) {
            return true;
          }
          isValid.errors = [];
          isValid.errors.push({
            keyword: 'phone',
            message: 'O telefone inserido é inválido',
            params: {
              keyword: 'phone',
            },
          });
          return false;
        }
      },
    });
    this.addKeyword('cep', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        const cep = args[1]?.replace(/\D/g, '');
        if (cep.length === 8 && !Validator.cache['fetchingCep']) {
          if (Validator.cache['cep']?.cep?.replace('-', '') !== cep) {
            Validator.cache['fetchingCep'] = true;
            Validator.cache['errorCep'] = false;
            Validator.cache['cep'] = { cep };
            const completeObj = args[6];
            let toUpdate = args[6];
            const path = args[3]?.slice(1).split('/').slice(0, -1);
            toUpdate = path.reduce((acc, value) => acc[value], toUpdate);
            viaCepClient
              .find(cep)
              .then((result) => {
                if (result.erro) {
                  Validator.cache['errorCep'] = true;
                } else {
                  Validator.cache['cep'] = result;
                  if (result.logradouro)
                    toUpdate['address'] = result.logradouro.toUpperCase();
                  if (result.bairro)
                    toUpdate['neighborhood'] = result.bairro.toUpperCase();
                  if (result.localidade)
                    toUpdate['city'] = result.localidade.toUpperCase();
                  if (result.uf) toUpdate['state'] = result.uf.toUpperCase();
                }
                Validator.asyncUpdate(completeObj);
                Validator.cache['fetchingCep'] = false;
              })
              .catch((error) => {
                Validator.cache['fetchingCep'] = false;
              });
          } else if (Validator.cache['errorCep']) {
            isValid.errors = [];
            isValid.errors.push({
              keyword: 'cep',
              message: 'O cep inserido é inválido',
              params: {
                keyword: 'cep',
              },
            });
            return false;
          }
        }
        return true;
      },
    });
    this.addKeyword('stateRegistry', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        args[4][args[5]] = args[1]?.replace(/\D/g, '');
        if (stateRegistryValidator(args[1] ?? '', args[4].state)) {
          args[4][args[5]] = stateRegistryMask.ie(args[1], args[4].state);
          return true;
        }
        isValid.errors = [];
        isValid.errors.push({
          keyword: 'state',
          message: 'A Inscrição Estadual inserida é inválida',
          params: {
            keyword: 'state',
          },
        });
        return false;
      },
    });

    this.addKeyword('beginInterval', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        isValid.errors = [];

        if (
          !moment(args[1], 'HH:mm').isBefore(
            moment(args[4].endInterval, 'HH:mm')
          )
        ) {
          isValid.errors.push({
            keyword: args[3],
            message: 'Início deve ser menor que fim',
            params: {
              keyword: args[3],
            },
          });
          return false;
        }

        if (!isEmpty(args[6]?.intervals)) {
          const intervals = args[6]?.intervals.map((x) => ({
            beginInterval: moment(x.beginInterval, 'HH:mm'),
            endInterval: moment(x.endInterval, 'HH:mm'),
          }));

          intervals.forEach((obj1) => {
            if (
              obj1.beginInterval.isBefore(moment(args[1], 'HH:mm')) &&
              moment(args[1], 'HH:mm').isBefore(obj1.endInterval)
            ) {
              isValid.errors.push({
                keyword: args[3],
                message: 'Horário conflitante',
                params: {
                  keyword: args[3],
                },
              });
              return false;
            }
          });
        }
      },
    });

    this.addKeyword('endInterval', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        isValid.errors = [];

        if (
          !moment(args[4].beginInterval, 'HH:mm').isBefore(
            moment(args[1], 'HH:mm')
          )
        ) {
          isValid.errors.push({
            keyword: args[3],
            message: 'Fim deve ser maior que início',
            params: {
              keyword: args[3],
            },
          });
          return false;
        }

        if (!isEmpty(args[6]?.intervals)) {
          const intervals = args[6]?.intervals.map((x) => ({
            beginInterval: moment(x.beginInterval, 'HH:mm'),
            endInterval: moment(x.endInterval, 'HH:mm'),
          }));

          intervals.forEach((obj1) => {
            if (
              obj1.beginInterval.isBefore(moment(args[1], 'HH:mm')) &&
              moment(args[1], 'HH:mm').isBefore(obj1.endInterval)
            ) {
              isValid.errors.push({
                keyword: args[3],
                message: 'Horário conflitante',
                params: {
                  keyword: args[3],
                },
              });
              return false;
            }
          });
        }
      },
    });

    this.addKeyword('beginTime', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        isValid.errors = [];

        if (
          args[4].endTime &&
          !moment(args[1], 'HH:mm').isBefore(moment(args[4].endTime, 'HH:mm'))
        ) {
          isValid.errors.push({
            keyword: args[3],
            message: 'Início deve ser menor que fim',
            params: {
              keyword: args[3],
            },
          });
          return false;
        }
      },
    });

    this.addKeyword('endTime', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        isValid.errors = [];

        if (
          args[4].beginTime &&
          !moment(args[4].beginTime, 'HH:mm').isBefore(moment(args[1], 'HH:mm'))
        ) {
          isValid.errors.push({
            keyword: args[3],
            message: 'Fim deve ser maior que início',
            params: {
              keyword: args[3],
            },
          });
          return false;
        }
      },
    });

    this.addKeyword('beforeToday', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const args = arguments;
        isValid.errors = [];

        if (!moment(yesterday).isAfter(moment(args[1]))) {
          isValid.errors.push({
            keyword: args[3],
            message: `A data deve ser menor do que ${dayjs(
              moment(yesterday)
            ).format('DD/MM/YYYY')}.`,
            params: {
              keyword: args[3],
            },
          });

          return false;
        }
      },
    });

    this.addKeyword('beginDate', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        isValid.errors = [];

        if (
          args[4].endDate &&
          !moment(args[1]).isSameOrBefore(moment(args[4].endDate))
        ) {
          isValid.errors.push({
            keyword: args[3],
            message: 'Início deve ser menor que fim',
            params: {
              keyword: args[3],
            },
          });
          return false;
        }
      },
    });

    this.addKeyword('endDate', {
      errors: true,
      modifying: true,
      validate: function isValid() {
        const args = arguments;
        isValid.errors = [];

        if (
          args[4].beginDate &&
          !moment(args[4].beginDate).isSameOrBefore(moment(args[1]))
        ) {
          isValid.errors.push({
            keyword: args[3],
            message: 'Fim deve ser maior que início',
            params: {
              keyword: args[3],
            },
          });
          return false;
        }
      },
    });
  }
}

export default Validator;
