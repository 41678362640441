/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  AddShoppingCart,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
} from './styles';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import TransferType from '../../forms/transferType';

const TransferTypeDialog = ({ open, onSuccess, onCancel, user, ...props }) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const columns = TransferType.tableSchema;

  useEffect(() => {
    restApiClient.transferType
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onCancel}
    >
      <DialogContent>
        <StyledCrudTable
          title={'Seleção de Tipo de Transferência'}
          modelActions={TransferType.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[3]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderby(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderby(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            insert: {
              icon: <AddShoppingCart />,
              onClick: (id) => {
                onSuccess?.(id, user);
              },
              title: 'Selecionar',
              allowedActions: [TransferType.actions.getAll],
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferTypeDialog;
