import React, { useState, useCallback, useEffect } from 'react';
import Attendance from '../../../../../forms/attendance';
import Substitute from '../../../../../forms/substitute';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, FileEarmarkMedicalFill } from './styles';
import moment from 'moment';

function ReplacementsAuditList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const columns = Attendance.replacementsTableSchema;

  const loadAttendances = useCallback(() => {
    restApiClient.attendance
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .and((leftChild, rightChild) => {
                leftChild
                  .like('status', 'Sob Auditoria')
                  .like('type', 'Substituição');
                rightChild
                  .like('id', searchBy)
                  .or()
                  .like('type', searchBy)
                  .or()
                  .like('patient.name', searchBy)
                  .or()
                  .like('procedure.name', searchBy)
                  .or()
                  .like('unit.name', searchBy)
                  .or()
                  .like('status', searchBy);
              })
              .toString()
          : new FilterTree()
              .like('status', 'Sob Auditoria')
              .notNull('replacedDentistId')
              .toString(),
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadAttendances();
  }, [loadAttendances]);

  return (
    <Container>
      <CrudTable
        title={'Auditar Substituições'}
        modelActions={Attendance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          audit: {
            icon: <FileEarmarkMedicalFill />,
            onClick: (id) => {
              history.push({
                pathname: `/dentist/audit/replacement/procedure/${id}`,
                state: { readonly: true },
              });
            },
            title: 'Auditar Procedimentos',
            allowedActions: [
              [
                Attendance.actions.findById,
                Attendance.actions.setApproved,
                Attendance.actions.setDisapproved,
                Substitute.actions.findById,
                Substitute.actions.getAllByReplacement,
              ],
            ],
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.history) {
            return '#ffe0e0';
          }
        }}
      />
    </Container>
  );
}

export default ReplacementsAuditList;
