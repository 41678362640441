import React, { useState } from 'react';
import Item from '../../../forms/item';
import CreateUpdateForm from '../../../components/createUpdateForm';
import restApiClient from '../../../services/restApiClient';
import { Container } from './styles';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// TODO: Acessar permissionamento de Fornecedores e Categorias

function ItemCreateUpdate(props) {
  const [errorMessage, setErrorMessage] = useState('');

  const handleAlertClose = (event, reason) => {
    setErrorMessage('');
  };

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Item}
        restGetDataById={async (id) => {
          const result = await restApiClient.item.findById(id);
          result.data.rawBarcode = result.data.barcode;
          return result;
        }}
        restCreateData={async (item) => {
          if (item.hasRegistry) {
            item.price = item.price * item.multiplyFactor;
          }
          const result = await restApiClient.item.create(item);
          return result;
        }}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          const added = modelData.barcode
            .filter(
              (item) =>
                !updateOriginalData.rawBarcode.some(
                  (obj) => obj.barcode === item.barcode
                )
            )
            .map((item) => item.barcode);

          const removed = updateOriginalData.rawBarcode
            .filter(
              (item) =>
                !modelData.barcode.some((obj) => obj.barcode === item.barcode)
            )
            .map((item) => item.id);

          delete updateOriginalData.rawBarcode;
          delete updateOriginalData.barcode;
          delete updateOriginalData.hasRegistry;

          delete modelData.rawBarcode;
          delete modelData.barcode;
          delete modelData.hasRegistry;

          const result = await restApiClient.item.update(
            modelId,
            updateOriginalData,
            modelData,
            added,
            removed
          );
          return result;
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        onChange={(data) => {
          if (!data.hasRegistry && data.sonId) {
            data.sonId = undefined;
            data.brand = undefined;
            data.groupId = undefined;
            data.price = undefined;
          }
        }}
        mapCustomErrors={(errors, modelData) => {
          const barcode = {};
          barcode[
            modelData.barcode.findIndex(
              (x) => x.barcode === errors.barcode.model.barcode
            )
          ] = { barcode: ['Código de barras cadastrado em outro item.'] };
          return { ...errors, barcode };
        }}
        {...props}
      />
      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ItemCreateUpdate;
