/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { isEmpty } from 'lodash';
import {
  Container,
  ActionsContainer,
  SubmitButton,
  CancelButton,
  DialogContent,
  StyledMoneyInput,
  Card,
  FormDiv,
  Grid,
  CardHeader,
  HeaderContainer,
  DialogActions,
  Input,
} from './styles';
import restApiClient from '../../services/restApiClient';

function ConfirmationOutsourcedStepPriceDialog({
  onSuccess,
  onError,
  onCancel,
  outsourced,
  step,
  ...props
}) {
  const [price, setPrice] = useState(0);
  const [outsourcedStepPrice, setOutsourcedStepPrice] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (price > 0) {
      if (!isEmpty(outsourcedStepPrice)) {
        restApiClient.outsourcedStepPrice
          .update(outsourcedStepPrice.id, outsourcedStepPrice, {
            ...outsourcedStepPrice,
            price: price,
          })
          .then(async (result) => {
            onSuccess?.(result);
          })
          .catch((e) => {
            if (e.response?.status === 400) {
              onError?.(e.response);
              setErrors(e.response.data.errors);
            }
          });
      } else {
        restApiClient.outsourcedStepPrice
          .create({
            outsourcedId: outsourced.addressId,
            stepId: step?.id,
            price: price,
          })
          .then(async (result) => {
            onSuccess?.(result);
          })
          .catch((e) => {
            if (e.response?.status === 400) {
              onError?.(e.response);
              setErrors(e.response.data.errors);
            }
          });
      }
    } else {
      setErrors({
        price: ['O campo deve ser maior que 0.'],
      });
    }
  };

  useEffect(() => {
    if (props.open) {
      restApiClient.outsourcedStepPrice
        .findByStepAndOutsourced(step?.id, outsourced?.addressId)
        .then((e) => {
          setOutsourcedStepPrice(e.data);
          setPrice(e.data.price);
        })
        .catch((e) => setOutsourcedStepPrice(null));
    }
  }, [props.open]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Confirmação de Preço" />
              </HeaderContainer>
              <FormDiv>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Input
                      disabled
                      type="text"
                      label="Executor"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        outsourced?.isPhysicalPerson
                          ? outsourced?.socialName
                          : outsourced?.fantasyName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Input
                      disabled
                      type="text"
                      label="Etapa"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={step?.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <StyledMoneyInput
                      required
                      label="Preço do serviço"
                      value={price}
                      error={!isEmpty(errors.price)}
                      helperText={
                        !isEmpty(errors.price) ? errors.price.join(' ') : ''
                      }
                      onChange={(e, value) => {
                        setErrors({});
                        setPrice(value);
                      }}
                    />
                  </Grid>
                </Grid>
              </FormDiv>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setErrors({});
                    setPrice(0);
                    onCancel?.();
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Confirmar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </Container>
  );
}

export default ConfirmationOutsourcedStepPriceDialog;
