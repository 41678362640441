import React from 'react';
import Substitute from '../../../../../forms/substitute';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container, BackButton, FooterContainer } from './styles';
import { useSelector } from 'react-redux';

function SubstituteCreateUpdate(props) {
  const employeeUnit = useSelector((x) => x.authReducer.user?.unitId ?? -1);
  const readonly = props.location?.state?.readonly ?? false;
  const isClinic = props.location?.state?.isClinic ?? false;

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Substitute}
        uischemaSelector={(modelSchema, modelId) => {
          return readonly
            ? modelSchema.uischemaAlternative
            : modelId
            ? modelSchema.updateUischema
            : modelSchema.uischema;
        }}
        restGetDataById={restApiClient.substitute.findById}
        restCreateData={restApiClient.substitute.create}
        restUpdateData={async (modelId, originalData, modelData) => {
          delete originalData.requestingUnitBoolean;
          delete modelData.requestingUnitBoolean;
          delete originalData.isClinic;
          delete modelData.isClinic;

          const result = await restApiClient.substitute.update(
            modelId,
            originalData,
            modelData
          );

          return result;
        }}
        onCreateSuccess={() => {
          props.history.goBack();
        }}
        onUpdateSuccess={() => {
          props.history.goBack();
        }}
        modelData={{
          isClinic,
          unitId: employeeUnit,
        }}
        customButtonsBuilder={
          readonly
            ? () => (
                <>
                  <FooterContainer>
                    <BackButton
                      onClick={() => props.history.goBack()}
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      Voltar
                    </BackButton>
                  </FooterContainer>
                </>
              )
            : null
        }
        {...props}
      />
    </Container>
  );
}

export default SubstituteCreateUpdate;
