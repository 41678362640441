import styled from 'styled-components';
import MoneyInput from '../moneyInput';
import {
  Button,
  Card as MaterialCard,
  CardHeader as MaterialCardHeader,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogTitle as MaterialDialogTitle,
  FormControl as MaterialFormControl,
  FormControlLabel as MaterialFormControlLabel,
  FormLabel as MaterialFormLabel,
  Radio as MaterialRadio,
  RadioGroup as MaterialRadioGroup,
  TextField as MaterialTextField,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';
import SelectUnitFinanceDialog from '../selectUnitFinanceDialogControl';
import SelectItemizedCheckDialog from '../selectItemizedCheckDialogControl';

export const CustomMuiPickersUtilsProvider = styled(
  MaterialMuiPickersUtilsProvider
)``;

export const CustomKeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  width: 100% !important;
  margin: 3px 0 5px 0 !important;
`;

export const SubmitButton = styled(Button)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(Button)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Container = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Dialog = styled(MaterialDialog)``;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  width: 400px !important;
  padding: 10px !important;
`;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 0 !important;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px 10px 10px;
`;

export const CardHeader = styled(MaterialCardHeader)``;

export const FormControl = styled(MaterialFormControl)``;

export const FormLabel = styled(MaterialFormLabel)``;

export const FormControlLabel = styled(MaterialFormControlLabel)``;

export const Radio = styled(MaterialRadio)``;

export const RadioGroup = styled(MaterialRadioGroup)``;

export const InputTextarea = styled(MaterialTextField)`
  width: 100%;
  color: white;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  margin-top: 3px !important;
  margin-bottom: 20px !important;
`;

export const SelectUnitFinance = styled(SelectUnitFinanceDialog)`
  margin: 3px !important;
  width: 100%;
`;

export const SelectItemizedCheck = styled(SelectItemizedCheckDialog)`
  margin: 3px !important;
  width: 100%;
`;
