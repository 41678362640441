import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import {
  withJsonFormsCellProps,
  withJsonFormsEnumProps,
} from '@jsonforms/react';
import SelectSupplierDialogControl from '../../components/selectSupplierDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectSupplierDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectSupplierDialog'
  )
);

const customSelectSupplierDialogControlTester = rankWith(
  4,
  isSelectSupplierDialogControl
);

export default {
  tester: customSelectSupplierDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectSupplierDialogControl),
};
