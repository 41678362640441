import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  Button as MaterialButton,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  Card as MaterialCard,
  TextField as MaterialTextField,
  IconButton as MaterialIconButton,
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  CardHeader as MaterialCardHeader,
} from '@material-ui/core';
import { Home as MaterialHome, Edit as MaterialEdit } from '../../styles/icons';
import CrudTable from '../crudTable';
import MoneyInput from '../moneyInput';

export const SelectContainer = styled.div``;

export const Container = styled.div``;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconButton = styled(MaterialIconButton)``;

export const Dialog = styled(MaterialDialog)``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  padding-bottom: 10px;
`;

export const H3 = styled.h3``;
export const HeaderTotal = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  text-align: end !important;
  align-items: center !important;
  margin: 0 auto !important;
  width: 100%;
`;
export const CardHeader = styled(MaterialCardHeader)`
  align-items: flex-end;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DialogActions = styled(MaterialDialogActions)``;

export const DialogContent = styled(MaterialDialogContent)`
  padding-bottom: 0px !important;
  padding-top: 0px !important;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Home = styled(MaterialHome)`
  width: 25px;
  height: 25px;
`;

export const Card = styled(MaterialCard)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
`;

export const TextField = styled(MaterialTextField)`
  width: 100%;
  margin-bottom: 0px !important;
  color: white;
`;

export const Edit = styled(MaterialEdit)`
  width: 30px;
  height: 30px;
  fill: var(--secondary);
`;

export const Select = styled(MaterialSelect)``;
export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const InputLabel = styled(MaterialInputLabel)``;

export const MenuItem = styled(MaterialMenuItem)``;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 110px;
`;
