import styled from 'styled-components';
import { DialogContent as MaterialDialogContent } from '@material-ui/core';
import MaterialDialog from '@material-ui/core/Dialog';
import MaterialDialogActions from '@material-ui/core/DialogActions';
import { Button as MaterialButton, TextField } from '@material-ui/core';
import CrudTable from '../crudTable';
import TextFieldInput from '../../components/textFieldInput';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Dialog = styled(MaterialDialog)``;

export const DialogActions = styled(MaterialDialogActions)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 5px;
`;

export const DialogContent = styled(MaterialDialogContent)`
  margin: 0px !important;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
`;
export const Input = styled(TextField)`
  width: 100%;
  margin-bottom: 15px !important;
  color: white;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-top: 10px;
  margin-bottom: 5px;
  width: 90%;
`;

export const StyledTextFieldInput = styled(TextFieldInput)`
  width: 70px;
`;
