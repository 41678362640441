import React, { useState } from 'react';
import StockConfig from '../../forms/stockConfig';
import restApiClient from '../../services/restApiClient';
import { Container, StyledCreateUpdateForm } from './styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function StockConfigCreateUpdate(props) {
  const [alert, setAlert] = useState({ message: '', severity: '' });

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  return (
    <Container>
      <StyledCreateUpdateForm
        modelSchema={StockConfig}
        forceId={-1}
        restGetDataById={restApiClient.stockConfig.get}
        restUpdateData={restApiClient.stockConfig.update}
        onUpdateSuccess={() => {
          setAlert({
            message: 'As configurações foram salvas com sucesso!',
            severity: 'success',
          });
        }}
        {...props}
      />
      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default StockConfigCreateUpdate;
