import React, { useState, useEffect, useCallback } from 'react';
import ItemizedCheck from '../../../../../../forms/itemizedCheck';
import restApiClient from '../../../../../../services/restApiClient';
import CrudTable from '../../../../../../components/crudTable';
import FilterTree from '../../../../../../helpers/filterTree';
import {
  CancelButton,
  Container,
  HeaderContainer,
  AutoDiv,
  StyledTypography,
} from './styles';
import CreateItemizedCheckDialog from '../../../../../../components/createItemizedCheckDialog';

function ClinicItemizedCheckList({ history, match, location }) {
  const cashRegisterValueId = match?.params?.cashRegisterValueId ?? -1;
  const cashRegisterValue = location?.state?.cashRegisterValue;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [], valueSum: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const columns = ItemizedCheck.tableSchema;

  const loadCashRegisterValues = useCallback(() => {
    if (cashRegisterValueId) {
      restApiClient.itemizedCheck
        .getAllByCashRegisterValueId(
          cashRegisterValueId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('number', searchBy)
                .or()
                .like('status', searchBy)
                .or()
                .like('name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, cashRegisterValueId]);

  useEffect(() => {
    loadCashRegisterValues();
  }, [loadCashRegisterValues]);

  return (
    <Container>
      <CrudTable
        title={`Lançamento de Cheques - Caixa ${cashRegisterValue?.cashRegisterClose.numberCashRegister}`}
        customHeaderComponent={
          <HeaderContainer>
            <AutoDiv>
              <StyledTypography variant="h6">
                {`Lançado: ${rows?.valueSum?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })} de ${cashRegisterValue?.value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`}
              </StyledTypography>
            </AutoDiv>
          </HeaderContainer>
        }
        modelActions={ItemizedCheck.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpenDialog(true);
            },
            title: 'Lançar entrada de cheque',
          },
          delete: {
            onClick: (id) => {
              restApiClient.itemizedCheck.delete(id).then(() => {
                loadCashRegisterValues();
              });
            },
            allowedActions: [ItemizedCheck.actions.delete],
          },
        }}
        customCellRenderers={{
          'cashRegisterClose.date': {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
      />

      <CreateItemizedCheckDialog
        open={openDialog}
        onCancel={() => {
          setOpenDialog(false);
        }}
        onSuccess={(data) => {
          setRows({
            ...rows,
            values: [...rows.values, { ...data, status: 'Pendente' }],
            valueSum: rows.valueSum + data.value,
          });

          if (rows.valueSum + data.value === cashRegisterValue.value) {
            setOpenDialog(false);
          }
        }}
        cashRegisterValueId={parseInt(cashRegisterValueId)}
      />
    </Container>
  );
}

export default ClinicItemizedCheckList;
