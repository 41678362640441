/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Treatment from '../../forms/treatment';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import {
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
  SubmitButton,
  AddBox,
} from './styles';

const TreatmentsDialog = ({
  onSuccess,
  onError,
  onCancel,
  unitId,
  services,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [listServices, setListServices] = useState([]);
  const columns = Treatment.treatmentListSchema;

  useEffect(() => {
    setListServices(services);
  }, [services]);

  useEffect(() => {
    restApiClient.treatment
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
            .like('id', searchBy)
            .or()
            .like('name', searchBy)
            .or()
            .like('price', searchBy)
            .or()
            .like('origin.name', searchBy)
            .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogContent>
        <StyledCrudTable
          title={'Tratamentos'}
          modelActions={Treatment.actions}
          columns={columns}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[5]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderBy(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderBy(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          actions={{
            selectTreatment: {
              icon: <AddBox />,
              onClick: (id) => {
                restApiClient.unitRadiologyService
                  .create({ unitId, treatmentId: id })
                  .then((r) => {
                    setListServices([...listServices, r.data]);
                    onSuccess(r.data);
                  });
              },
              allowedActions: [Treatment.actions.getAll],
              selector: (row) =>
                !listServices?.some(function (obj) {
                  return obj.treatmentId === row.id;
                }),
            },
          }}
          customCellRenderers={{

          }}
        />
      </DialogContent>
      <DialogActions>
        <SubmitButton
          onClick={() => {
            onCancel?.();
            setPage(0);
            setSearchBy('');
          }}
          type="button"
          variant="contained"
          color="primary"
        >
          Fechar
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default TreatmentsDialog;
