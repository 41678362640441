const GroupedExpense = {
  tableSchema: [
    {
      id: 'responsable',
      label: 'Responsável',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Soma Total',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'GroupedExpenseController@getAll',
    delete: 'GroupedExpenseController@delete',
  },
};

export default GroupedExpense;
