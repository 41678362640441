const PurchaseEntry = {
  tableSchema: [
    {
      id: 'invoiceId',
      label: 'Nome do arquivo',
      minWidth: 25,
      align: 'center',
    },
  ],
  actions: {
    create: 'PurchaseEntryController@create',
    getAllByPurchaseId: 'PurchaseEntryController@getAllByPurchaseId',
    downloadInvoice: 'PurchaseEntryController@downloadInvoice',
  },
};
export default PurchaseEntry;
