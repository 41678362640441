import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { isEmpty } from 'lodash';

const ReportSales = {
  buildCompletedSalesPdf: (from, to, result) => {
    const doc = new jsPDF();
    let y = 0;
    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(15, (y += 15), `Relatório de Transferências Realizadas`);

    doc.setFontSize(12);
    doc.text(15, (y += 8), `Período: ${from} a ${to}`);
    let now = new Date;
    doc.text(15, (y += 5), 'Emissão: ' + now.toLocaleDateString() + ' às ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds());
    y += 6;
    //CORPO DO PDF
    let bodyValue = [];
    result.forEach(function(value) {
      let date = new Date(value.updatedAt);
      bodyValue.push([
        date.toLocaleDateString(),
        value?.employee.user.name !== '' ? value?.employee.user.name : '-',
        value?.buyer?.socialName !== '' ? value.buyer?.isLegalPerson
          ? value.buyer?.fantasyName
          : value.buyer?.socialName : '-',
        value?.payment.amount !== '' ? Number(value?.payment.amount).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }) : '-',
        value?.costPrice ? Number(value?.costPrice).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }) : '-',
        value?.profit && value?.costPrice ? Number(value?.profit).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }) : '-',
        !isEmpty(value?.payment?.installments) ?
          value?.payment?.installments.length + 'x(' +
          value?.payment.installments.map(e => Number(e.price)
            .toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })).join(', ')
          + ')'
          : '1x(' + Number(value?.payment.amount).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }) + ')',
      ]);
    });
    doc.autoTable({
      startY: (y += 1),
      headStyles: { fillColor: [0, 0, 0] },
      head: [['Data', 'Vendedor', 'Destinatário', 'Valor total', 'Preço de custo', 'Lucro', 'Forma de pagamento']],
      body: bodyValue,
      theme: 'grid',
      styles: {
        halign: 'center',
        valign: 'middle',
        fontSize: 8,
      },
      columnStyles: {
        3: {cellWidth: 'wrap'},
        4: {cellWidth: 'wrap'},
        5: {cellWidth: 'wrap'},
        6: {cellWidth: 60},
      },
      margin: 5,
      rowPageBreak: 'avoid'
    });

    y = doc.lastAutoTable.finalY;

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 295, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default ReportSales;
