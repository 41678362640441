import React, { useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { isEmpty } from 'lodash';
import DayJsUtils from '@date-io/dayjs';
import { useSecurity } from '../../../../../extensions/hooks';
import SecuredItem from '../../../../../components/securedItem';
import {
  Hidden,
  Select,
  FileCsv,
  FilePdf,
  AutoDiv,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectContainer,
  HeaderContainer,
  FooterContainer,
  ExportCsvButton,
  ExportPdfButton,
  ButtonContainer,
  CircularProgress,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import Report from '../../../../../forms/report';
import restApiClient from '../../../../../services/restApiClient';
import ReportRadiologySchedulesCostUnits from '../../../../../pdf/reportRadiologySchedulesCostUnits';
import SubmitDialog from '../../../../../components/submitDialog';
import { useSelector } from 'react-redux';

function ClinicRadiologyReportList() {
  const unit = useSelector((x) => x.authReducer.user.unit);
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateTo, setMaxDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [selectedReport, setSelectedReport] = useState('');
  const [reportError, setReportError] = useState('');
  const [finalDateError, setFinalDateError] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [openSubmit, setOpenSubmit] = useState(false);

  const pdfSecurity = new Map();
  pdfSecurity.set('radiologyCostUnit', [
    Report.clinicActions.getAllClinicRadiologySchedulesByPeriod,
  ]);
  const csvSecurity = new Map();
  csvSecurity.set('clinicSchedules', [
    Report.clinicActions.getClinicSchedulesCSVReport,
  ]);
  csvSecurity.set('radiologySchedules', [
    Report.clinicActions.getRadiologySchedulesCSVReportClinic,
  ]);

  const listReports = [
    useSecurity(
      { value: 'radiologyCostUnit', label: 'Custo Parcial (Origem)' },
      [Report.clinicActions.getAllClinicRadiologySchedulesByPeriod]
    ),
    useSecurity({ value: 'clinicSchedules', label: 'Agendamentos (Clínica)' }, [
      Report.clinicActions.getClinicSchedulesCSVReport,
    ]),
    useSecurity(
      { value: 'radiologySchedules', label: 'Agendamentos (Radiologia)' },
      [Report.clinicActions.getRadiologySchedulesCSVReportClinic]
    ),
  ].filter((x) => !!x);

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios da Radiologia</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setDateFromError(false);
                setReportError('');
                switch (e.target.value) {
                  case 'radiologyCostUnit':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    break;
                  case 'clinicSchedules':
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    break;
                  case 'radiologySchedules':
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    break;
                  default:
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
      </AutoDiv>
      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setInitialDateError(value)}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateTo}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateTo
              ).format('DD/MM/YYYY')}.`}
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setFinalDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={csvSecurity.get(selectedReport)}>
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'clinicSchedules':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              setFetching(true);
                              restApiClient.radiologySchedule
                                .getClinicSchedulesCSVReport(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          case 'radiologySchedules':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              restApiClient.radiologySchedule
                                .getRadiologySchedulesCSVReportClinic(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={pdfSecurity.get(selectedReport)}>
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async () => {
                        switch (selectedReport) {
                          case 'radiologyCostUnit':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              setFetching(true);
                              restApiClient.radiologySchedule
                                .getAllClinicRadiologySchedulesByUnitByPeriod(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  const doc =
                                    ReportRadiologySchedulesCostUnits.buildRadiologySchedulesCostUnitsPdf(
                                      moment(selectedDateFrom).format(
                                        'DD/MM/YYYY'
                                      ),
                                      moment(selectedDateTo).format(
                                        'DD/MM/YYYY'
                                      ),
                                      { count: 1, values: [unit] },
                                      e.data
                                    );
                                  doc.save(
                                    `RELATORIO_CUSTO_PARCIAL_${unit.name.replaceAll(
                                      ' ',
                                      '_'
                                    )}_ORIGEM.pdf`
                                  );
                                  setFetching(false);
                                })
                                .catch((e) => {
                                  if (e.response?.status === 406) {
                                    setDialogText(e.response?.data?.errors);
                                    setOpenSubmit(true);
                                    setFetching(false);
                                  }
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>

      <SubmitDialog
        open={openSubmit}
        onCancel={() => setOpenSubmit(false)}
        onSuccess={() => {
          setOpenSubmit(false);
        }}
        errorMessage={true}
        dialogText={dialogText}
      />
    </Container>
  );
}

export default ClinicRadiologyReportList;
