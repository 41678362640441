const Expense = {
  schema: {
    type: 'object',
    properties: {
      expenseTypeId: {
        format: 'selectExpenseTypeDialog',
        customErrors: '',
      },
      dueDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      observation: {
        type: ['string', 'null'],
        customErrors: '',
      },
      paymentTypeId: {
        format: 'selectPaymentTypeDialog',
        customErrors: '',
      },
      employeeId: {
        format: 'selectEmployeeDialog',
        customErrors: '',
      },
      patientId: {
        format: 'selectPatientDialog',
        customErrors: '',
      },
      dentistId: {
        format: 'selectDentist',
        customErrors: '',
      },
      supplierId: {
        format: 'selectAnySupplierDialog',
        customErrors: '',
      },
      recurrentExpense: {
        type: 'boolean',
      },
      installment: {
        type: 'boolean',
      },
      apportion: {
        type: 'boolean',
      },
      expensePaid: {
        type: 'boolean',
      },
      payday: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      responsableType: {
        enum: ['Funcionário', 'Fornecedor', 'Paciente', 'Dentista'],
        customErrors: '',
      },
      unitFinanceId: {
        format: 'selectUnitFinanceDialog',
        customErrors: '',
      },
      agroup: {
        type: 'boolean',
      },
    },
    required: ['responsableType', 'expenseTypeId', 'unitId', 'paymentTypeId'],
  },
  updateSchema: {
    type: 'object',
    properties: {
      expenseTypeId: {
        format: 'selectExpenseTypeDialog',
        customErrors: '',
      },
      dueDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      observation: {
        type: ['string', 'null'],
        customErrors: '',
      },
      paymentTypeId: {
        format: 'selectPaymentTypeDialog',
        customErrors: '',
      },
      employeeId: {
        format: 'selectEmployeeDialog',
        customErrors: '',
      },
      patientId: {
        format: 'selectPatientDialog',
        customErrors: '',
      },
      dentistId: {
        format: 'selectDentist',
        customErrors: '',
      },
      supplierId: {
        format: 'selectAnySupplierDialog',
        customErrors: '',
      },
      installment: {
        type: 'boolean',
      },
      apportion: {
        type: 'boolean',
      },
      payday: {
        type: 'string',
        format: 'date',
        customErrors: '',
      },
      unitFinanceId: {
        format: 'selectUnitFinanceDialog',
        customErrors: '',
      },
      agroup: {
        type: 'boolean',
      },
    },
    required: ['expenseTypeId', 'unitId', 'paymentTypeId'],
  },
  uischema: {
    type: 'Group',
    label: 'Cadastro de Despesa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Agrupar',
            scope: '#/properties/agroup',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/hideAgroup',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'A despesa é referente a um:',
            scope: '#/properties/responsableType',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Funcionário *',
            scope: '#/properties/employeeId',
          },
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/responsableType',
            schema: {
              enum: [null, undefined, '', 'Fornecedor', 'Paciente', 'Dentista'],
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Paciente *',
            scope: '#/properties/patientId',
          },
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/responsableType',
            schema: {
              enum: [
                null,
                undefined,
                '',
                'Funcionário',
                'Fornecedor',
                'Dentista',
              ],
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dentista *',
            scope: '#/properties/dentistId',
          },
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/responsableType',
            schema: {
              enum: [
                null,
                undefined,
                '',
                'Funcionário',
                'Fornecedor',
                'Paciente',
              ],
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Fornecedor *',
            scope: '#/properties/supplierId',
          },
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/responsableType',
            schema: {
              enum: [
                null,
                undefined,
                '',
                'Funcionário',
                'Paciente',
                'Dentista',
              ],
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
          {
            type: 'Control',
            label: 'Forma de Pagamento',
            scope: '#/properties/paymentTypeId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Valor',
            scope: '#/properties/value',
          },
          {
            type: 'Control',
            label: 'Vencimento',
            scope: '#/properties/dueDate',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Despesa Paga',
            scope: '#/properties/expensePaid',
            rule: {
              effect: 'DISABLE',
              condition: {
                scope: '#/properties/disableExpensePaid',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Ratear',
            scope: '#/properties/apportion',
            rule: {
              effect: 'DISABLE',
              condition: {
                scope: '#/properties/disableApportion',
                schema: {
                  const: true,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Parcelar',
            scope: '#/properties/installment',
            rule: {
              effect: 'DISABLE',
              condition: {
                scope: '#/properties/disableInstallment',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Pagamento',
            scope: '#/properties/payday',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/expensePaid',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Conta bancária',
            scope: '#/properties/unitFinanceId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/expensePaid',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Despesa Recorrente',
            scope: '#/properties/recurrentExpense',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/installment',
                schema: {
                  const: true,
                },
              },
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/expensePaid',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Observação',
            scope: '#/properties/observation',
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Despesa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Agrupar',
            scope: '#/properties/agroup',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/readonly',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Funcionário *',
            scope: '#/properties/employeeId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/employeeId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Paciente *',
            scope: '#/properties/patientId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/patientId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dentista *',
            scope: '#/properties/dentistId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/dentistId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Fornecedor *',
            scope: '#/properties/supplierId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/supplierId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
          {
            type: 'Control',
            label: 'Vencimento',
            scope: '#/properties/dueDate',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Forma de Pagamento',
            scope: '#/properties/paymentTypeId',
          },
          {
            type: 'Control',
            label: 'Valor',
            scope: '#/properties/value',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Ratear',
            scope: '#/properties/apportion',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/readonly',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Observação',
            scope: '#/properties/observation',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Conta bancária',
            scope: '#/properties/unitFinanceId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/readonly',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data do Pagamento',
            scope: '#/properties/payday',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/readonly',
            schema: {
              const: false,
            },
          },
        },
      },
    ],
    rule: {
      effect: 'ENABLE',
      condition: {
        scope: '#/properties/readonly',
        schema: {
          const: false,
        },
      },
    },
  },
  customUischema: {
    type: 'Group',
    label: 'Despesa',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Funcionário *',
            scope: '#/properties/employeeId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/employeeId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Paciente *',
            scope: '#/properties/patientId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/patientId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dentista *',
            scope: '#/properties/dentistId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/dentistId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Fornecedor *',
            scope: '#/properties/supplierId',
            options: {
              readonly: true,
            },
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/supplierId',
            schema: { enum: [null, undefined] },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Tipo de Despesa',
            scope: '#/properties/expenseTypeId',
          },
          {
            type: 'Control',
            label: 'Vencimento',
            scope: '#/properties/dueDate',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Unidade',
            scope: '#/properties/unitId',
            options: {
              readonly: true,
            },
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Forma de Pagamento',
            scope: '#/properties/paymentTypeId',
          },
          {
            type: 'Control',
            label: 'Valor',
            scope: '#/properties/value',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Despesa Paga',
            scope: '#/properties/expensePaid',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/readonly',
            schema: {
              const: true,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Pagamento',
            scope: '#/properties/payday',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/expensePaid',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Conta bancária',
            scope: '#/properties/unitFinanceId',
          },
        ],
        rule: {
          effect: 'HIDE',
          condition: {
            scope: '#/properties/expensePaid',
            schema: {
              const: false,
            },
          },
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Observação',
            scope: '#/properties/observation',
          },
        ],
      },
    ],
    rule: {
      effect: 'DISABLE',
      condition: {
        scope: '#/properties/readonly',
        schema: {
          const: true,
        },
      },
    },
  },
  tableSchema: [
    {
      id: 'expenseType',
      label: 'Tipo Despesa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'name',
      label: 'Nome',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
    { id: 'dueDate', label: 'Data Vencimento', minWidth: 100, align: 'center' },
    {
      id: 'installmentNumber',
      label: 'Número Parcela',
      minWidth: 100,
      align: 'center',
    },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
  ],
  InstallmentTableSchema: [
    { id: 'number', label: 'Parcela', minWidth: 10, align: 'center' },
    { id: 'value', label: 'Valor', minWidth: 10, align: 'center' },
    { id: 'dueDate', label: 'Vencimento', minWidth: 10, align: 'center' },
  ],
  ApportionTableSchema: [
    { id: 'enabled', label: '', minWidth: 10, align: 'center' },
    { id: 'dueDate', label: 'Vencimento', minWidth: 10, align: 'center' },
    { id: 'name', label: 'Unidade', minWidth: 50, align: 'center' },
    { id: 'percentage', label: '%', minWidth: 10, align: 'center' },
    { id: 'valueApportion', label: 'Rateio', minWidth: 10, align: 'center' },
  ],
  actions: {
    create: 'ExpenseController@create',
    checkDuplicate: 'ExpenseController@checkDuplicate',
    update: 'ExpenseController@patchUpdate',
    updateExpenseWithApportion: 'ExpenseController@updateExpenseWithApportion',
    findById: 'ExpenseController@findById',
    getAll: 'ExpenseController@getAll',
    getAllGrouped: 'ExpenseController@getAllGrouped',
    getAllByPeriod: 'ExpenseController@getAllByPeriod',
    getAllByMonth: 'ExpenseController@getAllByMonth',
    setPaid: 'ExpenseController@setPaid',
    setUndonePayment: 'ExpenseController@setUndonePayment',
    bulkPayment: 'ExpenseController@bulkPayment',
    agroupExpense: 'ExpenseController@agroupExpense',
    delete: 'ExpenseController@delete',
  },
};

export default Expense;
