import styled from 'styled-components';
import CreateUpdateForm from '../../components/createUpdateForm';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)`
  width: 600px;
`;
