import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ReportFinishedProducts = {
  buildFinishedProductsPdf: (from, to, result) => {
    const doc = new jsPDF();
    let y = 0;
    doc.addFont('Arial', 'Arial', 'bold');
    doc.setFont('Arial');
    doc.setFontSize(14);
    doc.text(15, (y += 15), `Relatório de Produtos Finalizados`);

    doc.setFontSize(12);
    doc.text(15, (y += 8), `Período: ${from} a ${to}`);
    let now = new Date;
    doc.text(15, (y += 5), 'Emissão: ' + now.toLocaleDateString() + ' às ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds());
    y += 6;
    //CORPO DO PDF
    let bodyValue = [];
    result.forEach(function(value) {
      bodyValue.push([
        value?.name ?? '-',
        value?.quantity
      ]);
    });
    doc.autoTable({
      startY: (y += 1),
      headStyles: { fillColor: [0, 0, 0] },
      head: [['Produto Final', 'Quantidade']],
      body: bodyValue,
      theme: 'grid',
      styles: {
        halign: 'center',
        valign: 'middle',
        fontSize: 8,
      },
      columnStyles: {
        4: {cellWidth: 60},
      },
      margin: 5,
      rowPageBreak: 'avoid'
    });

    y = doc.lastAutoTable.finalY;

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(198, 295, String(i) + '/' + String(pageCount));
    }

    return doc;
  },
};

export default ReportFinishedProducts;
