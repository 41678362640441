const Address = {
  tableSchema: [
    { id: 'socialName', label: 'Nome Social', minWidth: 100, align: 'center' },
    {
      id: 'fantasyName',
      label: 'Nome Fantasia',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'typeLabel',
      label: 'Tipo',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAll: 'AddressController@getAllWithSupplier',
    findById: 'AddressController@findWithSupplierById',
  },
};

export default Address;
