import React, { useState, useEffect } from 'react';
import Dentist from '../../../../forms/dentist';
import FilterTree from '../../../../helpers/filterTree';
import CrudTable from '../../../../components/crudTable';
import restApiClient from '../../../../services/restApiClient';
import ShowPassDialog from '../../../../components/showPassDialog';
import {
  Container,
  LockPassword,
  CardChecklist,
  Restore,
  Close,
} from './styles';

function DentistList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [password, setPassword] = useState('');
  const [resetConfirmationId, setResetConfirmationId] = useState(-1);

  const columns = Dentist.tableSchema;

  useEffect(() => {
    restApiClient.dentist
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('name', searchBy)
              .or()
              .like('cro_number', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Gestão de Dentistas'}
        modelActions={Dentist.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === resetConfirmationId,
            content: <span>Você tem certeza que deseja alterar senha?</span>,
            onSuccess: (employeeId) => {
              restApiClient.employee
                .resetPassword(employeeId)
                .then((result) => {
                  setPassword(result.data.passwordChanged);
                  setResetConfirmationId(-1);
                });
            },
            onCancel: () => setResetConfirmationId(-1),
          },
        ]}
        actions={{
          add: {
            onClick: () => history.push('/dentist/create'),
          },
          update: {
            onClick: (dentistId) =>
              history.push(`/dentist/update/${dentistId}`),
            selector: (row) => !row?.deletedAt,
          },
          manageSpecialties: {
            icon: <CardChecklist />,
            onClick: (dentistId, row) => {
              history.push(`/dentist/specialties/${dentistId}`);
            },
            title: 'Gerenciar Especialidades',
            selector: (row) => !row?.deletedAt,
          },
          resetPassword: {
            icon: <LockPassword />,
            title: 'Alterar Senha',
            onClick: (employeeId) => {
              setResetConfirmationId(employeeId);
            },
            allowedActions: [Dentist.actions.resetPassword],
            selector: (row) => !row?.deletedAt,
          },
          customDelete: {
            icon: <Close />,
            onClick: (dentistId, index) => {
              restApiClient.dentist.delete(dentistId).then(() => {
                const result = { ...rows };
                const restored = result.values.find(
                  (x) => x.userId === dentistId
                );
                restored.deletedAt = 'datanova';
                setRows(result);
              });
            },
            title: 'Desativar',
            selector: (row) => !row?.deletedAt,
          },
          restore: {
            icon: <Restore />,
            onClick: (dentistId, index) => {
              restApiClient.dentist.restore(dentistId).then(() => {
                const result = { ...rows };
                const restored = result.values.find(
                  (x) => x.userId === dentistId
                );
                restored.deletedAt = null;
                setRows(result);
              });
            },
            title: 'Ativar',
            selector: (row) => row?.deletedAt,
          },
        }}
      />
      <ShowPassDialog
        onClose={() => {
          setPassword('');
        }}
        password={password}
        open={!!password}
      />
    </Container>
  );
}

export default DentistList;
