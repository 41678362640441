import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  Button as MaterialButton,
  DialogTitle as MaterialDialogTitle,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogContentText,
  Typography as MaterialTypography,
} from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Dialog = styled(MaterialDialog)``;

export const DialogContentText = styled(MaterialDialogContentText)``;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export const Typography = styled(MaterialTypography)``;

export const DialogActions = styled(MaterialDialogActions)``;

export const DialogContent = styled(MaterialDialogContent)`
  padding-bottom: 0px !important;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
