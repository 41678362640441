/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Container,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  Card,
  HeaderContainer,
  StyledCrudTable,
  CardHeader,
  Grid,
  StyledMoneyInput,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  Checkbox,
  FormControlLabel,
  SelectUnitFinance,
  StyledPercentageInput,
  TotalContainer,
} from './styles';
import Expense from '../../forms/expense';
import { toFixed } from '../../extensions/object';
import restApiClient from '../../services/restApiClient';
import moment from 'moment';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DayJsUtils from '@date-io/dayjs';

function ExpenseApportionDialog({
  total,
  date,
  selectedUnit,
  onSuccess,
  onCancel,
  ...props
}) {
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [orderBy, setOrderby] = useState({});
  const [apportionList, setApportionList] = useState([]);
  const [totalApportion, setTotalApportion] = useState(0);
  const [errorApportion, setErrorApportion] = useState(false);
  const [allUnits, setAllUnits] = useState(false);
  const [forceUncheck, setForceUncheck] = useState(false);
  const [firstExpensePaid, setFirstExpensePaid] = useState(false);
  const [payday, setPayday] = useState(null);
  const [errorPayday, setErrorPayday] = useState(false);
  const [unitFinanceId, setUnitFinanceId] = useState(false);
  const [mainUnit, setMainUnit] = useState('');
  const columns = Expense.ApportionTableSchema;

  const rebuildTotal = (items) => {
    const total = sumBy(items, (obj) => {
      return obj.valueApportion ?? 0;
    });
    setTotalApportion(total);
  };

  useEffect(() => {
    if (!forceUncheck) {
      restApiClient.unit.getAll(null, null, null, orderBy).then((e) => {
        let newRows = e.data;
        newRows.values = sortBy(
          e.data.values.map((obj) => {
            const modelUnit = {
              unitId: obj.id,
              name: obj.name,
              dueDate: date,
            };

            if (allUnits) {
              modelUnit.enabled = true;
              let apportionNumber = newRows.count;
              let valueApportion = toFixed(total / apportionNumber, 2);
              let percentage = toFixed((valueApportion / total) * 100, 2);

              if (modelUnit.unitId === selectedUnit) {
                modelUnit.valueApportion =
                  total - valueApportion * (apportionNumber - 1);
                modelUnit.percentage = parseFloat(
                  (100 - percentage * (apportionNumber - 1)).toFixed(2)
                );
              } else {
                modelUnit.percentage = percentage;
                modelUnit.valueApportion = valueApportion;
              }
            } else {
              if (modelUnit.unitId === selectedUnit) {
                setMainUnit(obj.name);
                modelUnit.enabled = true;
                modelUnit.percentage = (total / total) * 100;
                modelUnit.valueApportion = total;
              }
            }
            return modelUnit;
          }),
          (x) => x.unitId !== selectedUnit
        );
        setRows(newRows);
        setApportionList(newRows.values.filter((x) => x.enabled));
        rebuildTotal(newRows.values.filter((x) => x.enabled));
      });
    }
  }, [total, allUnits, selectedUnit]);

  return (
    <Container>
      <Dialog
        maxWidth={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent dividers>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (total === parseFloat(totalApportion.toFixed(2))) {
                onSuccess?.({
                  apportions: apportionList,
                  firstExpensePaid,
                  payday: moment(new Date(payday)).format('YYYY-MM-DD'),
                  unitFinanceId,
                });
              } else {
                setErrorApportion(true);
              }
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader
                  title="Rateio de Despesa"
                  titleTypographyProps={{ variant: 'title' }}
                />
                <TotalContainer>
                  <h3>
                    {`Total da Despesa: ${total?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </h3>
                  <h3>
                    {`Total do Rateio: ${totalApportion?.toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}`}
                  </h3>
                </TotalContainer>
              </HeaderContainer>
              <StyledCrudTable
                emptyHeader
                noPagination
                modelActions={Expense.actions}
                columns={columns}
                rows={rows}
                customHeaderTable={{
                  id: 'enabled',
                  customCell: (
                    <Checkbox
                      checked={allUnits}
                      onChange={(e) => {
                        setForceUncheck(false);
                        setAllUnits(e.target.checked);
                      }}
                      name="checked"
                    />
                  ),
                }}
                orderBy={orderBy}
                setOrderBy={(value) => setOrderby(value)}
                customCellRenderers={{
                  enabled: {
                    renderer: (field, value, column, row) => {
                      return (
                        <Checkbox
                          checked={value || false}
                          disabled={row.unitId === selectedUnit}
                          onChange={(e, value) => {
                            setAllUnits(false);
                            setForceUncheck(true);
                            let newRows = [...rows.values];
                            let apportionNumber =
                              newRows.filter((x) => x.enabled).length + 1;
                            if (!value) apportionNumber -= 2;
                            let valueApportion = toFixed(
                              total / apportionNumber,
                              2
                            );
                            let percentage = toFixed(
                              (valueApportion / total) * 100,
                              2
                            );

                            newRows = newRows.map((obj) => {
                              if (obj.unitId === row.unitId) {
                                obj.enabled = value;
                              }

                              if (obj.enabled) {
                                if (obj.unitId === selectedUnit) {
                                  obj.valueApportion =
                                    total -
                                    valueApportion * (apportionNumber - 1);
                                  obj.percentage = parseFloat(
                                    (
                                      100 -
                                      percentage * (apportionNumber - 1)
                                    ).toFixed(2)
                                  );
                                } else {
                                  obj.percentage = percentage;
                                  obj.valueApportion = valueApportion;
                                }
                              } else {
                                obj.percentage = '';
                                obj.valueApportion = 0;
                              }
                              return obj;
                            });
                            setApportionList(newRows.filter((x) => x.enabled));
                            rebuildTotal(newRows.filter((x) => x.enabled));
                          }}
                        />
                      );
                    },
                  },
                  percentage: {
                    renderer: (field, value, column, row) => {
                      return (
                        <StyledPercentageInput
                          key={row.unitId}
                          value={value || ''}
                          onChange={(e) => {
                            let item;
                            const newRows = [...apportionList];
                            if (parseFloat(e.target.value) !== row.percentage) {
                              if (
                                (item = newRows.find(
                                  (x) => x.unitId === row.unitId
                                ))
                              ) {
                                item.percentage = parseFloat(e.target.value);
                                item.valueApportion =
                                  (parseFloat(e.target.value) / 100) * total;
                              }
                              rebuildTotal(newRows);
                            }
                          }}
                          disabled={!row.enabled}
                          inputProps={{
                            style: { min: 0, textAlign: 'center' },
                          }}
                        />
                      );
                    },
                  },
                  valueApportion: {
                    renderer: (field, value, column, row) => {
                      return (
                        <StyledMoneyInput
                          value={value || 0}
                          onChange={(e, value) => {
                            let item;
                            const newRows = [...apportionList];
                            if (value !== row.valueApportion) {
                              if (
                                (item = newRows.find(
                                  (x) => x.unitId === row.unitId
                                ))
                              ) {
                                item.valueApportion = value;
                                item.percentage = toFixed(
                                  (value / total) * 100,
                                  2
                                );
                              }
                              rebuildTotal(newRows);
                            }
                          }}
                          disabled={!row.enabled}
                          inputProps={{ style: { textAlign: 'center' } }}
                        />
                      );
                    },
                  },
                  dueDate: {
                    renderer: (index, value) => {
                      return value ? moment(value).format('DD/MM/YYYY') : '-';
                    },
                  },
                }}
              />
            </Card>
            <Card>
              <FormControlLabel
                style={{ pointerEvents: 'none' }}
                control={
                  <Checkbox
                    style={{ pointerEvents: 'auto' }}
                    checked={firstExpensePaid}
                    onChange={(e) => {
                      setUnitFinanceId(null);
                      setPayday(null);
                      setFirstExpensePaid(e.target.checked);
                    }}
                    name="checked"
                  />
                }
                label={`Pagar Despesa da Unidade Principal (${mainUnit})`}
              />
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DayJsUtils}>
                      <KeyboardDatePicker
                        id="payday"
                        label="Data de Pagamento"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        size="small"
                        value={payday}
                        disabled={!firstExpensePaid}
                        onChange={(date) => {
                          setErrorPayday(false);
                          setPayday(date);
                        }}
                        cancelLabel="Cancelar"
                        okLabel="Selecionar"
                        invalidDateMessage="Data em formato inválido."
                        onError={(value) => setErrorPayday(!!value)}
                        helperText={
                          errorPayday ? 'Data em formato inválido.' : ''
                        }
                        error={errorPayday}
                        required={firstExpensePaid}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectUnitFinance
                      label="Conta Bancária"
                      size="small"
                      variant="outlined"
                      data={unitFinanceId}
                      onSuccess={(id) => {
                        setUnitFinanceId(id);
                      }}
                      visible
                      enabled={firstExpensePaid}
                      required={firstExpensePaid}
                    />
                  </Grid>
                </Grid>
              </>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setApportionList([]);
                    setRows({ count: 0, values: [] });
                    setForceUncheck(false);
                    setAllUnits(false);
                    setUnitFinanceId(null);
                    setPayday(null);
                    setFirstExpensePaid(false);
                    onCancel?.();
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Finalizar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={errorApportion}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setErrorApportion(false)}
      >
        <Alert onClose={() => setErrorApportion(false)} severity="error">
          {'Valor total do rateio deve ser igual ao valor da despesa!'}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ExpenseApportionDialog;
