import React, { useState, useEffect } from 'react';
import PurchaseItem from '../../../forms/purchaseItem';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, CancelButton } from './styles';

function ItemSearch({ history, match, ...props }) {
  const purchaseId = match.params?.id;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = PurchaseItem.tableSchema;

  useEffect(() => {
    restApiClient.purchaseItem
      .getAllByPurchaseId(
        purchaseId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('item.id', searchBy)
              .or()
              .like('item.name', searchBy)
              .or()
              .like('purchase_quantity', searchBy)
              .or()
              .like('price', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        newRows.values = e.data.values.map((rowItem) => {
          rowItem.subtotal = rowItem.purchaseQuantity * rowItem.price;
          if (isNaN(rowItem.subtotal)) rowItem.subtotal = 0;
          return rowItem;
        });
        setRows(newRows);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Visualizar Compra'}
        modelActions={PurchaseItem.actions}
        customFooterComponent={
          <>
            <CancelButton
              onClick={() => history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </CancelButton>
          </>
        }
        columns={columns}
        customColumns={[
          {
            id: 'subtotal',
            label: 'Subtotal',
            minWidth: 50,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          price: {
            renderer: (field, value) => {
              value = parseFloat(value);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          purchaseQuantity: {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
          subtotal: {
            renderer: (field, value, column, row) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.receivedQuantity > row.purchaseQuantity) {
            return '#e6ffe6';
          } else if (row.receivedQuantity < row.purchaseQuantity) {
            return '#ffe0e0';
          }
        }}
      />
    </Container>
  );
}

export default ItemSearch;
