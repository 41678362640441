const Unit = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        minLength: 3,
        maxLength: 100,
        customErrors: '',
      },
      showInDre: {
        type: 'boolean',
        customErrors: '',
      },
      cep: {
        type: 'string',
        description: 'Digite somente números',
        mask: '99999-999',
        cep: '',
        minLength: 8,
        maxLength: 8,
        customErrors: '',
      },
      address: {
        type: 'string',
        customErrors: '',
      },
      number: {
        type: 'string',
        minLength: 1,
        maxLength: 8,
        customErrors: '',
      },
      neighborhood: {
        type: 'string',
        customErrors: '',
      },
      complement: {
        type: 'string',
        maxLength: 128,
        customErrors: '',
      },
      city: {
        type: 'string',
        customErrors: '',
      },
      state: {
        type: 'string',
        customErrors: '',
      },
    },
    required: [
      'name',
      'cep',
      'address',
      'number',
      'neighborhood',
      'city',
      'state',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Unidade',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'CEP',
            scope: '#/properties/cep',
          },
          {
            type: 'Control',
            label: 'Endereço',
            scope: '#/properties/address',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Número',
            scope: '#/properties/number',
          },
          {
            type: 'Control',
            label: 'Complemento',
            scope: '#/properties/complement',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Bairro',
            scope: '#/properties/neighborhood',
          },
          {
            type: 'Control',
            label: 'Município',
            scope: '#/properties/city',
          },
          {
            type: 'Control',
            label: 'Estado',
            scope: '#/properties/state',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Contabilizar na DRE',
            scope: '#/properties/showInDre',
          },
        ],
      },
    ],
  },
  tableSchema: [{ id: 'name', label: 'Nome', minWidth: 100, align: 'center' }],
  unaryTableSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
  ],
  unitTypesSchema: [
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
  ],
  data: {
    showInDre: false,
  },
  actions: {
    getAll: 'UnitController@getAll',
    getAllCritical: 'UnitController@getAllCritical',
    getAllByType: 'UnitController@getAllByType',
    getAllWithConsumptions: 'UnitController@getAllWithConsumptions',
    findById: 'UnitController@findById',
    create: 'UnitController@create',
    update: 'UnitController@patchUpdate',
    delete: 'UnitController@delete',
  },
};

export default Unit;
