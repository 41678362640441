const ItemizedCardInstallment = {
  tableSchema: [
    {
      id: 'number',
      label: 'Parcela',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'date',
      label: 'Data',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'value',
      label: 'Valor Bruto',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'liquid',
      label: 'Valor Líquido',
      minWidth: 100,
      align: 'center',
    },
  ],
  actions: {
    getAllByItemizedCardId:
      'ItemizedCardInstallmentController@getAllByItemizedCardId',
  },
};

export default ItemizedCardInstallment;
