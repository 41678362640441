import styled from 'styled-components';
import MaterialList from '@material-ui/core/List';
import MaterialListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import {
  ExpandLess as MaterialExpandLess,
  ExpandMore as MaterialExpandMore,
} from '../../styles/icons';

export const StyledCollapse = styled(Collapse)``;

export const InnerList = styled(MaterialList)`
  padding-left: 15px !important;
`;
export const ListItem = styled(MaterialListItem)``;
export const ExpandLess = styled(MaterialExpandLess)`
  width: 25px;
  height: 25px;

  fill: var(--secondary);
`;
export const ExpandMore = styled(MaterialExpandMore)`
  width: 25px;
  height: 25px;

  fill: var(--secondary);
`;
