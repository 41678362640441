import styled from 'styled-components';
import {
  TextField,
  Button as MaterialButton,
  Dialog as MaterialDialog,
  Select as MaterialSelect,
  MenuItem as MaterialMenuItem,
  InputLabel as MaterialInputLabel,
  DialogTitle as MaterialDialogTitle,
  FormControl as MaterialFormControl,
  DialogContent as MaterialDialogContent,
  DialogActions as MaterialDialogActions, 
  Card as MaterialCard, 
  CardHeader as MaterialCardHeader,
} from '@material-ui/core';

export const Container = styled.div``;

export const Dialog = styled(MaterialDialog)``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 0 !important;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 15px !important;
`;

export const Input = styled(TextField)`
  width: 100%;
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  color: white;
`;

export const SelectContainer = styled.div`
  margin-top: 10px !important;
  width: 300px;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;
export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;
export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;
export const MenuItem = styled(MaterialMenuItem)``;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const CardHeader = styled(MaterialCardHeader)`
  padding: 0 !important;
`;
