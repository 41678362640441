import styled from 'styled-components';
import { Button as MaterialButton, } from '@material-ui/core';
import {
  UserShield as MaterialUserShield,
  Calendar as MaterialCalendar
} from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Calendar = styled(MaterialCalendar)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const UserShield = styled(MaterialUserShield)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;

