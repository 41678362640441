import React, { useState, useEffect } from 'react';
import restApiClient from '../../../../services/restApiClient';
import { isEmpty } from 'lodash';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import ReportSales from '../../../../pdf/reportSales';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {
  HeaderContainer,
  FooterContainer,
  Container,
  Hidden,
  AutoDiv,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  SelectContainer,
  InputLabel,
  ExportCsvButton,
  ExportPdfButton,
  FileCsv,
  FilePdf,
  ButtonContainer,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import { useSecurity } from '../../../../extensions/hooks';
import Report from '../../../../forms/report';
import Group from '../../../../forms/group';
import SecuredItem from '../../../../components/securedItem';

function PurchasingGroupReportList({ history }) {
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateTo, setMaxDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [selectedReport, setSelectedReport] = useState('');
  const [reportError, setReportError] = useState('');
  const [dateError, setDateError] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [selectGroupVisible, setSelectGroupVisible] = useState(false);
  const [groups, setGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupError, setGroupError] = useState('');

  const pdfSecurity = new Map();
  pdfSecurity.set('completedSales', [Report.actions.getCompletedSalesReport]);

  const csvSecurity = new Map();
  csvSecurity.set('completedSales', [
    Report.actions.getCsvCompletedSalesReport,
  ]);
  csvSecurity.set('customerPriceList', [
    Report.actions.getCsvCustomerPriceList,
  ]);

  const listReports = [
    useSecurity(
      { value: 'completedSales', label: 'Transferências Realizadas' },
      [
        [Report.actions.getCompletedSalesReport],
        [Report.actions.getCsvCompletedSalesReport],
      ]
    ),
    useSecurity(
      { value: 'customerPriceList', label: 'Lista de Preço Cliente' },
      [[Report.actions.getCsvCustomerPriceList, Group.actions.getAllAsGroup]]
    ),
  ].filter((x) => !!x);

  useEffect(() => {
    if (['customerPriceList'].includes(selectedReport)) {
      restApiClient.group.getAllAsGroup().then((e) => {
        setGroups(e.data);
      });
    }
  }, [selectedReport]);

  async function getCompletedSalesCsv() {
    setFetching(true);
    restApiClient.sale
      .getCsvCompletedSalesReport(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY')
      )
      .then((e) => {
        setFetching(false);
      });
  }

  async function getCompletedSalesPdf() {
    setFetching(true);
    restApiClient.sale
      .getCompletedSalesReport(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY')
      )
      .then((e) => {
        const doc = ReportSales.buildCompletedSalesPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          e.data
        );
        doc.save(`RELATORIO_TRANSFERENCIAIS_REALIZADAS.pdf`);
        setFetching(false);
      });
  }

  async function getCustomerPriceList(groupId = null) {
    setFetching(true);
    restApiClient.item.getCsvCustomerPriceList(groupId).then((e) => {
      setFetching(false);
    });
  }

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios do Setedental</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setReportError('');
                switch (e.target.value) {
                  case 'completedSales':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectGroupVisible(false);
                    break;
                  case 'customerPriceList':
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setExportCsvVisible(true);
                    setSelectGroupVisible(true);
                    break;
                  default:
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
        <Hidden xsUp={!selectGroupVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(groupError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma categoria
              </InputLabel>
              <Select
                value={selectedGroup || ''}
                label="Selecione uma categoria"
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                  setGroupError('');
                }}
              >
                {[
                  selectedReport === 'customerPriceList' ? (
                    <MenuItem value={'all'} key={'all'}>
                      {'TODOS'}
                    </MenuItem>
                  ) : (
                    ''
                  ),
                ]
                  .concat(
                    groups?.map((optionValue) => (
                      <MenuItem value={optionValue.id} key={optionValue.id}>
                        {optionValue.name}
                      </MenuItem>
                    ))
                  )
                  .concat([
                    <MenuItem value="" key={'empty'}>
                      <em>Nenhuma</em>
                    </MenuItem>,
                  ])}
              </Select>
              <FormHelperText>
                {!isEmpty(groupError) ? groupError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
      </AutoDiv>
      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateTo}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateTo
              ).format('DD/MM/YYYY')}.`}
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem
                    allowedActions={[csvSecurity.get(selectedReport)]}
                  >
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'completedSales':
                            if (!dateError) {
                              if (selectedDateFrom) {
                                await getCompletedSalesCsv();
                              } else {
                                setDateFromError(true);
                              }
                            }
                            break;
                          case 'customerPriceList':
                            if (selectedGroup == 'all') {
                              await getCustomerPriceList();
                            } else if (selectedGroup) {
                              await getCustomerPriceList(selectedGroup);
                            } else {
                              setGroupError('Selecione uma opção válida.');
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem
                    allowedActions={[pdfSecurity.get(selectedReport)]}
                  >
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'completedSales':
                            if (selectedDateFrom) {
                              await getCompletedSalesPdf();
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>
    </Container>
  );
}

export default PurchasingGroupReportList;
