import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

const ReportCashRegisterClose = {
  buildPdf: (from, to, result) => {
    const doc = new jsPDF();
    let now = new Date();
    let y = 0;

    doc.setFont('Arial');
    doc.setFontSize(10);
    doc.text(`Período: ${from} a ${to}`, 160, (y += 8));
    doc.text(
      'Emissão: ' +
        now.toLocaleDateString() +
        ' às ' +
        now.getHours() +
        ':' +
        now.getMinutes() +
        ':' +
        now.getSeconds(),
      160,
      (y += 5)
    );
    doc.setFontSize(18);
    doc.text(`Caixas`, doc.internal.pageSize.getWidth() / 2, (y += 10), {
      align: 'center',
    });
    y += 3;
    //CORPO DO PDF
    doc.setLineWidth(0.3);
    doc.setDrawColor(0, 0, 0);
    y += 10;
    doc.setFontSize(11);
    doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
    y += 1;
    if (y >= doc.internal.pageSize.height - 25) {
      doc.addPage();
      y = 5;
    }

    const registerByUnit = result?.values.reduce((acc, item) => {
      const registerWithEmployee = `${item?.number_cash_register}|${item?.user_name}`;
      if (!acc[item?.unit_name]) {
        acc[item?.unit_name] = {};
      }
      if (!acc[item?.unit_name][item?.date]) {
        acc[item?.unit_name][item?.date] = {};
      }
      if (!acc[item?.unit_name][item?.date][registerWithEmployee]) {
        acc[item?.unit_name][item?.date][registerWithEmployee] = [];
      }
      acc[item?.unit_name][item?.date][registerWithEmployee].push(item);
      return acc;
    }, {});

    let completeTotal = 0;
    Object.entries(registerByUnit).forEach(([unit, dates]) => {
      if (y >= doc.internal.pageSize.height - 25) {
        doc.addPage();
        y = 5;
      }
      doc.setFontSize(14);
      doc.text(`Unidade: ${unit}`, 5, (y += 8));
      y += 3;
      doc.setFontSize(10);
      let total = 0;
      const columns = ['Tipo', 'Conta', 'Valor'];
      const columnValues = columns.map((head) => ({
        content: head,
        styles: {
          fillColor: [55, 112, 222],
          textColor: [255, 255, 255],
          lineWidth: 0.25,
        },
      }));

      Object.entries(dates).forEach(([date, registers]) => {
        if (y >= doc.internal.pageSize.height - 25) {
          doc.addPage();
          y = 5;
        }
        doc.setFontSize(13);
        doc.text(`${moment(date).format('LL')}`, 5, (y += 8));
        y += 3;
        Object.entries(registers).forEach(([registerWithEmployee, values]) => {
          const [register, employee] = registerWithEmployee.split('|');
          doc.text(`Funcionário: ${employee}`, 5, (y += 4));
          y += 3;
          doc.text(`Caixa: ${register}`, 5, (y += 4));
          let subtotal = 0;
          const bodyValues = values.map(
            ({ payment_type_name, bank, agency, account, value }) => {
              subtotal += parseFloat(value);
              total += parseFloat(value);
              return [
                payment_type_name,
                bank ? `${bank} - ${agency} - ${account}` : 'Caixa',
                parseFloat(value).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
              ];
            }
          );
          doc.autoTable({
            startY: (y += 3),
            showHead: 'firstPage',
            styles: {
              halign: 'center',
              valign: 'middle',
              overflow: 'linebreak',
            },
            headStyles: {
              halign: 'center',
              valign: 'middle',
              fontSize: 10,
              fontStyle: 'bold',
              textColor: [25, 25, 25],
              fillColor: [235, 235, 235],
              lineWidth: 0.25,
            },
            bodyStyles: {
              fontSize: 10,
              textColor: [0, 0, 0],
            },
            head: [],
            body: [
              columnValues,
              ...bodyValues,
              [
                {
                  content: `Subtotal: ${subtotal.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}`,
                  colSpan: 3,
                  styles: {
                    halign: 'left',
                    valign: 'middle',
                    fontSize: 10,
                    fontStyle: 'bold',
                    textColor: [25, 25, 25],
                    fillColor: [225, 225, 225],
                  },
                },
              ],
            ],
            columnStyles: {
              0: { cellWidth: 30 },
              1: { cellWidth: 110 },
              2: { cellWidth: 60 },
            },
            theme: 'grid',
            margin: 5,
            rowPageBreak: 'avoid',
          });
          y = doc.lastAutoTable.finalY + 5;
        });
      });
      completeTotal += total;
      if (y >= doc.internal.pageSize.height - 25) {
        doc.addPage();
        y = 5;
      }
      doc.setFontSize(14);
      doc.text(
        `Total(${unit}): ${total.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`,
        5,
        (y += 8)
      );
      y += 3;
      if (y >= doc.internal.pageSize.height - 25) {
        doc.addPage();
        y = 5;
      }
      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0);
      doc.setFontSize(11);
      doc.line(5, y, doc.internal.pageSize.getWidth() - 5, y);
      y += 1;
    });

    if (y >= doc.internal.pageSize.height - 25) {
      doc.addPage();
      y = 5;
    }
    doc.setFontSize(14);
    doc.text(
      `Total Geral: ${completeTotal.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      5,
      (y += 8)
    );
    y += 3;

    // PAGINAÇÃO
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + '/' + String(pageCount), 198, 295);
    }
    return doc;
  },
};

export default ReportCashRegisterClose;
