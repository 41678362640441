import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsCellProps, withJsonFormsEnumProps } from '@jsonforms/react';
import SelectLabEmployeeDialogControl from '../../components/selectLabEmployeeDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectLabEmployeeDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && 
      schema.format === 'selectLabEmployeeDialog' &&
      schema.type === 'number'
  )
);

const customSelectLabEmployeeDialogControlTester = rankWith(
  4,
  isSelectLabEmployeeDialogControl
);

export default {
  tester: customSelectLabEmployeeDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectLabEmployeeDialogControl),
  cell: withJsonFormsCellProps(SelectLabEmployeeDialogControl),
};
