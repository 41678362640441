/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
  Container,
  DialogContent,
  InputTextarea,
  Dialog,
  FormDiv,
  ActionsContainer,
  CancelButton,
  DialogActions,
  SubmitButton,
  Card,
  CardHeader,
  HeaderContainer,
  FormControlLabel,
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  TextStyled,
} from './styles';
import restApiClient from '../../services/restApiClient';
import { isEmpty } from 'lodash';

function RejectOrderServiceDialog({
  onSuccess,
  onCancel,
  orderServiceStepId,
  nextStep = false,
  ...props
}) {
  const [rejectedReason, setRejectedReason] = useState(null);
  const [rejectedJustification, setRejectedJustification] = useState(null);
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    restApiClient.orderServiceStep.setRejected(orderServiceStepId, {
      rejectedReason: rejectedReason,
      rejectedJustification: rejectedJustification
    })
      .then((r) => {
        setRejectedJustification(null);
        setRejectedReason(null);
        onSuccess?.();
      }).catch((result) => {
        setErrors(result.response.data.errors);
      });
  }
  return (
    <Container>
      <Dialog
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title='Justificativa da Recusa' />
              </HeaderContainer>
              <FormDiv>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <FormControl component='fieldset' error={!isEmpty(errors?.rejectedReason)}>
                      <FormLabel component='legend' required>
                        Motivo
                      </FormLabel>
                      <RadioGroup
                        row={true}
                        aria-label='reason'
                        name='reason'
                        value={rejectedReason ?? null}
                        onChange={(e) => {
                          setErrors({});
                          setRejectedReason(e.target.value);
                        }}
                      >
                        <FormControlLabel value='invalid_date' control={<Radio required />} label='Data inválida' />
                        <FormControlLabel value='material_adjustment' control={<Radio required />} label='Ajuste de material' />
                        { nextStep ? (<FormControlLabel value='skip_step' control={<Radio required />} label='Pular Etapa' />) : ''}
                      </RadioGroup>
                      <FormHelperText>
                        {!isEmpty(errors?.rejectedReason) ? errors?.rejectedReason.join(' ') : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputTextarea
                      id='outlined-multiline-static'
                      size='small'
                      label='Justificativa'
                      value={rejectedJustification ?? ''}
                      onChange={(e) => {
                        setErrors({});
                        setRejectedJustification(e.target.value);
                      }}
                      multiline
                      rows={6}
                      variant='outlined'
                      inputProps={{ maxLength: 250 }}
                      required
                      helperText={!isEmpty(errors?.rejectedJustification) ? 
                        errors?.rejectedJustification.join(' ') : ''}
                      error={!isEmpty(errors?.rejectedJustification)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {
                      rejectedReason === 'invalid_date' ?
                        (<TextStyled>Esta OS será devolvida para a clínica.</TextStyled>) : 
                      rejectedReason === 'material_adjustment' ?
                        (<TextStyled>Esta OS será devolvida ao dentista.</TextStyled>) : null
                    }
                  </Grid>
                </Grid>
              </FormDiv>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setErrors({});
                    setRejectedJustification(null);
                    setRejectedReason(null);
                    onCancel?.();
                  }}
                  type='button'
                  variant='contained'
                  color='primary'
                >
                  Cancelar
                </CancelButton>
                <SubmitButton
                  type='submit'
                  variant='contained'
                  color='primary'
                >
                  Salvar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </Container>
  );
}

export default RejectOrderServiceDialog;
