/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import File from '../../forms/file';
import Dialog from '@material-ui/core/Dialog';
import {
  Container,
  DialogContent,
  DialogActions,
  StyledCrudTable,
  Button,
  StyledImportFileDialog,
  FileDownload,
} from './styles';
import FilterTree from '../../helpers/filterTree';

function FilesDialog({
  onSuccess,
  onError,
  onCancel,
  modelId,
  restGetData,
  restDeleteData,
  restSubmitData,
  restDownloadData,
  actions,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const [successUpdate, setSuccessUpdate] = useState(0);

  useEffect(() => {
    if (modelId) {
      restGetData(
        modelId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('file.name', searchBy).toString()
          : null,
        orderBy
      ).then((e) => {
        setRows(e.data);
      });
    }
  }, [rowsPerPage, page, orderBy, searchBy, modelId, successUpdate]);

  const columns = File.tableSchema;

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCrudTable
            title={'Anexos'}
            modelActions={actions}
            columns={columns}
            rows={rows}
            page={page}
            setPage={(page) => setPage(page)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[3]}
            setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            onOrderByChange={(orderBy) => setOrderby(orderBy)}
            orderBy={orderBy}
            setOrderBy={(value) => setOrderby(value)}
            searchBy={searchBy}
            setSearchBy={(value) => setSearchBy(value)}
            actions={{
              add: {
                onClick: () => setOpen(true),
              },
              download: {
                icon: <FileDownload />,
                onClick: async (id) => {
                  await restDownloadData(id);
                },
                title: 'Download Anexo',
                allowedActions: [actions?.downloadFile],
              },
              delete: {
                onClick: (FileId, index) => {
                  restDeleteData(FileId).then(() => {
                    const result = { ...rows };
                    if (index > -1) {
                      result.values.splice(index, 1);
                      result.count--;
                    }
                    setRows(result);
                  });
                },
              },
            }}
            customCellRenderers={{
              createdAt: {
                renderer: (field, value) => {
                  const date = new Date(value);
                  return `${date.toLocaleDateString(
                    'pt-br'
                  )} às ${date.toLocaleTimeString('pt-br')}`;
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOrderby({});
              setPage(0);
              setSearchBy('');
              onCancel?.();
            }}
            type="button"
            variant="contained"
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <StyledImportFileDialog
        open={open}
        modelId={modelId}
        restSubmitData={restSubmitData}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          setSuccessUpdate(successUpdate + 1);
        }}
      />
    </Container>
  );
}

export default FilesDialog;
