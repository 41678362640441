import styled from 'styled-components';
import MaterialSelect from '@material-ui/core/Select';
import MaterialFormControl from '@material-ui/core/FormControl';

export const Select = styled(MaterialSelect)`
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
  margin-top: 16px;
`;
