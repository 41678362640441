import React, { useState, useEffect } from 'react';
import restApiClient from '../../../services/restApiClient';

import {
  Container,
  ButtonsContainer,
  HeaderContainer,
  Paper,
  StyledTransferList,
  CancelButton,
  SubmitButton,
  Form,
} from './styles';

function EmployeeRoleLink(props) {
  const modelId = props.match.params?.id;

  const [userCurrentRoles, setUserCurrentRoles] = useState([]);
  const [systemRoles, setSystemRoles] = useState([]);
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);
  const [employeeLogin, setEmployeeLogin] = useState('');

  useEffect(() => {
    restApiClient.role
      .getAll()
      .then((result) => {
        setSystemRoles(result.data.values);
      })
      .catch((e) => console.error(e));

    restApiClient.employee
      .findById(modelId)
      .then((result) => {
        setEmployeeLogin(result.data.login);
        setUserCurrentRoles(result.data.roles);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const newLeftData = systemRoles.filter(
      (x) => !userCurrentRoles.some((y) => y.id === x.id)
    );
    const newRightData = systemRoles.filter((x) =>
      userCurrentRoles.some((y) => y.id === x.id)
    );
    setLeftData(newLeftData);
    setRightData(newRightData);
  }, [userCurrentRoles, systemRoles]);

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const removed = userCurrentRoles
            .filter((action) => leftData.some((x) => x.id === action.id))
            .map((x) => x.id);
          const added = rightData
            .filter((x) => !userCurrentRoles.some((y) => y.id === x.id))
            .map((x) => x.id);
          if (removed.length > 0 || added.length > 0) {
            restApiClient.employee.updateRoles(modelId, added, removed);
          }
          props.history.goBack();
        }}
      >
        <Paper>
          <HeaderContainer>
            <span>
              {employeeLogin
                ? `Vincular cargos para "${employeeLogin}"`
                : 'Vínculo de Cargos'}
            </span>
          </HeaderContainer>
          <StyledTransferList
            leftData={leftData}
            rightData={rightData}
            labelSelector={(item) => item.name ?? ''}
            onChange={(leftData, rightData) => {
              setLeftData(leftData);
              setRightData(rightData);
            }}
          />
        </Paper>
        <ButtonsContainer>
          <CancelButton
            onClick={() => props.history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Cancelar
          </CancelButton>
          <SubmitButton type="submit" variant="contained" color="primary">
            {modelId ? 'Atualizar' : 'Cadastrar'}
          </SubmitButton>
        </ButtonsContainer>
      </Form>
    </Container>
  );
}

export default EmployeeRoleLink;
