import styled from 'styled-components';
import {
  FileEarmarkMedicalFill as MaterialFileEarmarkMedicalFill,
  TextBulletListSquareWarning as MaterialTextBulletListSquareWarning,
  Preview as MaterialPreview,
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextBulletListSquareWarning = styled(
  MaterialTextBulletListSquareWarning
)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const FileEarmarkMedicalFill = styled(MaterialFileEarmarkMedicalFill)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
