import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectSpecialtyDialogControl from '../../components/selectSpecialtyDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectSpecialtyDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectSpecialtyDialog'
  )
);

const customSelectSpecialtyDialogControlTester = rankWith(
  4,
  isSelectSpecialtyDialogControl
);

export default {
  tester: customSelectSpecialtyDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectSpecialtyDialogControl),
};
