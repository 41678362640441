import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { isEmpty } from 'lodash';
import DayJsUtils from '@date-io/dayjs';
import { useSecurity } from '../../../../../extensions/hooks';
import SecuredItem from '../../../../../components/securedItem';
import {
  Hidden,
  Select,
  FileCsv,
  FilePdf,
  AutoDiv,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectContainer,
  HeaderContainer,
  FooterContainer,
  ExportCsvButton,
  ExportPdfButton,
  ButtonContainer,
  CircularProgress,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import Report from '../../../../../forms/report';
import restApiClient from '../../../../../services/restApiClient';
import ReportLabCostUnits from '../../../../../pdf/reportLabCostUnits';
import Section from '../../../../../forms/section';
import SubmitDialog from '../../../../../components/submitDialog';
import { useSelector } from 'react-redux';

function ClinicLabReportList() {
  const unit = useSelector((x) => x.authReducer.user.unit);
  const [selectSectionVisible, setSelectSectionVisible] = useState(false);
  const [exportCsvVisible, setExportCsvVisible] = useState(false);
  const [exportPdfVisible, setExportPdfVisible] = useState(false);
  const [dateIntervalVisible, setDateIntervalVisible] = useState(false);
  const [sections, setSections] = useState(null);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [maxDateTo, setMaxDateTo] = useState(new Date());
  const [maxDateFrom, setMaxDateFrom] = useState(new Date());
  const [minDateFrom, setMinDateFrom] = useState(undefined);
  const [sectionError, setSectionError] = useState('');
  const [selectedReport, setSelectedReport] = useState('');
  const [reportError, setReportError] = useState('');
  const [finalDateError, setFinalDateError] = useState(false);
  const [initialDateError, setInitialDateError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [dateFromError, setDateFromError] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [openSubmit, setOpenSubmit] = useState(false);

  const pdfSecurity = new Map();
  pdfSecurity.set('labCostUnits', [
    [
      Section.actions.getAllBranches,
      Report.clinicActions.getLabCostInClinicByPeriod,
    ],
  ]);
  const csvSecurity = new Map();
  csvSecurity.set('pendingSteps', [[Report.clinicActions.getCsvPendingSteps]]);
  csvSecurity.set('finishedSteps', [
    [Report.clinicActions.getFinishedLabStepsInClinicCSVReport],
  ]);

  const listReports = [
    useSecurity({ value: 'labCostUnits', label: 'Custo Parcial (Seção)' }, [
      [
        Section.actions.getAllBranches,
        Report.clinicActions.getLabCostInClinicByPeriod,
      ],
    ]),
    useSecurity({ value: 'finishedSteps', label: 'Etapas Realizadas' }, [
      [Report.clinicActions.getFinishedLabStepsInClinicCSVReport],
    ]),
    useSecurity({ value: 'pendingSteps', label: 'Etapas Pendentes' }, [
      [Report.clinicActions.getCsvPendingSteps],
    ]),
  ].filter((x) => !!x);

  useEffect(() => {
    if (['labCostUnits'].includes(selectedReport)) {
      restApiClient.section.getAllBranches().then((e) => {
        setSections(e.data);
      });
    }
  }, [selectedReport]);

  async function loadDataCostUnits(unitId = null) {
    setFetching(true);
    restApiClient.orderServiceStep
      .getLabCostInClinicByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY')
      )
      .then((e) => {
        const doc = ReportLabCostUnits.buildLabCostUnitsPdf(
          moment(selectedDateFrom).format('DD/MM/YYYY'),
          moment(selectedDateTo).format('DD/MM/YYYY'),
          { count: 1, values: [unit] },
          sections,
          e.data
        );
        doc.save(
          `RELATORIO_CUSTO_PARCIAL_${unit.name.replaceAll(' ', '_')}.pdf`
        );
        setFetching(false);
      })
      .catch((e) => {
        if (e.response?.status === 406) {
          setDialogText(e.response?.data?.errors);
          setOpenSubmit(true);
          setFetching(false);
        }
      });
  }

  return (
    <Container>
      <HeaderContainer>
        <h1>Relatórios do Laboratório</h1>
      </HeaderContainer>
      <AutoDiv>
        <SelectContainer>
          <FormControl
            required
            variant="outlined"
            size="small"
            error={!isEmpty(reportError)}
          >
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione o tipo de Relatório
            </InputLabel>
            <Select
              value={selectedReport || ''}
              label="Selecione o tipo de Relatório"
              onChange={(e) => {
                setSelectedReport(e.target.value);
                setDateFromError(false);
                setReportError('');
                switch (e.target.value) {
                  case 'labCostUnits':
                    setExportPdfVisible(true);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(false);
                    setSelectSectionVisible(false);
                    break;
                  case 'pendingSteps':
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setExportCsvVisible(true);
                    setSelectSectionVisible(false);
                    break;
                  case 'finishedSteps':
                    setExportPdfVisible(false);
                    setDateIntervalVisible(true);
                    setExportCsvVisible(true);
                    setSelectSectionVisible(false);
                    break;
                  default:
                    setExportCsvVisible(false);
                    setExportPdfVisible(false);
                    setDateIntervalVisible(false);
                    setSelectSectionVisible(false);
                    break;
                }
              }}
            >
              {[
                <MenuItem value="" key={'empty'}>
                  <em>Nenhuma</em>
                </MenuItem>,
              ].concat(
                listReports?.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {!isEmpty(reportError) ? reportError : ''}
            </FormHelperText>
          </FormControl>
        </SelectContainer>
        <Hidden xsUp={!selectSectionVisible}>
          <SelectContainer>
            <FormControl
              required
              variant="outlined"
              size="small"
              error={!isEmpty(sectionError)}
            >
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma seção
              </InputLabel>
              <Select
                value={selectedSection || ''}
                label="Selecione uma seção"
                onChange={(e) => {
                  setSelectedSection(e.target.value);
                  setSectionError('');
                }}
              >
                {[
                  <MenuItem value="" key={'empty'}>
                    <em>Nenhuma</em>
                  </MenuItem>,
                ].concat(
                  sections?.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>
                {!isEmpty(sectionError) ? sectionError : ''}
              </FormHelperText>
            </FormControl>
          </SelectContainer>
        </Hidden>
      </AutoDiv>

      <AutoDiv>
        <Hidden xsUp={!dateIntervalVisible}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-from"
              label="Data Inicial"
              format="DD/MM/YYYY"
              value={selectedDateFrom}
              onChange={(date) => {
                setDateFromError(false);
                setSelectedDateFrom(date.toDate());
                setMinDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateFrom}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setInitialDateError(value)}
              helperText={dateFromError ? 'Este campo é obrigatório.' : ''}
              error={dateFromError}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-to"
              label="Data Final"
              format="DD/MM/YYYY"
              value={selectedDateTo}
              onChange={(date) => {
                setSelectedDateTo(date.toDate());
                setMaxDateFrom(date.toDate());
              }}
              KeyboardButtonProps={{
                'aria-label': 'Editar data',
              }}
              cancelLabel="Cancelar"
              okLabel="Selecionar"
              invalidDateMessage="Data em formato inválido."
              maxDate={maxDateTo}
              maxDateMessage={`A data deve ser menor do que ${dayjs(
                maxDateTo
              ).format('DD/MM/YYYY')}.`}
              minDate={minDateFrom}
              minDateMessage={`A data deve ser maior que ${dayjs(
                minDateFrom
              ).format('DD/MM/YYYY')}.`}
              onError={(value) => setFinalDateError(value)}
            />
          </MuiPickersUtilsProvider>
        </Hidden>
      </AutoDiv>
      <FooterContainer>
        <AutoDiv>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span> Processando Relatório</span>
            </>
          ) : (
            <>
              <Hidden xsUp={!exportCsvVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={csvSecurity.get(selectedReport)}>
                    <ExportCsvButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FileCsv />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'pendingSteps':
                            setFetching(true);
                            restApiClient.orderServiceStep
                              .getCsvPendingSteps()
                              .then((e) => {
                                setFetching(false);
                              });
                            break;
                          case 'finishedSteps':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            ) {
                              setFetching(true);
                              restApiClient.orderServiceStep
                                .getFinishedLabStepsInClinicCSVReport(
                                  moment(selectedDateFrom).format('DD-MM-YYYY'),
                                  moment(selectedDateTo).format('DD-MM-YYYY')
                                )
                                .then((e) => {
                                  setFetching(false);
                                });
                            } else {
                              setDateFromError(true);
                            }
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para CSV
                    </ExportCsvButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
              <Hidden xsUp={!exportPdfVisible}>
                <ButtonContainer>
                  <SecuredItem allowedActions={pdfSecurity.get(selectedReport)}>
                    <ExportPdfButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<FilePdf />}
                      onClick={async (e) => {
                        switch (selectedReport) {
                          case 'labCostUnits':
                            if (
                              selectedDateFrom &&
                              (!initialDateError || !finalDateError)
                            )
                              await loadDataCostUnits(unit?.addressId);
                            else setDateFromError(true);
                            break;
                          default:
                            setReportError('Selecione uma opção válida.');
                            break;
                        }
                      }}
                    >
                      Exportar para PDF
                    </ExportPdfButton>
                  </SecuredItem>
                </ButtonContainer>
              </Hidden>
            </>
          )}
        </AutoDiv>
      </FooterContainer>

      <SubmitDialog
        open={openSubmit}
        onCancel={() => setOpenSubmit(false)}
        onSuccess={() => {
          setOpenSubmit(false);
        }}
        errorMessage={true}
        dialogText={dialogText}
      />
    </Container>
  );
}

export default ClinicLabReportList;
