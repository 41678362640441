import React, { useState, useEffect, useCallback } from 'react';
import BankingOperation from '../../../../forms/bankingOperation';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container } from './styles';
import SwitchOperationTypeDialog from '../../../../components/switchOperationTypeDialog';

function BankingOperationList() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const columns = BankingOperation.tableSchema;

  const loadBankingOperations = useCallback(() => {
    restApiClient.bankingOperation
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('type', searchBy)
              .or()
              .like('value', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadBankingOperations();
  }, [loadBankingOperations]);

  return (
    <Container>
      <CrudTable
        title={'Gestão de Operações Bancárias'}
        modelActions={BankingOperation.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => setOpenDialog(true),
          },
        }}
        customCellRenderers={{
          value: {
            renderer: (field, value) => {
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          'destinationAccount.bankAccount.holderName': {
            renderer: (field, value, column, row) => {
              return value ?? row.destinationAccount.type ?? '-';
            },
          },
          'sourceAccount.bankAccount.holderName': {
            renderer: (field, value, column, row) => {
              return value ?? row.sourceAccount.type ?? '-';
            },
          },
          date: {
            renderer: (field, value) => {
              return new Date(`${value}T03:24:00`).toLocaleDateString('pt-br');
            },
          },
        }}
      />

      <SwitchOperationTypeDialog
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onSuccess={() => {
          setOpenDialog(false);
          loadBankingOperations();
        }}
      />
    </Container>
  );
}

export default BankingOperationList;
