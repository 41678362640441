import { Paths } from '@jsonforms/core';
import { MuiCheckbox } from '@jsonforms/material-renderers';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormHelperText,
  Hidden,
} from '@material-ui/core';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

export const EnumArrayRenderer = ({
  id,
  label,
  schema,
  config,
  visible,
  errors,
  path,
  options,
  data,
  addItem,
  removeItem,
  handleChange,
  required,
  ...otherProps
}) => {
  const isValid = !errors || errors?.length === 0;

  return (
    <Hidden xlUp={!visible}>
      <FormControl required={required} component="fieldset">
        <FormLabel htmlFor={id} error={!isValid} component={'legend'}>
          {label}
        </FormLabel>
        <FormGroup row>
          {options.map((option, index) => {
            const optionPath = Paths.compose(path, `${index}`);
            const checkboxValue = data?.includes(option.value)
              ? option.value
              : undefined;
            return (
              <FormControlLabel
                id={option.value}
                key={option.value}
                style={{ width: schema?.fixedWidth ? '30%' : 'auto' }}
                control={
                  <MuiCheckbox
                    key={'checkbox-' + option.value}
                    isValid={isEmpty(errors)}
                    path={optionPath}
                    handleChange={(_childPath, newValue) =>
                      newValue
                        ? addItem(path, option.value)
                        : removeItem(path, option.value)
                    }
                    data={checkboxValue}
                    errors={errors}
                    schema={schema}
                    visible={visible}
                    {...otherProps}
                  />
                }
                label={option.label}
              />
            );
          })}
        </FormGroup>
        <FormHelperText error>{errors}</FormHelperText>
      </FormControl>
    </Hidden>
  );
};

export default EnumArrayRenderer;
