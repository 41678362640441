const OrderItem = {
  tableSchemaAddItems: [
    { id: 'item.id', label: 'Código Interno', minWidth: 25, align: 'center' },
    { id: 'item.name', label: 'Nome do Item', minWidth: 100, align: 'center' },
    { id: 'item.brand', label: 'Marca', minWidth: 100, align: 'center' },
    {
      id: 'item.barcode',
      label: 'Código de Barras',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'quantity',
      label: 'Quantidade Pedido',
      minWidth: 10,
      align: 'center',
    },
    {
      id: 'approvedQuantity',
      label: 'Quantidade Aprovada',
      minWidth: 10,
      align: 'center',
    },
  ],
  tableSchemaSeparateOrder: [
    { id: 'item.id', label: 'Código', minWidth: 10, align: 'center' },
    { id: 'item.name', label: 'Nome', minWidth: 10, align: 'center' },
    { id: 'item.brand', label: 'Marca', minWidth: 10, align: 'center' },
    {
      id: 'item.itemLocators',
      label: 'Localizador',
      minWidth: 10,
      align: 'center',
    },
    {
      id: 'item.fromStock',
      label: 'Quantidade Estoque',
      minWidth: 10,
      align: 'center',
    },
    {
      id: 'item.toStock',
      label: 'Quantidade Clínica',
      minWidth: 10,
      align: 'center',
    },
    {
      id: 'quantity',
      label: 'Quantidade Solicitada',
      minWidth: 10,
      align: 'center',
    },
    {
      id: 'approvedQuantity',
      label: 'Quantidade Aprovada',
      minWidth: 10,
      align: 'center',
    },
  ],
  actions: {
    getAllByOrder: 'OrderItemController@getAllByOrder',
    getAllByOrderWithItemStock:
      'OrderItemController@getAllByOrderWithItemStock',
    getAll: 'OrderItemController@getAll',
    findById: 'OrderItemController@findById',
    create: 'OrderItemController@create',
    update: 'OrderItemController@patchUpdate',
    delete: 'OrderItemController@delete',
  },
};

export default OrderItem;
