import React, { useState, useEffect } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import Attendance from '../../../../../forms/attendance';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  FileEarmarkMedicalFill,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectContainer,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from './styles';
import DayJsUtils from '@date-io/dayjs';
import moment from 'moment';

function ClinicProceduresAuditList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [dentists, setDentists] = useState([]);
  const [selectedDentist, setSelectedDentist] = useGlobal('selectedDentist');
  const [selectedDate, setSelectedDate] = useGlobal('selectedDate');
  const [dates, setDates] = useGlobal('dates');
  const [openCalendar, setOpenCalendar] = useState(false);
  const columns = Attendance.tableSchema;

  useEffect(() => {
    if (selectedDentist) {
      restApiClient.attendance
        .getAllInClinic(
          selectedDentist,
          selectedDate ?? null,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftChild, rightChild) => {
                  leftChild.and((leftChild, rightChild) => {
                    leftChild.like('status', 'Sob Auditoria');
                    rightChild.and((leftOp, rightOp) => {
                      leftOp.isNull('replacedDentistId');
                      rightOp
                        .eq('type', 'Fixo')
                        .or()
                        .eq('type', 'Procedimentos')
                        .or()
                        .eq('type', 'Pacientes');
                    });
                  });
                  rightChild
                    .like('id', searchBy)
                    .or()
                    .like('dentist.user.name', searchBy)
                    .or()
                    .like('type', searchBy)
                    .or()
                    .like('patient.name', searchBy)
                    .or()
                    .like('procedure.name', searchBy)
                    .or()
                    .like('unit.name', searchBy)
                    .or()
                    .like('status', searchBy);
                })
                .toString()
            : new FilterTree()
                .and((leftChild, rightChild) => {
                  leftChild.like('status', 'Sob Auditoria');
                  rightChild.and((leftOp, rightOp) => {
                    leftOp.isNull('replacedDentistId');
                    rightOp
                      .eq('type', 'Fixo')
                      .or()
                      .eq('type', 'Procedimentos')
                      .or()
                      .eq('type', 'Pacientes');
                  });
                })
                .toString(),
          orderBy
        )
        .then((e) => {
          if (!selectedDate) {
            const listDates = e.data.values.map((attendance) => {
              let date = new Date(attendance.attendanceDate + ' 00:00:00');
              return date.getTime();
            });
            setDates(listDates);
          }
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, selectedDentist, selectedDate]);

  useEffect(() => {
    if (!selectedDentist && !selectedDate) {
      setGlobal({
        selectedDentist: -1,
        selectedDate: null,
      });
    }
    restApiClient.dentist
      .getAll(null, null, null, { name: 'asc' })
      .then((e) => {
        setDentists(e.data.values);
      });
  }, [selectedDate, selectedDentist]);

  function disableSundays(dayJsDate) {
    const date = dayJsDate.toDate();
    return date.getDay() === 0 || !dates.includes(date.getTime());
  }

  return (
    <Container>
      <CrudTable
        title={'Auditar Procedimentos'}
        customHeaderComponent={
          <SelectContainer>
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-dentist-native-simple">
                Dentista
              </InputLabel>
              <Select
                id="dentist"
                size="small"
                defaultValue={selectedDentist}
                value={selectedDentist ?? -1}
                label="Dentista"
                onChange={(e) => {
                  setSelectedDate(null);
                  setSelectedDentist(e.target.value);
                }}
              >
                {[
                  <MenuItem value={-1} key={'all'}>
                    TODOS
                  </MenuItem>,
                ].concat(
                  dentists?.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            {selectedDentist ? (
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-from"
                  inputVariant="outlined"
                  size="small"
                  label="Data"
                  format="DD/MM/YYYY"
                  autoOk
                  initialFocusedDate={selectedDate}
                  value={selectedDate}
                  inputValue={null}
                  onChange={(date) => {
                    setSelectedDate(
                      date ? moment(date.toDate()).format('YYYY-MM-DD') : null
                    );
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => setOpenCalendar(true)}
                  open={openCalendar}
                  onClose={() => setOpenCalendar(false)}
                  KeyboardButtonProps={{
                    'aria-label': 'Editar data',
                  }}
                  clearable
                  shouldDisableDate={disableSundays}
                  disableFuture
                  cancelLabel="Cancelar"
                  okLabel="Selecionar"
                  clearLabel="Limpar"
                />
              </MuiPickersUtilsProvider>
            ) : null}
          </SelectContainer>
        }
        modelActions={Attendance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          audit: {
            icon: <FileEarmarkMedicalFill />,
            onClick: (id) => {
              history.push({
                pathname: `/clinic/dentist/audit-procedures/procedure/${id}`,
                state: {
                  readonly: true,
                },
              });
            },
            title: 'Auditar Procedimentos',
            allowedActions: [
              [
                Attendance.actions.findById,
                Attendance.actions.setApproved,
                Attendance.actions.setDisapproved,
              ],
            ],
          },
        }}
        customCellRenderers={{
          attendanceDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY') : '-';
            },
          },
        }}
        customColoring={(id, value, column, row) => {
          if (row.history) {
            return '#ffe0e0';
          }
        }}
      />
    </Container>
  );
}

export default ClinicProceduresAuditList;
