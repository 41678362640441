const BudgetFile = {
  actions: {
    create: 'BudgetFileController@submitFile',
    getAll: 'BudgetFileController@getAll',
    downloadFile: 'BudgetFileController@downloadFile',
    getAllByBudgetId:
      'BudgetFileController@getAllByBudgetId',
    submitFile: 'BudgetFileController@submitFile',
    delete: 'BudgetFileController@delete',
  },
};
export default BudgetFile;
