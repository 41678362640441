/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import restApiClient from '../../services/restApiClient';
import { 
  Container,
  Paper,
  FormControl,
  InputLabel, 
  MenuItem,
  Select,
  SelectContainer,
  SchedulerContainer
} from './styles';
import { useHistory } from 'react-router-dom';
import SchedulerComponent from '../schedulerComponent';

function CenterRadiologyScheduleCard(props) {
  const history = useHistory();
  const [treatments, setTreatments] = useState(null);
  const [selectedTreatment, setSelectedTreatment] = useState(null);

  useEffect(() => {
    restApiClient.treatment
      .getAllByConsiderTime()
      .then((e) => {
        setTreatments(e.data);
        setSelectedTreatment(e.data[0]);
      });
  }, []);
  
  return (
    <Container>
      <Paper>
        <SelectContainer>
          <FormControl variant="outlined" size="small">
            <InputLabel htmlFor="outlined-selectedUnit-native-simple">
              Selecione um Tratamento
            </InputLabel>
            <Select
              id="treatment"
              value={selectedTreatment || ''}
              label="Selecione um Tratamento"
              onChange={(e) => {
                setSelectedTreatment(e.target.value);
              }}
            >
              {treatments?.map((optionValue) => (
                <MenuItem value={optionValue} key={optionValue.id}>
                  {optionValue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </SelectContainer>
        <SchedulerContainer>
          <SchedulerComponent
            treatment={selectedTreatment}
            onSuccess={(scheduling) => {
              history.push({
                pathname: `/radiology/schedule/create`,
                state: {
                  treatmentId: selectedTreatment.id,
                  scheduling: scheduling,
                  uischemaAlternative: true
                },
              });
            }}
          />
        </SchedulerContainer>
      </Paper>
    </Container>
  );
}

export default CenterRadiologyScheduleCard;
