import React, { useState, useEffect } from 'react';
import Item from '../../../../forms/item';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  AddBox,
  SelectContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from './styles';
import ItemLocatorDialog from '../../../../components/locatorDialog/item';
import { useSelector } from 'react-redux';

function ItemLocatorList({ history }) {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const columns = Item.itemLocatorTableSchema;
  const [units, setUnits] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(user?.unitId ?? '');

  async function loadItems() {
    if (selectedUnit) {
      restApiClient.item
        .getAllByUnitId(
          selectedUnit,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .and((leftOp, rightOp) => {
                  leftOp.isNull('deletedAt');
                  rightOp
                    .like('id', searchBy)
                    .or()
                    .like('name', searchBy)
                    .or()
                    .like('brand', searchBy)
                    .or()
                    .like('barcode.barcode', searchBy)
                    .or()
                    .like('itemLocators.locator', searchBy);
                })
                .toString()
            : new FilterTree().isNull('deletedAt').toString(),
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }

  useEffect(() => {
    loadItems();
  }, [rowsPerPage, page, orderBy, searchBy, selectedUnit]);

  useEffect(() => {
    restApiClient.unit.getAll().then((e) => {
      setUnits(e.data);
    });
  }, []);

  return (
    <Container>
      <CrudTable
        title={'Atualizar Localizadores'}
        customHeaderComponent={
          <SelectContainer>
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnit || ''}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                }}
              >
                {[
                  <MenuItem value="" key={'empty'}>
                    <em>Nenhuma</em>
                  </MenuItem>,
                ].concat(
                  units?.values.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </SelectContainer>
        }
        modelActions={Item.itemLocatorActions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          insert: {
            icon: <AddBox />,
            onClick: (id, row) => {
              setOpen(true);
              setSelectedRow(row);
            },
            title: 'Definir Localizador',
            allowedActions: [
              [Item.itemLocatorActions.create, Item.itemLocatorActions.update],
            ],
          },
        }}
        customCellRenderers={{
          itemLocators: {
            renderer: (field, value) => {
              return value[0]?.locator ? value[0]?.locator : '-';
            },
          },
        }}
      />
      <ItemLocatorDialog
        open={open}
        item={selectedRow}
        unitId={selectedUnit}
        onCancel={() => setOpen(false)}
        onSuccess={(itemSuccess) => {
          const newRows = { ...rows };
          const item = newRows.values.find(
            (item) => item.id === itemSuccess.itemId
          );
          item.itemLocators[0] = itemSuccess;
          setOpen(false);
        }}
      />
    </Container>
  );
}

export default ItemLocatorList;
