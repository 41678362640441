import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  ExclamationTriangleFill as MaterialExclamationTriangleFill,
  AddToQueue as MaterialAddToQueue,
  TextBulletListSquareWarning as MaterialTextBulletListSquareWarning
} from '../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ExclamationTriangleFill = styled(MaterialExclamationTriangleFill)`
  width: 25px;
  height: 25px;
  fill: red;
`;

export const AddToQueue = styled(MaterialAddToQueue)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const TextBulletListSquareWarning = styled(MaterialTextBulletListSquareWarning)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
