const DentistOfficeHour = {
  schema: {
    type: 'object',
    properties: {
      beginInterval: {
        type: 'string',
        format: 'time',
        beginInterval: '',
        customErrors: '',
      },
      endInterval: {
        type: 'string',
        format: 'time',
        endInterval: '',
        customErrors: '',
      },
      dayOfWeek: {
        type: 'array',
        items: {
          type: 'string',
          enum: [
            'Domingo',
            'Segunda-Feira',
            'Terça-Feira',
            'Quarta-Feira',
            'Quinta-Feira',
            'Sexta-Feira',
            'Sábado',
          ],
        },
        fixedWidth: true,
        customErrors: '',
      },
    },
    required: ['beginInterval', 'endInterval', 'dayOfWeek'],
  },
  updateSchema: {
    type: 'object',
    properties: {
      beginInterval: {
        type: 'string',
        format: 'time',
        beginInterval: '',
        customErrors: '',
      },
      endInterval: {
        type: 'string',
        format: 'time',
        endInterval: '',
        customErrors: '',
      },
      dayOfWeek: {
        type: 'string',
        customErrors: '',
      },
    },
    required: ['beginInterval', 'endInterval'],
  },
  uischema: {
    type: 'Group',
    label: 'Horário de Atendimento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dia(s) da Semana',
            scope: '#/properties/dayOfWeek',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Início',
            scope: '#/properties/beginInterval',
          },
          {
            type: 'Control',
            label: 'Término',
            scope: '#/properties/endInterval',
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Horário de Atendimento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Dia da Semana',
            scope: '#/properties/dayOfWeek',
          },
        ],
        options: {
          readonly: true,
        },
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Início',
            scope: '#/properties/beginInterval',
          },
          {
            type: 'Control',
            label: 'Término',
            scope: '#/properties/endInterval',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'dayOfWeek', label: 'Dia', minWidth: 100, align: 'center' },
    { id: 'beginInterval', label: 'Início', minWidth: 100, align: 'center' },
    { id: 'endInterval', label: 'Término', minWidth: 100, align: 'center' },
  ],
  actions: {
    create: 'DentistOfficeHourController@create',
    update: 'DentistOfficeHourController@patchUpdate',
    findById: 'DentistOfficeHourController@findById',
    getAllByUnit: 'DentistOfficeHourController@getAllByUnit',
    getAllByChair: 'DentistOfficeHourController@getAllByChair',
    getAllByChairDentist: 'DentistOfficeHourController@getAllByChairDentist',
    delete: 'DentistOfficeHourController@delete',
  },
};

export default DentistOfficeHour;
