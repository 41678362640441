import styled from 'styled-components';
import TreeView from '../../../../../components/treeView';
import { Paper as PaperMaterial } from '@material-ui/core';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectContainer = styled.div`
  width: 650px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  h1 {
    width: 100%;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 22px;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  width: 98%;
  margin-bottom: 10px;
`;

export const CustomTreeView = styled(TreeView)`
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Paper = styled(PaperMaterial)`
  margin-top: 15px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MuiPickersUtilsProvider = styled(MaterialMuiPickersUtilsProvider)`
  width: 300px;
`;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  margin: 0px !important;
  padding-bottom: 5px;
`;
