import React, { useState, useEffect } from 'react';
import Sale from '../../../forms/sale';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, AssignmentTurnedIn, Printer } from './styles';
import SaleDialog from '../../../components/saleCreateUpdateDialog';
import PrintSaleDialog from '../../../components/printCompletedSale';

function SaleList({ history, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openCompletedSale, setOpenCompletedSale] = useState(false);
  const [selectedSale, setSelectedSale] = useState(false);
  const [selectedCompletedSale, setSelectedCompletedSale] = useState(null);

  const columns = Sale.tableSchema;

  useEffect(() => {
    restApiClient.sale
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('buyer.social_name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Gestão de Transferências'}
        modelActions={Sale.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => setOpen(true),
            title: 'Criar Transferência',
            allowedActions: [Sale.actions.create],
          },
          addItem: {
            icon: <AssignmentTurnedIn />,
            onClick: (id) => {
              history.push(`/sale/add-items/${id}`);
            },
            title: 'Gerenciar Itens',
            allowedActions: [
              Sale.actions.findById,
              Sale.actions.getAllBySaleId,
              Sale.actions.update,
              Sale.actions.completeTransfer,
            ],
          },
          printTransfer: {
            icon: <Printer />,
            selector: (row) => row.status === 'completed_operation',
            onClick: async (id) => {
              await restApiClient.sale.findById(id).then((e) => {
                setSelectedCompletedSale(e.data);
                setOpenCompletedSale(true);
              });
            },
            title: 'Imprimir Comprovante',
            allowedActions: [Sale.actions.findById],
          },
          update: {
            onClick: (saleId) => {
              setSelectedSale(saleId);
              setOpen(true);
            },
            allowedActions: [Sale.actions.update],
            selector: (row) => row.status !== 'completed_operation',
          },
          delete: {
            onClick: (id, index) => {
              restApiClient.sale.delete(id).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
            selector: (row) => row.status !== 'completed_operation',
          },
        }}
        customCellRenderers={{
          status: {
            renderer: (field, value) => {
              switch (value) {
                case 'operation_in_progress':
                  return 'Operação em andamento';
                case 'completed_operation':
                  return 'Operação concluída';
                default:
                  return '-';
              }
            },
          },
          buyer: {
            renderer: (field, value, column, row) => {
              return row.buyer?.isLegalPerson
                ? row.buyer?.fantasyName
                : row.buyer?.socialName;
            },
          },
        }}
      />
      <SaleDialog
        open={open}
        sale={Sale}
        selectedSale={selectedSale}
        onCancel={() => {
          setOpen(false);
          setSelectedSale(null);
        }}
        onSuccess={(result) => {
          const sale = result.data;
          const newRows = [...rows.values];
          newRows[newRows.length] = sale;
          setRows({ ...rows, values: newRows });
          setOrderby({ id: 'desc' });
          setSelectedSale(null);
          setOpen(false);
        }}
      />

      <PrintSaleDialog
        open={openCompletedSale}
        selectedSale={selectedCompletedSale}
        onCancel={() => {
          setSelectedCompletedSale(null);
          setOpenCompletedSale(false);
        }}
      />
    </Container>
  );
}

export default SaleList;
