import styled from 'styled-components';
import {
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
  Select as MaterialSelect,
  Button as MaterialButton,
  TextField,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
  FormHelperText as MaterialFormHelperText,
} from '@material-ui/core';

export const Container = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 0px !important;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Input = styled(TextField)`
  width: 100%;
  margin-bottom: 15px !important;
  color: white;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const SelectContainer = styled.div`
  width: 300px;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const FormHelperText = styled(MaterialFormHelperText)``;
