export const objectFilter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(key, obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

export const objectReduce = (obj, predicate, initValue) =>
  Object.keys(obj).reduce(
    (res, key, index, arr) => predicate(res, key, obj[key], index, arr),
    initValue
  );

export const objectMap = (obj, predicate) => {
  const newObj = {};
  Object.keys(obj).map((key) => (newObj[key] = predicate(key, obj[key])));
  return newObj;
};

export const objectToArray = (obj, predicate) =>
  Object.keys(obj).reduce(
    (res, key) => (res.push(predicate(key, obj[key])), res),
    []
  );

export const objectDiff = (obj1, obj2) => {
  return [...Object.keys(obj1), ...Object.keys(obj2)]
    .filter(
      (key) =>
        !(key in obj1) ||
        !(key in obj2) ||
        JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])
    )
    .reduce(
      (res, key) => (
        (res[key] = key in obj2 ? obj2[key] ?? null : obj1[key] ?? null), res
      ),
      {}
    );
};

export const hasSameFields = (obj1, obj2) => {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  for (let i = 0; i < obj1Keys.length; i++) {
    if (!obj2Keys.includes(obj1Keys[i])) return false;
  }
  return true;
};

export const objectSort = (obj) =>
  Object.keys(obj)
    .sort()
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

export const isNullOrEmptyObject = (obj) => !obj || isEmptyObject(obj);
export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const toFixed = (op, fixed) => {
  return Math.round(op * 100).toFixed(fixed) / 100;
};
