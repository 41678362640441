import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../store';
import Layout from '../components/layout';
import GlobalStyles from '../styles/globalStyles';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Layout />

          <GlobalStyles />
        </Router>
      </PersistGate>
    </Provider>
  );
};
export default App;
