const EndProduct = {
    schema: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            minLength: 3,
            maxLength: 100,
            customErrors: '',
          },
          workflows: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                stepId: {
                  title: '',
                  type: 'number',
                  format: 'selectStepDialog',
                  customErrors: '',
                },
              },
              required: [
                'stepId',
              ],
            },
          },
        },
        required: ['name'],
      },
    
      uischema: {
        type: 'Group',
        label: 'Cadastro de Produto Final',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome',
                scope: '#/properties/name',
              },
            ],
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Fluxo(s) de trabalho',
                scope: '#/properties/workflows',
                options: {
                  showSortButtons: true
                }
              },
            ],
          },
        ],
      },
      updateUischema: {
        type: 'Group',
        label: 'Produto final',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Nome',
                scope: '#/properties/name',
              },
            ],
          },
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Fluxo(s) de trabalho',
                scope: '#/properties/workflows',
                options: {
                  showSortButtons: true
                }
              },
            ],
          },
        ],
        rule: {
          effect: 'DISABLE',
          condition: {
            scope: '#/properties/disableFields',
            schema: {
              const: true,
            },
          },
        },
      },
      tableSchema: [
        { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
        { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
        { id: 'enabled', label: 'Habilitado', align: 'center' },
      ],
      alternativeTableSchema: [
        { id: 'id', label: 'Código Interno', minWidth: 25, align: 'center' },
        { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
      ],
      actions: {
        getAll: 'EndProductController@getAll',
        findById: 'EndProductController@findById',
        create: 'EndProductController@create',
        update: 'EndProductController@patchUpdate',
      },
};
  
  export default EndProduct;
  
