/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import ExpenseType from '../../forms/expenseType';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';

import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AddBox,
  StyledCrudTable,
} from './styles';

const ExpenseTypeDialog = ({
  open,
  selectedId,
  onSuccess,
  onClose,
  restModel,
  modelData,
  type,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const columns = ExpenseType.tableSchema;

  useEffect(() => {
    restApiClient.expenseType
      .getAllLeaf(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('parent.name', searchBy)
              .or()
              .like('parent.parent.name', searchBy)
              .or()
              .like('parent.parent.parent.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        const rows = e.data;
        let expenseTypes = e.data.values;
        expenseTypes = expenseTypes.map((expenseType) => {
          if (expenseType?.parent?.parent?.parent) {
            expenseType.name = `${expenseType.parent.parent.parent.name} > ${expenseType.parent.parent.name} > ${expenseType.parent.name} > ${expenseType.name}`;
          } else if (expenseType?.parent?.parent) {
            expenseType.name = `${expenseType.parent.parent.name} > ${expenseType.parent.name} > ${expenseType.name}`;
          } else if (expenseType?.parent) {
            expenseType.name = `${expenseType.parent.name} > ${expenseType.name}`;
          }
          return expenseType;
        });
        rows.values = expenseTypes;
        setRows(rows);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <StyledCrudTable
            title={'Seleção de Tipo de Despesa'}
            modelActions={ExpenseType.actions}
            columns={columns}
            rows={rows}
            page={page}
            setPage={(page) => setPage(page)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[3]}
            setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            onOrderByChange={(orderBy) => setOrderby(orderBy)}
            orderBy={orderBy}
            setOrderBy={(value) => setOrderby(value)}
            searchBy={searchBy}
            setSearchBy={(value) => setSearchBy(value)}
            actions={{
              insert: {
                icon: <AddBox />,
                onClick: (id, data) => {
                  if (selectedId) {
                    restModel
                      ?.update(
                        selectedId,
                        {},
                        type === 'income'
                          ? { incomeExpenseTypeId: id }
                          : {
                              expenseTypeId: id,
                            }
                      )
                      .then((x) => onSuccess?.(x));
                  } else {
                    restModel
                      ?.create({
                        sectorId: modelData.sectorId,
                        expenseTypeId: id,
                        type: modelData.type,
                      })
                      .then((x) => onSuccess?.(x));
                  }
                },
                title: 'Vincular',
                allowedActions: [ExpenseType.actions.getAllLeaf],
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ExpenseTypeDialog;
