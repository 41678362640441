import React from 'react';
import { rankWith, and, schemaMatches, isEnumControl } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import { MuiSelect } from '@jsonforms/material-renderers';
import { MaterialInputControl } from '@jsonforms/material-renderers';
import isEmpty from 'lodash/isEmpty';

export const MaterialEnumControl = (props) => {
  let visible;
  if (props.schema.customRules) {
    const pathArray = props.path.split('.');
    pathArray.pop();
    let crrPtr = props.config.completeData;

    pathArray.forEach((x) => {
      crrPtr = crrPtr[x];
    });

    visible = crrPtr.showDentalArch || crrPtr.dentalArch;
  }

  return (
    <MaterialInputControl {...props} visible={visible} input={MuiSelect} />
  );
};

const isCustomEnumControl = and(
  isEnumControl,
  schemaMatches((schema) => !isEmpty(schema) && schema.format === 'customEnum')
);

export const materialEnumControlTester = rankWith(5, isCustomEnumControl);

export default {
  tester: materialEnumControlTester,
  renderer: withJsonFormsEnumProps(MaterialEnumControl),
};
