import React, { useState, useEffect, useCallback, useRef } from 'react';
import OrderService from '../../../../forms/orderService';
import OrderServiceStep from '../../../../forms/orderServiceStep';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  ExclamationTriangleFill,
  Preview,
  Assignment,
  Printer,
  PrintOS,
} from './styles';
import ViewUrgencyDialog from '../../../../components/viewUrgencyDialog';
import { first } from 'lodash';
import VoucherOS from '../../../../components/voucherOS';
import { useReactToPrint } from 'react-to-print';

function LabOrderServiceList({ history, props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [justification, setJustification] = useState('');
  const [openDentist, setOpenDentist] = useState(false);
  const [justificationDentist, setJustificationDentist] = useState('');
  const [orderService, setOrderService] = useState(null);
  const columns = OrderService.labOSPendingTableSchema;
  const componentRef = useRef();

  const loadServiceOrders = useCallback(() => {
    restApiClient.orderServiceStep
      .getAllOrderServiceStepSended(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('orderService.patient.record_number', searchBy)
              .or()
              .like('id', searchBy)
              .or()
              .like('orderService.patient.name', searchBy)
              .or()
              .like('dentist.user.name', searchBy)
              .or()
              .like('step.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadServiceOrders();
  }, [loadServiceOrders]);

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Ordem de Serviço`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }
      @media print { 
        grid {
          page-break-inside: avoid;
        }
      }`,
  });

  return (
    <Container>
      <CrudTable
        title={'Ordens de Serviço Enviadas'}
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          viewOrderService: {
            icon: <Preview />,
            onClick: (orderServiceStepId, row) => {
              history.push({
                pathname: `/lab/orderService/update/${
                  row.orderService.endProductId
                }/${orderServiceStepId}/${true}/${row.workflowPosition}`,
                state: {
                  dentistId: row.orderServiceStep.dentistId,
                  date: row.orderServiceStep.createdAt,
                  allowView: true,
                  patientName: row.orderService?.patient?.name,
                  recordNumber: row.orderService?.patient?.recordNumber,
                },
              });
            },
            allowedActions: [
              [
                OrderServiceStep.actions.setQueued,
                OrderServiceStep.actions.setRejected,
              ],
            ],
            title: 'Visualizar Etapa',
            selector: (row) =>
              row?.status === 'Recusada Dentista (Laboratório)',
          },
          evaluateOrderService: {
            icon: <Assignment />,
            onClick: (orderServiceStepId, row) => {
              history.push({
                pathname: `/lab/orderService/update/${
                  row.orderService.endProductId
                }/${orderServiceStepId}/${true}/${row.workflowPosition}/${
                  row.orderServiceId
                }`,
                state: {
                  dentistId: row.dentistId,
                  date: row.createdAt,
                  allowView: false,
                  patientName: row.orderService?.patient?.name,
                  recordNumber: row.orderService?.patient?.recordNumber,
                },
              });
            },
            allowedActions: [
              [
                OrderServiceStep.actions.defineExecutor,
                OrderServiceStep.actions.setQueued,
                OrderServiceStep.actions.setRejected,
              ],
            ],
            title: 'Avaliar Etapa',
            selector: (row) =>
              row?.status === 'Enviada (Laboratório)' ||
              row?.status === 'Retrabalho Enviado (Laboratório)',
          },
          viewUrgency: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceStepId, row) => {
              setOpen(true);
              setJustification(row?.schedule?.justification);
            },
            allowedActions: [
              OrderServiceStep.actions.getAllOrderServiceStepSended,
            ],
            title: 'Visualizar Urgência',
            selector: (row) => row?.schedule?.urgency,
          },
          viewDentistRejectedJustification: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceId, row) => {
              setOpenDentist(true);
              setJustificationDentist(row?.dentistRejectedJustification);
            },
            allowedActions: [
              OrderServiceStep.actions.getAllOrderServiceStepSended,
            ],
            title: 'Visualizar Justificativa',
            selector: (row) =>
              row?.status === 'Recusada Dentista (Laboratório)',
          },
          printOS: {
            icon: <Printer />,
            onClick: async (orderServiceId, row) => {
              await setOrderService({
                ...row.orderService,
                orderServiceStep: row,
              });
              handlePrint();
            },
            title: 'Imprimir Etapa',
            selector: (row) => row?.status === 'Enviada (Laboratório)',
          },
        }}
        customCellRenderers={{
          'schedule.labReturnDate': {
            renderer: (field, value) => {
              const date = new Date(value + ' 00:00:00');
              return `${date.toLocaleDateString('pt-br')}`;
            },
          },
          'step.suggestedExecutors': {
            renderer: (field, value) => {
              return first(value) ? first(value)?.employee?.user.name : '-';
            },
          },
        }}
      />

      <ViewUrgencyDialog
        open={openDentist}
        title={'Recusa - Justificativa'}
        justification={justificationDentist}
        onCancel={() => {
          setOpenDentist(false);
        }}
      />

      <ViewUrgencyDialog
        open={open}
        title={'Urgência - Justificativa'}
        justification={justification}
        onCancel={() => {
          setOpen(false);
        }}
      />

      <PrintOS ref={componentRef}>
        <VoucherOS isClinic orderService={orderService} />
      </PrintOS>
    </Container>
  );
}

export default LabOrderServiceList;
