/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
  Container,
  DialogContent,
  InputTextarea,
  Dialog,
  ActionsContainer,
  CancelButton,
  DialogActions,
  Card,
  CardHeader,
  HeaderContainer,
  SubmitButton,
  FormDiv,
  Grid,
} from './styles';
import { isEmpty } from 'lodash';
import restApiClient from '../../services/restApiClient';

function DisapprovedJusticationDialog({
  auditId = null,
  onSuccess,
  onCancel,
  disapprovedJustification = null,
  cancelSubmit = false,
  ...props
}) {
  const [justification, setJustification] = useState('');
  const [errors, setErrors] = React.useState({});

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (cancelSubmit) {
              onSuccess?.(justification);
            } else {
              restApiClient.attendance
                .setDisapproved(auditId, justification)
                .then((e) => {
                  onSuccess?.();
                })
                .catch((result) => {
                  setErrors(result.response.data.errors);
                });
            }
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Justificativa - Reprovação" />
              </HeaderContainer>
              <FormDiv>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <InputTextarea
                      id="outlined-multiline-static"
                      size="small"
                      label="Justificativa"
                      value={disapprovedJustification ?? justification}
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{
                        maxLength: 250,
                        readOnly: !!disapprovedJustification,
                      }}
                      onChange={(e) => {
                        setErrors({});
                        setJustification(e.target.value);
                      }}
                      required
                      error={!isEmpty(errors?.disapprovedJustification)}
                      helperText={
                        !isEmpty(errors?.disapprovedJustification)
                          ? errors?.disapprovedJustification[0]
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </FormDiv>
            </Card>
          </DialogContent>
          <DialogActions>
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  setJustification('');
                  onCancel?.();
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Fechar
              </CancelButton>
              {!disapprovedJustification ? (
                <SubmitButton type="submit" variant="contained" color="primary">
                  Salvar
                </SubmitButton>
              ) : null}
            </ActionsContainer>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default DisapprovedJusticationDialog;
