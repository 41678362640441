import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectPaymentTypeDialogControl from '../../components/selectPaymentTypeDialogControl';
import isEmpty from 'lodash/isEmpty';

const isSelectPaymentTypeDialogControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectPaymentTypeDialog'
  )
);

const customSelectPaymentTypeDialogControlTester = rankWith(
  4,
  isSelectPaymentTypeDialogControl
);

export default {
  tester: customSelectPaymentTypeDialogControlTester,
  renderer: withJsonFormsEnumProps(SelectPaymentTypeDialogControl),
};
