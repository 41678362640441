const DentistFixedValue = {
  schema: {
    type: 'object',
    properties: {
      dentistId: {
        type: 'number',
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
    },
    required: ['value'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de valor fixo do dentista',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: '',
            scope: '#/properties/value',
          },
        ],
      },
    ],
  },
  updateUischema: {
    type: 'Group',
    label: 'Atualização de valor fixo do dentista',
    elements: [
      {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: '',
            scope: '#/properties/value',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAllByDentist: 'DentistFixedValueController@getAllByDentist',
    findById: 'DentistFixedValueController@findById',
    create: 'DentistFixedValueController@create',
    patchUpdate: 'DentistFixedValueController@patchUpdate',
  },
};

export default DentistFixedValue;
