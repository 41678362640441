import React, { useState, useEffect } from 'react';
import Substitute from '../../../../../forms/substitute';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, ViewShow } from './styles';
import moment from 'moment';

function ClinicSubstituteList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Substitute.tableSchema;

  useEffect(() => {
    restApiClient.substitute
      .getAllInClinic(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('substituteDentist.user.name', searchBy)
              .or()
              .like('replacedDentist.user.name', searchBy)
              .or()
              .like('unit.name', searchBy)
              .or()
              .like('replacementDate', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Substitutos '}
        modelActions={Substitute.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              history.push({
                pathname: '/clinic/dentist/substitute/create',
                state: { isClinic: true },
              });
            },
          },
          update: {
            onClick: (substituteId) => {
              history.push({
                pathname: `/clinic/dentist/substitute/update/${substituteId}`,
                state: { isClinic: true },
              });
            },
          },
          view: {
            icon: <ViewShow />,
            onClick: (substituteId) => {
              history.push({
                pathname: `/clinic/dentist/substitute/${substituteId}`,
                state: {
                  readonly: true,
                },
              });
            },
            title: 'Visualizar Substituto',
            allowedActions: [Substitute.actions.getAll],
          },
          delete: {
            onClick: (substituteId, index) => {
              restApiClient.substitute.delete(substituteId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
        }}
        customCellRenderers={{
          replacementDate: {
            renderer: (index, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
        }}
      />
    </Container>
  );
}

export default ClinicSubstituteList;
