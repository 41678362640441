/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import isString from 'lodash/isString';
import {
  Container,
  ActionsContainer,
  SubmitButton,
  Input,
  ContentContainer,
  DialogTitle,
  CancelButton,
  DialogContent,
} from './styles';
import { isNullOrEmptyObject } from '../../extensions/object';

function CreateUpdateGroupDialog({
  restModel,
  onSuccess,
  onError,
  onCancel,
  parentId,
  action,
  fieldName = 'name',
  type = 'text',
  label = 'Nome',
  ...props
}) {
  const [name, setName] = useState('');
  const [requestModelData, setRequestModelData] = useState({});
  const [error, setError] = useState({
    state: false,
    description: '',
  });

  useEffect(() => {
    if (action.key === 'edit') {
      if (parentId) {
        restModel?.findById(parentId).then((e) => {
          setRequestModelData(e.data);
          setName(e.data[fieldName]);
        });
      }
    } else {
      setRequestModelData({});
      setName('');
    }
  }, [action, parentId, restModel]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNullOrEmptyObject(requestModelData)) {
      const obj = { parentId };
      obj[fieldName] = isString(name) ? name.trim() : name;
      await restModel
        ?.create(obj)
        .then(async (result) => {
          onSuccess?.(result);
          setError({
            state: false,
            description: '',
          });
          setName('');
        })
        .catch((e) => {
          if (e.response?.status === 400) {
            onError?.(e.response);
            setError({
              state: true,
              description: e.response.data.errors[fieldName]?.join(' '),
            });
          }
        });
    } else {
      const obj = {
        ...requestModelData,
      };
      obj[fieldName] = isString(name) ? name.trim() : name;
      restModel
        ?.update(parentId, requestModelData, obj)
        .then((x) => onSuccess(x))
        .catch((x) => {
          if (x.response?.status === 400) {
            onError?.(x.response);
            setError({
              state: true,
              description: x.response.data.errors[fieldName]?.join(' '),
            });
          }
        });
    }
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {action.actionLabel} de {action.depthLabel}
          </DialogTitle>
          <DialogContent>
            <ContentContainer>
              <Input
                required
                type={type}
                label={label}
                value={name}
                error={error.state}
                helperText={error.description}
                onChange={(e) => {
                  setError({
                    state: false,
                    description: '',
                  });
                  setName(e.target?.value?.toUpperCase());
                }}
              />
            </ContentContainer>
          </DialogContent>
          <DialogActions>
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  setError({
                    state: false,
                    description: '',
                  });
                  setName('');
                  onCancel?.();
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SubmitButton type="submit" variant="contained" color="primary">
                {action.key === 'add' ? 'Cadastrar' : 'Editar'}
              </SubmitButton>
            </ActionsContainer>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default CreateUpdateGroupDialog;
