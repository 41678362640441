const Procedure = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        description: 'Nome',
        customErrors: '',
      },
      price: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      specialtyId: {
        type: 'number',
        format: 'selectSpecialtyDialog',
        customErrors: '',
      },
      toothNumber: {
        type: 'boolean',
        customErrors: '',
      },
      dentalArch: {
        type: 'boolean',
        customErrors: '',
      },
    },
    required: [
      'name',
      'price',
    ],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Procedimento',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: 'Preço',
            scope: '#/properties/price',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Preço',
            scope: '#/properties/specialtyId',
          },
        ],
      },
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Arcadas',
            scope: '#/properties/dentalArch',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/showDentalArch',
                schema: {
                  const: false,
                },
              },
            },
          },
          {
            type: 'Control',
            label: 'Numero do dente',
            scope: '#/properties/toothNumber',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/showToothNumber',
                schema: {
                  const: false,
                },
              },
            },
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Preço', minWidth: 100, align: 'center' },
    { id: 'specialty.name', label: 'Especialidade', minWidth: 100, align: 'center' },
  ],
  dentistProceduresTableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'price', label: 'Valor Base', minWidth: 100, align: 'center' },
    { id: 'newPrice', label: 'Novo Valor', minWidth: 100, align: 'center' },
    { id: 'specialty.name', label: 'Especialidade', minWidth: 100, align: 'center' },
  ],
  procedureListSchema: [
    { id: 'name', label: 'Nome', minWidth: 50, align: 'center' },
    { id: 'specialty.name', label: 'Especialidade', minWidth: 50, align: 'center' },
  ],
  actions: {
    getAll: 'ProcedureController@getAll',
    getAllBySpecialtyDentist: 'ProcedureController@getAllBySpecialtyDentist',
    findById: 'ProcedureController@findById',
    create: 'ProcedureController@create',
    update: 'ProcedureController@patchUpdate',
    delete: 'ProcedureController@delete',
  },
};

export default Procedure;
