import styled from 'styled-components';
import { AssignmentTurnedIn as MaterialAssignmentTurnedIn } from '@styled-icons/material/AssignmentTurnedIn';
import { Printer as MaterialPrinter } from '@styled-icons/boxicons-solid/Printer';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const AssignmentTurnedIn = styled(MaterialAssignmentTurnedIn)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
