import React, { useEffect, useState } from 'react';
import DentistFavoriteUnit from '../../../../forms/dentistFavoriteUnit';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { Container } from './styles';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { Creators } from '../../../../store/ducks/auth';

function DentistFavoriteUnitCreateUpdate(props) {
  const [successMessage, setSuccessMessage] = useState(null);
  const dentist = useSelector((state) => state.authReducer?.user);
  const dispatch = useDispatch();

  return (
    <Container>
      <CreateUpdateForm
        forceId={dentist?.id}
        modelSchema={DentistFavoriteUnit}
        restGetDataById={restApiClient.dentist.findById}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          const result = await restApiClient.dentist.updateFavoriteUnit(
            modelId,
            modelData?.favoriteUnitId
          );
          return result;
        }}
        onUpdateSuccess={(result) => {
          dispatch(
            Creators.storeUser({
              ...dentist,
              favoriteUnitId: result.data.favoriteUnitId,
            })
          );
          setSuccessMessage('Unidade favorita salva com sucesso.');
        }}
        {...props}
      />

      <Snackbar
        open={!!successMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default DentistFavoriteUnitCreateUpdate;
