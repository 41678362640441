/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import {
  Container,
  ActionsContainer,
  SubmitButton,
  Input,
  ContentContainer,
  DialogTitle,
  CancelButton,
  DialogContent,
} from './styles';
import { isNullOrEmptyObject } from '../../extensions/object';

function CreateUpdateExpenseTypeDialog({
  restModel,
  onSuccess,
  onError,
  onCancel,
  expenseTypeId,
  parent,
  action,
  ...props
}) {
  const [name, setName] = useState('');
  const [line, setLine] = useState('');
  const [requestModelData, setRequestModelData] = useState({});
  const [error, setError] = useState({
    name: '',
    line: '',
  });

  useEffect(() => {
    if (action.key === 'edit') {
      if (expenseTypeId) {
        restModel?.findById(expenseTypeId).then((e) => {
          setRequestModelData(e.data);
          setName(e.data.name);
          setLine(e.data.line?.slice(-2).replace('.', '') ?? '');
        });
      }
    } else {
      setRequestModelData({});
      setName('');
      setLine('');
    }
  }, [action, expenseTypeId, restModel]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNullOrEmptyObject(requestModelData)) {
      await restModel
        ?.create({
          parentId: parent?.id,
          expenseTypeId,
          name: name.trim(),
          line: parent ? `${parent.line}.${line}` : line,
        })
        .then(async (result) => {
          onSuccess?.(result);
          setError({
            name: '',
            line: '',
          });
          setName('');
          setLine('');
        })
        .catch((e) => {
          if (e.response?.status === 400) {
            onError?.(e.response);
            setError({
              name: e.response.data.errors?.name?.join(' '),
              line: e.response.data.errors?.line?.join(' '),
            });
          }
        });
    } else {
      restModel
        ?.update(expenseTypeId, requestModelData, {
          ...requestModelData,
          name: name.trim(),
          line: parent ? `${parent.line}.${line}` : line,
        })
        .then((x) => onSuccess(x))
        .catch((x) => {
          if (x.response?.status === 400) {
            onError?.(x.response);
            setError({
              name: x.response.data.errors?.name?.join(' '),
              line: x.response.data.errors?.line?.join(' '),
            });
          }
        });
    }
  };

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {action.actionLabel} de {action.depthLabel}
          </DialogTitle>
          <DialogContent>
            <ContentContainer>
              <Input
                required
                type="text"
                label="Nome"
                value={name}
                error={error.name}
                helperText={error.name}
                onChange={(e) => {
                  setError({
                    name: '',
                    line: '',
                  });
                  setName(e.target?.value?.toUpperCase());
                }}
              />
              <Input
                required
                type="text"
                label="Linha"
                value={line}
                error={error.line}
                helperText={error.line}
                onChange={(e) => {
                  setError({
                    name: '',
                    line: '',
                  });
                  const regex = /[^0-9\.]/gm;
                  const result = e.target?.value?.replace(regex, '');
                  setLine(result.slice(-2));
                }}
              />
            </ContentContainer>
          </DialogContent>
          <DialogActions>
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  setError({
                    name: '',
                    line: '',
                  });
                  setName('');
                  setLine('');
                  onCancel?.();
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SubmitButton type="submit" variant="contained" color="primary">
                {action.key === 'add' ? 'Cadastrar' : 'Editar'}
              </SubmitButton>
            </ActionsContainer>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default CreateUpdateExpenseTypeDialog;
