/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  ActionsContainer,
  DropzoneContainer,
  SubmitButton,
  ContentContainer,
  DialogTitle,
  CancelButton,
  DialogContent,
  DialogActions,
} from './styles';
import { DropzoneArea } from 'material-ui-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormHelperText } from '@material-ui/core';

function ImportCardCsvDialog({
  onSuccess,
  onError,
  onCancel,
  title,
  restSubmitData,
  placeholder,
  errorMessage,
  customform,
  ...props
}) {
  const [file, setFile] = useState('');
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      setError('Arquivo não pode ser vazio');
      return;
    }
    setFetching(true);
    restSubmitData(file)
      .then((x) => {
        setFetching(false);
        onSuccess?.(x);
      })
      .catch((e) => {
        onError?.(e);
        setFetching(false);
      });
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <ContentContainer>
            <DropzoneContainer>
              <DropzoneArea
                useChipsForPreview
                filesLimit={1}
                acceptedFiles={[
                  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
                ]}
                dropzoneText="Clique ou arraste o arquivo aqui"
                onChange={(files) => {
                  if (files.length > 0) {
                    const reader = new FileReader();
                    reader.readAsText(files[0], 'UTF-8');
                    reader.onload = function (evt) {
                      setFile(files[0]);
                    };
                    setError(null);
                  } else {
                    setFile('');
                  }
                }}
              />
              <FormHelperText error={!!error}>{error}</FormHelperText>
              {customform}
              {!errorMessage && placeholder}
            </DropzoneContainer>
            {errorMessage}
          </ContentContainer>
        </DialogContent>
        <DialogActions>
          {fetching ? (
            <>
              <CircularProgress size={24} />
              <span>Importando Informações</span>
            </>
          ) : (
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  onCancel?.();
                  setError(null);
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SubmitButton type="submit" variant="contained" color="primary">
                Entrar
              </SubmitButton>
            </ActionsContainer>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ImportCardCsvDialog;
