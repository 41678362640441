import React, { useState, useEffect } from 'react';
import Budget from '../../../forms/budget';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { isEqualWith, cloneDeep, isEmpty } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  Container,
  StyledMoneyInput,
  StyledTextFieldInput,
  Button,
  SaveIcon,
  StyledTypography,
  StyledSelectGroupDialogControl,
} from './styles';
import moment from 'moment';

function ItemList({ history, match, ...props }) {
  const budgetId = match.params?.id;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const [originalBudget, setOriginalBudget] = useState({});
  const [budget, setBudget] = useState({});
  const columns = Budget.itemListTableSchema;
  const [originalItemList, setOriginalItemList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [total, setTotal] = useState(0);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [budgetName, setBudgetName] = useState('');
  const [searchGroupId, setSearchGroupId] = useState(undefined);
  const [setNull, setSetNull] = useState(false);

  const handleAlertClose = () => {
    setAlert({ message: '', severity: '' });
  };

  const salvar = (backPage = false) => {
    let isValid = itemList.reduce(
      (acc, value) => acc && value.price > 0 && value.itemQuantity >= 0,
      true
    );

    if (isValid) {
      const added = itemList
        .filter(
          (item) =>
            !originalItemList.some((obj) =>
              isEqualWith(
                obj,
                item,
                (obj1, obj2) =>
                  obj1.itemId === obj2.itemId &&
                  obj1.itemQuantity === obj2.itemQuantity &&
                  obj1.budgetId === obj2.budgetId &&
                  obj1.price === obj2.price
              )
            )
        )
        .filter((item) => item.itemQuantity > 0);

      const removed = originalItemList
        .filter(
          (item) =>
            !itemList.some((obj) =>
              isEqualWith(
                obj,
                item,
                (obj1, obj2) =>
                  obj1.itemId === obj2.itemId &&
                  obj1.itemQuantity === obj2.itemQuantity &&
                  obj1.budgetId === obj2.budgetId &&
                  obj1.price === obj2.price
              )
            )
        )
        .map((item) => item.id);

      restApiClient.budget
        .update(budgetId, originalBudget, budget, added, removed, setNull)
        .then((result) => {
          if (result.status === 200) {
            loadList();
            setOriginalBudget({});
            setAlert({
              message: 'O orçamento foi salvo com sucesso!',
              severity: 'success',
            });
          }
        });

      if (backPage) history.goBack();
    }
  };

  const loadList = async () => {
    restApiClient.budget.findById(budgetId).then((e) => {
      const budgetItems = e.data.budgetItems;
      const crrSearchGroupId = searchGroupId;
      restApiClient.item
        .getAllWithItemsByGroup(
          e.data.unitId,
          crrSearchGroupId,
          budgetId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          let newRows = e.data;
          newRows.values = e.data.values.map((rowItem) => {
            const budgetItemInfo = budgetItems.filter(
              (budgetItem) => budgetItem.itemId === rowItem.id
            );
            if (budgetItemInfo) {
              rowItem.itemQuantity = budgetItemInfo[0]?.itemQuantity;
              rowItem.price = parseFloat(
                typeof budgetItemInfo[0] === 'undefined'
                  ? rowItem.price
                  : budgetItemInfo[0]?.price
              );
              rowItem.subtotal = rowItem.itemQuantity * rowItem.price;
              if (isNaN(rowItem.subtotal)) rowItem.subtotal = 0;
            }
            return rowItem;
          });

          setRows(newRows);
        });
    });
  };

  useEffect(() => {
    if (!isEmpty(originalBudget)) {
      loadList();
    }
  }, [rowsPerPage, page, orderBy, searchBy, budgetId, searchGroupId]);

  useEffect(() => {
    if (isEmpty(originalBudget)) {
      restApiClient.budget.findById(budgetId).then((e) => {
        let mapList = e.data.budgetItems.map((budgetItem) => ({
          id: budgetItem.id,
          itemId: budgetItem.itemId,
          itemQuantity: budgetItem.itemQuantity,
          budgetId: e.data.id,
          price: budgetItem.price,
        }));
        setOriginalItemList(mapList);
        setItemList(cloneDeep(mapList));
        setOriginalBudget(e.data);
        setBudget(cloneDeep(e.data));
        setSearchGroupId(e.data.searchGroupId);
        setBudgetName(
          `Orçamento - ${moment(new Date(e.data.expectedDate)).format(
            'DD/MM/YYYY'
          )}`
        );
        setTotal(
          e.data.budgetItems.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.itemQuantity * currentValue.price;
          }, 0)
        );
        setSetNull(false);
      });
    }
  }, [budgetId, originalBudget]);

  return (
    <Container>
      <CrudTable
        title={budgetName}
        customHeaderComponent={
          <>
            <StyledSelectGroupDialogControl
              enabled={true}
              visible={true}
              noValidation={true}
              data={searchGroupId}
              onSuccess={(groupData) => {
                if (groupData.type === 'none') {
                  setSearchGroupId(null);
                  setSetNull(true);
                } else {
                  setSearchGroupId(groupData.id);
                  budget.searchGroupId = groupData.id;
                }
              }}
            />
          </>
        }
        customFooterComponent={
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={async () => {
                salvar();
              }}
            >
              Salvar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={async () => {
                await salvar(true);
              }}
            >
              Salvar e Voltar
            </Button>
          </>
        }
        customInfosComponent={
          <StyledTypography variant="h6">
            Valor total:{' '}
            {total?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledTypography>
        }
        modelActions={Budget.actions}
        columns={columns}
        customColumns={[
          {
            id: 'suggestedQuantity',
            label: 'Quantidade Sugerida',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'itemQuantity',
            label: 'Quantidade Pedido',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'price',
            label: 'Preço',
            minWidth: 50,
            align: 'center',
          },
          {
            id: 'subtotal',
            label: 'Subtotal',
            minWidth: 50,
            align: 'center',
          },
        ]}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          price: {
            renderer: (field, value, column, row) => {
              row.price = parseFloat(row.price);
              let itemFind = itemList.find((x) => x.itemId === row.id);
              let price = 0;
              if (itemFind) price = itemFind?.price;
              else price = value;
              const error = price <= 0;
              return (
                <StyledMoneyInput
                  value={price}
                  onChange={(e, value) => {
                    let item;
                    const newList = [...itemList];
                    if ((item = newList.find((x) => x.itemId === row.id))) {
                      item.price = value;
                    } else {
                      const item = {
                        itemId: row.id,
                        itemQuantity: row.itemQuantity ?? 0,
                        budgetId: budget?.id,
                        price: value,
                      };
                      newList.push(item);
                    }
                    row.price = value;
                    row.subtotal =
                      (row.itemQuantity !== '' ? row.itemQuantity : 0) *
                      row.price;
                    if (isNaN(row.subtotal)) row.subtotal = 0;
                    setItemList(newList);
                    // recalcular total do orçamento
                    setTotal(
                      newList.reduce((accumulator, currentValue) => {
                        return (
                          accumulator +
                          currentValue.itemQuantity * currentValue.price
                        );
                      }, 0)
                    );
                  }}
                  error={error}
                  helperText={error ? 'Valor inválido.' : ''}
                />
              );
            },
          },
          itemQuantity: {
            renderer: (field, value, column, row) => {
              let itemFind = itemList.find((x) => x.itemId === row.id);
              let quantity = '';
              if (itemFind)
                quantity =
                  itemFind?.itemQuantity === 0 ? 0 : itemFind?.itemQuantity;
              else quantity = value ?? 0;
              return (
                <StyledTextFieldInput
                  value={quantity}
                  onChange={(e) => {
                    let item;
                    const newList = [...itemList];
                    if ((item = newList.find((x) => x.itemId === row.id))) {
                      item.itemQuantity = parseInt(
                        e.target.value !== '' ? e.target.value : 0
                      );
                    } else {
                      const item = {
                        itemId: row.id,
                        itemQuantity: parseInt(
                          e.target.value !== '' ? e.target.value : 0
                        ),
                        budgetId: budget?.id,
                        price: row.price ?? 0,
                      };
                      newList.push(item);
                    }
                    row.itemQuantity = e.target.value;
                    row.subtotal = row.itemQuantity * row.price;
                    if (isNaN(row.subtotal)) row.subtotal = 0;
                    setItemList(newList);
                    // recalcular total do orçamento
                    setTotal(
                      newList.reduce((accumulator, currentValue) => {
                        return (
                          accumulator +
                          currentValue.itemQuantity * currentValue.price
                        );
                      }, 0)
                    );
                  }}
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+')
                      event.preventDefault();
                  }}
                />
              );
            },
          },
          suggestedQuantity: {
            renderer: (field, value, column, row) => {
              const suggestion =
                parseFloat(value ?? 0) * budget.expectedDuration -
                parseFloat(row.quantity ?? 0);
              return (suggestion < 0 ? 0 : suggestion).toFixed(0);
            },
          },
          subtotal: {
            renderer: (field, value) => {
              return (value ?? 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          quantity: {
            renderer: (field, value) => {
              return value ?? 0;
            },
          },
        }}
      />

      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ItemList;
