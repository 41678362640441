const RecurrentExpense = {
  schema: {
    type: 'object',
    properties: {
      expenseTypeId: {
        format: 'selectExpenseTypeDialog',
        customErrors: '',
      },
      dueDay: {
        type: 'integer',
        format: 'date',
        minimum: 1,
        maximum: 31,
        customErrors: '',
      },
      unitId: {
        format: 'selectUnitDialog',
        customErrors: '',
      },
      value: {
        type: 'number',
        format: 'money',
        customErrors: '',
      },
      observation: {
        type: 'string',
        customErrors: '',
      },
      paymentTypeId: {
        format: 'selectPaymentTypeDialog',
        customErrors: '',
      },
      employeeId: {
        format: 'selectEmployeeDialog',
        customErrors: '',
      },
      supplierId: {
        format: 'selectServiceSupplierDialog',
        customErrors: '',
      },
      patientId: {
        format: 'selectPatientDialog',
        customErrors: '',
      },
      dentistId: {
        format: 'selectDentist',
        customErrors: '',
      },
    },
    required: ['expenseTypeId', 'dueDay', 'unitId', 'value', 'paymentTypeId'],
  },
  updateUischema: {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Group',
        label: 'Despesa Recorrente',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Funcionário *',
                scope: '#/properties/employeeId',
                options: {
                  readonly: true,
                },
              },
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/employeeId',
                schema: { enum: [null, undefined] },
              },
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Paciente *',
                scope: '#/properties/patientId',
                options: {
                  readonly: true,
                },
              },
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/patientId',
                schema: { enum: [null, undefined] },
              },
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Dentista *',
                scope: '#/properties/dentistId',
                options: {
                  readonly: true,
                },
              },
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/dentistId',
                schema: { enum: [null, undefined] },
              },
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Fornecedor *',
                scope: '#/properties/supplierId',
                options: {
                  readonly: true,
                },
              },
            ],
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/supplierId',
                schema: { enum: [null, undefined] },
              },
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Tipo de Despesa',
                scope: '#/properties/expenseTypeId',
              },
              {
                type: 'Control',
                label: 'Unidade',
                scope: '#/properties/unitId',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Tipo de Pagamento',
                scope: '#/properties/paymentTypeId',
              },
              {
                type: 'Control',
                label: 'Valor',
                scope: '#/properties/value',
              },
              {
                type: 'Control',
                label: 'Dia de Vencimento',
                scope: '#/properties/dueDay',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: 'Observação',
                scope: '#/properties/observation',
              },
            ],
          },
        ],
      },
    ],
  },
  tableSchema: [
    {
      id: 'expenseType',
      label: 'Tipo Despesa',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'name',
      label: 'Nome',
      minWidth: 100,
      align: 'center',
    },
    { id: 'unit.name', label: 'Unidade', minWidth: 100, align: 'center' },
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
    { id: 'dueDay', label: 'Dia Vencimento', minWidth: 100, align: 'center' },
  ],
  actions: {
    update: 'ExpenseController@patchUpdate',
    findById: 'ExpenseController@findById',
    getAll: 'ExpenseController@getAll',
    delete: 'ExpenseController@delete',
  },
};

export default RecurrentExpense;
