import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  Button as MaterialButton,
  DialogTitle as MaterialDialogTitle,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogContentText,
  Typography as MaterialTypography,
  Checkbox as MaterialCheckbox,
  FormControlLabel as MaterialFormControlLabel,
  FormGroup as MaterialFormGroup,
  Grid as MaterialGrid, 
  TextField as MaterialTextField,
} from '@material-ui/core';
import MoneyInput from '../moneyInput';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Dialog = styled(MaterialDialog)`
  height: 100%;
`;

export const DialogContentText = styled(MaterialDialogContentText)`
  font-size: 20px !important;
  color: black !important;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
`;

export const DialogTitle = styled(MaterialDialogTitle)`
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export const Typography = styled(MaterialTypography)``;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 8px 8px 8px 8px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  padding-bottom: 10px !important;
  padding-top: 0px !important;
  display: inline-table;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Checkbox = styled(MaterialCheckbox)``;

export const Grid = styled(MaterialGrid)``;

export const FormGroup = styled(MaterialFormGroup)`
  margin-bottom: 5px !important;
`;

export const FormControlLabel = styled(MaterialFormControlLabel)``;

export const InputTextarea = styled(MaterialTextField)`
  width: 100%;
  color: white;
`;

export const StyledMoneyInput = styled(MoneyInput)``;
