/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Container,
  CancelButton,
  SubmitButton,
  DialogContent,
  Card,
  Dialog,
  DialogActions,
  ActionsContainer,
  HeaderContainer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CardHeader,
  InputTextarea,
  CustomMuiPickersUtilsProvider,
  CustomKeyboardDatePicker,
  StyledMoneyInput,
  SelectUnitFinance,
  SelectItemizedCheck,
} from './styles';
import restApiClient from '../../services/restApiClient';
import DayJsUtils from '@date-io/dayjs';
import moment from 'moment';
import { Hidden } from '@material-ui/core';
import { isEmpty } from 'lodash';

function SwitchOperationTypeDialog({ onSuccess, onError, onCancel, ...props }) {
  const [answer, setAnswer] = useState(null);
  const [sourceAccountId, setSourceAccountId] = useState(null);
  const [destinationAccountId, setDestinationAccountId] = useState(null);
  const [itemizedCheckId, setItemizedCheckId] = useState(null);
  const [operationDate, setOperationDate] = useState(null);
  const [showInfos, setShowInfos] = useState(false);
  const [observation, setObservation] = useState('');
  const [valueOperation, setValueOperation] = useState(0);
  const [depositType, setDepositType] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState(false);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (operationDate && !error) {
                if (sourceAccountId !== destinationAccountId) {
                  const formatedDate = moment(new Date(operationDate)).format(
                    'YYYY-MM-DD'
                  );
                  restApiClient.bankingOperation
                    .create({
                      type: answer,
                      sourceAccountId: sourceAccountId,
                      destinationAccountId: destinationAccountId ?? -1,
                      itemizedCheckId: itemizedCheckId ?? -1,
                      depositType: depositType,
                      date: formatedDate,
                      value: valueOperation,
                      observation: observation,
                    })
                    .then((e) => {
                      setError(false);
                      setAnswer(null);
                      setSourceAccountId(null);
                      setDestinationAccountId(null);
                      setOperationDate(null);
                      setValueOperation(0);
                      setObservation('');
                      setShowInfos(false);
                      onSuccess?.(e.data);
                    })
                    .catch((e) => {
                      if (e.response?.status === 400) {
                        setErrors(e.response.data.errors);
                      }
                    });
                } else {
                  setErrors({
                    destinationAccountId:
                      'Conta de Destino deve ser diferente Conta de Origem.',
                  });
                }
              } else {
                setError(true);
              }
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title="Adicionar Operação Bancária" />
              </HeaderContainer>
              <FormControl component="fieldset">
                <FormLabel component="legend" required>
                  Selecione a operação:
                </FormLabel>
                <RadioGroup
                  row={true}
                  aria-label="question"
                  name="question"
                  value={answer ?? null}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                    setSourceAccountId(null);
                    setDestinationAccountId(null);
                    setOperationDate(null);
                    setValueOperation(0);
                    setDepositType(null);
                    setObservation('');
                    setErrors(false);
                    if (e.target.value !== 'Depósito') {
                      setShowInfos(true);
                    } else {
                      setShowInfos(false);
                    }
                  }}
                >
                  <FormControlLabel
                    value="Transferência"
                    control={<Radio required />}
                    label="Transferência"
                  />
                  <FormControlLabel
                    value="Saque"
                    control={<Radio required />}
                    label="Saque"
                  />
                  <FormControlLabel
                    value="Depósito"
                    control={<Radio required />}
                    label="Depósito"
                  />
                </RadioGroup>
              </FormControl>
              {answer === 'Depósito' ? (
                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    Tipo de depósito:
                  </FormLabel>
                  <RadioGroup
                    row={true}
                    aria-label="depositType"
                    name="depositType"
                    value={depositType ?? null}
                    onChange={(e) => {
                      setDepositType(e.target.value);
                      setSourceAccountId(null);
                      setDestinationAccountId(null);
                      setItemizedCheckId(null);
                      setOperationDate(null);
                      setValueOperation(0);
                      setObservation('');
                      setShowInfos(true);
                    }}
                  >
                    <FormControlLabel
                      value="Caixa"
                      control={<Radio required />}
                      label="Dinheiro"
                    />
                    <FormControlLabel
                      value="Cheque"
                      control={<Radio required />}
                      label="Cheque"
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
              <Hidden xsUp={!showInfos}>
                {answer === 'Saque' || answer === 'Transferência' ? (
                  <SelectUnitFinance
                    label="Conta Origem"
                    size="small"
                    data={sourceAccountId}
                    onSuccess={(id) => {
                      setErrors(false);
                      setSourceAccountId(id);
                    }}
                    visible
                    enabled
                    required
                    customType={'Banco'}
                    error={errors.sourceAccountId}
                    helperText={errors.sourceAccountId ?? ''}
                  />
                ) : null}
                {answer === 'Transferência' || answer === 'Depósito' ? (
                  <>
                    {depositType === 'Cheque' ? (
                      <SelectItemizedCheck
                        label="Cheque"
                        data={itemizedCheckId}
                        onSuccess={(id, value) => {
                          setErrors(false);
                          setItemizedCheckId(id);
                          setValueOperation(value);
                        }}
                        visible
                        enabled
                        required
                        customStatus={['Em Compensação', 'Devolvido']}
                        error={errors?.itemizedCheckId}
                        helperText={errors?.itemizedCheckId ?? ''}
                      />
                    ) : null}
                    <SelectUnitFinance
                      label="Conta Destino"
                      size="small"
                      data={destinationAccountId}
                      onSuccess={(id) => {
                        setErrors(false);
                        setDestinationAccountId(id);
                      }}
                      visible
                      enabled
                      required
                      customType={'Banco'}
                      error={errors.destinationAccountId}
                      helperText={errors.destinationAccountId ?? ''}
                    />
                  </>
                ) : null}
                <CustomMuiPickersUtilsProvider utils={DayJsUtils}>
                  <CustomKeyboardDatePicker
                    margin="normal"
                    id="date-from"
                    label="Data"
                    format="DD/MM/YYYY"
                    value={operationDate}
                    onChange={(date) => {
                      setOperationDate(date);
                      setError(false);
                      setErrors(false);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Editar data',
                    }}
                    cancelLabel="Cancelar"
                    okLabel="Selecionar"
                    invalidDateMessage="Data inválida."
                    onError={(value) => setError(!!value)}
                    helperText={
                      error
                        ? 'Data inválida.'
                        : errors?.date
                        ? errors?.date.join()
                        : ''
                    }
                    error={error || errors?.date}
                    required
                  />
                </CustomMuiPickersUtilsProvider>
                <StyledMoneyInput
                  required
                  label={'Valor'}
                  value={valueOperation}
                  error={!isEmpty(errors.value)}
                  helperText={
                    !isEmpty(errors.value) ? errors.value.join(' ') : ''
                  }
                  onChange={(e, value) => {
                    setErrors(false);
                    setValueOperation(value);
                  }}
                  disabled={depositType === 'Cheque'}
                />
                <InputTextarea
                  id="outlined-multiline-static"
                  size="small"
                  label="Observação"
                  multiline
                  rows={4}
                  variant="outlined"
                  onChange={(e) => {
                    setObservation(e.target.value);
                  }}
                  inputProps={{ maxLength: 250 }}
                />
              </Hidden>
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setAnswer(null);
                    setSourceAccountId(null);
                    setDestinationAccountId(null);
                    setOperationDate(null);
                    setValueOperation(0);
                    setShowInfos(false);
                    setObservation('');
                    onCancel?.();
                  }}
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Salvar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default SwitchOperationTypeDialog;
