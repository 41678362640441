import React, { useState } from 'react';
import Attendance from '../../../../../forms/attendance';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container, BackButton, CancelButton, SubmitButton } from './styles';
import DisapprovedJusticationDialog from '../../../../../components/disapprovedJustificationDialog';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import SubmitDialog from '../../../../../components/submitDialog';

function ProceduresAuditCreateUpdate(props) {
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const auditId = props.match.params?.id ?? -1;
  const readonly = props.location?.state?.readonly ?? false;
  const disaproved = props.location?.state?.disaproved ?? false;
  const [error, setError] = useState({
    state: false,
    description: '',
  });
  const [openDisapproved, setOpenDisapproved] = useState(false);

  const submit = () =>
    restApiClient.attendance
      .setApproved(auditId)
      .then((e) => {
        props.history.goBack();
      })
      .catch((e) => {
        setError({
          state: true,
          description: e.response.data.errors,
        });
      });

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Attendance}
        schemaSelector={(modelSchema, modelId) =>
          modelId ? modelSchema.updateSchema : modelSchema.schema
        }
        restGetDataById={async (id) => {
          const result = await restApiClient.attendance.findById(id);
          result.data.recordNumber = result.data.patient.recordNumber;
          result.data.recordNumberUninformed =
            !result.data.patient.recordNumber;
          result.data.name = result.data.patient.name;
          result.data.showToothNumber = !!result.data.toothNumber;
          result.data.showDentalArch = !!result.data.dentalArch;
          return result;
        }}
        modelData={{
          requestingUnitBoolean: true,
          recordNumberFound: false,
          readonly,
          procedures: [{}],
        }}
        customButtonsBuilder={(_, data) => (
          <>
            <BackButton
              onClick={() => props.history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
            {disaproved ? (
              ''
            ) : (
              <CancelButton
                type="button"
                variant="contained"
                color="primary"
                id="submit"
                onClick={() => {
                  setOpenDisapproved(true);
                }}
              >
                {'Reprovar'}
              </CancelButton>
            )}
            <SubmitButton
              type="button"
              variant="contained"
              color="primary"
              id="submit"
              onClick={() => {
                if (data.history.length > 0) {
                  setOpen(true);
                  setDialogText(
                    'O procedimento se trata de um retrabalho, deseja aprovar mesmo assim?'
                  );
                } else {
                  submit();
                }
              }}
            >
              {'Aprovar'}
            </SubmitButton>
          </>
        )}
        {...props}
      />

      <DisapprovedJusticationDialog
        open={openDisapproved}
        auditId={auditId}
        onCancel={() => setOpenDisapproved(false)}
        onSuccess={() => {
          setOpenDisapproved(false);
          props.history.goBack();
        }}
      />

      <SubmitDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          submit();
        }}
        dialogText={dialogText}
      />

      <Snackbar
        open={error.state}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() =>
          setError({
            state: false,
            description: '',
          })
        }
      >
        <Alert
          onClose={() => setError({ state: false, description: '' })}
          severity="error"
        >
          {error.description}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ProceduresAuditCreateUpdate;
