import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync';

import { reducers, sagas } from './ducks';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, createStateSyncMiddleware({})];

const store = createStore(
  persistReducer(
    {
      key: 'root',
      storage,
    },
    reducers
  ),
  applyMiddleware(...middlewares)
);
initStateWithPrevTab(store);

const persistor = persistStore(store);

sagaMiddleware.run(function* registerSagas() {
  yield all(sagas);
});

export { store, persistor };
