import styled from 'styled-components';
import { DialogContent as MaterialDialogContent } from '@material-ui/core';
import CreateUpdateForm from '../createUpdateForm';
import Button from '@material-ui/core/Button';

export const Container = styled.div``;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 0px !important;
  width: 600px !important;

`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)`
  margin-bottom: 50px !important ;
`;

export const SubmitButton = styled(Button)`
  width: 30%;
  color: #ffffff !important;
  background: #2dbd17 !important;
`;

export const CancelButton = styled(Button)`
  width: 30%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;
