import { createActions, createReducer } from 'reduxsauce';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import RestApiClient from '../../services/restApiClient';

export const { Types, Creators } = createActions({
  login: ['login', 'password'],
  refresh: [],
  tokenFetching: [],
  tokenRefreshing: [],
  tokenError: ['error'],
  storeToken: ['authDto'],
  storeUser: ['userDto'],
  logout: [],
});

const INITIAL_STATE = {
  user: undefined,
  token: undefined,
  refreshToken: undefined,
  tokenExpiration: undefined,
  fetching: false,
  refreshing: true,
  error: undefined,
};

const fetching = (state = INITIAL_STATE) => ({
  ...state,
  fetching: true,
  error: undefined,
});
const refreshing = (state = INITIAL_STATE) => ({
  ...state,
  refreshing: true,
  error: undefined,
});
const error = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error,
  fetching: false,
  refreshing: false,
});
const storeToken = (state = INITIAL_STATE, { authDto }) => ({
  ...state,
  ...authDto,
  fetching: false,
  refreshing: false,
  error: undefined,
});
const storeUser = (state = INITIAL_STATE, { userDto }) => ({
  ...state,
  user: userDto,
});
const logout = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOKEN_FETCHING]: fetching,
  [Types.TOKEN_REFRESHING]: refreshing,
  [Types.TOKEN_ERROR]: error,
  [Types.STORE_TOKEN]: storeToken,
  [Types.STORE_USER]: storeUser,
  [Types.LOGOUT]: logout,
});

export const saga = [
  takeEvery(Types.LOGIN, function* loginAsync({ login, password }) {
    try {
      yield put(Creators.tokenFetching());
      const authDto = yield call(RestApiClient.token.get, login, password);
      yield put(Creators.storeToken(authDto));
      const userDto = yield call(RestApiClient.user.profile);
      yield put(Creators.storeUser(userDto));
    } catch (e) {
      yield put(Creators.logout());
      yield put(Creators.tokenError(e.response));
    }
  }),

  takeEvery(Types.REFRESH, function* refresh() {
    try {
      const authReducer = yield select((store) => store.authReducer);
      if (authReducer.token) {
        if (new Date(authReducer.tokenExpiration) <= new Date(Date.now())) {
          if (!authReducer.refreshing) {
            yield put(Creators.tokenFetching());
            yield put(Creators.tokenRefreshing());
            const authDto = yield call(
              RestApiClient.token.refresh,
              authReducer.refreshToken
            );
            yield put(Creators.storeToken(authDto));
            const userDto = yield call(RestApiClient.user.profile);
            yield put(Creators.storeUser(userDto));
          }
        }
      }
    } catch (e) {
      yield put(Creators.logout());
      yield put(Creators.tokenError(e));
    }
  }),
];
