import React, { useState, useEffect, useCallback } from 'react';
import DentistConsumption from '../../../../../forms/dentistConsumption';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import { Container, BackButton, Send, ClipboardList, Preview } from './styles';
import startCase from 'lodash/startCase';
import ConsumptionDialog from '../../../../../components/consumptionDialog';
import ErrorDialog from '../../../../../components/errorDialog';
import moment from 'moment/moment';

function ThrowDentistConsumptionUnit(props) {
  const unitId = parseInt(props.match?.params?.id ?? -1);
  const labelUnit = props.location?.state?.labelUnit;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ endDate: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [dentistConsumptionId, setDentistConsumptionId] = useState(null);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const columns = DentistConsumption.tableSchema;

  const loadIntervals = useCallback(() => {
    restApiClient.dentistConsumption
      .getAllByUnit(
        unitId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('id', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, unitId]);

  useEffect(() => {
    loadIntervals();
  }, [loadIntervals]);

  return (
    <Container>
      <CrudTable
        title={`${startCase(labelUnit.toLowerCase())}`}
        customFooterComponent={
          <BackButton
            onClick={() => props.history.goBack()}
            type="button"
            variant="contained"
            color="primary"
          >
            Voltar
          </BackButton>
        }
        modelActions={DentistConsumption.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id, row) => row.id === submitConfirmationId,
            content: <span>Você tem certeza que deseja enviar o consumo?</span>,
            onSuccess: () => {
              restApiClient.dentistConsumption
                .sendConsumption(submitConfirmationId)
                .then(() => {
                  loadIntervals();
                  setSubmitConfirmationId(-1);
                })
                .catch((e) => {
                  setOpenErrorDialog(true);
                  setErrorMessage(e.response.data.errors);
                });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          add: {
            onClick: () => {
              setOpen(true);
            },
            title: 'Adicionar Intervalo de Consumo',
          },
          update: {
            onClick: (id) => {
              setDentistConsumptionId(id);
              setOpen(true);
            },
            selector: (row) => row.status === 'Novo',
          },
          manageConsumption: {
            onClick: (id, row) => {
              props.history.push({
                pathname: `/administrative/manage-dentist-consumption/${id}/unit/${row.unitId}`,
                state: {
                  beginDate: row.beginDate,
                  endDate: row.endDate,
                },
              });
            },
            icon: <ClipboardList />,
            title: 'Gerenciar Consumo',
            allowedActions: [
              DentistConsumption.actions.setEditing,
              DentistConsumption.valuesActions.getAllByDentistConsumptionId,
            ],
            selector: (row) => row.status !== 'Enviado',
          },
          sendConsumption: {
            icon: <Send />,
            onClick: (id) => {
              setSubmitConfirmationId(id);
            },
            title: 'Enviar Consumo',
            allowedActions: [DentistConsumption.actions.sendConsumption],
            selector: (row) => row.status === 'Em Edição',
          },
          viewConsumption: {
            onClick: (id, row) => {
              props.history.push({
                pathname: `/administrative/view-dentist-consumption/${id}/unit/${row.unitId}`,
                state: {
                  beginDate: row.beginDate,
                  endDate: row.endDate,
                  noEdit: true,
                },
              });
            },
            icon: <Preview />,
            title: 'Visualizar Consumo',
            allowedActions: [
              DentistConsumption.valuesActions.getAllByDentistConsumptionId,
            ],
            selector: (row) => row.status === 'Enviado',
          },
          delete: {
            onClick: (id, index) => {
              restApiClient.dentistConsumption.delete(id).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
            selector: (row) => row.status !== 'Enviado',
          },
        }}
        customCellRenderers={{
          beginDate: {
            renderer: (field, value) => {
              return `${moment(value).format('DD/MM/YYYY')}`;
            },
          },
          endDate: {
            renderer: (field, value) => {
              return `${moment(value).format('DD/MM/YYYY')}`;
            },
          },
          total: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value
                ? value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : '-';
            },
          },
        }}
      />

      <ErrorDialog
        open={openErrorDialog}
        title="Erro"
        error={errorMessage}
        onClose={() => setOpenErrorDialog(false)}
      />

      <ConsumptionDialog
        open={open}
        consumptionId={dentistConsumptionId}
        unitId={unitId}
        modelSchema={DentistConsumption}
        restData={restApiClient.dentistConsumption}
        onCancel={() => {
          setDentistConsumptionId(null);
          setOpen(false);
        }}
        onSuccess={() => {
          setDentistConsumptionId(null);
          setOpen(false);
          loadIntervals();
        }}
      />
    </Container>
  );
}

export default ThrowDentistConsumptionUnit;
