import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  Check as MaterialCheck,
  Close as MaterialClose,
  Send as MaterialSend,
  Printer as MaterialPrinter,
  UserEdit as MaterialUserEdit,
} from '../../../../styles/icons';
import {
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel, MenuItem as MaterialMenuItem,
  Select as MaterialSelect,
} from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Check = styled(MaterialCheck)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Close = styled(MaterialClose)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const SelectContainer = styled.div`
  width: 300px;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const UserEdit = styled(MaterialUserEdit)`
  width: 25px;
  height: 25px;
  color: var(--secondary);
`;
