import styled from 'styled-components';
import {
  PackageIcon as MaterialPackageIcon,
  Restore as MaterialRestore,
} from '../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const PackageIcon = styled(MaterialPackageIcon)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Restore = styled(MaterialRestore)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
