import React from 'react';
import ToothType from '../../../../forms/toothType';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { Container } from './styles';

function ToothTypeCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={ToothType}
        restGetDataById={async (id) => {
          const result = await restApiClient.toothType.findById(id);
          result.data.rawToothShades = result.data.toothShades;
          result.data.toothShades = result.data.toothShades.map((toothShade) => toothShade.name);
          return result;
        }}
        restCreateData={restApiClient.toothType.create}
        restUpdateData={async (modelId, updateOriginalData, modelData) => {
          const added = modelData.toothShades.filter(
            (tooth) =>
              !updateOriginalData.rawToothShades.some(
                (obj) => obj.name === tooth
              )
          );
          const removed = updateOriginalData.rawToothShades
            .filter((tooth) => !modelData.toothShades.includes(tooth.name))
            .map((tooth) => tooth.id);
          delete updateOriginalData.rawToothShades;
          delete updateOriginalData.toothShades;
          delete modelData.rawToothShades;
          delete modelData.toothShades;
          const result = await restApiClient.toothType.update(
            modelId,
            updateOriginalData,
            modelData,
            added,
            removed
          );
          return result;
        }}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default ToothTypeCreateUpdate;
