import styled from 'styled-components';
import { ColorPicker as MaterialColorPicker } from 'material-ui-color';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ColorPicker = styled(MaterialColorPicker)``;
