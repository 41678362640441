import { Error } from '../../styles/icons';
import FilterNode from './filterNode';

export default class FilterTree {
  constructor() {
    this.head = null;
    this.crrOp = null;
  }

  and(builder) {
    if (builder && typeof builder === 'function') {
      const leftChild = new FilterTree();
      const rightChild = new FilterTree();
      builder(leftChild, rightChild);
      if (
        (leftChild.head.isAggregation() &&
          leftChild.head.rightChild === null) ||
        (rightChild.head.isAggregation() && rightChild.head.rightChild === null)
      ) {
        throw new Error();
      }
      return this.op(FilterNode.strictAnd(leftChild.head, rightChild.head));
    } else {
      if (this.head === null) {
        throw new Error();
      }
      const newAgg = FilterNode.and(this.crrOp, null);
      if (this.crrOp?.parent) {
        newAgg.parent = this.crrOp.parent;
        this.crrOp.parent.rightChild = newAgg;
      } else {
        this.head = newAgg;
      }
      this.crrOp.parent = newAgg;
      this.crrOp = newAgg;
      return this;
    }
  }

  or(builder) {
    if (builder && typeof builder === 'function') {
      const leftChild = new FilterTree();
      const rightChild = new FilterTree();
      builder(leftChild, rightChild);
      if (
        (leftChild.head.isAggregation() &&
          leftChild.head.rightChild === null) ||
        (rightChild.head.isAggregation() && rightChild.head.rightChild === null)
      ) {
        throw new Error();
      }
      return this.op(FilterNode.strictOr(leftChild.head, rightChild.head));
    } else {
      if (this.head === null) {
        throw new Error();
      }
      const newAgg = FilterNode.or(this.head, null);
      this.head.parent = newAgg;
      this.head = newAgg;
      this.crrOp = newAgg;
      return this;
    }
  }

  op(filterNode) {
    if (this.head === null) {
      this.head = this.crrOp = filterNode;
    } else {
      if (!this.crrOp.isAggregation() || this.crrOp.rightChild !== null) {
        this.and();
      }
      filterNode.parent = this.crrOp;
      this.crrOp.rightChild = filterNode;
      this.crrOp = filterNode;
    }
    return this;
  }

  eq(field, value) {
    return this.op(FilterNode.eq(field, value));
  }

  df(field, value) {
    return this.op(FilterNode.df(field, value));
  }

  gt(field, value) {
    return this.op(FilterNode.gt(field, value));
  }

  lt(field, value) {
    return this.op(FilterNode.lt(field, value));
  }

  gte(field, value) {
    return this.op(FilterNode.gte(field, value));
  }

  lte(field, value) {
    return this.op(FilterNode.lte(field, value));
  }

  like(field, value) {
    return this.op(FilterNode.like(field, value));
  }

  dateLike(field, value) {
    const splitted = value.split('/');
    if (splitted.length === 2) {
      value = `${splitted[1]}-${splitted[0]}`;
    } else if (splitted.length === 3) {
      value = `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
    }
    return this.op(FilterNode.like(field, value));
  }

  regex(field, value) {
    return this.op(FilterNode.regex(field, value));
  }

  isNull(field) {
    return this.op(FilterNode.isNull(field));
  }

  notNull(field) {
    return this.op(FilterNode.notNull(field));
  }

  toString() {
    return this.head?.toString() ?? '';
  }

  in(field, [...value]) {
    const last = value.pop();
    value.forEach((element) => {
      this.eq(field, element).or();
    });
    this.eq(field, last);
    return this;
  }

  notIn(field, [...value]) {
    const last = value.pop();
    value.forEach((element) => {
      this.df(field, element).and();
    });
    this.df(field, last);
    return this;
  }

  conditionals(callback) {
    callback(this);
    return this;
  }
}
