import styled from 'styled-components';
import {
  Check as MaterialCheck,
  NotesMedical as MaterialNotesMedical,
  Cancel as MaterialCancel,
} from '../../../../../styles/icons';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Check = styled(MaterialCheck)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const NotesMedical = styled(MaterialNotesMedical)`
  width: 23px;
  height: 23px;
  color: var(--secondary);
`;

export const Cancel = styled(MaterialCancel)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
