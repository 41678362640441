const UnitRadiologyService = {
  linkedServicesTableSchema: [
    { id: 'treatment.name', label: 'Nome', minWidth: 100, align: 'center' },
    { id: 'treatment.origin.name', label: 'Origem', minWidth: 100, align: 'center' },
  ],
  actions: {
    create: 'UnitRadiologyServiceController@create',
    getAllByUnit: 'UnitRadiologyServiceController@getAllByUnit',
    delete: 'UnitRadiologyServiceController@delete'
  },
};

export default UnitRadiologyService;
