import React, { useState, useEffect } from 'react';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import {
  Container,
  AddBox,
  ViewShow,
  GetApp,
  Button,
  AddShoppingCart,
} from './styles';
import moment from 'moment';
import Order from '../../../../../forms/order';
import TransferType from '../../../../../forms/transferType';
import Item from '../../../../../forms/item';
import OrderItem from '../../../../../forms/orderItem';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import SecuredItem from '../../../../../components/securedItem';
import TransferTypeDialog from '../../../../../components/transferTypeDialog';

function ItemOrder({ history }) {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [open, setOpen] = React.useState(false);
  const columns = Order.tableSchema;

  async function loadOrders() {
    await restApiClient.order
      .getAllByUnit(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('toUnit.name', searchBy)
              .or()
              .like('employee.user.name', searchBy)
              .or()
              .like('submissionDate', searchBy)
              .or()
              .like('transferType.name', searchBy)
              .or()
              .like('sendDate', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }

  useEffect(() => {
    loadOrders();
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Requisição de Transferência de Itens'}
        customHeaderComponent={
          <SecuredItem
            allowedActions={[
              [
                Order.actions.create,
                TransferType.actions.getAll,
                Item.actions.getAllWhereHasStockInDistributionCenter,
              ],
            ]}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddShoppingCart />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Criar Pedido
            </Button>
          </SecuredItem>
        }
        modelActions={Order.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: (
              <span>Você tem certeza que deseja submeter o pedido?</span>
            ),
            onSuccess: (id, row) => {
              restApiClient.order.submitOrder(id).then(() => {
                loadOrders();
                setSubmitConfirmationId(-1);
              });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          insert: {
            icon: <AddBox />,
            onClick: (id) => {
              history.push(`/clinic/stock/order/addItems/${id}`);
            },
            title: 'Adicionar itens',
            selector: (row) => row.status === 'Em edição',
            allowedActions: [
              Item.actions.getAllWhereHasStockInDistributionCenter,
              Order.actions.update,
              Order.actions.findById,
            ],
          },
          view: {
            icon: <ViewShow />,
            onClick: (id) => {
              history.push(`/clinic/stock/order/view/${id}`);
            },
            title: 'Visualizar items',
            allowedActions: [OrderItem.actions.getAllByOrder],
            selector: (row) => !isEmpty(row?.orderItem),
          },
          submit: {
            icon: <GetApp />,
            onClick: (id) => {
              setSubmitConfirmationId(id);
            },
            title: 'Submeter pedido',
            selector: (row) =>
              row.status === 'Em edição' && !isEmpty(row?.orderItem),
            allowedActions: [Order.actions.submitOrder],
          },
          delete: {
            selector: (row) => row.status === 'Em edição',
            onClick: (id, index) => {
              restApiClient.order.delete(id).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
        }}
        customCellRenderers={{
          submissionDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
          'transferType.name': {
            renderer: (field, value) => {
              return value ? value : '-';
            },
          },
          sendDate: {
            renderer: (field, value) => {
              return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-';
            },
          },
          orderItem: {
            renderer: (field, value, column, row) => {
              var newValue = '-';
              if (row.status == 'Enviado') {
                newValue = value
                  .reduce((num, x) => {
                    if (x.orderId === row.id) {
                      return num + x?.approvedQuantity * x?.price;
                    }
                    return num;
                  }, 0)
                  .toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  });
              }
              return newValue;
            },
          },
        }}
      />

      <TransferTypeDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={(id, user) => {
          setOpen(false);
          restApiClient.order
            .create({
              employeeId: user.id,
              toUnit: user.unitId,
              transferTypeId: id,
            })
            .then((result) => {
              history.push(`/clinic/stock/order/addItems/${result.data.id}`);
            });
        }}
        user={user}
      />
    </Container>
  );
}

export default ItemOrder;
