import styled from 'styled-components';
import {
  Scheduler as MaterialScheduler,
  WeekView as MaterialWeekView,
  Appointments as MaterialAppointments,
  Toolbar as MaterialToolbar,
  DateNavigator as MaterialDateNavigator,
  TodayButton as MaterialTodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState as MaterialViewState } from '@devexpress/dx-react-scheduler';

export const Scheduler = styled(MaterialScheduler)``;

export const WeekView = styled(MaterialWeekView)``;

export const Appointments = styled(MaterialAppointments)``;

export const Toolbar = styled(MaterialToolbar)``;

export const DateNavigator = styled(MaterialDateNavigator)``;

export const TodayButton = styled(MaterialTodayButton)``;

export const ViewState = styled(MaterialViewState)``;

export const Container = styled.div``;

export const ContentContainer = styled.div`
  height: 80vh;
  width: 82vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
