import styled from 'styled-components';
import { AddToList as MaterialAddToList } from '../../../../../styles/icons';
import { Button as MaterialButton } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const AddToList = styled(MaterialAddToList)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const BackButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;