/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Container,
  CancelButton,
  SubmitButton,
  DialogActions,
  DialogContent,
  ActionsContainer,
  Card,
  TextField,
  Edit,
  IconButton,
  HeaderContainer,
  SelectContainer,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  StyledCrudTable,
  CardHeader,
  HeaderTotal,
  StyledMoneyInput,
  TotalContainer,
  H3,
} from './styles';
import Grid from '@material-ui/core/Grid';
import Sale from '../../forms/sale';
import SelectBuyerAddressDialog from '../selectBuyerAddressDialog';
import { toFixed } from '../../extensions/object';
import restApiClient from '../../services/restApiClient';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { isEmpty } from 'lodash';
import { FormHelperText } from '../exportCsvDialog/styles';

function PaymentDialog({ onSuccess, onCancel, total, sale, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [buyerAddress, setBuyerAddress] = useState({});
  const [crrTotal, setCrrTotal] = useState(0);
  const [parcelsList, setParcelsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMethod, setErrorMethod] = useState(false);
  const [errorParcel, setErrorParcel] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const columns = Sale.InstallmentTableSchema;

  const handleAlertClose = () => setAlert({ message: '', severity: '' });

  const paymentMethods = [
    { value: 'pix', label: 'PIX' },
    { value: 'money', label: 'Caixa' },
    { value: 'debit_card', label: 'Cartão de Débito' },
    { value: 'credit_card', label: 'Cartão de Crédito' },
    { value: 'payroll_discount', label: 'Desconto em folha de pagamento' },
    { value: 'duplicate_in_wallet', label: 'Duplicata em Carteira' },
  ];

  const createParcels = () => {
    const parcels = [];
    for (let i = 1; i <= 12; i++) {
      let parcel = {
        value: i,
        label: i === 1 ? i + 'x (À Vista)' : i + 'x',
      };
      parcels.push(parcel);
    }
    return parcels;
  };

  useEffect(() => {
    if (props.open) {
      setBuyerAddress(sale?.buyer?.buyerAddresses[0]);
      setCrrTotal(total);
    }
  }, [props.open, sale]);

  useEffect(() => {
    if (selectedParcel) {
      const valueParcel = toFixed(total / selectedParcel, 2);
      const firstParcel = total - valueParcel * (selectedParcel - 1);
      const parcels = [];
      for (let i = page * rowsPerPage; i < (page + 1) * rowsPerPage; i++) {
        if (i < selectedParcel) {
          let parcel = {
            number: i + 1,
            price: i !== 0 ? valueParcel : firstParcel,
          };
          parcels.push(parcel);
        }
      }
      setRows({ count: selectedParcel, values: parcels });
      setParcelsList(parcels);
      setCrrTotal(
        parcels.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.price;
        }, 0)
      );
    }
  }, [rowsPerPage, page, orderBy, searchBy, selectedParcel, total]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent dividers>
          <Card>
            <HeaderContainer>
              <CardHeader title="Endereço de Entrega" />
              <IconButton onClick={() => setOpen(true)}>
                <Edit />
              </IconButton>
            </HeaderContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="cep"
                  name="cep"
                  label="CEP"
                  variant="outlined"
                  value={buyerAddress.cep}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  id="address"
                  name="address"
                  label="Endereço"
                  variant="outlined"
                  value={buyerAddress.address}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  id="number"
                  name="number"
                  label="Número"
                  variant="outlined"
                  value={buyerAddress.number}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="neighborhood"
                  name="neighborhood"
                  label="Bairro"
                  variant="outlined"
                  value={buyerAddress.neighborhood}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="city"
                  name="city"
                  label="Cidade"
                  variant="outlined"
                  value={buyerAddress.city}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  id="state"
                  name="state"
                  label="Estado"
                  variant="outlined"
                  value={buyerAddress.state}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              {buyerAddress.complement ? (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="complement"
                    name="complement"
                    label="Complemento"
                    variant="outlined"
                    value=""
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>
              ) : null}
            </Grid>
          </Card>
          <Card>
            <HeaderContainer>
              <HeaderTotal>
                <CardHeader title="Pagamento" />
                <TotalContainer>
                  <H3>
                    {`Valor total: R$ ${total
                      ?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .replace('.', ',')}`}
                  </H3>
                  {[
                    'credit_card',
                    'payroll_discount',
                    'duplicate_in_wallet',
                  ].includes(selectedMethod) ? (
                    <H3>
                      {`Total das Parcelas: R$ ${crrTotal
                        ?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                        .replace('.', ',')}`}
                    </H3>
                  ) : null}
                </TotalContainer>
              </HeaderTotal>
            </HeaderContainer>
            <SelectContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    error={errorMethod}
                  >
                    <InputLabel>Forma de Pagamento</InputLabel>
                    <Select
                      value={selectedMethod || ''}
                      label="Forma de Pagamento"
                      required
                      onChange={(e) => {
                        setErrorMethod(false);
                        setSelectedMethod(e.target.value);
                        if (
                          e.target.value === 'debitCard' ||
                          e.target.value === 'money'
                        )
                          setSelectedParcel(null);
                      }}
                    >
                      {paymentMethods?.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errorMethod ? 'Este campo é obrigatório.' : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {selectedMethod === 'credit_card' ||
                selectedMethod === 'payroll_discount' ||
                selectedMethod === 'duplicate_in_wallet' ? (
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      error={errorParcel}
                    >
                      <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                        Parcelas
                      </InputLabel>
                      <Select
                        labelId="outlined-selectedUnit-native-simple"
                        id="outlined-selectedUnit-native-simple"
                        value={selectedParcel || ''}
                        label="Parcelas"
                        required
                        onChange={(e) => {
                          setErrorParcel(false);
                          setSelectedParcel(e.target.value);
                        }}
                      >
                        {createParcels()?.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errorParcel ? 'Este campo é obrigatório.' : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
            </SelectContainer>
            {selectedParcel ? (
              <StyledCrudTable
                title={'Parcelamento'}
                emptyHeader
                modelActions={Sale.actions}
                columns={columns}
                rows={rows}
                page={page}
                setPage={(page) => setPage(page)}
                rowsPerPage={rowsPerPage}
                availableRowsPerPage={[3, 6, 9, 12]}
                setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                onOrderByChange={(orderBy) => setOrderby(orderBy)}
                orderBy={orderBy}
                setOrderBy={(value) => setOrderby(value)}
                searchBy={searchBy}
                setSearchBy={(value) => setSearchBy(value)}
                customCellRenderers={{
                  price: {
                    renderer: (field, value, column, row) => {
                      let itemFind = parcelsList.find(
                        (x) => x.number === row.number
                      );
                      let price;
                      if (itemFind) price = itemFind?.price;
                      else price = value;
                      return (
                        <StyledMoneyInput
                          value={price}
                          onChange={(e, value) => {
                            let item;
                            const newList = [...parcelsList];
                            if (
                              (item = newList.find(
                                (x) => x.number === row.number
                              ))
                            ) {
                              item.price = value;
                            } else {
                              const item = {
                                number: row.number,
                                price: value,
                              };
                              newList.push(item);
                            }
                            row.price = value;
                            setCrrTotal(
                              newList.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.price;
                              }, 0)
                            );
                          }}
                        />
                      );
                    },
                  },
                }}
              />
            ) : null}
          </Card>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <CancelButton
              onClick={() => {
                setSelectedParcel(null);
                setSelectedMethod(null);
                setRowsPerPage(12);
                onCancel?.();
              }}
              type="button"
              variant="contained"
              color="primary"
            >
              Cancelar
            </CancelButton>
            <SubmitButton
              onClick={() => {
                if (selectedMethod) {
                  switch (selectedMethod) {
                    case 'credit_card':
                    case 'payroll_discount':
                    case 'duplicate_in_wallet':
                      if (selectedParcel) {
                        if (toFixed(crrTotal, 2) == total) {
                          const model = {
                            formOfPayment: selectedMethod,
                            amount: total,
                            installments: parcelsList,
                          };
                          restApiClient.paymentSale.create(model).then((r) => {
                            onSuccess?.(r.data.id, buyerAddress.id);
                          });
                        } else {
                          setAlert({
                            message:
                              'Valor total das parcelas é maior que valor da compra',
                            severity: 'error',
                          });
                        }
                      } else {
                        setErrorParcel(true);
                      }
                      break;
                    case 'pix':
                    case 'money':
                    case 'debit_card':
                      if (toFixed(crrTotal, 2) == total) {
                        const model = {
                          formOfPayment: selectedMethod,
                          amount: total,
                          installments: parcelsList,
                        };
                        restApiClient.paymentSale.create(model).then((r) => {
                          onSuccess?.(r.data.id, buyerAddress.id);
                        });
                      } else {
                        setAlert({
                          message:
                            'Valor total das parcelas é maior que valor da compra',
                          severity: 'error',
                        });
                      }
                      break;
                    default:
                      break;
                  }
                } else {
                  setErrorMethod(true);
                }
              }}
              variant="contained"
              color="primary"
            >
              Finalizar
            </SubmitButton>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
      <SelectBuyerAddressDialog
        open={open}
        buyerId={sale?.buyer?.id}
        onCancel={() => {
          setOpen(false);
        }}
        onSuccess={(data) => {
          setBuyerAddress(data);
          setOpen(false);
        }}
      />
      <Snackbar
        open={!!alert?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert?.severity}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PaymentDialog;
