import React from 'react';
import { Container, AutoDiv } from './styles';
import Employee from '../../forms/employee';
import RadiologySchedule from '../../forms/radiologySchedule';
import Treatment from '../../forms/treatment';
import LabCriticalCard from '../../components/labCriticalCard';
import SecuredItem from '../../components/securedItem';
import CenterRadiologyScheduleCard from '../../components/centerRadiologyScheduleCard';
import RadiologyCenterScheduleViewCard from '../../components/radiologyCenterScheduleViewCard';
import { useSelector } from 'react-redux';

function Dashboard() {
  const userData = useSelector((state) => state.authReducer?.user);

  const allowedLabBusyLevelActions = [
    Employee.actions.getAllLabEmployeeWithBusyLevel,
  ];

  const allowedCenterRadiologyScheduleActions = [
    [Treatment.actions.getAllByConsiderTime, RadiologySchedule.actions.create],
  ];

  const allowedCenterRadiologyViewActions = [
    [
      RadiologySchedule.actions.getAllExecutedRadiology,
      Treatment.actions.getAll,
    ],
  ];

  return (
    <Container>
      <SecuredItem allowedActions={allowedLabBusyLevelActions}>
        <AutoDiv>
          <LabCriticalCard />
        </AutoDiv>
      </SecuredItem>

      {userData?.unit?.type === 'clinic' ? (
        <SecuredItem allowedActions={allowedCenterRadiologyScheduleActions}>
          <AutoDiv>
            <CenterRadiologyScheduleCard />
          </AutoDiv>
        </SecuredItem>
      ) : null}

      {userData?.unit?.type === 'radiology' ? (
        <SecuredItem allowedActions={allowedCenterRadiologyViewActions}>
          <AutoDiv>
            <RadiologyCenterScheduleViewCard />
          </AutoDiv>
        </SecuredItem>
      ) : null}
    </Container>
  );
}

export default Dashboard;
