const LabConsumption = {
  schema: {
    type: 'object',
    properties: {
      beginDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
        beginDate: '',
      },
      endDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
        endDate: '',
        beforeToday: '',
      },
    },
    required: ['beginDate', 'endDate'],
  },
  uischema: {
    type: 'Group',
    label: 'Intervalo de Consumo - Laboratório',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Inicial',
            scope: '#/properties/beginDate',
          },
          {
            type: 'Control',
            label: 'Data Final',
            scope: '#/properties/endDate',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'beginDate', label: 'Início', minWidth: 100, align: 'center' },
    { id: 'endDate', label: 'Término', minWidth: 100, align: 'center' },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
    { id: 'total', label: 'Total', minWidth: 100, align: 'center' },
  ],
  labConsumptionValuesTableSchema: [
    {
      id: 'name',
      label: 'Seção',
      minWidth: 100,
      align: 'center',
    },
    { id: 'services', label: 'Serviços', minWidth: 100, align: 'center' },
    {
      id: 'extraCosts',
      label: 'Custos Extras',
      minWidth: 100,
      align: 'center',
    },
    { id: 'correction', label: 'Correção', minWidth: 100, align: 'center' },
    { id: 'subtotal', label: 'Subtotal', minWidth: 100, align: 'center' },
  ],
  dreConsumptionTableSchema: [
    {
      id: 'labConsumption.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'range', label: 'Período', minWidth: 100, align: 'center' },
    { id: 'services', label: 'Serviços', minWidth: 100, align: 'center' },
    {
      id: 'extraCosts',
      label: 'Custos Extras',
      minWidth: 100,
      align: 'center',
    },
    { id: 'correction', label: 'Correção', minWidth: 100, align: 'center' },
    { id: 'subtotal', label: 'Subtotal', minWidth: 100, align: 'center' },
  ],
  actions: {
    create: 'LabConsumptionController@create',
    findById: 'LabConsumptionController@findById',
    update: 'LabConsumptionController@patchUpdate',
    getAllByUnit: 'LabConsumptionController@getAllByUnit',
    getAllGroupsConsumptionByPeriod:
      'LabConsumptionController@getAllGroupsConsumptionByPeriod',
    getAllSectionsConsumptionByPeriod:
      'LabConsumptionController@getAllSectionsConsumptionByPeriod',
    setEditing: 'LabConsumptionController@setEditing',
    sendConsumption: 'LabConsumptionController@sendConsumption',
    delete: 'LabConsumptionController@delete',
  },
  valuesActions: {
    getAllByLabConsumptionId:
      'LabConsumptionValueController@getAllByLabConsumptionId',
    getAllToDre: 'LabConsumptionValueController@getAllToDre',
  },
};

export default LabConsumption;
