import { rankWith, and, uiTypeIs, schemaMatches } from '@jsonforms/core';
import { withJsonFormsEnumProps } from '@jsonforms/react';
import SelectStatusControl from '../../components/selectStatusControl';
import isEmpty from 'lodash/isEmpty';

const isSelectStatusControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'selectStatus'
  )
);

const customSelectStatusControlTester = rankWith(
  4,
  isSelectStatusControl
);

export default {
  tester: customSelectStatusControlTester,
  renderer: withJsonFormsEnumProps(SelectStatusControl),
};
