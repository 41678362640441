/* eslint-disable no-unused-expressions */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, DialogContent, StyledCreateUpdateForm } from './styles';
import restApiClient from '../../services/restApiClient';

function ChairDialog({
  onSuccess,
  onError,
  onCancel,
  chair,
  chairId,
  unitId,
  ...props
}) {
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogContent>
          <StyledCreateUpdateForm
            modelSchema={chair}
            restGetDataById={async (id) => {
              const result = await restApiClient.chair.findById(id);
              return result;
            }}
            restCreateData={restApiClient.chair.create}
            restUpdateData={async (modelId, updateOriginalData, modelData) => {
              const chair = {
                unitId: modelData.unitId,
                name: modelData.name.trim(),
              };

              return restApiClient.chair.update(modelId, chair);
            }}
            modelData={{ ...chair.data, unitId: unitId }}
            onCreateSuccess={(request) => {
              onSuccess?.(request);
            }}
            onUpdateSuccess={(request) => {
              onSuccess?.(request);
            }}
            forceId={chairId}
            onCancelClick={onCancel}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default ChairDialog;
