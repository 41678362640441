import styled from 'styled-components';
import {
  Button as MaterialButton,
} from '@material-ui/core';

import {
  AddBox as MaterialAddBox,
} from '../../../styles/icons';

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CancelButton = styled(MaterialButton)`
  background-color: #e05100 !important;
  color: #ffffff !important;
`;