const DentistConsumption = {
  schema: {
    type: 'object',
    properties: {
      beginDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
        beginDate: '',
      },
      endDate: {
        type: 'string',
        format: 'date',
        customErrors: '',
        endDate: '',
        beforeToday: '',
      },
    },
    required: ['beginDate', 'endDate'],
  },
  uischema: {
    type: 'Group',
    label: 'Intervalo de Consumo - Dentista',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Data Inicial',
            scope: '#/properties/beginDate',
          },
          {
            type: 'Control',
            label: 'Data Final',
            scope: '#/properties/endDate',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    { id: 'beginDate', label: 'Início', minWidth: 100, align: 'center' },
    { id: 'endDate', label: 'Término', minWidth: 100, align: 'center' },
    { id: 'status', label: 'Situação', minWidth: 100, align: 'center' },
    { id: 'total', label: 'Total', minWidth: 100, align: 'center' },
  ],
  dentistConsumptionValuesTableSchema: [
    {
      id: 'name',
      label: 'Especialidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
    { id: 'correction', label: 'Correção', minWidth: 100, align: 'center' },
    { id: 'subtotal', label: 'Subtotal', minWidth: 100, align: 'center' },
  ],
  dreConsumptionTableSchema: [
    {
      id: 'radiologyConsumption.unit.name',
      label: 'Unidade',
      minWidth: 100,
      align: 'center',
    },
    { id: 'range', label: 'Período', minWidth: 100, align: 'center' },
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
    { id: 'correction', label: 'Correção', minWidth: 100, align: 'center' },
    { id: 'subtotal', label: 'Subtotal', minWidth: 100, align: 'center' },
  ],
  actions: {
    create: 'DentistConsumptionController@create',
    findById: 'DentistConsumptionController@findById',
    update: 'DentistConsumptionController@patchUpdate',
    getAllByUnit: 'DentistConsumptionController@getAllByUnit',
    getAllSpecialtiesConsumptionByPeriod:
      'DentistConsumptionController@getAllSpecialtiesConsumptionByPeriod',
    setEditing: 'DentistConsumptionController@setEditing',
    sendConsumption: 'DentistConsumptionController@sendConsumption',
    delete: 'DentistConsumptionController@delete',
  },
  valuesActions: {
    getAllByDentistConsumptionId:
      'DentistConsumptionValueController@getAllByDentistConsumptionId',
    getAllToDre: 'DentistConsumptionValueController@getAllToDre',
  },
};

export default DentistConsumption;
