import React, { useState } from 'react';
import {
  Container,
  ActionsContainer,
  UnassignedSelectList,
  AssignedSelectList,
  Button,
} from './styles';

export default function TransferList({
  leftData,
  leftDataFilter = () => true,
  rightData,
  rightDataFilter = () => true,
  labelSelector,
  onChange,
  className,
}) {
  const [leftChecked, setLeftChecked] = useState([]);
  const [rightChecked, setRightChecked] = useState([]);

  return (
    <Container className={className}>
      <UnassignedSelectList
        data={leftData}
        dataFilter={leftDataFilter}
        checked={leftChecked}
        labelSelector={labelSelector}
        onChange={(selected) => {
          setLeftChecked(selected);
        }}
      />
      <ActionsContainer>
        <Button
          title="Adicionar Todos"
          variant="outlined"
          size="small"
          onClick={() => {
            onChange(
              leftData.filter(
                (value, id, arr) => !leftDataFilter(value, id, arr)
              ),
              [...rightData, ...leftData.filter(leftDataFilter)]
            );
          }}
          disabled={leftData.length === 0}
          aria-label="move all right"
        >
          ≫
        </Button>
        <Button
          title="Adicionar Selecionados"
          variant="outlined"
          size="small"
          onClick={() => {
            const leftToRight = [];
            const newLeft = [];
            for (let i = 0; i < leftData.length; i++) {
              const element = leftData[i];
              if (leftChecked.includes(i)) {
                leftToRight.push(element);
              } else {
                newLeft.push(element);
              }
            }
            const newRight = [...leftToRight, ...rightData];
            onChange(newLeft, newRight);
            setLeftChecked([]);
            setRightChecked([]);
          }}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          title="Remover Selecionados"
          variant="outlined"
          size="small"
          onClick={() => {
            const rightToLeft = [];
            const newRight = [];
            for (let i = 0; i < rightData.length; i++) {
              const element = rightData[i];
              if (rightChecked.includes(i)) {
                rightToLeft.push(element);
              } else {
                newRight.push(element);
              }
            }
            const newLeft = [...rightToLeft, ...leftData];
            onChange(newLeft, newRight);
            setLeftChecked([]);
            setRightChecked([]);
          }}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
        <Button
          title="Remover Todos"
          variant="outlined"
          size="small"
          onClick={() => {
            onChange(
              [...leftData, ...rightData.filter(rightDataFilter)],
              rightData.filter(
                (value, id, arr) => !rightDataFilter(value, id, arr)
              )
            );
          }}
          disabled={rightData.length === 0}
          aria-label="move all left"
        >
          ≪
        </Button>
      </ActionsContainer>
      <AssignedSelectList
        data={rightData}
        dataFilter={rightDataFilter}
        checked={rightChecked}
        labelSelector={labelSelector}
        onChange={(selected) => {
          setRightChecked(selected);
        }}
      />
    </Container>
  );
}
