import React, { useState, useEffect, useCallback, useRef } from 'react';
import RadiologySchedule from '../../../../forms/radiologySchedule';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import moment from 'moment';
import {
  Container,
  CheckmarkDone,
  QueuePlayNext,
  ViewShow,
  SelectContainer,
  ClockDismiss,
  CalendarSync,
  PrintOS,
  Printer,
  UserCheck,
} from './styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useReactToPrint } from 'react-to-print';
import VoucherRadiology from '../../../../components/voucherRadiology';
import DateRangePickersComponent from '../../../../components/dateRangePickersComponent';

function RadiologyScheduleList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ scheduling: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [radiologySchedule, setRadiologySchedule] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Encaminhamento`,
    content: () => {
      return componentRef.current;
    },
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }
      @media print { 
        grid {
          page-break-inside: avoid;
        }
      }`,
  });

  const columns = RadiologySchedule.tableSchema;

  const loadRadiologySchedules = useCallback(() => {
    restApiClient.radiologySchedule
      .getAllExecutedRadiologyByPeriod(
        moment(selectedDateFrom).format('DD-MM-YYYY'),
        moment(selectedDateTo).format('DD-MM-YYYY'),
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('treatment.name', searchBy)
              .or()
              .like('requestingUnit.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    loadRadiologySchedules();
  }, [loadRadiologySchedules]);

  const handleAlertClose = () => {
    setOpenMessage(false);
  };

  return (
    <Container>
      <CrudTable
        title={'Agendamentos'}
        customHeaderComponent={
          <SelectContainer>
            <DateRangePickersComponent
              dateTo={selectedDateTo}
              dateFrom={selectedDateFrom}
              handleChange={(to, from) => {
                setSelectedDateTo(to);
                setSelectedDateFrom(from);
              }}
            />
          </SelectContainer>
        }
        modelActions={RadiologySchedule.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              history.push({
                pathname: '/radiology/schedule/create',
                state: {
                  isRadiology: true,
                },
              });
            },
          },
          update: {
            onClick: (radiologyScheduleId) => {
              history.push({
                pathname: `/radiology/schedule/update/${radiologyScheduleId}/true`,
                state: {
                  isRadiology: true,
                },
              });
            },
            selector: (row) => row?.status === 'Agendado',
          },
          delete: {
            onClick: (radiologyScheduleId, index) => {
              restApiClient.radiologySchedule
                .delete(radiologyScheduleId)
                .then(() => {
                  const result = { ...rows };
                  if (index > -1) {
                    result.values.splice(index, 1);
                    result.count--;
                  }
                  setRows(result);
                });
            },
            selector: (row) => row?.status === 'Agendado',
          },
          setOnWating: {
            icon: <UserCheck />,
            onClick: (radiologyScheduleId, row) => {
              restApiClient.radiologySchedule
                .setOnWaiting(radiologyScheduleId)
                .then(() => {
                  setOpenMessage(true);
                  loadRadiologySchedules();
                });
            },
            title: 'Confirmar Presença',
            allowedActions: [RadiologySchedule.actions.setOnAttendance],
            selector: (row) => row?.status === 'Agendado',
          },
          setOnAttendance: {
            icon: <QueuePlayNext />,
            onClick: (radiologyScheduleId, row) => {
              restApiClient.radiologySchedule
                .setOnAttendance(radiologyScheduleId)
                .then(() => {
                  setOpenMessage(true);
                  loadRadiologySchedules();
                });
            },
            title: 'Iniciar Atendimento',
            allowedActions: [RadiologySchedule.actions.setOnAttendance],
            selector: (row) => row?.status === 'Em espera',
          },
          setFinished: {
            icon: <CheckmarkDone />,
            onClick: (radiologyScheduleId) => {
              restApiClient.radiologySchedule
                .setFinished(radiologyScheduleId)
                .then(() => {
                  setOpenMessage(true);
                  loadRadiologySchedules();
                });
            },
            title: 'Finalizar Atendimento',
            allowedActions: [RadiologySchedule.actions.setFinished],
            selector: (row) => row?.status === 'Em Atendimento',
          },
          view: {
            icon: <ViewShow />,
            onClick: (radiologyScheduleId, row) => {
              history.push({
                pathname: `/radiology/schedule/update/${radiologyScheduleId}/false`,
                state: {
                  isRadiology: true,
                  reschedule: !!row?.reschedulingJustification,
                  viewForm: true,
                },
              });
            },
            title: 'Visualizar agendamento',
            allowedActions: [RadiologySchedule.actions.update],
            selector: (row) => row?.status !== 'Agendado',
          },
          rescheduling: {
            icon: <CalendarSync />,
            onClick: (radiologyScheduleId) => {
              history.push({
                pathname: `/radiology/schedule/update/${radiologyScheduleId}/true`,
                state: {
                  reschedule: true,
                  isRadiology: true,
                },
              });
            },
            title: 'Reagendamento',
            allowedActions: [RadiologySchedule.actions.rescheduleRadiology],
            selector: (row) => row?.status === 'Agendado',
          },
          missedAttendance: {
            icon: <ClockDismiss />,
            onClick: (radiologyScheduleId) => {
              restApiClient.radiologySchedule
                .setMissed(radiologyScheduleId)
                .then(() => {
                  setOpenMessage(true);
                  loadRadiologySchedules();
                });
            },
            title: 'Falta',
            allowedActions: [RadiologySchedule.actions.setMissed],
            selector: (row) =>
              row?.status === 'Agendado' &&
              new Date(row.scheduling).getTime() < new Date().getTime(),
          },
          printFowarding: {
            icon: <Printer />,
            onClick: async (radiologyScheduleId, row) => {
              await setRadiologySchedule(row);
              handlePrint();
            },
            title: 'Imprimir Encaminhamento',
          },
        }}
        customCellRenderers={{
          scheduling: {
            renderer: (field, value, column, row) => {
              const date = new Date(value);
              let options = {
                minute: 'numeric' || '2-digit',
                hour: 'numeric' || '2-digit',
                day: '2-digit' || 'numeric',
                month: '2-digit' || 'short',
                year: 'numeric' || '2-digit',
                hourCycle: 'h23',
              };
              return value
                ? row.fit
                  ? `${date.toLocaleDateString('pt-br', options)} (Encaixe)`
                  : `${date.toLocaleDateString('pt-br', options)}`
                : '-';
            },
          },
          'patient.recordNumber': {
            renderer: (field, value) => {
              return value ?? '-';
            },
          },
        }}
      />

      <Snackbar
        open={openMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {'Agendamento atualizado com Sucesso'}
        </Alert>
      </Snackbar>

      <PrintOS ref={componentRef}>
        <VoucherRadiology crrRadiologySchedule={radiologySchedule} />
      </PrintOS>
    </Container>
  );
}

export default RadiologyScheduleList;
