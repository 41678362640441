import React, { useState, useEffect, useCallback } from 'react';
import OrderItem from '../../../../forms/orderItem';
import Item from '../../../../forms/item';
import Order from '../../../../forms/order';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import ItemDialog from '../../../../components/itemDialog';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  AddBox,
  Button,
  SaveIcon,
  StyledTextFieldInput,
} from './styles';
import ErrorDialog from '../../../../components/errorDialog';
import { cloneDeep, isEqualWith, isEmpty } from 'lodash';
import { first, last } from 'lodash';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

function SeparateOrderItems({ history, match }) {
  const unitId = useSelector((x) => x.authReducer.user.unitId);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ itemId: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const orderId = match.params?.id;
  const columns = OrderItem.tableSchemaSeparateOrder;
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [order, setOrder] = useState({});
  const [itemList, setItemList] = useState([]);
  const [originalItemList, setOriginalItemList] = useState([]);
  const [fetching, setFetching] = React.useState(false);

  async function alterStatus() {
    const order = await restApiClient.order.findById(orderId);

    if (order.data.status === 'Submetido') {
      await restApiClient.order.separateOrder(order.data.id);
    }
  }

  const updateList = () => {
    restApiClient.orderItem
      .getAllByOrderWithItemStock(
        orderId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('item.id', searchBy)
              .or()
              .like('item.name', searchBy)
              .or()
              .like('item.itemLocators.locator', searchBy)
              .or()
              .like('item.brand', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  };

  useEffect(() => {
    if (isEmpty(order)) {
      restApiClient.order.findById(orderId).then((e) => {
        setOrder(e.data);
        setOriginalItemList(e.data.orderItem);
        setItemList(cloneDeep(e.data.orderItem));
      });
    }
  }, [orderId, order]);

  useEffect(() => {
    updateList();
  }, [rowsPerPage, page, orderBy, searchBy, orderId]);

  const saveListItems = async () => {
    setFetching(true);
    const edited = itemList
      .filter(
        (item) =>
          !originalItemList.some((obj) =>
            isEqualWith(
              obj,
              item,
              (obj1, obj2) =>
                obj1.itemId === obj2.itemId &&
                obj1.quantity === obj2.quantity &&
                obj1.orderId === obj2.orderId &&
                obj1.approvedQuantity === obj2.approvedQuantity
            )
          )
      )
      .map(({ id, orderId, itemId, quantity, approvedQuantity }) => ({
        id,
        orderId,
        itemId,
        quantity,
        approvedQuantity,
      }));

    await restApiClient.order.updateByStockItem(
      orderId,
      '',
      '',
      [],
      [],
      edited
    );
  };

  return (
    <Container>
      <CrudTable
        title={`Pedido de Transferência #${orderId}`}
        customFooterComponent={
          <>
            {fetching ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => {
                  saveListItems().then(() =>
                    alterStatus().then(() => {
                      setFetching(false);
                      history.goBack();
                    })
                  );
                }}
              >
                Salvar e Voltar
              </Button>
            )}
          </>
        }
        modelActions={OrderItem.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => {
              setOpenItemDialog(true);
            },
            title: 'Adicionar Itens',
            allowedActions: [Order.actions.updateByStock],
          },
        }}
        customCellRenderers={{
          'item.itemLocators': {
            renderer: (field, value) => {
              return first(value)?.locator ? first(value)?.locator : '-';
            },
          },
          'item.fromStock': {
            renderer: (field, value) => {
              return value ? value : 0;
            },
          },
          'item.toStock': {
            renderer: (field, value) => {
              return value ? value : 0;
            },
          },
          approvedQuantity: {
            renderer: (field, value, column, row) => {
              let itemFind = itemList.find((x) => x.itemId === row.itemId);
              let approvedQuantity = '';
              if (itemFind)
                approvedQuantity =
                  itemFind?.approvedQuantity === 0
                    ? ''
                    : itemFind?.approvedQuantity;
              else approvedQuantity = value;
              return (
                <StyledTextFieldInput
                  key={row.id}
                  value={approvedQuantity}
                  onChange={(e) => {
                    let item = null;
                    const newList = [...itemList];
                    if ((item = newList.find((x) => x.itemId === row.itemId))) {
                      item.approvedQuantity = parseInt(
                        e.target.value !== '' ? e.target.value : 0
                      );
                    } else {
                      const item = {
                        itemId: row.itemId,
                        approvedQuantity: parseInt(
                          e.target.value !== '' ? e.target.value : 0
                        ),
                        orderId: parseInt(orderId),
                        id: row.id,
                      };
                      newList.push(item);
                    }
                    row.approvedQuantity = e.target.value;
                    setItemList(newList);
                  }}
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                />
              );
            },
          },
        }}
      />

      <ErrorDialog
        open={errorMessage !== ''}
        title="Erro"
        error={errorMessage}
        onClose={() => {
          setErrorMessage('');
        }}
      />

      <ItemDialog
        open={openItemDialog}
        onSelect={(id, row) => {
          restApiClient.order
            .updateByStockItem(
              orderId,
              '',
              '',
              [{ quantity: 0, itemId: id }],
              [],
              []
            )
            .then((data) => {
              updateList();
              setOpenItemDialog(false);
            });
        }}
        onCancel={() => {
          setOpenItemDialog(false);
        }}
        unitId={unitId}
        disabledIds={rows.values.map((x) => x.itemId)}
      />
    </Container>
  );
}

export default SeparateOrderItems;
