import styled from 'styled-components';
import {
  Dialog as MaterialDialog,
  Button as MaterialButton,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  Card as MaterialCard,
  FormControl as MaterialFormControl,
  CardHeader as MaterialCardHeader,
  Checkbox as MaterialCheckbox,
  FormControlLabel as MaterialFormControlLabel,
  FormHelperText as MaterialFormHelperText,
  Grid as MaterialGrid,
  TextField as MaterialTextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';
import { Home as MaterialHome } from '../../styles/icons';
import CrudTable from '../crudTable';
import MoneyInput from '../moneyInput';
import SelectUnitFinanceDialog from '../selectUnitFinanceDialogControl';

export const SelectContainer = styled.div``;

export const Container = styled.div``;

export const Dialog = styled(MaterialDialog)``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5px;
  align-items: center !important;
`;

export const CardHeader = styled(MaterialCardHeader)``;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DialogActions = styled(MaterialDialogActions)`
  padding: 0 !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  padding: 10px !important;
`;

export const SubmitButton = styled(MaterialButton)`
  width: 40%;
  color: #ffffff !important;
`;

export const CancelButton = styled(MaterialButton)`
  width: 40%;
  background-color: #e05100 !important;
  color: #ffffff !important;
`;

export const Home = styled(MaterialHome)`
  width: 25px;
  height: 25px;
`;

export const Card = styled(MaterialCard)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCrudTable = styled(CrudTable)`
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
  height: 290px;
  overflow: auto;
`;

export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  width: 90px;
  padding: 6px !important;
`;

export const MuiPickersUtilsProvider = styled(
  MaterialMuiPickersUtilsProvider
)``;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  height: auto;
`;

export const DatePickerCell = styled(MaterialKeyboardDatePicker)`
  height: auto;
  width: 140px;
`;

export const Grid = styled(MaterialGrid)``;

export const Checkbox = styled(MaterialCheckbox)``;

export const FormControlLabel = styled(MaterialFormControlLabel)``;

export const FormHelperText = styled(MaterialFormHelperText)``;

export const SelectUnitFinance = styled(SelectUnitFinanceDialog)`
  margin-top: 10px !important;
`;

export const TextField = styled(MaterialTextField)`
  margin-bottom: 5px !important;
`;
