import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Container,
  ErrorContainer,
  ExclamationTriangleFill,
  HeaderContainer,
  MoneyCalculator,
} from './styles';
import CashRegisterClose from '../../../../../../forms/cashRegisterClose';
import Expense from '../../../../../../forms/expense';
import UnitFinance from '../../../../../../forms/unitFinance';
import restApiClient from '../../../../../../services/restApiClient';
import FilterTree from '../../../../../../helpers/filterTree';
import CrudTable from '../../../../../../components/crudTable';
import moment from 'moment';
import { Typography, Badge } from '@material-ui/core';
import SecuredItem from '../../../../../../components/securedItem';

function BankAccountConsolidationList({ history }) {
  const persistentFilter = JSON.parse(
    window.localStorage.getItem('consolidationFilterState')
  ) || {
    page: 0,
  };

  const [page, setPage] = useState(persistentFilter.page);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [pendingExpenseCount, setPendingExpenseCount] = useState(0);
  const [pendingCashRegisterCloseCount, setPendingCashRegisterCloseCount] =
    useState(0);
  const [consolidationDate, setConsolidationDate] = useState('');
  const columns = UnitFinance.consolidationTableSchema;

  const loadBankAccounts = useCallback(() => {
    restApiClient.unitFinance
      .getAllWithConsolidation(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('bankAccount.holderName', searchBy)
              .or()
              .like('bankAccount.bank', searchBy)
              .or()
              .like('bankAccount.account', searchBy)
              .or()
              .like('bankAccount.agency', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        let newRows = e.data;
        setPendingExpenseCount(newRows.pendingExpenseCount);
        setPendingCashRegisterCloseCount(newRows.pendingCashRegisterCloseCount);
        setConsolidationDate(newRows.consolidationDate);
        newRows.values = e.data.values.map((unitFinance) => {
          return {
            ...unitFinance,
            ...unitFinance.bankAccount,
            id: unitFinance.id,
            balanceValue: unitFinance.balanceValue,
            deletedAt: unitFinance.deletedAt,
          };
        });
        setRows(newRows);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadBankAccounts();
  }, [loadBankAccounts]);

  useEffect(() => {
    window.localStorage.setItem(
      'consolidationFilterState',
      JSON.stringify({
        page,
      })
    );
  }, [page]);

  return (
    <Container>
      <CrudTable
        title={'Consolidação de Contas Bancárias'}
        customHeaderComponent={
          <HeaderContainer>
            {pendingExpenseCount > 0 && (
              <SecuredItem allowedActions={[Expense.actions.getAll]}>
                <ErrorContainer>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        pathname: `/financial/bank-account-consolidation/expenses`,
                        state: {
                          consolidationDate,
                        },
                      });
                    }}
                    title={'Despesas Pendentes'}
                  >
                    <Badge badgeContent={pendingExpenseCount} color="error">
                      <ExclamationTriangleFill />
                    </Badge>
                  </IconButton>
                  <Typography align="center" style={{ margin: 'auto' }}>
                    Despesas Pendentes!
                  </Typography>
                </ErrorContainer>
              </SecuredItem>
            )}
            {pendingCashRegisterCloseCount > 0 && (
              <SecuredItem allowedActions={[CashRegisterClose.actions.getAll]}>
                <ErrorContainer>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        pathname: `/financial/bank-account-consolidation/cashRegisterClose`,
                        state: {
                          consolidationDate,
                        },
                      });
                    }}
                    title={'Caixas Pendentes'}
                  >
                    <Badge
                      badgeContent={pendingCashRegisterCloseCount}
                      color="error"
                    >
                      <ExclamationTriangleFill />
                    </Badge>
                  </IconButton>
                  <Typography align="center" style={{ margin: 'auto' }}>
                    Caixas Pendentes!
                  </Typography>
                </ErrorContainer>
              </SecuredItem>
            )}
          </HeaderContainer>
        }
        modelActions={UnitFinance.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          consolidate: {
            icon: <MoneyCalculator />,
            onClick: (id) => {
              history.push(
                `/financial/bank-account-consolidation/${id}/statement`
              );
            },
            allowedActions: [
              [
                UnitFinance.actions.findByIdWithHistory,
                UnitFinance.actions.consolidate,
              ],
            ],
            selector: (row) =>
              moment(row.date).add(1, 'days').diff(moment(), 'days') < 0,
          },
        }}
        customCellRenderers={{
          consolidatedBalanceValue: {
            renderer: (field, value) => {
              return parseFloat(value).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          holderName: {
            renderer: (field, value, column, row) => {
              return value ?? row.type;
            },
          },
          date: {
            renderer: (field, value) => {
              return moment(value).format('DD/MM/YYYY');
            },
          },
          'bankAccount.account': {
            renderer: (field, value, column, row) => {
              return row.account
                ? `${row.account}${
                    row.accountDigit ? `-${row.accountDigit}` : ''
                  }`
                : '-';
            },
          },
          'bankAccount.agency': {
            renderer: (field, value, column, row) => {
              return row.agency
                ? `${row.agency}${row.agencyDigit ? `-${row.agencyDigit}` : ''}`
                : '-';
            },
          },
        }}
      />
    </Container>
  );
}

export default BankAccountConsolidationList;
