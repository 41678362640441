import styled from 'styled-components';
import {
  Preview as MaterialPreview,
  Import as MaterialImport,
  Payments as MaterialPayments,
} from '../../../../styles/icons';
import {
  Button as MaterialButton,
  Select as MaterialSelect,
  MenuItem as MaterialMenuItem,
  InputLabel as MaterialInputLabel,
  FormControl as MaterialFormControl,
  FormHelperText as MaterialFormHelperText,
} from '@material-ui/core';
import MoneyInput from '../../../../components/moneyInput';
import SelectServiceSupplierDialogControl from '../../../../components/selectServiceSupplierDialogControl';
import SelectPaymentTypeDialogControl from '../../../../components/selectPaymentTypeDialogControl';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  margin: 0px !important;
`;

export const StyledMuiPickersUtilsProvider = styled(MuiPickersUtilsProvider)``;

export const StyledSelectServiceSupplierDialogControl = styled(
  SelectServiceSupplierDialogControl
)`
  height: 63px;
`;
export const StyledSelectPaymentTypeDialogControl = styled(
  SelectPaymentTypeDialogControl
)`
  height: 63px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const PlaceholderContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  font-size: 0.95rem;
  color: red;
`;

export const ImportCsvButton = styled(MaterialButton)`
  color: #ffffff !important;
  background: #2dbd17 !important;
`;

export const Import = styled(MaterialImport)`
  width: 25px;
  height: 25px;
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
`;

export const MenuItem = styled(MaterialMenuItem)``;

export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;

export const FormControl = styled(MaterialFormControl)`
  height: 63px;
  width: 100%;
`;

export const FormHelperText = styled(MaterialFormHelperText)``;

export const StyledMoneyInput = styled(MoneyInput)``;

export const Payments = styled(MaterialPayments)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;
