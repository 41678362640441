import styled from 'styled-components';
import { DialogContent as MaterialDialogContent } from '@material-ui/core';
import CreateUpdateForm from '../createUpdateForm';
import MaterialFormHelperText from '@material-ui/core/FormHelperText';

export const Container = styled.div`
`;

export const StyledText = styled.h4`
  color: red;
  margin: 10px !important;
`;

export const DialogContent = styled(MaterialDialogContent)`
  width: 450px !important;
  margin-bottom: 30px !important;
  padding: 0px !important;
`;

export const StyledCreateUpdateForm = styled(CreateUpdateForm)``;

export const FormHelperText = styled(MaterialFormHelperText)``;
