const Stock = {
  tableSchema: [
    { id: 'id', label: 'Código', minWidth: 25, align: 'center' },
    { id: 'name', label: 'Nome', minWidth: 100, align: 'center' },
    {
      id: 'barcode',
      label: 'Código de barras',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'itemLocators',
      label: 'Localizador',
      minWidth: 100,
      align: 'center',
    },
    { id: 'brand', label: 'Marca', minWidth: 100, align: 'center' },
    {
      id: 'stock.availableQuantity',
      label: 'Quantidade em estoque',
      minWidth: 25,
      align: 'center',
    },
  ],
  actions: {
    withdrawItems: 'ItemWithdrawController@withdrawItems',
    withdrawItem: 'ItemWithdrawController@withdrawItem',
    adjustQuantityStock: 'StockHistoryController@adjustQuantityStock',
    getAllStockHistoriesValuesByPeriod:
      'StockHistoryController@getAllStockHistoriesValuesByPeriod',
    clinicAdjustQuantityStock:
      'ClinicAdjustmentHistoryController@adjustQuantityStock',
  },
};

export default Stock;
