import React, { useState, useEffect } from 'react';
import Stock from '../../../../forms/stock';
import Unit from '../../../../forms/unit';
import Item from '../../../../forms/item';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import {
  Container,
  SelectContainer,
  Numbers,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from './styles';
import AdjustStockDialog from '../../../../components/adjustStockDialog';
import ErrorDialog from '../../../../components/errorDialog';
import { useSelector } from 'react-redux';
import LocatorItemDialog from '../../../../components/locatorItemDialog';
import isEmpty from 'lodash/isEmpty';

function LocatorList({ history }) {
  const user = useSelector((x) => x.authReducer.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [openAdjust, setOpenAdjust] = useState(false);
  const [units, setUnits] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(user?.unitId ?? '');
  const [selectedRow, setSelectedRow] = useState({});
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openLocator, setOpenLocator] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const columns = Stock.tableSchema;

  async function loadItems() {
    await restApiClient.item
      .getAllWithStock(
        selectedUnit,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .or()
              .like('brand', searchBy)
              .or()
              .like('barcode.barcode', searchBy)
              .or()
              .like('itemLocators.locator', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }

  useEffect(() => {
    if (selectedUnit) {
      loadItems();
    }
  }, [rowsPerPage, page, orderBy, searchBy, selectedUnit]);

  useEffect(() => {
    restApiClient.unit.getAll().then((e) => {
      setUnits(e.data);
    });
  }, []);

  return (
    <Container>
      <CrudTable
        title={'Ajuste de Estoque'}
        customHeaderComponent={
          <SelectContainer>
            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-selectedUnit-native-simple">
                Selecione uma unidade
              </InputLabel>
              <Select
                value={selectedUnit || ''}
                label="Selecione uma Unidade"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                }}
              >
                {[
                  <MenuItem value="" key={'empty'}>
                    <em>Nenhum</em>
                  </MenuItem>,
                ].concat(
                  units?.values.map((optionValue) => (
                    <MenuItem value={optionValue.id} key={optionValue.id}>
                      {optionValue.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </SelectContainer>
        }
        modelActions={Stock.actions}
        columns={columns}
        rows={selectedUnit ? rows : { count: 0, values: [] }}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          update: {
            onClick: (itemId) => history.push(`/item/update/${itemId}`),
            allowedActions: [[Item.actions.findById, Item.actions.update]],
          },
          adjustQuantity: {
            icon: <Numbers />,
            onClick: (id, row) => {
              if (row.price === 0) {
                setOpenErrorDialog(true);
              } else {
                setSelectedRow(row);
                setOpenAdjust(true);
              }
            },
            title: 'Ajustar quantidade',
            allowedActions: [Stock.actions.adjustQuantityStock],
          },
          locator: {
            icon: <Grid />,
            onClick: (id) => {
              setOpenLocator(true);
              setSelectedId(id);
            },
            title: 'Definir Localizador',
            allowedActions: [
              [
                Item.itemLocatorActions.create,
                Item.itemLocatorActions.update,
                Unit.actions.getAll,
                Item.actions.findByIdAndUnit,
              ],
            ],
          },
        }}
        customCellRenderers={{
          itemLocators: {
            renderer: (field, value) => {
              return value[0]?.locator ? value[0]?.locator : '-';
            },
          },
          barcode: {
            renderer: (field, value) => {
              return !isEmpty(value)
                ? value.map((item) => item.barcode).join(', ')
                : '-';
            },
          },
        }}
      />

      <ErrorDialog
        open={openErrorDialog}
        title="Erro"
        error="Itens sem preço não podem ser ajustados"
        onClose={() => {
          setOpenErrorDialog(false);
        }}
      />

      <AdjustStockDialog
        open={openAdjust}
        item={selectedRow}
        unitId={selectedUnit}
        onCancel={() => setOpenAdjust(false)}
        onSuccess={() => {
          setOpenAdjust(false);
          loadItems();
        }}
      />

      <LocatorItemDialog
        open={openLocator}
        itemId={selectedId}
        onCancel={() => setOpenLocator(false)}
        onSuccess={() => {
          setOpenLocator(false);
          loadItems();
        }}
      />
    </Container>
  );
}

export default LocatorList;
