import { isEmpty, isNil } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Appointments,
  ContentContainer,
  DateNavigator,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewState,
  WeekView,
} from './styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import restApiClient from '../../services/restApiClient';

function SchedulerComponent({
  treatment,
  onSuccess
}) {
  const selectedTreatment = treatment;
  const [treatmentPeriod, setTreatmentPeriod] = useState(null);
  const [crrDate, setCrrDate] = useState(moment());
  const [rows, setRows] = useState([]);
  const [officeHours, setOfficeHours] = useState(null);
  const [holidays, setHolidays] = useState(null);
  const [recess, setRecess] = useState(null);
  const [breaks, setBreaks] = useState(null);
  const [fetching, setFetching] = useState(false);

  const weekDays = [
    ['sunday', 0],
    ['monday', 1],
    ['tuesday', 2],
    ['wednesday', 3],
    ['thursday', 4],
    ['friday', 5],
    ['saturday', 6],
  ];
  const firstDayOfWeek = moment(crrDate).day(0).format('DD-MM-YYYY');
  const lastDayOfWeek = moment(crrDate).day(6).format('DD-MM-YYYY');

  useEffect(() => {
    if(!isEmpty(selectedTreatment)) {
      restApiClient.openingHour.getAllByTreatment(selectedTreatment?.id).then((e) => {
        setOfficeHours(e.data);
      });
    }
    restApiClient.holiday
      .getAllRadiologyByPeriod(firstDayOfWeek, lastDayOfWeek)
      .then((e) => {
        setHolidays(e.data.values);
      });
    restApiClient.recess
      .getAllByPeriod(firstDayOfWeek, lastDayOfWeek)
      .then((e) => {
        setRecess(e.data.values);
      });
  }, [crrDate, selectedTreatment]);

  useEffect(() => {
    if (!isNil(holidays) && !isNil(recess)) {
      setBreaks([
        ...holidays.map((x) => {
          const [beginHour, beginMinutes, beginSeconds] =
            x.beginTime.split(':');
          const [endHour, endMinutes, endSeconds] = x.endTime.split(':');
          return {
            startDate: moment(x.date)
              .add(beginHour, 'hours')
              .add(beginMinutes, 'minutes')
              .add(beginSeconds, 'seconds'),
            endDate: moment(x.date)
              .add(endHour, 'hours')
              .add(endMinutes, 'minutes')
              .add(endSeconds, 'seconds'),
          };
        }),
        ...recess.map((x) => {
          const [beginHour, beginMinutes, beginSeconds] =
            x.beginTime.split(':');
          const [endHour, endMinutes, endSeconds] = x.endTime.split(':');
          return {
            startDate: moment(x.date)
              .add(beginHour, 'hours')
              .add(beginMinutes, 'minutes')
              .add(beginSeconds, 'seconds'),
            endDate: moment(x.date)
              .add(endHour, 'hours')
              .add(endMinutes, 'minutes')
              .add(endSeconds, 'seconds'),
          };
        }),
      ]);
    }
  }, [holidays, recess]);

  useEffect(() => {
    if (!isNil(officeHours) && !isNil(breaks) && !isNil(selectedTreatment)) {
      setFetching(true);
      restApiClient.radiologySchedule
        .getAllExecutedRadiologyByPeriod(firstDayOfWeek, lastDayOfWeek)
        .then((e) => {
          const schedule = [
            ...breaks,
            ...e.data.values
              .filter((x) => x.treatment.considerTime && !x.fit)
              .map((x) => {
                const [hour, minutes, seconds] =
                  x.treatment.executionTime.split(':');
                return {
                  startDate: moment(x.scheduling),
                  endDate: moment(x.scheduling)
                    .add(hour, 'hours')
                    .add(minutes, 'minutes')
                    .add(seconds, 'seconds'),
                };
              }),
          ];
          const newRows = [];
          const [treatmentHour, treatmentMinutes, _] =
            selectedTreatment.executionTime.split(':').map((x) => parseInt(x));
          const treatmentPeriod = treatmentHour * 60 + treatmentMinutes;
          setTreatmentPeriod(treatmentPeriod);
          weekDays.forEach(([dayName, i]) => {
            const periods = officeHours[dayName];
            if (
              !isEmpty(periods) &&
              moment(crrDate)
                .day(i)
                .isAfter(
                  moment().set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                )
            ) {
              periods.forEach((period) => {
                const [beginHour, beginMinute] = period.beginInterval
                  .split(':')
                  .map((x) => parseInt(x));
                const [endHour, endMinute] = period.endInterval
                  .split(':')
                  .map((x) => parseInt(x));
                let appointmentBegin = moment(crrDate)
                  .day(i)
                  .hour(beginHour)
                  .minute(beginMinute)
                  .second(0)
                  .millisecond(1);
                let appointmentEnd = moment(crrDate)
                  .day(i)
                  .hour(beginHour)
                  .minute(beginMinute)
                  .second(0)
                  .millisecond(1)
                  .add(treatmentPeriod, 'minutes');
                let periodEnd = moment(crrDate)
                  .day(i)
                  .hour(endHour)
                  .minute(endMinute)
                  .second(0);
                while (appointmentEnd.isBefore(periodEnd)) {
                  if (
                    appointmentEnd.isAfter(moment()) &&
                    !schedule.some(
                      (x) =>
                        (x.startDate.isBefore(appointmentBegin) &&
                          x.endDate.isAfter(appointmentBegin)) ||
                        (x.startDate.isBefore(appointmentEnd, 'minutes') &&
                          x.endDate.isAfter(appointmentEnd, 'minutes')) ||
                        (appointmentBegin.isBefore(x.startDate) &&
                          appointmentEnd.isAfter(x.startDate, 'minutes')) ||
                        (appointmentBegin.isBefore(x.endDate) &&
                          appointmentEnd.isAfter(x.endDate, 'minutes')) ||
                        appointmentBegin.isBefore(moment())
                    )
                  ) {
                    newRows.push({
                      startDate: appointmentBegin.format('YYYY-MM-DD HH:mm:ss'),
                      title: 'Horário Disponível',
                    });
                  }
                  appointmentBegin.add(treatmentPeriod, 'minutes');
                  appointmentEnd.add(treatmentPeriod, 'minutes');
                }
              });
            }
          });
          setRows(newRows);
          setFetching(false);
        });
    }
  }, [officeHours, breaks, selectedTreatment]);

  return (
    <ContentContainer>
      {isEmpty(officeHours) || isNil(breaks) || fetching || isNil(treatmentPeriod) ? (
        isEmpty(officeHours) ? (
          <>Configure o Horário de Atendimento</>
        ) : (
          <CircularProgress />
        )
      ) : (
        <Scheduler data={rows} locale="pt-br">
          <ViewState
            currentDate={crrDate}
            onCurrentDateChange={(newDate) => {
              setRecess(null);
              setHolidays(null);
              setCrrDate(moment(newDate));
            }}
          />
          <WeekView
            startDayHour={8}
            endDayHour={18}
            cellDuration={treatmentPeriod}
            excludedDays={weekDays
              .filter(
                ([dayName]) =>
                  !Object.keys(officeHours).includes(dayName)
              )
              .map(([_, index]) => index)}
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton messages={{ today: 'Hoje' }} />
          <Appointments
            appointmentComponent={(appointmentProps) => (
              <Appointments.Appointment
                onClick={({ data }) => {
                  onSuccess(moment(data.startDate).format());
                }}
                style={{
                  height: '45px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '4px',
                  width: '100%',
                }}
                {...appointmentProps}
              />
            )}
          />
        </Scheduler>
      )}
    </ContentContainer>
  );
}

export default SchedulerComponent;
