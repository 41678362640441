/* eslint-disable no-unused-expressions */
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  Input,
  Dialog,
  Select,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
  SubmitButton,
  CancelButton,
  DialogContent,
  DialogActions,
  SelectContainer,
  ActionsContainer,
  Card,
  CardHeader,
  HeaderContainer,
} from './styles';
import restApiClient from '../../services/restApiClient';

function LocatorItemDialog({ onSuccess, onError, onCancel, itemId, ...props }) {
  const user = useSelector((x) => x.authReducer.user);
  const [locator, setLocator] = useState('');
  const [errors, setErrors] = useState([]);
  const [units, setUnits] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(user?.unitId ?? '');
  const [item, setItem] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const itemLocator = {
      itemId: item.id,
      unitId: selectedUnit,
      locator: locator,
    };
    const oldItemLocator = item.itemLocators[0]?.id ? item.itemLocators[0] : '';
    if (oldItemLocator === '') {
      restApiClient.itemLocator
        .create(itemLocator)
        .then((result) => {
          setLocator('');
          setSelectedUnit(user?.unitId ?? '');
          onSuccess();
        })
        .catch((x) => {
          if (x.response?.status === 400) {
            onError?.(x.response);
            setErrors(x.response.data.errors.locator.join(' '));
          }
        });
    } else {
      await restApiClient.itemLocator
        .update(oldItemLocator.id, oldItemLocator, itemLocator)
        .then((result) => {
          setLocator('');
          setSelectedUnit(user?.unitId ?? '');
          onSuccess();
        })
        .catch((x) => {
          if (x.response?.status === 400) {
            onError?.(x.response);
            setErrors(x.response.data.errors.locator.join(' '));
          }
        });
    }
  };

  useEffect(() => {
    if (itemId) {
      restApiClient.unit.getAll().then((e) => {
        setUnits(e.data);
      });
    }
  }, [itemId]);

  useEffect(() => {
    if (itemId && selectedUnit) {
      restApiClient.item.findByIdAndUnit(itemId, selectedUnit).then((e) => {
        setItem(e.data);
        setLocator(e.data?.itemLocators[0]?.locator ?? '');
      });
    }
  }, [itemId, selectedUnit]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Definir Localizador" />
              </HeaderContainer>
              <SelectContainer>
                <FormControl size="small">
                  <InputLabel>Selecione uma unidade</InputLabel>
                  <Select
                    value={selectedUnit || ''}
                    label="Selecione uma Unidade"
                    onChange={(e) => {
                      setSelectedUnit(e.target.value);
                    }}
                  >
                    {units?.values.map((optionValue) => (
                      <MenuItem value={optionValue.id} key={optionValue.id}>
                        {optionValue.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </SelectContainer>
              <Input
                required
                type="text"
                label="Localizador"
                value={locator}
                error={!isEmpty(errors.quantity)}
                helperText={!isEmpty(errors) ? errors.join(' ') : ''}
                onChange={(e) => setLocator(e.target.value.toUpperCase())}
              />
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setErrors([]);
                    setLocator('');
                    onCancel?.();
                    setSelectedUnit(user?.unitId ?? '');
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Entrar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </Container>
  );
}

export default LocatorItemDialog;
