import React, { useState, useEffect } from 'react';
import Unit from '../../../forms/unit';
import Chair from '../../../forms/chair';
import restApiClient from '../../../services/restApiClient';
import CrudTable from '../../../components/crudTable';
import FilterTree from '../../../helpers/filterTree';
import { Container, ChairAlt, Calendar } from './styles';
import DentistOfficeHour from '../../../forms/dentistOfficeHour';

function UnitList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ name: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = Unit.tableSchema;

  useEffect(() => {
    restApiClient.unit
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy ? new FilterTree().like('name', searchBy).toString() : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Unidades'}
        modelActions={Unit.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        actions={{
          add: {
            onClick: () => history.push('/unit/create'),
          },
          update: {
            onClick: (unitId) => history.push(`/unit/update/${unitId}`),
          },
          addChairs: {
            icon: <ChairAlt />,
            onClick: (unitId) => history.push(`/unit/${unitId}/chairs`),
            title: 'Vincular Cadeiras',
            allowedActions: [
              [Chair.actions.getAllByUnit, Chair.actions.create],
            ],
            selector: (row) => row.type === 'clinic',
          },
          unitSchedule: {
            icon: <Calendar />,
            onClick: (unitId) => history.push(`/unit/${unitId}/schedule`),
            title: 'Agenda da unidade',
            allowedActions: [
              [Chair.actions.getAllByUnit, DentistOfficeHour.actions.getAllByUnit],
            ],
            selector: (row) => row.type === 'clinic' && row.chairsCount > 0,
          },
          delete: {
            onClick: (unitId, index) => {
              restApiClient.unit.delete(unitId).then(() => {
                const result = { ...rows };
                if (index > -1) {
                  result.values.splice(index, 1);
                  result.count--;
                }
                setRows(result);
              });
            },
          },
        }}
      />
    </Container>
  );
}

export default UnitList;
