import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import OrderService from '../../../../../forms/orderService';
import restApiClient from '../../../../../services/restApiClient';
import CrudTable from '../../../../../components/crudTable';
import FilterTree from '../../../../../helpers/filterTree';
import ViewUrgencyDialog from '../../../../../components/viewUrgencyDialog';
import {
  Container,
  Preview,
  ExclamationTriangleFill,
  Printer,
  PrintOS,
  AddBox,
  AddToQueue,
  ReceiptRefund,
  Check,
  Restore,
  Cancel,
} from './styles';
import { useReactToPrint } from 'react-to-print';
import VoucherOS from '../../../../../components/voucherOS';
import SubmitDialog from '../../../../../components/submitDialog';
import OrderServiceDialog from '../../../../../components/orderServiceDialog';
import DentistRefusedDialog from '../../../../../components/dentistRefusedDialog';

function AllOrderServiceList({ history, ...props }) {
  const userData = useSelector((state) => state.authReducer?.user);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'desc' });
  const [searchBy, setSearchBy] = useState('');
  const [open, setOpen] = useState(false);
  const [openUrgencyDialog, setOpenUrgencyDialog] = useState(false);
  const [selectedOrderService, setSelectedOrderService] = useState(false);
  const [justification, setJustification] = useState('');
  const [orderService, setOrderService] = useState(null);
  const [printJustification, setPrintJustification] = useState(false);
  const [openRejection, setOpenRejection] = useState(false);
  const [orderServiceStepId, setOrderServiceStepId] = useState(null);
  const [workflowPosition, setWorkflowPosition] = useState(-1);
  const [createPath, setCreatePath] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [createDialogText, setCreateDialogText] = useState('');
  const [openRefuse, setOpenRefuse] = useState(false);
  const [submitConfirmationId, setSubmitConfirmationId] = useState(-1);
  const [dentist, setDentist] = useState(null);
  const columns = OrderService.dentistTableSchema;
  const componentRef = useRef();

  const loadServiceOrders = useCallback(() => {
    restApiClient.orderServiceStep
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('orderService.id', searchBy)
              .or()
              .like('orderService.patient.name', searchBy)
              .or()
              .like('orderService.patient.record_number', searchBy)
              .or()
              .like('dentist.user.name', searchBy)
              .or()
              .like('dentist.user.name', searchBy)
              .or()
              .like('orderService.endProduct.name', searchBy)
              .or()
              .like('step.name', searchBy)
              .or()
              .like('status', searchBy)
              .or()
              .like('unit.name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  useEffect(() => {
    loadServiceOrders();
  }, [loadServiceOrders]);

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Ordem de Serviço`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }`,
  });

  return (
    <Container>
      <CrudTable
        title={'Todas Ordens de Serviço'}
        modelActions={OrderService.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customConfirmations={[
          {
            selector: (id) => id === submitConfirmationId,
            content: (
              <span>
                Você tem certeza que deseja finalizar a ordem de serviço?
              </span>
            ),
            onSuccess: () => {
              restApiClient.orderServiceStep
                .setFinalized(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                  setSubmitConfirmationId(-1);
                });
            },
            onCancel: () => setSubmitConfirmationId(-1),
          },
        ]}
        actions={{
          update: {
            onClick: (orderServiceStepId, row) => {
              setSelectedOrderService(row?.orderService.id);
              setOpen(true);
            },
            allowDentist: true,
            selector: (row) =>
              row?.status === 'Em Edição (Dentista)' &&
              row.workflowPosition === 0,
          },
          delete: {
            onClick: (orderServiceStepId) => {
              restApiClient.orderServiceStep
                .delete(orderServiceStepId)
                .then(() => {
                  loadServiceOrders();
                });
            },
            allowDentist: true,
            selector: (row) =>
              (row?.status === 'Ajuste (Dentista)' ||
                row?.status === 'Em Edição (Dentista)') &&
              row?.current,
          },
          addOrderServiceStep: {
            icon: <AddToQueue />,
            onClick: (orderServiceStepId, row) => {
              if (row) {
                history.push({
                  pathname: `/dentist/lab/orderService/update/${row?.orderService.id}/${row.workflowPosition}`,
                  state: {
                    date: row.createdAt,
                    dentistEdit: true,
                  },
                });
              } else {
                history.push(
                  `/dentist/lab/orderService/create/${row?.orderService.id}/${
                    row.endProductId
                  }/${0}`
                );
              }
            },
            title: 'Atualizar uma etapa',
            allowDentist: true,
            selector: (row) => row?.status === 'Em Edição (Dentista)',
          },
          viewOS: {
            icon: <Preview />,
            onClick: (orderServiceStepId, row) => {
              history.push({
                pathname: `/dentist/lab/orderService/update/${row?.orderService.id}/${row.workflowPosition}`,
                state: {
                  date: row.createdAt,
                },
              });
            },
            title: 'Visualizar Etapa',
            allowDentist: true,
            selector: (row) => row?.status !== 'Em Edição (Dentista)',
          },
          refuseOrder: {
            icon: <ReceiptRefund />,
            onClick: (orderServiceId, row) => {
              setDentist(userData);
              setOrderServiceStepId(row.id);
              setOrderService({
                ...row.orderService,
                orderServiceStep: { ...row },
              });
              setOpenRefuse(true);
            },
            title: 'Recusar Ordem de Serviço',
            allowDentist: true,
            selector: (row) =>
              row?.status === 'Recebido (Clínica)' ||
              row?.status === 'Finalizada' ||
              row?.status === 'Etapa Concluída (Dentista)',
          },
          acceptOrderService: {
            icon: <Check />,
            onClick: (id, row) => {
              const lastWorkflow = row.orderService.endProduct.workflows.reduce(
                (workFlowPrev, workFlowCurrent) =>
                  workFlowPrev.position > workFlowCurrent.position
                    ? workFlowPrev
                    : workFlowCurrent
              );

              if (row?.stepId === lastWorkflow?.stepId) {
                setOrderServiceStepId(row.id);
                setSubmitConfirmationId(id);
              } else {
                restApiClient.orderServiceStep
                  .setStepCompleted(row.id)
                  .then(() => {
                    loadServiceOrders();
                  });
              }
            },
            allowDentist: true,
            title: 'Aceitar Ordem de Serviço',
            selector: (row) => row?.status === 'Recebido (Clínica)',
          },
          addNextStep: {
            icon: <AddBox />,
            onClick: (orderServiceId, row) => {
              setOpenCreateDialog(true);
              setCreateDialogText(
                'Ao iniciar uma nova etapa não será mais possível modificar a etapa anterior, você deseja continuar?'
              );
              setCreatePath(
                `/dentist/lab/orderService/update/${row.orderService.id}/${row.workflowPosition}`
              );
            },
            title: 'Adicionar Próxima Etapa',
            allowDentist: true,
            selector: (row) =>
              (row?.status === 'Etapa Concluída (Dentista)' ||
                row?.status === 'Etapa Concluída e Recusada (Laboratório)') &&
              row.nextStep,
          },
          viewRejection: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceStepId, row) => {
              setOrderServiceStepId(orderServiceStepId);
              setOpenRejection(true);
              setJustification(row?.rejectedJustification);
              setWorkflowPosition(row.workflowPosition);
            },
            allowDentist: true,
            title: 'Visualizar Justificativa da Recusa',
            selector: (row) => row?.status === 'Ajuste (Dentista)',
          },
          printOrder: {
            icon: <Printer />,
            onClick: async (id, row) => {
              await setOrderService({
                ...row.orderService,
                orderServiceStep: { ...row },
              });
              handlePrint();
            },
            title: 'Imprimir OS',
            allowDentist: true,
            selector: (row) =>
              row?.status === 'Etapa Concluída (Dentista)' ||
              row?.status === 'Ajuste (Dentista)' ||
              row?.status === 'Recebido (Clínica)',
          },
          viewDentistRejectedJustification: {
            icon: <ExclamationTriangleFill />,
            onClick: (orderServiceStepId, row) => {
              setOpenUrgencyDialog(true);
              setJustification(row?.dentistRejectedJustification);
            },
            allowDentist: true,
            title: 'Visualizar Justificativa',
            selector: (row) =>
              row?.status === 'Recusada Dentista (Laboratório)',
          },
          suspendedOS: {
            icon: <Cancel />,
            selector: (row) =>
              row?.status === 'Recebido (Clínica)' ||
              row?.status === 'Etapa Concluída (Dentista)',
            onClick: (id) => {
              restApiClient.orderServiceStep
                .suspendOrderService(id)
                .then(() => {
                  loadServiceOrders();
                });
            },
            title: 'Suspender OS',
            allowDentist: true,
          },
          resumeOS: {
            icon: <Restore />,
            selector: (row) => row?.status === 'OS Suspensa',
            onClick: (id) => {
              restApiClient.orderServiceStep.resumeOrderService(id).then(() => {
                loadServiceOrders();
              });
            },
            title: 'Retomar OS',
            allowDentist: true,
          },
        }}
      />

      <OrderServiceDialog
        open={open}
        orderService={OrderService}
        selectedOrderService={selectedOrderService}
        onCancel={() => {
          setOpen(false);
          setSelectedOrderService(null);
        }}
        onSuccess={(result) => {
          setOpen(false);
          if (result.data.orderServiceStep && !result.data.newEndProduct) {
            //Caso tenha um step e seja apenas uma atualização - atualiza o step
            history.push({
              pathname: `/dentist/lab/orderService/update/${
                result.data.id
              }/${0}`,
              state: {
                date: result.data.createdAt,
                dentistEdit: true,
              },
            });
          } else {
            //Caso seja um novo EndProduct - cria um novo step
            history.push(
              `/dentist/lab/orderService/create/${result.data.id}/${
                result.data.endProductId
              }/${0}`
            );
          }
        }}
        {...props}
      />

      <ViewUrgencyDialog
        open={openRejection}
        title={'Recusa - Justificativa'}
        justification={justification}
        orderServiceStepId={orderServiceStepId}
        onCancel={() => {
          setOpenRejection(false);
        }}
        onSuccess={(orderServiceStep) => {
          const modelOrderServiceStep = {
            orderServiceId: orderServiceStep.orderServiceId,
            dentistId: userData?.id,
            stepId: orderServiceStep.stepId,
            unitId: orderServiceStep.unitId,
            opposite: orderServiceStep.opposite
              ? orderServiceStep.opposite
              : false,
            waxBite: orderServiceStep.waxBite
              ? orderServiceStep.waxBite
              : false,
            toothNumber: orderServiceStep.toothNumber,
            dentalArch: orderServiceStep.dentalArch,
            toothShadeId: orderServiceStep.toothShadeId,
            observation: orderServiceStep.observation,
            current: orderServiceStep.current,
          };
          restApiClient.orderServiceStep
            .create(modelOrderServiceStep)
            .then((e) => {
              history.push(
                `/dentist/lab/orderService/update/${e.data.orderServiceId}/${workflowPosition}`
              );
            });
        }}
      />

      <ViewUrgencyDialog
        open={openUrgencyDialog}
        title={'Recusa - Justificativa'}
        justification={justification}
        onCancel={() => {
          setOpenUrgencyDialog(false);
        }}
      />

      <SubmitDialog
        open={openCreateDialog}
        onCancel={() => setOpenCreateDialog(false)}
        onSuccess={() => {
          setOpenCreateDialog(false);
          if (createPath) {
            history.push({
              pathname: createPath,
              state: {
                typeRequest: 'create',
              },
            });
          }
        }}
        dialogText={createDialogText}
      />

      <DentistRefusedDialog
        open={openRefuse}
        orderServiceStepId={orderServiceStepId}
        dentist={dentist}
        onCancel={() => {
          setOpenRefuse(false);
        }}
        onSuccess={async (justification, stepFinished) => {
          if (!stepFinished) {
            setPrintJustification(justification);
            await setOrderService({
              ...orderService,
              orderServiceStep: {
                ...orderService.orderServiceStep,
                dentist: {
                  user: dentist,
                },
              },
            });
            handlePrint();
          }
          setPrintJustification(false);
          setOpenRefuse(false);
          loadServiceOrders();
        }}
      />

      <PrintOS ref={componentRef}>
        <VoucherOS
          orderService={orderService}
          printJustification={printJustification}
        />
      </PrintOS>
    </Container>
  );
}

export default AllOrderServiceList;
