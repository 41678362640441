import React, { useState, useEffect } from 'react';
import EndProduct from '../../../../forms/endProduct';
import restApiClient from '../../../../services/restApiClient';
import CrudTable from '../../../../components/crudTable';
import FilterTree from '../../../../helpers/filterTree';
import { Container, Preview } from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import { isEmpty } from 'lodash';

function EndProductList({ history }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');

  const columns = EndProduct.tableSchema;

  useEffect(() => {
    restApiClient.endProduct
      .getAll(
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree()
              .like('id', searchBy)
              .or()
              .like('name', searchBy)
              .toString()
          : null,
        orderBy
      )
      .then((e) => {
        setRows(e.data);
      });
  }, [rowsPerPage, page, orderBy, searchBy]);

  return (
    <Container>
      <CrudTable
        title={'Cadastro de Produto Final'}
        modelActions={EndProduct.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          enabled: {
            renderer: (field, value, column, row) => {
              return (
                <Checkbox
                  key={row.id}
                  checked={value}
                  onChange={(e) => {
                    restApiClient.endProduct
                      .update(row.id, {}, { enabled: e.target.checked }, [], [])
                      .then((result) => {
                        const newRows = { ...rows };
                        const item = newRows.values.find(
                          (x) => x.id === row.id
                        );
                        item.enabled = result.data.enabled;
                        setRows(newRows);
                      });
                  }}
                />
              );
            },
          },
        }}
        actions={{
          add: {
            onClick: () => history.push('/lab/endProduct/create'),
          },
          update: {
            onClick: (endProductId) =>
              history.push(`/lab/endProduct/update/${endProductId}`),
            selector: (row) => isEmpty(row?.orderServices),
          },
          viewEndProduct: {
            icon: <Preview />,
            onClick: (endProductId) =>
              history.push({
                pathname: `/lab/endProduct/update/${endProductId}`,
                state: {
                  disableFields: true,
                },
              }),
            title: 'Visualizar Produto Final',
            allowedActions: [EndProduct.actions.getAll],
          },
        }}
      />
    </Container>
  );
}

export default EndProductList;
