/* eslint-disable no-unused-expressions */
import React from 'react';
import IntlCurrencyInput from 'react-intl-currency-input';
import { TextField } from './styles';
import { Hidden } from '@material-ui/core';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      },
    },
  },
};

function BrlIntlCurrencyInput(props) {
  return (
    <IntlCurrencyInput currency="BRL" config={currencyConfig} {...props} />
  );
}

function MoneyInput({
  id,
  handleChange,
  description,
  errors,
  label,
  schema,
  uischema,
  visible = true,
  required,
  config,
  input,
  inputProps,
  onChange,
  path,
  data,
  ...props
}) {
  const isValid = errors?.length === 0;
  const formHelperText = !isValid ? errors : null;

  return (
    <Hidden xsUp={!visible}>
      <TextField
        id={id}
        label={label}
        value={data}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: BrlIntlCurrencyInput,
          ...inputProps,
        }}
        onChange={(e, value, maskedValue) => {
          handleChange?.(path, value);
          onChange?.(e, value, maskedValue);
        }}
        error={!isValid}
        helperText={!isValid ? formHelperText : null}
        {...props}
      />
    </Hidden>
  );
}

export default MoneyInput;
