/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
  Container,
  DialogContent,
  InputTextarea,
  Dialog,
  ActionsContainer,
  CancelButton,
  DialogActions,
  Card,
  CardHeader,
  HeaderContainer,
  SubmitButton,
  Checkbox,
  FormControlLabel,
} from './styles';
import restApiClient from '../../services/restApiClient';
import SubmitDialog from '../submitDialog';
import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';

function DentistRefusedDialog({
  onSuccess,
  onCancel,
  orderServiceStepId,
  dentist = null,
  ...props
}) {
  const [justification, setJustification] = useState('');
  const [justificationError, setJustificationError] = useState('');
  const [setStepFinish, setSetStepFinish] = useState(false);
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const printJustification = justification;
    if (setStepFinish) {
      restApiClient.orderServiceStep
        .setStepCompletedRefused(orderServiceStepId, {
          dentistRejectedJustification: justification,
        })
        .then((r) => {
          setJustification(null);
          setSetStepFinish(false);
          onSuccess?.(printJustification, setStepFinish);
        })
        .catch((result) => {
          setJustificationError(result?.response?.data?.errors);
        });
    } else {
      // MUDAR PARA DENTIST REFUSED E CLONAR A STEP COM A SITUAÇÃO RETRABALHO E A JUSTIFICATIVA COMO OBS
      restApiClient.orderServiceStep
        .setDentistRefused(orderServiceStepId, {
          dentistRejectedJustification: justification,
        })
        .then((e) => {
          const step = e.data;
          const newOrderServiceStep = {
            orderServiceId: step.orderServiceId,
            dentistId: !isEmpty(dentist) ? dentist.id : step.dentistId,
            stepId: step.stepId,
            unitId: step.unitId,
            observation: step.dentistRejectedJustification,
            dentalArch: step.dentalArch,
            toothNumber: step.toothNumber,
            toothShadeId: step.toothShadeId,
            waxBite: step.waxBite ?? false,
            opposite: step.opposite ?? false,
            status: 'Retrabalho (Clínica)',
            skipStep: false,
            current: step.current,
          };
          restApiClient.orderServiceStep
            .create(newOrderServiceStep)
            .then((e) => {
              setJustification(null);
              setSetStepFinish(false);
              onSuccess?.(printJustification, setStepFinish);
            });
        })
        .catch((result) => {
          setJustificationError(result.response.data.errors);
        });
    }
  };
  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogContent>
            <Card>
              <HeaderContainer>
                <CardHeader title="Recusa - Justificativa" />
              </HeaderContainer>
              <Grid item xs={12} sm={12}>
                <InputTextarea
                  id="outlined-multiline-static"
                  size="small"
                  label="Justificativa"
                  multiline
                  rows={4}
                  variant="outlined"
                  onChange={(e) => {
                    setJustificationError(false);
                    setJustification(e.target.value);
                  }}
                  inputProps={{ maxLength: 250 }}
                  helperText={
                    justificationError ? 'Este campo é obrigatório.' : ''
                  }
                  error={justificationError}
                />
              </Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setStepFinish}
                    onChange={(e) => {
                      setSetStepFinish(e.target.checked);
                    }}
                    name="checked"
                  />
                }
                label="Finalizar Etapa"
              />
            </Card>
          </DialogContent>
          <DialogActions>
            <ActionsContainer>
              <CancelButton
                onClick={() => {
                  onCancel?.();
                  setJustification('');
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SubmitButton
                onClick={(e) => {
                  if (setStepFinish) {
                    setEvent(e);
                    setOpen(true);
                  } else {
                    handleSubmit(e);
                  }
                }}
                type="button"
                variant="contained"
                color="primary"
              >
                Submeter
              </SubmitButton>
            </ActionsContainer>
          </DialogActions>
        </form>
      </Dialog>

      <SubmitDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          handleSubmit(event);
        }}
        dialogText={
          'Ao finalizar esta etapa o laboratório não irá refazer o trabalho, você deseja continuar?'
        }
      />
    </Container>
  );
}

export default DentistRefusedDialog;
