import styled from 'styled-components';
import {
  Box as MaterialBox,
  AddToQueue as MaterialAddToQueue,
  Send as MaterialSend,
  Preview as MaterialPreview,
  ReceiptRefund as MaterialReceiptRefund,
  AddBox as MaterialAddBox,
  Printer as MaterialPrinter,
  Cancel as MaterialCancel
} from '../../../../../styles/icons';
import {
  Select as MaterialSelect,
  FormControl as MaterialFormControl,
  InputLabel as MaterialInputLabel,
  MenuItem as MaterialMenuItem,
} from '@material-ui/core';
import { ExclamationTriangleFill as MaterialExclamationTriangleFill } from '@styled-icons/bootstrap/ExclamationTriangleFill';
import { Check as MaterialCheck } from '@styled-icons/material/Check';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SelectContainer = styled.div`
  width: 300px;
`;

export const Box = styled(MaterialBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddToQueue = styled(MaterialAddToQueue)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Select = styled(MaterialSelect)`
  width: 100%;
  /* margin-top: 11px !important; */
`;
export const FormControl = styled(MaterialFormControl)`
  width: 100%;
`;
export const InputLabel = styled(MaterialInputLabel)`
  width: 100%;
`;
export const MenuItem = styled(MaterialMenuItem)``;

export const Send = styled(MaterialSend)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const AddBox = styled(MaterialAddBox)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Preview = styled(MaterialPreview)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ReceiptRefund = styled(MaterialReceiptRefund)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const ExclamationTriangleFill = styled(MaterialExclamationTriangleFill)`
  width: 25px;
  height: 25px;
  fill: red;
`;

export const Check = styled(MaterialCheck)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Printer = styled(MaterialPrinter)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const Cancel = styled(MaterialCancel)`
  width: 25px;
  height: 25px;
  fill: var(--secondary);
`;

export const PrintOS = styled.div`
  @media screen {
    display: none
  }
`;
