import React, { useCallback } from 'react';
import { and, isObjectArray, rankWith, uiTypeIs } from '@jsonforms/core';
import { Hidden } from '@material-ui/core';
import { withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import JsonFormsArrayLayout from '../../components/jsonFormsArrayLayout';

export const MaterialArrayLayoutRenderer = ({
  visible,
  enabled,
  id,
  uischema,
  schema,
  label,
  rootSchema,
  renderers,
  cells,
  data,
  path,
  errors,
  uischemas,
  addItem,
  config,
}) => {
  const addItemCb = useCallback((p, value) => addItem(p, value), [addItem]);
  return (
    <Hidden xsUp={!visible}>
      <JsonFormsArrayLayout
        label={label}
        uischema={uischema}
        schema={schema}
        id={id}
        rootSchema={rootSchema}
        errors={errors}
        enabled={enabled}
        visible={visible}
        data={data}
        path={path}
        addItem={addItemCb}
        renderers={renderers}
        cells={cells}
        uischemas={uischemas}
        config={config}
      />
    </Hidden>
  );
};

export default {
  tester: rankWith(5, and(uiTypeIs('ControlCustomArray'), isObjectArray)),
  renderer: withJsonFormsArrayLayoutProps(MaterialArrayLayoutRenderer),
};
