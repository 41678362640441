import React from 'react';
import Procedure from '../../../../forms/procedure';
import CreateUpdateForm from '../../../../components/createUpdateForm';
import restApiClient from '../../../../services/restApiClient';
import { Container } from './styles';

function ProcedureCreateUpdate(props) {
  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Procedure}
        restGetDataById={restApiClient.procedure.findById}
        restCreateData={restApiClient.procedure.create}
        restUpdateData={restApiClient.procedure.update}
        onCreateSuccess={(request) => {
          props.history.goBack();
        }}
        onUpdateSuccess={(request) => {
          props.history.goBack();
        }}
        {...props}
      />
    </Container>
  );
}

export default ProcedureCreateUpdate;
