import styled from 'styled-components';
import {
  MuiPickersUtilsProvider as MaterialMuiPickersUtilsProvider,
  KeyboardDatePicker as MaterialKeyboardDatePicker,
} from '@material-ui/pickers';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const KeyboardDatePicker = styled(MaterialKeyboardDatePicker)`
  width: 185px;
  margin: 0 !important;
`;

export const MuiPickersUtilsProvider = styled(MaterialMuiPickersUtilsProvider)``;
