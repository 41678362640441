/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import Purchase from '../../forms/purchase';
import restApiClient from '../../services/restApiClient';
import FilterTree from '../../helpers/filterTree';
import Switch from '@material-ui/core/Switch';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  StyledCrudTable,
} from './styles';

const PurchaseDialog = ({ purchaseId, onSelect, onCancel, open }) => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [orderBy, setOrderby] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const [mergeList, setMergeList] = useState([]);

  const columns = Purchase.tableSchema;

  useEffect(() => {
    if (purchaseId !== -1) {
      restApiClient.purchase
        .getAllAvailableToMerge(
          purchaseId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy
            ? new FilterTree()
                .like('id', searchBy)
                .or()
                .like('unit.name', searchBy)
                .or()
                .like('supplier.fantasy_name', searchBy)
                .or()
                .like('budget.name', searchBy)
                .toString()
            : null,
          orderBy
        )
        .then((e) => {
          setRows(e.data);
        });
    }
  }, [rowsPerPage, page, orderBy, searchBy, purchaseId]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onCancel}
      maxWidth="xl"
    >
      <DialogContent>
        <StyledCrudTable
          title={'Pedidos para agrupar'}
          modelActions={Purchase.actions}
          columns={columns}
          customColumns={[
            {
              id: 'selected',
              label: 'Agrupar',
              minWidth: 50,
              align: 'center',
            },
          ]}
          rows={rows}
          page={page}
          setPage={(page) => setPage(page)}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[3]}
          setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          onOrderByChange={(orderBy) => setOrderby(orderBy)}
          orderBy={orderBy}
          setOrderBy={(value) => setOrderby(value)}
          searchBy={searchBy}
          setSearchBy={(value) => setSearchBy(value)}
          customCellRenderers={{
            selected: {
              renderer: (field, value, column, row) => {
                return (
                  <Switch
                    key={field}
                    onChange={() => {
                      if (mergeList.includes(row.id)) {
                        setMergeList(mergeList.filter((x) => x !== row.id));
                      } else {
                        setMergeList([...mergeList, row.id]);
                      }
                    }}
                  />
                );
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (mergeList.length > 0) {
              onSelect([...mergeList, purchaseId]);
            } else {
              onCancel();
            }
          }}
          color="primary"
        >
          Agrupar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseDialog;
