import React, { useCallback, useState } from 'react';
import {
  rankWith,
  and,
  or,
  schemaTypeIs,
  uiTypeIs,
  schemaMatches,
  isDescriptionHidden,
} from '@jsonforms/core';
import {
  withJsonFormsCellProps,
  withJsonFormsControlProps,
} from '@jsonforms/react';
import isEmpty from 'lodash/isEmpty';
import MaskedInputTextControl from '../../components/maskedInputTextControl';
import { merge } from 'lodash';
import { FormControl, Hidden, InputLabel } from '@material-ui/core';
import { MaterialInputControl } from '@jsonforms/material-renderers';

const isStringControl = and(
  uiTypeIs('Control'),
  schemaMatches((schema) => !isEmpty(schema) && schema.format === undefined),
  or(schemaTypeIs('string'), schemaTypeIs('null'))
);

const MaterialInputCellControl = (props) => {
  const [focused, setFocused] = useState(false);
  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);
  const {
    id,
    description,
    errors,
    label,
    uischema,
    visible,
    required,
    config,
    input,
  } = props;
  const isValid = errors.length === 0;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  const showDescription = !isDescriptionHidden(
    visible,
    description,
    focused,
    appliedUiSchemaOptions.showUnfocusedDescription
  );
  const InnerComponent = input;

  return (
    <Hidden xsUp={!visible}>
      <FormControl
        fullWidth={!appliedUiSchemaOptions.trim}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        variant={'standard'}
        error={!isValid}
      >
        <InputLabel
          htmlFor={id + '-input'}
          error={!isValid}
          required={required || appliedUiSchemaOptions.hideRequiredAsterisk}
        >
          {label}
        </InputLabel>
        <InnerComponent
          {...props}
          id={id + '-input'}
          isValid={isValid}
          visible={visible}
        />
      </FormControl>
    </Hidden>
  );
};

export const MaterialTextControl = (props) => {
  const upperCased = props.schema.upperCased ?? true;
  return (
    <MaterialInputControl
      {...props}
      input={MaskedInputTextControl}
      handleChange={(path, value) => {
        props.handleChange(path, upperCased ? value?.toUpperCase() : value);
      }}
    />
  );
};

export const MaterialTextCellControl = (props) => {
  const upperCased = props.schema.upperCased ?? true;
  return (
    <MaterialInputCellControl
      {...props}
      input={MaskedInputTextControl}
      handleChange={(path, value) => {
        props.handleChange(path, upperCased ? value?.toUpperCase() : value);
      }}
    />
  );
};

const materialTextControlTester = rankWith(5, isStringControl);

export default {
  tester: materialTextControlTester,
  renderer: withJsonFormsControlProps(MaterialTextControl),
  cell: withJsonFormsCellProps(MaterialTextCellControl),
};
