import React, { useRef, useState, useEffect } from 'react';
import OrderServiceStep from '../../../../../forms/orderServiceStep';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import {
  Container,
  FooterContainer,
  SubmitButton,
  SaveButton,
  CancelButton,
  BackButton,
  AutoDiv,
} from './styles';
import { useSelector } from 'react-redux';
import { objectMap } from '../../../../../extensions/object';
import SubmitDialog from '../../../../../components/submitDialog';
import VoucherOS from '../../../../../components/voucherOS';
import { PrintOS } from '../../../../clinic/lab/orderService/list/styles';
import { useReactToPrint } from 'react-to-print';
import CircularProgress from '@material-ui/core/CircularProgress';

function OrderServiceStepCreateUpdate({
  crrViewForm = null,
  crrEndProductId = null,
  crrOrderServiceId = null,
  crrWorkflowPosition = null,
  crrId = false,
  crrOrderServiceStep = null,
  crrPatientName = null,
  crrRecordNumber = null,
  crrUnitId = null,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [groupStep, setGroupStep] = useState(false);
  const [showGroupStep, setShowGroupStep] = useState(false);
  const [showDentalArch, setShowDentalArch] = useState(false);
  const [showToothNumber, setShowToothNumber] = useState(false);
  const [showToothShade, setShowToothShade] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [orderService, setOrderService] = useState(null);
  const [orderServiceStep, setOrderServiceStep] = useState(null);
  const [stepId, setStepId] = useState(-1);
  const endProductId = parseInt(
    crrEndProductId ?? props.match?.params?.endProductId ?? -1
  );
  const orderServiceId = parseInt(
    crrOrderServiceId ?? props.match?.params?.orderServiceId ?? -1
  );
  const workflowPosition = parseInt(
    crrWorkflowPosition ?? props.match?.params?.workflowPosition ?? -1
  );
  const dentistEdit = props.location?.state?.dentistEdit ?? false;
  const userData = useSelector((state) => state.authReducer?.user);
  let viewForm = crrViewForm ?? props.match?.params?.viewForm ?? -1;

  const patientName =
    crrPatientName ?? props.location?.state?.patientName ?? '';
  const recordNumber = parseInt(
    crrRecordNumber ?? props.location?.state?.recordNumber ?? -1
  );

  const unitId = parseInt(crrUnitId ?? props.location?.state?.unitId ?? null);

  const date = props.location?.state?.date
    ? new Date(props.location?.state.date).toLocaleDateString('pt-br')
    : new Date().toLocaleDateString();
  const componentRef = useRef();
  if (viewForm === 'true' || viewForm === true) {
    viewForm = true;
  } else {
    viewForm = false;
  }

  const handlePrint = useReactToPrint({
    documentTitle: `Comprovante - Ordem de Serviço`,
    content: () => componentRef.current,
    pageStyle: () =>
      `@page {
        size: 80mm 60cm;
        margin: 3mm 3mm 3mm 3mm;   
      }`,
  });

  useEffect(() => {
    if (endProductId) {
      restApiClient.endProduct.findById(endProductId).then((e) => {
        let firstStepFind = e.data.workflows.find(
          (x) => x.position === workflowPosition
        );
        let nextStepFind = e.data.workflows.find(
          (x) => x.position === workflowPosition + 1
        );

        if (firstStepFind) {
          if (nextStepFind) {
            setShowGroupStep(true);
          } else {
            setShowGroupStep(false);
          }
          let step = firstStepFind.step;
          setShowDentalArch(step.dentalArch);
          setShowToothNumber(step.toothNumber);
          setShowToothShade(step.toothColor);
          setStepId(step.id);
        }
      });
    }
  }, [endProductId, workflowPosition]);

  return stepId !== -1 ? (
    <Container>
      <CreateUpdateForm
        modelSchema={OrderServiceStep}
        forceId={crrId}
        restGetDataById={async (id) => {
          let result = await restApiClient.orderServiceStep.findById(id);
          setOrderServiceStep(result.data);
          result.data = objectMap(result.data, (key, value) =>
            value !== null ? value : undefined
          );
          return result;
        }}
        restCreateData={async (orderServiceStep) => {
          delete orderServiceStep.status;
          delete orderServiceStep.executorId;
          orderServiceStep.current = true;
          if (!orderServiceStep.skipStep) {
            // se apagou o skip, deve ser atualizado a justificativa
            orderServiceStep.skipJustify = null;
          } else {
            orderServiceStep.dentalArch = null;
            orderServiceStep.toothShadeId = null;
            orderServiceStep.toothNumber = null;
          }
          const result = await restApiClient.orderServiceStep.create(
            orderServiceStep
          );
          return result;
        }}
        restUpdateData={async (id, oldOrderServiceStep, orderServiceStep) => {
          delete oldOrderServiceStep.dentistId;
          delete oldOrderServiceStep.date;
          delete oldOrderServiceStep.endProductId;
          delete oldOrderServiceStep.stepId;
          delete oldOrderServiceStep.enableFields;
          delete oldOrderServiceStep.showDentalArch;
          delete oldOrderServiceStep.showToothNumber;
          delete oldOrderServiceStep.showToothShade;
          delete oldOrderServiceStep.viewForm;
          delete oldOrderServiceStep.workflowPosition;
          delete oldOrderServiceStep.showGroupStep;
          delete oldOrderServiceStep.groupStep;
          delete oldOrderServiceStep.status;
          delete oldOrderServiceStep.executorId;
          delete oldOrderServiceStep.patientName;
          delete oldOrderServiceStep.recordNumber;
          delete oldOrderServiceStep.requestingUnitBoolean;

          delete orderServiceStep.dentistId;
          delete orderServiceStep.date;
          delete orderServiceStep.endProductId;
          delete orderServiceStep.stepId;
          delete orderServiceStep.enableFields;
          delete orderServiceStep.showDentalArch;
          delete orderServiceStep.showToothNumber;
          delete orderServiceStep.showToothShade;
          delete orderServiceStep.viewForm;
          delete orderServiceStep.workflowPosition;
          delete orderServiceStep.showGroupStep;
          delete orderServiceStep.groupStep;
          delete orderServiceStep.status;
          delete orderServiceStep.executorId;
          delete orderServiceStep.patientName;
          delete orderServiceStep.recordNumber;
          delete orderServiceStep.requestingUnitBoolean;

          dentistEdit
            ? (orderServiceStep.dentistId = userData?.id)
            : delete orderServiceStep.dentistId;

          if (!orderServiceStep.skipStep) {
            // se apagou o skip, deve ser atualizado a justificativa
            orderServiceStep.skipJustify = 'null';
          } else {
            orderServiceStep.dentalArch = 'null';
            orderServiceStep.toothShadeId = 'null';
            orderServiceStep.toothNumber = 'null';
          }

          const result = await restApiClient.orderServiceStep.update(
            id,
            oldOrderServiceStep,
            orderServiceStep
          );
          return result;
        }}
        modelData={{
          date: date,
          enableFields: false,
          showDentalArch: showDentalArch,
          showToothNumber: showToothNumber,
          showToothShade: showToothShade,
          stepId: stepId,
          unitId: unitId,
          showGroupStep: showGroupStep,
          status: orderServiceStep?.status ?? 'Em Edição (Dentista)',
          skipStep: false,
          dentistId: !crrViewForm && !dentistEdit ? userData?.id : undefined,
          endProductId: parseInt(endProductId),
          orderServiceId: parseInt(orderServiceId),
          viewForm: viewForm,
          workflowPosition: parseInt(workflowPosition),
          patientName: patientName,
          recordNumber: recordNumber,
        }}
        onChange={(data) => {
          if (data.skipStep) {
            setGroupStep(false);
          } else {
            setGroupStep(data.groupStep);
          }
        }}
        onCreateSuccess={(request, data) => {
          if (data === 'submit') {
            //SUBMETER
            if (request.data.skipStep && request.data.skipJustify !== null) {
              restApiClient.orderService
                .findById(request.data.orderServiceId)
                .then((e) => {
                  const skipStepFind = e.data.endProduct.workflows.find(
                    (x) => x.stepId === request.data.stepId
                  );
                  const nextStep = e.data.endProduct.workflows.find(
                    (x) => x.position === skipStepFind.position + 1
                  );
                  if (nextStep) {
                    const nextOrderServiceStep = {
                      orderServiceId: request.data.orderServiceId,
                      dentistId: request.data.dentistId,
                      stepId: nextStep.stepId,
                      unitId: request.data.unitId,
                      current: true,
                    };
                    restApiClient.orderServiceStep
                      .setSkippedStep(request.data.id)
                      .then((e) => {
                        props.history.goBack();
                        restApiClient.orderServiceStep
                          .create(nextOrderServiceStep)
                          .then((e) => {
                            props.history.push(
                              `/dentist/lab/orderService/update/${request.data.orderServiceId}/${nextStep.position}`
                            );
                          });
                      });
                  } else {
                    restApiClient.orderServiceStep
                      .setSkippedStep(request.data.id)
                      .then((e) => {
                        props.history.goBack();
                      });
                  }
                });
            } else {
              restApiClient.orderServiceStep
                .setSubmitted(request.data.id)
                .then((result) => {
                  setOrderService({
                    ...result.data.orderService,
                    orderServiceStep: result.data,
                  });
                  props.history.goBack();
                  handlePrint();
                });
            }
          } else if (data === 'nextStep') {
            //AGRUPAR COM PRÓXIMA ETAPA
            restApiClient.orderService
              .findById(request.data.orderServiceId)
              .then((e) => {
                const skipStepFind = e.data.endProduct.workflows.find(
                  (x) => x.stepId === request.data.stepId
                );
                const nextStep = e.data.endProduct.workflows.find(
                  (x) => x.position === skipStepFind.position + 1
                );
                const nextOrderServiceStep = {
                  orderServiceId: request.data.orderServiceId,
                  dentistId: request.data.dentistId,
                  stepId: nextStep.stepId,
                  unitId: request.data.unitId,
                  current: true,
                };
                restApiClient.orderServiceStep
                  .setGrouped(request.data.id)
                  .then((result) => {
                    setOrderService({
                      ...result.data.orderService,
                      orderServiceStep: result.data,
                    });
                    props.history.goBack();
                    handlePrint();
                    restApiClient.orderServiceStep
                      .create(nextOrderServiceStep)
                      .then((e) => {
                        props.history.push(
                          `/dentist/lab/orderService/update/${request.data.orderServiceId}/${nextStep.position}`
                        );
                      });
                  });
              });
          } else {
            //SALVAR FORM
            props.history.goBack();
            props.history.replace({
              pathname: `/dentist/lab/orderService`,
              state: {
                updateSuccess: true,
              },
            });
          }
        }}
        onUpdateSuccess={(request, data) => {
          if (data === 'submit') {
            // SUBMETER
            if (request.data.skipStep && request.data.skipJustify !== null) {
              // SUBMETER OS - PULAR ETAPA
              restApiClient.orderService
                .findById(request.data.orderServiceId)
                .then((e) => {
                  const skipStepFind = e.data.endProduct.workflows.find(
                    (x) => x.stepId === request.data.stepId
                  );
                  const nextStep = e.data.endProduct.workflows.find(
                    (x) => x.position === skipStepFind.position + 1
                  );
                  if (nextStep) {
                    const nextOrderServiceStep = {
                      orderServiceId: request.data.orderServiceId,
                      dentistId: request.data.dentistId,
                      stepId: nextStep.stepId,
                      unitId: request.data.unitId,
                      current: true,
                    };
                    restApiClient.orderServiceStep
                      .setSkippedStep(request.data.id)
                      .then((e) => {
                        props.history.goBack();
                        restApiClient.orderServiceStep
                          .create(nextOrderServiceStep)
                          .then((e) => {
                            props.history.push(
                              `/dentist/lab/orderService/update/${request.data.orderServiceId}/${nextStep.position}`
                            );
                          });
                      });
                  } else {
                    restApiClient.orderServiceStep
                      .setSkippedStep(request.data.id)
                      .then((e) => {
                        props.history.goBack();
                      });
                  }
                });
            } else {
              restApiClient.orderService
                .findById(request.data.orderServiceId)
                .then((e) => {
                  const skipStepFind = e.data.endProduct.workflows.find(
                    (x) => x.stepId === request.data.stepId
                  );
                  const lastStep = e.data.endProduct.workflows.find(
                    (x) => x.position === skipStepFind.position - 1
                  );
                  if (lastStep) {
                    const step = e.data.orderServiceSteps.find(
                      (x) => x.stepId === lastStep.stepId
                    );
                    if (step && step.status === 'Agrupado') {
                      // SUBMETER OS COM ETAPA ANTERIOR AGRUPADA
                      restApiClient.orderServiceStep
                        .setSubmittedInGroup(request.data.id)
                        .then((result) => {
                          setOrderService({
                            ...result.data.orderService,
                            orderServiceStep: result.data,
                          });
                          props.history.goBack();
                          handlePrint();
                        });
                    } else {
                      restApiClient.orderServiceStep
                        .setSubmitted(request.data.id)
                        .then((result) => {
                          setOrderService({
                            ...result.data.orderService,
                            orderServiceStep: result.data,
                          });
                          props.history.goBack();
                          handlePrint();
                        });
                    }
                  } else {
                    //SUBMETER OS NORMAL
                    restApiClient.orderServiceStep
                      .setSubmitted(request.data.id)
                      .then((result) => {
                        setOrderService({
                          ...result.data.orderService,
                          orderServiceStep: result.data,
                        });
                        props.history.goBack();
                        handlePrint();
                      });
                  }
                });
            }
          } else if (data === 'nextStep') {
            // AGRUPAR COM PRÓXIMA ETAPA
            restApiClient.orderService
              .findById(request.data.orderServiceId)
              .then((e) => {
                const skipStepFind = e.data.endProduct.workflows.find(
                  (x) => x.stepId === request.data.stepId
                );
                const nextStep = e.data.endProduct.workflows.find(
                  (x) => x.position === skipStepFind.position + 1
                );
                const nextOrderServiceStep = {
                  orderServiceId: request.data.orderServiceId,
                  dentistId: request.data.dentistId,
                  stepId: nextStep.stepId,
                  unitId: request.data.unitId,
                  current: true,
                };
                restApiClient.orderServiceStep
                  .setGrouped(request.data.id)
                  .then((result) => {
                    setOrderService({
                      ...result.data.orderService,
                      orderServiceStep: result.data,
                    });
                    props.history.goBack();
                    handlePrint();
                    restApiClient.orderServiceStep
                      .create(nextOrderServiceStep)
                      .then((e) => {
                        props.history.push(
                          `/dentist/lab/orderService/update/${request.data.orderServiceId}/${nextStep.position}`
                        );
                      });
                  });
              });
          } else {
            //SALVAR FORM
            props.history.goBack();
            props.history.replace({
              pathname: `/dentist/lab/orderService`,
              state: {
                updateSuccess: true,
              },
            });
          }
        }}
        customButtonsBuilder={(submit) =>
          viewForm ? (
            <>
              <FooterContainer>
                <BackButton
                  onClick={() => props.history.goBack()}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Voltar
                </BackButton>
              </FooterContainer>
            </>
          ) : groupStep ? (
            <>
              <CancelButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SaveButton
                type="button"
                variant="contained"
                color="primary"
                id="salvarTop"
                onClick={() => submit()}
              >
                {'Salvar'}
              </SaveButton>
              <SubmitButton
                type="button"
                variant="contained"
                color="primary"
                id="teste1"
                onClick={() => {
                  setOpen(true);
                  setDialogText(
                    'Ao agrupar com a próxima etapa, a mesma não poderá mais ser editada. Deseja continuar?'
                  );
                  setConfirmSubmit(() => () => submit('nextStep'));
                }}
              >
                {'Próxima etapa'}
              </SubmitButton>
            </>
          ) : (
            <>
              <CancelButton
                onClick={() => props.history.goBack()}
                type="button"
                variant="contained"
                color="primary"
              >
                Cancelar
              </CancelButton>
              <SaveButton
                type="button"
                variant="contained"
                color="primary"
                id="salvarTop"
                onClick={() => submit()}
              >
                {'Salvar'}
              </SaveButton>
              <SubmitButton
                type="button"
                variant="contained"
                color="primary"
                id="teste1"
                onClick={() => {
                  setOpen(true);
                  setDialogText(
                    'Ao submeter a OS, a mesma não poderá mais ser editada. Deseja continuar?'
                  );
                  setConfirmSubmit(() => () => submit('submit'));
                }}
              >
                {'Submeter'}
              </SubmitButton>
            </>
          )
        }
        {...props}
      />
      <SubmitDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          confirmSubmit();
        }}
        dialogText={dialogText}
      />

      <PrintOS ref={componentRef}>
        <VoucherOS orderService={orderService} />
      </PrintOS>
    </Container>
  ) : (
    <AutoDiv>
      <CircularProgress size={60} />
    </AutoDiv>
  );
}

export default OrderServiceStepCreateUpdate;
