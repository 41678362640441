import { and, rankWith, schemaMatches, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import TimeInputControl from '../../components/timeInput';
import isEmpty from 'lodash/isEmpty';

const isTimeInputControl = and(
  uiTypeIs('Control'),
  schemaMatches(
    (schema) => !isEmpty(schema) && schema.format === 'time'
  )
);

const materialTextControlTester = rankWith(5, isTimeInputControl);

export default {
  tester: materialTextControlTester,
  renderer: withJsonFormsControlProps(TimeInputControl),
};
