import React, { useState } from 'react';
import Attendance from '../../../../../forms/attendance';
import CreateUpdateForm from '../../../../../components/createUpdateForm';
import restApiClient from '../../../../../services/restApiClient';
import { Container, BackButton, CancelButton, SubmitButton } from './styles';
import AuditReplacementDialog from '../../../../../components/auditReplacementDialog';
import DisapprovedJusticationDialog from '../../../../../components/disapprovedJustificationDialog';
import SubmitDialog from '../../../../../components/submitDialog';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

function ReplacementsAuditCreateUpdate(props) {
  const auditId = props.match.params?.id ?? -1;
  const readonly = props.location?.state?.readonly ?? false;
  const disaproved = props.location?.state?.disaproved ?? false;
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [openAuditReplecement, setOpenAuditReplecement] = useState(false);
  const [openDisapproved, setOpenDisapproved] = useState(false);
  const [type, setType] = useState(null);
  const [substituteDentist, setSubstituteDentist] = useState(null);
  const [replacedDentistId, setReplacedDentistId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <Container>
      <CreateUpdateForm
        modelSchema={Attendance}
        schemaSelector={(modelSchema, modelId) =>
          modelId ? modelSchema.updateSchema : modelSchema.schema
        }
        restGetDataById={async (id) => {
          const result = await restApiClient.attendance.findById(id);
          result.data.recordNumber = result.data.patient.recordNumber;
          result.data.recordNumberUninformed =
            !result.data.patient.recordNumber;
          result.data.name = result.data.patient.name;
          result.data.showToothNumber = !!result.data.toothNumber;
          result.data.showDentalArch = !!result.data.dentalArch;
          return result;
        }}
        modelData={{
          requestingUnitBoolean: true,
          recordNumberFound: false,
          readonly,
          procedures: [{}],
        }}
        customButtonsBuilder={(_, data) => (
          <>
            <BackButton
              onClick={() => props.history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
            {disaproved ? (
              ''
            ) : (
              <CancelButton
                type="button"
                variant="contained"
                color="primary"
                id="submit"
                onClick={() => {
                  setOpenDisapproved(true);
                }}
              >
                {'Reprovar'}
              </CancelButton>
            )}
            <SubmitButton
              type="button"
              variant="contained"
              color="primary"
              id="submit"
              onClick={() => {
                if (data.history.length > 0) {
                  setOpen(true);
                  setType(data.type);
                  setReplacedDentistId(data.replacedDentistId);
                  setSubstituteDentist(data.dentist);
                  setDialogText(
                    'O procedimento se trata de um retrabalho, deseja aprovar mesmo assim?'
                  );
                } else {
                  setType(data.type);
                  setReplacedDentistId(data.replacedDentistId);
                  setSubstituteDentist(data.dentist);
                  setOpenAuditReplecement(true);
                }
              }}
            >
              {'Aprovar'}
            </SubmitButton>
          </>
        )}
        {...props}
      />

      <SubmitDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          setOpenAuditReplecement(true);
        }}
        dialogText={dialogText}
      />

      <AuditReplacementDialog
        open={openAuditReplecement}
        type={type}
        substituteDentist={substituteDentist}
        replacedDentistId={replacedDentistId}
        onCancel={() => {
          setSubstituteDentist(null);
          setType(null);
          setOpenAuditReplecement(false);
        }}
        onSuccess={(type, replacementId) => {
          restApiClient.attendance
            .setApproved(auditId, { scheduleType: type, replacementId })
            .then(() => {
              setOpenAuditReplecement(false);
              props.history.goBack();
            })
            .catch((result) => {
              setErrorMessage(result?.response?.data?.errors);
            });
        }}
      />

      <DisapprovedJusticationDialog
        open={openDisapproved}
        auditId={auditId}
        onCancel={() => setOpenDisapproved(false)}
        onSuccess={() => {
          setOpenDisapproved(false);
          props.history.goBack();
        }}
      />

      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ReplacementsAuditCreateUpdate;
