const BankingOperation = {
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        mask: '',
        minLength: 3,
        maxLength: 100,
        description: 'Entre com um nome',
        customErrors: '',
      },
    },
    required: ['name'],
  },

  uischema: {
    type: 'Group',
    label: 'Cadastro de Operação Bancária',
    elements: [
      {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Nome',
            scope: '#/properties/name',
          },
        ],
      },
    ],
  },
  tableSchema: [
    { id: 'id', label: 'Código Interno', minWidth: 100, align: 'center' },
    {
      id: 'sourceAccount.bankAccount.holderName',
      label: 'Conta Origem',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'destinationAccount.bankAccount.holderName',
      label: 'Conta Destino',
      minWidth: 100,
      align: 'center',
    },
    { id: 'type', label: 'Operação', minWidth: 100, align: 'center' },
    { id: 'date', label: 'Data', minWidth: 100, align: 'center' },
    { id: 'value', label: 'Valor', minWidth: 100, align: 'center' },
  ],
  actions: {
    getAll: 'BankingOperationController@getAll',
    findById: 'BankingOperationController@findById',
    create: 'BankingOperationController@create',
  },
};

export default BankingOperation;
