/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Container,
  CancelButton,
  SubmitButton,
  DialogContent,
  Card,
  Dialog,
  DialogActions,
  ActionsContainer,
  HeaderContainer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CardHeader,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  StyledMoneyInput,
} from './styles';
import SelectEmployee from '../selectEmployeeDialogControl';
import SelectAnySupplier from '../selectAnySupplierDialogControl';
import SelectPatientDialogControl from '../selectPatientDialogControl';
import SelectDentistControl from '../selectDentistControl';
import DayJsUtils from '@date-io/dayjs';
import moment from 'moment';

function ConsolidateExpenseDialog({
  adverseExpense,
  onSuccess,
  onError,
  onCancel,
  ...props
}) {
  const [answer, setAnswer] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [dentistId, setDentistId] = useState(null);
  const [expensableType, setExpensableType] = useState(null);
  const [payday, setPayday] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [consolidatedValue, setConsolidatedValue] = useState(0);
  const [errorPayday, setErrorPayday] = useState(false);
  const [errorDueDate, setErrorDueDate] = useState(false);
  const [errorValue, setErrorValue] = useState(false);

  useEffect(() => {
    setDueDate(adverseExpense?.dueDate);
    setConsolidatedValue(adverseExpense?.estimatedValue);
  }, [adverseExpense]);

  return (
    <Container>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        {...props}
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (consolidatedValue > 0 && !errorPayday && !errorDueDate) {
                onSuccess?.({
                  ...adverseExpense,
                  employeeId,
                  supplierId,
                  dentistId,
                  patientId,
                  expensableType,
                  dueDate: moment(new Date(dueDate)).format('YYYY-MM-DD'),
                  payday: moment(new Date(payday)).format('YYYY-MM-DD'),
                  consolidatedValue,
                });
              } else {
                setErrorValue(consolidatedValue <= 0);
              }
            }}
          >
            <Card>
              <HeaderContainer>
                <CardHeader title="Despesa" />
              </HeaderContainer>
              <FormControl component="fieldset">
                <FormLabel component="legend" required>
                  A despesa é referente a um:
                </FormLabel>
                <RadioGroup
                  row={true}
                  aria-label="question"
                  name="question"
                  value={answer ?? null}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                    setExpensableType(null);
                    setEmployeeId(null);
                    setSupplierId(null);
                    setPatientId(null);
                    setDentistId(null);
                  }}
                >
                  <FormControlLabel
                    value="employee"
                    control={<Radio required />}
                    label="Funcionário"
                    style={{ marginRight: '2.15rem' }}
                  />
                  <FormControlLabel
                    value="supplier"
                    control={<Radio required />}
                    label="Fornecedor"
                  />
                  <FormControlLabel
                    value="patient"
                    control={<Radio required />}
                    label="Paciente"
                    style={{ marginRight: '3.5rem' }}
                  />
                  <FormControlLabel
                    value="dentist"
                    control={<Radio required />}
                    label="Dentista"
                  />
                </RadioGroup>
              </FormControl>
              {answer === 'employee' ? (
                <SelectEmployee
                  label="Funcionário"
                  size="small"
                  onSuccess={(id) => {
                    setEmployeeId(id);
                  }}
                  visible
                  enabled
                  required
                />
              ) : answer === 'supplier' ? (
                <FormControl component="fieldset">
                  <SelectAnySupplier
                    label="Fornecedor"
                    size="small"
                    data={supplierId}
                    onSuccess={(id, data) => {
                      setExpensableType(data.type);
                      setSupplierId(id);
                    }}
                    visible
                    enabled
                    required
                  />
                </FormControl>
              ) : answer === 'patient' ? (
                <FormControl component="fieldset">
                  <SelectPatientDialogControl
                    label="Paciente"
                    size="small"
                    onSuccess={(id) => {
                      setPatientId(id);
                    }}
                    visible
                    enabled
                    required
                  />
                </FormControl>
              ) : answer === 'dentist' ? (
                <FormControl component="fieldset">
                  <SelectDentistControl
                    label="Dentista"
                    size="small"
                    onSuccess={(id) => {
                      setDentistId(id);
                    }}
                    visible
                    enabled
                    required
                  />
                </FormControl>
              ) : null}
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-from"
                  label="Data de Vencimento"
                  format="DD/MM/YYYY"
                  value={dueDate}
                  onChange={(date) => {
                    setDueDate(date);
                    setErrorDueDate(false);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Editar data',
                  }}
                  cancelLabel="Cancelar"
                  okLabel="Selecionar"
                  invalidDateMessage="Data em formato inválido."
                  onError={(value) => setErrorDueDate(!!value)}
                  helperText={errorDueDate ? 'Data em formato inválido.' : ''}
                  error={errorDueDate}
                  required
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-from"
                  label="Data do Pagamento"
                  format="DD/MM/YYYY"
                  value={payday}
                  onChange={(date) => {
                    setPayday(date);
                    setErrorPayday(false);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Editar data',
                  }}
                  cancelLabel="Cancelar"
                  okLabel="Selecionar"
                  invalidDateMessage="Data em formato inválido."
                  onError={(value) => setErrorPayday(!!value)}
                  helperText={errorPayday ? 'Data em formato inválido.' : ''}
                  error={errorPayday}
                  required
                />
              </MuiPickersUtilsProvider>
              <StyledMoneyInput
                value={consolidatedValue}
                onChange={(e, value) => {
                  setErrorValue(null);
                  setConsolidatedValue(value);
                }}
                label="Valor Consolidado"
                error={errorValue}
                helperText={errorValue ? 'Este campo é obrigatório.' : ''}
                required
              />
            </Card>
            <DialogActions>
              <ActionsContainer>
                <CancelButton
                  onClick={() => {
                    setAnswer(null);
                    setEmployeeId(null);
                    setSupplierId(null);
                    setPatientId(null);
                    setDentistId(null);
                    setExpensableType(null);
                    setPayday(null);
                    setErrorPayday(false);
                    setDueDate(null);
                    setErrorDueDate(false);
                    setConsolidatedValue(0);
                    setErrorValue(false);
                    onCancel?.();
                  }}
                >
                  Cancelar
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Selecionar
                </SubmitButton>
              </ActionsContainer>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default ConsolidateExpenseDialog;
